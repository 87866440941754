import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTable = styled(Table)({
  minWidth: 650,
});


export default function Tabela(props) {
  const { rows, acoes } = props
  const headCells = props.headCell

  return (
    <div>
      <TableContainer component={Paper}>
        <StyledTable size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {headCells.map(headCell => {
                return (
                  <TableCell 
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    style={{color: '#FFF', fontSize: 14}}
                  >
                    {headCell.label}
                  </TableCell>
                )
              })}
              {acoes.length === 1 &&
                <TableCell style={{ width: 80, color: '#FFF', fontSize: 14 }} align={'center'}>
                  <span>Ações</span>
                </TableCell>
              }
              {acoes.length === 2 &&
                <TableCell style={{ width: 100, color: '#FFF', fontSize: 14 }} align={'center'}>
                  <span>Ações</span>
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return(
                <TableRow kkey={row._id}>
                  {headCells.map((param, key) => {
                    if((typeof row[param.id]) === 'boolean'){
                      return (
                        <TableCell 
                          component="th" 
                          scope="row"
                          align={param.numeric ? 'right' : 'left'}
                          key={key}
                          style={{fontSize: 14}}
                        >
                          {row[param.id] ? 'Sim' : 'Não'}
                        </TableCell>
                      )  
                    }else {
                      return (
                        <TableCell 
                          component="th" 
                          scope="row"
                          align={param.numeric ? 'right' : 'left'}
                          key={key}
                          style={{fontSize: 14}}
                        >
                          {row[param.id]}
                        </TableCell>
                      )  
                    }
                  })}
                  {acoes.length > 0 &&
                    <TableCell 
                      component="th" 
                      scope="row"
                      align={'center'}
                      style={{fontSize: 14}}
                    >
                      {acoes.map(acao => {
                        if(acao === 'update'){
                          return (
                            <Tooltip title='Editar' aria-label="add">
                              <IconButton color="primary" aria-label="view" onClick={() => props.editar(row)}>
                                <CreateIcon />
                              </IconButton>
                            </Tooltip>
                          )
                        }else if(acao === 'remover'){
                          return (
                            <Tooltip title='Remover' aria-label="add">
                              <IconButton color="error" aria-label="view" onClick={() => props.remover(row.index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                        return false
                      })}
                    </TableCell>
                  }
                </TableRow>
              )
            })}

          </TableBody>
        </StyledTable>
      </TableContainer>
    </div >
  );
}
