import React, { useState, useEffect } from 'react';
import './Funcionario.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Loading from './../../components/templates/Loading'
import TabViews from './TabViews'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  funcionario: {},
  modalErro: false,
  unin_tipo: '',
  pessoa: {},
  contratos: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Colaborador...',
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesPromover: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

const ViewFuncionario = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { funcionarioId, tab } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 3000) => {
    const self = this

    setTimeout(() => {
      navigate("/funcionario");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'funcionario')[0]

      if (permissoes) {
        setState(prevState => ({...prevState,
          permissoes
        }))
      }

      let permissoesPromover = perfil.permissoes.filter(param => param.tela.modulo.slug === 'promover_colaborador')[0]

      if (permissoesPromover) {
        setState(prevState => ({...prevState,
          permissoesPromover
        }))
      }

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

      setState(prevState => ({...prevState,
        unin_tipo,
        pessoa
      }))

      try {

        const resp = await axios.get(`${window._env_.REACT_APP_API_URL}/funcionarios/${funcionarioId}`, getToken())

        setState(prevState => ({...prevState,
          funcionario: resp.data
        }))

        const { data: contratos } = await axios.get(`${window._env_.REACT_APP_API_URL}/funcionarios/contratos/${funcionarioId}`, getToken())

        setState(prevState => ({...prevState,
          contratos: {
            list: contratos
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { funcionario, permissoesPromover } = state
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState({openModalAjuda: true})}}>
            <div>
              {funcionario.fisica &&
                <TabViews permissoes={state.permissoes}
                  funcionario={funcionario}
                  unin_tipo={state.unin_tipo}
                  pessoa={state.pessoa}
                  contratos={state.contratos.list}
                  permissoesPromover={permissoesPromover}
                  params={{funcionarioId, tab}}
                  navigate={navigate}
                />
              }
            </div>
            <br />
            <br />
          </Main>
          <Nav />
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default ViewFuncionario

// export default class View extends Component {

//   render() {

//   }
// }