import React, { useState, useEffect, createRef } from 'react';
import './TabelaPreco.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import FormTabelaPreco from '../../components/forms/FormTabelaPreco'
import SaveIcon from '@mui/icons-material/Save';
import Loading from './../../components/templates/Loading'
import Table from '../../components/Table'
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import moment from 'moment';
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
  tabelaPreco: {
    id: "",
    pessoa_id: "",
    unidade_id: "",
    descricao: "",
    data_inicio: "",
    data_fim: "",
    padrao: false,
    itens: []
  },
  produto: {
    _id: "",
    descricao: "",
    produto_id: "",
    sub_grupo_id: "",
    grupo_id: "",
    valor_maximo: "",
    valor_minimo: "",
    index: "",
    ativo: true
  },
  produto_selecionado: {
    _id: "",
    descricao: "",
    codigo: "",
  },
  cabecalhoTabela: [
    { id: 'codigo', numeric: false, disablePadding: true, label: 'Cod. Barras' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Produto' },
    { id: 'sub_grupo', numeric: false, disablePadding: true, label: 'Sub Grupo' },
    { id: 'valor_minimo', numeric: false, disablePadding: false, label: 'Preço Mínimo' },
    { id: 'valor_maximo', numeric: false, disablePadding: false, label: 'Preço Máximo' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: ['editar', 'removeTabela'],
  tabelaPrecos: {
    list: []
  },
  produtos: {
    list: []
  },
  produtosRemovidos: {
    list: []
  },
  unidades: {
    list: []
  },
  grupoProdutos: {
    list: []
  },
  subGrupoProdutos: {
    list: []
  },
  tipo: "",
  unidadesSelecionadas: [],
  loading: true,
  mensagemLoading: 'Carregando Tabelas de Preço...',
  update: false,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: '',
  filtro: {
    codigo: "",
    descricao: "",
    sub_grupo: ""
  },
  term: "",
  itemFiltro: "",
  itens_tabela_filtrado: {
    list: []
  }
}

function mascaraValor(valor) {
  valor = valor.toString().replace(/\D/g, "");
  valor = valor.toString().replace(/(\d)(\d{8})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{5})$/, "$1.$2");
  valor = valor.toString().replace(/(\d)(\d{2})$/, "$1,$2");
  return valor
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

const CadastroTabela = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { tabelaPrecoProdutoId } = useParams();
  const inputRef = createRef();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = async (event) => {
    const tabelaPreco = { ...state.tabelaPreco }

    const name = event.target.name
    const value = event.target.value

    if(name === 'unidade_id'){
      await consultarGrupos(parseInt(value))
      await consultarSubGrupos(parseInt(value))
    }

    tabelaPreco[name] = value
    setState(prevState => ({...prevState, tabelaPreco }))
  }

  const consultaProdutos = async (unidade_id, sub_grupo_id) =>{
    try {
      const dados = {
        unidadesnegocio: [unidade_id],
        sub_grupo_id
      }

      const { data: produtosAux }  = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, getToken())
      
      const produtos = []
      produtosAux.filter(param => param.destaque).forEach(produto => {
        produtos.push({
          id: produto.id,
          mid_caminho: produto.midia ? produto.midia.mid_caminho : '',
          descricao: produto.descricao,
          codigo: produto.codigo,
          unidademedida: produto.unidademedida,
          subgrupdescricao: produto.subgrupoproduto.subgrupdescricao,
          subgrupid: produto.subgrupoproduto.subgrupid,
          grupdescricao: produto.subgrupoproduto.grupoproduto.grupdescricao,
          visibilidade: produto.visibilidade,
          ativo: produto.ativo
        })
      })

      setState(prevState => ({...prevState,
        produtos: {
          list: produtos
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldProduto = (event, value) =>{
    let {produto, produto_selecionado} = state

    if (!value) {
      setState(prevState => ({...prevState,
        produto: {
          _id: "",
          descricao: "",
          codigo: "",
          produto_id: "",
          grupo_id: produto.grupo_id,
          sub_grupo_id: produto.sub_grupo_id,
          valor_maximo: "",
          valor_minimo: "",
          index: "",
          ativo: true
        },
        produto_selecionado: {
          _id: "",
          descricao: "",
          codigo: "",
        }
      }))
      return false
    }
    

    const aux = state.produtos.list.filter(param => param.id === value.id)

    produto.produto_id = value.id
    produto.descricao = aux[0].descricao
    produto.codigo = aux[0].codigo

    produto_selecionado._id = value.id
    produto_selecionado.descricao = aux[0].descricao
    produto_selecionado.codigo = aux[0].codigo
    
    setState(prevState => ({...prevState, produto, produto_selecionado }))
  }

  const updateFieldProdutoAtivo = () => {
    const produto = { ...state.produto }

    produto.ativo = !produto.ativo
    setState(prevState => ({...prevState, produto }))
  }

  const updateFieldProdutoPreco = async (event)=>{
    let {produto, tabelaPreco, produto_selecionado, subGrupoProdutos} = state

    const {name, value} = event.target

    if(name === 'grupo_id'){
      produto.sub_grupo_id = ''
      produto.produto_id = ''
      produto.descricao = ''
      produto.valor_maximo = ""
      produto.valor_minimo = ""
      produto.codigo = ""

      produto_selecionado={
        _id: "",
        descricao: "",
        codigo: ""
      }
    }

    if(name === 'sub_grupo_id'){
      await consultaProdutos(tabelaPreco.unidade_id, parseInt(value))

      produto.produto_id = ''
      produto.descricao = ''
      produto.valor_maximo = ""
      produto.valor_minimo = ""
      produto.codigo = ""
      produto.sub_grupo = subGrupoProdutos.list.filter(param => param.id === parseInt(value))[0].descricao

      produto_selecionado={
        _id: "",
        descricao: "",
        codigo: ""
      }
    }

    produto[name] = value
    setState(prevState => ({...prevState, produto, produto_selecionado }))
  }

  const updateAtivo = (index)=>{
    const tabelaPreco = { ...state.tabelaPreco }
    tabelaPreco.itens[index].ativo = !tabelaPreco.itens[index].ativo
    setState(prevState => ({...prevState, tabelaPreco }))
  }

  const addItem = () => {

    if (!validateFormProduto()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios do Produto (*)."
        }
      }))
      return
    }

    const produto = state.produto

    const precoMinimo = parseFloat(produto.valor_minimo.replace('R$ ', '').replaceAll(".", "").replace(",", "."))
    const precoMaximo = parseFloat(produto.valor_maximo.replace('R$ ', '').replaceAll(".", "").replace(",", "."))
    
    if (precoMaximo < precoMinimo) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "O preço mínimo não pode ser superior ao preço máximo."
        }
      }))
      return
    }

    const tabelaPreco = state.tabelaPreco

    let produtos = state.produtos.list

    const itens = tabelaPreco.itens

    
    if (produto.index !== '') {
      const index = tabelaPreco.itens.findIndex(param => param.index === produto.index)
            
      itens[index] = {
        id: produto.id,
        descricao: produto.descricao,
        codigo: produto.codigo,
        produto_id: produto.produto_id,
        grupo_id: produto.grupo_id,
        sub_grupo_id: produto.sub_grupo_id,
        sub_grupo: produto.sub_grupo,
        valor_maximo: 'R$ ' + mascaraValor(precoMaximo.toFixed(2)),
        valor_minimo: 'R$ ' + mascaraValor(precoMinimo.toFixed(2)),
        ativo: produto.ativo,
        index: produto.index
      }

    } else {
      const index = itens.length

      itens.push({
        id: produto.id,
        descricao: produto.descricao,
        codigo: produto.codigo,
        produto_id: produto.produto_id,
        grupo_id: produto.grupo_id,
        sub_grupo_id: produto.sub_grupo_id,
        sub_grupo: produto.sub_grupo,
        valor_maximo: 'R$ ' + mascaraValor(precoMaximo.toFixed(2)),
        valor_minimo: 'R$ ' + mascaraValor(precoMinimo.toFixed(2)),
        ativo: produto.ativo,
        index
      })
    }

    tabelaPreco.itens = itens

    if(state.term !== ""){
      setState(prevState => ({...prevState,
        itens_tabela_filtrado: {
          list: tabelaPreco.itens.filter(searching(state.term, state.itemFiltro)),
        }
      }))
    }else{
      setState(prevState => ({...prevState,
        itens_tabela_filtrado: {
          list: tabelaPreco.itens,
        }
      }))
    }

    setState(prevState => ({...prevState,
      tabelaPreco,
      produto: initialState.produto,
      produto_selecionado: {
        _id: "",
        descricao: "",
        codigo: ""
      },
      update: false
    }))
  }

  const removerItem = (index) => {
    let {tabelaPreco, itens_tabela_filtrado} = state

    let index_remover = tabelaPreco.itens.findIndex(param => param.index === index)
    let index_remover_filtrado = itens_tabela_filtrado.list.findIndex(param => param.index === index)

    let itens = tabelaPreco.itens

    itens.splice(index_remover, 1)

    let itens_filtrado = itens_tabela_filtrado.list

    itens_filtrado.splice(index_remover_filtrado, 1)

    tabelaPreco.itens = itens
    itens_tabela_filtrado.list = itens_filtrado

    setState(prevState => ({...prevState,
      tabelaPreco,
      itens_tabela_filtrado
    }))
  }

  const editarItem = (index) => {
    const aux = state.tabelaPreco.itens.filter(param => param.index === index)

    setState(prevState => ({...prevState,
      produto: {...aux[0]},
      produto_selecionado: {
        _id: aux[0].produto_id,
        descricao: aux[0].descricao,
        codigo: aux[0].codigo
      },
      update: true
    }))
  }

  const backPage = (timeout = 3000) => {
    const self = this
    setState(prevState => ({...prevState,
      tabelaPreco: {
        id: "",
        pessoa_id: "",
        descricao: "",
        data_inicio: "",
        data_fim: "",
        itens: []
      },
      produto: {
        _id: "",
        descricao: "",
        produto_id: "",
        valor_maximo: "",
        valor_minimo: "",
        ativo: true
      }
    }))
    setTimeout(() => {
      navigate("/tabela_preco");
    }, timeout)
  }

  const validateFormProduto = () => {
    const { produto } = state

    if (!produto.produto_id) return false
    if (!produto.valor_maximo) return false
    if (!produto.valor_minimo) return false

    return true
  }

  const validateForm = () => {
    const { tabelaPreco } = state

    if (!tabelaPreco.descricao) return false
    if (!tabelaPreco.data_inicio) return false
    if (!tabelaPreco.data_fim) return false

    tabelaPreco.itens.forEach(item => {
      if (!item.produto_id) return false
      if (!item.valor_maximo) return false
      if (!item.valor_minimo) return false
    });

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    if (tipo !== 'UNIDADE') {
      if (!tabelaPreco.unidade_id) return false
    }

    return true
  }

  const updateFieldPadrao = () => {
    const {tabelaPreco} = state

    tabelaPreco.padrao = !tabelaPreco.padrao

    setState(prevState => ({...prevState,
      tabelaPreco
    }))
  }

  const salvar = async () => {
    const tabelaPreco = state.tabelaPreco

    if (!validateForm()) {
      setState(prevState => ({...prevState, loading: false }))
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    setState(prevState => ({...prevState, 
      loading: true,
      mensagemLoading: 'Cadastrando/Alterando Tabela de Preço'
    }))

    const date1 = new Date(tabelaPreco.data_inicio);
    const date2 = new Date(tabelaPreco.data_fim);

    if (date1.getTime() > date2.getTime()) {
      setState(prevState => ({...prevState, loading: false }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "A data inicial não pode ser maior que a data final."
        }
      }))

      return
    }

    const itens = tabelaPreco.itens
    let aux = []

    itens.forEach(item => {
      let valor_maximo = item.valor_maximo.replace("R$ ", "");
      valor_maximo = valor_maximo.replace(".", "");
      valor_maximo = valor_maximo.replace(",", ".");
      valor_maximo = parseFloat(valor_maximo)

      let valor_minimo = item.valor_minimo.replace("R$ ", "");
      valor_minimo = valor_minimo.replace(".", "");
      valor_minimo = valor_minimo.replace(",", ".");
      valor_minimo = parseFloat(valor_minimo)

      if (item.id !== "") {
        aux.push({
          id: item.id,
          produto_id: item.produto_id,
          valor_maximo: valor_maximo,
          valor_minimo: valor_minimo,
          ativo: item.ativo
        })
      } else {
        aux.push({
          produto_id: item.produto_id,
          valor_maximo: valor_maximo,
          valor_minimo: valor_minimo,
          ativo: item.ativo
        })
      }
    });

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo
    const unidade_id = pessoa.fisica.funcionario.contrato.unidadetrabalho.id

    let unidades = []

    if (tipo === 'UNIDADE') {
      unidades = [
        {
          unidade_id,
        }
      ]
    } else {
      unidades = [
        {
          id: parseInt(tabelaPreco.unidade_id),
        }
      ]
    }

    const dados = {
      descricao: tabelaPreco.descricao,
      data_inicio: tabelaPreco.data_inicio,
      data_fim: tabelaPreco.data_fim,
      padrao: tabelaPreco.padrao,
      unidades,
      itens: aux
    }

    if(dados.itens.length === 0){
      delete dados.itens
    }

    try {
      if (tabelaPreco.id !== "") {
        await axios.put(`${window._env_.REACT_APP_API_URL}/tabelaPreco/${tabelaPreco.id}`, dados, getToken())
      } else {
        await axios.post(`${window._env_.REACT_APP_API_URL}/tabelaPreco`, dados, getToken())
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        tabelaPreco: {
          id: "",
          pessoa_id: "",
          descricao: "",
          data_inicio: "",
          data_fim: "",
          padrao: false,
          itens: []
        },
        produto: {
          _id: "",
          descricao: "",
          produto_id: "",
          valor_maximo: "",
          valor_minimo: "",
          ativo: true
        },
        loading: false
      }))

      backPage()

    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }

  }

  const handleCloseAlerta =()=> {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const consultarGrupos = async (unidade_id) => {
    try {

      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data: grupoProdutos } = await axios.post(`${window._env_.REACT_APP_API_URL}/grupoProdutos/list`, dados, getToken())

      setState(prevState => ({...prevState,
        grupoProdutos: {
          list: grupoProdutos.filter(u => u.ativo === true).sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarSubGrupos = async (unidade_id) => {
    try {

      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data: subGrupoProdutosAux } = await axios.post(`${window._env_.REACT_APP_API_URL}/subGrupoProdutos/list`, dados, getToken())

      setState(prevState => ({...prevState,
        subGrupoProdutos: {
          list: subGrupoProdutosAux.filter(u => u.ativo === true).sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFiltro = (event) => {
    setState(prevState => ({...prevState, term: event.target.value }))
    let {tabelaPreco, filtro} = state
    const {name, value} = event.target

    const list = tabelaPreco.itens.filter(searching(value, name))

    filtro[name] = value

    setState(prevState => ({...prevState,
      itens_tabela_filtrado: {
        list
      },
      filtro,
      term: value,
      itemFiltro: name
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/tabelaPrecos/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        tabelaPreco: {
          id: "",
          pessoa_id: "",
          unidade_id: "",
          descricao: "",
          data_inicio: "",
          data_fim: "",
          padrao: false,
          itens: []
        },
        produto: {
          _id: "",
          descricao: "",
          produto_id: "",
          sub_grupo_id: "",
          grupo_id: "",
          valor_maximo: "",
          valor_minimo: "",
          index: "",
          ativo: true
        }
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'tabela-precos')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (tabelaPrecoProdutoId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "Você não tem permissão para acessa essa tela!"
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "Você não tem permissão para acessa essa tela!"
            }
          }))

          backPage()
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          const {tabelaPreco} = state
          tabelaPreco.unidade_id = unidades[0].id

          await consultarGrupos(unidades[0].id)
          await consultarSubGrupos(unidades[0].id)
          setState(prevState => ({...prevState,tabelaPreco}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

      setState(prevState => ({...prevState,
        tipo
      }))

      if (tabelaPrecoProdutoId) {
        try {
          const { data: tabelaPreco } = await axios.get(`${window._env_.REACT_APP_API_URL}/tabelaPreco/${tabelaPrecoProdutoId}`, getToken())
          
          await consultaProdutos(tabelaPreco.unidade.id)

          let itens = []

          for (let i = 0; i < tabelaPreco.itens.length; i++) {
            itens.push({
              id: tabelaPreco.itens[i].id,
              descricao: tabelaPreco.itens[i].produto.descricao,
              codigo: tabelaPreco.itens[i].produto.codigo,
              produto_id: tabelaPreco.itens[i].produto_id,
              sub_grupo: tabelaPreco.itens[i].produto.subgrupoproduto.descricao,
              sub_grupo_id: tabelaPreco.itens[i].produto.subgrupoproduto.id,
              grupo_id: tabelaPreco.itens[i].produto.subgrupoproduto.grupoproduto.id,
              grupo: tabelaPreco.itens[i].produto.subgrupoproduto.grupoproduto.descricao,
              valor_maximo: 'R$ ' + mascaraValor(tabelaPreco.itens[i].valor_maximo.toFixed(2)),
              valor_minimo: 'R$ ' + mascaraValor(tabelaPreco.itens[i].valor_minimo.toFixed(2)),
              ativo: tabelaPreco.itens[i].ativo,
              index: i
            })

            let produtos = state.produtos.list

            const index2 = produtos.findIndex(param => param.id === tabelaPreco.itens[i].produto_id)

            if (index2 !== -1) {
              const produtoRemovido = produtos[index2]

              const produtosRemovidos = state.produtosRemovidos.list

              produtosRemovidos.push(produtoRemovido)

              produtos.splice(index2, 1)

              setState(prevState => ({...prevState,
                produtos: {
                  list: produtos
                },
                produtosRemovidos: {
                  list: produtosRemovidos
                }
              }))
            }

          }

          const aux = {
            id: tabelaPreco.id,
            pessoa_id: tabelaPreco.pessoa_id,
            unidade_id: tabelaPreco.unidade.id,
            descricao: tabelaPreco.descricao,
            data_inicio: moment(tabelaPreco.data_inicio).format('YYYY-MM-DD'),
            data_fim: moment(tabelaPreco.data_fim).format('YYYY-MM-DD'),
            padrao: tabelaPreco.padrao,
            itens
          }
        
          setState(prevState => ({...prevState,
            tabelaPreco: aux,
            itens_tabela_filtrado: {
              list: [...itens]
            }
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
          backPage()
          return false
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { tabelaPreco, itens_tabela_filtrado, filtro } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Tabela de Preço</h2>
                </Grid>
              </Grid>
              <FormTabelaPreco
                updateField={e => updateField(e)}
                unidadesSelecionadas={state.unidadesSelecionadas}
                tipo={state.tipo}
                updateFieldProdutoPreco={(e) => updateFieldProdutoPreco(e)}
                updateFieldProduto={(e, value) => updateFieldProduto(e, value)}
                updateFieldProdutoAtivo={(e) => updateFieldProdutoAtivo(e)}
                dados={tabelaPreco}
                produtos={state.produtos.list}
                unidades={state.unidades.list}
                produto={state.produto}
                addItem={(e) => addItem(e)}
                update={state.update}
                updateFieldPadrao={e => updateFieldPadrao()}
                inputRef={inputRef}
                grupoProdutos={state.grupoProdutos.list}
                subGrupoProdutos={state.subGrupoProdutos.list}
                produto_selecionado={state.produto_selecionado}
              />
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Cod. Barras" variant="outlined" size="small" name="codigo" value={filtro.codigo} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Produto/Serviço" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Sub. Grupo" variant="outlined" size="small" name="sub_grupo" value={filtro.sub_grupo} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Table
                  updateAtivo={e => updateAtivo(e)}
                  editar={e => editarItem(e)}
                  remove={e => removerItem(e)}
                  headCell={state.cabecalhoTabela}
                  rows={itens_tabela_filtrado.list.sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0)}
                  acoes={state.acoesTabela}
                  noRemove={true}
                />
              </Grid>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={e => backPage(0)}>Voltar</Button>
                </Grid>
                <Grid item md={8} xs={false} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message}
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Tabela de Preço"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroTabela