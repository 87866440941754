import React, { Component } from 'react'
import './Usuario.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import axios from 'axios'

import { Grid, Button, TextField } from '@mui/material'

import CircularProgress from '@mui/material/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import ModalTrocaSenha from './../../components/modals/TrocarSenha'
import moment from 'moment'
import verificaForcaDaSenha from './../../services/verificaForcaSenha'

const initialState = {
  dadosUsuario: {},
  usuario: {},
  perfil: {},
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  modalTrocaSenha: false,
  usuarioEdit: {
    senhaAtual: '',
    novaSenha: '',
    repetirSenha: ''
  },
  showPassword: false,
  showPasswordNova: false,
  showPasswordRepetir: false,
  nivelSenha: 'Muito Fraca',
  corSenha: 'red',
}

export default class Aluno extends Component {
  state = { ...initialState }
  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    this.setState({
      usuarioEdit: {
        senhaAtual: '',
        novaSenha: '',
        repetirSenha: ''
      },
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const usuario = JSON.parse(localStorage.getItem('usuario'))
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    this.setState({
      dadosUsuario: pessoa,
      usuario: usuario,
      perfil: perfil
    })

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleCloseTrocarSenha() {
    this.setState({
      modalTrocaSenha: !this.state.modalTrocaSenha
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  openTrocarSenha() {
    this.setState({
      modalTrocaSenha: true
    })
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleClickShowPasswordNova() {
    this.setState({
      showPasswordNova: !this.state.showPasswordNova
    })
  }

  handleClickShowPasswordRepetir() {
    this.setState({
      showPasswordRepetir: !this.state.showPasswordRepetir
    })
  }

  async updateFieldUsuario(event) {
    const { usuarioEdit } = this.state

    usuarioEdit[event.target.name] = event.target.value

    if (event.target.name === 'novaSenha') {
      const resp = await verificaForcaDaSenha(event.target.value)
      this.setState({
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      })
    }
    this.setState({ usuarioEdit })
  }

  validateForm() {
    const { usuarioEdit } = this.state

    if (!usuarioEdit.senhaAtual) return false
    if (!usuarioEdit.novaSenha) return false
    if (!usuarioEdit.repetirSenha) return false

    return true
  }

  async trocarSenha() {
    const { usuarioEdit, nivelSenha, usuario } = this.state

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Preencher todos os campos!'
      })
      return
    }

    if (usuarioEdit.novaSenha !== usuarioEdit.repetirSenha) {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Senhas não conferem!'
      })
      return
    }

    if (nivelSenha === 'Média' || nivelSenha === 'Forte') {
      try {
        await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/trocarSenhaLogado/${usuario.usuario_id}`, usuarioEdit)

        window.location.reload()

      } catch ({ response }) {
        this.handleOpenDialog({
          titulo: 'Opps',
          descricao: response.data.message
        })
        return
      }
    } else {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Senha Fraca!'
      })
      return
    }
  }

  render() {
    const { dadosUsuario, usuario, perfil } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Dados Usuário...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div id='view'>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12} xs={12}>
                  <h1 className="titulo">Meus Dados</h1>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={6} xs={12} sm={6}>
                  <TextField className="input" label="Nome" variant="outlined" size="small" value={dadosUsuario.fisica.nome} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField className="input" label="CPF" variant="outlined" size="small" value={dadosUsuario.fisica.cpf} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField className="input" label="Data Nascimento" variant="outlined" size="small" value={moment(dadosUsuario.fisica.datanascimento).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} xs={12} sm={3}>
                  <TextField className="input" label="RG" variant="outlined" size="small" value={dadosUsuario.fisica.rg} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField className="input" label="Data Emissão RG" variant="outlined" size="small" value={moment(dadosUsuario.fisica.rg_data_emissao).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField className="input" label="Orgão Emissor" variant="outlined" size="small" value={dadosUsuario.fisica.rg_orgao_emissor} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField className="input" label="Estado Emissor" variant="outlined" size="small" value={dadosUsuario.fisica.rg_uf} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="titulo">Endereços:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  {dadosUsuario.pessoa.enderecos.map(endereco => {
                    return (
                      <div key={endereco.id} className='cardEndereco'>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} xs={12} sm={3}>
                            <TextField className="input" label="CEP" variant="outlined" size="small" value={endereco.num_cep} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={4} xs={12} sm={3}>
                            <TextField className="input" label="Rua" variant="outlined" size="small" value={endereco.logradouro} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={3}>
                            <TextField className="input" label="Número" variant="outlined" size="small" value={endereco.numero} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={3} xs={12} sm={3}>
                            <TextField className="input" label="Complemento" variant="outlined" size="small" value={endereco.complemento} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={4} xs={12} sm={3}>
                            <TextField className="input" label="Bairro" variant="outlined" size="small" value={endereco.bairro} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={3} xs={12} sm={3}>
                            <TextField className="input" label="Cidade" variant="outlined" size="small" value={endereco.cidade} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={3} xs={12} sm={3}>
                            <TextField className="input" label="Estado" variant="outlined" size="small" value={endereco.estado} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={3}>
                            <TextField className="input" label="Endereço Principal" variant="outlined" size="small" value={endereco.principal ? 'Sim' : 'Não'} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="titulo">Telefones:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  {dadosUsuario.pessoa.telefones.map(telefone => {
                    return (
                      <div key={telefone.id} className='cardEndereco'>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={5} xs={12} sm={3}>
                            <TextField className="input" label="Telefone" variant="outlined" size="small" value={telefone.numero} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={5} xs={12} sm={3}>
                            <TextField className="input" label="Tipo" variant="outlined" size="small" value={telefone.tipo} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={3}>
                            <TextField className="input" label="Telefone Principal" variant="outlined" size="small" value={telefone.principal ? 'Sim' : 'Não'} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="titulo">Emails:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  {dadosUsuario.pessoa.emails.map(email => {
                    return (
                      <div key={email.id} className='cardEndereco'>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={10} xs={12} sm={6}>
                            <TextField className="input" label="Email" variant="outlined" size="small" value={email.email} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={6}>
                            <TextField className="input" label="Email Principal" variant="outlined" size="small" value={email.principal ? 'Sim' : 'Não'} InputLabelProps={{ shrink: true }} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="titulo">Usuário:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Perfil" variant="outlined" size="small" value={perfil.descricao} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Login" variant="outlined" size="small" value={usuario.login} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <Button className="btnCadastrar" variant="contained" color="primary" onClick={e => this.openTrocarSenha()}>
                    Trocar Senha
								  </Button>
                </Grid>
              </Grid>
              <br />
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalTrocaSenha
            open={this.state.modalTrocaSenha}
            handleClose={e => this.handleCloseTrocarSenha(e)}
            usuario={this.state.usuarioEdit}
            showPassword={this.state.showPassword}
            showPasswordNova={this.state.showPasswordNova}
            showPasswordRepetir={this.state.showPasswordRepetir}
            handleClickShowPassword={e => this.handleClickShowPassword(e)}
            handleClickShowPasswordNova={e => this.handleClickShowPasswordNova(e)}
            handleClickShowPasswordRepetir={e => this.handleClickShowPasswordRepetir(e)}
            updateField={e => this.updateFieldUsuario(e)}
            nivelSenha={this.state.nivelSenha}
            corSenha={this.state.corSenha}
            trocarSenha={e => this.trocarSenha(e)} />
        </Main>
         <Nav history={this.props.history}/>
      </div>
    )
  }
}