import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 14, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: true,
};

const CurrencyInput = React.forwardRef(function CurrencyInput(props, ref) {
  const { maskOptions, ...inputProps } = props;
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions
  });

  return (
    <MaskedInput
      {...inputProps}
      ref={(inputElement) => {
        if (inputElement && typeof ref === 'function') {
          ref(inputElement.inputElement);
        } else if (inputElement && ref) {
          ref.current = inputElement.inputElement;
        }
      }}
      mask={currencyMask}
    />
  );
});

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
};

CurrencyInput.propTypes = {
  inputMode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.number,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any
    })
  ])
};

export default CurrencyInput;