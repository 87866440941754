import React, { useState, useEffect } from 'react';
import './ItemReceitaDespesa.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Alert from './../../components/templates/Alert'
import { Grid, Button, TextField } from '@mui/material'
import Table from '../../components/Table'
import ModalCadastro from './../../components/modals/Cadastro/ItemReceitaDespesa'
import axios from 'axios'
import { styled } from '@mui/system';
import Color from 'color';
import ModalConfirmacao from './../../components/modals/Confirmacao'
import Autocomplete from '@mui/material/Autocomplete';
import ModalAjuda from './../../components/modals/Ajuda'
import ModalAjudaCadastro from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  loading: true,
  mensagemLoading: 'Carregando Itens de Receita e Despesa...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  itemReceitaDespesa:{
    id: '',
    descricao: '',
    planocontas_id: '',
    unidade_id: '',
    tipo: '',
    ativo: true
  },
  planoContaSelecionado:{
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  },
  planoContaSelecionadoFiltro: {
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  },
  planos:{
    list: []
  },
  unidades: {
    list: []
  },
  itens: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'planocontas', numeric: false, disablePadding: true, label: 'Plano de Contas' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  filtro: {
    tipo_item: '',
    descricao: '',
    plano_contas_id: '',
    ativo: true
  },
  modal: {
    mensagem: '',
    row: null
  },
  openModalAjuda: false,
  markdown: '',
  openModalAjudaCadastro: false,
  markdownCadastro: ''
}

const ItemReceitaDespesa = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultaItens = async() => {
    try {
      let {filtro, planoContaSelecionadoFiltro} = state 

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      filtro.unidadesnegocio = acesso_atual.map(acesso => {
        return acesso.id
      })

      if(planoContaSelecionadoFiltro.id !== ''){
        filtro.plano_contas_id = planoContaSelecionadoFiltro.id
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, filtro, getToken())

      const itens = []

      data.sort((a, b) => (a.plano_contas.codigo > b.plano_contas.codigo) ? 1 : (b.plano_contas.codigo > a.plano_contas.codigo) ? -1 : 0).forEach(item => {
        itens.push({
          id: item.id,
          descricao: item.descricao,
          planocontas: item.plano_contas.codigo + ' - ' + item.plano_contas.descricao,
          sequencia: item.plano_contas.sequencia,
          tipo: item.plano_contas.tipo === 'DESPESA' ? 'Despesa' : 'Receita',
          planocontas_id: item.plano_contas.id,
          codigo: item.plano_contas.codigo,
          unidade_id: item.plano_contas.unidade_id,
          ativo: item.ativo
        })
      })

      setState(prevState => ({...prevState,
        itens: {
          list: itens
        },
        loading: false
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultaPlanoContas = async (unidade_id, tipo) =>{
    try {
  
      const dados = {
        unidadesnegocio: [parseInt(unidade_id)],
        tipo: tipo === 'PAGAR' ? "DESPESA" : "RECEITA"
      }
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/list`, dados, getToken())

      let planoContas = data
        .sort((a, b) => (parseInt(a.codigo) > parseInt(b.codigo)) ? 1 : ((parseInt(b.codigo) > parseInt(a.codigo)) ? -1 : 0))
        .filter(param => param.ativo === true)
        .map(param => {
          return {
            id: param.id,
            codigo: param.codigo,
            descricao: param.descricao,
            ativo: param.ativo,
            slug: param.slug,
            centro_custos: param.centro_custos,
            sequencia: param.sequencia,
            tipo: param.tipo,
            unidade_id: param.unidade_id,
            totalizador: param.totalizador
          }
        })
        
      setState(prevState => ({...prevState,
        planos: {
          list: planoContas
        },
      }))

      return planoContas

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const openCadastro = () => {
    setState(prevState => ({...prevState,
      openModalCadastro: true,
      itemReceitaDespesa:{
        id: '',
        descricao: '',
        planocontas_id: '',
        planocontas: '',
        unidade_id: state.itemReceitaDespesa.unidade_id,
        tipo: '',
        ativo: true
      }
    }))
  }

  const handleCloseModalCadastro = async() => {
    await consultaItens()

    setState(prevState => ({...prevState,
      openModalCadastro: false,
      itemReceitaDespesa:{
        id: '',
        descricao: '',
        planocontas_id: '',
        planocontas: '',
        unidade_id: state.itemReceitaDespesa.unidade_id,
        tipo: '',
        ativo: true
      },
      planoContaSelecionado:{
        id: '',
        codigo: '',
        descricao: '',
        ativo: '',
        slug: '',
        centro_custos: '',
        totalizador: ''
      }
    }))
  }

  const updateFieldPlanoContas = (e, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        planoContaSelecionado: {
          id: '',
          codigo: '',
          descricao: '',
          ativo: '',
          slug: '',
          centro_custos: '',
          totalizador: ''
        }
      }))

      return false
    } 

    let {itemReceitaDespesa} = state

    itemReceitaDespesa.planocontas_id = value.id

    setState(prevState => ({...prevState,
      planoContaSelecionado: value
    }))
  }

  const updateFieldPlanoContasFiltro = (e, value) => {
    if(!value){
      let {filtro} = state

      filtro.plano_contas_id = ''

      setState(prevState => ({...prevState,
        planoContaSelecionadoFiltro: {
          id: '',
          codigo: '',
          descricao: '',
          ativo: '',
          slug: '',
          centro_custos: '',
          totalizador: ''
        },
        filtro
      }))

      return false
    } 

    let {itemReceitaDespesa} = state

    itemReceitaDespesa.planocontas_id = value.id

    setState(prevState => ({...prevState,
      planoContaSelecionadoFiltro: value
    }))
  }

  const updateField = async (event) => {
    const {itemReceitaDespesa} = state

    const name = event.target.name
    const value = event.target.value

    itemReceitaDespesa[name] = value
    setState(prevState => ({...prevState,itemReceitaDespesa}))

    if(name === 'tipo'){
      await consultaPlanoContas(itemReceitaDespesa.unidade_id, value)
    }

  }

  const updateFieldAtivo = () => {
    let {itemReceitaDespesa} = state
    itemReceitaDespesa.ativo = !itemReceitaDespesa.ativo
    setState(prevState => ({...prevState,itemReceitaDespesa}))
  }

  const editarItem = async (row) => {
    let planos = await consultaPlanoContas(row.unidade_id, row.tipo === "Receita" ? "RECEBER": "PAGAR")
    
    const planoContaSelecionado = planos.filter(param => param.id === row.planocontas_id)[0]

    row.tipo = row.tipo === "Receita" ? "RECEBER": "PAGAR"

    setState(prevState => ({...prevState,
      planoContaSelecionado,
      itemReceitaDespesa: {...row},
      openModalCadastro: true,
      alterar: true
    }))
  }

  const salvar = async (value) =>{
    if(value){
      const {itemReceitaDespesa, itens, planos} = state

      if(itemReceitaDespesa.planocontas_id === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o plano de contas!'
          }
        }))
        return
      }

      if(itemReceitaDespesa.descricao === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a descrição!'
          }
        }))
  
        document.getElementById('descricao').focus();
        return
      }

      try {

        setState(prevState => ({...prevState,
          loading: true,
          mensagemLoading: 'Cadastrando/Alterando Item'
        }))

        const dados = {
          planocontas_id: itemReceitaDespesa.planocontas_id,
          descricao: itemReceitaDespesa.descricao,
          ativo: itemReceitaDespesa.ativo
        }

        if(itemReceitaDespesa.id !== ''){
          await axios.put(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/${itemReceitaDespesa.id}`, dados, getToken())

          const plano = planos.list.filter(param => param.id === itemReceitaDespesa.planocontas_id)[0]

          itens.list[itens.list.findIndex(param => param.id === itemReceitaDespesa.id)] = {
            id: itemReceitaDespesa.id,
            descricao: itemReceitaDespesa.descricao,
            planocontas: plano.codigo + ' - ' + plano.descricao,
            planocontas_id: plano.id,
            tipo: plano.tipo === 'DESPESA' ? 'Despesa' : 'Receita',
            sequencia: plano.sequencia,
            codigo: plano.codigo,
            unidade_id: plano.unidade_id,
            ativo: itemReceitaDespesa.ativo
          }

        }else{
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa`, dados, getToken())
        
          itens.list.push({
            id: data.id,
            descricao: data.descricao,
            planocontas: data.plano_contas.codigo + ' - ' + data.plano_contas.descricao,
            planocontas_id: data.plano_contas.id,
            tipo: data.plano_contas.tipo === 'DESPESA' ? 'Despesa' : 'Receita',
            codigo: data.plano_contas.codigo,
            sequencia: data.plano_contas.sequencia,
            unidade_id: data.plano_contas.unidade_id,
            ativo: data.ativo
          })
        }

        setState(prevState => ({...prevState,
          itens: {
            list: itens.list.sort((a, b) => (a.codigo > b.codigo) ? 1 : (b.codigo > a.codigo) ? -1 : 0),
          },
          alerta: {
            open: true,
            severity: 'success',
            message: itemReceitaDespesa.id !== '' ? 'Cadastro atualizado com sucesso' : 'Cadastro realizado com sucesso.'
          },
          loading: false
        }))
  
        handleCloseModalCadastro()
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loading: false
        }))
      }

    }else{
      handleCloseModalCadastro()
    }
  }

  const updateFiltro = async (event) => {
    let {filtro} = state

    let name = event.target.name
    let value = event.target.value

    filtro[name] = value

    if(name === 'tipo_item'){

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      await consultaPlanoContas(acesso_atual[0].id, value === 'DESPESA' ? 'PAGAR': 'RECEBER')
    }

    setState(prevState => ({...prevState,filtro}))
  }

  const filtrar = async () => {
    await consultaItens()
  }

  const removeItem = async(row) => {

    setState(prevState => ({...prevState,
      modalConfirmacaoOpen: true,
      modal: {
        mensagem: `Deseja realmente deletar o item: ${row.descricao}.`,
        row
      }
    }))
    return
  }

  const confirmarFecharModal = async (value) => {
    if(value){
      try {
        let {itens, modal} = state
  
        let row = modal.row

        await axios.delete(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/${row.id}`, getToken())
  
        itens.list.splice(itens.list.findIndex(param => param.id === row.id), 1)
  
        setState(prevState => ({...prevState,
          itens,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Item deletado'
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      handleClose()
    }
  }

  const handleClose = () => {
    setState(prevState => ({...prevState,
      modalConfirmacaoOpen: false,
      modal: {
        mensagem: '',
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/Listagem.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const helpPathCadastro = require("./../../help/itemReceitaDespesa/Cadastro.md")

      fetch(helpPathCadastro)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdownCadastro: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'item-receita-despesa')[0]

      let acoesTabela = []

      if (permissoes.alterar) {
        acoesTabela.push('update')
      }

      if (permissoes.deletar) {
        acoesTabela.push('removeItem')
      }

      setState(prevState => ({...prevState,
        acoesTabela,
        permissoes
      }))
      
      try {
        await consultaItens()

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              descricao = `${unidade.numero} - ${unidade.descricao}`
            
              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }

            })
          }
        }))

        if(unidades.length === 1){
          const {itemReceitaDespesa} = state
          itemReceitaDespesa.unidade_id = unidades[0].id
          setState(prevState => ({...prevState,itemReceitaDespesa}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState(prevState => ({...prevState,loading: false}))
    };
    
    fetchData();
  }, []);

  const { permissoes, planos, unidades, planoContaSelecionado, planoContaSelecionadoFiltro, itens, filtro } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={10} xs={12} sm={8}>
                  <h1 className="titulo">Itens Receita/Despesa</h1>
                </Grid>
                {permissoes.inserir &&
                  <Grid item md={2} xs={12} sm={4}>
                    <Button onClick={() => openCadastro()} size="small" className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Item
                    </Button>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={2} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tipo Item"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tipo_item"
                    value={filtro.tipo_item}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    <option value="RECEITA">Receita</option>
                    <option value="DESPESA">Despesa</option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => updateFieldPlanoContasFiltro(event, value)}
                    freeSolo
                    options={planos.list.filter(param => !param.totalizador)}
                    value={planoContaSelecionadoFiltro}
                    getOptionLabel={option => `${option.codigo} - ${option.descricao}`}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Plano de Contas*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${planoContaSelecionado.codigo} - ${planoContaSelecionado.descricao}`} />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Ativo"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="ativo"
                    value={filtro.ativo}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </TextField>
                </Grid>
                <Grid item md={1} xs={12} sm={3}>
                  <Button color='warning' size='small' fullWidth variant="contained" onClick={() => filtrar()} disabled={state.loadingImprimir}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate={(e) => editarItem(e)}
                    headCell={state.cabecalhoTabela} 
                    rows={itens.list} 
                    acoes={state.acoesTabela} 
                    remove={(e) => removeItem(e)}
                  />
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
          <ModalCadastro
            open={state.openModalCadastro}
            handleClose={e => handleCloseModalCadastro()} 
            updateField={(e) => updateField(e)}
            updateFieldAtivo={(e) => updateFieldAtivo(e)}
            dados={state.itemReceitaDespesa}
            planos={planos.list}
            unidades={unidades.list}
            planoContaSelecionado={planoContaSelecionado}
            updateFieldPlanoContas={(e, value) => updateFieldPlanoContas(e, value)}
            confirmar={(e) => salvar(e)}
            loadingSalvar={state.loadingSalvar}
            openModalAjudaCadastro={() => {setState(prevState => ({...prevState,openModalAjudaCadastro: true}))}}
          />
          <ModalConfirmacao 
            open={state.modalConfirmacaoOpen} 
            handleClose={e => handleClose(e)} 
            dados={state.modal} 
            confirmar={e => confirmarFecharModal(e)}
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Itens de Receita e Despesa"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
      <ModalAjudaCadastro 
        open={state.openModalAjudaCadastro}
        tela={"Cadastro de Itens de Receita e Despesa"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjudaCadastro: false}))}}
        markdown={state.markdownCadastro}
      />
    </React.Fragment>
  )
}

export default ItemReceitaDespesa