export default {
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  cliente: {
    tipoPessoa: 'FISICA',
    fisica: {
      cpf: '',
      nome: '',
      sobrenome: '',
      telefone: '55',
      data_nascimento: '',
      email: '',
      dadosComplementar: {
        sexo: '',
        num_calcado: '',
        receber_novidades: false,
        quantidade_filhos: '',
        profissao: '',
        data_casamento: '',
        rg: '',
        rg_orgao_emissor: '',
        rg_uf: '',
        rg_data_emissao: '',
        como_conheceu_id: '',
        estado_civil: '',
        colegio: '',
        nome_pai: '',
        nome_mae: '',
        cnh_cliente: '',
        nome_conjugue: '',
        cpf_conjugue: '',
        rg_conjugue: '',
        rg_orgao_emissor_conjugue: '',
        rg_data_emissao_conjugue: '',
        data_nascimento_conjugue: '',
        email_conjugue: '',
        telefone_conjugue: '55',
      }
    },
    juridica: {
      cnpj: '',
      razao_social: '',
      nome_fantasia: '',
      data_fundacao: '',
      telefone: '',
      email: '',
    },
    endereco:{
      cep_id: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: ''
    },
    files: {
      doc_rg_frente: [],
      doc_rg_verso: [],
      doc_cnh_frente: [],
      doc_cnh_verso: [],
      doc_comprovante_renda: [],
      doc_comprovante_endereco: [],
    }
  },
  dadosComplementaresPF: {
    list: []
  },
  configuracao_unidade: {
    midia: {
      id: '',
      key: ''
    }
  },
  metodos_divugacoes:{
    list: []
  },
  loadingSalvar: false,
  cadastrado: false,
}