import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PanToolIcon from '@mui/icons-material/PanTool';
import PrintIcon from '@mui/icons-material/Print';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import {Button, TextField, Checkbox } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DescriptionIcon from '@mui/icons-material/Description';
import real from './../services/real'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    color: '#FFF',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#31353D',
  },
}));

const Root = styled('div')({
  width: '100%',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const VisuallyHidden = styled('div')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
});

const renderTooltipDep = (row, exibir_ids_pessoas) => {
  const rows = []

  if(row.listDependentes){
    if(row.listDependentes.length > 0){
      for (let i = 0; i < row.listDependentes.length; i++) {
        rows.push(<p style={{margin: 3, fontSize: 16}} key={i}><b>{`${exibir_ids_pessoas ? `${row.listDependentes[i].pessoa_id} - ` : ''}${row.listDependentes[i].nome}`}</b></p>)
      }
    }
  }
  
  return rows
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  let stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  stabilizedThis = stabilizedThis.map((el) => el[0]);

  return stabilizedThis
}

function renderClassFinanceiro(param) {
  if (param === 'VENCIDO') {
    return {
      backgroundColor: '#FFB6C1	'
    }
  } else if (param === 'A VENCER') {
    return {
      backgroundColor: '#FFFFE0'
    }
  } else if (param === 'BAIXADO') {
    return {
      backgroundColor: '#98FB98'
    }
  } else if (param === 'CANCELADO' || param === 'ESTORNADO') {
    return {
      backgroundColor: '#FFFAFA'
    }
  }
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, acoes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = props.headCell

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map(headCell => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.habilitaFiltro &&
                <React.Fragment>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={(orderBy === headCell.id || orderBy === headCell.orderBy) ? order : 'asc'}
                    onClick={createSortHandler(headCell.orderBy || headCell.id)}
                    // style={{width: '30%', marginTop: '7px'}}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <VisuallyHidden>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </VisuallyHidden>
                    ) : null}
                  </TableSortLabel>
                  <br />
                  {headCell.filtro === 'descricao' &&
                    <TextField placeholder='Pesquisar' className="input" variant="outlined" size="small" name="descricao" value={props.filtros_tela.descricao} onChange={e => props.updateFiltroDescricao(e)} InputLabelProps={{ shrink: true }} />
                  }
                  {headCell.filtro === 'forma' &&
                    <TextField placeholder='Pesquisar' className="input" variant="outlined" size="small" name="forma_pgto" value={props.filtros_tela.forma_pgto} onChange={e => props.updateFiltroDescricao(e)} InputLabelProps={{ shrink: true }} />
                  }
                  {headCell.filtro === 'valor' &&
                    <TextField placeholder='Pesquisar' className="input" variant="outlined" size="small" name="valor" value={props.filtros_tela.valor} onChange={e => props.updateFiltroDescricao(e)} InputLabelProps={{ shrink: true }} />
                  }
                </React.Fragment>
              }
              {!headCell.habilitaFiltro &&
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={(orderBy === headCell.id || orderBy === headCell.orderBy) ? order : 'asc'}
                  onClick={createSortHandler(headCell.orderBy || headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              }
            </StyledTableCell>
          )
        })}
        {acoes.length === 1 &&
          <StyledTableCell style={{ minWidth: 60, width: 60 }} align={'center'}>
            <span>Ações</span>
          </StyledTableCell>
        }
        {acoes.length === 2 &&
          <StyledTableCell style={{ minWidth: 100, width: 100 }} align={'center'}>
            <span>Ações</span>
          </StyledTableCell>
        }
        {acoes.length === 3 &&
          <StyledTableCell style={{ minWidth: 140, width: 140 }} align={'center'}>
            <span>Ações</span>
          </StyledTableCell>
        }
        {acoes.length === 4 &&
          <StyledTableCell style={{ minWidth: 180, width: 180 }} align={'center'}>
            <span>Ações</span>
          </StyledTableCell>
        }
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const { acoes, noRemove, headCell, rows, conciliar, ordem, ordernar, heightRows, idUnidadeTrabalho, registrarRows, loadingTable } = props

  const [order, setOrder] = React.useState(ordem || 'asc');
  const [orderBy, setOrderBy] = React.useState(ordernar || '');
  const [selected] = React.useState([]);
  const [page] = React.useState(0);
  // const [dense] = React.useState(false);
  const [rowsPerPage] = React.useState(props.qtdLinhas === 0 ? 1 : ( props.qtdLinhas ? props.qtdLinhas : props.filtro.offset));

  const habilitaRodape = props.desabilitaRodape ? false : true

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const rowsTable = stableSort(rows, getComparator(order, orderBy))

  if(registrarRows){
    registrarRows(rowsTable)
  }

  return (
    <Root>
      <StyledPaper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCell={headCell}
              acoes={acoes}
              updateFiltroDescricao={e => props.updateFiltroDescricao(e)}
              filtros_tela={props.filtros_tela}
            />
            <TableBody>
              {loadingTable &&
                <TableRow style={{ height: 140 }}>
                  <TableCell colSpan={12}>
                    <div className="progressCircular" style={{marginTop: 20}}>
                      <CircularProgress />
                    </div>
                    <div className="progressText" style={{marginTop: 30}}>
                      <p>Carregando Contas a Pagar/Receber...</p>
                    </div>
                  </TableCell>
                </TableRow>
              }
              {!loadingTable &&
                <React.Fragment>
                  {stableSort(rows, getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;
    
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          style={renderClassFinanceiro(row.situacao || '')}
                        >
                          {headCell.map((param, key) => {
                            if (row[param.id] === true) {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <span>Sim</span>
                                </TableCell>
                              )
                            } else if (row[param.id] === false) {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <span>Não</span>
                                </TableCell>
                              )
                            } else if (param.id === 'url') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <img className="imagemTable" src={`${window._env_.REACT_APP_API_URL}/static/${row[param.id]}`} alt="foto" />
                                </TableCell>
                              )
                            } else if (param.id === 'url_banco') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <img className="imagemTable" src={row.url_banco || 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt="foto" />
                                </TableCell>
                              )
                            }else if (param.id === 'datavencimento_parcela') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <span>{row.datavencimento_parcela}</span>
                                </TableCell>
                              )
                            } else if (param.id === 'dependentes') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <Tooltip title={renderTooltipDep(row, props.exibir_ids_pessoas)} aria-label="add">
                                    <span style={{cursor: 'pointer'}}>{row[param.id]}</span>
                                  </Tooltip>
                                </TableCell>
                              )
                            } else if (param.id === 'contpr_descricao') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row.pessoa_id &&
                                    <p style={{ marginBottom: 0, marginTop: 0, fontSize: '10px' }}><b>{row.pessoa_nome}</b></p>
                                  }
                                  <span>{row[param.id]}</span>
                                </TableCell>
                              )
                            } else if (param.id === 'qtdAulas') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row[param.id]}
    
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsAulas, 'aluno-aulas-professor', {})} disabled={row[param.id] === 0}>
                                    <SearchIcon />
                                  </IconButton>
    
                                </TableCell>
                              )
                            } else if (param.id === 'qtdAulasNoShow') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row[param.id]}
    
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsAulasNoShow, 'aluno-aulas-professor', {})} disabled={row[param.id] === 0}>
                                    <SearchIcon />
                                  </IconButton>
                                </TableCell>
                              )
                            } else if (param.id === 'matricula') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row[param.id]}
    
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsMatricula, 'contratos-alunos', {})} disabled={row[param.id] === 0}>
                                    <SearchIcon />
                                  </IconButton>
    
                                </TableCell>
                              )
                            } else if (param.id === 'rematricula') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row[param.id]}
    
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsRematricula, 'contratos-alunos', {})} disabled={row[param.id] === 0}>
                                    <SearchIcon />
                                  </IconButton>
    
                                </TableCell>
                              )
                            } else if (param.id === 'valor_caixa') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <p style={{ color: row.tipo === 'SAQUE' ? '#FF0000' : '#006400', textAlign: 'right' }}>R$ {row.valor_caixa}</p>
                                </TableCell>
                              )
                            } else if (param.id === 'total_credito') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <p style={{ color: '#006400', marginBottom: '0px' }}>{row.total_credito}</p>
                                </TableCell>
                              )
                            } else if (param.id === 'total_debito') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <p style={{ color: '#FF0000', marginBottom: '0px' }}>{row.total_debito}</p>
                                </TableCell>
                              )
                            } else if (param.id === 'valor_debito') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <p style={{ color: '#FF0000', marginBottom: '0px' }}>{row.valor_debito}</p>
                                </TableCell>
                              )
                            } else if (param.id === 'valor_credito') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <p style={{ color: '#006400', marginBottom: '0px', textDecoration: row.cancelamento_voucher_id ? 'line-through' : '' }}>{row.valor_credito}</p>
                                </TableCell>
                              )
                            } else if (param.id === 'saldo') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <p style={{ color: '#006400', marginBottom: '0px', textDecoration: row.cancelamento_voucher_id ? 'line-through' : '' }}>{row.saldo}</p>
                                </TableCell>
                              )
                            } else if (param.id === 'valor_recebido') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  R$ {real(row.valor_recebido)}
                                </TableCell>
                              )
                            } else if (param.id === 'valor') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align='right'
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <p style={{ color:row['tipo'] === 'SAQUE' ? '#FF0000' : '#006400', marginBottom: '0px' }}>{row.valor}</p>
                                </TableCell>
                              )
                            } else if (param.id === 'valor_total') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align='right'
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <span style={{ color:row['tipo'] === 'SAQUE' ? '#FF0000' : '#006400', marginBottom: '0px' }}>{row.valor_total}</span>
                                </TableCell>
                              )
                            } else if (param.id === 'valorparcela'){
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align='right'
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row.recorrencia_id &&
                                    <Tooltip title={'Recorrência'} aria-label="add">
                                      <div style={{
                                        backgroundColor: 'red',
                                        width: 18,
                                        height: 18,
                                        textAlign: 'center',
                                        paddingTop: 4,
                                        color: '#FFF',
                                        borderRadius: 10,
                                        fontSize: 12,
                                        position: 'relative',
                                        top: 6
                                      }}>R</div>
                                    </Tooltip>
                                  }
                                  {(!row.recorrencia_id && row.numparcela > 1) &&
                                    <Tooltip title={'Parcelado'} aria-label="add">
                                      <div style={{
                                        backgroundColor: 'blue',
                                        width: 18,
                                        height: 18,
                                        textAlign: 'center',
                                        paddingTop: 4,
                                        color: '#FFF',
                                        borderRadius: 10,
                                        fontSize: 12,
                                        position: 'relative',
                                        top: 6
                                      }}>P</div>
                                    </Tooltip>
                                  }
                                  <span style={
                                    { 
                                      color:row.tipo === 'RECEBER' ? '#006400' : '#FF0000', 
                                      position: 'relative', 
                                      top: row.recorrencia_id ? -10 : ((!row.recorrencia_id && row.numparcela > 1) ? -10 : 0)
                                    }
                                  }>{row.valorparcela}</span>
                                </TableCell>
                              )
                            }else if (param.id === 'multajuros'){
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align='right'
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  <span>{row.multajuros}</span>
                                </TableCell>
                              )
                            } else if (param.id === 'conta_destino') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row.formapagto_descricao === 'Dinheiro' &&
                                    <TextField style={{ marginTop: '8px', marginBottom: '8px' }} className="input" label="Conta*" variant="outlined" size="small" value={props.contasFinanceira.filter(param => param.cofre === true)[0].descricao} InputLabelProps={{ shrink: true }} disabled={true} />
                                  }
                                  {row.formapagto_descricao !== 'Dinheiro' &&
                                    <TextField
                                      style={{ marginTop: '8px', marginBottom: '8px' }}
                                      id="standard-select-currency"
                                      select
                                      variant="outlined"
                                      className="input"
                                      fullWidth
                                      label="Conta*"
                                      size="small"
                                      SelectProps={{
                                        native: true,
                                      }}
                                      name="contfin_id"
                                      value={row.contfin_id}
                                      onChangeCapture={(e) => props.updateFieldConta(e, row.index)}
                                      InputLabelProps={{ shrink: true }}
                                    >
                                      <option value="">--Escolha--</option>
                                      {props.contasFinanceira.filter(param => param.tipo_conta === 'BANCO').sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(conta => {
                                        return (
                                          <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                        )
                                      })}
                                    </TextField>
                                  }
    
                                </TableCell>
                              )
                            } else if (param.id === 'flags') {
                              return (
                                <TableCell
                                  key={key}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte, width: '210px'}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row['flags'].map((flag, key2) => {
                                    return (
                                      <div key={key2} className={`card-flag-${flag.toLowerCase()}`}>{flag}</div>
                                    )
                                  })}
                                </TableCell>
                              )
                            } else if (param.id === 'baixar') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte, width: '30px'}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {(row.aberto && row.habilita !== '') &&
                                    <React.Fragment>
                                      {row.qtd_parcelas === 1 &&
                                        <React.Fragment>
                                          {props.primeiraParcelaSelecionada.formapagto_slug === '' &&
                                            <React.Fragment>
                                              {props.parcelasBaixa.findIndex(param => param === row._id) !== -1 &&
                                                <Tooltip title='Baixar' aria-label="add">
                                                  <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                    <CheckCircleOutlineIcon />
                                                  </IconButton>
                                                </Tooltip>
                                              }
        
                                              {props.parcelasBaixa.findIndex(param => param === row._id) === -1 &&
                                                <Tooltip title='Baixar' aria-label="add">
                                                  <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                    <RadioButtonUncheckedIcon />
                                                  </IconButton>
                                                </Tooltip>
                                              }
                                            </React.Fragment>
                                          }
                                          {(props.primeiraParcelaSelecionada.formapagto_slug !== '' && props.primeiraParcelaSelecionada.formapagto_slug === row.formapagto_slug) &&
                                            <React.Fragment>
                                              {props.parcelasBaixa.findIndex(param => param === row._id) !== -1 &&
                                                <Tooltip title='Baixar' aria-label="add">
                                                  <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                    <CheckCircleOutlineIcon />
                                                  </IconButton>
                                                </Tooltip>
                                              }
        
                                              {props.parcelasBaixa.findIndex(param => param === row._id) === -1 &&
                                                <Tooltip title='Baixar' aria-label="add">
                                                  <IconButton style={{marginLeft: '25px'}} onClick={e => props.selecionarBaixa(row)} color="primary" aria-label="editar">
                                                    <RadioButtonUncheckedIcon />
                                                  </IconButton>
                                                </Tooltip>
                                              }
                                            </React.Fragment>
                                          }
                                        </React.Fragment>
                                      }
                                    </React.Fragment>
                                  }
                                </TableCell>
                              )
                            } else if (param.id === 'data_hora') {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {row[param.id]}
    
                                  <Tooltip title='Alterar Data' aria-label="add">
                                    <IconButton color="primary" aria-label="view" onClick={e => props.alterarData(row)}>
                                      <SwapHorizIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell
                                  key={param.id}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align={param.numeric ? 'right' : 'left'}
                                  style={{fontSize: props.tamanhoFonte}}
                                  padding={param.disablePadding ? 'none' : 'normal'}
                                >
                                  {param.edit &&
                                    <Tooltip title={param.tooltip} aria-label="add">
                                      <Link to={`${param.link}${row._id}`}>
                                        {row[param.id]}
                                      </Link>
                                    </Tooltip>
                                  }
                                  {!param.edit &&
                                    <span style={{textDecoration: row.cancelamento_voucher_id ? 'line-through' : ''}}>
                                      {row[param.id]}
                                    </span>
                                  }
                                </TableCell>
                              )
                            }
                          })}
                          {acoes.length > 0 &&
                            <TableCell align={'center'}>
                              {acoes.map(acao => {
                                if (acao === "view") {
                                  return (
                                    <Tooltip key={acao} title='Visualizar' aria-label="add">
                                      <Link to={`${props.urlView}${row._id}`}>
                                        <IconButton color="primary" aria-label="view">
                                          <VisibilityIcon />
                                        </IconButton>
                                      </Link>
                                    </Tooltip>
                                  )
                                } else if (acao === 'imprimirBoleto'){
                                  if(row.parceiro_bancario_id && (row.situacao === 'A VENCER' ||  row.situacao === 'VENCIDO') && row.tipo === 'RECEBER'){
                                    return (
                                      <Tooltip key={acao} title={row.formapagto_slug === 'boleto' ? 'Imprimir Boleto' : 'Imprimir Pix'} aria-label="add">
                                        <IconButton style={{marginLeft: '10px'}} onClick={e => props.imprimirBoleto(row)} color="primary" aria-label="view">
                                          <PrintIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }else{
                                    return (
                                      <Tooltip key={acao} title={row.formapagto_slug === 'boleto' ? 'Imprimir Boleto' : 'Imprimir Pix'} aria-label="add">
                                        <IconButton style={{marginLeft: '10px'}} disabled aria-label="view">
                                          <PrintIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                } else if (acao === 'imprimirBoletoCliente'){
                                  if(row.tipo_transaction &&row.status !== "Pago" && row.tipo_conta === 'A Pagar'){
                                    return (
                                      <Tooltip title={row.forma_pgto_slug === 'boleto' ? 'Imprimir Boleto' : 'Imprimir Pix'} aria-label="add">
                                        <IconButton onClick={e => props.imprimirBoleto(row)} color="secondary" aria-label="view" disabled={row.status !== 'Pendente' && !row.boleto_transaction_id}>
                                          <PrintIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }else{
                                    return (
                                      <IconButton disabled color="secondary" aria-label="view">
                                        <PrintIcon />
                                      </IconButton>
                                    )
                                  }
                                } else if (acao === 'imprimirNFSE'){
                                  if(row.pedido_id && row.situacao !== 'CANCELADO' && row.situacao !== 'ESTORNADO' && row.liberaNFSE &&
                                  (moment(row.data_inicio_nfse).isBefore(row.datavencimento_sem_format) || row.data_inicio_nfse === row.datavencimento_sem_format)){
                                    return (
                                      <Tooltip key={acao} title={row.nfse_id ? 'Visualizar NFS-e' :'Gerar NFSE'} aria-label="add">
                                        <IconButton 
                                          style={{color: row.nfse_id ? '#063e11': '#f50057', marginLeft: '10px'}} 
                                          onClick={e => props.imprimirNFSE(row)} 
                                          aria-label="view"
                                        >
                                          <DescriptionIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }else{
                                    return (
                                      <Tooltip key={acao} title={'Imprimir NFSE'} aria-label="add">
                                        <IconButton disabled={true} aria-label="view" style={{marginLeft: '10px'}} >
                                          <DescriptionIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                } else if (acao === 'imprimirNFSERel'){
                                  if(row.status === 'Cancelado'){
                                    return (
                                      <Tooltip title={'Imprimir NFSE'} aria-label="add">
                                        <IconButton disabled={true} aria-label="view">
                                          <DescriptionIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }else{
                                    return (
                                      <Tooltip title={'Imprimir NFSE'} aria-label="add">
                                        <IconButton style={{color:'#063e11'}} onClick={e => props.imprimirNFSE(row)} aria-label="view">
                                          <DescriptionIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                }else if(acao === 'cancelarNFSE'){
                                  return (
                                    <Tooltip title={'Cancelar NFSE'} aria-label="add">
                                      <IconButton disabled={row.status !== 'Aprovado'} color='secondary' onClick={e => props.cancelarNFSE(row)} aria-label="view">
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if(acao === 'viewNFSE'){
                                  if(row['parcela_id'] !== ''){
                                    return (
                                      <Tooltip title={'Visualizar Parcela'} aria-label="add">
                                        <Link key={acao} to={`conta_pagar_receber/cadastro/${row['parcela_id']}`}>
                                          <IconButton color="primary" aria-label="view">
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Link>
                                      </Tooltip>
                                    )
                                  }else{
                                    return (
                                      <IconButton color="primary" aria-label="view" disabled={row['parcela_id'] === ''}>
                                        <VisibilityIcon />
                                      </IconButton>
                                    )
                                  }
                                } else if (acao === 'imprimirFichaCliente'){
                                  return (
                                    <Tooltip key={acao} title={'Imprimir Ficha Cadastral do Cliente'} aria-label="add">
                                      <IconButton onClick={e => props.imprimirFichaCliente(row)} color="success" aria-label="view">
                                        <PrintIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === 'selecionarMov'){
                                  if(props.movimentacoes_selecionadas.findIndex(param => param._id === row._id) !== -1){
                                    return (
                                      <Tooltip title={'Desmarcar Movimentação'} aria-label="add">
                                        <IconButton style={{margin: 0}} onClick={e => props.selecionarMov(row)} color="primary" aria-label="view">
                                          <CheckCircleIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }else{
                                    return (
                                      <Tooltip title={'Selecionar Movimentação'} aria-label="add">
                                        <IconButton style={{margin: 0}} onClick={e => props.selecionarMov(row)} color="primary" aria-label="view" disabled={row.conciliado}>
                                          <RadioButtonUncheckedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                }else if (acao === "viewPlano") {
                                  return (
                                    <Tooltip title='Visualizar' aria-label="add">
                                      <IconButton color="primary" aria-label="view" onClick={e => props.viewPlanos(row)}>
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === "viewDemonstrativo") {
                                  return (
                                    <Tooltip title='Visualizar' aria-label="add">
                                      <Link key={acao} to={`${props.urlView}${row['datapagamento_sem_form']}/${row['pesf_id']}/${row['unin_id']}`}>
                                        <IconButton color="primary" aria-label="view">
                                          <VisibilityIcon />
                                        </IconButton>
                                      </Link>
                                    </Tooltip>
                                  )
                                } else if (acao === "viewContrato") {
                                  return (
                                    <Tooltip title='Visualizar' aria-label="add">
                                      <IconButton color="primary" aria-label="view" onClick={e => props.viewContrato(row)}>
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === "viewContratosAlunos") {
                                  return (
                                    <Tooltip title='Visualizar' aria-label="add">
                                      <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsContratos, 'contratos-alunos', row.status)}>
                                        <SearchIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === "viewAgenda") {
                                  return (
                                    <Tooltip title='Visualizar' aria-label="add">
                                      <Link key={acao} to={`${props.urlView}${row.aluno_matricula}/agenda`}>
                                        <IconButton color="primary" aria-label="view">
                                          <VisibilityIcon />
                                        </IconButton>
                                      </Link>
                                    </Tooltip>
                                  )
                                } else if (acao === "viewCliente") {
                                  return (
                                    <Tooltip key={acao} title='Visualizar' aria-label="add">
                                      <Link to={`${props.urlView}${row._id}`}>
                                        <IconButton color="primary" aria-label="view">
                                          <VisibilityIcon />
                                        </IconButton>
                                      </Link>
                                    </Tooltip>
                                  )
                                } else if (acao === "viewDependente") {
                                  return (
                                    <Tooltip title='Visualizar' aria-label="add">
                                      <IconButton color="primary" aria-label="view" onClick={e => props.viewDependente(row)}>
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === "update") {
                                  if (props.tela === 'Perfil') {
                                    if (idUnidadeTrabalho === row.dono_unin_id) {
                                      return (
                                        <Tooltip title='Editar' aria-label="add">
                                          {(() => {
                                            if (typeof props.urlUpdate == 'function') {
                                              return (
                                                <IconButton
                                                  color="secondary"
                                                  aria-label="view"
                                                  onClick={e => props.urlUpdate(row)}
                                                >
                                                  <CreateIcon />
                                                </IconButton>
                                              )
                                            } else {
                                              return (
                                                <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                                  <IconButton color="error" aria-label="view">
                                                    <CreateIcon />
                                                  </IconButton>
                                                </Link>
                                              )
                                            }
                                          })()}
                                        </Tooltip>
                                      )
                                    }
                                  } else {
                                    if (row.descricao === 'Administrador do Sistema') {
                                      return false
                                    } else {
                                      return (
                                        <Tooltip key={acao} title='Editar' aria-label="add">
                                          {(() => {
                                            if (typeof props.urlUpdate == 'function') {
                                              return (
                                                <IconButton
                                                  color="secondary"
                                                  aria-label="view"
                                                  onClick={e => props.urlUpdate(row)}
                                                >
                                                  <CreateIcon />
                                                </IconButton>
                                              )
                                            } else {
                                              return (
                                                <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                                  <IconButton color="error" aria-label="view">
                                                    <CreateIcon />
                                                  </IconButton>
                                                </Link>
                                              )
                                            }
                                          })()}
                                        </Tooltip>
                                      )
                                    }
                                  }
                                } else if (acao === "updateServico") {
                                  if(row['ativo']){
                                    return (
                                      <IconButton
                                        color="primary"
                                        aria-label="view"
                                        onClick={e => props.urlUpdate(row)}
                                      >
                                        <CreateIcon />
                                      </IconButton>
                                    )
                                  }
                                } else if (acao === "conciliar_mov") {
                                  if (row['contfin_conciliavel']) {
                                    if (row['conciliavel']) {
                                      if (row['conciliado']) {
                                        return (
                                          <Tooltip key={acao} title='Desconciliar' aria-label="add">
                                            <IconButton onClick={e => props.conciliarMov(row)} color="primary" aria-label="editar">
                                              <CheckCircleOutlineIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )
                                      } else {
                                        return (
                                          <Tooltip key={acao} title='Conciliar' aria-label="add">
                                            <IconButton onClick={e => props.conciliarMov(row)} color="secondary" aria-label="editar">
                                              <RadioButtonUncheckedIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )
                                      }
                                    }
                                  }
                                } else if (acao === "editar") {
                                  return (
                                    <Tooltip title='Editar' aria-label="add" key={acao}>
                                      <React.Fragment >
                                        <IconButton onClick={e => props.editar(row.index)} color="primary" aria-label="editar">
                                          <CreateIcon />
                                        </IconButton>
                                      </React.Fragment>
                                    </Tooltip>
                                  )
                                } else if (acao === "imprimir") {
                                  return (
                                    <Tooltip title='Imprimir' aria-label="add">
                                      <React.Fragment key={acao}>
                                        <IconButton onClick={e => props.imprimir(row)} color="primary" aria-label="editar">
                                          <PrintIcon />
                                        </IconButton>
                                      </React.Fragment>
                                    </Tooltip>
                                  )
                                } else if (acao === "imprimirDocVers") {
                                  return (
                                    <Tooltip title='Imprimir Doc' aria-label="add">
                                      <React.Fragment key={acao}>
                                        <IconButton onClick={e => props.imprimir(row)} color="primary" aria-label="editar">
                                          <PrintIcon />
                                        </IconButton>
                                      </React.Fragment>
                                    </Tooltip>
                                  )
                                } else if (acao === "desativar") {
                                  if (row.ativo) {
                                    return (
                                      <Tooltip title='Excluir' aria-label="add">
                                        <React.Fragment key={acao}>
                                          <IconButton onClick={e => props.desativar(row)} color="secondary" aria-label="editar">
                                            <CloseIcon />
                                          </IconButton>
                                        </React.Fragment>
                                      </Tooltip>
                                    )
                                  }
                                } else if (acao === "remove") {
                                  return (
                                    <Tooltip title='Remover' aria-label="add">
                                      <React.Fragment key={acao}>
                                        {!noRemove &&
                                          <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                            <DeleteIcon />
                                          </IconButton>
                                        }
                                      </React.Fragment>
                                    </Tooltip>
                                  )
                                } else if (acao === "removeServico") {
                                  if(row['ativo']){
                                    return (
                                      <Tooltip title='Remover' aria-label="add">
                                        <React.Fragment key={acao}>
                                          {!noRemove &&
                                            <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                              <DeleteIcon />
                                            </IconButton>
                                          }
                                        </React.Fragment>
                                      </Tooltip>
                                    )
                                  }
                                } else if (acao === 'removeCentroCusto') {
                                  return (
                                    <Tooltip title='Remover' aria-label="add">
                                      <React.Fragment key={acao}>
                                        {!row._id &&
                                          <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                            <DeleteIcon />
                                          </IconButton>
                                        }
                                      </React.Fragment>
                                    </Tooltip>
                                  )
                                } else if (acao === "estornarProrrogacao") {
                                  if (row.hisp_ativo) {
                                    return (
                                      <Tooltip title='Estornar' aria-label="add">
                                        <IconButton onClick={e => props.estornarProrrogacao(row)} color="secondary" aria-label="remove">
                                          <CloseIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                } else if (acao === "removeTabela") {
                                  return (
                                    <Tooltip title='Remover' aria-label="add">
                                      <React.Fragment key={acao}>
                                        {!row.precprod_id &&
                                          <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                            <DeleteIcon />
                                          </IconButton>
                                        }
                                      </React.Fragment>
                                    </Tooltip>
                                  )
                                } else if (acao === "conciliar") {
                                  return (
                                    <React.Fragment key={acao}>
                                      <Button onClick={e => conciliar(row)} className="btn-conciliar" size="small" variant="contained" color="primary">
                                        Conciliar
                                      </Button>
                                    </React.Fragment>
                                  )
                                } else if (acao === "updateVersaoDoc") {
                                  return (
                                    <Tooltip title='Editar' aria-label="add">
                                      <React.Fragment key={acao}>
                                        <IconButton onClick={e => props.editarVersao(row)} color="secondary" aria-label="editar">
                                          <CreateIcon />
                                        </IconButton>
                                      </React.Fragment>
                                    </Tooltip>
                                  )
                                } else if (acao === "copy") {
                                  return (
                                    <Tooltip title='Copiar' aria-label="add">
                                      <IconButton style={{marginLeft: '10px'}} onClick={e => props.copiarVersao(row)} color="secondary" aria-label="editar">
                                        <FileCopyIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === "horario") {
                                  if (row.aula_tipo_horario === 'FIXO') {
                                    return (
                                      <Tooltip title='Horario das Aulas' aria-label="add">
                                        <Link key={acao} to={`${props.urlHorario}${row._id}`}>
                                          <IconButton color="primary" aria-label="view">
                                            <AccessTimeIcon />
                                          </IconButton>
                                        </Link>
                                      </Tooltip>
                                    )
                                  }
                                } else if (acao === 'selecionar') {
                                  if (props.contrato.contm_tabval_id === row._id) {
                                    return (
                                      <React.Fragment key={acao}>
                                        <Checkbox
                                          checked={true}
                                          onChange={() => props.updateFieldSelectPlano(row)}
                                          color="primary"
                                          inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                      </React.Fragment>
                                    )
                                  } else {
                                    return (
                                      <React.Fragment key={acao}>
                                        <Checkbox
                                          checked={false}
                                          onChange={() => props.updateFieldSelectPlano(row)}
                                          color="primary"
                                          inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                      </React.Fragment>
                                    )
                                  }
                                } else if (acao === 'selectVar') {
                                  return (
                                    <Tooltip title='Selecionar' aria-label="add">
                                      <IconButton onClick={e => props.selecionarVar(row)} color="primary" aria-label="view">
                                        <PanToolIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === 'updateFormaPagto') {
                                  return (
                                    <Tooltip title='Editar Forma de Pagto' aria-label="add">
                                      <IconButton onClick={e => props.editarFormaPagto(row)} color="secondary" aria-label="editar">
                                        <CreateIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === 'corrigeSaldo') {

                                  return (
                                    <Tooltip title='Corrigir Saldo' aria-label="add">
                                      <Link key={acao} to={`${props.urlCorrigeSaldo}${row._id}`}>
                                        <IconButton color="primary" aria-label="view">
                                          <MonetizationOnIcon />
                                        </IconButton>
                                      </Link>
                                    </Tooltip>
                                  )
                                } else if (acao === 'viewAula') {
                                  return (
                                    <Tooltip title='Visualizar aula' aria-label="add">
                                      <IconButton onClick={e => props.viewRow(row, 'aulas-agendadas')} color="primary" aria-label="view">
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === 'viewBaixaAula') {
                                  return (
                                    <Tooltip title='Corrigir Saldo' aria-label="add">
                                      <Link key={acao} to={`${props.urlView}${row.data_aula_sem_form}`}>
                                        <IconButton color="primary" aria-label="view">
                                          <SearchIcon />
                                        </IconButton>
                                      </Link>
                                    </Tooltip>
                                  )
                                } else if (acao === 'transferirCodigo'){
                                  return (
                                    <Tooltip title='Transferir Código' aria-label="add">
                                      <IconButton onClick={e => props.transferirCodigo(row)} color="primary" aria-label="view">
                                        <SwapHorizIcon/>
                                      </IconButton>
                                    </Tooltip>
                                  )
                                } else if (acao === 'pegarPessoa'){
                                  if(row.ativo){
                                    return (
                                      <Tooltip title='Selecionar' aria-label="add">
                                        <IconButton onClick={e => props.selecionarPessoa(row)} color="primary" aria-label="view">
                                          <PanToolIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                } else if (acao === 'openConta'){
                                  return (
                                    <Tooltip title='Visualizar Conta' aria-label="add">
                                      <IconButton onClick={e => props.viewRow(row)} color="primary" aria-label="view">
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                }else {
                                  return false
                                }
    
                                return false
                              })}
                            </TableCell>
                          }
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (heightRows || 24) * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </React.Fragment>
              }

            </TableBody>
          </Table>
        </TableContainer>
        {habilitaRodape &&
          <TablePagination
            labelRowsPerPage='Linhas por Pag.'
            rowsPerPageOptions={[5, 10, 15, 25]}
            component="div"
            count={parseInt(props.count)}
            page={props.filtro.offset}
            onPageChange={(event, newPage) => props.handleChangePage(event, newPage)}
            rowsPerPage={props.filtro.limit}
            onRowsPerPageChange={e => props.handleChangeRowsPerPage(e)}
          />
        }
      </StyledPaper>
    </Root >
  );
}
