import React from 'react'
import real from './../../../services/real'
import { Grid } from '@mui/material'
import moment from 'moment/moment'
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

let ultimo_id = ''
let ultima_cor = '#DCDCDC'
let proxima_cor = '#FFF'
let borda = false

const VoucherCliente = ({ rows, offset, limit, handleChangePage, handleChangeRowsPerPage, qtd, viewVoucher }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={12} xs={12} sm={12}>
        <table className="mg_top_20 sempadding borderTable">
          <thead>
            <tr className="titulos acompanha">
              <th>Cliente</th>
              <th>Data da Compra</th>
              <th>Voucher</th>
              <th>Credito</th>
              <th>Saldo</th>
              <th>Validade Prom.</th>
              <th>Validade Normal</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((value, key) => {
              let last_cor = ultima_cor
              let next_cor = proxima_cor

              if(value.id !== ultimo_id){
                ultimo_id = value.id
                ultima_cor = next_cor
                proxima_cor = last_cor
                borda = true
              }
              
              if(value.pessoa){
                return (
                  <React.Fragment key={key}>
                    <tr style={{
                      backgroundColor: last_cor,
                      border: value.pessoa ? (value.pessoa.cliente.vouchers_cliente.length === 1 ? '1px solid #A9A9A9' : '') : ''
                    }}>
                      <td style={{height: '38px'}} rowSpan={value.pessoa.cliente.vouchers_cliente.length}>{value.nome}</td>
                      <td style={{height: '38px', textDecoration: value.pessoa.cliente.vouchers_cliente[0].voucher.cancelamento_voucher ? 'line-through' : ''}}>{moment(value.pessoa.cliente.vouchers_cliente[0].voucher.data_compra).format('DD/MM/YYYY')}</td>
                      <td style={{height: '38px', textDecoration: value.pessoa.cliente.vouchers_cliente[0].voucher.cancelamento_voucher ? 'line-through' : ''}}>{value.pessoa.cliente.vouchers_cliente[0].voucher.numero} - {value.pessoa.cliente.vouchers_cliente[0].voucher.padrao.nome}</td>
                      <td style={{height: '38px', textDecoration: value.pessoa.cliente.vouchers_cliente[0].voucher.cancelamento_voucher ? 'line-through' : ''}}>R$ {real(value.pessoa.cliente.vouchers_cliente[0].voucher.valor_credito)}</td>
                      <td style={{height: '38px', textDecoration: value.pessoa.cliente.vouchers_cliente[0].voucher.cancelamento_voucher ? 'line-through' : ''}}>R$ {real(value.pessoa.cliente.vouchers_cliente[0].voucher.saldo)}</td>
                      <td style={{height: '38px', textDecoration: value.pessoa.cliente.vouchers_cliente[0].voucher.cancelamento_voucher ? 'line-through' : ''}}>{moment(value.pessoa.cliente.vouchers_cliente[0].voucher.data_valid_prom).format('DD/MM/YYYY')}</td>
                      <td style={{height: '38px', textDecoration: value.pessoa.cliente.vouchers_cliente[0].voucher.cancelamento_voucher ? 'line-through' : ''}}>{moment(value.pessoa.cliente.vouchers_cliente[0].voucher.data_valid_normal).format('DD/MM/YYYY')}</td>
                      <td style={{height: '38px', textDecoration: value.pessoa.cliente.vouchers_cliente[0].voucher.cancelamento_voucher ? 'line-through' : ''}}>
                        <Tooltip title='Visualizar Voucher' aria-label="add">
                          <Link to={`/venda_voucher/view/${value.pessoa.cliente.vouchers_cliente[0].voucher.id}`} target="_blank">
                            <IconButton color="primary" aria-label="view">
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </td>
                    </tr>
                    {value.pessoa.cliente.vouchers_cliente.map((voucher_cliente, index) => {
                      return (
                        <React.Fragment>
                          {index !== 0 &&
                            <tr 
                              key={index}
                              style={{
                                backgroundColor: last_cor,
                                border: value.pessoa.cliente.vouchers_cliente.length === index ? '1px solid #A9A9A9' : ''
                              }}
                            >
                              <td style={{height: '38px', textDecoration: voucher_cliente.voucher.cancelamento_voucher ? 'line-through' : ''}}>{moment(voucher_cliente.voucher.data_compra).format('DD/MM/YYYY')}</td>
                              <td style={{height: '38px', textDecoration: voucher_cliente.voucher.cancelamento_voucher ? 'line-through' : ''}}>{voucher_cliente.voucher.numero} - {voucher_cliente.voucher.padrao.nome}</td>
                              <td style={{height: '38px', textDecoration: voucher_cliente.voucher.cancelamento_voucher ? 'line-through' : ''}}>R$ {real(voucher_cliente.voucher.valor_credito)}</td>
                              <td style={{height: '38px', textDecoration: voucher_cliente.voucher.cancelamento_voucher ? 'line-through' : ''}}>R$ {real(voucher_cliente.voucher.saldo)}</td>
                              <td style={{height: '38px', textDecoration: voucher_cliente.voucher.cancelamento_voucher ? 'line-through' : ''}}>{moment(voucher_cliente.voucher.data_valid_prom).format('DD/MM/YYYY')}</td>
                              <td style={{height: '38px', textDecoration: voucher_cliente.voucher.cancelamento_voucher ? 'line-through' : ''}}>{moment(voucher_cliente.voucher.data_valid_normal).format('DD/MM/YYYY')}</td>
                              <td style={{height: '38px', textDecoration: voucher_cliente.voucher.cancelamento_voucher ? 'line-through' : ''}}>
                                <Tooltip title='Visualizar Voucher' aria-label="add">
                                  <Link to={`/venda_voucher/view/${voucher_cliente.voucher.id}`} target="_blank">
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              </td>
                            </tr>
                          } 
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )
              }

              return false
            })}
          </tbody>
        </table>
        <TablePagination
          component="div"
          labelRowsPerPage='Linhas por Pag.'
          count={qtd}
          page={offset}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 15, 25]}
          onPageChange={(event, newPage) => handleChangePage(event, newPage)}
          onRowsPerPageChange={e => handleChangeRowsPerPage(e)}
        />
      </Grid>
    </Grid>

  </React.Fragment>
)

export default VoucherCliente
