import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import {  Grid, TextField, Button, Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CurrencyInput from './../mask/CurrencyInput'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));


export default function SimpleModal({conta, open, handleClose, updateField, formasPagamento, verificaDataParcela, 
  updateFieldParcela, mudarParcelas, recalcularParcela, contasFinanceira, logado, marcaParcelas, confirmar, disabledButtons}) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
        <Grid container direction="row" spacing={1} className="borderBottom">
          <Grid item md={12} xs={12} sm={12}>
            <h5 className="tituloConfirmacao">Dados de Troco</h5>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h4 className='titulo'>Valor do Troco: R$ {conta.valortotal}</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <div className='scroll'>
              <table className="lista sempadding borderTable">
                <thead>
                  <tr className="titulos acompanha">
                    <th>Parcelas</th>
                    <th>Valor*</th>
                    <th>Data Movimentação*</th>
                    <th>Contas Bancárias</th>
                    <th>Observação</th>
                  </tr>
                </thead>
                <tbody>
                  {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                    return (
                      <tr key={parcela.index}>
                        <td>
                          <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                          <span className="separacaoParcelas">/</span>
                          <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateField(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }}/>
                        </td>
                        <td>
                          <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                        </td>
                        <td>
                          <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="datavencimento" value={parcela.datavencimento} onBlur={(e) => verificaDataParcela(e, parcela.index)} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                        </td>
                        <td>
                          <Select
                            fullWidth
                            className={`${parcela.contfin_id !== '' ? 'selecionado' : 'nao_selecionado'} inputConta`}
                            variant="outlined"
                            name="contfin_id"
                            value={parcela.contfin_id}
                            onChange={(e) => updateFieldParcela(e, parcela.index)}
                          >
                            <MenuItem value=''>
                              <ListItemIcon>
                              </ListItemIcon>
                              <ListItemText primary={'Selecionar Conta'} />
                            </MenuItem>
                            {contasFinanceira.map(conta => {
                              return (
                                <MenuItem key={conta.id} value={conta.id}>
                                  <ListItemIcon>
                                    <img style={{width: '30px', height: '30px', marginRight: '5px'}} src={conta.conta_corrente ? conta.conta_corrente.conta.banco.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt='logo'/>
                                  </ListItemIcon>
                                  <ListItemText primary={conta.descricao} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </td>
                        <td>
                          <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={6} xs={false} sm={2}></Grid>
          <Grid item md={3} xs={12} sm={5}>
            <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => confirmar(false)} disabled={disabledButtons}>Voltar</Button>
          </Grid>
          <Grid item md={3} xs={12} sm={5}>
            <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => confirmar(true)} disabled={disabledButtons}>Continuar</Button>
          </Grid>
        </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
