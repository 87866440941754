import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  const {dados, formas_pagamento, contasFinanceira} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Corrigir Lançamento</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
            <Grid item md={3} sm={12} xs={4}>
              <TextField 
                fullWidth 
                size="small" 
                variant="outlined" 
                className="input" 
                label="Descrição" 
                value={dados.contpr_descricao} 
                disabled 
              />
            </Grid>
            <Grid item md={1} sm={12} xs={2}>
              <TextField 
                fullWidth 
                size="small" 
                variant="outlined" 
                className="input" 
                label="Valor" 
                value={dados.valorparcela} 
                disabled 
              />
            </Grid>
            <Grid item md={1} sm={12} xs={2}>
              <TextField 
                fullWidth 
                size="small" 
                variant="outlined" 
                className="input" 
                label="Multa/Juros"
                value={dados.multajuros} 
                disabled 
              />
            </Grid>
            <Grid item md={2} sm={12} xs={2}>
              <TextField 
                fullWidth 
                size="small" 
                variant="outlined" 
                className="input" 
                label="Valor Recebido" 
                value={dados.valorpagamento} 
                disabled 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={12}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                label="Forma de Pagamento*"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="formapagto_id"
                value={dados.formapagto_id}
                onChangeCapture={(e) => props.updateFieldParcela(e, dados.index)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Selecionar--</option>
                {formas_pagamento.map(forma => {
                  return (
                    <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12} sm={12}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                label="Conta Financeira*"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="conta_fin_id"
                value={dados.conta_fin_id}
                onChangeCapture={(e) => props.updateFieldParcela(e, dados.index)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Selecionar--</option>
                {contasFinanceira.filter(param => param.tipo_conta === dados.habilita).map(conta => {
                  return (
                    <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={8} sm={12} xs={6}></Grid>
            <Grid item md={2} sm={12} xs={3}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.voltar()} >Voltar</Button>
            </Grid>
            <Grid item md={2} sm={12} xs={3}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar()}>Confirmar</Button>
            </Grid>
          </Grid>
       </StyledPaper>
      </Modal>
    </div>
  );
}
