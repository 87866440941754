import React, { useState, useEffect } from 'react';
import './Caixa.scss'
import './../../main/ultil.scss'
import axios from 'axios'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import Loading from './../../components/templates/Loading'
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import real from '../../services/real'
import CurrencyInput from './../../components/mask/CurrencyInput'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  caixaOriginal: {},
  caixa: {
    id: '',
    fundotroco: ''
  },
  telas: [],
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  update: false,
  openModalAjuda: false,
  markdown: ''
}

let AberturaCaixa = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = async (event) => {
    const caixa = { ...state.caixa }
    caixa[event.target.name] = event.target.value
    setState(prevState => ({...prevState, caixa }))
  }

  const salvar = async () => {
    setState(prevState => ({...prevState, loading: true }))
    let { caixa } = state

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
      const dados = {
        fundotroco: parseFloat(caixa.fundotroco.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
        unidade_id: acesso_atual[0].id
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/caixa`, dados, getToken())


      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Caixa aberto com sucesso.'
        },
        loading: false,
        caixaOriginal: {},
        caixa: {
          id: '',
          fundotroco: 0
        }
      }))

      backPage()
    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    } 
  }

  const backPage = (timeout = 2000) => {
    setTimeout(() => {
      navigate('/caixa');
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const helpPath = require("./../../help/caixa/Cadastro.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        setState(prevState => ({...prevState,
          markdown: text
        }))
      })

    const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))

    const caixaOriginal = { ...state.caixa }

    if (ultimo && ultimo.fechado) {
      caixaOriginal.fundotroco = ultimo['conta.contfin_saldo_atual']
    }

    setState(prevState => ({...prevState,
      ultimoCaixa: ultimo,
      caixa: {
        id: caixaOriginal.id,
        fundotroco: real(caixaOriginal.fundotroco)
      },
      loading: false
    }))
  }, []);

  const { caixa, ultimoCaixa } = state

  const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div id="caixa" className="cadastro">
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Abertura de Caixa</h1>
                </Grid>
              </Grid>

              {acesso_atual.length > 1 &&
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <p>Para a abertura do caixa por favor selecionar apenas uma unidade.</p>
                  </Grid>
                </Grid>
              }

              {acesso_atual.length === 1 &&
                <React.Fragment>
                  {((!ultimoCaixa || !ultimoCaixa.id) || (ultimoCaixa && ultimoCaixa.fechado)) &&
                    <div>
                      <Grid container direction="row" style={{ 'marginTop': '20px' }}>
                        <Grid item md={12}>
                          O caixa será iniciado com:
                        </Grid>
                        <Grid item md={12} className="mg_top_20">
                          <Grid item md={3} xs={12} sm={6}>
                            <TextField
                              className="input"
                              label="Fundo de Troco *"
                              variant="outlined"
                              size="small"
                              name="fundotroco"
                              value={caixa.fundotroco} onChange={(e) => updateField(e)}
                              InputProps={{ inputComponent: CurrencyInput }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
      
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={3}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            className="btn_salvar"
                            size="small"
                            startIcon={<SaveIcon />}
                            onClick={e => salvar(e)}
                            disabled={state.loadingSalvar}
                          >
                            Salvar
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
      
                  {ultimoCaixa && ultimoCaixa.id && !ultimoCaixa.fechado &&
                    <div style={{ 'marginTop': '30px' }}>
                      <p>Para criar um novo caixa, é necessário fechar o caixa em aberto de nº {ultimoCaixa.numero}</p>
      
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={3}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            className="btn_salvar"
                            size="small"
                            startIcon={<ArrowBackIcon />}
                            onClick={e => backPage(0)}
                            disabled={state.loadingSalvar}
                          >
                            Voltar
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
                </React.Fragment>
              }

            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Abertura de Caixa"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default AberturaCaixa