import React, { useState, useEffect } from 'react';
import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import './Representante.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, TextField, Button } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import FormPessoa from '../../components/forms/FormPessoa'
import FormUsuario from '../../components/forms/FormUsuario'
import FormContratoFuncionario from '../../components/forms/FormContratoFuncionario'
import FormContaBancaria from '../../components/forms/FormContaBancaria'
import Alert from './../../components/templates/Alert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios'
import { format } from 'cnpj';
import Loading from './../../components/templates/Loading'
import verificaForcaDaSenha from './../../services/verificaForcaSenha'
import ModalAjuda from './../../components/modals/Ajuda'
import { useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');
const cnpj = require('node-cpf-cnpj')

const initialState = {
  unidade: {
    id: "",
    descricao: "",
    ativo: true,
    tipoPessoa: "FISICA",
    pessoaFisica: {
      nome: "",
      sobrenome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: ""
    },
    pessoaJuridica: {
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: ""
    },
    enderecos: [],
    emails: [],
    contatos: []
  },
  unidades: {
    list: []
  },
  franqueados: {
    list: []
  },
  funcionario: {
    id: "",
    dataAdmissao: "",
    dataDemissao: "",
    tipoPessoa: "FISICA",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      sobrenome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: ""
    },
    enderecos: [],
    emails: [],
    contatos: [],
    contas: [{
      agencia: "",
      numero: "",
      gerente: "",
      chave_pix: "",
      banco_id: "",
      index: 1,
      banco: {
        id: "",
        nome: "",
        numero: "",
        padraocosif: ""
      }
    }],
    usuario: {
      email_login: "",
      senha_usuario: "",
      repetirSenha: "",
      perfil_id: ""
    },
    ativo: "",
  },
  bancos: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  perfil: {
    id: "",
    descricao: ""
  },
  perfils: {
    list: []
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  disableEnderecoFuncionario: true,
  enableDadosPessoaFuncionario: true,
  enableDadosEnderecoFuncionario: false,
  enableDadosEmailFuncionario: false,
  enableDadosContatoFuncionario: false,
  enableDadosFuncionarioFuncionario: false,
  enableDadosContratoFuncionario: false,
  enableDadosUsuarioFuncionario: false,
  enableDadosContaBancariaFuncionario: false,
  enableUpdate: false,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  showPassword: false,
  showPasswordRepetir: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidadePlano:{
    id: '',
    descricao: '',
    numero: ''
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes: {
    list: []
  },
  loading: false,
  mensagemLoading: 'Carregando Dados...',
  informarEndereco: false,
  informarContato: false,
  informarEmail: false,
  endereco: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contato: {
    numero: "55",
    tipoContato: "RESIDENCIAL",
    principal: true,
    index: ""
  },
  email: {
    email: "",
    principal: true,
    index: ""
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes: {
    list: []
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: ['update', 'remover'],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
  informarEnderecoFuncionario: false,
  informarContatoFuncionario: false,
  informarEmailFuncionario: false,
  enderecoFuncionario: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contatoFuncionario: {
    numero: "55",
    tipoContato: "RESIDENCIAL",
    principal: true,
    index: ""
  },
  emailFuncionario: {
    email: "",
    principal: true,
    index: ""
  },
  openModalAjuda: false,
  markdown: '',
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

let CadastroRepresentante = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  
  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = (event) => {
    if (event.target.name === "tipoPessoa") {
      if (event.target.value === "FISICA") {
        setState(prevState => ({
          ...prevState,
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true
        }));
      }
    }

    const unidade = { ...state.unidade }
    unidade[event.target.name] = event.target.value

    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const updateFieldPessoaFisica = (event) => {
    const unidade = { ...state.unidade }
    unidade.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const updateFieldPessoaJuridica = (event) => {
    const unidade = { ...state.unidade }
    unidade.pessoaJuridica[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const validarCpfCnpj = async (event) => {
    let cpf_cnpj = event.target.value
    const unidade = state.unidade

    if (unidade.tipoPessoa === "FISICA") {
      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {

        unidade.pessoaFisica.cpf = ""

        setState(prevState => ({
          ...prevState,
          unidade,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CPF Invalido! Por favor corrija o campo CPF'
          }
        }));
      } else {
        unidade.pessoaFisica.cpf = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          unidade
        }));
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        unidade.pessoaJuridica.cnpj = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          unidade
        }));

      } else {
        unidade.pessoaJuridica.cnpj = ""
        setState(prevState => ({
          ...prevState,
          unidade,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CNPJ Invalido! Por favor corrija o campo CNPJ'
          }
        }));
      }
    }
  }

  const informarEndereco = () => {
    const { unidade } = state;
    let principal = !unidade.enderecos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEndereco: true,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }));
  }

  const pegaEndereco = async (event, index) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { endereco } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: endereco.numero,
        complemento: endereco.complemento,
        cep: formatarCEP(endereco.cep),
        principal: endereco.principal,
        tipoEndereco: endereco.tipoEndereco,
        index: endereco.index
      };
  
      setState(prevState => ({
        ...prevState,
        endereco: novoEndereco
      }));
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: endereco.complemento,
          rua: resp.logradouro,
          numero: endereco.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };
  
        setState(prevState => ({
          ...prevState,
          endereco: enderecoEncontrado,
          disableEndereco: true,
          modalErro: false
        }));
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: endereco.numero,
          cep: "",
          complemento: endereco.complemento,
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };

        setState(prevState => ({
          ...prevState,
          endereco: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }));
      }
    }
  }

  const updateFieldEndereco = (event, index) => {
    let { endereco } = state
    endereco[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const updateFieldEmail = (event, index) => {
    let { email } = state
    email[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      email
    }));
  }

  const updateFieldContato = (event, index) => {
    let { contato } = state
    contato[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const updateFieldEnderecoPrincipal = (event, index) => {
    let { endereco, unidade } = state
    const indexAux = unidade.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== endereco.index)) {
      endereco.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui endereço principal'
        }
      }));
    }else{
      endereco.principal = !endereco.principal
    }

    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const addNovoEndereco = () => {
    const { endereco, unidade } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        endereco.cep !== "" &&
        endereco.rua !== "" &&
        endereco.bairro !== "" &&
        endereco.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }));
      return;
    }
  
    // Crie uma cópia dos endereços da unidade
    const novosEnderecos = [...unidade.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (endereco.index === "") {
      endereco.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...endereco });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[endereco.index - 1] = { ...endereco };
    }
  
    // Atualize os endereços da unidade
    unidade.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({
      ...prevState,
      unidade,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEndereco: false,
      alerta: { open: false } // Feche o alerta após adicionar o novo endereço
    }));
  }

  const editarEndereco = (row) => {
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      endereco: {...unidade.enderecos[row.index - 1]},
      informarEndereco: true
    }));
  }

  const removeEnderecoUnidade = (index) => {
    const unidade = { ...state.unidade }
    const enderecos = unidade.enderecos
    let aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.enderecos = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const informarContato = () => {
    const { unidade } = state;
    let principal = !unidade.contatos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarContato: true,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal,
        index: ""
      },
    }));
  }

  const updateFieldContatoPrincipal = (event, index) => {
    let { contato, unidade } = state
    const indexAux = unidade.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contato.index)) {
      contato.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui contato principal'
        }
      }));
    }else{
      contato.principal = !contato.principal
    }

    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const addNovoContato = () => {
    let {contato, unidade} = state

    let valido = true

    if (contato.numero === "") valido = false
    if (contato.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }));
      return
    }

    const contatos = unidade.contatos

    if(contato.index === ""){
      contato.index = contatos.length + 1

      contatos.push({
        ...contato
      })
    }else{
      contatos[contato.index - 1] = {
        ...contato
      }
    }

    unidade.contatos = contatos

    setState(prevState => ({
      ...prevState,
      unidade,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: false,
        index: ""
      },
      informarContato: false
    }));
  }

  const editarContato = (row) =>{
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      contato: {...unidade.contatos[row.index - 1]},
      informarContato: true
    }));
  }

  const removeContatoUnidade = (index) => {
    const unidade = { ...state.unidade }
    const contatos = unidade.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.contatos = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }))
  }

  const informarEmail = () => {
    const { unidade } = state;
    let principal = !unidade.emails.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEmail: true,
      email: {
        email: "",
        principal,
        index: ""
      },
    }))
  }

  const updateFieldEmailPrincipal = (event, index) => {
    let { email, unidade } = state
    const indexAux = unidade.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== email.index)) {
      email.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui email principal'
        }
      }))
    }else{
      email.principal = !email.principal
    }

    setState(prevState => ({
      ...prevState,
      email
    }))
  }

  const addNovoEmail = () => {
    let {email, unidade} = state

    let valido = true

    if (email.email === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(email.email)) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do unidade é inválido!'
        }
      }))
      return
    }

    const emails = unidade.emails

    if(email.index === ""){
      email.index = emails.length + 1

      emails.push({
        ...email
      })
    }else{
      emails[email.index - 1] = {
        ...email
      }
    }

    unidade.emails = emails

    setState(prevState => ({
      ...prevState,
      unidade,
      email: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmail: false
    }))
  }

  const editarEmail = (row) => {
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      email: {...unidade.emails[row.index - 1]},
      informarEmail: true
    }))
  }

  const removeEmailUnidade = (index) => {
    const unidade = { ...state.unidade }
    const emails = unidade.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.emails = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }))
  }

  const handleCloseAlerta = () =>{
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const validarDadosPessoa = async() =>{
    const {unidade} = state
    
    if (unidade.tipoPessoa === "FISICA") {
      if (
        unidade.pessoaFisica.nome === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o nome do(a) representante.'
        }
      }

      if (
        unidade.pessoaFisica.dataNascimento === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar a data de nascimento do(a) representante.'
        }
      }

      if (
        unidade.pessoaFisica.cpf === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o CPF do(a) representante.'
        }
      }

      let cpf_cnpj = unidade.pessoaFisica.cpf

      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {
        if(cpf_cnpj !== '000.000.000-00'){
          return {
            valido: false,
            mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
          }
        }
      }

      setState(prevState => ({
        ...prevState,
        unidade
      }))
    }else{
      if (unidade.pessoaJuridica.razaoSocial === "") {
        return {
          valido: false,
          mensagem: 'Por favor informar a razão social do representante.'
        }
      }

      if (unidade.pessoaJuridica.nomeFantasia === "") {
        return {
          valido: false,
          mensagem: `Por favor informar o nome fantasia do representante.`
        }
      }
    }

    if(unidade.enderecos.length > 0){
      let valido = true
      unidade.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })
  
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }
    
    if(unidade.contatos.length > 0){
      unidade.contatos.forEach(contato => {
        if (contato.numero === "") {
          return {
            valido: false,
            mensagem: `Por favor informar o contato da unidade.`
          }
        }
      })
    }

    if(unidade.emails.length > 0){
      let valido = true
      unidade.emails.forEach(email => {
        if (email.email === "") valido = false
      })
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    setState(prevState => ({
      ...prevState,
      unidade
    }))

    return {
      valido: true,
      mensagem: ''
    }
  }

  const informarFuncionario = async () => {
    let validacao = await validarDadosPessoa()

    if(!validacao.valido){
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: validacao.mensagem
        }
      }))
      return
    }

    let {unidade} = state

    if(unidade.tipoPessoa === "FISICA"){
      await buscaPerfils()
      
      setState(prevState => ({
        ...prevState,
        enableDadosFuncionario: false,
        enableDadosUsuarioFuncionario: true
      }))
    }else{
      setState(prevState => ({
        ...prevState,
        enableDadosFuncionario: true,
        enableDadosUsuarioFuncionario: false
      }))
    }

    setState(prevState => ({
      ...prevState,
      enableDadosPessoa: false
    }))
  }

  const updateFieldPerfil = (event, value) => {
    if (!value) return false

    const {funcionario} = state
    funcionario.usuario.perfil_id = value.id

    setState(prevState => ({
      ...prevState,
      funcionario,
      perfil: {
        id: value.id,
        descricao: value.descricao
      }
    }))
  }

  const buscaPerfils = async() => {

    let tipoUnidade = 'REPRESENTANTE'

    let params = `visibilidade=${tipoUnidade}`

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?${params}`, getToken())

      setState(prevState => ({
        ...prevState,
        perfils: {
          list: data
        }
      }))      
    }catch (error) {
      console.log(error)
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
    }
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/representante");      
    }, timeout)
  }

  const updateFieldUsuario = async (event) => {
    const {funcionario} = state
    let {name, value} = event.target
    funcionario.usuario[name] = value

    if (name === 'senha_usuario') {
      const resp = await verificaForcaDaSenha(value)
      setState(prevState => ({
        ...prevState,
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      }))
    }

    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const handleClickShowPassword = () => {
    setState(prevState => ({
      ...prevState,
      showPassword: !state.showPassword
    }))
  }

  const handleClickShowPasswordRepetir = () => {
    setState(prevState => ({
      ...prevState,
      showPasswordRepetir: !state.showPasswordRepetir
    }))
  }

  const voltarTela = () => {
    let {unidade} = state

    if(unidade.tipoPessoa === "FISICA"){
      setState(prevState => ({
        ...prevState,
        enableDadosFuncionario: false,
        enableDadosUsuarioFuncionario: false,
        enableDadosPessoa: true
      }))
    }else{
      if(state.enableDadosFuncionario){
        setState(prevState => ({
          ...prevState,
          enableDadosFuncionario: false,
          enableDadosUsuarioFuncionario: false,
          enableDadosPessoa: true
        }))
      }else if(state.enableDadosUsuarioFuncionario){
        setState(prevState => ({
          ...prevState,
          enableDadosFuncionario: true,
          enableDadosUsuarioFuncionario: false,
          enableDadosPessoa: false
        }))
      }
    }
  }

  const salvar = async () => {
    const { unidade, funcionario } = state
    let valido = true

    if (funcionario.usuario.email_login === "") {
      valido = false
    }

    if (funcionario.usuario.senha_usuario !== "" && funcionario.usuario.repetirSenha === "") {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios.'
        }
      }))
      return
    }

    if (funcionario.usuario.senha_usuario !== funcionario.usuario.repetirSenha) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Senhas não conferem, por favor verificar as senhas.'
        }
      }))
      return
    }

    if (!validacaoEmail(funcionario.usuario.email_login)) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email Invalido!'
        }
      }))
      return
    }

    if (valido) {
      setState(prevState => ({
        ...prevState,
        loading: true,
        mensagemLoading: 'Cadastrando/Alterando Representante'
      }))

      let fisica = {}
      let juridica = {}

      if (unidade.tipoPessoa === "FISICA") {
        fisica = {
          nome: unidade.pessoaFisica.nome,
          sobrenome: unidade.pessoaFisica.sobrenome,
          cpf: cpf.strip(unidade.pessoaFisica.cpf),
          rg: unidade.pessoaFisica.rg,
          datanascimento: unidade.pessoaFisica.dataNascimento,
          rg_orgao_emissor: unidade.pessoaFisica.rg_orgao_emissor,
          rg_uf: unidade.pessoaFisica.rg_uf,
          rg_data_emissao: unidade.pessoaFisica.rg_data_emissao,
        }
      } else {
        juridica = {
          razaosocial: unidade.pessoaJuridica.razaoSocial,
          nomefantasia: unidade.pessoaJuridica.nomeFantasia,
          inscricaoestadual: unidade.pessoaJuridica.inscricaoEstadual,
          datafundacao: unidade.pessoaJuridica.dataFundacao,
          cnpj: cnpj.strip(unidade.pessoaJuridica.cnpj)
        }
      }

      let enderecos = []
      let emails = []
      let telefones = []

      unidade.enderecos.forEach(endereco => {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: endereco.complemento === "" ? "N/A" : endereco.complemento,
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      });

      unidade.emails.forEach(email => {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      });

      unidade.contatos.forEach(telefone => {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      });

      let responsavel = {}

      let dados = {}

      if (unidade.tipoPessoa === "FISICA") {
        responsavel = {
          usuario: {
            perfil_id: funcionario.usuario.perfil_id,
            login: funcionario.usuario.email_login,
            senha: funcionario.usuario.senha_usuario
          }
        }
      } else {

        responsavel = {
          nome: funcionario.pessoaFisica.nome,
          sobrenome: funcionario.pessoaFisica.sobrenome,
          cpf: funcionario.pessoaFisica.cpf,
          rg: funcionario.pessoaFisica.rg,
          rg_uf: funcionario.pessoaFisica.rg_uf,
          rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
          rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
          datanascimento: funcionario.pessoaFisica.dataNascimento,
          unidadetrabalho_id: funcionario.unidadetrabalho_id,
          contt_funcao_id: funcionario.contrato.idFuncao,
          data_admissao: funcionario.contrato.dataInicial,
          datainicio: funcionario.contrato.dataInicial,
          observacao: funcionario.contrato.observacao,
          enderecos,
          emails,
          telefones,
          usuario: {
            perfil_id: funcionario.usuario.perfil_id,
            login: funcionario.usuario.email_login,
            senha: funcionario.usuario.senha_usuario
          }
        }
        
        const contas = funcionario.contas
        let contasAux = []

        contas.forEach(conta => {
          if (conta.agencia !== "" && conta.numero !== "" && conta.banco_id !== "") {

            const aux = {
              numero: conta.numero,
              gerente: conta.gerente,
              agencia: conta.agencia,
              chave_pix: conta.chave_pix,
              banco_id: conta.banco_id
            }

            if (conta.gerente === "") {
              delete aux.gerente
            }

            if (conta.chave_pix === "") {
              delete aux.chave_pix
            }

            contasAux.push(aux)
          }
        });

        if (contasAux.length !== 0) {
          responsavel.contas = contasAux
        }
      }

      dados = {
        unidade: {
          tipo: unidade.tipoPessoa,
          juridica,
          fisica,
          enderecos,
          emails,
          telefones
        },
        responsavel
      }

      try {

        await axios.post(`${window._env_.REACT_APP_API_URL}/representantes`, dados, getToken())

        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Cadastro realizado com sucesso.'
          },
          unidade: {
            id: "",
            ativo: true,
            tipoPessoa: "FISICA",
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            pessoaJuridica: {
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: ""
            },
            enderecos: [{
              cep_id: "",
              rua: "",
              numero: "",
              complemento: "",
              bairro: "",
              cidade: "",
              estado: "",
              cep: "",
              principal: true,
              tipoEndereco: "RESIDENCIAL",
              index: "1"
            }],
            emails: [{
              email: "",
              principal: true,
              index: "1"
            }],
            contatos: [{
              numero: "55",
              tipoContato: "RESIDENCIAL",
              principal: true,
              index: "1"
            }]
          },
          funcionario: {
            id: "",
            dataAdmissao: "",
            dataDemissao: "",
            contrato: {
              departamento: "",
              idDepartamento: "",
              cargo: "",
              idCargo: "",
              funcao: "",
              idFuncao: "",
              dataInicial: "",
              dataFinal: "",
              observacao: ""
            },
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            enderecos: [{
              rua: "",
              complemento: "",
              numero: "",
              bairro: "",
              cidade: "",
              estado: "",
              cep: "",
              principal: true,
              tipoEndereco: "RESIDENCIAL",
              index: 1
            }],
            emails: [{
              email: "",
              principal: true,
              index: 1
            }],
            contatos: [{
              numero: "55",
              tipoContato: "RESIDENCIAL",
              principal: true,
              index: 1
            }],
            usuario: {
              email_login: "",
              senha_usuario: "",
              repetirSenha: "",
              perfil_id: ""
            },
            ativo: "",
          },
          loading: false
        }))

        backPage()

      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loading: false
        }))
      }

    } else {
      if (funcionario.usuario.senha !== funcionario.usuario.repetirSenha) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Senhas não conferem, por favor verificar as senhas.'
          },
          loading: false
        }))
      } else {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios.'
          },
          loading: false
        }))
      }
    }
  }

  const updateFieldPessoaFisicaFuncionario = (event) => {
    const {funcionario} = state

    let {name, value} = event.target

    funcionario.pessoaFisica[name] = value

    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const validarCpfCnpjFuncionario = async (event) => {
    let {value} = event.target

    let cpf_cnpj = value
    const {funcionario} = state
    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {

      funcionario.pessoaFisica.cpf = ""
      setState(prevState => ({
        ...prevState,
        funcionario,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'CPF Invalido! Por favor corrija o campo CPF'
        }
      }))
    } else {
      funcionario.pessoaFisica.cpf = cpf_cnpj
      setState(prevState => ({
        ...prevState,
        funcionario
      }))
    }
  }

  const informarEnderecoFuncionario = () => {
    const { funcionario } = state;
    let principal = !funcionario.enderecos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEnderecoFuncionario: true,
      enderecoFuncionario: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }))
  }

  const pegaEnderecoFuncionario = async (event, index) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { enderecoFuncionario } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecoFuncionario.numero,
        complemento: enderecoFuncionario.complemento,
        cep: formatarCEP(enderecoFuncionario.cep),
        principal: enderecoFuncionario.principal,
        tipoEndereco: enderecoFuncionario.tipoEndereco,
        index: enderecoFuncionario.index
      };
  
      setState(prevState => ({
        ...prevState,
        enderecoFuncionario: novoEndereco
      }))
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: enderecoFuncionario.complemento,
          rua: resp.logradouro,
          numero: enderecoFuncionario.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: enderecoFuncionario.principal,
          tipoEndereco: enderecoFuncionario.tipoEndereco,
          index: enderecoFuncionario.index
        };

        setState(prevState => ({
          ...prevState,
          enderecoFuncionario: enderecoEncontrado,
          disableEndereco: true,
        }))
  
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecoFuncionario.numero,
          cep: "",
          complemento: enderecoFuncionario.complemento,
          principal: enderecoFuncionario.principal,
          tipoEndereco: enderecoFuncionario.tipoEndereco,
          index: enderecoFuncionario.index
        };
  
        setState(prevState => ({
          ...prevState,
          enderecoFuncionario: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }))
      }
    }
  }

  const updateFieldEnderecoFuncionario = (event, index) => {
    let { enderecoFuncionario } = state
    enderecoFuncionario[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      enderecoFuncionario
    }));
  }

  const updateFieldEnderecoPrincipalFuncionario = (event, index) => {
    let { enderecoFuncionario, funcionario } = state
    const indexAux = funcionario.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== enderecoFuncionario.index)) {
      enderecoFuncionario.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui endereço principal'
        }
      }))
    }else{
      enderecoFuncionario.principal = !enderecoFuncionario.principal
    }

    setState(prevState => ({
      ...prevState,
      enderecoFuncionario
    }))
  }

  const addNovoEnderecoFuncionario = () => {
    const { enderecoFuncionario, funcionario } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        enderecoFuncionario.cep !== "" &&
        enderecoFuncionario.rua !== "" &&
        enderecoFuncionario.bairro !== "" &&
        enderecoFuncionario.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }))
      return;
    }
  
    // Crie uma cópia dos endereços do funcionario
    const novosEnderecos = [...funcionario.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (enderecoFuncionario.index === "") {
      enderecoFuncionario.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...enderecoFuncionario });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[enderecoFuncionario.index - 1] = { ...enderecoFuncionario };
    }
  
    // Atualize os endereços do funcionario
    funcionario.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({
      ...prevState,
      funcionario,
      enderecoFuncionario: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEnderecoFuncionario: false,
      alerta: {
        ...state.alerta,
        open: false 
      }
    }))
  }

  const editarEnderecoFuncionario = (row) =>{
    let {funcionario} = state

    setState(prevState => ({
      ...prevState,
      enderecoFuncionario: {...funcionario.enderecos[row.index - 1]},
      informarEnderecoFuncionario: true
    }))
  }

  const removeEnderecoFuncionario = (index) => {
    const {funcionario} = state
    const enderecos = funcionario.enderecos
    let aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    funcionario.enderecos = aux
    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const informarContatoFuncionario = () => {
    const { funcionario } = state;
    let principal = !funcionario.contatos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarContatoFuncionario: true,
      contatoFuncionario: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal,
        index: ""
      },
    }))
  }

  const updateFieldContatoFuncionario = (event, index) => {
    let { contatoFuncionario } = state
    contatoFuncionario[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      contatoFuncionario
    }));
  }

  const updateFieldContatoPrincipalFuncionario = (event, index) => {
    let { contatoFuncionario, funcionario } = state
    const indexAux = funcionario.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contatoFuncionario.index)) {
      contatoFuncionario.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui contato principal'
        }
      }))
    }else{
      contatoFuncionario.principal = !contatoFuncionario.principal
    }

    setState(prevState => ({
      ...prevState,
      contatoFuncionario
    }))
  }

  const addNovoContatoFuncionario = () => {
    let {contatoFuncionario, funcionario} = state

    let valido = true

    if (contatoFuncionario.numero === "") valido = false
    if (contatoFuncionario.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    const contatos = funcionario.contatos

    if(contatoFuncionario.index === ""){
      contatoFuncionario.index = contatos.length + 1

      contatos.push({
        ...contatoFuncionario
      })
    }else{
      contatos[contatoFuncionario.index - 1] = {
        ...contatoFuncionario
      }
    }

    funcionario.contatos = contatos

    setState(prevState => ({
      ...prevState,
      funcionario,
      contatoFuncionario: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: false,
        index: ""
      },
      informarContatoFuncionario: false
    }))
  }

  const editarContatoFuncionario = (row) => {
    let {funcionario} = state

    setState(prevState => ({
      ...prevState,
      contatoFuncionario: {...funcionario.contatos[row.index - 1]},
      informarContatoFuncionario: true
    }))
  }

  const removeContatoFuncionario = (index) => {
    const {funcionario} = state
    const contatos = funcionario.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    funcionario.contatos = aux
    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const informarEmailFuncionario = () => {
    const { funcionario } = state;
    let principal = !funcionario.emails.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEmailFuncionario: true,
      emailFuncionario: {
        email: "",
        principal,
        index: ""
      },
    }))
  }

  const updateFieldEmailFuncionario = (event, index) => {
    let { emailFuncionario } = state
    emailFuncionario[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      emailFuncionario
    }));
  }

  const updateFieldEmailPrincipalFuncionario = (event, index) => {
    let { emailFuncionario, funcionario } = state
    const indexAux = funcionario.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== emailFuncionario.index)) {
      emailFuncionario.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui email principal'
        }
      }))
    }else{
      emailFuncionario.principal = !emailFuncionario.principal
    }

    setState(prevState => ({
      ...prevState,
      emailFuncionario
    }))
  }

  const addNovoEmailFuncionario = () => {
    let {emailFuncionario, funcionario} = state

    let valido = true

    if (emailFuncionario.email === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(emailFuncionario.email)) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do funcionario é inválido!'
        }
      }))
      return
    }

    const emails = funcionario.emails

    if(emailFuncionario.index === ""){
      emailFuncionario.index = emails.length + 1

      emails.push({
        ...emailFuncionario
      })
    }else{
      emails[emailFuncionario.index - 1] = {
        ...emailFuncionario
      }
    }

    funcionario.emails = emails

    setState(prevState => ({
      ...prevState,
      funcionario,
      emailFuncionario: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmailFuncionario: false
    }))
  }

  const editarEmailFuncionario = (row) => {
    let {funcionario} = state

    setState(prevState => ({
      ...prevState,
      emailFuncionario: {...funcionario.emails[row.index - 1]},
      informarEmailFuncionario: true
    }))
  }

  const removeEmailFuncionario = (index) => {
    const {funcionario} = state
    const emails = funcionario.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    funcionario.emails = aux
    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const validarDadosPessoaFuncionario = async() =>{
    const {funcionario} = state
    
    if (
      funcionario.pessoaFisica.nome === ""
    ) {
      return {
        valido: false,
        mensagem: 'Por favor informar o nome do(a) funcionário(a).'
      }
    }

    if (
      funcionario.pessoaFisica.dataNascimento === ""
    ) {
      return {
        valido: false,
        mensagem: 'Por favor informar a data de nascimento do(a) funcionário(a).'
      }
    }

    if (
      funcionario.pessoaFisica.cpf === ""
    ) {
      return {
        valido: false,
        mensagem: 'Por favor informar o CPF do(a) funcionário(a).'
      }
    }

    let cpf_cnpj = funcionario.pessoaFisica.cpf

    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {
      if(cpf_cnpj !== '000.000.000-00'){
        return {
          valido: false,
          mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
        }
      }
    }

    setState(prevState => ({
      ...prevState,
      funcionario
    }))

    if(funcionario.enderecos.length > 0){
      let valido = true
      funcionario.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })
  
      if (!valido) {
        return {
          valido: false,
          mensagem: `Por favor informar o endereço do(a) funcionário(a).`
        }
      }
    }
    
    if(funcionario.contatos.length > 0){
      funcionario.contatos.forEach(contato => {
        if (contato.numero === "") {
          return {
            valido: false,
            mensagem: `Por favor informar o contato do(a) funcionário(a).`
          }
        }
      })
    }

    if(funcionario.emails.length > 0){
      let valido = true
      funcionario.emails.forEach(email => {
        if (email.email === "") valido = false
      })
      if (!valido) {
        return {
          valido: false,
          mensagem: `Por favor informar o email do(a) funcionário(a).`
        }
      }
    }

    setState(prevState => ({
      ...prevState,
      funcionario
    }))

    return {
      valido: true,
      mensagem: ''
    }
  }

  const informarUsuario = async () => {
    await buscaPerfils()

    setState(prevState => ({
      ...prevState,
      enableDadosContaBancariaFuncionario: false,
      enableDadosUsuarioFuncionario: true,
    }))
  }

  const informarContrato = () => {
    // let validacao = await validarDadosPessoaFuncionario()

    // if(!validacao.valido){
    //   setState(prevState => ({
    //     ...prevState,
    //     alerta: {
    //       open: true,
    //       severity: 'warning',
    //       message: validacao.mensagem
    //     }
    //   }))
    //   return
    // }

    setState(prevState => ({
      ...prevState,
      enableDadosFuncionario: false,
      enableDadosContratoFuncionario: true,
    }))
  }

  const updateFieldContratoFuncionario = async (event) => {
    if (!event.target) return false;
    
    const { name, value } = event.target;
    const { funcionario } = state
  
    try {
      switch (name) {
        case "idDepartamento":
          if (funcionario.contrato.departamento === parseInt(value)) return false;
          
          funcionario.contrato.idDepartamento = parseInt(value);
          funcionario.contrato.departamento = state.departamentos.list.find(u => u.id === parseInt(value)).descricao;
  
          const { data: cargos } = await axios.get(`${window._env_.REACT_APP_API_URL}/cargos/departamento/${parseInt(value)}`, getToken());
  
          setState(prevState => ({
            ...prevState,
            funcionario,
            cargosFiltrado: {
              list: cargos.filter(u => u.ativo === true)
            }
          }));
          break;
          
        case "idCargo":
          if (funcionario.contrato.cargo === parseInt(value)) return false;
  
          funcionario.contrato.idCargo = parseInt(value);
          funcionario.contrato.cargo = state.cargosFiltrado.list.find(u => u.id === parseInt(value)).descricao;
  
          const { data: funcoes } = await axios.get(`${window._env_.REACT_APP_API_URL}/funcoes/cargo/${parseInt(value)}`, getToken());
  
          setState(prevState => ({
            ...prevState,
            funcionario,
            funcaosFiltrado: {
              list: funcoes.filter(u => u.ativo === true)
            }
          }));
          break;
          
        case "idFuncao":
          if (funcionario.contrato.funcao === parseInt(value)) return false;
  
          funcionario.contrato.idFuncao = parseInt(value);
          funcionario.contrato.funcao = state.funcaosFiltrado.list.find(u => u.id === parseInt(value)).descricao;
  
          setState(prevState => ({
            ...prevState,
            funcionario
          }));
          break;
  
        case "observacao":
          funcionario.contrato.observacao = value;
          setState(prevState => ({
            ...prevState,
            funcionario
          }));
          break;
  
        case "dataInicial":
          funcionario.contrato.dataInicial = value;
          setState(prevState => ({
            ...prevState,
            funcionario
          }));
          break;
  
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage();
    }
  };

  const voltarFuncionario = () => {
    setState(prevState => ({
      ...prevState,
      enableDadosFuncionario: true,
      enableDadosContratoFuncionario: false,
    }))
  }

  const informarDadosBancario = () => {
    setState(prevState => ({
      ...prevState,
      enableDadosContratoFuncionario: false,
      enableDadosContaBancariaFuncionario: true
    }))
  }

  const updateFieldContaBancariaFuncionario = (event, index) => {
    const {funcionario} = state
    funcionario.contas[index - 1][event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const updateFieldBanco = (event, value, index) => {
    if (!value) return false

    const {funcionario} = state
    funcionario.contas[index - 1].banco = value
    funcionario.contas[index - 1].banco_id = value.id
    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const removerConta = (index) => {
    const {funcionario} = state
    const contas = funcionario.contas
    const aux = contas

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    funcionario.contas = array
    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const addNovaContaBancariaFuncionario = () => {
    const {funcionario} = state
    const contas = funcionario.contas

    const index = contas.length + 1

    contas.push({
      agencia: "",
      numero: "",
      gerente: "",
      chave_pix: "",
      banco_id: "",
      banco: {
        nome: "",
        numero: "",
        padraocosif: ""
      },
      index
    })

    funcionario.contas = contas
    setState(prevState => ({
      ...prevState,
      funcionario
    }))
  }

  const voltarContrato = () => {
    setState(prevState => ({
      ...prevState,
      enableDadosContratoFuncionario: true,
      enableDadosContaBancariaFuncionario: false
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/representante/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState((prevState) => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({
        ...prevState,
        loading: true
      }))
  
      const perfil = JSON.parse(localStorage.getItem('perfil'))
  
      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'representante')[0]
  
      setState(prevState => ({
        ...prevState,
        permissoes
      }))
  
      if (!permissoes.inserir) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }))

        backPage()
      }
  
      setState(prevState => ({
        ...prevState,
        unidade: {
          id: "",
          ativo: true,
          tipoPessoa: "FISICA",
          pessoaFisica: {
            nome: "",
            cpf: "",
            rg: "",
            dataNascimento: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: ""
          },
          pessoaJuridica: {
            razaoSocial: "",
            nomeFantasia: "",
            cnpj: "",
            inscricaoEstadual: "",
            dataFundacao: ""
          },
          enderecos: [],
          emails: [],
          contatos: []
        },
        funcionario: {
          id: "",
          dataAdmissao: "",
          dataDemissao: "",
          tipoPessoa: "FISICA",
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: "",
            cpf: "",
            rg: "",
            dataNascimento: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: ""
          },
          enderecos: [],
          emails: [],
          contatos: [],
          contas: [{
            agencia: "",
            numero: "",
            gerente: "",
            chave_pix: "",
            banco_id: "",
            index: 1,
            banco: {
              id: "",
              nome: "",
              numero: "",
              padraocosif: ""
            }
          }],
          usuario: {
            email_login: "",
            senha_usuario: "",
            repetirSenha: "",
            perfil_id: ""
          },
          ativo: "",
        }
      }))
  
      try {
        const { data: bancos } = await axios.get(`${window._env_.REACT_APP_API_URL}/bancos`, getToken())
  
        setState(prevState => ({
          ...prevState,
          bancos: {
            list: bancos
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        backPage()
      }
  
      try {
        const { data: departamentos } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, {}, getToken())
  
        setState(prevState => ({
          ...prevState,
          departamentos: {
            list: departamentos.filter(u => u.ativo === true)
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }

      setState(prevState => ({
        ...prevState,
        loading: false
      }))
  
    };
    
    fetchData();
  }, []);

  const { unidade, funcionario, enableUpdate, loading, enableDadosPessoa, mensagemLoading, enableDadosFuncionario, 
    enableDadosUsuarioFuncionario, perfils, perfil, enableDadosContratoFuncionario, enableDadosContaBancariaFuncionario
  } = state
  
  return (
    <React.Fragment>
      {!loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState((prevState) => ({...prevState, openModalAjuda: true}))}}>
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={12}>
                <h1 className="titulo">Cadastro de Representante</h1>
              </Grid>
            </Grid>
            {enableDadosPessoa &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Pessoa*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipoPessoa"
                      value={unidade.tipoPessoa}
                      onChangeCapture={(e) => updateField(e)}
                    >
                      <option value="FISICA">
                        Pessoa Física
                        </option>
                      <option value="JURIDICA">
                        Pessoa Júridica
                        </option>
                    </TextField>
                  </Grid>
                </Grid>
                <FormPessoa
                  pessoa={unidade}
                  informarEndereco={state.informarEndereco}
                  endereco={state.endereco}
                  informarContato={state.informarContato}
                  contato={state.contato}
                  informarEmail={state.informarEmail}
                  email={state.email}
                  updateFieldPessoaFisica={(e) => updateFieldPessoaFisica(e)}
                  updateFieldPessoaJuridica={(e) => updateFieldPessoaJuridica(e)}
                  validarCpfCnpj={(e) => validarCpfCnpj(e)}
                  dadosComplementaresPF={state.dadosComplementaresPF}
                  metodos_divugacoes={state.metodos_divugacoes.list}
                  informarNovoEndereco={() => informarEndereco()}
                  pegaEndereco={e => pegaEndereco(e)}
                  updateFieldEndereco={e => updateFieldEndereco(e)} 
                  updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipal(e)} 
                  addNovoEndereco={(e) => addNovoEndereco(e)}
                  cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                  editarEndereco={(e) => editarEndereco(e)}
                  removeEndereco={(e) => removeEnderecoUnidade(e)}
                  informarNovoContato={() => informarContato()}
                  updateFieldContato={e => updateFieldContato(e)} 
                  updateFieldContatoPrincipal={e => updateFieldContatoPrincipal(e)} 
                  addNovoContato={() => addNovoContato()}
                  editarContato={(e) => editarContato(e)}
                  removeContato={(e) => removeContatoUnidade(e)}
                  informarNovoEmail={() => informarEmail()}
                  updateFieldEmail={e => updateFieldEmail(e)} 
                  updateFieldEmailPrincipal={e => updateFieldEmailPrincipal(e)}
                  addNovoEmail={() => addNovoEmail()}
                  editarEmail={(e) => editarEmail(e)}
                  removeEmail={(e) => removeEmailUnidade(e)}
                  acoesTabelaEndereco={state.acoesTabelaEndereco}
                  cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                  cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
                />
                <hr className='mg_top_10'/>
                <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                  <Grid item md={10}></Grid>
                  <Grid item md={2}>
                    <Button color="success" fullWidth onClick={() => informarFuncionario()} variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {enableDadosFuncionario &&
              <React.Fragment>
                <Grid container direction="row" className='mg_top_10'>
                  <Grid item md={12}>
                    <h4 className="titulo">Cadastro do Responsável</h4>
                  </Grid>
                </Grid>
                <FormPessoa
                  pessoa={funcionario}
                  informarEndereco={state.informarEnderecoFuncionario}
                  endereco={state.enderecoFuncionario}
                  informarContato={state.informarContatoFuncionario}
                  contato={state.contatoFuncionario}
                  informarEmail={state.informarEmailFuncionario}
                  email={state.emailFuncionario}
                  updateFieldPessoaFisica={(e) => updateFieldPessoaFisicaFuncionario(e)}
                  validarCpfCnpj={(e) => validarCpfCnpjFuncionario(e)}
                  dadosComplementaresPF={state.dadosComplementaresPF}
                  metodos_divugacoes={state.metodos_divugacoes.list}
                  informarNovoEndereco={() => informarEnderecoFuncionario()}
                  pegaEndereco={e => pegaEnderecoFuncionario(e)}
                  updateFieldEndereco={e => updateFieldEnderecoFuncionario(e)} 
                  updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipalFuncionario(e)} 
                  addNovoEndereco={(e) => addNovoEnderecoFuncionario(e)}
                  cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                  editarEndereco={(e) => editarEnderecoFuncionario(e)}
                  removeEndereco={(e) => removeEnderecoFuncionario(e)}
                  informarNovoContato={() => informarContatoFuncionario()}
                  updateFieldContato={e => updateFieldContatoFuncionario(e)} 
                  updateFieldContatoPrincipal={e => updateFieldContatoPrincipalFuncionario(e)} 
                  addNovoContato={() => addNovoContatoFuncionario()}
                  editarContato={(e) => editarContatoFuncionario(e)}
                  removeContato={(e) => removeContatoFuncionario(e)}
                  informarNovoEmail={() => informarEmailFuncionario()}
                  updateFieldEmail={e => updateFieldEmailFuncionario(e)} 
                  updateFieldEmailPrincipal={e => updateFieldEmailPrincipalFuncionario(e)}
                  addNovoEmail={() => addNovoEmailFuncionario()}
                  editarEmail={(e) => editarEmailFuncionario(e)}
                  removeEmail={(e) => removeEmailFuncionario(e)}
                  acoesTabelaEndereco={state.acoesTabelaEndereco}
                  cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                  cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
                />
                <hr className="mg_top_10"/>
                <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                  <Grid item md={2}>
                    <Button fullWidth onClick={() => voltarTela()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                  </Grid>
                  <Grid item md={8}></Grid>
                  <Grid item md={2}>
                    <Button fullWidth color="success" variant="contained" size="small" endIcon={<ArrowForwardIcon />} onClick={e => informarContrato(e)}>Proximo</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {enableDadosContratoFuncionario &&
              <React.Fragment>
                <Grid container direction="row" className='mg_top_10'>
                  <Grid item md={12}>
                    <h4 className="titulo">Dados do Contrato</h4>
                  </Grid>
                </Grid>
                <FormContratoFuncionario
                  form='funcionario'
                  enableUpdate={enableUpdate}
                  updateField={e => updateFieldContratoFuncionario(e)}
                  dados={funcionario.contrato}
                  departamentos={state.departamentos.list}
                  cargos={state.cargosFiltrado.list}
                  funcaos={state.funcaosFiltrado.list}
                />
                <hr className="mg_top_10"/>
                <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                  <Grid item md={2}>
                    <Button fullWidth onClick={() => voltarFuncionario()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                  </Grid>
                  <Grid item md={8}></Grid>
                  <Grid item md={2}>
                    <Button fullWidth color="success" variant="contained" size="small" endIcon={<ArrowForwardIcon />} onClick={e => informarDadosBancario(e)}>Proximo</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {enableDadosContaBancariaFuncionario &&
              <React.Fragment>
                <Grid container direction="row" className='mg_top_10'>
                  <Grid item md={12}>
                    <h4 className="titulo">Dados das Contas Bancarias</h4>
                  </Grid>
                </Grid>
                {funcionario.contas.map(conta => {
                  return (
                    <div key={conta.index} className="cardEndereco">
                      <FormContaBancaria
                        form='funcionario'
                        updateField={e => updateFieldContaBancariaFuncionario(e, conta.index)}
                        updateFieldBanco={(event, value, index) => updateFieldBanco(event, value, conta.index)}
                        dados={conta}
                        banco={conta.banco}
                        bancos={state.bancos.list}
                        removerConta={(index) => removerConta(index)} 
                      />
                    </div>
                  )
                })}
                <hr className="mg_top_10"/>
                <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                  <Grid item md={4}>
                    <Button fullWidth onClick={() => addNovaContaBancariaFuncionario()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Nova Conta </Button>
                  </Grid>
                  <Grid item md={2}></Grid>
                  <Grid item md={3}>
                    <Button fullWidth onClick={() => voltarContrato()} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                  </Grid>
                  <Grid item md={3}>
                    <Button fullWidth onClick={() => informarUsuario()} color="success" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                  </Grid>
                </Grid>

              </React.Fragment>
            }
            {enableDadosUsuarioFuncionario &&
              <React.Fragment>
                <Grid container direction="row" className='mg_top_10'>
                  <Grid item md={12}>
                    <h4 className="titulo">Cadastro de Usuário (Representante)</h4>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6}>
                    <Autocomplete
                      onChange={(event, value) => updateFieldPerfil(event, value)}
                      freeSolo
                      options={perfils.list}
                      value={perfil}
                      getOptionLabel={option => option.descricao}
                      renderInput={(params) => (
                        <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={state.perfil.descricao} />
                      )}
                    />
                  </Grid>
                </Grid>
                <FormUsuario 
                  updateField={e => updateFieldUsuario(e)} 
                  dados={funcionario.usuario} 
                  handleClickShowPassword={e => handleClickShowPassword(e)}
                  handleClickShowPasswordRepetir={e => handleClickShowPasswordRepetir(e)}
                  showPassword={state.showPassword}
                  showPasswordRepetir={state.showPasswordRepetir}
                  nivelSenha={state.nivelSenha}
                  corSenha={state.corSenha}
                />
                <hr className="mg_top_10"/>
                <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                  <Grid item md={2}>
                    <Button fullWidth onClick={() => voltarTela()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                  </Grid>
                  <Grid item md={8}></Grid>
                  <Grid item md={2}>
                    <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
          <ModalAjuda 
            open={state.openModalAjuda}
            tela={"Cadastro de Representante"}
            handleClose={() => {setState((prevState) => ({...prevState, openModalAjuda: false}))}}
            markdown={state.markdown}
          />
        </div>
      }
      <Loading 
        open={loading}
        message={mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default CadastroRepresentante