import React, { useState, useEffect } from 'react';
import './Cargo.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormCargo from '../../components/forms/FormCargo'
import SaveIcon from '@mui/icons-material/Save';
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
  cargo: {
    id: "",
    descricao: "",
    departamento_id: "",
    dep_descricao: "",
    unidade_id: '',
    comissionado: true,
    ativo: true
  },
  cargos: {
    list: []
  },
  departamentos: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  openModalAjuda: false,
  markdown: ''
}

const CadastroCargo = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { cargoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 3000) => {
    const self = this
    setTimeout(() => {
      navigate("/cargo");
    }, timeout)
  }

  const updateField = async (event) => {
    const cargo = { ...state.cargo }

    let name = event.target.name
    let value = event.target.value

    if (name === "unidade_id") {
      try {

        const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/listByUnidade/${parseInt(value)}`, {}, getToken())
      
        let departamentosAtivos = []
          let departamentos = []

          departamentosAtivos = data.filter(u => u.ativo === true)

          departamentosAtivos.forEach(departamento => {
            departamentos.push({
              _id: departamento.id,
              descricao: departamento.descricao,
              ativo: departamento.ativo
            })
          })

          setState(prevState => ({...prevState,
            departamentos: {
              list: departamentos
            }
          }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }

    if (name === "departamento_id") {
      const departamento = state.departamentos.list.filter(u => u._id === parseInt(value))

      cargo.dep_descricao = departamento[0].descricao
    }

    cargo[name] = value
    setState(prevState => ({...prevState, cargo }))
  }

  const updateFieldAtivo = (event) => {
    const cargo = { ...state.cargo }
    cargo.ativo = !cargo.ativo
    setState(prevState => ({...prevState, cargo }))
  }

  const validateForm = () => {
    const { cargo } = state
    if (!cargo.descricao) return false
    if (!cargo.departamento_id) return false
    if (!cargo.unidade_id) return false

    return true
  }

  const salvar = async () => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    setState(prevState => ({...prevState, 
      loading: true,
      mensagemLoading: 'Cadastrando/Alterando o Cargo'
    }))

    const cargo = state.cargo

    const dados = {
      departamento_id: cargo.departamento_id,
      descricao: cargo.descricao,
      comissionado: cargo.comissionado,
      unidade_id: cargo.unidade_id,
      ativo: cargo.ativo
    }

    try {

      const method = cargo.id !== "" ? 'put' : 'post'
      const url = cargo.id !== "" ? `/cargos/${cargo.id}` : `/cargos`

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const handleCloseAlerta = () =>{
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/cargo/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'cargo')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (cargoId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())
        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          const {cargo} = state
          cargo.unidade_id = unidades[0].id
          setState(prevState => ({...prevState,cargo}))

          const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/listByUnidade/${unidades[0].id}`, {}, getToken())
          
          let departamentosAtivos = []
          let departamentos = []
  
          departamentosAtivos = data.filter(u => u.ativo === true)
  
          departamentosAtivos.forEach(departamento => {
            departamentos.push({
              _id: departamento.id,
              descricao: departamento.descricao,
              ativo: departamento.ativo
            })
          })
  
          setState(prevState => ({...prevState,
            departamentos: {
              list: departamentos
            }
          }))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (cargoId) {
        try {
          const { data: cargo } = await axios.get(`${window._env_.REACT_APP_API_URL}/cargos/${cargoId}`, getToken())

          setState(prevState => ({...prevState,
            cargo: {
              id: cargo.id,
              descricao: cargo.descricao,
              departamento_id: cargo.departamento_id,
              dep_descricao: cargo.departamento.descricao,
              comissionado: cargo.comissionado,
              unidade_id: cargo.unidade_id,
              ativo: cargo.ativo
            }
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
          return 
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))

    };
    
    fetchData();
  }, []);

  const { cargo } = state
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Cargos</h1>
                </Grid>
              </Grid>
              <FormCargo 
                departamentos={state.departamentos.list} 
                updateField={e => updateField(e)}
                updateFieldAtivo={e => updateFieldAtivo(e)} 
                dados={cargo}
                unidades={state.unidades.list}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={e => backPage(0)}>Voltar</Button>
                </Grid>
                <Grid item md={8} xs={false} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Cargo"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroCargo