import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, IconButton, Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '../../components/Table'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'codigo', numeric: false, disablePadding: false, label: 'Codigo' },
  { id: 'data_inicio', numeric: false, disablePadding: false, label: 'Data Incial' },
  { id: 'data_final', numeric: false, disablePadding: false, label: 'Data Final' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'responsavel', numeric: false, disablePadding: false, label: 'Responsavel' }
]

const acoesTabela = ['selecionarContrato']

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Selecionar Contrato</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <Table 
                headCell={cabecalhoTabela} 
                rows={props.contratos.map(value => {
                  return {
                    _id: value.id,
                    codigo: value.codigo,
                    data_inicio: moment(value.data_ini_vig).format('DD/MM/YYYY'),
                    data_final: moment(value.data_fim_vig).format('DD/MM/YYYY'),
                    status: value.statusAtual.estado_contrato,
                    responsavel: value.resp_fin[0] ? value.resp_fin[0].nome : ''
                  }
                })} 
                selecionarContrato={ e => props.selecionarContrato(e)}
                acoes={acoesTabela}                
              />
            </Grid>
          </Grid>
          <br />
        </StyledPaper>
      </Modal>
    </div>
  );
}
