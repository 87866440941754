import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import Chart from "react-google-charts";
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
        <Chart
          chartType="LineChart"
          width="100%"
          height='500px'
          data={props.dataChartAno}
          options={{
            curveType: "function",
            legend: { position: 'top', alignment: 'start' },
            chartArea:{
              left: 100,
              top: 40,
              bottom: 50,
              width: '90%',
            },
            annotations: {
              1: {
                style: 'point'
              },
              2: {
                style: 'point'
              },
              3: {
                style: 'point'
              },
              4: {
                style: 'point'
              }
            },
            series: {
              2: { lineDashStyle: [4, 4] },
              3: { lineDashStyle: [4, 4] },
            },
            lineWidth: 2,
            pointSize: 4,
            hAxis : {
              gridlines : {
                count : 2
              }
            },
            vAxis : {
              gridlines : {
                count : 2
              }
            },
            colors: ['green', 'red', '#00BFFF', 'orange']
          }}
          formatters={[
            {
              type: 'NumberFormat',
              column: 1,
              options: {
                groupingSymbol: '.',
                decimalSymbol: ','
              }
            }
          ]}
        />
        </StyledPaper>
      </Modal>
    </div>
  );
}
