import React from 'react'
import { Grid } from '@mui/material'
import Table from './../../../components/Table'
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Chart } from "react-google-charts";
import real from './../../../services/real'

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let dadosNFSE= {
  qtd_emitidas: 5,
  limite: 50
}

const NFSE = ({tab, handleChange, cabecalhoTabela, list_nfse, acoesTabela, imprimirNFSE, filtro, count,
  handleChangePage, handleChangeRowsPerPage, total_notas_geradas, total_vendas_sem_nfse, qtd_notas_emitidas}) => (
  <React.Fragment>
    <AppBar position="static" className="mg_top_20">
      <Tabs id="viewCliente" value={tab} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Listagem" {...a11yProps(0)} />
        {/* <Tab label="Gráficos" {...a11yProps(1)} /> */}
      </Tabs>
    </AppBar>
    <TabPanel value={tab} index={0}>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12}>
          {list_nfse.length > 0 &&
            <Table
              headCell={cabecalhoTabela}
              rows={list_nfse}
              acoes={acoesTabela}
              imprimirNFSE={(e) => imprimirNFSE(e)}
              count={count}
              handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
              handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
              filtro={filtro}
            />
          }
          {list_nfse.length === 0 &&
            <p>Nenhuma nota encontrada no período informado</p>
          }
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12}>
          <p style={{fontSize: '20px'}}>Total em Notas Emitidas: R$ {real(somarValores(list_nfse.map(value => {return value.valor})))}</p>
        </Grid>
      </Grid>
    </TabPanel>
    <TabPanel value={tab} index={1}>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12} sm={12}>
          <h4>Mês Selecionado: OUTUBRO/2022</h4>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={6} xs={12} sm={6} style={{borderRight: '1px solid #c0c0c0'}}>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h3>Controle de emissão de notas:</h3>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={4}>
              <div className='card_nfse'>
                <p>Notas Emitidas</p>
                <p>{qtd_notas_emitidas}</p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <div className='card_nfse'>
                <p>Notas a emitir.</p>
                <p>{(dadosNFSE.limite - qtd_notas_emitidas)}</p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <div className='card_nfse'>
                <p>Total de Notas</p>
                <p>{dadosNFSE.limite}</p>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={4}></Grid>
            <Grid item md={4} xs={12} sm={4}>
              <div style={{width: '100%', textAlign: 'center'}}>
                <h5 className='titulo' style={{margin: '0', marginBottom: '27px'}}>% NFSE Emitidas</h5>
                <Chart
                  chartType="Gauge"
                  width="100%"
                  height="400px"
                  data={[
                    ["Label", "Value"],
                    ["", parseFloat(((qtd_notas_emitidas/dadosNFSE.limite) * 100).toFixed(2))]
                  ]}
                  options={{
                    redFrom: 90,
                    redTo: 100,
                    yellowFrom: 75,
                    yellowTo: 90,
                    greenFrom: 0,
                    greenTo:75,
                    minorTicks: 5,
                    majorTicks: ['0', '25', '50', '75', '100']
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={4}></Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} sm={6}>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h3>Valor Faturado X Notas Emitidas:</h3>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={4}>
              <div className='card_nfse'>
                <p>Notas Emitidas</p>
                <p>R$ {real(total_notas_geradas)}</p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <div className='card_nfse'>
                <p>Total Faturamento</p>
                <p>R$ {real(total_vendas_sem_nfse + total_notas_geradas)}</p>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={4}></Grid>
            <Grid item md={4} xs={12} sm={4}>
              <div style={{width: '100%', textAlign: 'center'}}>
                <h5 className='titulo' style={{margin: '0'}}>% Faturamento com NFSE</h5>
                <Chart
                  chartType="Gauge"
                  width="100%"
                  height="400px"
                  data={[
                    ["Label", "Value"],
                    ["", parseFloat(((total_notas_geradas/(total_vendas_sem_nfse + total_notas_geradas)) * 100).toFixed(2))]
                  ]}
                  options={{
                    redFrom: 0,
                    redTo: 50,
                    yellowFrom: 50,
                    yellowTo: 75,
                    greenFrom: 75,
                    greenTo:100,
                    minorTicks: 5,
                    majorTicks: ['0', '25', '50', '75', '100']
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={4}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPanel>
  </React.Fragment>
)

export default NFSE
