import React from 'react'
import { Grid } from '@mui/material'
import Table from './../../../components/Table'
import real from './../../../services/real'

const VendaVouchers = ({urlView, cabecalhoTabela, relatorios, acoesTabela, viewRow, urlViewAgenda, cabecalhoTabelaAgenda, relatoriosAgenda, acoesTabelaAgenda,
  valor_total}) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={12} xs={12}>
        <h4 className='titulo'>Vouchers:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12}>
        <Table
          urlView={urlView}
          headCell={cabecalhoTabela}
          rows={relatorios.map((value, key) => {
            return {
              _id:key,
              valor_form: 'R$ ' + real(value.valor),
              ...value
            }
          })}
          acoes={acoesTabela}
          viewRow={(e, acao, status) => viewRow(e, acao, status)} 
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={12} xs={12}>
        <h4 className='titulo'>Agendas:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12}>
        <Table
          urlView={urlViewAgenda}
          headCell={cabecalhoTabelaAgenda}
          rows={relatoriosAgenda.map((value, key) => {
            return {
              _id:key,
              valor_form: 'R$ ' + real(value.valor),
              ...value
            }
          })}
          acoes={acoesTabelaAgenda}
          viewRow={(e, acao, status) => viewRow(e, acao, status)} 
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={12} xs={12}>
        <h4 className='titulo'>Valor Total da Vendas: R$ {real(valor_total)}</h4>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default VendaVouchers
