import React, { useState, useEffect } from 'react';
import './../Cliente.scss'
import './../../../main/ultil.scss'
import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import Table from '../../../components/Table'
import Alert from './../../../components/templates/Alert'
import { useNavigate } from 'react-router-dom';

const initialState = {
  dependentes:{
    list: []
  },
  cabecalhoTabela: [
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome/Razão Social' },
    { id: 'sobrenome', numeric: false, disablePadding: false, label: 'Sobrenome/Nome Fantasia' },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo Pessoa' },
    { id: 'cpf_cnpj', numeric: false, disablePadding: false, label: 'CPF/CNPJ' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: ['viewDependente', 'update'],
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

const Dependentes = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const viewDependente = (row) => {
    navigate(`/cliente/view/${row._id}`);
    window.location.reload()
    return;
  }

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({...prevState,msgLoading: 'Carregando Dados dos Dependentes...'}))

      try {

        const cliente = props.cliente

        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/cliente/dependentes/${cliente._id}`, getToken())
      
        let dependentes = []

        for (const resp of data) {
          let index = dependentes.findIndex(param => param._id === resp.contrato.cliente.pessoa.id)

          if(index === -1){
            dependentes.push({
              _id: resp.contrato.cliente.pessoa.id,
              tipo: resp.contrato.cliente.pessoa.tipo,
              nome: resp.contrato.cliente.pessoa.tipo === 'FISICA' ? resp.contrato.cliente.pessoa.fisica.nome : resp.contrato.cliente.pessoa.juridica.razaosocial,
              sobrenome: resp.contrato.cliente.pessoa.tipo === 'FISICA' ? resp.contrato.cliente.pessoa.fisica.sobrenome : resp.contrato.cliente.pessoa.juridica.nomefantasia,
              cpf_cnpj: resp.contrato.cliente.pessoa.tipo === 'FISICA' ? resp.contrato.cliente.pessoa.fisica.cpf : resp.contrato.cliente.pessoa.juridica.cnpj,
              ativo: resp.contrato.cliente.pessoa.ativo
            })
          }
        }

        setState(prevState => ({...prevState,
          dependentes:{
            list: dependentes
          }
        }))
      
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    };
    
    fetchData();
  }, []);

  const {dependentes} = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>{state.msgLoading}</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} sm={12} xs={12}>
              <h4 className='titulo'>Dependentes:</h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table 
                urlUpdate="/cliente/cadastro/" 
                viewDependente={e => viewDependente(e)}
                headCell={state.cabecalhoTabela} 
                rows={dependentes.list} 
                acoes={state.acoesTabela}
              />
            </Grid>
          </Grid>
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
    </React.Fragment>
  )
}

export default Dependentes