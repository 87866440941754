import React, { useState, useEffect } from 'react';
import './Franqueado.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Link } from 'react-router-dom'
import { Grid, TextField, Button } from '@mui/material'
import ModalAjuda from './../../components/modals/Ajuda'
import Loading from './../../components/templates/Loading'
import moment from 'moment';
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import { useParams, useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj')
const cnpj = require('node-cpf-cnpj')

const initialState = {
  franqueado: {},
  franqueados: {
    list: [
      {

      }
    ]
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  mensagemLoading: 'Carregando Grupo Econômico...',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: '',
}

const View = () => {
  const [state, setState] = useState({ ...initialState });
  const { franqueadoId } = useParams();
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/franqueado");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }));
  }

  useEffect(() => {

    const fetchData = async () => {
      const helpPath = require("./../../help/franqueado/View.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState((prevState) => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({
        ...prevState,
        loading: true
      }));

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'franqueado')[0]

      if(permissoes){
        setState(prevState => ({
          ...prevState,
          permissoes
        }));
      }

      try {
        const { data: franqueado } = await axios.get(`${window._env_.REACT_APP_API_URL}/franqueados/${franqueadoId}`, getToken())

        setState(prevState => ({
          ...prevState,
          franqueado
        }));

      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }));
      }

      setState(prevState => ({
        ...prevState,
        loading: false
      }));
    };

    fetchData();

    setState(prevState => ({
      ...prevState,
      loading: false
    }));
  }, []);

  const { franqueado, permissoes } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState((prevState) => ({...prevState, openModalAjuda: true}))}}>
            <div>
              {franqueado.pessoa &&
                <div id="view" className="cardEndereco">
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={8} xs={12} sm={12}>
                      <h1 className="titulo">Dados Grupos Econômico: </h1>
                    </Grid>
                    {!permissoes.alterar && 
                      <Grid item md={2} xs={12} sm={6}></Grid>
                    }
                    <Grid item md={2} xs={12} sm={6}>
                      <Link to={`/franqueado`}>
                        <Button className="btnCadastrar" variant="contained" color="secondary">
                          Voltar
                        </Button>
                      </Link>
                    </Grid>
                    {permissoes.alterar && 
                      <Grid item md={2} xs={12} sm={6}>
                        <Link to={`/franqueado/update/${franqueado.id}`}>
                          <Button className="btnCadastrar" variant="contained" color="primary">
                            Editar Grupo
                          </Button>
                        </Link>
                      </Grid>
                    }
                  </Grid>
                  {franqueado.pessoa.pes_tipo === "FISICA" &&
                    <div>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Dados Pessoa Física: </h4>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={franqueado.pessoa.fisica.nome} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(franqueado.pessoa.fisica.cpf)} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={franqueado.pessoa.fisica.rg} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(franqueado.pessoa.fisica.datanascimento).format('YYYY-MM-DD')} disabled />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {franqueado.pessoa.pes_tipo === "JURIDICA" &&
                    <div>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Dados Pessoa Jurídica: </h4>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={franqueado.pessoa.juridica.razaosocial} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantasia" value={franqueado.pessoa.juridica.nomefantasia} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={cnpj.format(franqueado.pessoa.juridica.cnpj)} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={franqueado.pessoa.juridica.inscricaoestadual} disabled />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Fundação" value={moment(franqueado.pessoa.juridica.datafundacao).format('YYYY-MM-DD')} disabled />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {franqueado.pessoa.enderecos.length > 0 &&
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Endereços Franqueado: </h4>
                      </Grid>
                    </Grid>
                  }
                  {franqueado.pessoa.enderecos.map(endereco => {
                    return (
                      <div className="cardEndereco" key={endereco.id}>
                        {endereco.principal &&
                          <h4 className="titulo">Endereço Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.num_cep} disabled />
                          </Grid>
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.logradouro} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.numero} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.complemento} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.bairro} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.cidade} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.estado} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  {franqueado.pessoa.telefones.length > 0 &&
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Telefones Franqueado: </h4>
                      </Grid>
                    </Grid>
                  }
                  {franqueado.pessoa.telefones.map(telefone => {
                    return (
                      <div className="cardEndereco" key={telefone.id}>
                        {telefone.principal &&
                          <h4 className="titulo">Telefone Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.numero} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  {franqueado.pessoa.emails.length > 0 &&
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">E-mails Franqueado: </h4>
                      </Grid>
                    </Grid>
                  }
                  {franqueado.pessoa.emails.map(email => {
                    return (
                      <div className="cardEndereco" key={email.id}>
                        {email.principal &&
                          <h4 className="titulo">E-mail Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.email} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              }
              {franqueado.responsavel &&
                <div id="view" className="cardEndereco">
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={9} xs={12} sm={12}>
                      <h1 className="titulo">Dados Responsavel: </h1>
                    </Grid>
                    {permissoes.alterar && 
                      <Grid item md={3} xs={12} sm={6}>
                        <Link to={`/franqueado/updateResponsavel/${franqueado.id}`}>
                          <Button className="btnCadastrar" variant="contained" color="primary">
                            Editar Responsável
                          </Button>
                        </Link>
                      </Grid>
                    }
                  </Grid>
                  <div>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Dados Pessoa Física: </h4>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={franqueado.responsavel.nome} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(franqueado.responsavel.cpf)} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={franqueado.responsavel.rg} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(franqueado.responsavel.datanascimento).format('YYYY-MM-DD')} disabled />
                      </Grid>
                    </Grid>
                  </div>
                  {franqueado.responsavel.pessoa.enderecos.length > 0 &&
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Endereços Responsável: </h4>
                      </Grid>
                    </Grid>
                  }
                  {franqueado.responsavel.pessoa.enderecos.map(endereco => {
                    return (
                      <div className="cardEndereco" key={endereco.id}>
                        {endereco.principal &&
                          <h4 className="titulo">Endereço Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.num_cep} disabled />
                          </Grid>
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.logradouro} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.numero} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.complemento} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.bairro} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.cidade} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.estado} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  {franqueado.responsavel.pessoa.telefones.length > 0 &&
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Telefones Responsável: </h4>
                      </Grid>
                    </Grid>
                  }
                  {franqueado.responsavel.pessoa.telefones.map(telefone => {
                    return (
                      <div className="cardEndereco" key={telefone.id}>
                        {telefone.principal &&
                          <h4 className="titulo">Telefone Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.numero} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  {franqueado.responsavel.pessoa.emails.length > 0 &&
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">E-mails Responsável: </h4>
                      </Grid>
                    </Grid>
                  }
                  {franqueado.responsavel.pessoa.emails.map(email => {
                    return (
                      <div className="cardEndereco" key={email.id}>
                        {email.principal &&
                          <h4 className="titulo">E-mail Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.email} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              }
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
          <ModalAjuda 
            open={state.openModalAjuda}
            tela={"Visualizar Grupo Econômico"}
            handleClose={() => {setState((prevState) => ({...prevState, openModalAjuda: false}))}}
            markdown={state.markdown}
          />
        </div >
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default View