import React from 'react'
import CNPJInput from './../mask/CNPJInput'
import { Grid, TextField } from '@mui/material'

const inputTexts = [
  {
    name: "razaoSocial",
    label: "Razão Social*",
    type: "text"
  },
  {
    name: "nomeFantasia",
    label: "Nome Fantasia*",
    type: "text"
  },
  {
    name: "cnpj",
    label: "CNPJ*",
    type: "text"
  },
  {
    name: "inscricaoEstadual",
    label: "Inscrição Estadual",
    type: "text"
  },
  {
    name: "dataFundacao",
    label: "Data Fundação",
    type: "date"
  },
  {
    name: "nome_responsavel",
    label: "Nome Responsável",
    type: "text"
  },
]

const FormPessoaJuridica = ({ form, dados, updateField, validarCpfCnpj, disabledCNPJ }) => (
  <React.Fragment>
    <Grid container direction="row" className="mg_top_10">
      <Grid item md={12}>
        <h5>Dados Pessoais - Pessoa Jurídica:</h5>
      </Grid>
    </Grid>

    <Grid container spacing={1} direction="row" className="mg_top_10">
      {inputTexts.map(input => {
        if (input.name === "cnpj") {
          return (
            <Grid item md={3} xs={12} sm={6} key={input.name}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 18 }}
                className="input"
                type={input.type}
                name={input.name}
                label={input.label}
                value={dados[input.name]}
                variant="outlined"
                size="small"
                onChange={(e) => updateField(e)}
                onBlur={e => validarCpfCnpj(e)}
                InputLabelProps={{
                  shrink: true
                }} 
                disabled={disabledCNPJ ? true: false}/>
              {/* <span id={`error-pj-${form}-${input.name}`} className="error">Informar {input.label}</span> */}
            </Grid>
          )
        } else {
          return (
            <Grid item md={3} xs={12} sm={6} key={input.name}>
              <TextField
                fullWidth
                className="input"
                type={input.type}
                name={input.name}
                label={input.label}
                value={dados[input.name]}
                variant="outlined"
                size="small"
                onChange={(e) => updateField(e)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              {/* <span id={`error-pj-${form}-${input.name}`} className="error">Informar {input.label}</span> */}
            </Grid>
          )
        }
      })}
    </Grid>
    <br />
    <hr />
  </React.Fragment>
)

export default FormPessoaJuridica