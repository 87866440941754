import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  marginTop: 100,
  width: '30%', 
  marginLeft: '35%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const {dados, recorrencia} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p>Esta conta é recorrente, e existem {props.dados.qtd_recorrencia} recorrências abertas.</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg-top--20">
            <Grid item md={12} xs={12} sm={12}>
              <FormControl component="fieldset" id='radio'>
                <RadioGroup aria-label="gender" name="acao_cancelamento" value={dados.acao_cancelamento} onChange={(e) => props.updateData(e)}>
                  <FormControlLabel value="apenas_esta" control={<Radio />} label="Alterar apenas esta recorrência" />
                  <FormControlLabel value="todas" control={<Radio />} label="Alterar todas as recorrências" />
                  <FormControlLabel value="a_partir_hoje" control={<Radio />} label="Alterar todas a partir desta recorrência" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <hr />
          <br />
          <React.Fragment>
            {(dados.acao_cancelamento === 'todas' || dados.acao_cancelamento === 'a_partir_hoje') &&
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={6}>
                  <TextField 
                    type='number'
                    className="input" 
                    label="Repetir a cada*" 
                    variant="outlined" 
                    name="recorrenciaACada" 
                    size="small"
                    disabled
                    value={recorrencia.recorrenciaACada} 
                    onChange={(e) => props.updateFieldRecorrencia(e)} 
                    InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    label="Repetir a Cada*" 
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tipoRecorrencia"
                    disabled
                    value={recorrencia.tipoRecorrencia}
                    onChangeCapture={(e) => props.updateFieldRecorrencia(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=''>  </option>
                    <option value='d'> Dia </option>
                    <option value='w'> Semana </option>
                    <option value='M'> Mês </option>
                    <option value='y'> Ano </option>
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12} sm={6}>
                  <TextField 
                    type='number'
                    className="input" 
                    label="Num. de Ocorrências*" 
                    variant="outlined" 
                    name="qtdRecorrencia" 
                    size="small"
                    value={recorrencia.qtdRecorrencia} 
                    disabled
                    // onChange={(e) => props.updateFieldRecorrencia(e)} 
                    InputLabelProps={{ shrink: true }}/>
                </Grid>
              </Grid>
            }
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <p className='avisoAlerta'><i>Esta operação não poderá ser desfeita.</i></p>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item md={6} xs={6} sm={6}>
                <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={dados.acao_cancelamento === '' ? true : false}>Sim</Button>
              </Grid>
              <Grid item md={6} xs={6} sm={6}>
                <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
              </Grid>
            </Grid>
          </React.Fragment>
        </StyledPaper>
      </Modal>
    </div>
  );
}
