import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import Table from '../../components/Table'
import Autocomplete from '@mui/material/Autocomplete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'os', numeric: false, disablePadding: true, label: 'Os' },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'servico', numeric: false, disablePadding: true, label: 'Serviço' },
  { id: 'atendente', numeric: false, disablePadding: true, label: 'Fisio' },
  { id: 'sala', numeric: false, disablePadding: true, label: 'Sala.' },
  { id: 'data', numeric: false, disablePadding: true, label: 'Data' },
]

const acoesTabela = [
  'viewAgenda'
]

export default function SimpleModal(props) {
  const theme = useTheme();

  const {clientes, cliente, agendasCliente, filtroAgendaCliente, consultaRealizada} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Buscar Agendas</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={3} xs={12} sm={3}>
              <Autocomplete
                onChange={(event, value) => props.updateFieldCliente(event, value)}
                freeSolo
                options={clientes.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)}
                value={cliente}
                id='cliente'
                getOptionLabel={option => `${option.nome}`}
                renderInput={(params) => (
                  <TextField 
                    InputProps={{ ...params.InputProps, type: 'search' }} 
                    {...params} 
                    size="small" 
                    label="Cliente*" 
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }} 
                    value={`${cliente.nome}`}
                    placeholder='Buscar Cliente'
                    onChange={(e) => props.updateFiltroPessoa(e)}
                  />
                )}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                type='date'
                className="input" 
                label="Data Inicio" 
                variant="outlined" 
                size="small" 
                name="data_inicio" 
                value={filtroAgendaCliente.data_inicio}
                InputProps={{ inputProps: { max: filtroAgendaCliente.data_fim } }}
                onChange={(e) => props.updateFieldData(e)} 
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                type='date'
                className="input" 
                label="Data Fim" 
                variant="outlined" 
                size="small" 
                name="data_fim" 
                value={filtroAgendaCliente.data_fim} 
                InputProps={{ inputProps: { min: filtroAgendaCliente.data_inicio } }}
                onChange={(e) => props.updateFieldData(e)} 
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <Button 
                onClick={() => props.buscarAgendaCliente()} 
                size="small" 
                fullWidth
                variant="contained" 
                color="primary"
                disabled={!cliente.pessoa_id}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              {(agendasCliente.length === 0 && !consultaRealizada) &&
                <p style={{fontSize: 18, marginTop: 5, marginBottom: 10}}>Por favor informar o cliente!</p>
              }
              {(agendasCliente.length === 0 && consultaRealizada) &&
                <p style={{fontSize: 18, marginTop: 5, marginBottom: 10}} className='error'>Nenhuma agenda foi encontrada!</p>
              }
              {agendasCliente.length > 0 &&
                <Table 
                  headCell={cabecalhoTabela} 
                  rows={agendasCliente} 
                  acoes={acoesTabela} 
                  viewAgenda={e => props.viewAgenda(e)}
                />
              }
            </Grid>
          </Grid>
          <hr />
          <Grid container direction="row" spacing={1}>
            <Grid item md={9} xs={12} sm={8}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="secondary" 
                startIcon={<ArrowBackIcon />}
                onClick={props.handleClose}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
