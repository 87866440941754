import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'

const FormFuncao = ({ departamentos, unidades, cargos, dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Departamento"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="departamento_id"
          value={dados.departamento_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.unidade_id === ''}
        >
          <option value={dados.departamento_id === "" ? "Selecionar Departamento" : dados.departamento_id}> {dados.departamento_id === "" ? "Selecionar Departamento" : dados.dep_descricao} </option>
          {departamentos.map(departamento => {
            if (departamento.id !== dados.departamento_id) {
              return (
                <option key={departamento.id} value={departamento.id}> {departamento.descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Cargo"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="cargo_id"
          value={dados.cargo_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}}
          disabled={dados.departamento_id === ''}
        >
          <option value={dados.cargo_id === "" ? "Selecionar cargo" : dados.cargo_id}> {dados.cargo_id === "" ? "Selecionar Cargo" : dados.car_descricao} </option>
          {cargos.map(cargo => {
            if (cargo.id !== dados.cargo_id) {
              return (
                <option key={cargo.id} value={cargo.id}> {cargo.descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Função" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={dados.descricao} 
          onChange={(e) => updateField(e)} 
          disabled={dados.cargo_id === ''}
          InputLabelProps={{ shrink: true}}/>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Nivel da Função" 
          variant="outlined" 
          size="small" 
          name="nivel" 
          value={dados.nivel} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true}}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="ativo"
              color="primary"
              checked={dados.ativo}
            />
          }
          label="Ativo"
          name="ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
    <hr />
  </React.Fragment>
)

export default FormFuncao