const run = (slug, permissao) => {
  const perfil = JSON.parse(localStorage.getItem('perfil'))

  let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === slug)[0]

  if(permissoes[permissao]){
    return {
      libera: true,
      permissoes
    }
  }

  return {
    libera: false,
    permissoes
  }
}

export default run