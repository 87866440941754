import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './Cliente.scss'
import { Link } from 'react-router-dom'
import Cliente from './View/Cliente'
import Contrato from './View/Contrato'
import Dependentes from './View/Dependentes'
import ContratoUnidade from './View/ContratoUnidade'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Button, TextField } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import { useTheme } from '@mui/material/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#FFF',
}));

export default function SimpleTabs(props) {
  const tabs = {
    cliente: 0,
    contrato: 1,
    responsavel: 2,
    dependente: 3,
    dependente: 4,
  }

  const [value, setValue] = React.useState(tabs[props.tab] ? tabs[props.tab] : 0);

  const cliente = props.dadosCliente
  const uploadedFiles = props.uploadedFiles
  const responsaveis = props.dadosResponsavel
  const dadosComplementaresPF = props.dadosComplementaresPF
  const pessoa = JSON.parse(localStorage.getItem('pessoa'))
  const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

  const handleChange = (event, newValue) => {
    const tabsUrl = {
      0: 'cliente',
      1: 'contrato',
      2: 'responsavel',
      3: 'dependente',
      4: 'contrato-unidade',
    }

    setValue(newValue)
    props.navigate(`/cliente/view/${cliente._id}/${tabsUrl[newValue]}`);
    return;
  };

  let criaUrl = () => {
    const filtro = JSON.parse(localStorage.getItem('filtro_pessoas'))

    if(filtro){
      let url = `/cliente?limit=${filtro.limit}&offset=${filtro.offset}`
  
      if(filtro.filtrar_por !== ''){
        url += `&filtrar_por=${filtro.filtrar_por.replaceAll(' ', '%')}&value=${filtro.value.replaceAll(' ', '%')}`
      }
  
      if(filtro.nome !== ''){
        url += `&nome=${filtro.nome.replaceAll(' ', '%')}`
      }
  
      if(filtro.cpf_cnpj !== ''){
        url += `&cpf_cnpj=${filtro.cpf_cnpj.replaceAll(' ', '')}`
      }
  
      if(filtro.telefone !== ''){
        url += `&telefone=${filtro.telefone.replaceAll(' ', '')}`
      }
  
      if(filtro.ativo !== ''){
        url += `&ativo=${filtro.ativo.replaceAll(' ', '')}`
      }
  
      if(filtro.flag !== ''){
        url += `&flag=${filtro.flag.replaceAll(' ', '')}`
      }
  
      return url

    }

    return `/cliente?limit=10&offset=0`

  }

  const theme = useTheme();

  return (
    <Root theme={theme}>
      <AppBar position="static">
        <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Dados da Pessoa" {...a11yProps(0)} />
          {(cliente.cliente && props.permissoesContrato.visualizar) && 
            <Tab label="Contratos" {...a11yProps(1)} />
          }
          {(cliente.contrato && cliente.contrato.resp_fin.length > 0) &&
            <Tab label="Responsável Financeiro" {...a11yProps(2)} />
          }
          {cliente.flags.includes('RESPONSÁVEL') && 
            <Tab label="Dependentes Financeiro" {...a11yProps(3)} />
          }
          {cliente.flags.includes('UNIDADE') && 
            <Tab label="Contrato Unidade" {...a11yProps(4)} />
          }
        </Tabs>
      </AppBar>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={2} xs={6} sm={6}>
          <Link to={criaUrl()}>
            <Button fullWidth size='small' variant="contained" color="secondary" startIcon={<ArrowBackIcon />}>
              Voltar Listagem
            </Button>
          </Link>
        </Grid>
        {cliente.flags.includes('CLIENTE') &&
          <Grid item md={2} sm={6} xs={3}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Codigo" value={cliente.codigo || ""} InputLabelProps={{ shrink: true }} disabled />
          </Grid>
        }
        <Grid item md={3} sm={6} xs={3}>
          <TextField fullWidth size="small" variant="outlined" className="input" label={cliente.tipoPessoa === 'FISICA' ? "Nome" : "Razão Social"} value={cliente.tipoPessoa === 'FISICA' ? cliente.pessoaFisica.nome : cliente.pessoaJuridica.razaoSocial} disabled />
        </Grid>
        {tipo === 'MANTENEDOR' &&
          <Grid item md={4}>
            {cliente.flags.map((flag, key) => {
              return (
                <div key={key} style={{padding: '2px 2px'}} className={`card-flag-${flag.toLowerCase()}`}>{flag}</div>
              )
            })}
          </Grid>
        }
        {tipo !== 'MANTENEDOR' &&
          <Grid item md={4}>
            {cliente.flags.map((flag, key) => {
              return (
                <React.Fragment>
                  {(flag !== 'REPRESENTANTE' && flag !== 'GRUPOECONOMICO' && flag !== 'UNIDADE' ) &&
                    <div key={key} style={{padding: '2px 2px'}} className={`card-flag-${flag.toLowerCase()}`}>{flag}</div>
                  }
                </React.Fragment>
              )
            })}
          </Grid>
        }
      </Grid>
      <br />
      <hr />
      <TabPanel value={value} index={0}>
        <div className="cardEndereco">
          <Cliente cliente={cliente} dadosComplementaresPF={dadosComplementaresPF} uploadedFiles={uploadedFiles}/>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Contrato cliente={cliente} permissoesContrato={props.permissoesContrato}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {responsaveis.map((responsavel, key) => {
          return (
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row">
                <Grid item md={12} sm={12} xs={12}>
                  <h4 className="titulo">Responsavel:</h4>
                </Grid>
              </Grid>
              {responsavel.pessoa_tipo === 'FISICA' &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={responsavel.pessoaFisica.nome} disabled />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Sobrenome" value={responsavel.pessoaFisica.sobrenome} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={responsavel.pessoaFisica.cpf} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={responsavel.pessoaFisica.rg} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={responsavel.pessoaFisica.dataNascimento} disabled />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {responsavel.pessoa_tipo === 'JURIDICA' &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={responsavel.pessoaJuridica.razaoSocial} disabled />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantasia" value={responsavel.pessoaJuridica.nomeFantasia} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={responsavel.pessoaJuridica.cnpj} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={responsavel.pessoaJuridica.inscricaoEstadual} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Data Fundação" value={responsavel.pessoaJuridica.dataFundacao} disabled />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {responsavel.enderecos.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Endereços do Responsável: </h4>
                    </Grid>
                  </Grid>
                  {responsavel.enderecos.map(endereco => {
                    return (
                      <div className="cardEndereco" key={endereco.index}>
                        {endereco.principal &&
                          <h4 className="titulo">Endereço Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.cep} disabled />
                          </Grid>
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.rua} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.numero} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.complemento} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.bairro} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.cidade} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.estado} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.tipoEndereco} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </React.Fragment>
              }

              {responsavel.contatos.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Telefones do Responsável: </h4>
                    </Grid>
                  </Grid>
                  {responsavel.contatos.map(telefone => {
                    return (
                      <div className="cardEndereco" key={telefone.index}>
                        {telefone.principal &&
                          <h4 className="titulo">Telefone Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.numero} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tipoContato} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </React.Fragment>
              }

              {responsavel.emails.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">E-mails do Responsável: </h4>
                    </Grid>
                  </Grid>
                  {responsavel.emails.map(email => {
                    return (
                      <div className="cardEndereco" key={email.index}>
                        {email.principal &&
                          <h4 className="titulo">E-mail Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.email} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </React.Fragment>
              }
              <Grid container spacing={1} direction="row">
                <Grid item md={3} sm={12} xs={4}>
                  <Link to={`/cliente/cadastro/${responsavel.pessoa_id}`}>
                    <Button fullWidth size='small' variant="contained" color="primary" startIcon={<CreateIcon />}>
                      Editar Responsavel
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          )
        })}
      </TabPanel>
      {cliente.flags.includes('RESPONSÁVEL') && 
        <TabPanel value={value} index={3}>
          <Dependentes cliente={cliente} {...props.props} />
        </TabPanel>
      }
      {cliente.flags.includes('UNIDADE') && 
        <TabPanel value={value} index={4}>
          <ContratoUnidade cliente={cliente} {...props.props} />
        </TabPanel>
      }
    </Root>
  );
}
