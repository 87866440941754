import React from 'react'
import moment from 'moment';
import { Grid, TextField } from '@mui/material'

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

const FormContratoFuncionario = ({ departamentos, cargos, funcaos, dados, updateField, enableUpdate }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Departamento*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idDepartamento"
          value={dados.departamento}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}}
          disabled={enableUpdate ? true : (dados.unidadetrabalho_id === '' ? true : false)}
        >
          <option value={dados.departamento === "" ? "" : dados.idDepartamento}> {dados.departamento === "" ? "Selecionar Departamento" : dados.departamento} </option>
          {
            departamentos.map(departamento => {
              if (departamento.id !== dados.idDepartamento) {
                return (
                  <option key={departamento.id} value={departamento.id}> {departamento.descricao} </option>
                )
              } else {
                return false
              }
            })
          }
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Cargo*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idCargo"
          value={dados.cargo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
          disabled={enableUpdate}
        >
          <option value={dados.cargo === "" ? "" : dados.idCargo}> {dados.cargo === "" ? "Selecionar Cargo" : dados.cargo} </option>
          {
            cargos.map(cargo => {
              if (cargo.id !== dados.idCargo) {
                return (
                  <option key={cargo.id} value={cargo.id}> {cargo.descricao} </option>
                )
              } else {
                return false
              }
            })
          }
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Funcão*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idFuncao"
          value={dados.funcao}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
          disabled={enableUpdate}
        >
          <option value={dados.funcao === "" ? "" : dados.idFuncao}> {dados.funcao === "" ? "Selecionar Função" : dados.funcao} </option>
          {
            funcaos.map(funcao => {
              if (funcao.id !== dados.idFuncao) {
                return (
                  <option key={funcao.id} value={funcao.id}> {funcao.descricao} </option>
                )
              } else {
                return false
              }
            })
          }
        </TextField>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField type="date" className="input" label="Data Inicial*" variant="outlined" size="small" name="dataInicial" value={dados.dataInicial} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { max: dataHojeFormatado } }} disabled={enableUpdate} />
      </Grid>
      {enableUpdate &&
        <Grid item md={4} xs={12} sm={6}>
          <TextField type="date" className="input" label="Data Final" variant="outlined" size="small" name="dataFinal" value={dados.dataFinal} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { max: dataHojeFormatado } }} disabled={enableUpdate} />
        </Grid>
      }
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <TextField
          id="outlined-multiline-static"
          label="Observação"
          className="input"
          multiline
          rows={5}
          size="small"
          variant="outlined"
          name="observacao"
          value={dados.observacao}
          onChange={(e) => updateField(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)


export default FormContratoFuncionario