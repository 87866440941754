import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',
  marginTop: 100,
  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  
  let {disabledButton, dados} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente cancelar essa parcela?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                className="input"
                label="Motivo do cancelamento*"
                variant="outlined"
                size="small"
                name="motivo_cancelamento"
                value={dados.motivo_cancelamento}
                onChange={(e) => props.updateData(e)}
                InputLabelProps={{ shrink: true }}
                placeholder="!!!OBRIGATORIO!!!" />
            </Grid>
          </Grid>
          {dados.conta.conta_pai_id && 
            <React.Fragment> 
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <p>Esta conta é recorrente, e existem {props.dados.qtd_recorrencia} recorrências abertas.</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg-top--20">
                <Grid item md={12} xs={12} sm={12}>
                  <FormControl component="fieldset" id='radio'>
                    <RadioGroup aria-label="gender" name="acao_cancelamento" value={dados.acao_cancelamento} onChange={(e) => props.updateData(e)}>
                      <FormControlLabel value="apenas_esta" control={<Radio />} label="Cancelar apenas esta recorrência" />
                      <FormControlLabel value="todas" control={<Radio />} label="Cancelar todas as recorrências" />
                      <FormControlLabel value="a_partir_hoje" control={<Radio />} label="Cancelar todas a partir desta recorrência" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </React.Fragment>
          }

          {dados.conta.numparcela > 1 && 
            <React.Fragment> 
              {!dados.conta.conta_pai_id &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} xs={12} sm={12}>
                      <p>Esta conta é parcelada, e existem {props.dados.qtd_parcelas} parcelas abertas.</p>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg-top--20">
                    <Grid item md={12} xs={12} sm={12}>
                      <FormControl component="fieldset" id='radio'>
                        <RadioGroup aria-label="gender" name="acao_cancelamento" value={dados.acao_cancelamento} onChange={(e) => props.updateData(e)}>
                          <FormControlLabel value="apenas_esta" control={<Radio />} label="Cancelar apenas esta parcela" />
                          <FormControlLabel value="todas" control={<Radio />} label="Cancelar todas as parcelas" />
                          <FormControlLabel value="a_partir_hoje" control={<Radio />} label="Cancelar todas a partir desta parcela" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='avisoAlerta'><i>Esta operação não poderá ser desfeita.</i></p>
              {dados.boleto_transaction_id && 
                <p className='avisoAlerta'><i>Para esta parcela foi gerado um boleto, ao cancelar você também estará cancelando o boleto!</i></p>
              }
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={disabledButton ? true : (dados.motivo_cancelamento === "" ? true : (dados.acao_cancelamento === '' ? true : false))}>Sim</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={disabledButton}>Não</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
