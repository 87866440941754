import React, { useState, useEffect } from 'react';
import './WhatsApp.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, CircularProgress } from '@mui/material'
import FormWhatsApp from '../../components/forms/FormWhatsApp'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import { EditorState } from "draft-js"
import ModalConfirmarSenha from './../../components/modals/ConfirmarSenha'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  whatsapp: {
    id: "",
    numero: "",
    nome: "",
    api_key: "U2FsdGVkX1-7vKd89aj4yVNkSeYFs8De1hO-3Uj10vJzdDCC+460pej5lnrN0+EI",
    instancia_id: "",
    instancia_token: "",
    conectado: false,
    ativo: true,
    qrCode: ''
  },
  loadingSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading:true,
  update: false,
  unidades: {
    list: []
  },
  tipo: 'UNIDADE',
  filtro: {
    descricao: ''
  },
  variaveisFiltrada:{
    list: []
  },
  templates: {
    list: []
  },
  editorState: EditorState.createEmpty(),
  unidadesSelecionadas: [],
  logo_unidade: {
    id: '',
    key: ''
  },
  midias_pessoa: {
    list: []
  },
  openModalSelecionarLogo: false,
  login: {
    login: '',
    senha: ''
  },
  showPassword: false,
  openModalSenha: false
}

function formataTelefone(numero) {

  if(numero){
    let texto = numero.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

let watchID = ''

const CadastroNotificacao = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { numeroWhatsId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultaQrcode = async (whatsapp) => {
    try {
      if(whatsapp.id !== ''){
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/whatsApp/getQrcode/${whatsapp.api_key}/${whatsapp.instancia_id}/${whatsapp.instancia_token}`, getToken())
     
        if(data.connected){
          whatsapp.conectado = true
          whatsapp.qrCode = ''
        }else{
          whatsapp.qrCode = data.value
          whatsapp.conectado = false
        }
    
        setState(prevState => ({...prevState,
          whatsapp
        }))
      }

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
    }
  }

  const backPage = (timeout = 2000) => {
    setTimeout(() => {
      navigate("/api_whatsapp");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const formataTelefoneWhats = (event) => {
    if(event.target.value !== ''){
      let texto = event.target.value.trim()
  
      texto = texto.replace(/\D/g,""); 
      
      if(texto.length === 11 || texto.length === 10){
        texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else if(texto.length === 12){
        texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else if(texto.length === 8){
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Telefone Invalido'
          }
        }))

        const whatsapp = { ...state.whatsapp }
        whatsapp.numero = ''
        setState(prevState => ({...prevState, whatsapp }))

        return false

      }
    
      const whatsapp = { ...state.whatsapp }
      whatsapp.numero = texto
      setState(prevState => ({...prevState, whatsapp }))
    }
  }

  const updateFieldUnidade = (event, value) => {
    setState(prevState => ({...prevState,
      unidadesSelecionadas: value
    }))
  }

  const validaForm = () => {
    const {whatsapp} = state

    if(whatsapp.nome === '') return false
    if(whatsapp.numero === '') return false
    if(whatsapp.api_key === '') return false

    return true
  }

  const salvar = () => {
  
    if(!validaForm()){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        },
        loadingSalvar: false
      }))
      return
    }

    setState(prevState => ({...prevState,
      openModalSenha: true
    }))
  }
  
  const continuarSalvar = async (value) => {
    if(value){ 

      let valido = true

      try {
        const login = {
          login: JSON.parse(localStorage.getItem('login')),
          senha: state.login.senha
        }

        await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/login`, login)

        valido = true

      } catch (error) {
        valido = false
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a senha corretamente!'
          },
          loadingSalvar: false
        }))
        return
      }

      if(valido){
        setState(prevState => ({...prevState, loadingSalvar: true }))
        const {whatsapp, unidadesSelecionadas} = state
  
        if(unidadesSelecionadas.length > 0){
          whatsapp.unidades = unidadesSelecionadas.map(unidade => { return {unidade_id: unidade.id}})
        }
  
        try {
          if(whatsapp.id !== ''){
            await axios.put(`${window._env_.REACT_APP_API_URL}/whatsApp/${whatsapp.id}`, whatsapp, getToken())
          }else{
            await axios.post(`${window._env_.REACT_APP_API_URL}/whatsApp`, whatsapp, getToken())
          }
      
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'success',
              message: 'Cadastro realizado com sucesso.'
            }
          }))
      
          backPage()
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            },
            loadingSalvar: false
          }))
        }
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a senha corretamente!'
          }
        }))
        return
      }
    }else{
      setState(prevState => ({...prevState,
        openModalSenha: false,
        loadingSalvar: false
      }))
    }
  }

  const updateFieldAtivo = () => {
    const {whatsapp} = state
    whatsapp.ativo = !whatsapp.ativo
    setState(prevState => ({...prevState,whatsapp}))
  }

  const updateField = (event) => {
    const {whatsapp} = state

    let name = event.target.name
    let value = event.target.value

    whatsapp[name] = value

    setState(prevState => ({...prevState,whatsapp}))
  }

  const handleCloseConfirmarSenha = () => {
    setState(prevState => ({...prevState,
      openModalSenha: false
    }))
  }

  const updateData = (event) => {
    const { login } = state

    login[event.target.name] = event.target.value
    setState(prevState => ({...prevState, login }))
  }

  const handleClickShowPassword = () => {
    setState(prevState => ({...prevState,
      showPassword: !state.showPassword
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'central-alerta')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (numeroWhatsId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }  

      try {
        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, {}, getToken())

        setState(prevState => ({...prevState,
          unidades:{
            list: unidades.filter(param => (param.tipo === 'UNIDADE' || param.tipo === 'MANTENEDOR') && param.status).map(unidade => {
              return {
                id: unidade.id,
                numero: unidade.numero,
                descricao: unidade.descricao,
                midias: unidade.pessoa.midias_pessoa
              }
            })
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (numeroWhatsId) {
        try {
          const { data: numero_whats } = await axios.get(`${window._env_.REACT_APP_API_URL}/whatsApp/getInstancia/${numeroWhatsId}`, getToken())
          
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/whatsApp/getQrcode/${numero_whats.api_key}/${numero_whats.instancia_id}/${numero_whats.instancia_token}`, getToken())

          console.log(data)

          let whatsapp = {
            id: numero_whats.id,
            nome: numero_whats.nome,
            numero: formataTelefone(numero_whats.numero),
            api_key: numero_whats.api_key,
            instancia_id: numero_whats.instancia_id,
            instancia_token: numero_whats.instancia_token,
            conectado: numero_whats.conectado,
            ativo: numero_whats.ativo,
            qrCode: ''
          }

          if(data.connected){
            whatsapp.conectado = true
            whatsapp.qrCode = ''
          }else{
            whatsapp.qrCode = data.value
            whatsapp.conectado = false
          }
          
          setState(prevState => ({...prevState,
            whatsapp,
            unidadesSelecionadas: numero_whats.unidades.map(value => {
              return {
                id: value.unidade_id,
                descricao: value.unidade.descricao,
                numero: value.unidade.numero
              }
            }),
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
        }
      }

      // await consultaQrcode(whatsapp);

      watchID = setInterval(async () => {
        await consultaQrcode(whatsapp);
      }, 1000 * 10);

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { whatsapp, unidades, unidadesSelecionadas, loadingSalvar } = state

  console.log(whatsapp.qrCode)

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Tela...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom" >
              <Grid item md={11}>
                <h1 className="titulo">Cadastro de Número do WhatsApp</h1>
              </Grid>
              <Grid item md={1}>
                {loadingSalvar &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>
            <FormWhatsApp 
              dados={whatsapp}
              updateField={e => updateField(e)}
              updateFieldAtivo={e => updateFieldAtivo()}
              unidades={unidades.list}
              unidadesSelecionadas={unidadesSelecionadas}
              updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
              formataTelefone={e => formataTelefoneWhats(e)}
            />
            <hr />
            <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
              <Grid item md={10} xs={12} sm={6}></Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button 
                  fullWidth 
                  color="primary" 
                  variant="contained"  
                  size="small" 
                  startIcon={<SaveIcon />} 
                  onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalConfirmarSenha
        open={state.openModalSenha}
        handleClose={e => handleCloseConfirmarSenha(e)}
        dados={state.login}
        updateData={e => updateData(e)}
        confirmar={e => continuarSalvar(e)}
        disabled={loadingSalvar}
        showPassword={state.showPassword}
        handleClickShowPassword={e => handleClickShowPassword(e)}
        mensagem={'Ao confirmar você estará consciente dos custos da assinatura'}
      />
    </div>
  )
}

export default CadastroNotificacao