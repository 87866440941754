import React from 'react'

import { Grid, TextField, FormControl, InputLabel, OutlinedInput } from '@mui/material'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const FormUsuario = ({ dados, updateField, handleClickShowPassword, handleClickShowPasswordRepetir, showPassword, showPasswordRepetir, corSenha, nivelSenha }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Login"
          variant="outlined" 
          name="email_login" 
          value={dados.email_login}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={dados.senha}
            onChange={(e) => updateField(e)}
            className="input"
            name="senha_usuario"
            InputLabelProps={{ shrink: true }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => handleClickShowPassword(e)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        <br />
        <span>Nivel Senha: <b style={{ color: corSenha }}>{nivelSenha}</b> </span>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Repetir Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPasswordRepetir ? 'text' : 'password'}
            value={dados.repetirSenha}
            onChange={(e) => updateField(e)}
            className="input"
            name="repetirSenha"
            InputLabelProps={{ shrink: true }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => handleClickShowPasswordRepetir(e)}
                  edge="end"
                >
                  {showPasswordRepetir ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormUsuario