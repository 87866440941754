import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField, FormControlLabel, Switch, FormControl, FormLabel, Button } from '@mui/material'
import Color from 'color';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import Table from './../Table'
import { Editor } from "react-draft-wysiwyg";

let cabecalhoTabela = [
  { id: 'titulo', numeric: false, disablePadding: true, label: 'Titulo Opção' },
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' }
]

let acoesTabela = ['editar', 'remove']

const cabecalhoTabelaVar = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
  { id: 'variavel', numeric: false, disablePadding: true, label: 'Variavel' }
]

const acoesTabelaVar = ['selectVar']

const FormNotificacao = ({ dados, updateField, unidades, unidadesSelecionadas, updateFieldUnidade, updateFieldAtivo, filtro, updateFiltro,
  opcao, addOpcao, update, updateFieldOpcao, editarOpcao, removerOpcao, onEditorStateChange, editorState, variaveisFiltrada, selecionarVar }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={4}>
        <TextField 
          className="input" 
          label="Questão da Pesquisa*" 
          variant="outlined" 
          size="small" 
          name="titulo" 
          value={dados.titulo} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={8} xs={12} sm={12}>
        <Autocomplete
          multiple
          limitTags={3}
          id="multiple-limit-tags"
          options={unidades.filter(unidade => {
            if (unidadesSelecionadas.findIndex(i => i.id === unidade.id) === -1)
              return unidade;
            return false
          })}
          size="small"
          value={unidadesSelecionadas}
          onChange={(event, value) => updateFieldUnidade(event, value)}
          getOptionLabel={(option) => option.descricao}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Unidades*" InputLabelProps={{ shrink: true}} />
          )}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={3}>
        <TextField 
          className="input" 
          label="Data de Inicio" 
          type="date"
          variant="outlined" 
          size="small" 
          name="data_inicio" 
          value={dados.data_inicio} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={2}>
        <TextField 
          className="input" 
          label="Hora do Inicio" 
          variant="outlined" 
          type="time"
          size="small" 
          name="hora_inicio" 
          value={dados.hora_inicio} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField 
          className="input" 
          label="Data de Fim" 
          type="date"
          variant="outlined" 
          size="small" 
          name="data_fim" 
          value={dados.data_fim} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={2}>
        <TextField 
          className="input" 
          label="Hora do Fim" 
          variant="outlined" 
          type="time"
          size="small" 
          name="hora_fim" 
          value={dados.hora_fim} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={9} xs={12} sm={12}>
        <h4 className='titulo'>Mensagem da Pesquisa:</h4>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Descrição" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={filtro.descricao} 
          onChange={(e) => updateFiltro(e)} 
          InputLabelProps={{ shrink: true }} 
          placeholder='Pesquisar' 
        />
      </Grid>
    </Grid>
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={6} xs={12} sm={12}>
        <div className="cardEditor">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={e => onEditorStateChange(e)}
          />
        </div>
      </Grid>
      <Grid item md={6} xs={12} sm={12}>
        <Table
          headCell={cabecalhoTabelaVar}
          rows={variaveisFiltrada}
          acoes={acoesTabelaVar}
          selecionarVar={e => selecionarVar(e)} />
      </Grid>
    </Grid>
    <hr className="mg_top_10"/>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <h4 className='titulo'>Opções:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={3}>
        <TextField 
          className="input" 
          label="Opção*" 
          variant="outlined" 
          size="small" 
          name="titulo" 
          value={opcao.titulo} 
          onChange={(e) => updateFieldOpcao(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={7} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Descricao*" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={opcao.descricao} 
          onChange={(e) => updateFieldOpcao(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addOpcao(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Table 
        headCell={cabecalhoTabela} 
        rows={dados.opcoes} 
        acoes={acoesTabela} 
        editar={e => editarOpcao(e)}
        remove={e => removerOpcao(e)}
        />
    </Grid>
  </React.Fragment>
)

export default FormNotificacao