import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@mui/material'
import CurrencyInput from './../mask/CurrencyInput'
import real  from '../../services/real'
import Table from './../Table'
import moment from 'moment'
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '92%',
  marginLeft: '4%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabelaFinanceiro = [
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Descrição'},
  { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
  { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma Pgto' },
  { id: 'situacao', numeric: false, disablePadding: false, label: 'Situação' },
  { id: 'valor', numeric: false, disablePadding: false, label: 'Valor' },
  { id: 'multajuros', numeric: false, disablePadding: false, label: 'Juros/Multas' },
]

const acoesTabelaFinanceiro = []

export default function SimpleModal(props) {
  const theme = useTheme();
  const {dados, updateFieldConta, conta, updateFieldParcela, contasFinanceira, formasPagamento, mudarParcelas,
  recalcularParcela, itens_receita_despesa, confirmar, itemSelecionado} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Cancelar Contrato</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className='titulo'>Financeiro Pendente:</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={cabecalhoTabelaFinanceiro} 
                rows={props.financeiros_pendentes.map(parcela => {
                  return {
                    descricao: parcela.conta.descricao,
                    datavencimento: moment(parcela.datavencimento).format('DD/MM/YYYY'),
                    formapagto_descricao: parcela.valores_pago[0].forma_pagto.descricao,
                    situacao: parcela.situacao,
                    valor: real(parcela.valorparcela),
                    multajuros: parcela.multajuros ? real(parcela.multajuros) : real(0)
                  }
                })} 
                acoes={acoesTabelaFinanceiro} />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
						<Grid item md={4} xs={12} sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Lançar Financeiro de Conciliação</FormLabel>
								<FormControlLabel className="mg_left_10"
									control={
										<Switch
											name="Ativo"
											color="primary"
											checked={dados.lanc_fin}
										/>
									}
									size="small"
									label={dados.lanc_fin ? 'Sim' : 'Não'}
									name="lanc_fin"
									onChange={(e) => props.updateFieldLancFinanceiroCancelamento(e)}
								/>
							</FormControl>
						</Grid>
					</Grid>
          {dados.lanc_fin && 
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => props.updateFieldItem(event, value)}
                    freeSolo
                    options={itens_receita_despesa}
                    value={itemSelecionado}
                    getOptionLabel={option => `${option.descricao}`}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Item Receita/Despesa*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${itemSelecionado.descricao}`} />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" size="small" label="Descrição*" variant="outlined" name="descricao" value={conta.descricao} InputLabelProps={{ shrink: true }} onChange={(e) => props.updateFieldConta(e)}/>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" size="small" label="Valor*" variant="outlined" name="valortotal" value={conta.valortotal} onChange={(e) => props.updateFieldConta(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
              </Grid>
              {conta.valortotal !== '' &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                  <div className='scroll'>
                    <table className="lista sempadding borderTable">
                      <thead>
                        <tr className="titulos acompanha">
                          <th>Parcelas</th>
                          <th>Valor*</th>
                          <th>Vencimento*</th>
                          <th>Forma de Pagamento*</th>
                          <th>Conta Financeira</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                          return (
                            <tr key={parcela.index}>
                              <td>
                                <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                                <span className="separacaoParcelas">/</span>
                                <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                              </td>
                              <td>
                                <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                              </td>
                              <td>
                                <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="datavencimento" value={parcela.datavencimento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                              </td>
                              <td>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="inputFormPagamento"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="formapagto_id"
                                  value={parcela.formapagto_id}
                                  onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  <option value="">--Escolha--</option>
                                  {formasPagamento.map(forma => {
                                    return (
                                      <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                    )
                                  })}
                                </TextField>
                              </td>
                              <td>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="inputConta"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="contfin_id"
                                  value={parcela.contfin_id}
                                  onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                  InputLabelProps={{ shrink: true }}
                                  disabled={parcela.formapagto_id === ''}
                                >
                                  <option value="">--Escolha--</option>
                                  {contasFinanceira.filter(param => param.tipo_conta === (parseInt(parcela.formapagto_id) === 1 ? 'COFRE': 'BANCO')).map(conta => {
                                    return (
                                      <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                    )
                                  })}
                                </TextField>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  </Grid>
                </Grid>
              }
           </React.Fragment>
          }
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
            <TextField
                id="outlined-multiline-static"
                label="Motivo"
                className="input"
                multiline
                rows={5}
                size="small"
                variant="outlined"
                name="motivoCancelamento"
                value={dados.motivoCancelamento}
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente realizar o cancelamento?</p>
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={12} sm={4}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button
                size='small'
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => confirmar(false)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button
                size='small'
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => confirmar(true)}
              >
                Confirmar Cancelamento
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
