import React, { Component } from 'react'
import './Caixa.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, Button, TextField } from '@mui/material'
import Loading from './../../components/templates/Loading'
import real from '../../services/real'
import moment from 'moment'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  caixa: {},
  caixas: {
    list: []
  },
  caixasFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'numero', numeric: false, disablePadding: true, label: 'Nº Caixa' },
    { id: 'dataabertura', numeric: false, disablePadding: true, label: 'Data de abertura' },
    { id: 'fundotroco', numeric: false, disablePadding: true, label: 'Fundo de Troco' },
    { id: 'saldo', numeric: false, disablePadding: true, label: 'Saldo atual' },
    { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
  ],
  acoesTabela: ['view'],
  filtro: {
    numero: "",
    fundotroco: ""
  },
  tipo: 'UNIDADE',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  loading: true,
  mensagemLoading: 'Carregando Caixas...',
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Caixa extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]

    if(permissoes){
      if (!permissoes.visualizar) {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: "Você não tem permissão para acessa essa tela!"
          }
        })
      }

      this.setState({
        permissoes
      })
    }
    
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

    this.setState({
      tipo
    })

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa`, this.getToken())

      const list = data.map(caixa => {
        return {
          _id: caixa.id,
          numero: caixa.numero,
          dataabertura: moment(caixa.dataabertura).format('DD/MM/YYYY HH:mm'),
          fundotroco: `R$ ${real(caixa.fundotroco)}`,
          saldo: `R$ ${real(caixa['conta.saldo_atual'])}`,
          situacao: caixa['status_atual.status'],
          unidade: caixa['unidade.descricao']
        }
      })

      this.setState({
        caixas: { list },
        caixasFiltrado: { list }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const caixas = this.state.caixas.list
    const filtro = this.state.filtro

    const list = caixas.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      caixasFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, caixasFiltrado, permissoes } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="borderBottom"
                >
                  <Grid item md={8} xs={12}>
                    <h1 className="titulo">Caixas</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={4} xs={12}>
                      <Link to="/caixa/cadastro">
                        <Button className="btnCadastrar" variant="contained" color="primary">
                          Iniciar Caixa
                      </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Caixa" variant="outlined" size="small" name="numero" value={filtro.numero} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                >
                  <Grid item md={12} xs={12}>
                    <Table
                      urlUpdate="/caixa/cadastro/"
                      urlView="/caixa/view/"
                      headCell={this.state.cabecalhoTabela}
                      rows={caixasFiltrado.list}
                      acoes={this.state.acoesTabela}
                      ordem='desc'
                    />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Caixas"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}