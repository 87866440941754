import { validate, format } from 'cnpj';

export default function validaCnpj(cnpj){
	try {
		cnpj = format(cnpj)

		return validate(cnpj) ? true : false
		
	} catch (error) {
		console.log(error);
		return error
	}
	
}