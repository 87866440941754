import React from 'react'
import { Grid, TextField, FormControlLabel, Switch, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';

const FormEndereco = ({ addNovoEndereco, dados, updateField,
  pegaEndereco, updateFieldPrincipal, removerItem }) => (
  <React.Fragment>
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          onBlur={e => pegaEndereco(e)}
          fullWidth
          className="input"
          label="CEP*"
          variant="outlined"
          size="small"
          name="cep"
          value={dados.cep}
          inputProps={{ maxLength: 8 }}
          onChange={(e) => updateField(e, dados.index)}
          InputLabelProps={{
            shrink: true
          }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Rua*" variant="outlined" size="small" name="rua" value={dados.rua} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Número*" variant="outlined" size="small" name="numero" value={dados.numero} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Complemento" variant="outlined" size="small" name="complemento" value={dados.complemento} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Bairro*" variant="outlined" size="small" name="bairro" value={dados.bairro} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container spacing={1} direction="row" className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField disabled={true} fullWidth className="input" label="Cidade*" variant="outlined" size="small" name="cidade" value={dados.cidade} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField disabled={true} fullWidth className="input" label="Estado*" variant="outlined" size="small" name="estado" value={dados.estado} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo Endereço*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="tipoEndereco"
          value={dados.tipoEndereco}
          onChangeCapture={(e) => updateField(e, dados.index)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="RESIDENCIAL">
            RESIDENCIAL
					</option>
          <option value="COMERCIAL">
            COMERCIAL
					</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="principal"
              color="primary"
              checked={dados.principal}
            />
          }
          label="Principal*"
          name="principal"
          onChange={(e) => updateFieldPrincipal(e)}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <Button fullWidth onClick={() => addNovoEndereco()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>{dados.index === '' ? 'Adicionar Endereço' : 'Alterar Endereço'} </Button>
      </Grid>
    </Grid>
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <WarningIcon style={{color: 'red'}}/><span style={{marginLeft: 5, fontSize: 14}} className='error'>Esse endereço ainda não foi {dados.index === '' ? 'adicionado' : 'alterado'}, para confirmar clique em "{dados.index === '' ? 'ADICIONAR ENDEREÇO' : 'ALTERAR ENDEREÇO'}"</span>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormEndereco