import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import Color from 'color';
import Typography from '@mui/material/Typography';
import Table from './../TableV2'
import TableNormal from './../Table'
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const ColorOrangeButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: Color('#FF8C00').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#FF8C00',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let cabecalhoTabela = [
  { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição Conta' },
  { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Data Venc.', orderBy: 'datavencimentoOrder' },
  { id: 'valorparcela', numeric: true, disablePadding: false, label: 'Vl. Parc.' },
  { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma Pagto.' },
  { id: 'datapagamento', numeric: false, disablePadding: false, label: 'Data Pag.', orderBy: 'datapagamentoOrder' },
  { id: 'multajuros', numeric: true, disablePadding: false, label: 'Multa/Desc.' },
  { id: 'valor_pagamento', numeric: true, disablePadding: false, label: 'Vl. Pag..' },
  { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
  { id: 'conciliado', numeric: false, disablePadding: true, label: 'Conciliado' }
]

let acoesTabela = ['selecionarMov']

let cabecalhoTabelaMov = [
  { id: 'data_hora_mov', numeric: false, disablePadding: false, label: 'Data/Hora' },
  { id: 'contfin_descricao', numeric: false, disablePadding: true, label: 'Conta' },
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição', habilitaFiltro: true},
  { id: 'forma_pgto', numeric: false, disablePadding: true, label: 'Forma de Pagto.', habilitaFiltro: true},
  { id: 'valor', numeric: false, disablePadding: false, label: 'Valor Mov.', habilitaFiltro: true},
  { id: 'conciliado', numeric: false, disablePadding: true, label: 'Conciliado' }
]

export default function SimpleModal(props) {
  const theme = useTheme();

  let { filtro, updateFiltro, updateFieldPessoaFiltro, updateFieldForma, pessoas, cliente, formasPagamento, formasSelecionadas, situacoes,
    situacoesSelecionadas, updateFiltroPessoa, handleChangePage, handleChangeRowsPerPage, contas, rows, loadingTable,
    selecionarMov, filtrar, movimentacoes_selecionadas, confirmarSelecionados, updateFieldSituacao, tab, handleChangeTab,
    updateFiltroMovimentacao, filtrarMovimentacao, contaFinanceiras, filtroMovimentacao, movimentacoes, contas_selecionadas } = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Localizar Movimentação:</h5>
            </Grid>
          </Grid>
          <AppBar position="static" className="mg_top_20">
            <Tabs id="viewCliente" value={tab} onChange={handleChangeTab} aria-label="simple tabs example">
              <Tab label="Contas A Pagar/Receber" {...a11yProps(0)} />
              <Tab label="Movimentação Financeira" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tab} index={0}>
            <Grid container direction="row" spacing={1} className="mg_top_20">
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Filtrar data por"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="data_por"
                  value={filtro.data_por}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="VENCIMENTO">Data de Vencimento</option>
                  <option value="PAGAMENTO">Data de Pagamento</option>
                  <option value="EMISSAO">Data de Emissão</option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" type="date" label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Tipo"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="tipo"
                  value={filtro.tipo}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""></option>
                  <option value="PAGAR">Despesa</option>
                  <option value="RECEBER">Receita</option>
                </TextField>
              </Grid>
              <Grid item md={4} xs={12} sm={6}>
                <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={4} xs={12} sm={6}>
                <Autocomplete
                  onChange={(event, value) => updateFieldPessoaFiltro(event, value)}
                  freeSolo
                  options={pessoas.list}
                  value={cliente}
                  size="small"
                  getOptionLabel={option => option.nome}
                  renderInput={(params) => (
                    <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Cliente/Fornecedor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={cliente.nome} onChange={(e) => updateFiltroPessoa(e)}/>
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12} sm={6} id='form-contas'>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={formasPagamento.list.filter(forma => {
                    if (formasSelecionadas.findIndex(i => i.id === forma.id) === -1){
                      return forma;
                    }else{
                      return false
                    }
                  })}
                  size="small"
                  value={formasSelecionadas}
                  onChange={(event, value) => updateFieldForma(event, value)}
                  getOptionLabel={(option) => option.descricao}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Formas de Pagto." InputLabelProps={{ shrink: true }}/>
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12} sm={6} id='form-contas'>
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="multiple-limit-tags"
                  options={situacoes.list.filter(value => {
                    if (situacoesSelecionadas.findIndex(i => i.id === value.id) === -1){
                      return value;
                    }else{
                      return false
                    }
                  })}
                  size="small"
                  value={situacoesSelecionadas}
                  onChange={(event, value) => updateFieldSituacao(event, value)}
                  getOptionLabel={(option) => option.descricao}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Situações." InputLabelProps={{ shrink: true }}/>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={7} xs={false} sm={3}>
                {filtro.tipo === '' &&
                  <p style={{fontSize: '20px', marginBottom: '0px'}}>Tipo das Contas: A PAGAR / A RECEBER</p>
                }
                {filtro.tipo !== '' &&
                  <p style={{fontSize: '20px', marginBottom: '0px'}}>Tipo das Contas: {filtro.tipo === 'PAGAR' ? 'A PAGAR' : 'A RECEBER'}</p>
                }
              </Grid>
              <Grid item md={3} xs={12} sm={5}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Exibir"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="conciliado"
                  value={filtro.conciliado}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">Exibir Todos</option>
                  <option value="Sim">Apenas Conciliado</option>
                  <option value="EMISSAO">Apenas Não Conciliado</option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={4}>
                <ColorOrangeButton size='small' fullWidth variant="contained" onClick={() => filtrar()}>
                  Filtrar
                </ColorOrangeButton>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table
                  headCell={cabecalhoTabela} 
                  rows={contas.list} 
                  acoes={acoesTabela} 
                  tamanhoFonte={'10px'}
                  count={rows}
                  handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                  handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                  filtro={filtro}
                  loadingTable={loadingTable}
                  selecionarMov={(e) => selecionarMov(e)}
                  movimentacoes_selecionadas={contas_selecionadas}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={12} sm={3}>
                <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtroMovimentacao.data_inicial} onChange={(e) => updateFiltroMovimentacao(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={3}>
                <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtroMovimentacao.data_final} onChange={(e) => updateFiltroMovimentacao(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Conciliado"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="conciliado"
                  value={filtroMovimentacao.conciliado}
                  onChangeCapture={(e) => updateFiltroMovimentacao(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""></option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </TextField>
              </Grid>
              <Grid item md={4} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Conta"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="contfin_id"
                  value={filtroMovimentacao.contfin_id}
                  onChangeCapture={(e) => updateFiltroMovimentacao(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""></option>
                  {contaFinanceiras.list.map(conta => {
                    return (
                      <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <ColorOrangeButton size='small' fullWidth variant="contained" onClick={() => filtrarMovimentacao()}>
                  Filtrar
                </ColorOrangeButton>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12}>
                <TableNormal
                  headCell={cabecalhoTabelaMov} 
                  rows={movimentacoes.list} 
                  acoes={acoesTabela}
                  selecionarMov={(e) => selecionarMov(e)}
                  movimentacoes_selecionadas={movimentacoes_selecionadas}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={9} xs={12} sm={8}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth size='small' onClick={e => confirmarSelecionados()} variant="contained" color="primary">
                Conciliar Movimentações
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
