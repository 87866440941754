export default {
  pessoa: {
    _id: "",
    flags: [],
    cliente_id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    pessoaJuridica: {
      id: '',
      razaoSocial:'',
      nomeFantasia:'',
      cnpj:'',
      inscricaoEstadual:'',
      dataFundacao: ''
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    pre_contrato: {
      id: '',
      status_atual: {
        id: '',
        estado_contrato: '',
        data_inicio: '',
        data_fim: ''
      }
    }
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: [],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
  openModalVisualizarDocumento: false,
  documento: {
    nome: '',
    url: '',
    formato: ''
  },
  tab: 0,
  uploadedFiles: [],
  dadosContrato: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    cliente_id: '',
    produtos: [],
    template_versao_id: "",
    assinar_contrato: false,
    cobrar_multa: false,
    tipo_multa: '',
    valor_multa: '0',
    agrupar_vencidas: false,
    bonus_pont: false,
    porc_bonus_pont: '0',
    intervalo_parcela: '',
    cobrar_domingos: 'SIM',
    bonus_indicacao: false,
    cliente_indicacao: '',
    valor_indicacao: '0',
    formapagto_indicacao_id: '',
    contfin_indicacao_id: '',
    habilita: ''
  },
  template_documentos: {
    list: []
  },
  servico:{
    produto_id: '',
    descricao: '',
    formapagto_id: '',
    forma_pagto: '',
    valor: '',
    num_parcelas: '',
    contfin_id: '',
    conta_fin: '',
    venc_prim_parcela: '',
    venc_prim_parcela_sem_form: '',
    gerar_nfse: false,
    index: '',
    recorrencia: false,
    parcelado: false,
    recorrenciaACada: 1,
    tipoRecorrencia: 'M',
    qtdRecorrencia: 1,
  },
  produtos: {
    list: []
  },
  formasPagamento : {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  possui_emissao_nfse: false,
  exibir_opcao_multa_contrato: false,
  exibir_opcao_pontualidade_contrato: false,
  exibir_opcao_bonus_indicacao_contrato: false,
  conta: {
    descricao: "",
    tipo: "A RECEBER",
    valortotal: "",
    numparcela: 1,
    item_id: '',
    tipo_item: 'ITEMRECDESP',
    qtd_parcelas: 1,
    gerar_nfse: false,
    parcelas: [
      {
        baixado: "",
        datavencimento: "",
        valorparcela: "",
        formapagto_id: "",
        documento: "",
        num: "",
        contfin_id: "",
        parcela_editada: false,
        index: 1
      }
    ]
  },
  produtoSelecionado:{
    preco: null
  },
  alteraServico: false,
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Serviço/Produto' },
    { id: 'valor_contrato', numeric: true, disablePadding: true, label: 'Valor de Contrato' },
    { id: 'parcelas', numeric: false, disablePadding: true, label: 'Parcelas' },
    { id: 'venc_prim_parcela', numeric: false, disablePadding: true, label: 'Venc. Prim. Parcela'},
    { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
    { id: 'conta_fin', numeric: false, disablePadding: true, label: 'Contas Bancárias' },
    { id: 'gerar_nfse', numeric: false, disablePadding: true, label: 'Gerar NFS-e' },
  ],
  acoesTabela: ['remove', 'editar'],
  permissoes: [],
  clienteIndicacao: {
    id: '',
    nome: '',
    cpf_cnpj: '',
    pessoa_id: ''
  },
  clientes:{
    list:[]
  },
}