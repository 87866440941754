import React, { Component } from 'react'
import './Departamento.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@mui/material'
import Alert from './../../components/templates/Alert'
import listaDepartamentos from './services/listaDepartamentos'
import validaPermissao from './../../services/validaPermissao'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  departamento: {},
  departamentos: {
    list: []
  },
  departamentosFiltrado: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Departamentos...',
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'centro_custos', numeric: false, disablePadding: true, label: 'Centros de Custo' },
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    descricao: "",
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Cargo extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    let acoesTabela = []

    if (validaPermissao('departamento', 'alterar').libera) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes: validaPermissao('departamento', 'alterar').permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      let departamentos = await listaDepartamentos()

      this.setState({
        departamentos:{
          list:departamentos
        },
        departamentosFiltrado: {
          list: departamentos
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
    
    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const departamentos = this.state.departamentos.list
    const filtro = this.state.filtro

    const list = departamentos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      departamentosFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, permissoes, departamentosFiltrado } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="borderBottom"
                >
                  <Grid item md={9} xs={12} sm={6}>
                    <h1 className="titulo">Departamentos</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={3} xs={12} sm={6}>
                      <Link to="/departamentos/cadastro">
                        <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar Departamento
                        </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Departamento" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table urlUpdate="/departamentos/cadastro/" headCell={this.state.cabecalhoTabela} rows={departamentosFiltrado.list} acoes={this.state.acoesTabela} />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} 
            />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Departamentos"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}