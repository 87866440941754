import React, { useState, useEffect } from 'react';
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button, Tooltip, IconButton } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../../components/templates/Alert'
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  registros: {
    list: []
  },
  registrosSelecionados: [],
  unidade: {},
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  desabilitarCampos: false,
  loadingSalvar: false
}

const DadosComplementares = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const voltar = () => {
    navigate("/unidade");
    return;
  }

  const addRegistro = (registro) => {
    const { registrosSelecionados, desabilitarCampos } = state
  
    if(!desabilitarCampos){
      registrosSelecionados.push({...registro})
  
      setState(prevState => ({...prevState,
        registrosSelecionados,
      }))
    }else{
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Você não possui permissão para essa ação!'
        }
      }))
    }
  }

  const removerRegistro = (registro) => {
    const { registrosSelecionados, desabilitarCampos } = state
    if(!desabilitarCampos){
      const index = registrosSelecionados.findIndex(param => param.id === registro.id)
  
      registrosSelecionados.splice(index, 1)
  
      setState(prevState => ({...prevState,
        registrosSelecionados,
      }))
    }else{
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Você não possui permissão para essa ação!'
        }
      }))
    }
  }

  const salvar = async () => {
    const {registrosSelecionados} = state

    try {
      const unidade = props.unidade

      const dados = {
        unidade_id: unidade.id,
        itens: registrosSelecionados.map(value => {
          return {
            dtd_identifier: value.id,
            obrigatorio: value.obrigatorio,
            ordem: value.ordem ? value.ordem : 1
          }
        })
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPF`,dados, getToken())
      
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateObrigatoriedade = (registro) => {
    const {registrosSelecionados} = state

    const index = registrosSelecionados.findIndex(param => param.id === registro.id)
    
    registrosSelecionados[index].obrigatorio = !registrosSelecionados[index].obrigatorio

    setState(prevState => ({...prevState,
      registrosSelecionados
    }))
  }

  const updateFieldOrdem = (event, id) =>{
    const {registrosSelecionados} = state

    const index = registrosSelecionados.findIndex(param => param.id === id)
    
    registrosSelecionados[index].ordem = event.target.value

    setState(prevState => ({...prevState,
      registrosSelecionados
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'unidade')[0]
    
      if(permissoes){
        setState(prevState => ({...prevState,
          permissoes
        }))

        if(!permissoes.alterar && !permissoes.inserir){
          setState(prevState => ({...prevState,
            desabilitarCampos: true
          }))
        }
      }

      try {
        const unidade = props.unidade
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPF/buscaDDL/${unidade.id}`, getToken())
        const { data: atributosUnidade } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPF/list/${unidade.id}`, getToken())

        setState(prevState => ({...prevState,
          registros:{
            list: data.map(value => {
              return {
                id: value.dtd_identifier, 
                nome: value.column_comment, 
                slug: value.column_name,
                obrigatorio: false,
                ordem: 0
              }
            })
          },
          registrosSelecionados: atributosUnidade.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
            return {
              id: value.dtd_identifier, 
              nome: value.column_comment, 
              slug: value.column_name,
              obrigatorio: value.obrigatorio,
              ordem: value.ordem
            }
          })
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);
  
  const { registros, registrosSelecionados, desabilitarCampos } = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Dados Complementares...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
            <Grid item md={6} xs={12} sm={12}>
              <h1 className="titulo">Dados Complementares: </h1>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} style={{marginTop: 5}}>
            <Grid item md={4} xs={12} sm={5}>
              <div className="cardSelectAulas">
                <h4>Invisíveis</h4>
                <Grid container direction="row" spacing={2} className='fundo'>
                  <Grid item md={12} xs={12} sm={12}>
                    <h5>Itens</h5>
                  </Grid>
                </Grid>
                <div className="cardSelectAulasOverFlow">
                  {registros.list.filter(param => {
                    if (registrosSelecionados.findIndex(i => i.id === param.id) === -1) {
                      return true
                    } else {
                      return false
                    }
                  }).sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)).map(registro => {
                    return (
                      <div className='item' onClick={() => addRegistro(registro)}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item md={12} xs={12} sm={12}>
                            <p key={registro.id}>{registro.nome}</p>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
                {/* <h4 className='cursorPointer' onClick={e => addTodasAulas()}>Selecionar Todos</h4> */}
              </div>
            </Grid>
            <Grid item md={1} xs={12} sm={2} className="aling-center">
              <SyncAltIcon className="iconeTroca" />
            </Grid>
            <Grid item md={7} xs={12} sm={5}>
              <div className="cardSelectAulas">
                <h4>Visíveis</h4>
                <Grid container direction="row" spacing={2} className='fundo'>
                  <Grid item md={7} xs={12} sm={6}>
                    <h5>Itens</h5>
                  </Grid>
                  <Grid item md={3} xs={12} sm={3}>
                    <h5>Obrigatorio?</h5>
                  </Grid>
                  <Grid item md={1} xs={12} sm={3}>
                    <h5>Ordem</h5>
                  </Grid>
                </Grid>
                <div className="cardSelectAulasOverFlow">
                  {registrosSelecionados.map(registro => {
                    return (
                      <div className='item'>
                        <Grid container direction="row" spacing={1}>
                          <Grid item md={7} xs={12} sm={6} onClick={() => removerRegistro(registro)}>
                            <p style={{marginTop: '8px'}} key={registro.id}>{registro.nome}</p>
                          </Grid>
                          <Grid item md={3} xs={12} sm={6}>
                            {registro.obrigatorio &&
                              <Tooltip aria-label="add" title='Desmarcar'>
                                <IconButton disabled={desabilitarCampos} color="primary" aria-label="editar" className='alinha_botao' onClick={e => updateObrigatoriedade(registro)}>
                                  <CheckCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {!registro.obrigatorio &&
                              <Tooltip aria-label="add" title='Marcar'>
                                <IconButton disabled={desabilitarCampos} color="primary" aria-label="editar" className='alinha_botao' onClick={e => updateObrigatoriedade(registro)}>
                                  <RadioButtonUncheckedIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Grid>
                          <Grid item md={1} xs={12} sm={6}>
                            <TextField 
                              type="number" 
                              className="input" 
                              variant="outlined" 
                              size="small" 
                              name="ordem" 
                              value={registro.ordem} 
                              onChange={(e) => updateFieldOrdem(e, registro.id)}
                              disabled={desabilitarCampos}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
                {/* <h4 className='cursorPointer' onClick={e => removerTodasAulas()}>Remover Todos</h4> */}
              </div>
            </Grid>
          </Grid>
          <hr className="mg_top_10" />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={false} sm={4}></Grid>
            {desabilitarCampos &&
              <Grid item md={3} xs={false} sm={4}></Grid>
            }
            <Grid item md={3} xs={false} sm={4}>
              <Button fullWidth onClick={() => voltar()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
            </Grid>
            {!desabilitarCampos &&
              <Grid item md={3} xs={false} sm={4}>
                <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            }
          </Grid>
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
    </React.Fragment>
  )
}

export default DadosComplementares