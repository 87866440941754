import React, { Component } from 'react'
import './Representante.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, TextField, Button } from '@mui/material'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  representante: {

  },
  representantes: {
    list: []
  },
  representantesFiltrado: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Representantes...',
  cabecalho: [
    { id: 'tipo_unidade', numeric: false, disablePadding: false, label: 'Tipo Unidade', edit: false, link: "" },
    { id: 'representante', numeric: false, disablePadding: true, label: 'Representante/', edit: true, link: "/representante/update/", tooltip: "Editar Representante" },
    { id: 'responsavel', numeric: false, disablePadding: false, label: 'Responsavel', edit: true, link: "/representante/updateResponsavel/", tooltip: "Editar Responsavel" },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade', edit: false, link: "" },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo Pessoa', edit: false, link: "" },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo', edit: false, link: "" }
  ],
  acoesTabela: ['view'],
  filtro: {
    responsavel: "",
    representante: "",
    cidade: "",
    tipo: '',
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Representante extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'representante')[0]

    if(permissoes){
      this.setState({
        permissoes
      })
    }

    try {

      const resp = await axios.get(`${window._env_.REACT_APP_API_URL}/representantes`, this.getToken())

      const representantes = []

      resp.data.forEach(representante => {
        const enderecoPrincipal = representante.pessoa.enderecos.filter(u => u.principal)
        if (representante.pessoa.tipo === "FISICA") {
          representantes.push({
            _id: representante.id,
            tipo_unidade: representante.tipo,
            representante: representante.pessoa.fisica.nome,
            responsavel: representante.responsavel.nome,
            cidade: enderecoPrincipal[0] ? `${enderecoPrincipal[0].cidade} - ${enderecoPrincipal[0].estado}` : '',
            tipo: representante.pessoa.tipo,
            ativo: representante.status
          })
        } else {
          representantes.push({
            _id: representante.id,
            tipo_unidade: representante.tipo,
            representante: representante.pessoa.juridica.nomefantasia,
            responsavel: representante.responsavel.nome,
            cidade: enderecoPrincipal[0] ? `${enderecoPrincipal[0].cidade} - ${enderecoPrincipal[0].estado}` : '',
            tipo: representante.pessoa.tipo,
            ativo: representante.status
          })
        }
      });

      this.setState({
        representantes: {
          list: representantes,
        },
        representantesFiltrado: {
          list: representantes
        },
        loading: false
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const representantes = this.state.representantes.list
    const filtro = this.state.filtro

    const list = representantes.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      representantesFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, representantesFiltrado, permissoes } = this.state

    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    className="borderBottom"
                  >
                  <Grid item md={9} sm={6} xs={12}>
                      <h1 className="titulo">Representante</h1>
                    </Grid>
                    {permissoes.inserir &&
                      <Grid item md={3} sm={6} xs={12}>
                        <Link to="/representante/cadastro">
                          <Button size='small' className="btnCadastrar" variant="contained" color="primary">
                            Cadastrar Representante
                          </Button>
                        </Link>
                      </Grid>
                    }
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_20">
                    {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="Representante" variant="outlined" size="small" name="representante" value={filtro.representante} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="Responsavel" variant="outlined" size="small" name="responsavel" value={filtro.responsavel} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="Cidade" variant="outlined" size="small" name="cidade" value={filtro.cidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Tipo Pessoa"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="tipo"
                        value={filtro.tipo}
                        onChangeCapture={(e) => this.updateFiltro(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value="">Pesquisar</option>
                        <option value="FISICA">
                          Pessoa Física
                          </option>
                        <option value="JURIDICA">
                          Pessoa Júridica
                          </option>
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                  >
                    <Grid item md={12} xs={12}>
                      <Table 
                        urlView="/representante/view/" 
                        headCell={this.state.cabecalho} 
                        rows={representantesFiltrado.list} 
                        acoes={this.state.acoesTabela} />
                    </Grid>
                  </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Representantes"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}