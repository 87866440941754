import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { saveAs } from 'file-saver'
import Alert from './../../components/templates/Alert'
import Table from '../../components/Table'
import real from './../../services/real'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unidade_id: "",
    funcionario_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  cabecalhoTabela2: [],
  acoesTabela2: [],
  relatorios2: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm: {
        visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  urlView2: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  planosConta: {
    list: []
  },
  ModalRelatorio: false,
  funcionarios:{
    list:[]
  },
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default class Financeiro extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/relatorios/Comissoes.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'comissoes')

    this.setState({
      relatorios_options: {
        list: permissoes.map(value => {
          return {
            descricao: value.tela.descricao,
            slug: value.tela.slug,
            perm: {
              visualizar: value.visualizar
            }
          }
        })
      }
    })

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
            let descricao = ''
            if (unidade.tipo === 'MANTENEDOR') {
              descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            }if (unidade.tipo === 'REPRESENTANTE') {
              descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            } else if (unidade.tipo === 'GRUPOECONOMICO') {
              descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            } else if (unidade.tipo === 'UNIDADE') {
              descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
            }

            return {
              id: unidade.id,
              descricao,
              tipo: unidade.tipo
            }
          })
        }
      })

      if(unidades.length === 1){
        const {filtro} = this.state
        filtro.unidade_id = unidades[0].id

        await this.consultaFuncionarios(unidades[0].id)

        this.setState({filtro})
      }
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async consultaFuncionarios(unidade_id){
    try {
      let aux = {
        ativo: true,
        nome: '',
        unidade_descricao: '',
        funcao_descricao: ''
      }
  
      aux.unidadesnegocio = [unidade_id]

      const { data: funcionarios } = await axios.post(`${window._env_.REACT_APP_API_URL}/funcionarios/list/filtro`, aux, this.getToken())

      this.setState({
        funcionarios: {
          list: funcionarios
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro

    let name = event.target.name
    let value = event.target.value

    filtro[name] = value

    if(name === 'unidade_id'){
      await this.consultaFuncionarios(value)
    }

    this.setState({ filtro })
  }

  async filtrar() {
    const {filtro} = this.state

    this.setState({
      loading: true
    })

    if (filtro.unidade_id === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a unidade'
        }
      })
      this.setState({
        loading: false
      })
      return
    }

    if (filtro.data_inicial === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial'
        }
      })
      this.setState({
        loading: false
      })
      return
    }

    if (filtro.data_final === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar a data final'
        }
      })
      this.setState({
        loading: false
      })
      return

    }

    if (filtro.funcionario_id === '' || !filtro.funcionario_id) {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar o atendente!'
        }
      })
      this.setState({
        loading: false
      })
      return

    }
    
    try {
     
      filtro.relatorio = 'comissoes'

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())     
              
      this.setState({
        cabecalhoTabela: data.tabela_1.cabecalhoTabela,
        acoesTabela: data.tabela_1.acoesTabela,
        relatorios: {
          list: data.tabela_1.list
        },
        urlView: data.tabela_1.urlView,
        cabecalhoTabela2: data.tabela_2.cabecalhoTabela,
        acoesTabela2: data.tabela_2.acoesTabela,
        relatorios2: {
          list: data.tabela_2.list
        },
        urlView2: data.tabela_2.urlView
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      console.log(error)
    }


  }

  async imprimir() {
    const { filtro, relatorios, funcionarios, relatorios2 } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      if (filtro.relatorio !== 'contratos-alunos') {
        filtro.list = relatorios.list
        filtro.list2 = relatorios2.list
      }

      filtro.atendente = funcionarios.list.filter(param => param._id === parseInt(filtro.funcionario_id))[0].nome

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, 'Comissoes.pdf')
      
    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  openRow(key){

    const {linhaSelecionada} = this.state

    this.setState({
      linhaSelecionada: linhaSelecionada === key  ? '' : key
    })
  }

  render() {
    const { filtro, funcionarios, cabecalhoTabela, 
      loadingDownload,  unidades, urlView, relatorios, acoesTabela } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          <Grid container spacing={2} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Comissões</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={2} direction="row" className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_id"
                value={filtro.unidade_id}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Unidade</option>
                {unidades.list.map(unidade => {
                  return (
                    <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type='date' label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Atendente"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="funcionario_id"
                value={filtro.funcionario_id}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Atendente</option>
                {funcionarios.list.map(func => {
                  return (
                    <option key={func._id} value={func._id}> {func.nome} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()}>
                Filtrar
              </Button>
            </Grid>
            {relatorios.list.length > 0 &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          <hr className="mg_top_10"/>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {cabecalhoTabela.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={2} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12}>
                      <Table
                        urlView={urlView}
                        headCell={cabecalhoTabela}
                        rows={relatorios.list}
                        acoes={acoesTabela}
                        viewRow={(e, acao, status) => this.viewRow(e, acao, status)} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="row">
                    <Grid item md={9} xs={12} sm={6}>
                      <h4>Acumulado por Serviço:</h4>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <h4 style={{textAlign: 'right'}}>Valor Total: R$ {real(somarValores(relatorios.list.map(value => {return parseFloat(value.valor_comissao.replace("R$", "").replaceAll(".", "").replace(",", "."))})))}</h4>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }

              {this.state.cabecalhoTabela2.length > 0 &&
                <Grid container spacing={2} direction="row">
                  <Grid item md={12} xs={12}>
                    <Table
                      urlView={this.state.urlView2}
                      headCell={this.state.cabecalhoTabela2}
                      rows={this.state.relatorios2.list}
                      acoes={this.state.acoesTabela2}
                      viewRow={(e, acao, status) => this.viewRow(e, acao, status)} />
                  </Grid>
                </Grid>
              }
            </React.Fragment>
          }
        </Main>
        <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Comissões"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}