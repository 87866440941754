import React, { useState, useEffect } from 'react';
import './ContaFinanceira.scss'
import './../../main/ultil.scss'
import CurrencyInput from './../../components/mask/CurrencyInput'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, TextField, Button } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import ModalConfirmarSenha from './../../components/modals/ConfirmarSenha'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import real from '../../services/real'
import { useParams, useNavigate } from 'react-router-dom';
import Alert from './../../components/templates/Alert'

const initialState = {
  contaFinanceiraAux: {
    contfin_id: '',
    contfin_descricao: '',
    contfin_ordem: '',
    contfin_conciliavel: '',
    contfin_saldo_inicial: '',
    contfin_saldo_atual: '',
    contfin_saldo_atual_corrigido: '',
    contfin_tipo_conta: '',
    contfin_pes_id: '',
    contfin_ativo: '',
    contfin_cofre: '',
    contfin_saldo_nao_conciliado: '',
    contfin_unin_id: '',
    observacao: '',
    data_ajuste: ''
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  loadingSalvar: false,
  update: false,
  modalSenha: false,
  login: {
    login: '',
    senha: ''
  },
  showPassword: false
}

const CorrigirSaldo = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { contaFinanceiraId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = (event) => {
    const contaFinanceira = { ...state.contaFinanceira }

    contaFinanceira[event.target.name] = event.target.value
    setState(prevState => ({...prevState, contaFinanceira }))
  }

  const handleCloseConfirmarSenha = () => {
    setState(prevState => ({...prevState,
      modalSenha: false
    }))
  }

  const backPage = (timeout = 1000) => {
    setState(prevState => ({...prevState,
      contaFinanceira: {
        contfin_id: '',
        contfin_descricao: '',
        contfin_ordem: '',
        contfin_conciliavel: '',
        contfin_saldo_inicial: '',
        contfin_saldo_atual: '',
        contfin_saldo_atual_corrigido: '',
        contfin_tipo_conta: '',
        contfin_pes_id: '',
        contfin_ativo: '',
        contfin_cofre: '',
        contfin_saldo_nao_conciliado: '',
        contfin_unin_id: '',
        observacao: ''
      },
      loadingSalvar: false
    }))
    setTimeout(() => {
      navigate("/conta_financeira");
    }, timeout)

  }

  const validateForm = () => {
    const { contaFinanceira } = state

    if (!contaFinanceira.contfin_saldo_atual_corrigido) return false
    if (!contaFinanceira.observacao) return false

    return true
  }

  const updateData = (event) => {
    const { login } = state

    login[event.target.name] = event.target.value
    setState(prevState => ({...prevState, login }))
  }

  const openConfirmaSenha = () => {
    setState(prevState => ({...prevState,
      login: {
        login: '',
        senha: ''
      }
    }))
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        loadingSalvar: false,
        alerta: {
          open: true,
          severity: 'warnig',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    setState(prevState => ({...prevState,
      modalSenha: true
    }))
  }

  const verificaData = (event) => {
    const contaFinanceira = { ...state.contaFinanceira }
    
    const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
    const data_vencimento = new Date(event.target.value)

    if (data_vencimento.getTime() > data_hoje.getTime()) {
      contaFinanceira.data_ajuste = moment(new Date()).format('YYYY-MM-DD')
    }

    setState(prevState => ({...prevState,
      contaFinanceira
    }))
  }

  const salvar = async (value) => {
    if (value) {
      setState(prevState => ({...prevState,
        loadingSalvar: true
      }))

      let valido = true

      try {
        const login = {
          login: JSON.parse(localStorage.getItem('usu_login')),
          senha: state.login.senha
        }

        await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/login`, login)

        valido = true

      } catch (error) {
        valido = false
        setState(prevState => ({...prevState,
          loadingSalvar: false,
          alerta: {
            open: true,
            severity: 'warnig',
            message: "Por favor informar a senha corretamente!"
          }
        }))
      }

      if (valido) {
        try {

          const { contaFinanceira } = state

          if(contaFinanceira.data_ajuste === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warnig',
                message: 'Por favor informar a data do ajuste.'
              }
            }))
            return
          }

          if(contaFinanceira.observacao === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warnig',
                message: 'Por favor informar a justificativa.'
              }
            }))
            return
          }

          const dados = {
            contfin_saldo_atual_corrigido: parseFloat(contaFinanceira.contfin_saldo_atual_corrigido.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
            observacao: contaFinanceira.observacao,
            data_ajuste: contaFinanceira.data_ajuste
          }

          await axios.put(`${window._env_.REACT_APP_API_URL}/contaFinanceira/corrigeSaldo/${contaFinanceira.contfin_id}`, dados, getToken())

          setState(prevState => ({...prevState,
            contaFinanceira: {
              contfin_id: '',
              contfin_descricao: '',
              contfin_ordem: '',
              contfin_conciliavel: '',
              contfin_saldo_inicial: '',
              contfin_saldo_atual: '',
              contfin_saldo_atual_corrigido: '',
              contfin_tipo_conta: '',
              contfin_pes_id: '',
              contfin_ativo: '',
              contfin_cofre: '',
              contfin_saldo_nao_conciliado: '',
              contfin_unin_id: '',
              observacao: ''
            }
          }))

          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Atualização realizada com sucesso.'
            }
          }))
          backPage()

        } catch ({ response }) {
          setState(prevState => ({...prevState,
            loadingSalvar: false,
            alerta: {
              open: true,
              severity: 'error',
              message: response.data.message
            }
          }))
          return
        }
      }
    } else {
      setState(prevState => ({...prevState,
        loadingSalvar: false
      }))
      setState(prevState => ({...prevState,
        modalSenha: false
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleClickShowPassword = () => {
    setState(prevState => ({...prevState,
      showPassword: !state.showPassword
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({...prevState,
        contaFinanceiraAux: {
          contfin_id: '',
          contfin_descricao: '',
          contfin_ordem: '',
          contfin_conciliavel: '',
          contfin_saldo_inicial: '',
          contfin_saldo_atual: '',
          contfin_saldo_atual_corrigido: '',
          contfin_tipo_conta: '',
          contfin_pes_id: '',
          contfin_ativo: '',
          contfin_cofre: '',
          contfin_saldo_nao_conciliado: '',
          contfin_unin_id: '',
          observacao: ''
        },
        login: {
          login: '',
          senha: ''
        },
      }))
  
      const perfil = JSON.parse(localStorage.getItem('perfil'))
      let permissoesCorrigeSaldo = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'ajuste_saldo_conta')[0]
  
      if (!permissoesCorrigeSaldo.perm_inserir && !permissoesCorrigeSaldo.perm_alterar) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: "Você não tem permissão para acessa essa tela!"
          }
        }))  
        backPage()
      }
  
      if (contaFinanceiraId) {
        try {
          const { data: contaFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/${contaFinanceiraId}`, getToken())
  
          let contaFinanceiraAux = {
            contfin_id: contaFinanceira.contfin_id,
            contfin_descricao: contaFinanceira.contfin_descricao,
            contfin_ordem: contaFinanceira.contfin_ordem,
            contfin_conciliavel: contaFinanceira.contfin_conciliavel,
            contfin_saldo_inicial: real(contaFinanceira.contfin_saldo_inicial),
            contfin_saldo_atual: real(contaFinanceira.contfin_saldo_atual),
            contfin_saldo_atual_corrigido: real(contaFinanceira.contfin_saldo_atual),
            contfin_tipo_conta: contaFinanceira.contfin_tipo_conta,
            contfin_pes_id: contaFinanceira.contfin_pes_id,
            contfin_ativo: contaFinanceira.contfin_ativo,
            contfin_cofre: contaFinanceira.contfin_cofre ? 'Sim' : 'Não',
            contfin_saldo_nao_conciliado: contaFinanceira.contfin_saldo_nao_conciliado,
            contfin_unin_id: contaFinanceira.contfin_unin_id,
            observacao: ''
          }
  
          setState(prevState => ({...prevState,
            contaFinanceira: contaFinanceiraAux
          }))
  
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "Não foi possível buscar a Contas Bancárias, tente mais tarde"
            }
          }))
  
          backPage()
        }
      }
  
      setState(prevState => ({...prevState, loading: false }))
    };
    
    fetchData();
  }, []);

  const { contaFinanceira, loadingSalvar } = state

  const dataHoje = new Date()
  const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Contas Bancárias...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={11}>
                <h1 className="titulo">Corrigir Saldo Contas Bancárias</h1>
              </Grid>
              <Grid item md={1}>
                {state.loadingSalvar &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_20">
              <Grid item md={1} xs={12} sm={2}>
                <TextField className="input" label="Ordem" variant="outlined" size="small" name="contfin_ordem" value={contaFinanceira.contfin_ordem} InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
              <Grid item md={3} xs={12} sm={4}>
                <TextField className="input" label="Descrição" variant="outlined" size="small" name="contfin_descricao" value={contaFinanceira.contfin_descricao} InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
              <Grid item md={1} xs={12} sm={4}>
                <TextField className="input" label="Conciliavel" variant="outlined" size="small" name="contfin_conciliavel" value={contaFinanceira.contfin_conciliavel ? 'Sim' : 'Não'} InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
              <Grid item md={1} xs={12} sm={4}>
                <TextField className="input" label="Tipo Conta" variant="outlined" size="small" name="contfin_tipo_conta" value={contaFinanceira.contfin_tipo_conta} InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
              <Grid item md={2} xs={12} sm={4}>
                <TextField className="input" label="Saldo Atual" variant="outlined" size="small" name="contfin_saldo_atual" value={contaFinanceira.contfin_saldo_atual} InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
              <Grid item md={2} xs={12} sm={4}>
                <TextField className="input" label="Saldo Corrigido*" variant="outlined" size="small" name="contfin_saldo_atual_corrigido" value={contaFinanceira.contfin_saldo_atual_corrigido} InputLabelProps={{ shrink: true }} onChange={(e) => updateField(e)} InputProps={{ inputComponent: CurrencyInput }} />
              </Grid>
              <Grid item md={2} xs={12} sm={4}>
                <TextField className="input" label="Data AJuste*" type='date' variant="outlined" size="small" name="data_ajuste" value={contaFinanceira.data_ajuste} InputLabelProps={{ shrink: true }} onBlur={(e) => verificaData(e)} onChange={(e) => updateField(e)} InputProps={{ inputProps: { max: dataHojeFormatado } }} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Justificativa*"
                  className="input"
                  multiline
                  rows={5}
                  size="small"
                  variant="outlined"
                  name="observacao"
                  value={contaFinanceira.observacao}
                  onChange={(e) => updateField(e)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <br />
            <hr />
            <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
              <Grid item md={9} xs={12} sm={8}></Grid>
              <Grid item md={3} xs={12} sm={4}>
                <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => openConfirmaSenha(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
        <br />
        <ModalConfirmarSenha
          open={state.modalSenha}
          handleClose={e => handleCloseConfirmarSenha(e)}
          dados={state.login}
          updateData={e => updateData(e)}
          confirmar={e => salvar(e)}
          disabled={loadingSalvar}
          showPassword={state.showPassword}
          handleClickShowPassword={e => handleClickShowPassword(e)} 
          mensagem={'Deseja continuar com a correção do saldo?'}
        />
      </Main>
      <Nav />
      <Alert
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
    </div>
  )
}

export default CorrigirSaldo