import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import real from './../../services/real'
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';


const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));


export default function SimpleModal(props) {
  const {dados} = props
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Contas do Dia: {dados.data_vencimento}</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row">
            {dados.parcelas.sort((a, b) => (a.datavencimento_orig > b.datavencimento_orig) ? 1 : ((b.datavencimento_orig > a.datavencimento_orig) ? -1 : 0)).map((value, key) => {
              return (
                <Grid 
                  key={key} 
                  item 
                  md={3} xs={12} sm={4}
                >
                  <div
                    style={{
                      width: '96%',
                      marginLeft: '2%',
                      marginTop: '20px',
                      backgroundColor: props.renderClassFinanceiro(value.status),
                      border: '1px solid #b0b0b0',
                      height: '160px',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      position: 'relative'
                    }}
                  >
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12} sm={12} style={{textTransform: 'uppercase'}}>
                        <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>{value.conta.descricao} - {value.cliente}</p></b>
                        <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Vencimento: {moment(value.datavencimento_orig).format('DD/MM/YYYY')}</p></b>
                        <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Valor: R$ <span>{real(value.valor_parcela + value.multajuros)}</span></p></b>
                        <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Forma de Pagto: {value.forma_pagto_descricao}</p></b>
                        <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Situação: {value.status}</p></b>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )
            })}
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={3} xs={12} sm={4} >
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.voltar()}>Voltar</Button>
            </Grid>
          </Grid>
          {/* <br />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={5}>
              <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={dados.descricao} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={4} xs={12} sm={5}>
              <TextField className="input" label="Responsável Financeiro" variant="outlined" size="small" name="responsavel" value={dados.responsavel} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={2} xs={12} sm={5}>
              <TextField className="input" label="Valor Total" variant="outlined" size="small" name="valor" value={dados.valor} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={2} xs={12} sm={5}>
              <TextField className="input" label="Tipo da Conta" variant="outlined" size="small" name="tipo_conta" value={dados.tipo_conta} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
          </Grid>
          <br />
          <hr />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className='titulo'>Contas: </h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={dados.parcelas.map(parcela => {
                  return {
                    _id: parcela.id,
                    descricao: `${parcela.conta.descricao} - Parc ${parcela.num}/${parcela.conta.numparcela}`,
                    valor:'R$ ' + real(parcela.valor_parcela),
                    multajuros: 'R$ ' + real(parcela.multajuros),
                    valor_total:'R$ ' + real(parcela.valor_parcela + parcela.multajuros),
                    status: parcela.status,
                    boleto_transaction_id: parcela.boleto_transaction_id,
                    cliente: parcela.cliente,
                    forma_pagto: parcela.forma_pagto_descricao
                  }
                })}
                qtdLinhas={dados.parcelas.length}
                acoes={acoesTabela} 
              />
            </Grid>
          </Grid> */}
        </StyledPaper>
      </Modal>
    </div>
  );
}
