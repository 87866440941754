import React, { useState, useEffect } from 'react';
import './Movimentacao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormMovimentacao from '../../components/forms/FormMovimentacao'
import SaveIcon from '@mui/icons-material/Save'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import moment from 'moment'
import real from '../../services/real'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  movimentacoes: [
    {
      data_hora_conta: "",
      descricao: "",
      tipo: "SAQUE",
      conciliado: false,
      valor: "",
      contfin_id: "",
      conciliavel: "",
      saldo_atual: "",
      contfin_descricao: "",
      contfin_transferencia_id: ""
    },
    {
      data_hora_conta: "",
      descricao: "",
      tipo: "DEPOSITO",
      conciliado: false,
      valor: "",
      contfin_id: "",
      conciliavel: "",
      saldo_atual: "",
      contfin_descricao: ""
    }
  ],
  tipo: "",
  contasFinanceira: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  update: false,
  contaSelecionadaSaque:{
    parceiro_id: null,
    saldo_atual: 0
  },
  contaSelecionadaDeposito:{
    parceiro_id: null,
    saldo_atual: 0
  },
  openModalAjuda: false,
  markdown: '',
  tiposMovimentacao: [
    {
      value: 'TRANSFERENCIA',
      name: 'Transferência'
    },
    {
      value: 'SAQUE',
      name: 'Saque'
    },
    {
      value: 'DEPOSITO',
      name: 'Deposito'
    }
  ],
  travarCampos: false
}

const CadastroMovimentacao = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateFieldSaque = (event) => {
    const movimentacoes = { ...state.movimentacoes }

    if (event.target.name === 'contfin_id') {
      
      if(event.target.value !== ''){
        const conta = state.contasFinanceira.list.filter(conta => conta.id === parseInt(event.target.value))[0]
        
        movimentacoes[0].conciliavel = conta.conciliavel
        movimentacoes[0].saldo_atual = conta.saldo_atual
        movimentacoes[0].contfin_descricao = conta.descricao
  
        if (state.tipo === 'TRANSFERENCIA') {
          movimentacoes[0].descricao = `Transferência recebida da conta: ${movimentacoes[1].contfin_descricao}`
          movimentacoes[1].descricao = `Transferência para a conta: ${movimentacoes[0].contfin_descricao} `
          
        }

        setState(prevState => ({...prevState,
          contaSelecionadaSaque: conta
        }))
      }else{
        movimentacoes[0].conciliavel = false
        movimentacoes[0].saldo_atual = ""
        movimentacoes[0].contfin_descricao = ""

        if (state.tipo === 'TRANSFERENCIA') {
          movimentacoes[0].descricao = ``
          movimentacoes[1].descricao = ``
        }

        setState(prevState => ({...prevState,
          contaSelecionadaSaque: {
            parceiro_id: null
          }
        }))
      }

    }

    if (state.tipo === 'TRANSFERENCIA') {
      if (event.target.name === 'valor') {
        movimentacoes[1].valor = event.target.value
      }
    }

    movimentacoes[0][event.target.name] = event.target.value
    setState(prevState => ({...prevState, movimentacoes }))
  }

  const verificaValorSaldo = (event) => {
    const movimentacoes = { ...state.movimentacoes }

    if (movimentacoes[0].saldo_atual < parseFloat(event.target.value.replace("R$ ", "").replaceAll(".", "").replace(",", "."))) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Valor de saque maior que o saldo atual da conta!'
        }
      }))

      movimentacoes[0].valor = ''
      movimentacoes[1].valor = ''

      setState(prevState => ({...prevState, movimentacoes }))
    }
  }

  const updateFieldDeposito = (event) => {
    const movimentacoes = { ...state.movimentacoes }

    if (event.target.name === 'contfin_id') {

      if(event.target.value !== ''){ 
        const conta = state.contasFinanceira.list.filter(conta => conta.id === parseInt(event.target.value))
        movimentacoes[1].conciliavel = conta[0].conciliavel
        movimentacoes[1].saldo_atual = conta[0].saldo_atual
        movimentacoes[1].contfin_descricao = conta[0].descricao
  
        if (state.tipo === 'TRANSFERENCIA') {
          movimentacoes[0].descricao = `Transferência para a conta: ${movimentacoes[1].contfin_descricao}`
          movimentacoes[1].descricao = `Transferência recebida da conta: ${movimentacoes[0].contfin_descricao} `
        }

        setState(prevState => ({...prevState,
          contaSelecionadaDeposito: conta
        }))

      }else{
        movimentacoes[1].conciliavel = false
        movimentacoes[1].saldo_atual = ''
        movimentacoes[1].contfin_descricao = ''
  
        if (state.tipo === 'TRANSFERENCIA') {
          movimentacoes[0].descricao = ''
          movimentacoes[1].descricao = ''
        }

        setState(prevState => ({...prevState,
          contaSelecionadaDeposito: {
            parceiro_id: null
          }
        }))
      }


    }

    movimentacoes[1][event.target.name] = event.target.value
    setState(prevState => ({...prevState, movimentacoes }))
  }

  const updateFieldConciliadoSaque = () => {
    const movimentacoes = { ...state.movimentacoes }

    movimentacoes[0].conciliado = !movimentacoes[0].conciliado
    setState(prevState => ({...prevState, movimentacoes }))
  }

  const updateFieldConciliadoDeposito = () => {
    const movimentacoes = { ...state.movimentacoes }

    movimentacoes[1].conciliado = !movimentacoes[1].conciliado

    setState(prevState => ({...prevState, movimentacoes }))
  }

  const updateFieldTipo = (event) => {
    let movimentacoes= [
      {
        data_hora_conta: "",
        descricao: "",
        tipo: "SAQUE",
        conciliado: false,
        valor: "",
        contfin_id: "",
        conciliavel: "",
        saldo_atual: "",
        contfin_descricao: ""
      },
      {
        data_hora_conta: "",
        descricao: "",
        tipo: "DEPOSITO",
        conciliado: false,
        valor: "",
        contfin_id: "",
        conciliavel: "",
        saldo_atual: "",
        contfin_descricao: ""
      }
    ]

    let dados_movimentacao = JSON.parse(localStorage.getItem('dados_movimentacao'))

    if(dados_movimentacao){
      movimentacoes = dados_movimentacao
    }

    setState(prevState => ({...prevState,
      movimentacoes,
      contaSelecionadaSaque:{
        parceiro_id: null,
        saldo_atual: 0
      },
      contaSelecionadaDeposito:{
        parceiro_id: null,
        saldo_atual: 0
      },
      tipo: event.target.value
    }))

  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/movimentacao_financeira");
    }, timeout)
  }

  const validateForm = () => {
    const { movimentacoes, tipo } = state

    if (!tipo) return false

    if (tipo === 'TRANSFERENCIA') {

      if (!movimentacoes[0].descricao) return false
      if (!movimentacoes[0].valor) return false
      if (!movimentacoes[0].contfin_id) return false

      if (!movimentacoes[1].descricao) return false
      if (!movimentacoes[1].valor) return false
      if (!movimentacoes[1].contfin_id) return false


    } else if (tipo === 'SAQUE') {
      if (!movimentacoes[0].descricao) return false
      if (!movimentacoes[0].valor) return false
      if (!movimentacoes[0].contfin_id) return false

    } else if (tipo === 'DEPOSITO') {
      if (!movimentacoes[1].descricao) return false
      if (!movimentacoes[1].valor) return false
      if (!movimentacoes[1].contfin_id) return false

    }

    return true
  }

  const salvar = async () => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    try {
      const { movimentacoes, tipo } = state

      const usuario = JSON.parse(localStorage.getItem('usuario'))

      setState(prevState => ({...prevState,
        loading: true,
        mensagemLoading: 'Cadastrando Movimentação'
      }))

      let movimentacao_extrato = JSON.parse(localStorage.getItem('movimentacao_extrato'))

      if (tipo === 'TRANSFERENCIA') {
        let dados_transferencia = {
          data_hora_conta: moment(movimentacoes[0].data_hora_conta).format('YYYY-MM-DD') + ' 00:01:00',
          descricao: 'Transferência - ' + movimentacoes[0].descricao,
          descricao_destino:  'Transferência - ' + movimentacoes[1].descricao,
          tipo: 'TRANSFERENCIA',
          conciliado: movimentacoes[0].conciliado,
          valor: parseFloat(movimentacoes[0].valor.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          destino_id: parseInt(movimentacoes[1].contfin_id),
          origem_id: parseInt(movimentacoes[0].contfin_id),
          usu_criador_id: usuario.usuario_id,
        }

        if(movimentacao_extrato){
          dados_transferencia.extrato_id = movimentacao_extrato.id
        }
        
        await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/transferencia`, dados_transferencia, getToken())
        
      } else if (tipo === 'SAQUE') {

        let movimentacao = {...movimentacoes[0]}

        movimentacao.data_hora_conta = moment(movimentacao.data_hora_conta).format('YYYY-MM-DD') + ' 00:01:00'
        movimentacao.valor = parseFloat(movimentacao.valor.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
        movimentacao.valor = movimentacao.valor > 0 ? movimentacao.valor * -1 : movimentacao.valor
        movimentacao.usu_criador_id = usuario.usuario_id
        movimentacao.contfin_id = parseInt(movimentacao.contfin_id) 

        movimentacao.descricao = 'Saque - ' + movimentacao.descricao
        
        if(!movimentacao.conciliavel){
          movimentacao.conciliado = true
          movimentacao.usu_baixa_id = usuario.usuario_id
        }

        delete movimentacao.conciliavel
        delete movimentacao.saldo_atual
        delete movimentacao.contfin_descricao

        if(movimentacao_extrato){
          movimentacao.extrato_id = movimentacao_extrato.id
        }

        await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira`, movimentacao, getToken())

      } else if (tipo === 'DEPOSITO') {

        let movimentacao = {...movimentacoes[1]}

        movimentacao.data_hora_conta = moment(movimentacao.data_hora_conta).format('YYYY-MM-DD') + ' 00:01'
        movimentacao.valor = parseFloat(movimentacao.valor.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
        movimentacao.usu_criador_id = usuario.usuario_id
        movimentacao.contfin_id = parseInt(movimentacao.contfin_id) 

        movimentacao.descricao = 'Deposito - ' + movimentacao.descricao

        if(!movimentacao.conciliavel){
          movimentacao.conciliado = true
          movimentacao.usu_baixa_id = usuario.usuario_id
        }
      
        delete movimentacao.conciliavel
        delete movimentacao.saldo_atual
        delete movimentacao.contfin_descricao

        if(movimentacao_extrato){
          movimentacao.extrato_id = movimentacao_extrato.id
        }

        await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira`, movimentacao, getToken())

      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      if(movimentacao_extrato){
        setTimeout(() => {
          localStorage.removeItem('movimentacao_extrato')
          localStorage.removeItem('dados_movimentacao')
  
          navigate("/movimentacao_financeira/importar_extrato");
        }, 2000)
      }else{
        backPage()
      }

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/movimentacao/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })
        
      let movimentacoes= [
        {
          data_hora_conta: "",
          descricao: "",
          tipo: "SAQUE",
          conciliado: false,
          valor: "",
          contfin_id: "",
          conciliavel: "",
          saldo_atual: "",
          contfin_descricao: ""
        },
        {
          data_hora_conta: "",
          descricao: "",
          tipo: "DEPOSITO",
          conciliado: false,
          valor: "",
          contfin_id: "",
          conciliavel: "",
          saldo_atual: "",
          contfin_descricao: ""
        }
      ]
      let tipo = ""

      let dados_movimentacao = JSON.parse(localStorage.getItem('dados_movimentacao'))

      if(dados_movimentacao){
        movimentacoes = dados_movimentacao
        tipo = movimentacoes[0].contfin_id ? 'SAQUE' : 'DEPOSITO'

        if(tipo === 'SAQUE'){
          setState(prevState => ({...prevState,
            tiposMovimentacao: [
              {
                value: 'TRANSFERENCIA',
                name: 'Transferência'
              },
              {
                value: 'SAQUE',
                name: 'Saque'
              }
            ]
          }))
        }else{
          setState(prevState => ({...prevState,
            tiposMovimentacao: [
              {
                value: 'TRANSFERENCIA',
                name: 'Transferência'
              },
              {
                value: 'DEPOSITO',
                name: 'Deposito'
              }
            ]
          }))
        }

        setState(prevState => ({...prevState,
          travarCampos: true
        }))
      }

      setState(prevState => ({...prevState,
        movimentacoes,
        tipo,
        contasFinanceira: {
          list: []
        }
      }))

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          filtro: {
            tipo_conta: "",
            descricao: "",
            ativo: "Sim"
          },
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())

        const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
          return {
            id: param.id,
            descricao: param.descricao,
            ordem: param.ordem,
            saldo_atual: real(param.saldo_atual),
            tipo_conta: param.tipo_conta,
            ativo: param.ativo,
            conciliavel: param.conciliavel,
            unin_descricao: param.unin_descricao,
            logo: param.logo,
            parceiro_id: param.parceiro_id
          }
        })

        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contaFinanceiras
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { movimentacoes } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Movimentação Financeira</h1>
                </Grid>
              </Grid>
              <FormMovimentacao
                updateFieldDeposito={e => updateFieldDeposito(e)}
                verificaValorSaldo={e => verificaValorSaldo(e)}
                updateFieldSaque={e => updateFieldSaque(e)}
                updateFieldTipo={e => updateFieldTipo(e)}
                updateFieldConciliadoSaque={e => updateFieldConciliadoSaque(e)}
                updateFieldConciliadoDeposito={e => updateFieldConciliadoDeposito(e)}
                dados={movimentacoes}
                tipo={state.tipo}
                contasFinanceira={state.contasFinanceira.list}
                contaSelecionadaDeposito={state.contaSelecionadaDeposito}
                contaSelecionadaSaque={state.contaSelecionadaSaque}
                tiposMovimentacao={state.tiposMovimentacao}
                travarCampos={state.travarCampos}
              />

              <Grid container direction="row" alignItems="flex-end" className="mg_top_20">
                <Grid item md={9} xs={12} sm={8}></Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
            <br />
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Movimentação"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroMovimentacao


//   render() {

//   }
// }