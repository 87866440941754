module.exports = (str) => {
  if(str){
    // console.log(number)
    // // Converte o número para string caso não seja
    // const str = number.toString();
  
    // Extrai o código do país, DDD e o restante do número
    const countryCode = str.slice(0, 2);
    const areaCode = str.slice(2, 4);
    const mainNumber = str.slice(4);
  
    // Formata o número dependendo do comprimento
    let formattedNumber;
  
    if (mainNumber.length === 9) {
      // Formato para celular
      formattedNumber = `${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;
    } else if (mainNumber.length === 8) {
      // Formato para telefone fixo
      formattedNumber = `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`;
    } else {
      // Retorna o número original se não tiver o comprimento esperado
      return str;
    }
  
    // Retorna o número formatado com o código do país e DDD
    return `+${countryCode} (${areaCode}) ${formattedNumber}`;
  }

  return ''
}