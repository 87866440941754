import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CurrencyInput from './../mask/CurrencyInput'
import real from '../../services/real';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Table from './../Table'
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Serviço/Produto' },
  { id: 'valor_contrato', numeric: true, disablePadding: true, label: 'Valor de Contrato' },
  { id: 'parcelas', numeric: false, disablePadding: true, label: 'Parcelas' },
  { id: 'venc_prim_parcela', numeric: false, disablePadding: true, label: 'Venc. Prim. Parcela'},
  { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
  { id: 'conta_fin', numeric: false, disablePadding: true, label: 'Contas Bancárias' },
]

const acoesTabela = ['remove', 'editar']

export default function SimpleModal(props) {
  const theme = useTheme();
  const {dadosRenovarContrato, produtos, servico, formasPagamento, contasFinanceira, habilita_servico, contrato,
    updateParcelado, updateRecorrencia, updateDadosRenovar, updateServico, handleClose, confirmar, possui_emissao_nfse
  } = props

  const {updateFieldConta, mudarParcelas, recalcularParcela, updateFieldParcela, travarCampos,
    marcaParcelas, marcaParcelasContaFin, logado, conta, alteraServico
  } = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h6 className="tituloConfirmacao">Renovar Contrato:</h6>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={2} xs={12} sm={6}>
              <TextField type="date"
                className="input"
                label="Antiga Data Final"
                variant="outlined"
                name="data_ini_vig"
                value={dadosRenovarContrato.data_ini_vig}
                disabled
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Acres. Tempo Contrato" 
                type="number"
                variant="outlined" 
                size="small" 
                name="tempo_contrato" 
                value={dadosRenovarContrato.tempo_contrato} 
                disabled={dadosRenovarContrato.unidade_tempo === ''}
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade Tempo Duração"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_tempo"
                value={dadosRenovarContrato.unidade_tempo}
                disabled={dadosRenovarContrato.data_ini_vig === ''}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=''> Selecionar Unidade Tempo </option>
                <option value='DIA'> Dia </option>
                <option value='MÊS'> Mês </option>
                {/* <option value='SEMESTRE'> Semestre </option> */}
                <option value='ANO'> Ano </option>
                
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField type="date"
                className="input"
                label="Nova Data Final"
                variant="outlined"
                name="data_fim_vig"
                value={dadosRenovarContrato.data_fim_vig}
                disabled
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            {/* <Grid item md={3} sm={6} xs={12}>
              <TextField 
                type='date' 
                fullWidth 
                size="small" 
                variant="outlined" 
                label="Nova Data Final do Contrato" 
                name='nova_data_fim'
                value={dadosRenovarContrato.nova_data_fim} 
                InputLabelProps={{ shrink: true }}
                onChange={(e) => updateDadosRenovar(e)}
                InputProps={{ inputProps: { 
                  min: contrato.data_ini_vig_orig 
                } }}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} sm={12} xs={12}>
              <h5 className='titulo'>Serviços:</h5>
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          {!habilita_servico &&
            <Grid container direction="row" spacing={1} className="mg_top_10">
               <Grid item md={9} xs={12} sm={9}></Grid>
             <Grid item md={3} xs={12} sm={3}>
               <Button color='success' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => props.habilitaServico(e)}>{'Informar Serviço'}</Button>
             </Grid>
            </Grid>
          }
          {habilita_servico &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={2} sm={2}>
                  <Button
                    fullWidth 
                    color={servico.parcelado ? 'primary' : 'inherit'} 
                    variant="contained" 
                    size="small" 
                    endIcon={servico.parcelado ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />} 
                    onClick={e => updateParcelado()}
                  >
                    Parcelamento
                  </Button>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                  <Button 
                    fullWidth 
                    color={servico.recorrencia ? 'secondary' : 'inherit'} 
                    variant="contained" 
                    size="small" 
                    endIcon={servico.recorrencia ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
                    onClick={e => updateRecorrencia()}
                  >
                    Recorrência
                  </Button>
                </Grid>
                <Grid item md={1} xs={1} sm={1}></Grid>
              </Grid>
              {servico.recorrencia &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Serviço"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{ native: true }}
                      name="produto_id"
                      value={servico.produto_id}
                      onChangeCapture={(e) => props.updateServico(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""> Selecionar Produto</option>
                      {produtos.list.map(produto => {
                        return (
                          <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={1} xs={12} sm={6}>
                    <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={servico.valor} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField 
                      type='number'
                      className="input" 
                      label="Repetir a cada*" 
                      variant="outlined" 
                      name="recorrenciaACada" 
                      size="small"
                      value={servico.recorrenciaACada} 
                      onChange={(e) => props.updateServico(e)} 
                      InputLabelProps={{ shrink: true }}/>
                  </Grid>
                  <Grid item md={1} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label=""
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipoRecorrencia"
                      value={servico.tipoRecorrencia}
                      onChangeCapture={(e) => props.updateServico(e)}
                    >
                      <option value='d'> Dia </option>
                      <option value='w'> Semana </option>
                      <option value='M'> Mês </option>
                      <option value='y'> Ano </option>
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField 
                      type='number'
                      className="input" 
                      label="Num. de Ocorrências*" 
                      variant="outlined" 
                      name="qtdRecorrencia" 
                      size="small"
                      value={servico.qtdRecorrencia} 
                      onChange={(e) => props.updateServico(e)} 
                      InputLabelProps={{ shrink: true }}/>
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" type='date' label="Venc. Prim. Recor.*" variant="outlined" size="small" name="venc_prim_parcela" value={servico.venc_prim_parcela} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{ native: true }}
                      name="formapagto_id"
                      value={servico.formapagto_id}
                      label="Forma de Pgto.*"
                      onChangeCapture={(e) => props.updateServico(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">Selecionar Forma de Pgto.</option>
                      {formasPagamento.list.map(forma => {
                        return (
                          <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="contfin_id"
                      value={servico.contfin_id}
                      label="Contas Bancárias.*"
                      onChangeCapture={(e) => props.updateServico(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">--Escolha--</option>
                      {contasFinanceira.list.map(conta => {
                        return (
                          <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField 
                      className="input" 
                      label="Totalizando*" 
                      variant="outlined" 
                      name="qtdRecorrencia" 
                      size="small"
                      value={servico.valor ? real(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) * servico.qtdRecorrencia) : real(0)} 
                      InputLabelProps={{ shrink: true }}
                      disabled/>
                  </Grid>
                </Grid>
              }
              {servico.parcelado && 
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={4} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Serviço"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{ native: true }}
                        name="produto_id"
                        value={servico.produto_id}
                        onChangeCapture={(e) => updateServico(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Selecionar Produto</option>
                        {produtos.list.map(produto => {
                          return (
                            <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                          )
                        })}
                      </TextField>
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField 
                        className="input" 
                        label="Valor Total*" 
                        variant="outlined" 
                        size="small" 
                        name="valor" 
                        value={servico.valor} 
                        onChange={(e) => updateServico(e)} 
                        InputLabelProps={{ shrink: true }} 
                        InputProps={{ inputComponent: CurrencyInput }}
                        disabled={servico.produto_id === ''}
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={1}>
                      {possui_emissao_nfse &&
                        <React.Fragment>
                          <div style={{float: 'left'}}>
                            <Tooltip
                              title={'Ao marcar essa opção quando o cliente efetuar o pagamento através do Pix ou do Boleto a NFSe será emitida Automáticamente.'}
                            >
                              <InfoIcon className='info'/>
                            </Tooltip>
                          </div>
                          <div style={{float: 'left'}}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Gerar NFSE?</FormLabel>
                              <FormControlLabel className="mg_left_10"
                                control={
                                  <Switch
                                    name="Ativo"
                                    color="primary"
                                    checked={servico.gerar_nfse}
                                  />
                                }
                                size="small"
                                label={servico.gerar_nfse ? 'Sim' : 'Não'}
                                name="gerar_nfse"
                                onChange={(e) => props.updateFieldGerarNfse(e)}
                              />
                            </FormControl>
                          </div>
                        </React.Fragment>
                      }
                    </Grid>
                  </Grid>
                  {servico.valor !== '' &&
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <div className='scroll'>
                          <table className="lista sempadding borderTable">
                            <thead>
                              <tr className="titulos acompanha">
                                <th>Parcelas</th>
                                <th>Valor*</th>
                                <th>Vencimento*</th>
                                <th>Forma de Pagamento*</th>
                                <th>Contas Bancárias</th>
                                {/* <th>Documento</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                                return (
                                  <tr key={parcela.index}>
                                    <td>
                                      <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                                      <span className="separacaoParcelas">/</span>
                                      <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }}/>
                                    </td>
                                    <td>
                                      <TextField 
                                        className="inputValor" 
                                        variant="outlined" 
                                        size="small" 
                                        name="valorparcela" 
                                        value={parcela.valorparcela} 
                                        onBlur={(e) => recalcularParcela(e, parcela.index)} 
                                        InputLabelProps={{ shrink: true }} 
                                        InputProps={{ inputComponent: CurrencyInput }} 
                                        disabled={travarCampos}
                                      />
                                    </td>
                                    <td>
                                      <TextField 
                                        type="date" 
                                        className="inputVencimento" 
                                        variant="outlined" 
                                        size="small" 
                                        name="datavencimento" 
                                        value={parcela.datavencimento} 
                                        onChange={(e) => updateFieldParcela(e, parcela.index)} 
                                        InputLabelProps={{ shrink: true }}
                                        disabled={travarCampos}
                                      />
                                    </td>
                                    <td>
                                      <TextField
                                        id="standard-select-currency"
                                        select
                                        variant="outlined"
                                        className="inputFormPagamento"
                                        fullWidth
                                        size="small"
                                        SelectProps={{
                                          native: true,
                                        }}
                                        name="formapagto_id"
                                        value={parcela.formapagto_id}
                                        onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                        onBlur={(e) => marcaParcelas(e, parcela.index)}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        <option value="">--Escolha--</option>
                                        {formasPagamento.list.map(forma => {
                                          return (
                                            <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                          )
                                        })}
                                      </TextField>
                                    </td>
                                    <td>
                                      <TextField
                                        id="standard-select-currency"
                                        select
                                        variant="outlined"
                                        className="inputFormPagamento"
                                        fullWidth
                                        size="small"
                                        SelectProps={{
                                          native: true,
                                        }}
                                        name="contfin_id"
                                        value={parcela.contfin_id}
                                        onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                        onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={travarCampos}
                                      >
                                        <option value="">--Escolha--</option>
                                        {contasFinanceira.list.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                                          return (
                                            <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                          )
                                        })}
                                      </TextField>
                                    </td>
                                    {/* <td>
                                      <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                    </td> */}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Grid>
                    </Grid>
                  }
                </React.Fragment>
              }
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={10} xs={12} sm={1}></Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => props.addServico(e)}>{alteraServico ? 'Alterar' : 'Adicionar'}</Button>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
            <Table
              remove={e => props.removerServico(e)}
              editar={e => props.editarServico(e)}
              headCell={cabecalhoTabela}
              rows={dadosRenovarContrato.produtos.map(value => {
                return {
                  produto_id: value.produto_id,
                  descricao: value.descricao,
                  formapagto_id: value.formapagto_id,
                  forma_pagto: value.forma_pagto,
                  valor_contrato: 'R$ '  + (value.recorrencia ? real(parseFloat(value.valor.replaceAll(".", "").replace(",", ".")) * parseInt(value.qtdRecorrencia)) : value.valor),
                  parcelas: value.descricao_parcelamento,
                  venc_prim_parcela: moment(value.venc_prim_parcela).format('DD/MM/YYYY'),
                  conta_fin: value.conta_fin,
                  contfin_id: value.contfin_id,
                  parcelado: value.parcelado,
                  recorrencia: value.recorrencia,
                  index: value.index
                }
              })}
              acoes={acoesTabela} />
            </Grid>
          </Grid>
          <br />
          <hr />
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => confirmar(true)}>Confirmar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => confirmar(false)} >Voltar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div> 
  );
}
