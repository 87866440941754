import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const {agenda} = props
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h6 className="tituloConfirmacao">OS. Núm. {agenda.ordem}</h6>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={4}>
              <TextField className="input" label="Cliente" variant="outlined" size="small" name="cliente" value={agenda.cliente} disabled />
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <TextField className="input" label="Serviço" variant="outlined" size="small" name="servico" value={agenda.servico} disabled />
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <TextField className="input" label="Sala" variant="outlined" size="small" name="ambiente" value={agenda.ambiente} disabled />
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <TextField className="input" label="Atendente" variant="outlined" size="small" name="atendente" value={agenda.atendente} disabled />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={4}>
              <TextField className="input" label="Data do Atendimento" variant="outlined" size="small" name="data" value={moment(agenda.data_inicio).format('DD/MM/YYYY')} disabled />
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <TextField className="input" label="Hora de Inicio" variant="outlined" size="small" name="data" value={moment(agenda.data_inicio).format('HH:mm')} disabled />
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <TextField className="input" label="Hora Fim" variant="outlined" size="small" name="data" value={moment(agenda.data_fim).format('HH:mm')} disabled />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-static"
                label="Observações Atendimento"
                className="input"
                multiline
                rows={2}
                size="small"
                variant="outlined"
                name="observacao"
                value={agenda.observacao === '' ? 'Sem Observações' : agenda.observacao}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-static"
                label="Observações Geral"
                className="input"
                multiline
                rows={2}
                size="small"
                variant="outlined"
                name="observacao_geral"
                value={agenda.observacao_geral === '' ? 'Sem Observações' : agenda.observacao_geral}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={3} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="secondary" 
                startIcon={<ArrowBackIcon />}
                onClick={e => props.handleClose()}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div> 
  );
}
