import React, { useState, useEffect } from 'react';
import './PreContasPR.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import Loading from './../../components/templates/Loading'
import { Grid, Button, TextField } from '@mui/material'
import {Autocomplete} from '@mui/material';
import axios from 'axios'
import moment from 'moment';
import Alert from './../../components/templates/Alert'
import real from '../../services/real'
import { Link } from "react-router-dom";
import { styled } from '@mui/system';
import Color from 'color';
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  filtro: {
    data_inicial: "",
    data_final: "",
    cadastrado: false,
    descricao: "",
    tipo: "",
    item_id: ""
  },
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'data_vencimento', numeric: false, disablePadding: true, label: 'Data Conta', orderBy: 'datavencimentoOrder' },
    { id: 'valor_conta', numeric: true, disablePadding: true, label: 'Valor' },
    { id: 'item_receita_despesa', numeric: false, disablePadding: true, label: 'Item Receita e Despesa' },
    { id: 'forma_pagto', numeric: false, disablePadding: false, label: 'Forma Pagamento'},
    { id: 'conta_fin', numeric: false, disablePadding: false, label: 'Conta Financeira'}
  ],
  acoesTabela: ['concluirPreContas'],
  preContasPR: {
    list:[]
  },
  itens_receita_despesa: {
    list: []
  },
  itemSelecionado:{
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  }
}

const Listagem = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const filtrarContas = async () => {
    try {

      let {filtro} = state
      
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/preContasPR/list`, filtro, getToken())

      setState(prevState => ({...prevState,
        preContasPR: {
          list: data.map(value => {
            return {
              _id: value.id,
              descricao: value.descricao,
              data_vencimento: moment(value.data_vencimento).format('DD/MM/YYYY'),
              data_vencimento_sem_form: value.data_vencimento,
              datavencimentoOrder: moment(value.data_vencimento).format('YYYYMMDD'),
              valor_conta: 'R$ ' + real(value.valor),
              item_receita_despesa: value.item_rec_desp.descricao,
              item_id: value.item_id,
              forma_pagto: value.forma_pagto.descricao,
              forma_pagto_id: value.forma_pagto.id,
              conta_fin: value.conta_financeira.descricao,
              conta_fin_id: value.conta_financeira.id,
              tipo: value.item_rec_desp.plano_contas.tipo === 'DESPESA' ? 'PAGAR' : 'RECEBER',
              habilita: value.forma_pagto.habilita,
              unidade_id: value.unidade_id,
              cadastrado: value.cadastrado
            }
          })
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      return
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const finalizarLancamento = (row) => {
    localStorage.setItem('conta_pre_cadastro', JSON.stringify(row));
    const ultima_tela_financeiro = {
      tela: 'preContasPR',
      id: ''
    }

    localStorage.setItem('ultima_tela_financeiro', JSON.stringify(ultima_tela_financeiro));
    navigate("/conta_pagar_receber/cadastro");
  }

  const updateFiltro = (event) => {
    let {filtro} = state
    
    let name = event.target.name
    let value = event.target.value

    filtro[name] = value

    if(name === 'tipo'){
      setState(prevState => ({...prevState,
        itemSelecionado:{
          id: '',
          codigo: '',
          descricao: '',
          ativo: '',
          slug: '',
          centro_custos: '',
          totalizador: ''
        }
      }))
    }

    setState(prevState => ({...prevState,filtro}))
  }

  const updateFieldItem = (e, value) => {
    const {filtro} = state

    if(!value){

      filtro.item_id = ''

      setState(prevState => ({...prevState,
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        },
        filtro
      }))

      return false
    } 

    filtro.item_id = value.id
      
    setState(prevState => ({...prevState,
      itemSelecionado: value,
      filtro
    }))
  }

  useEffect(() => {
    const fetchData = async () => {

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }
  
        const { data: itens_receita_despesa } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados,getToken())
               
        setState(prevState => ({...prevState,
          itens_receita_despesa: {
            list: itens_receita_despesa.sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0).filter(param => param.ativo).map(item => {
              return {
                id: item.id,
                descricao: item.descricao,
                planocontas_id: item.planocontas_id,
                plano_contas: item.plano_contas
              }
            })
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
      
      await filtrarContas()
    };
    
    fetchData();
  }, []);

  const { preContasPR, filtro, itens_receita_despesa, itemSelecionado } = state

  const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Pré Contas a Pagar/Receber</h1>
                </Grid>
                {acesso_atual.length === 1 &&
                  <Grid item md={3} xs={12}>
                    <Link target='_blank' to={`/preContasPR/${acesso_atual[0].id}`}>
                      <Button size='small' className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Pré Conta
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tipo"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tipo"
                    value={filtro.tipo}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                   <option value=""></option>
                    <option value="PAGAR">Despesa</option>
                    <option value="RECEBER">Receita</option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => updateFieldItem(event, value)}
                    freeSolo
                    options={filtro.tipo !== '' ? itens_receita_despesa.list.filter(param => param.plano_contas.tipo === (filtro.tipo === 'PAGAR' ? 'DESPESA' : 'RECEITA')) :itens_receita_despesa.list}
                    value={itemSelecionado}
                    getOptionLabel={option => `${option.descricao}`}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Item Receita/Despesa*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${itemSelecionado.descricao}`} />
                    )}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={10} xs={false} sm={9}></Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button color='warning' size='small' fullWidth variant="contained" onClick={() => filtrarContas()}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} className='ocultar-mobile'>
                  <Table
                    headCell={state.cabecalhoTabela} 
                    rows={preContasPR.list} 
                    acoes={state.acoesTabela} 
                    tamanhoFonte={'10px'}
                    finalizarLancamento={(e) => finalizarLancamento(e)}
                  />
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default Listagem
