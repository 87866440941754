import React, { useState, useEffect } from 'react';
import './Pesquisa.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, TextField, Button } from '@mui/material'
import ModalEnviarPesquisa from './../../components/modals/EnviarPesquisa'
import Alert from './../../components/templates/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from './../../components/Table'
import { styled } from '@mui/system';
import Color from 'color';
import moment from 'moment'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Chart } from "react-google-charts";
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  pesquisa: {
    id: "",
    titulo: "",
    mensagem: "",
    hora_inicio: "",
    hora_fim: "",
    data_inicio: "",
    data_fim: "",
    ativo: true,
    opcoes: [],
  },
  opcoesFiltrada: {
    list: []
  },
  totalEnvios: 0,
  totalRespostas: 0,
  loadingSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading:true,
  tab: 0,
  cabecalhoTabela: [
    { id: 'titulo', numeric: false, disablePadding: true, label: 'Opção' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'qtd_escolha', numeric: false, disablePadding: true, label: 'Total de Escolhas' },
  ],
  acoesTabela: [],
  openModalEnviarPesquisa: false,
  dadosEnvio: {
    canal: '',
  },
  flagsSelecionadas: [],
  disabledButtons: false,
  tipoGrafico: 'barra',
  options: {
    is3D: true,
  },
  data_chart: [],
  filtro: {
    data_inicio: '',
    data_fim: ''
  }
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const ViewPesquisa = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { pesquisaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 2000) => {
    const self = this
    setTimeout(() => {
      navigate("/pesquisa");
    }, timeout)
    setState(prevState => ({...prevState,
      pesquisa: initialState.pesquisa
    }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const voltar = () => {
    navigate("/pesquisa");
  }

  const editarPesquisa = () => {
    const { pesquisa } = state

    navigate(`/pesquisa/cadastro/${pesquisa.id}`);
  }

  const handleCloseModalEnviarPesquisa = () => {
    setState(prevState => ({...prevState,
      openModalEnviarPesquisa: false
    }))
  }

  const enviarPesquisa = () => {
    setState(prevState => ({...prevState,
      openModalEnviarPesquisa: true
    }))
  }

  const updateFieldEnviaPesquisa = (event) =>{
    let {dadosEnvio} = state

    dadosEnvio[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      dadosEnvio
    }))
  }

  const confirmarEnvioPesquisa = async (value) => {
    if(value){
      let {dadosEnvio, flagsSelecionadas, pesquisa} = state

      if(dadosEnvio.canal === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o canal de comunicação.'
          }
        }))
        return 
      }

      if(flagsSelecionadas.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor selecionar pelo menos uma flag'
          }
        }))

        return 
      }
      
      dadosEnvio.flags = flagsSelecionadas
      dadosEnvio.pesquisa_id = pesquisa.id

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Enviando pesquisa.'
        }
      }))

      handleCloseModalEnviarPesquisa()

      pesquisa.enviado = true

      setState(prevState => ({...prevState,
        pesquisa
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/pesquisa/enviarPesquisa`, dadosEnvio, getToken())
      
    }else{
      handleCloseModalEnviarPesquisa()
    }
  }

  const updateFieldFlags = (event, value) => {
    setState(prevState => ({...prevState,
      flagsSelecionadas: value
    }))
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState,
      tab: newValue
    }))
  };

  const alterarTipoGrafico = (value) => {
    setState(prevState => ({...prevState,
      tipoGrafico: value
    }))
  }

  const updateFiltro = (event) => {
    const {filtro} = state

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtro
    }))

  }

  const filtrarOpcoes = (array) => {
    let {filtro} = state

    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      
      let respostas = []

      for (const resposta of element.respostas) {
        if(filtro.data_fim === ''){
          if(filtro.data_inicio === moment(resposta.criado_em).format('YYYY-MM-DD')){
            respostas.push({...resposta})
          }else if(moment(filtro.data_inicio).isBefore(moment(resposta.criado_em).format('YYYY-MM-DD'))){
            respostas.push({...resposta})
          }
        }else{
          if(filtro.data_inicio === moment(resposta.criado_em).format('YYYY-MM-DD') || filtro.data_fim === moment(resposta.criado_em).format('YYYY-MM-DD')){
            respostas.push({...resposta})
          }else if(moment(filtro.data_inicio).isBefore(moment(resposta.criado_em).format('YYYY-MM-DD')) && moment(filtro.data_fim).isAfter(moment(resposta.criado_em).format('YYYY-MM-DD'))){
            respostas.push({...resposta})
          }
        }
      }

      element.respostas = respostas

      array[i] = element
    }

    return array
  }

  const filtrar = async () => {
    try {
      let {filtro} = state

      if(filtro.data_inicio === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: 'Por favor informar uma data inicial'
          }
        }))

        return 
      }

      const { data: pesquisa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pesquisa/${state.pesquisa.id}`, getToken())

      let unidades = ''

      for (let i = 0; i < pesquisa.unidades.length; i++) {
        if((i + 1) === pesquisa.unidades.length){
          unidades += `${pesquisa.unidades[i].unidade.descricao}`
        }else{
          unidades += `${pesquisa.unidades[i].unidade.descricao}, `
        }
      } 

      let pesquisa_aux = {
        id: pesquisa.id,
        titulo: pesquisa.titulo,
        mensagem: pesquisa.mensagem,
        hora_inicio: pesquisa.hora_inicio,
        hora_fim: pesquisa.hora_fim,
        data_inicio: pesquisa.data_inicio,
        data_fim: pesquisa.data_fim,
        ativo: pesquisa.ativo,
        enviado: pesquisa.enviado,
        opcoes: filtrarOpcoes(pesquisa.opcoes).sort((a, b) => (a.titulo < b.titulo) ? 1 : (b.titulo < a.titulo) ? -1 : 0).map((opcao, key) => {
          return {
            id: opcao.id,
            titulo: opcao.titulo,
            descricao: opcao.descricao,
            qtd_escolha: opcao.qtd_escolha,
            respostas: opcao.respostas,
            index: key
          } 
        }),
        unidades,
        envio: pesquisa.envio,
      }

      let totalEnvios = 0
      let totalRespostas = 0

      if(pesquisa.envio){
        setState(prevState => ({...prevState,
          dadosEnvio: {
            canal: pesquisa.envio.meio_envio,
          },
          flagsSelecionadas: pesquisa.envio.flags.split(",")
        }))

        totalEnvios = pesquisa.envio.qtd_pessoas
      }

      totalRespostas = somarValores(pesquisa.opcoes.map(value => { return value.qtd_escolha }))

      let data_chart = [["Opções", "Qtd. Votos"]]

      for (const opcao of pesquisa_aux.opcoes) {
        data_chart.push([opcao.titulo, opcao.respostas.length])
      }

      setState(prevState => ({...prevState,
        pesquisa:pesquisa_aux,
        totalEnvios,
        totalRespostas,
        data_chart,
        opcoesFiltrada: {
          list: pesquisa_aux.opcoes
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
    }
  }

  const encerrarPesquisa = async () => {
    try {
      let {data: pesquisa} = await axios.put(`${window._env_.REACT_APP_API_URL}/pesquisa/encerrar/${state.pesquisa.id}`,{}, getToken())
    
      setState(prevState => ({...prevState,
        filtro: {
          data_inicio: pesquisa.data_inicio || '',
          data_fim: pesquisa.data_fim || ''
        },
      }))

      let unidades = ''

      for (let i = 0; i < pesquisa.unidades.length; i++) {
        if((i + 1) === pesquisa.unidades.length){
          unidades += `${pesquisa.unidades[i].unidade.descricao}`
        }else{
          unidades += `${pesquisa.unidades[i].unidade.descricao}, `
        }
      } 

      let pesquisa_aux = {
        id: pesquisa.id,
        titulo: pesquisa.titulo,
        mensagem: pesquisa.mensagem,
        hora_inicio: pesquisa.hora_inicio,
        hora_fim: pesquisa.hora_fim,
        data_inicio: pesquisa.data_inicio,
        data_fim: pesquisa.data_fim,
        ativo: pesquisa.ativo,
        enviado: pesquisa.enviado,
        opcoes: filtrarOpcoes(pesquisa.opcoes).sort((a, b) => (a.titulo < b.titulo) ? 1 : (b.titulo < a.titulo) ? -1 : 0).map((opcao, key) => {
          return {
            id: opcao.id,
            titulo: opcao.titulo,
            descricao: opcao.descricao,
            qtd_escolha: opcao.qtd_escolha,
            respostas: opcao.respostas,
            index: key
          } 
        }),
        unidades,
        envio: pesquisa.envio,
      }

      let totalEnvios = 0
      let totalRespostas = 0

      if(pesquisa.envio){
        setState(prevState => ({...prevState,
          dadosEnvio: {
            canal: pesquisa.envio.meio_envio,
          },
          flagsSelecionadas: pesquisa.envio.flags.split(",")
        }))

        totalEnvios = pesquisa.envio.qtd_pessoas
      }

      totalRespostas = somarValores(pesquisa.opcoes.map(value => { return value.qtd_escolha }))

      let data_chart = [["Opções", "Qtd. Votos"]]

      for (const opcao of pesquisa_aux.opcoes) {
        data_chart.push([opcao.titulo, opcao.qtd_escolha])
      }

      setState(prevState => ({...prevState,
        pesquisa:pesquisa_aux,
        totalEnvios,
        totalRespostas,
        data_chart,
        opcoesFiltrada: {
          list: pesquisa_aux.opcoes
        }
      }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Pesquisa encerrada'
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({...prevState,
        pesquisa: {
          id: "",
          titulo: "",
          mensagem: "",
          hora_inicio: "",
          hora_fim: "",
          data_inicio: "",
          data_fim: "",
          ativo: true,
          opcoes: []
        },
        unidadesSelecionadas: []
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'pesquisa')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (!permissoes.visualizar) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }))

        backPage()
      }

      try {
        const { data: pesquisa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pesquisa/${pesquisaId}`, getToken())

        setState(prevState => ({...prevState,
          filtro: {
            data_inicio: pesquisa.data_inicio || '',
            data_fim: pesquisa.data_fim || ''
          },
        }))

        let unidades = ''

        for (let i = 0; i < pesquisa.unidades.length; i++) {
          if((i + 1) === pesquisa.unidades.length){
            unidades += `${pesquisa.unidades[i].unidade.descricao}`
          }else{
            unidades += `${pesquisa.unidades[i].unidade.descricao}, `
          }
        }

        let pesquisa_aux = {
          id: pesquisa.id,
          titulo: pesquisa.titulo,
          mensagem: pesquisa.mensagem,
          hora_inicio: pesquisa.hora_inicio,
          hora_fim: pesquisa.hora_fim,
          data_inicio: pesquisa.data_inicio,
          data_fim: pesquisa.data_fim,
          ativo: pesquisa.ativo,
          enviado: pesquisa.enviado,
          opcoes: filtrarOpcoes(pesquisa.opcoes).sort((a, b) => (a.titulo < b.titulo) ? 1 : (b.titulo < a.titulo) ? -1 : 0).map((opcao, key) => {
            return {
              id: opcao.id,
              titulo: opcao.titulo,
              descricao: opcao.descricao,
              qtd_escolha: opcao.qtd_escolha,
              respostas: opcao.respostas,
              index: key
            } 
          }),
          unidades,
          envio: pesquisa.envio,
        }

        let totalEnvios = 0
        let totalRespostas = 0

        if(pesquisa.envio){
          setState(prevState => ({...prevState,
            dadosEnvio: {
              canal: pesquisa.envio.meio_envio,
            },
            flagsSelecionadas: pesquisa.envio.flags.split(",")
          }))

          totalEnvios = pesquisa.envio.qtd_pessoas
        }

        totalRespostas = somarValores(pesquisa.opcoes.map(value => { return value.qtd_escolha }))

        let data_chart = [["Opções", "Qtd. Votos"]]

        for (const opcao of pesquisa_aux.opcoes) {
          data_chart.push([opcao.titulo, opcao.qtd_escolha])
        }

        setState(prevState => ({...prevState,
          pesquisa:pesquisa_aux,
          totalEnvios,
          totalRespostas,
          data_chart,
          opcoesFiltrada: {
            list: pesquisa_aux.opcoes
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { tab, pesquisa, totalEnvios, totalRespostas, tipoGrafico, data_chart, filtro, opcoesFiltrada } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Tela...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom" >
              <Grid item md={12}>
                <h1 className="titulo">Resultado da Pesquisa</h1>
              </Grid>
            </Grid>

            <AppBar position="static" className="mg_top_10"> 
              <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Pesquisa" {...a11yProps(0)} />
                {pesquisa.enviado &&
                  <Tab label="Resultado" {...a11yProps(1)} />
                }
              </Tabs>
            </AppBar>

            <TabPanel value={tab} index={0} id='view'>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={5} xs={12} sm={6}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} xs={12} sm={12}>
                      <TextField 
                        className="input" 
                        label="Questão da Pesquisa" 
                        variant="outlined" 
                        size="small" 
                        name="titulo" 
                        value={pesquisa.titulo} 
                        disabled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <TextField 
                        className="input" 
                        label="Unidades" 
                        variant="outlined" 
                        size="small" 
                        name="titulo" 
                        value={pesquisa.unidades} 
                        disabled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={8} xs={12} sm={8}>
                      <TextField 
                        className="input" 
                        label="Data de Inicio" 
                        type="date"
                        variant="outlined" 
                        size="small" 
                        name="data_inicio" 
                        value={pesquisa.data_inicio} 
                        disbled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sm={4}>
                      <TextField 
                        className="input" 
                        label="Hora do Inicio" 
                        variant="outlined" 
                        type="time"
                        size="small" 
                        name="hora_inicio" 
                        value={pesquisa.hora_inicio} 
                        disbled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={8} xs={12} sm={8}>
                      <TextField 
                        className="input" 
                        label="Data de Fim" 
                        type="date"
                        variant="outlined" 
                        size="small" 
                        name="data_fim" 
                        value={pesquisa.data_fim} 
                        disbled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sm={4}>
                      <TextField 
                        className="input" 
                        label="Hora do Fim" 
                        variant="outlined" 
                        type="time"
                        size="small" 
                        name="hora_fim" 
                        value={pesquisa.hora_fim} 
                        disbled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={6} xs={12} sm={6}>
                      <TextField 
                        className="input" 
                        label="Ativo" 
                        variant="outlined" 
                        size="small" 
                        name="titulo" 
                        value={pesquisa.ativo ? 'Sim' : 'Não'} 
                        disabled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                      <TextField 
                        className="input" 
                        label="Enviado" 
                        variant="outlined" 
                        size="small" 
                        name="titulo" 
                        value={pesquisa.enviado ? 'Sim' : 'Não'} 
                        disabled
                        InputLabelProps={{ shrink: true}} 
                      />
                    </Grid>
                  </Grid>
                  {pesquisa.enviada &&
                    <React.Fragment>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={6} xs={12} sm={6}>
                          <TextField 
                            className="input" 
                            label="Data do Envio" 
                            variant="outlined" 
                            size="small" 
                            name="data_envio" 
                            value={moment(pesquisa.envio.data_envio).format('DD/MM/YYYY HH:mm')} 
                            disabled
                            InputLabelProps={{ shrink: true}} 
                          />
                        </Grid>
                        <Grid item md={6} xs={12} sm={6}>
                          <TextField 
                            className="input" 
                            label="Meio de Comunicação" 
                            variant="outlined" 
                            size="small" 
                            name="meio_envio" 
                            value={pesquisa.envio.meio_envio} 
                            disabled
                            InputLabelProps={{ shrink: true}} 
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <TextField 
                            className="input" 
                            label="Flags Selecionadas" 
                            variant="outlined" 
                            size="small" 
                            name="flags" 
                            value={pesquisa.envio.flags} 
                            disabled
                            InputLabelProps={{ shrink: true}} 
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                </Grid>
                <Grid item md={7} xs={12} sm={6}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Mensagem"
                    className="input"
                    multiline
                    rows={10}
                    size="small"
                    variant="outlined"
                    name="mensagem"
                    value={pesquisa.mensagem}
                    disabled
                    InputLabelProps={{ shrink: true}} 
                  />
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className='titulo'>Opções:</h4>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Table 
                  headCell={state.cabecalhoTabela} 
                  rows={pesquisa.opcoes} 
                  acoes={state.acoesTabela}
                  qtdLinhas={pesquisa.opcoes.length}
                  desabilitaRodape={true}
                />
              </Grid>
              <br />
              <hr />
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <Button size='small' fullWidth variant="contained" color="secondary" onClick={() => voltar()}>
                    Voltar
                  </Button>
                </Grid>
                {(pesquisa.enviado && pesquisa.ativo) &&
                  <Grid item md={2} xs={12} sm={4}>
                    <Button size='small' fullWidth variant="contained" color="primary" onClick={() => encerrarPesquisa()}>
                      Encerrar Pesquisa
                    </Button>
                  </Grid>
                }
                {!pesquisa.enviado &&
                  <React.Fragment>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button size='small' fullWidth variant="contained" color="primary" onClick={() => editarPesquisa()}>
                        Editar Pesquisa
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button color='success' size='small' fullWidth variant="contained" onClick={() => enviarPesquisa()}>
                        Enviar Pesquisa
                      </Button>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={1} id='view'>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={3}>
                  <h4 className='titulo'>Filtro:</h4>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    type="date" 
                    label="Data Inicial*" 
                    variant="outlined" 
                    size="small" 
                    name="data_inicio" 
                    value={filtro.data_inicio} 
                    onChange={(e) => updateFiltro(e)} 
                    InputLabelProps={{ shrink: true }} 
                    InputProps={{ 
                      inputProps: { 
                        min: pesquisa.data_inicio || '',
                        max: pesquisa.data_fim || '' 
                      } 
                    }}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    type="date" 
                    label="Data Final" 
                    variant="outlined" 
                    size="small" 
                    name="data_fim" 
                    value={filtro.data_fim} 
                    onChange={(e) => updateFiltro(e)} 
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ 
                      inputProps: { 
                        min: pesquisa.data_inicio || '',
                        max: pesquisa.data_fim || '' 
                      } 
                    }}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button color='primary' size='small' variant="contained" onClick={() => filtrar()} disabled={state.loadingImprimir}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              <hr className="mg_top_10"/>

              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className='titulo'>Alternar Gráficos:</h4>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <Tooltip title='Gráfico em Barras' aria-label="add">
                    <IconButton color="primary" aria-label="view" onClick={() => alterarTipoGrafico('barra')}>
                      <EqualizerIcon style={{fontSize: '40px'}}/>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Gráfico em Pizza' aria-label="add">
                    <IconButton color="error" aria-label="view" onClick={() => alterarTipoGrafico('pizza')}>
                      <PieChartIcon style={{fontSize: '40px'}}/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={6} xs={12} sm={6}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className='titulo'>Adesão da Pesquisa:</h4>
                    </Grid>
                  </Grid>
                  {tipoGrafico === 'barra' &&
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                      <div 
                        style={{
                          width: '80%',
                        }}
                      >
                        <Tooltip title={`Total de Respostas: ${totalRespostas}`} aria-label="add">
                          <div
                            className='bar_totais'
                            style={{
                              width: `${((totalRespostas/totalEnvios)*100)}%`,
                              backgroundColor: '#7cb453',
                              marginTop: '20px'
                            }}
                          >
                            {((totalRespostas/totalEnvios)*100 || 0).toFixed(0)}%
                          </div>
                        </Tooltip>
                        <Tooltip title={`Total Não Respondido: ${(totalEnvios-totalRespostas)}`} aria-label="add">
                          <div
                            className='bar_totais'
                            style={{
                              width: `${(((totalEnvios-totalRespostas)/totalEnvios)*100)}%`,
                              backgroundColor: '#FF6347',
                              color: '#000',
                              marginTop: '20px'
                            }}
                          >
                            {(((totalEnvios-totalRespostas)/totalEnvios)*100 || 0).toFixed(0)}%
                          </div>
                        </Tooltip>
                      </div>
                      </Grid>
                    </Grid>
                  }
                  {tipoGrafico === 'pizza' && 
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Chart
                          chartType="PieChart"
                          data={[
                            ["Total", "Qtd. Votos"],
                            ["Respondido", totalRespostas],
                            ["Não Respondido", (totalEnvios-totalRespostas)],
                          ]}
                          options={state.options}
                          width={"100%"}
                          height={"400px"}
                        />
                      </Grid>
                    </Grid>
                  }
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className='titulo'>Resultado:</h4>
                    </Grid>
                  </Grid>
                  {tipoGrafico === 'barra' &&
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <div className='chart' style={{clear:'both'}}>
                          {opcoesFiltrada.list.sort((a, b) => (a.respostas.length < b.respostas.length) ? 1 : (b.respostas.length < a.respostas.length) ? -1 : 0).map((value, index) => {
                            return (
                              <div className='row_chart'>
                                <Grid container spacing={2} direction="row">
                                  <Grid item md={3} className='descricao'>
                                    {value.titulo}
                                  </Grid>
                                  <Grid item md={9} className='bar'>
                                    <Tooltip title={`Total de Votos: ${value.respostas.length}`} aria-label="add">
                                      <div 
                                        style={{
                                          width: `${(value.respostas.length / totalRespostas) * 100}%`,
                                          backgroundColor: '#7cb453',
                                          color: '#000',
                                          height: '25px',
                                          paddingTop: '4px',
                                          paddingLeft: '10px'
                                        }}
                                      >
                                        {((value.respostas.length/totalRespostas)*100 || 0).toFixed(0)}%
                                      </div>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </div>
                            )
                          })}
                        </div>
                      </Grid>
                    </Grid>
                  }
                  {tipoGrafico === 'pizza' && 
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Chart
                          chartType="PieChart"
                          data={data_chart}
                          options={state.options}
                          width={"100%"}
                          height={"400px"}
                        />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={4} xs={12} sm={6}>
                  <h5 className='titulo'>Total de Envios: {totalEnvios}</h5>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <h5 className='titulo'>Total de Respostas: {totalRespostas}</h5>
                </Grid>
              </Grid>
            </TabPanel>
            
          </div>
        }
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalEnviarPesquisa 
        open={state.openModalEnviarPesquisa}
        handleClose={e => handleCloseModalEnviarPesquisa()}
        dados={state.dadosEnvio} 
        disabledButtons={state.disabledButtons}
        updateField={(e) => updateFieldEnviaPesquisa(e)}
        confirmar={(e) => confirmarEnvioPesquisa(e)}
        flagsSelecionadas={state.flagsSelecionadas}
        updateFieldFlags={(event, value) => updateFieldFlags(event, value)}
      />
    </div>
  )

}

export default ViewPesquisa