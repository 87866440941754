import React, { Component } from 'react'
import { Grid, TextField } from '@mui/material'
import axios from 'axios'

const initialState = {
  franqueados: {
    list: []
  },
  unidades: {
    list: []
  },
  dadosUsuario: {}
}

export default class FormQuemTrabalha extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    this.setState({
      dadosUsuario: pessoa
    })

    axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())
      .then(resp => {

        this.setState({
          unidades: {
            list: resp.data
          }
        })

      }).catch(error => {
        console.log(error)
      })

    axios.get(`${window._env_.REACT_APP_API_URL}/franqueados`, this.getToken())
      .then(resp => {

        this.setState({
          franqueados: {
            list: resp.data
          }
        })

      }).catch(error => {
        console.log(error)
      })
  }

  render() {
    const nivelAcesso = this.state.dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.tipo
    const dados = this.props.dados

    return (
      <React.Fragment>
        <Grid container direction="row" spacing={1} alignItems="flex-end">
          <Grid item md={12}> <h4 className="titulo">Para quem irá trabalhar</h4> </Grid>
          <Grid item md={4}>
            <TextField
              id="standard-select-currency"
              select
              label="Funcionario para"
              variant="outlined"
              className="input"
              value={dados.paraquem.toLowerCase()}
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paraquem"
              onChangeCapture={(e) => this.props.updateParaquem(e)}
              InputLabelProps={{ shrink: true }}
              disabled={this.props.enableUpdate}
            >
              {nivelAcesso === "MANTENEDOR" &&
                <React.Fragment>
                  <option value="mantenedor"> Minha Mantenedora </option>
                  <option value="representante"> Representante </option>
                  <option value="grupoeconomico"> Grupo Econômico </option>
                  <option value="unidade"> Unidade </option>
                </React.Fragment>
              }
              {nivelAcesso === "REPRESENTANTE" &&
                <React.Fragment>
                  <option value="representante"> Representante </option>
                  <option value="grupoeconomico"> Grupo Econômico </option>
                  <option value="unidade"> Unidade </option>
                </React.Fragment>
              }
              {nivelAcesso === "GRUPOECONOMICO" &&
                <React.Fragment>
                  <option value="grupoeconomico"> Grupo Econômico </option>
                  <option value="unidade"> Unidade </option>
                </React.Fragment>
              }
              {nivelAcesso === "UNIDADE" &&
                <React.Fragment>
                  <option key="unidade" value="unidade"> Minha Unidade </option>
                </React.Fragment>
              }
            </TextField>
          </Grid>
          <Grid item md={4}>
            {nivelAcesso === 'MANTENEDOR' && this.props.selects_unidadetrabalho.franqueado &&
              <TextField
                id="standard-select-currency"
                select
                label="Para qual Franquia"
                variant="outlined"
                className="input"
                value={this.props.unidadetrabalho_id}
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="paraquem"
                onChangeCapture={(e) => this.props.updateContrato(e)}
                InputLabelProps={{ shrink: true }}
                disabled={this.props.enableUpdate}
              >
                <option value=""> Selecionar Franqueado </option>
                {this.state.franqueados.list.map(franqueado => {
                  return (
                    <option key={franqueado.id} value={franqueado.id}>{franqueado.pessoa.tipo === 'FISICA' ? franqueado.pessoa.fisica.nome : franqueado.pessoa.juridica.razaosocial} </option>
                  )
                })}
              </TextField>
            }
            {(['MANTENEDOR', 'REPRESENTANTE', 'GRUPOECONOMICO'].includes(nivelAcesso)) && this.props.selects_unidadetrabalho.unidade &&
              <TextField
                id="standard-select-currency"
                select
                label="Para qual Unidade"
                variant="outlined"
                className="input"
                value={this.props.unidadetrabalho_id}
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="paraquem"
                onChangeCapture={(e) => this.props.updateContrato(e)}
                InputLabelProps={{ shrink: true }}
                disabled={this.props.enableUpdate}
              >
                <option value=""> Selecionar Unidade </option>
                {this.state.unidades.list.map(unidade => {
                  return (
                    <option key={unidade.id} value={unidade.id}>{unidade.numero} - {unidade.descricao} </option>
                  )
                })}
              </TextField>
            }
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}