import React, { useState, useEffect } from 'react';
import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import './Franqueado.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import Loading from './../../components/templates/Loading'
import FormPessoa from '../../components/forms/FormPessoa'
import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import { useParams, useNavigate } from 'react-router-dom';
import Alert from './../../components/templates/Alert'
import moment from 'moment';
import { format } from 'cnpj'
import axios from 'axios'

const cpf = require('node-cpf-cnpj')

const initialState = {
  responsavel: {
    id: "",
    pessoa_id: "",
    descricao: "",
    tipoPessoa: "FISICA",
    unin_pessoa_id: "",
    pessoaFisica: {
      id: "",
      pessoa_id: "",
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    enderecos: [{
      id: "",
      cep_id: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: "",
      tipoEndereco: "RESIDENCIAL",
      index: "1"
    }],
    emails: [{
      id: "",
      email: "",
      principal: "",
      index: "1"
    }],
    contatos: [{
      id: "",
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: "",
      index: "1"
    }]
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  mensagemLoading: 'Carregando Responsável...',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes: {
    list: []
  },
  informarEndereco: false,
  informarContato: false,
  informarEmail: false,
  endereco: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contato: {
    numero: "",
    tipoContato: "COMERCIAL",
    principal: true,
    index: ""
  },
  email: {
    email: "",
    principal: true,
    index: ""
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: ['update', 'remover'],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

const CadastroRepresentante = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { franqueadoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/franqueado");      
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldPessoaFisica = (event) => {
    const responsavel = { ...state.responsavel }
    responsavel.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      responsavel
    }));
  }

  const updateFieldPessoaJuridica = (event) => {
    const responsavel = { ...state.responsavel }
    responsavel.pessoaJuridica[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      responsavel
    }));
  }

  const validarCpfCnpj = async (event) => {
    let cpf_cnpj = event.target.value
    const responsavel = state.responsavel

    if (responsavel.tipoPessoa === "FISICA") {
      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {

        responsavel.pessoaFisica.cpf = ""

        setState(prevState => ({
          ...prevState,
          responsavel,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CPF Invalido! Por favor corrija o campo CPF'
          }
        }));
      } else {
        responsavel.pessoaFisica.cpf = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          responsavel
        }));
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        responsavel.pessoaJuridica.cnpj = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          responsavel
        }));

      } else {
        responsavel.pessoaJuridica.cnpj = ""
        setState(prevState => ({
          ...prevState,
          responsavel,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CNPJ Invalido! Por favor corrija o campo CNPJ'
          }
        }));
      }
    }
  }

  const informarEndereco = () => {
    const { responsavel } = state;
    let principal = !responsavel.enderecos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEndereco: true,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }));
  }

  const pegaEndereco = async (event, index) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { endereco } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: endereco.numero,
        complemento: endereco.complemento,
        cep: formatarCEP(endereco.cep),
        principal: endereco.principal,
        tipoEndereco: endereco.tipoEndereco,
        index: endereco.index
      };
  
      setState(prevState => ({
        ...prevState,
        endereco: novoEndereco
      }));
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: endereco.complemento,
          rua: resp.logradouro,
          numero: endereco.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };
  
        setState(prevState => ({
          ...prevState,
          endereco: enderecoEncontrado,
          disableEndereco: true,
          modalErro: false
        }));
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: endereco.numero,
          cep: "",
          complemento: endereco.complemento,
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };

        setState(prevState => ({
          ...prevState,
          endereco: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }));
      }
    }
  }

  const updateFieldEndereco = (event, index) => {
    let { endereco } = state
    endereco[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const updateFieldEnderecoPrincipal = (event, index) => {
    let { endereco, responsavel } = state
    const indexAux = responsavel.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== endereco.index)) {
      endereco.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse representante já possui endereço principal'
        }
      }));
    }else{
      endereco.principal = !endereco.principal
    }

    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const addNovoEndereco = () => {
    const { endereco, responsavel } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        endereco.cep !== "" &&
        endereco.rua !== "" &&
        endereco.bairro !== "" &&
        endereco.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }));
      return;
    }
  
    // Crie uma cópia dos endereços da responsavel
    const novosEnderecos = [...responsavel.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (endereco.index === "") {
      endereco.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...endereco });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[endereco.index - 1] = { ...endereco };
    }
  
    // Atualize os endereços da responsavel
    responsavel.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({
      ...prevState,
      responsavel,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEndereco: false,
      alerta: { open: false } // Feche o alerta após adicionar o novo endereço
    }));
  }

  const editarEndereco = (row) => {
    let {responsavel} = state

    setState(prevState => ({
      ...prevState,
      endereco: {...responsavel.enderecos[row.index - 1]},
      informarEndereco: true
    }));
  }

  const removeEnderecoRepresentante = (index) => {
    const unidade = { ...state.unidade }
    const enderecos = unidade.enderecos
    let aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.enderecos = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const informarContato = () => {
    const { responsavel } = state;
    let principal = !responsavel.contatos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarContato: true,
      contato: {
        numero: "",
        tipoContato: "COMERCIAL",
        principal,
        index: ""
      },
    }));
  }

  const updateFieldContato = (event, index) => {
    let { contato } = state
    contato[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const updateFieldContatoPrincipal = (event, index) => {
    let { contato, responsavel } = state
    const indexAux = responsavel.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contato.index)) {
      contato.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse representante já possui contato principal'
        }
      }));
    }else{
      contato.principal = !contato.principal
    }

    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const addNovoContato = () => {
    let {contato, responsavel} = state

    let valido = true

    if (contato.numero === "") valido = false
    if (contato.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }));
      return
    }

    const contatos = responsavel.contatos

    if(contato.index === ""){
      contato.index = contatos.length + 1

      contatos.push({
        ...contato
      })
    }else{
      contatos[contato.index - 1] = {
        ...contato
      }
    }

    responsavel.contatos = contatos

    setState(prevState => ({
      ...prevState,
      responsavel,
      contato: {
        numero: "",
        tipoContato: "COMERCIAL",
        principal: false,
        index: ""
      },
      informarContato: false
    }));
  }

  const editarContato = (row) =>{
    let {responsavel} = state

    setState(prevState => ({
      ...prevState,
      contato: {...responsavel.contatos[row.index - 1]},
      informarContato: true
    }));
  }

  const removeContatoUnidade = (index) => {
    const responsavel = { ...state.responsavel }
    const contatos = responsavel.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.contatos = aux
    setState(prevState => ({
      ...prevState,
      responsavel
    }))
  }

  const informarEmail = () => {
    const { responsavel } = state;
    let principal = !responsavel.emails.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEmail: true,
      email: {
        email: "",
        principal,
        index: ""
      },
    }))
  }

  const updateFieldEmail = (event, index) => {
    let { email } = state
    email[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      email
    }));
  }

  const updateFieldEmailPrincipal = (event, index) => {
    let { email, responsavel } = state
    const indexAux = responsavel.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== email.index)) {
      email.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse responsavel já possui email principal'
        }
      }))
    }else{
      email.principal = !email.principal
    }

    setState(prevState => ({
      ...prevState,
      email
    }))
  }

  const addNovoEmail = () => {
    let {email, responsavel} = state

    let valido = true

    if (email.email === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(email.email)) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do representante é inválido!'
        }
      }))
      return
    }

    const emails = responsavel.emails

    if(email.index === ""){
      email.index = emails.length + 1

      emails.push({
        ...email
      })
    }else{
      emails[email.index - 1] = {
        ...email
      }
    }

    responsavel.emails = emails

    setState(prevState => ({
      ...prevState,
      responsavel,
      email: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmail: false
    }))
  }

  const editarEmail = (row) => {
    let {responsavel} = state

    setState(prevState => ({
      ...prevState,
      email: {...responsavel.emails[row.index - 1]},
      informarEmail: true
    }))
  }

  const removeEmailUnidade = (index) => {
    const {responsavel} = state
    const emails = responsavel.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.emails = aux
    setState(prevState => ({
      ...prevState,
      responsavel
    }))
  }

  const validarDadosPessoa = async() =>{
    const {responsavel} = state
    
    if (responsavel.tipoPessoa === "FISICA") {
      if (
        responsavel.pessoaFisica.nome === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o nome do(a) representante.'
        }
      }

      if (
        responsavel.pessoaFisica.dataNascimento === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar a data de nascimento do(a) representante.'
        }
      }

      if (
        responsavel.pessoaFisica.cpf === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o CPF do(a) representante.'
        }
      }

      let cpf_cnpj = responsavel.pessoaFisica.cpf

      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {
        if(cpf_cnpj !== '000.000.000-00'){
          return {
            valido: false,
            mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
          }
        }
      }

      setState(prevState => ({
        ...prevState,
        responsavel
      }))
    }else{
      if (responsavel.pessoaJuridica.razaoSocial === "") {
        return {
          valido: false,
          mensagem: 'Por favor informar a razão social do representante.'
        }
      }

      if (responsavel.pessoaJuridica.nomeFantasia === "") {
        return {
          valido: false,
          mensagem: `Por favor informar o nome fantasia do representante.`
        }
      }
    }

    if(responsavel.enderecos.length > 0){
      let valido = true
      responsavel.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })
  
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }
    
    if(responsavel.contatos.length > 0){
      responsavel.contatos.forEach(contato => {
        if (contato.numero === "") {
          return {
            valido: false,
            mensagem: `Por favor informar o contato da responsavel.`
          }
        }
      })
    }

    if(responsavel.emails.length > 0){
      let valido = true
      responsavel.emails.forEach(email => {
        if (email.email === "") valido = false
      })
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    setState(prevState => ({
      ...prevState,
      responsavel
    }))

    return {
      valido: true,
      mensagem: ''
    }
  }

  const salvar = async () => {
    let validacao = await validarDadosPessoa()

    if(!validacao.valido){
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: validacao.mensagem
        }
      }))
      return
    }

    const { responsavel } = state

    setState(prevState => ({
      ...prevState,
      loading: true,
      mensagemLoading: 'Alterando Responsável'
    }))

    let emails = []
    let telefones = []
    let enderecos = []

    responsavel.emails.forEach(email => {
      if (email.id) {
        emails.push({
          id: email.id,
          email: email.email,
          principal: email.principal
        })
      } else {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      }
    })

    responsavel.contatos.forEach(telefone => {
      if (telefone.id) {
        telefones.push({
          id: telefone.id,
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      } else {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      }
    })

    responsavel.enderecos.forEach(endereco => {
      if (endereco.id) {
        enderecos.push({
          id: endereco.id,
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      } else {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      }
    })

    const dados = {
      responsavel: {
        unin_id: franqueadoId,
        pessoa_id: responsavel.pessoa_id,
        id: responsavel.pessoaFisica.id,
        nome: responsavel.pessoaFisica.nome,
        cpf: cpf.strip(responsavel.pessoaFisica.cpf),
        rg: responsavel.pessoaFisica.rg,
        rg_orgao_emissor: responsavel.pessoaFisica.rg_orgao_emissor,
        rg_uf: responsavel.pessoaFisica.rg_uf,
        rg_data_emissao: responsavel.pessoaFisica.rg_data_emissao,
        datanascimento: responsavel.pessoaFisica.dataNascimento,
        class_tipo: "",
        tipo: responsavel.tipoPessoa,
        emails,
        telefones,
        enderecos,
      }
    }

    try {

      await axios.put(`${window._env_.REACT_APP_API_URL}/franqueados/responsavel/${franqueadoId}`, dados, getToken())

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {

      try {
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/franqueados/${franqueadoId}`, getToken())

        let enderecos = []
        let emails = []
        let contatos = []

        for (let i = 0; i < data.responsavel.pessoa.enderecos.length; i++) {
          enderecos.push({
            id: data.responsavel.pessoa.enderecos[i].id,
            cep_id: data.responsavel.pessoa.enderecos[i].cep_id,
            rua: data.responsavel.pessoa.enderecos[i].logradouro,
            numero: data.responsavel.pessoa.enderecos[i].numero,
            bairro: data.responsavel.pessoa.enderecos[i].bairro,
            cidade: data.responsavel.pessoa.enderecos[i].cidade,
            estado: data.responsavel.pessoa.enderecos[i].estado,
            cep: formatarCEP(data.responsavel.pessoa.enderecos[i].num_cep),
            principal: data.responsavel.pessoa.enderecos[i].principal,
            tipoEndereco: data.responsavel.pessoa.enderecos[i].tipo,
            index: i + 1
          })
        }

        for (let i = 0; i < data.responsavel.pessoa.telefones.length; i++) {
          contatos.push({
            id: data.responsavel.pessoa.telefones[i].id,
            numero: data.responsavel.pessoa.telefones[i].numero,
            tipoContato: data.responsavel.pessoa.telefones[i].tipo,
            principal: data.responsavel.pessoa.telefones[i].principal,
            index: i + 1
          })
        }

        for (let i = 0; i < data.responsavel.pessoa.emails.length; i++) {
          emails.push({
            id: data.responsavel.pessoa.emails[i].id,
            email: data.responsavel.pessoa.emails[i].email,
            principal: data.responsavel.pessoa.emails[i].principal,
            index: i + 1
          })
        }

        setState(prevState => ({
          ...prevState,
          responsavel: {
            id: data.frao_id,
            pessoa_id: data.frao_responsavel_id,
            tipoPessoa: data.responsavel.pessoa.tipo,
            unin_pessoa_id: data.unin_id,
            pessoaFisica: {
              id: data.responsavel.id,
              pessoa_id: data.responsavel.pessoa.id,
              nome: data.responsavel.nome,
              cpf: cpf.format(data.responsavel.cpf),
              rg: data.responsavel.rg,
              rg_orgao_emissor: data.responsavel.rg_orgao_emissor,
              rg_uf: data.responsavel.rg_uf,
              rg_data_emissao:data.responsavel.rg_data_emissao ? moment(data.responsavel.rg_data_emissao).format('YYYY-MM-DD') : '',
              dataNascimento: data.responsavel.datanascimento ? moment(data.responsavel.datanascimento).format('YYYY-MM-DD') : ''
            },
            enderecos,
            emails,
            contatos
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      setState(prevState => ({
        ...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { responsavel, loading } = state

  return (
    <React.Fragment>
      {!loading &&
        <div className="app-menu-closed" id="app">
          <Main>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={12} xs={12} sm={12}>
                <h1 className="titulo">Atualização de Responsável Grupo</h1>
              </Grid>
            </Grid>
            <FormPessoa
                pessoa={responsavel}
                informarEndereco={state.informarEndereco}
                endereco={state.endereco}
                informarContato={state.informarContato}
                contato={state.contato}
                informarEmail={state.informarEmail}
                email={state.email}
                updateFieldPessoaFisica={(e) => updateFieldPessoaFisica(e)}
                updateFieldPessoaJuridica={(e) => updateFieldPessoaJuridica(e)}
                validarCpfCnpj={(e) => validarCpfCnpj(e)}
                dadosComplementaresPF={state.dadosComplementaresPF}
                metodos_divugacoes={state.metodos_divugacoes.list}
                informarNovoEndereco={() => informarEndereco()}
                pegaEndereco={e => pegaEndereco(e)}
                updateFieldEndereco={e => updateFieldEndereco(e)} 
                updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipal(e)} 
                addNovoEndereco={(e) => addNovoEndereco(e)}
                cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                editarEndereco={(e) => editarEndereco(e)}
                removeEndereco={(e) => removeEnderecoRepresentante(e)}
                informarNovoContato={() => informarContato()}
                updateFieldContato={e => updateFieldContato(e)} 
                updateFieldContatoPrincipal={e => updateFieldContatoPrincipal(e)} 
                addNovoContato={() => addNovoContato()}
                editarContato={(e) => editarContato(e)}
                removeContato={(e) => removeContatoUnidade(e)}
                informarNovoEmail={() => informarEmail()}
                updateFieldEmail={e => updateFieldEmail(e)} 
                updateFieldEmailPrincipal={e => updateFieldEmailPrincipal(e)}
                addNovoEmail={() => addNovoEmail()}
                editarEmail={(e) => editarEmail(e)}
                removeEmail={(e) => removeEmailUnidade(e)}
                acoesTabelaEndereco={state.acoesTabelaEndereco}
                cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
              />
              <hr className="mg_top_10"/>
              <Grid container direction="row" alignItems="flex-end" spacing={1} className="mg_top_10">
                <Grid item md={10} xs={1} sm={9}></Grid>
                <Grid item md={2} xs={6} sm={3}>
                  <Button disabled={state.loading} fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default CadastroRepresentante