import React from 'react'

import { Grid, TextField } from '@mui/material'

const FormEstoqueProduto = ({ dados, updateField }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Estoque Mínimo" variant="outlined" size="small" name="estoqueMinimo" value={dados.estoqueMinimo} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Estoque Máximo" variant="outlined" size="small" name="estoqueMaximo" value={dados.estoqueMaximo} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Estoque Atual" variant="outlined" size="small" name="estoqueAtual" value={dados.estoqueAtual} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Custo Estoque" variant="outlined" size="small" name="valorVenda" value={dados.valorVenda} onChange={(e) => updateField(e)} />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormEstoqueProduto