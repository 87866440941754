import React, { useState, useEffect } from 'react';
import './Produto.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import TabViews from './TabViews'
import Alert from './../../components/templates/Alert'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  produto: {},
  locaisEstoques: [],
  estoqueProdutos: [],
  loading: true,
  mensagemLoading: 'Carregando Produto...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesEstoque: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  }
}

const ViewProduto = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { produtoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      navigate("/produtos");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'produto')[0]
      let permissoesEstoque = perfil.permissoes.filter(param => param.tela.modulo.slug === 'estoque-produtos')[0]

      if(permissoes){
        setState(prevState => ({...prevState,
          permissoes
        }))
      }

      if(permissoesEstoque){
        setState(prevState => ({...prevState,
          permissoesEstoque
        }))
      }

      if (!permissoes.visualizar) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }))
        backPage()
      }

      if (produtoId) {
        try {
          const { data: produto } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/${produtoId}`, getToken())

          const itensCombo = []

          if (produto.itemCombo) {
            for (let i = 0; i < produto.itemCombo.length; i++) {
              itensCombo.push({
                _id: produto.itemCombo[i].id,
                produtoId: produto.itemCombo[i].idprodutoitem,
                produtoItem: produto.itemCombo[i].produtoitem,
                quantidade: produto.itemCombo[i].quantidade,
                duracao: produto.itemCombo[i].produtoitem.servico.duracao || 0,
                ativo: produto.itemCombo[i].ativo,
                index: i + 1
              })
            }
          }

          let centro_custos = []

          if(produto.centro_custo_produto.length > 0){
            centro_custos = produto.centro_custo_produto.map((value, key) => {
              return {
                id: value.id,
                centrocusto_id: value.centro_custo_plano.id,
                planocontas: value.centro_custo_plano.plano_contas.codigo + " - " + value.centro_custo_plano.plano_contas.descricao,
                centrocusto: value.centro_custo_plano.centro_custo.descricao,
                valor_percentual: value.valor_percentual,
                ativo: true,
                index: key
              }
            })
          }

          let centro_lucros = []

          if(produto.centro_lucro_produto.length > 0){
            centro_lucros = produto.centro_lucro_produto.map((value, key) => {
              return {
                id: value.id,
                centrocusto_id: value.centro_custo_plano.id,
                planocontas: value.centro_custo_plano.plano_contas.codigo + " - " + value.centro_custo_plano.plano_contas.descricao,
                centrocusto: value.centro_custo_plano.centro_custo.descricao,
                valor_percentual: value.valor_percentual,
                ativo: true,
                index: key
              }
            })
          }

          let ambientes = ''

          if(produto.servico){
            for (let j = 0; j < produto.servico.ambientes_servicos.length; j++) {
              let ambiente = produto.servico.ambientes_servicos[j].ambiente

              // if((i + 1) === produto.servico.ambientes_servicos.length){
              //   ambientes = ambientes + `e ${ambiente.nome}.`
              // }else{  
              // }
              ambientes = ambientes + `${ambiente.nome}, `
            }
          }

          setState(prevState => ({...prevState,
            produto: {
              _id: produto.id,
              descricao: produto.descricao,
              codigo: produto.codigo,
              ativo: produto.ativo,
              grupoProduto: produto.subgrupoproduto.grupoproduto.descricao,
              idGrupoProduto: produto.subgrupoproduto.grupoproduto.id,
              subGrupoProduto: produto.subgrupoproduto.descricao,
              idSubGrupoProduto: produto.subgrupoproduto.id,
              unidadeMedida: produto.unidademedida,
              visibilidade: produto.visibilidade,
              idMidia: produto.idmidia,
              destaque: produto.destaque,
              tipo: produto.tipo,
              duracao: produto.servico ? produto.servico.duracao: 0, 
              centro_custos,
              centro_lucros,
              ambientes,
              itensCombo: itensCombo.sort((a, b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))
            },
            uploadedFiles: [{
              id: produto.midia ? produto.midia.id : '',
              name: produto.midia ? produto.midia.nome : '',
              preview: produto.midia ? `${window._env_.REACT_APP_API_URL}/static/${produto.midia.key}` : '',
              uploaded: produto.midia ? true : false,
              url: produto.midia ? `${window._env_.REACT_APP_API_URL}/static/${produto.midia.key}` : ''
            }]
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
          backPage()
          return false
        }
      }

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      axios.post(`${window._env_.REACT_APP_API_URL}/estoqueProduto/byProduto/${produtoId}`, dados, getToken())
        .then(resp => {

          setState(prevState => ({...prevState,
            estoqueProdutos: resp.data.map(estoque => {
              return {
                unidade: estoque.localestoque.unidade.descricao ? estoque.localestoque.unidade.descricao : (estoque.localestoque.unidade.pessoa.tipo === 'FISICA' ? estoque.localestoque.unidade.pessoa.fisica.nome : estoque.localestoque.unidade.pessoa.juridica.razaosocial),
                localEstoque: estoque.localestoque.descricao,
                estoqueMinimo: estoque.estoqueminimo,
                estoqueMaximo: estoque.estoquemaximo,
                estoqueAtual: estoque.estoqueatual
              }
            })
          }))


        }).catch(error => {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        })

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { produto, uploadedFiles, estoqueProdutos, permissoes, permissoesEstoque } = state
  
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
            <div>
              <TabViews 
                produto={produto} 
                uploadedFiles={uploadedFiles} 
                estoqueProdutos={estoqueProdutos} 
                permissoes={permissoes}
                permissoesEstoque={permissoesEstoque}
              />
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )

}

export default ViewProduto

//   render() {

//   }
// }