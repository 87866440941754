import React, { useState, useEffect } from 'react';
import './Funcao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormFuncao from '../../components/forms/FormFuncao'
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
  funcao: {
    id: "",
    descricao: "",
    nivel: "",
    car_descricao: "",
    cargo_id: "",
    dep_descricao: "",
    departamento_id: "",
    unidade_id: '',
    ativo: true
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Função...',
  openModalAjuda: false,
  markdown: ''
}

const CadastroFuncao = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { funcaoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    const self = this
    setTimeout(() => {
      navigate("/funcao");
    }, timeout)
  }

  const updateField = async (event) => {
    const funcao = { ...state.funcao }

    let name = event.target.name
    let value = event.target.value

    if(name === "unidade_id"){
      try {
        const { data: departamentos } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/listByUnidade/${parseInt(value)}`, {}, getToken())
  
        const { data: cargos } = await axios.post(`${window._env_.REACT_APP_API_URL}/cargos/listByUnidade/${parseInt(value)}`, {}, getToken())
  
        setState(prevState => ({...prevState,
          departamentos: {
            list: departamentos.filter(u => u.ativo === true)
          },
          cargos: {
            list: cargos.filter(u => u.ativo === true)
          },
          loading: false
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }
    }

    if (name === "departamento_id") {

      const departamento = state.departamentos.list.filter(u => u.id === parseInt(value))
      const cargos = state.cargos.list.filter(u => u.departamento_id === parseInt(value))

      setState(prevState => ({...prevState,
        cargosFiltrado: {
          list: cargos
        }
      }))

      funcao.dep_descricao = departamento[0].descricao
      funcao.car_descricao = ""
      funcao.cargo_id = ""
    }

    if (name === "cargo_id") {
      const cargo = state.cargosFiltrado.list.filter(u => u.id === parseInt(value))

      funcao.car_descricao = cargo[0].descricao
    }

    funcao[name] = value
    setState(prevState => ({...prevState, funcao }))

  }

  const updateFieldAtivo = (event) => {
    const funcao = { ...state.funcao }
    funcao.ativo = !funcao.ativo
    setState(prevState => ({...prevState, funcao }))
  }

  const validateForm = () => {
    const { funcao } = state

    if (!funcao.cargo_id) return false
    if (!funcao.descricao) return false
    if (!funcao.unidade_id) return false
    if (!funcao.nivel) return false

    return true
  }

  const salvar = async () => {

    setState(prevState => ({...prevState, loadingSalvar: true }))

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    setState(prevState => ({...prevState,
      loading: true,
      mensagemLoading: 'Cadastrando/Alterando Função'
    }))

    const funcao = state.funcao

    const dados = {
      descricao: funcao.descricao,
      nivel: funcao.nivel,
      cargo_id: funcao.cargo_id,
      unidade_id: funcao.unidade_id,
      ativo: funcao.ativo,
    }

    try {

      const method = funcao.id !== "" ? 'put' : 'post'
      const url = funcao.id !== "" ? `/funcoes/${funcao.id}` : `/funcoes`

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/funcao/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'funcao')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (funcaoId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))
        
        if(unidades.length === 1){
          const {funcao} = state
          funcao.unidade_id = unidades[0].id

          try {
            const { data: departamentos } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/listByUnidade/${unidades[0].id}`, {}, getToken())
      
            const { data: cargos } = await axios.post(`${window._env_.REACT_APP_API_URL}/cargos/listByUnidade/${unidades[0].id}`, {}, getToken())
      
            setState(prevState => ({...prevState,
              departamentos: {
                list: departamentos.filter(u => u.ativo === true)
              },
              cargos: {
                list: cargos.filter(u => u.ativo === true)
              }
            }))
      
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? error.response.data.message : 'Erro Interno'
              }
            }))
      
            backPage()
          }

          setState(prevState => ({...prevState,funcao}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (funcaoId) {
        try {
          const { data: funcao } = await axios.get(`${window._env_.REACT_APP_API_URL}/funcoes/${funcaoId}`, getToken())

          setState(prevState => ({...prevState,
            funcao: {
              id: funcao.id,
              descricao: funcao.descricao,
              nivel: funcao.nivel,
              car_descricao: funcao.cargo.descricao,
              cargo_id: funcao.cargo_id,
              dep_descricao: funcao.cargo.departamento.descricao,
              dep_id: funcao.cargo.id,
              unidade_id: funcao.unidade_id,
              ativo: funcao.ativo
            }
          }))


        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
          return false
        }

      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { funcao } = state
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Funções</h1>
                </Grid>
              </Grid>
              <FormFuncao 
                cargos={state.cargosFiltrado.list} 
                departamentos={state.departamentos.list} 
                updateField={e => updateField(e)} 
                updateFieldAtivo={e => updateFieldAtivo(e)} 
                dados={funcao} 
                unidades={state.unidades.list}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={e => backPage(0)}>Voltar</Button>
                </Grid>
                <Grid item md={8} xs={false} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Função"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroFuncao