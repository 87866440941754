import io from "socket.io-client";

export default function socketClient({acao, values}) {
	const usuario = JSON.parse(localStorage.getItem('usuario'))

	let socket = io(window._env_.REACT_APP_API_URL, {transports: ['websocket']});

	socket.on("set-session-acknowledgement", function(data) {
		localStorage.setItem('sessionId', data.sessionId);
	})

	var session_id;
	let session = localStorage.getItem('sessionId');
	
	if(usuario){
		if (session == null) {
			session_id = null//when we connect first time 
			socket.emit('start-session', {  sessionId: session_id, usuario_id: usuario.usuario_id })
		} else {
			session_id = session//when we connect n times 
			socket.emit('start-session', {  sessionId: session_id, usuario_id: usuario.usuario_id })
		}
	}

	switch (acao) {
    case 'drop-user':
			socket.emit('drop-user', {  sessionId: session_id, usuario_id: values.usuario_id })
			break;
		case 'user-logoff':
			socket.emit('user-logoff', {  sessionId: session_id, usuario_id: values.usuario_id })
			localStorage.removeItem('sessionId')
			break;
		default:
			break;
	}
				
	socket.on("deslogar-user", function(data) {
		alert(data.alerta)

		localStorage.clear()
		window.location.reload()
		return
	})

	socket.on("relatorio-disponivel", function(data) {
		alert('Relatorio Disponivel')
		// console.log('Aqui')

		return
	})

}