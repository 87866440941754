import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Button, TextField } from '@mui/material'
import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const {updateFieldCancelamento, dadosCancelar, motivosCancelamento} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h6 className="tituloConfirmacao">Cancelamento NFSE</h6>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="standard-select-currency"
                select
                label="Motivo"
                variant="outlined"
                className="input"
                fullWidth
                SelectProps={{
                  native: true,
                }}
                name="motivo"
                value={dadosCancelar.motivo}
                onChangeCapture={(e) => updateFieldCancelamento(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">Selecionar Motivo do Cancelamento</option>
                {motivosCancelamento.map(value => {
                  return (
                    <option value={value.numero}>{value.descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente realizar o cancelamento?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={12} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={dadosCancelar.motivo === "" ? true : false}>Sim</Button>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
