import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField, FormControlLabel, Switch, FormControl, FormLabel, Button } from '@mui/material'
import Table from './../Table'
import { Editor } from "react-draft-wysiwyg";
import ImageIcon from '@mui/icons-material/Image';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
  { id: 'variavel', numeric: false, disablePadding: true, label: 'Variavel' }
]

const acoesTabela = ['selectVar']


const FormNotificacao = ({ dados, updateField, onEditorStateChange, editorState, selecionarVar, variaveisFiltrada, filtro, 
  updateFiltro, unidades, unidadesSelecionadas, updateFieldUnidade, updateFieldAtivo, openModalSelecionarLogo, logo_unidade,
  dias_selecionado, updateFieldDias, dias_semana }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Nome da Notificação*" 
          variant="outlined" 
          size="small" 
          name="nome_alerta" 
          value={dados.nome_alerta} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Titulo da Notificação*" 
          variant="outlined" 
          size="small" 
          name="titulo_alerta" 
          value={dados.titulo_alerta} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Modulo*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="modulo"
          value={dados.modulo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        >
          <option value=''>Selecionar Modulo</option>
          <option value='COBRANCA'> Cobrança </option>
          <option value='AGENDA'> Agenda </option>
          <option value='ANIVERSARIO'> Aniversário </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Canal de Comunicação*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="canal"
          value={dados.canal}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        >
          <option value=''>Selecionar Canal</option>
          <option value='EMAIL'> Email </option>
          <option value='WHATSAPP'> WhatsApp </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          type="number"
          label="Qtd de Dias*" 
          variant="outlined" 
          size="small" 
          name="qtd_dias" 
          value={dados.qtd_dias} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      {dados.canal === 'EMAIL' &&
        <Grid item md={4} xs={12} sm={6}>
          <TextField 
            className="input" 
            type="text"
            label="Contato de Resposta*" 
            variant="outlined" 
            size="small" 
            name="contato_resposta" 
            value={dados.contato_resposta} 
            onChange={(e) => updateField(e)}
            InputLabelProps={{ shrink: true}} 
          />
        </Grid>
      }
      <Grid item md={3} xs={12} sm={6}>
        <Autocomplete
          multiple
          limitTags={3}
          id="multiple-limit-tags"
          options={unidades.filter(unidade => {
            if (unidadesSelecionadas.findIndex(i => i.id === unidade.id) === -1)
              return unidade;
            return false
          })}
          size="small"
          value={unidadesSelecionadas}
          onChange={(event, value) => updateFieldUnidade(event, value)}
          getOptionLabel={(option) => option.descricao}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Unidades*" InputLabelProps={{ shrink: true}} />
          )}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          type="time"
          label="Hora do Envio*" 
          variant="outlined" 
          size="small" 
          name="hora_envio" 
          value={dados.hora_envio} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      {dados.qtd_dias < 0 &&
        <Grid item md={2} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Enviar Todos os Dias?"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="enviar_todos_dias"
            value={dados.enviar_todos_dias}
            onChangeCapture={(e) => updateField(e)}
            InputLabelProps={{ shrink: true}} 
          >
            <option value={true}> Sim </option>
            <option value={false}> Não </option>
          </TextField>
        </Grid>
      }
      {dados.enviar_todos_dias &&
        <Grid item md={4} xs={12} sm={6} id='form-contas'>
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={dias_semana.filter(value => {
              if (dias_selecionado.findIndex(i => i.dia === value.dia) === -1){
                return value;
              }else{
                return false
              }
            })}
            size="small"
            value={dias_selecionado}
            onChange={(event, value) => updateFieldDias(event, value)}
            getOptionLabel={(option) => option.dia}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Dias da Semana" InputLabelProps={{ shrink: true }}/>
            )}
          />
        </Grid>
      }
      <Grid item md={1} xs={12} sm={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} style={{marginTop: '-20px'}}>
      <Grid item md={12} xs={12} sm={12}>
        <span style={{color: 'red'}}>Para notificações com data retroativa, informar o valor negativo. Ex. Para aviso de contas vencidas a partir de <b>um dia</b> usar o valor <b>-1</b>.</span>
      </Grid>
    </Grid>
    {dados.canal === 'EMAIL' &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h4 className='titulo'>Logo da Unidade:</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={12} sm={4}>
            <Button 
              fullWidth 
              color="primary" 
              variant="contained" 
              size="small" 
              startIcon={<ImageIcon />}
              onClick={() => openModalSelecionarLogo()}
              disabled={unidadesSelecionadas.length === 0}
            >
              {dados.midia_logo_id ? 'Alterar Logo': 'Selecionar Logo'}
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          {dados.midia_logo_id &&
            <Grid item md={2} xs={12} sm={4}>
              <img style={{width:'100%'}} src={`${window._env_.REACT_APP_API_URL}/static/${logo_unidade.key}`} alt="Imagem" /> 
            </Grid>
          }
        </Grid>
      </React.Fragment>
    }
    <br />
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <h5 className='titulo'>Mensagem da Notificação:</h5>
      </Grid>
    </Grid>

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
      </Grid>
    </Grid>

    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={12} xs={12}>
        <Table
          headCell={cabecalhoTabela}
          rows={variaveisFiltrada}
          acoes={acoesTabela}
          selecionarVar={e => selecionarVar(e)} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <span style={{color: 'red'}}>Para usar as variáveis, insira o conjunto de caracteres <b>{`{%}`}</b> no campo de texto e, em seguida, selecione a variável desejada na tabela acima.</span>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <div className="cardEditor">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={e => onEditorStateChange(e)}
          />
        </div>
      </Grid>
    </Grid>
    <br />
    <hr />
  </React.Fragment>
)

export default FormNotificacao