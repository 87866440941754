import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'

const FormCargo = ({ departamentos, dados, updateField,unidades, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Departamento"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="departamento_id"
          value={dados.departamento_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.unidade_id === ''}
        >
          <option value={dados.departamento_id === "" ? "" : dados.departamento_id}> {dados.departamento_id === "" ? "Selecionar Departamento" : dados.dep_descricao} </option>
          {departamentos.map(departamento => {
            if (departamento._id !== dados.departamento_id) {
              return (
                <option key={departamento._id} value={departamento._id}> {departamento.descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Cargo" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={dados.descricao} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
          disabled={dados.departamento_id === ''}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Comissionado"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="comissionado"
          value={dados.comissionado}
          onChangeCapture={(e) => updateField(e)}
        >
          <option key="Sim" value={true}>
            Sim
					</option>
          <option key="Não" value={false}>
            Não
					</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.ativo}
            />
          }
          label="Ativo"
          name="ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
    <hr />
  </React.Fragment>
)

export default FormCargo