import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import real from '../../services/real';
import CurrencyInput from './../mask/CurrencyInput'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).add(1, 'd').format('YYYY-MM-DD')

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '88%',
  marginLeft: '6%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const {boletosVencidos} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Faturas Vinculadas</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1} >
            <Grid item md={12} xs={12} sm={12}>
              <p>Existem faturas vinculadas vencidas com os clientes:</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <div className='scroll'>
                <table className="lista sempadding borderTable">
                  <thead>
                    <tr className="titulos acompanha">
                      <th>Cliente</th>
                      <th>Descrição</th>
                      <th>Data Vencimento</th>
                      <th>Valor</th>
                      <th>Multa/Juros</th>
                      <th>Nova Data Vencimento*</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boletosVencidos.map((boleto, key) => {
                      return (
                        <tr key={key}>
                          <td>{boleto.conta.pessoa_conta.tipo === 'FISICA' ? `${boleto.conta.pessoa_conta.fisica.nome} ${boleto.conta.pessoa_conta.fisica.sobrenome || ''}` : boleto.conta.pessoa_conta.juridica.nomefantasia}</td>
                          <td>{boleto.conta.descricao}</td>
                          <td>
                            <TextField type='date' className="inputVencimento2" variant="outlined" size="small" name="datavencimento" value={boleto.datavencimento} disabled/>
                          </td>
                          <td>{real(boleto.valorparcela)}</td>
                          <td>
                            <TextField className="inputValor" variant="outlined" size="small" name="nova_multajuros" value={boleto.nova_multajuros} onChange={(e) => props.updateFieldBoleto(e, boleto.id)}  InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                          </td>
                          <td>
                            <TextField 
                              type="date" 
                              className="inputVencimento" 
                              variant="outlined" 
                              size="small" 
                              name="nova_data_vencimento" 
                              value={boleto.nova_data_vencimento} 
                              onChange={(e) => props.updateFieldBoleto(e, boleto.id)} 
                              InputLabelProps={{ shrink: true }} 
                              InputProps={{ inputProps: { min: dataHojeFormatado } }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)}>Continuar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
