import React from 'react';
import './Natal.scss';

const Natal = () => (
  <div className="container">
    <div className="tree">
      <div className="star">
        <div className="mini__star mini__star--1"></div>
        <div className="mini__star mini__star--2"></div>
        <div className="mini__star mini__star--3"></div>
      </div>

      <div className="triangle__1">
        <div className="sticker"></div>
        <div className="anime light"></div>
      </div>
      <div className="shadow__1"></div>

      <div className="triangle__2">
        <div className="sticker__8"></div>
        <div className="sticker__9"></div>
        <div className="anime light--1"></div>
        <div className="anime light--2"></div>
        <div className="anime light--3"></div>
      </div>
      <div className="shadow__2"></div>

      <div className="triangle__3">
        <div className="sticker__6"></div>
        <div className="sticker__7"></div>
        <div className="anime light--4"></div>
        <div className="anime light--5"></div>
        <div className="anime light--6"></div>
      </div>
      <div className="shadow__3"></div>

      <div className="triangle__4">
        <div className="sticker__4"></div>
        <div className="sticker__5"></div>
        <div className="anime light--7"></div>
        <div className="anime light--8"></div>
        <div className="anime light--9"></div>
        <div className="anime light--10"></div>
      </div>

      <div className="post__container">
        <div className="post__shad"></div>
        <div className="post post__1"></div>
        <div className="post post__2"></div>
      </div>
    </div>
    <div className="boxes">
      <div className="box box--3 animate--3">
        <div className="cover"></div>
        <div className="body"></div>
        <div className="plate"></div>
      </div>
      <div className="box box--1 animate--1">
        <div className="cover cover--1"></div>
        <div className="body body--1"></div>
        <div className="plate"></div>
      </div>
      <div className="box box--2 animate--2">
        <div className="cover cover--2"></div>
        <div className="body body--2"></div>
        <div className="plate"></div>
      </div>
    </div>
    <div className="text">
      <h1 className="merry merry--0">Feliz Natal</h1>
      <h1 className="merry merry--1">e um</h1>
      <h1 className="merry merry--2">Próspero</h1>
      <h1 className="merry merry--3">2025</h1>
    </div>
  </div>
)

export default Natal
