import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import moment from 'moment'
import Alert from './../../../components/templates/Alert'
import { Link } from 'react-router-dom'
import MaskedInput from 'react-text-mask';
import Tabela from './../../../components/Tabela'
import formatarTelefone from './../../../services/formatarTelefone'

const initialState = {
  observacoes: {
    list: []
  },
  planosConta: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'funobs_id', numeric: false, disablePadding: false, label: 'id' },
    { id: 'data', numeric: false, disablePadding: false, label: 'Data' },
    { id: 'funobs_obs', numeric: false, disablePadding: false, label: 'Observação' },
    { id: 'funobs_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
    { id: 'responsavel', numeric: false, disablePadding: false, label: 'Responsável' }
  ],
  acoesTabela: ['update'],
  loading: true,
  cores: {
    cor_fundo: '',
    cor_fonte: ''
  },
  editando: false,
  modal: false,
  dialog: {
    ativo: false,
    titulo: "",
    descricao: ""
  },
  loadList: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: [],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
}

export default class Agenda extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'funcionario')[0]

    if(permissoes){
      this.setState({
        permissoes
      })
    }

    const {funcionario} = this.props

    this.setState({
      cores:{
        cor_fundo: funcionario.cor_fundo,
        cor_fonte: funcionario.cor_fonte
      },
      loading: false
    })

  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateFieldCores(event){
    const {cores} = this.state
    cores[event.target.name] = event.target.value
    this.setState({cores})
  }

  async salvarCores(){
    const {cores} = this.state
    const {funcionario} = this.props

    try {
      await axios.post(`${window._env_.REACT_APP_API_URL}/funcionario/cores/${funcionario.id}`, cores, this.getToken())
    
      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cores salva com sucesso'
        }
      })
    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  render() {
    const {funcionario, permissoes} = this.props

    const {cores, cabecalhoTabelaEndereco, acoesTabelaEndereco, cabecalhoTabelaTelefone, cabecalhoTabelaEmail} = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div id="view">
            <Grid container direction="row" className="mg_top_10">
              <Grid item md={12}>
                <h3 className='titulo'>Dados do Funcionário:</h3>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={6} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={funcionario.fisica.nome} disabled />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(funcionario.fisica.datanascimento).format('DD/MM/YYYY')} disabled />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={funcionario.fisica.cpf} disabled />
              </Grid>
            </Grid>
            {funcionario.fisica.pessoa.enderecos.length > 0 &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Endereço: </h4>
                  </Grid>
                </Grid>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <Tabela 
                      headCell={cabecalhoTabelaEndereco} 
                      rows={funcionario.fisica.pessoa.enderecos.map((endereco, index) => {
                        return {
                          cep: endereco.num_cep,
                          endereco: `${endereco.logradouro}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                          cidade: endereco.cidade,
                          estado: endereco.estado,
                          principal: endereco.principal,
                          tipoEndereco: endereco.tipo,
                          index
                        }
                      })} 
                      acoes={acoesTabelaEndereco}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {funcionario.fisica.pessoa.telefones.length > 0 &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Telefones: </h4>
                  </Grid>
                </Grid>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <Tabela 
                      headCell={cabecalhoTabelaTelefone} 
                      rows={funcionario.fisica.pessoa.telefones.map(contato => {
                        return {
                          numero: formatarTelefone(contato.numero),
                          tipoContato: contato.tipo,
                          principal: contato.principal,
                          index: contato.index
                        }
                      })} 
                      acoes={acoesTabelaEndereco}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {/* {funcionario.fisica.pessoa.telefones.map(telefone => {
              return (
                <div className="cardEndereco" key={telefone.id}>
                  {telefone.principal &&
                    <h4 className="titulo">Telefone Principal:</h4>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.numero} disabled />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tipo} disabled />
                    </Grid>
                  </Grid>
                </div>
              )
            })} */}
            {funcionario.fisica.pessoa.emails.length > 0 &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">E-mails: </h4>
                  </Grid>
                </Grid>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <Tabela 
                      headCell={cabecalhoTabelaEmail} 
                      rows={funcionario.fisica.pessoa.emails.map(email => {
                        return {
                          email: email.email,
                          principal: email.principal,
                          index: email.index
                        }
                      })} 
                      acoes={acoesTabelaEndereco}
                    />
                  </Grid>
                </Grid>                
              </React.Fragment>
            }
            {funcionario.fisica.pessoa.usuario &&
              <div>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Usuário: </h4>
                  </Grid>
                </Grid>
                <div className="cardEndereco">
                  <Grid container spacing={1} direction="row">
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Login" value={funcionario.fisica.pessoa.usuario.login} disabled />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Perfil" value={funcionario.fisica.pessoa.usuario.perfil.descricao} disabled />
                    </Grid>
                    {/* <Grid item md={6} sm={6} xs={12}>
                      <Link to={`/funcionario/update/usuario/${funcionario.id}`}>
                        <Button className="btnCadastrar" variant="contained" color="primary">
                          Editar Usuário
                        </Button>
                      </Link>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            }

            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <div className="cardEndereco">
                  <Grid container spacing={1} direction="row">
                    <Grid item md={12} xs={12} sm={12}>
                      <h5 className="titulo">Cores da Agenda: </h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={2} xs={6} sm={3}>
                      <TextField 
                        type="color" 
                        className="input" 
                        label="Cor de Fundo*" 
                        variant="outlined" 
                        size="small" 
                        name="cor_fundo" 
                        value={cores.cor_fundo} 
                        onChange={(e) => this.updateFieldCores(e)} 
                        InputLabelProps={{ shrink: true }} 
                        disabled={(!permissoes.inserir && !permissoes.alterar)}
                      />
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                      <TextField 
                        type="color" 
                        className="input" 
                        label="Cor da Fonte*" 
                        variant="outlined" 
                        size="small" 
                        name="cor_fonte" 
                        value={cores.cor_fonte} 
                        onChange={(e) => this.updateFieldCores(e)} 
                        InputLabelProps={{ shrink: true }} 
                        disabled={(!permissoes.inserir && !permissoes.alterar)}
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: "#FFF" }}>
                        <p style={{ color: "#000" }}>Demonstração:</p>
                      </div>
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: cores.cor_fundo }}>
                        <p style={{ color: cores.cor_fonte }}>Texto</p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={2} xs={6} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: '#FFF' }}>
                        <p style={{ color: '#000' }}>Cores Reservadas:</p>
                      </div>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: '#ffff00' }}>
                        <p style={{ color: '#000', fontSize: '12px', marginTop: '5px' }}>AGENDADO</p>
                      </div>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: '#00a933' }}>
                        <p style={{ color: '#000', fontSize: '12px', marginTop: '5px' }}>CONFIRMADO</p>
                      </div>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: '#2a6099' }}>
                        <p style={{ color: '#FFF', fontSize: '12px', marginTop: '5px' }}>EM ATENDIMENTO</p>
                      </div>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: '#ff4000' }}>
                        <p style={{ color: '#FFF', fontSize: '12px', marginTop: '5px' }}>ATRASADO</p>
                      </div>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: '#FFF', border: '1px solid #000' }}>
                        <p style={{ color: '#000', fontSize: '12px', marginTop: '4px' }}>FINALIZADO</p>
                      </div>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <div className="cardApresentacao" style={{ backgroundColor: 'rgb(128, 0, 0)' }}>
                        <p style={{ color: '#FFF', fontSize: '12px', marginTop: '5px' }}>FALTOU</p>
                      </div>
                    </Grid>
                  </Grid> 
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={10} sm={12} xs={9}></Grid>
                    {(permissoes.inserir || permissoes.alterar) &&
                      <Grid item md={2} sm={12} xs={3}>
                        <Button className="btnCadastrar" variant="contained" color="primary" onClick={(e => this.salvarCores())}>
                          Salvar Cores
                        </Button>
                      </Grid>
                    }
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={6}></Grid>
              <Grid item md={3} xs={6} sm={6}>
                <React.Fragment>
                  {permissoes.inserir &&
                    <Link to={`/funcionario/cadastro/${funcionario.id}`}>
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Editar Funcionário
                    </Button>
                    </Link>
                  }
                </React.Fragment>
              </Grid>
              <Grid item md={3} xs={5} sm={5}>
                <Link to={`/funcionario`}>
                  <Button className="btnCadastrar" variant="contained" color="secondary">
                    Voltar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} />
      </React.Fragment>
    )
  }
}