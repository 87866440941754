import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import real from '../../services/real'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

function renderClassFinanceiro(param) {
  if (param === 'Atrasado') {
    return  '#FFB6C1'
  } else if (param === 'A Pagar') {
    return  '#FFFFE0'
  } else if (param === 'Pago') {
    return '#98FB98'
  }
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Antecipar Parcelas</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
            <Grid item md={6} xs={12} sm={8}>
              <h6 style={{marginTop: 5}} className="titulo">Valor Selecionado: R$ {props.parcelas_antecipacao.length > 0 ? real(somarValores(props.parcelas_antecipacao.map(value => {return value.valor}))) : real(0)}</h6>
            </Grid>
            {props.parcelas_antecipacao.length === 0 &&
              <Grid item md={3} xs={12} sm={4}></Grid>
            }
            {props.parcelas_antecipacao.length > 0 &&
              <Grid item md={3} xs={12} sm={4}>
                <Button size='small' fullWidth variant="contained" color="success" onClick={() => props.confirmarAntecipacao()}>
                  Confirmar
                </Button>
              </Grid>
            }
            {!props.selecionar &&
              <Grid item md={3} xs={12} sm={4}>
                <Button size='small' fullWidth variant="contained" color="primary" onClick={() => props.selecionarTodos()}>
                  Selecionar Todos
                </Button>
              </Grid>
            }
            {props.selecionar &&
              <Grid item md={3} xs={12} sm={4}>
                <Button size='small' fullWidth variant="contained" color="secondary" onClick={() => props.desmarcarTodos()}>
                  Desmarcar Todos
                </Button>
              </Grid>
            }
          </Grid>
          <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
            {props.contas.map((value, key) => {
              return (
                <Grid key={key} item md={3} xs={12} sm={4}>
                  <div
                    style={{
                      width: '96%',
                      marginLeft: '2%',
                      border: '1px solid #b0b0b0',
                      backgroundColor: renderClassFinanceiro(value.status),
                      minHeight: '85px',
                      borderRadius: '10px',
                      padding: '5px',
                      overflow: 'hidden',
                      position: 'relative',
                      cursor: 'pointer'
                    }}
                    onClick={() => props.selecionarParcela(value, key)}
                  >
                    <b><p style={{fontSize: '12px', margin: '0px 5px'}}>{value.descricao}</p></b>
                    <b><p style={{fontSize: '12px', margin: '0px 5px'}}>Vencimento: {value.data_vencimento}</p></b>
                    <b><p style={{fontSize: '12px', margin: '0px 5px'}}>Valor: R$ <span>{real(value.valor_total)}</span></p></b>
                    <b><p style={{fontSize: '12px', margin: '0px 5px'}}>Situação: {value.status}</p></b>
                    {(key <= props.parcelas_antecipacao.length) &&
                      <React.Fragment>
                        {props.parcelas_antecipacao.findIndex(param => param.id === value.parcela_id) === -1 &&
                          <RadioButtonUncheckedIcon color='primary' style={{
                            position: 'absolute',
                            bottom: 5,
                            right: 5
                          }}/>
                        }
                        {props.parcelas_antecipacao.findIndex(param => param.id === value.parcela_id) !== -1 &&
                          <CheckCircleIcon color='primary' style={{
                            position: 'absolute',
                            bottom: 5,
                            right: 5
                          }}/>
                        }
                      </React.Fragment>
                    }
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
