import React, { useState, useEffect } from 'react';
import './Caixa.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import moment from 'moment';
import axios from 'axios'
import Loading from './../../components/templates/Loading'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import CurrencyInput from './../../components/mask/CurrencyInput'
import Table from '../../components/Table'
import real from '../../services/real'
import Alert from './../../components/templates/Alert'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom'
import { styled } from '@mui/system';
import Color from 'color';
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  loading: true,
  mensagemLoading: 'Carregando Caixa...',
  caixa: {},
  modalOpen: false,
  modal: {
    mensagem: '',
  },
  movimentacao: {
    valor: '',
    contfin_id: 0,
    data_conciliacao: '',
    descricao: '',
    caixa: ''
  },
  toogleTransferencia: false,
  cofre: {
    list: []
  },
  transferencias: {
    list: []
  },
  recebidos: {
    listDinheiro: [],
    listVoucher: [],
    listOutros: []
  },
  lancamentos: {
    list: []
  },
  headCellTransferencia: [
    { id: 'posicao', numeric: false, disablePadding: true, label: '' },
    { id: 'data_hora', numeric: false, disablePadding: true, label: 'Data e Hora' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição da Movimentação' },
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo da Movimentação' },
    { id: 'valor_caixa', numeric: false, disablePadding: true, label: 'Valor' }
  ],
  headCellRecebidos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'valorparcela', numeric: true, disablePadding: true, label: 'Valor Parcela' },
    { id: 'multajuros', numeric: true, disablePadding: true, label: 'Juros' },
    { id: 'valorpagamento', numeric: true, disablePadding: true, label: 'Valor Pago' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
    { id: 'conta_fin', numeric: false, disablePadding: true, label: 'Conta Fin.' },
  ],
  headCellRecebidosVoucher: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'numero_voucher', numeric: true, disablePadding: true, label: 'Núm. Voucher' },
    { id: 'valor_credito', numeric: true, disablePadding: true, label: 'Total do Voucher' },
    { id: 'valorparcela', numeric: true, disablePadding: true, label: 'Valor Utilizado' },
    { id: 'saldo', numeric: true, disablePadding: true, label: 'Saldo Atual' },
  ],
  headCellLancamentos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'valorparcela', numeric: true, disablePadding: true, label: 'Valor Parcela' },
    { id: 'multajuros', numeric: true, disablePadding: true, label: 'Juros' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
    { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
  ],
  headCellValores: [
    { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma de Pagamento' },
    { id: 'valor_recebido', numeric: false, disablePadding: false, label: 'Saldo' },
  ],
  contaCofre: {},
  valores_recebidos: {
    list: []
  },
  disabledButtonTransferir: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

const ViewCaixa = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { caixaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = async (event) => {
    const split = event.target.name.split('.')
    const data = { ...state[split[0]] }
    data[split[1]] = event.target.value
    const intercection = {}
    intercection[split[0]] = data
    setState(prevState => ({...prevState, ...intercection }))
  }

  const fechar = () => {
    let mensagem = 'Deseja realmente fechar o caixa?'

    setState(prevState => ({...prevState,
      modal: {
        mensagem,
        function: e => confirmarFechamento(e)
      },
      modalOpen: true,
    }))
  }

  const confirmarFechamento = async (resposta) => {
    if (resposta) {
      try {
        const caixa = { ...state.caixa }
        await axios.put(`${window._env_.REACT_APP_API_URL}/caixa/${caixa.id}/fechar`, {}, getToken())

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Caixa fechado com sucesso'
          }
        }))

        handleClose()

        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

    } else {
      handleClose()
    }
  }

  const transferir = () => {
    const { valores_recebidos } = state
    const transf = { ...state.movimentacao }

    setState(prevState => ({...prevState,
      disabledButtonTransferir: true
    }))

    const index = valores_recebidos.list.findIndex(param => param.formapagto_descricao === "Dinheiro")

    if (index !== -1) {
      if (parseFloat(transf.valor.replaceAll(".", "").replace(",", ".")) > valores_recebidos.list[index].valor_recebido) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Valor Informado superior ao saldo do caixa!'
          },
          disabledButtonTransferir: false
        }))
        return false
      }
    }

    setState(prevState => ({...prevState,
      modal: {
        mensagem: "Deseja mesmo realizar a sangria?",
        function: e => confirmarTransferencia(e)
      },
      modalOpen: true,
    }))
  }

  const confirmarTransferencia = async (resposta) => {
    if (resposta) {
      try {
        
        const transf = { ...state.movimentacao }
        const caixa = { ...state.caixa }

        if (
          transf.valor === 0
        ) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "Preencha os campos obrigatorios (*)."
            },
            loading: false
          }))
          return
        }

        if (caixa['conta.saldo_atual'] < transf.valor) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "O valor da movimentacao não pode ser maior do que o saldo do caixa."
            },
            loading: false
          }))
          return
        }

        let valor = transf.valor
        valor = valor.replace(/\./g, '');
        valor = valor.replace(',', '.');

        setState(prevState => ({...prevState, loading: true }))

        const pessoa = JSON.parse(localStorage.getItem('pessoa'))
        const usuario = JSON.parse(localStorage.getItem('usuario'))
        const contaCofre = state.contaCofre

        const data = {
          conciliado: true,
          tipo: 'DEPOSITO',
          valor: parseFloat(valor),
          contfin_id: contaCofre.id,
          data_conciliacao: new Date(),
          descricao: `Recebimento de sangria do caixa nº ${caixa.numero} do operador ${pessoa.fisica.nome}.`,
          caixa: caixa.id,
          data_hora_conta: new Date(),
          usu_criador_id: usuario.id
        }

        await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira`, data, getToken())
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message:'Transferencia realizada com sucesso'
          },
          disabledButtonTransferir: false,
          loading: false
        }))

        handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabledButtonTransferir: false,
          loading: false
        }))

        handleClose()
      }

    } else {
      handleClose()
    }
  }

  const handleClose = () => {
    setState(prevState => ({...prevState,
      modalOpen: false,
      loading: false
    }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const backPage = (timeout = 1000) => {
    const self = this
    setTimeout(() => {
      navigate("/caixa");
    }, timeout)
  }

  const resumo = async (event) => {
    setState(prevState => ({...prevState, loading: true }))

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${state.caixa.id}/resumo`, getToken())

      const dados = {
        path: data.path
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `resumo-caixa-${state.caixa.numero}.pdf`);

      setState(prevState => ({...prevState, loading: false }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const listaParcelas = (lista) => {
    const retorno = lista.map(object => {
      return {
        ...object,
        datavencimento: moment(object.datavencimento).format('DD/MM/YYYY'),
        datapagamento: object.datapagamento ? moment(object.datapagamento).format('DD/MM/YYYY'): '',
        num_parcela: `${object.num}/${object.contpr_numparcela}`,
        valorparcela: real(object.valorparcela || 0),
        multajuros: real(object.multajuros || 0),
        valorpagamento: real(object.valorpagamento || 0),
        valor_credito: real(object.valor_credito || 0),
        saldo: real(object.saldo || 0),
      }
    })

    return retorno
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/caixa/View.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      if (caixaId) {
        try {        
          const { data: caixa } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${caixaId}`, getToken())
          const { data: cofres } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${caixa.unidade_id}`,getToken())
          const { data: transferencias } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${caixa.id}/movimentacoes`, getToken())
          let { data: recebidos } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${caixa.id}/recebidos`, getToken())
          // const { data: lancamentos } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${caixa.id}/lancamentos`, getToken())
          let { data: valores_recebidos } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/valores-recebidos/${caixa.id}`, getToken())
          // const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())
    
          recebidos = recebidos.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

          for (let i = 0; i < transferencias.length; i++) {
            const index = valores_recebidos.findIndex(param => param.formapagto_descricao === "Dinheiro")

            if (index !== -1) {
              valores_recebidos[index].valor_recebido += transferencias[i].valor
            }else{
              let valor_recebido = transferencias[i].valor

              valores_recebidos.push({
                valor_recebido,
                formapagto_id: 1,
                formapagto_descricao: "Dinheiro"
              })
            }
          }

          let listDinheiro = []

          for (const transf of transferencias) {
            listDinheiro.push({
              contpr_descricao: transf.descricao,
              contpr_numparcela: 1,
              num: 1,
              datavencimento: transf.data_hora_conta,
              datapagamento: transf.data_hora_conta,
              valorparcela:transf.valor,
              multajuros: 0,
              valorpagamento:transf.valor,
              formapagto_descricao: 'Dinheiro',
              tipo: transf.valor < 0 ? 'SAQUE': 'DEPOSITO',
              conta_fin: transf.conta_financeira.descricao
            })
          }

          for (const parcela of recebidos) {
            if(parcela.formapagto_slug === 'dinheiro'){
              listDinheiro.push({
                contpr_descricao: parcela.contpr_descricao,
                contpr_numparcela: parcela.contpr_numparcela,
                num: parcela.num,
                datavencimento: parcela.datavencimento,
                datapagamento: parcela.datapagamento ? parcela.datapagamento : '',
                valorparcela: parcela.valorparcela,
                multajuros: parcela.multajuros ? parcela.multajuros : '',
                valorpagamento: parcela.valpag_valor_pagamento,
                formapagto_descricao: parcela.formapagto_descricao,
                tipo: parcela.valorparcela < 0 ? 'SAQUE': 'DEPOSITO',
                conta_fin: parcela.conta_fin
              })
            }
          }       

          let listVoucher = recebidos.filter(param => param.formapagto_slug === 'voucher').map(parcela => {
            return {
              contpr_descricao: parcela.contpr_descricao,
              contpr_numparcela: parcela.contpr_numparcela,
              num: parcela.num,
              datavencimento: parcela.datavencimento,
              datapagamento: parcela.datapagamento ? parcela.datapagamento : '',
              valorparcela: parcela.valorparcela,
              multajuros: parcela.multajuros ? parcela.multajuros : '',
              valorpagamento: parcela.valpag_valor_pagamento,
              formapagto_descricao: parcela.formapagto_descricao,
              tipo: parcela.valorparcela < 0 ? 'SAQUE': 'DEPOSITO',
              numero_voucher: parcela.numero_voucher,
              valor_credito: parcela.valor_credito,
              saldo: parcela.saldo,
              conta_fin: parcela.conta_fin
            }
          })

          let listOutros = recebidos.filter(param => param.habilita === 'BANCO').map(parcela => {
            return {
              contpr_descricao: parcela.contpr_descricao,
              contpr_numparcela: parcela.contpr_numparcela,
              num: parcela.num,
              datavencimento: parcela.datavencimento,
              datapagamento: parcela.datapagamento ? parcela.datapagamento : '',
              valorparcela: parcela.valorparcela,
              multajuros: parcela.multajuros ? parcela.multajuros : '',
              valorpagamento: parcela.valpag_valor_pagamento,
              formapagto_descricao: parcela.formapagto_descricao,
              tipo: parcela.valorparcela < 0 ? 'SAQUE': 'DEPOSITO',
              conta_fin: parcela.conta_fin
            }
          })

          setState(prevState => ({...prevState,
            caixa,
            cofre: {
              list: cofres
            },
            transferencias: {
              list: transferencias
            },
            valores_recebidos: {
              list: valores_recebidos
            },
            recebidos: {
              listDinheiro,
              listVoucher,
              listOutros: listOutros.sort((a, b) => (a.formapagto_descricao > b.formapagto_descricao) ? 1 : ((b.formapagto_descricao > a.formapagto_descricao) ? -1 : 0))
            },
            contaCofre: cofres.filter(param => param.cofre === true)[0]
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
          backPage()
        }
      }

      setState(prevState => ({...prevState, loading: false }))
    };
    
    fetchData();
  }, [caixaId]);

  const { caixa, movimentacao, contaCofre, valores_recebidos } = state

  const recebidos = listaParcelas(state.recebidos.listDinheiro)
  const vouchers = listaParcelas(state.recebidos.listVoucher)
  const outros = listaParcelas(state.recebidos.listOutros)

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div className="view" id="caixa">
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={3}>
                  <h1 className="titulo">
                    Caixa Nº {caixa.numero}
                    {caixa['status_atual.status'] !== 'ABERTO' && <span>({caixa['status_atual.status'] === 'AGUARDANDO_FECHAMENTO' ? 'AGUARDANDO FECHAMENTO' : caixa['status_atual.status']})</span>}
                  </h1>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Link to="/conta_pagar_receber" target="_blank">
                    <Button color='success' size="small" className="btnCadastrar" variant="contained">
                      Financeiro
                    </Button>
                  </Link>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Link to="/agenda_ambiente" target="_blank">
                    <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                      Agendas
                    </Button>
                  </Link>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Link to="/venda_voucher" target="_blank">
                    <Button size="small" className="btnCadastrar" variant="contained" color="secondary">
                      Vouchers
                    </Button>
                  </Link>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="titulo">Lançamentos em Dinheiro:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (recebidos.length) {
                      return (
                        <Table headCell={state.headCellRecebidos} rows={recebidos} acoes={[]} ordem='desc' heightRows={15} />
                      )
                    } else {
                      return (<p>Não há recebimentos no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="titulo">Uso de Voucher:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (recebidos.length) {
                      return (
                        <Table headCell={state.headCellRecebidosVoucher} rows={vouchers} acoes={[]} ordem='desc' heightRows={15} />
                      )
                    } else {
                      return (<p>Não há recebimentos no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="titulo">Outros:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (recebidos.length) {
                      return (
                        <Table headCell={state.headCellRecebidos} rows={outros} acoes={[]} ordem='desc' heightRows={15} />
                      )
                    } else {
                      return (<p>Não há recebimentos no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ marginTop: '50px' }}>
                <Grid item lg={4} md={4} xs={12} sm={4}>
                  <h4 className="saldo">Saldo Atual:</h4>
                  <p>Iniciado com R$ {real(caixa.fundotroco || 0)}</p>
                </Grid>
                {!state.toogleTransferencia &&
                  <Grid item md={2} xs={12} sm={4}>
                    {caixa['status_atual.status'] === 'ABERTO' &&
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className="btn_salvar"
                        style={{ 'marginTop': '5px' }}
                        size="small"
                        onClick={() => setState(prevState => ({...prevState, toogleTransferencia: true }))}
                        disabled={state.loading || caixa.fechado}
                      >
                        Realizar Sangria
                    </Button>
                    }
                  </Grid>
                }
              </Grid>

              {state.toogleTransferencia &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item lg={2} md={3} xs={8} sm={5}>
                    <TextField
                      className="input"
                      label="Conta"
                      variant="outlined"
                      size="small"
                      value={contaCofre.descricao}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item md={2} xs={4} sm={4}>
                    <TextField
                      className="input"
                      label="Valor*"
                      variant="outlined"
                      size="small"
                      name="movimentacao.valor"
                      value={movimentacao.valor}
                      onChange={(e) => updateField(e)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputComponent: CurrencyInput }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      className="btn_salvar"
                      style={{ 'marginTop': '5px' }}
                      size="small"
                      onClick={e => transferir(e)}
                      disabled={state.loading ? true : state.disabledButtonTransferir}
                    >
                      Confirmar Sangria
                    </Button>
                  </Grid>
                </Grid>
              }

              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={12}>
                  <Table 
                  headCell={state.headCellValores} 
                  rows={valores_recebidos.list} 
                  acoes={[]} />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1}>
                <Grid item md={3} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    className="btn_salvar mg_top_10"
                    size="small"
                    onClick={e => backPage(0)}
                    disabled={state.loading}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  {caixa['status_atual.status'] === 'ABERTO' &&
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      className="btn_salvar mg_top_10"
                      size="small"
                      onClick={e => fechar(e)}
                      disabled={state.loading || caixa.fechado}
                    >
                      Fechar Caixa
                    </Button>
                  }

                  {caixa['status_atual.status'] === 'FECHADO' &&
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      className="btn_salvar mg_top_10"
                      size="small"
                      onClick={e => resumo(e)}
                      disabled={state.loading}
                    >
                      Imprimir Resumo
                    </Button>
                  }
                  {caixa['status_atual.status'] === "AGUARDANDO_FECHAMENTO" &&
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      className="btn_salvar mg_top_10"
                      size="small"
                      onClick={e => resumo(e)}
                      disabled={state.loading}
                    >
                      Imprimir Resumo
                    </Button>
                  }
                </Grid>
              </Grid>
              <ModalConfirmacao open={state.modalOpen} dados={state.modal} handleClose={e => handleClose(e)} confirmar={e => state.modal.function(e)} />
            </div>
            <br />
            <br />
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Visualizar Caixa"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default ViewCaixa