import React from 'react'
import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'

const FormParceirosBancarios = ({ dados, updateField, updateFieldAtivo, updateFieldParceiro, fornecedores,
  servico, servicos, updateFieldServico, addServico, alteraServico, editarItemServico,removerItemServico }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Descrição" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={dados.descricao} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} />
      </Grid>
      <Grid item md={4} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Fornecedor*"
          variant="outlined"
          className="input"
          size="small"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="pessoa_id"
          value={dados.parceiro.pessoa_id}
          onChangeCapture={(e) => updateFieldParceiro(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={dados.parceiro.pessoa_id !== '' ? dados.parceiro.pessoa_id : ''}> {dados.parceiro.pessoa_id !== '' ? dados.parceiro.nome : 'Selecionar Fornecedor'} </option>
          {fornecedores.map((forn, key) => {
            return (
              <option key={key} value={forn.pessoa_id}>{forn.nome}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Parceiro ID" 
          variant="outlined" 
          size="small" 
          name="parceiro_id" 
          value={dados.parceiro_id} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.ativo}
            />
          }
          label="Ativo"
          name="ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
    <br />
    <hr />
  </React.Fragment>
)

export default FormParceirosBancarios