import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, FormLabel, FormControl, FormControlLabel, Switch } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Table from './../Table'
import CurrencyInput from './../mask/CurrencyInput'
import real from '../../services/real';
import moment from 'moment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabelaFinanceiro = [
  { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
  { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma Pgto' },
  { id: 'situacao', numeric: false, disablePadding: false, label: 'Situação' },
  { id: 'valorparcela', numeric: false, disablePadding: false, label: 'Valor' },
  { id: 'multajuros', numeric: false, disablePadding: false, label: 'Juros/Multas' },
]

const acoesTabelaFinanceiro = []

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h6 className="tituloConfirmacao">Atualização de Serviço</h6>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Serviço"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{ native: true }}
                name="produto_id"
                value={props.dados.produto_id}
                onChangeCapture={(e) => props.updateServico(e)}
                InputLabelProps={{ shrink: true }}
                disabled
              >
                <option value=""> Selecionar Produto</option>
                {props.produtos.list.map(produto => {
                  return (
                    <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={1} xs={12} sm={6}>
              <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={props.dados.valor} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type='number' label="Num. Parc. Rest.*" variant="outlined" size="small" name="num_parcelas" value={props.dados.num_parcelas} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} disabled/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{ native: true }}
                name="formapagto_id"
                value={props.dados.formapagto_id}
                label="Forma de Pgto.*"
                onChangeCapture={(e) => props.updateServico(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">Selecionar Forma de Pgto.</option>
                {props.formasPagamento.list.map(forma => {
                  return (
                    <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="contfin_id"
                value={props.dados.contfin_id}
                label="Contas Bancárias.*"
                onChangeCapture={(e) => props.updateServico(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha--</option>
                {props.contasFinanceira.list.map(conta => {
                  return (
                    <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type='date' label="Venc. Prim. Parcelas*" variant="outlined" size="small" name="venc_prim_parcela_sem_form" value={props.dados.venc_prim_parcela_sem_form} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} disabled />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            {props.possui_emissao_nfse &&
              <Grid item md={3} xs={12} sm={4}>
                <React.Fragment>
                  <div style={{float: 'left', marginRight: '5px'}}>
                    <Tooltip
                      title={'Ao marcar essa opção quando o cliente efetuar o pagamento através do Pix ou do Boleto a NFSe será emitida Automáticamente.'}
                    >
                      <InfoIcon className='info'/>
                    </Tooltip>
                  </div>
                  <div style={{float: 'left'}}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Gerar NFSe Automáticamente?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="Ativo"
                            color="primary"
                            checked={dados.gerar_nfse}
                          />
                        }
                        size="small"
                        label={dados.gerar_nfse ? 'Sim' : 'Não'}
                        name="gerar_nfse"
                        onChange={(e) => props.updateFieldGerarNfse(e)}
                      />
                    </FormControl>
                  </div>
                </React.Fragment>
              </Grid>
            }            
            <Grid item md={9} xs={12} sm={8}>
              <p className='avisoAlerta'><i>Ao alterar os dados do serviço, o financeiro abaixo tambem será alterado.</i></p>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={cabecalhoTabelaFinanceiro} 
                rows={props.financeiros_pendentes.map(parcela => {
                  return {
                    datavencimento: moment(parcela.datavencimento).format('DD/MM/YYYY'),
                    formapagto_descricao: parcela.valores_pago[0].forma_pagto.descricao,
                    situacao: parcela.situacao,
                    valorparcela: real(parcela.valorparcela),
                    multajuros: parcela.multajuros ? real(parcela.multajuros) : real(0)
                  }
                })} 
                acoes={acoesTabelaFinanceiro} />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente alterar esse serviço?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={dados.motivoInativar === "" ? true : false}>Sim</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div> 
  );
}
