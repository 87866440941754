import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'

const FormCargo = ({ dados, updateField, updateFieldAtivo, unidades, planos, updateFieldPlanoContas, planoContaSelecionado }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo do Item"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="tipo"
          size="small"
          value={dados.tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.unidade_id === ''}
        >
          <option value="">Selecionar Tipo</option>
          <option value="PAGAR">Despesa</option>
          <option value="RECEBER">Receita</option>
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldPlanoContas(event, value)}
          freeSolo
          options={planos.filter(param => !param.totalizador)}
          value={planoContaSelecionado}
          getOptionLabel={option => `${option.codigo} - ${option.descricao}`}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Plano de Contas*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${planoContaSelecionado.codigo} - ${planoContaSelecionado.descricao}`} />
          )}
        />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          id='descricao'
          className="input" 
          label="Descrição*" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={dados.descricao} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="ativo"
              color="primary"
              checked={dados.ativo}
            />
          }
          label="Ativo"
          name="ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
    <hr />
  </React.Fragment>
)

export default FormCargo