import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import Table from './../Table'
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'servico', numeric: false, disablePadding: false, label: 'Serviço' },
  { id: 'data', numeric: false, disablePadding: false, label: 'Data' },
  { id: 'alterar_atendente', numeric: false, disablePadding: false, label: 'Atendente' },
  { id: 'atendente_disponivel', numeric: false, disablePadding: false, label: 'Atendente Disponível' },
  { id: 'alterar_sala', numeric: false, disablePadding: false, label: 'Ambiente' },
  { id: 'sala_disponivel', numeric: false, disablePadding: false, label: 'Ambiente Disponível' }
]

const acoesTabela = []

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const {agendas_indisponivel, disabledSalvar} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h6 className="tituloConfirmacao">Salas/Atendentes Indisponíveis</h6>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <span>As seguintes agenda não podem ser agendadas:</span>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={cabecalhoTabela} 
                rows={agendas_indisponivel.map(agenda => {
                  return {
                    servico: agenda.servico,
                    alterar_atendente: agenda.atendente.nome,
                    alterar_sala: agenda.sala.nome,
                    data: moment(agenda.data_agenda).format('DD/MM/YYYY'),
                    atendente_disponivel: agenda.atendente_disponivel ? 'Sim': 'Não',
                    sala_disponivel: agenda.sala_disponivel ? 'Sim': 'Não',
                    atendente: agenda.atendente,
                    sala: agenda.sala,
                    atendentes: agenda.atendentes,
                    salas: agenda.salas
                  }
                })} 
                acoes={acoesTabela} 
                atendentes={props.atendentes}
                salas={props.salas}
                updateFieldSala={(event, value, row) => props.updateFieldSala(event, value, row)}
                updateFieldAtendente={(event, value, row) => props.updateFieldAtendente(event, value, row)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={6} xs={false} sm={4}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(false)} disabled={disabledSalvar}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true)} disabled={disabledSalvar}>Salvar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div> 
  );
}
