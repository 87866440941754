import React from 'react'
import { Grid, TextField, FormControlLabel, Switch, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import InputMask from "react-input-mask";
import WarningIcon from '@mui/icons-material/Warning';
import TelefoneInput from './../mask/TelefoneInput'

const FormContato = ({ dados, updateField, updateFieldPrincipal, addNovoContato, formataTelefone }) => (
  <React.Fragment>
    <Grid  container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TelefoneInput
          label="Número Telefone/Celular*"
          className="input"
          name="numero"
          value={dados.numero}
          onChange={(e) => updateField(e)}
        />
        {/* <InputMask
          mask="(99) 99999-9999"
          value={dados.numero}
          maskChar=""
          onChange={(e) => updateField(e)} 
        >
          {() =>
            <TextField className="input" label="Número*" variant="outlined" size="small" name="numero" InputLabelProps={{ shrink: true }} />
          }
        </InputMask> */}
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo Contato"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="tipoContato"
          value={dados.tipoContato}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="COMERCIAL">
            COMERCIAL
					</option>
          <option value="RESIDENCIAL">
            RESIDENCIAL
					</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="principal"
              color="primary"
              checked={dados.principal}
            />
          }
          label="Principal"
          name="principal"
          onChange={(e) => updateFieldPrincipal(e)}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <Button fullWidth onClick={() => addNovoContato()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>{dados.index === '' ? 'Adicionar Contato' : 'Alterar Contato'} </Button>
      </Grid>
    </Grid>
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <WarningIcon style={{color: 'red'}}/><span style={{marginLeft: 5, fontSize: 14}} className='error'>Esse contato ainda não foi {dados.index === '' ? 'adicionado' : 'alterado'}, para confirmar clique em "{dados.index === '' ? 'ADICIONAR CONTATO' : 'ALTERAR CONTATO'}"</span>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormContato