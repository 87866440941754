import React, { Component } from 'react'
import './Vendas.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import Alert from './../../components/templates/Alert'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import moment from 'moment';
import real from './../../services/real'
import { styled } from '@mui/system';
import Color from 'color';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { format } from 'cnpj'
import ModalAjuda from './../../components/modals/Ajuda'

const cpf = require('node-cpf-cnpj');

const initialState = {
  vendas: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'numero', numeric: false, disablePadding: true, label: 'Núm OS.' },
    { id: 'cliente', numeric: false, disablePadding: true, label: 'Cliente' },
    { id: 'data_pedido', numeric: false, disablePadding: true, label: 'Data da OS.' },
    { id: 'valor_pedido', numeric: false, disablePadding: false, label: 'Valor OS.' },
    { id: 'baixado', numeric: false, disablePadding: false, label: 'Venda Fechada' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    data_inicial: "",
    data_final: "",
    pessoa_id: "",
    nome: "",
    numero: ""
  },
  loading: true,
  mensagemLoading: 'Carregando Vendas...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  pessoas:{
    list: []
  },
  cliente: {
    pessoa_id: '',
    nome: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default class Cargo extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'venda-servico-produto')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('updateVenda')
    }

    if (permissoes.visualizar) {
      acoesTabela.push('view')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    
    let filtro_vendas =  JSON.parse(localStorage.getItem('filtro_vendas'))
    let filtro_cliente_vendas = JSON.parse(localStorage.getItem('filtro_cliente_vendas'))
    let {filtro} = this.state
    
    if(filtro_vendas){
      filtro = filtro_vendas
    }else{
      filtro.data_inicial = moment().format('YYYY-MM-DD')
      filtro.data_final = moment().add(7, 'days').format('YYYY-MM-DD')
      filtro.unidadesnegocio = acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    if(filtro_cliente_vendas){
      this.setState({
        cliente: filtro_cliente_vendas
      })
    }

    this.setState({
      filtro
    })
    
    await this.filtrar(filtro)

    this.setState({loading: false})
   
  }

  async filtrar(filtro){
    try {
      this.setState({loading: true})

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/vendas/list`, filtro, this.getToken())

      const vendas = []

      for (const venda of data) {
        if(venda.itens_pedido){
          let baixado = false

          if(venda.conta){
            for (const parcela of venda.conta.parcelas) {
              if(parcela.baixado){
                baixado = true
              }
            }
          }

          vendas.push({
            _id: venda.id,
            numero:'OS. ' + venda.numero,
            data_pedido: moment(venda.data_pedido).format('DD/MM/YYYY'),
            valor_pedido:'R$ ' + real(somarValores(venda.itens_pedido.map(value => { return value.valor_servico + (value.desconto * value.quantidade)}))),
            cliente: venda.pessoa.tipo === 'FISICA' ? venda.pessoa.fisica.nome : venda.pessoa.juridica.nomefantasia,
            cancelamento: venda.cancelamento,
            baixado
          })
        }
      }

      this.setState({
        vendas: {
          list: vendas
        },
        loading: false
      })

      localStorage.setItem('filtro_vendas', JSON.stringify(filtro));
      localStorage.setItem('filtro_cliente_vendas', JSON.stringify(this.state.cliente));

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  updateFiltro(event) {
    const {filtro} = this.state

    filtro[event.target.name] = event.target.value

    this.setState({ filtro })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateFieldPessoaFiltro(event, value){
    const {filtro} = this.state

    if(!value){

      filtro.pessoa_id = ''
      filtro.nome = ''

      this.setState({
        cliente: {
          pessoa_id: '',
          nome: '',
          cpf_cnpj: ''
        },
        filtro
      })

      return false
    } 

    filtro.pessoa_id = value.pessoa_id
    filtro.nome = value.nome

    this.setState({
      cliente: value
    })
  }

  async updateFiltroPessoa(event){
    if(event.target.value.length >= 3){
      await this.consultarPessoa(event.target.value)
    }else if(event.target.value.length < 3){
      this.setState({
        pessoas: {
          list: []
        }
      })
    }
  }

  async consultarPessoa(nome){
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listSearch`, filtroPessoa, this.getToken())

      const pessoas = data.map(pessoa => {
        return {
          pessoa_id: pessoa.id,
          nome: pessoa.nome + (pessoa.sobrenome ? ` ${pessoa.sobrenome}` : ''),
        }
      })

      this.setState({
        pessoas: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  render() {
    const { filtro, vendas, permissoes, pessoas, cliente } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid container spacing={1} direction="row" className="borderBottom">
                  <Grid item md={10} xs={12} sm={6}>
                    <h1 className="titulo">Vendas</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={2} xs={12} sm={6}>
                      <Link to="/vendas/cadastro">
                        <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar Venda
                        </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_20">
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" type="date" label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" label="Número da OS." variant="outlined" size="small" name="numero" value={filtro.numero} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={5} xs={12} sm={6}>
                    <Autocomplete
                      onChange={(event, value) => this.updateFieldPessoaFiltro(event, value)}
                      freeSolo
                      options={pessoas.list}
                      value={cliente}
                      size="small"
                      getOptionLabel={option => option.nome}
                      renderInput={(params) => (
                        <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Cliente/Fornecedor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={cliente.nome} onChange={(e) => this.updateFiltroPessoa(e)}/>
                      )}
                    />
                  </Grid>
                  <Grid item md={1} xs={12} sm={3}>
                    <Button color='warning' size='small' fullWidth variant="contained" onClick={() => this.filtrar(this.state.filtro)} disabled={this.state.loadingImprimir}>
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table 
                      urlView="/vendas/view/" 
                      urlUpdate="/vendas/cadastro/"
                      headCell={this.state.cabecalhoTabela} 
                      rows={vendas.list} 
                      acoes={this.state.acoesTabela} 
                    />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Vendas"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}