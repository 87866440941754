import React, { useState, useEffect } from 'react';
import getEndereco from './../../services/api/getEndereco'
import './Funcionario.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import FormContratoFuncionario from '../../components/forms/FormContratoFuncionario'
import FormUsuario from '../../components/forms/FormUsuario'
import FormQuemTrabalha from './FormQuemTrabalha'
import FormContaBancaria from '../../components/forms/FormContaBancaria'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import verificaForcaDaSenha from './../../services/verificaForcaSenha'
import FormPessoa from '../../components/forms/FormPessoa'
import { Link } from 'react-router-dom'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import formatarTelefone from './../../services/formatarTelefone'
import formatarCEP from './../../services/formatarCEP'
import validacaoEmail from './../../services/validacaoEmail'
import getToken from './../../services/getToken'

const cpf = require('node-cpf-cnpj');

const initialState = {
  contrato: {
    paraquem: "",
    selects_unidadetrabalho: {
      franqueador: true,
      franqueado: false,
      unidade: false
    }
  },
  franqueados: [],
  funcionario: {
    id: "",
    dataAdmissao: "",
    dataDemissao: "",
    unidadetrabalho_id: "",
    tipoPessoa: "FISICA",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "55",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    usuario: {
      email_login: "",
      senha_usuario: "",
      repetirSenha: "",
      perfil_id: ""
    },
    contas: [{
      agencia: "",
      numero: "",
      gerente: "",
      chave_pix: "",
      banco_id: "",
      index: 1,
      banco: {
        id: "",
        nome: "",
        numero: "",
        padraocosif: ""
      }
    }],
    ativo: "",
  },
  funcionarios: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  books: {
    list: []
  },
  book: {
    bok_id: "",
    bok_nome: "",
    bc_obrigatorio: true,
    bc_ativo: true,
    produto: {},
  },
  cargosFiltrado: {
    list: []
  },
  perfil: {
    id: "",
    descricao: ""
  },
  perfis: {
    list: []
  },
  disponibilidade: {
    disp_dia_semana: "",
    disp_hora_inicial: "",
    disp_hora_final: "",
    disp_data_vig_inicio: "",
    disp_data_vig_fim: "",
    index: ""
  },
  bancos: {
    list: []
  },
  enableUpdate: false,
  disableEndereco: true,
  enableBuscaFunc: true,
  enableDadosPessoa: true,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  enableDadosContrato: false,
  enableDadosUsuario: false,
  enableDadosDisponibilidade: false,
  enableDadosVigencia: false,
  enableDadosContaBancaria: false,
  enableDadosDisponibilidadeHorario: false,
  loading: true,
  mensagemLoading: 'Carregando Colaborador...',
  cabecalhoTabela: [
    { id: 'disp_dia_semana', numeric: false, disablePadding: true, label: 'Dia da Semana' },
    { id: 'disp_hora_inicial', numeric: false, disablePadding: false, label: 'Hora Inicial' },
    { id: 'disp_hora_final', numeric: false, disablePadding: false, label: 'Hora Final' }
  ],
  acoesTabela: ['editar', 'remove'],
  update: false,
  aulasSeleciondas: [],
  aulasSeleciondasFiltradas: [],
  aulas: {
    list: []
  },
  aulasFiltrada: {
    list: []
  },
  nomeAulas: [],
  showPassword: false,
  showPasswordRepetir: false,
  searchResp:{
    cpf: ''
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes: {
    list: []
  },
  openModalAjuda: false,
  markdown: '',
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: ['update', 'remover'],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
  informarEndereco: false,
  informarContato: false,
  informarEmail: false,
  endereco: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contato: {
    numero: "55",
    tipoContato: "COMERCIAL",
    principal: true,
    index: ""
  },
  email: {
    email: "",
    principal: true,
    index: ""
  },
}

const CadastroFuncionario = () => {
  const navigate = useNavigate();
  const { funcionarioId } = useParams();
  const [state, setState] = useState({ ...initialState });

  const updateFieldPessoaFisica = (event) => {
    const funcionario = { ...state.funcionario }
    funcionario.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({...prevState, funcionario }))
  }

  const updateFieldEndereco = (event, index) => {
    const {endereco} = state
    endereco[event.target.name] = event.target.value
    setState(prevState => ({...prevState, endereco }))
  }

  const updateFieldEmail = (event, index) => {
    const {email} = state
    email[event.target.name] = event.target.value
    setState(prevState => ({...prevState, email }))
  }

  const updateFieldContato = (event, index) => {
    const {contato} = state
    contato[event.target.name] = event.target.value
    setState(prevState => ({...prevState, contato }))
  }

  const updateFieldContrato = async (event) => {
    if (!event.target === undefined) return false
    const funcionario = { ...state.funcionario }

    if (event.target && event.target.name === "idDepartamento") {
      if (funcionario.contrato.departamento === event.target.value) return false

      try {
        funcionario.contrato.idDepartamento = event.target.value
        funcionario.contrato.departamento = state.departamentos.list.filter(u => u.id === parseInt(event.target.value))[0].descricao

        for (const dep of state.departamentos.list) {
          if (dep.dep_id === event.target.value)
            funcionario.contrato.departamento = dep[0].descricao
        }

        let {data: departamentos} = await axios.get(`${window._env_.REACT_APP_API_URL}/cargos/departamento/${event.target.value}`, getToken())

        setState(prevState => ({...prevState,
          funcionario,
          cargosFiltrado: {
            list: departamentos
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }
    }

    if (event.target && event.target.name === "idCargo") {
      if (funcionario.contrato.cargo === event.target.value) return false

      try {
        funcionario.contrato.idCargo = event.target.value
        funcionario.contrato.cargo = state.cargosFiltrado.list.filter(u => u.id === parseInt(event.target.value))[0].descricao

        let {data: cargos} = await axios.get(`${window._env_.REACT_APP_API_URL}/funcoes/cargo/${event.target.value}`, getToken())

        setState(prevState => ({...prevState,
          funcionario,
          funcaosFiltrado: {
            list: cargos
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }
    }

    if (event.target && event.target.name === "idFuncao") {
      if (funcionario.contrato.funcao === event.target.value) return false

      funcionario.contrato.idFuncao = event.target.value
      funcionario.contrato.funcao = state.funcaosFiltrado.list.filter(u => u.id === parseInt(event.target.value))[0].descricao
      setState(prevState => ({...prevState, funcionario }))
    }

    if (event.target && event.target.name === "dataInicial") {
      funcionario.contrato.dataInicial = event.target.value
      setState(prevState => ({...prevState, funcionario }))
    }

    if (event.target && event.target.name === "dataFinal") {
      funcionario.contrato.dataFinal = event.target.value
      setState(prevState => ({...prevState, funcionario }))
    }

    if (event.target && event.target.name === "observacao") {
      funcionario.contrato.observacao = event.target.value
      setState(prevState => ({...prevState, funcionario }))
    }
  }

  const updateFieldEnderecoPrincipal = () => {
    let { endereco, funcionario } = state
    const indexAux = funcionario.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== endereco.index)) {
      endereco.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui endereço principal'
        }
      }))
    }else{
      endereco.principal = !endereco.principal
    }

    setState(prevState => ({...prevState, endereco }))
  }

  const validarCpfCnpj = async (event) => {

    let cpf_cnpj = event.target.value
    const funcionario = state.funcionario

    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {

      funcionario.pessoaFisica.cpf = ""
      setState(prevState => ({...prevState, funcionario }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor corrija o campo CPF!'
        }
      }))

    } else {
      funcionario.pessoaFisica.cpf = cpf_cnpj
      setState(prevState => ({...prevState, funcionario }))
    }
  }

  const pegaEndereco = async (event, index) => {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const {endereco} = state

      let endereco_aux = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: endereco.numero,
        complemento: endereco.complemento,
        cep: formatarCEP(endereco.cep),
        principal: endereco.principal,
        tipoEndereco: endereco.tipoEndereco,
        index: endereco.index
      }
      
      setState(prevState => ({...prevState, endereco: endereco_aux}))

      try {
        const resp = await getEndereco(event.target.value)

        let endereco_aux = {
          cep_id: resp.id,
          complemento: endereco.complemento,
          rua: resp.logradouro,
          numero: endereco.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(endereco.cep),
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        }

        
        setState(prevState => ({...prevState, endereco: endereco_aux, disableEndereco: true }))
      } catch (error) {
        console.log(error)

        let endereco_aux = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: endereco.numero,
          cep: "",
          complemento: endereco.complemento,
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        }

        setState(prevState => ({
          ...prevState, 
          funcionario, 
          endereco: endereco_aux,
          alerta: {
            open: true,
            severity: 'error',
            message: 'CEP Não encontrado!'
          }
        }))
      }
    }
  }

  const addNovoContato = () => {
    let {contato, funcionario} = state

    let valido = true

    if (contato.numero === "") valido = false
    if (contato.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    const contatos = funcionario.contatos

    if(contato.index === ""){
      contato.index = contatos.length + 1

      contatos.push({
        ...contato
      })
    }else{
      contatos[contato.index - 1] = {
        ...contato
      }
    }

    funcionario.contatos = contatos

    setState(prevState => ({...prevState, 
      funcionario,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: false,
        index: ""
      },
      informarContato: false
    }))
  }

  const addNovoEmail = () => {
    let {email, funcionario} = state

    let valido = true

    if (email.email === "") valido = false

    if (!valido) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(email.email)) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do funcionario é inválido!'
        }
      }))
      return
    }

    const emails = funcionario.emails

    if(email.index === ""){
      email.index = emails.length + 1

      emails.push({
        ...email
      })
    }else{
      emails[email.index - 1] = {
        ...email
      }
    }

    funcionario.emails = emails

    setState(prevState => ({...prevState, 
      funcionario,
      email: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmail: false
    }))
  }

  const buscaPerfis = async () => {
    const funcionario = { ...state.funcionario }
    const selects_unidadetrabalho = { ...state.contrato.selects_unidadetrabalho }

    let tipoUnidade = null

    if (selects_unidadetrabalho.franqueador) tipoUnidade = 'MANTENEDOR'
    if (selects_unidadetrabalho.representante) tipoUnidade = 'REPRESENTANTE'
    if (selects_unidadetrabalho.franqueado) tipoUnidade = 'GRUPOECONOMICO'
    if (selects_unidadetrabalho.unidade) tipoUnidade = 'UNIDADE'

    let params = `visibilidade=${tipoUnidade}`

    if (funcionario.unidadetrabalho_id) {
      params += `&unidadetrabalho_id=${parseInt(funcionario.unidadetrabalho_id)}`
    }

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?${params}`, getToken())

      setState(prevState => ({...prevState,
        perfis: {
          list: data
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
    }
  }

  const proximo = (atual, prox) => {
    const { funcionario } = state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      if (
        funcionario.pessoaFisica.nome === "" ||
        funcionario.pessoaFisica.cpf === "" ||
        funcionario.pessoaFisica.dataNascimento === ""
      ) {
          setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        enableDadosPessoa: false,
        enableDadosEndereco: true
      }))

    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      funcionario.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.numero === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
      })

      if (!valido) {
          setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        enableDadosEndereco: false,
        enableDadosContato: true
      }))

    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      funcionario.contatos.forEach(contato => {
        if (contato.numero === "") valido = false
      })

      if (!valido) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        enableDadosContato: false,
        enableDadosEmail: true
      }))
    } else if (atual === "enableDadosEmail" && prox === "enableDadosContrato") {
      let valido = true
      funcionario.emails.forEach(email => {
        if (email.email === "") valido = false
      })

      if (!valido) {
          setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        enableDadosEmail: false,
        enableDadosContrato: true
      }))

    } else if (atual === "enableDadosContrato" && prox === "enableDadosContaBancaria") {

      if (
        funcionario.contrato.cargo === "" ||
        funcionario.contrato.dataInicial === ""
      ) {
          setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios(*).'
          }
        }))
        return
      }

      buscaPerfis()

      setState(prevState => ({...prevState,
        enableDadosContrato: false,
        enableDadosContaBancaria: true,
      }))
    } else if (atual === "enableDadosContaBancaria" && prox === "enableDadosUsuario") {

      setState(prevState => ({...prevState,
        enableDadosContaBancaria: false,
        enableDadosUsuario: true
      }))
    }
  }

  const voltar = (atual, anterior) => {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      setState(prevState => ({...prevState,
        enableDadosPessoa: true,
        enableDadosEndereco: false
      }))
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      setState(prevState => ({...prevState,
        enableDadosEndereco: true,
        enableDadosContato: false
      }))
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      setState(prevState => ({...prevState,
        enableDadosContato: true,
        enableDadosEmail: false
      }))
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosContrato") {
      setState(prevState => ({...prevState,
        enableDadosEmail: true,
        enableDadosContrato: false,
      }))
    } else if (anterior === "enableDadosContrato" && atual === "enableDadosContaBancaria") {
      setState(prevState => ({...prevState,
        enableDadosContrato: true,
        enableDadosContaBancaria: false,
      }))
    } else if (anterior === "enableDadosContaBancaria" && atual === "enableDadosUsuario") {
      setState(prevState => ({...prevState,
        enableDadosContaBancaria: true,
        enableDadosUsuario: false,
      }))
    } else if (anterior === "enableDadosContaBancaria" && atual === "enableDadosDisponibilidade") {
      setState(prevState => ({...prevState,
        enableDadosContaBancaria: true,
        enableDadosDisponibilidade: false,
      }))
    } else if (anterior === "enableDadosDisponibilidade" && atual === "enableDadosDisponibilidadeHorario") {
      setState(prevState => ({...prevState,
        enableDadosDisponibilidade: true,
        enableDadosDisponibilidadeHorario: false,
      }))
    } else if (anterior === "enableDadosDisponibilidadeHorario" && atual === "enableDadosVigencia") {
      setState(prevState => ({...prevState,
        enableDadosDisponibilidadeHorario: true,
        enableDadosVigencia: false,
      }))
    } else if (anterior === "enableDadosVigencia" && atual === "enableDadosUsuario") {
      setState(prevState => ({...prevState,
        enableDadosVigencia: true,
        enableDadosUsuario: false,
      }))
    }
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      navigate("/funcionario");
    }, timeout)
  }

  const updateFieldPerfil = (event, value) => {
    if (!value) return false

    const funcionario = { ...state.funcionario }
    funcionario.usuario.perfil_id = value.id

    setState(prevState => ({...prevState,
      funcionario,
      perfil: {
        id: value.id,
        descricao: value.descricao
      }
    }))

  }

  const updateContrato = async (event) => {
    const { funcionario, contrato } = state
    funcionario.unidadetrabalho_id = event.target.value

    let name = event.target.name
    let value = event.target.value

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?visibilidade=${contrato.paraquem}&unidadetrabalho_id=${parseInt(event.target.value)}`, getToken())

      setState(prevState => ({...prevState,
        perfis: {
          list: data
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
    }

    if(name === 'paraquem'){
      await consultaDepartamentos(parseInt(value))
    }

    setState(prevState => ({...prevState, funcionario }))
  }

  const consultaDepartamentos = async (unidade_id) => {
    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/listByUnidade/${unidade_id}`, {}, getToken())

      setState(prevState => ({...prevState,
        departamentos: {
          list: data.filter(param => param.ativo === true)
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateParaquem = async (event) => {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    
    const nivelAcesso = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo.toLowerCase()
    
    const para = event.target.value || nivelAcesso
    const funcionario = { ...state.funcionario }
    const contrato = { ...state.contrato }
    
    switch (para) {
      case 'mantenedor':
        funcionario.unidadetrabalho_id = null
        contrato.paraquem = "MANTENEDOR"
        contrato.selects_unidadetrabalho = {
          franqueador: true,
          representante: false,
          franqueado: false,
          unidade: false
        }

        try {
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/listByUnidade/1`, {}, getToken())
    
          setState(prevState => ({...prevState,
            departamentos: {
              list: data.filter(param => param.ativo === true)
            }
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
        }

        break;

      case 'representante':
        contrato.paraquem = "REPRESENTANTE"
        contrato.selects_unidadetrabalho = {
          franqueador: false,
          representante: true,
          franqueado: false,
          unidade: false
        }
        break;

      case 'grupoeconomico':
        contrato.paraquem = "GRUPOECONOMICO"
        contrato.selects_unidadetrabalho = {
          franqueador: false,
          representante: false,
          franqueado: true,
          unidade: false
        }
        break;

      case 'unidade':
        contrato.paraquem = "UNIDADE"
        contrato.selects_unidadetrabalho = {
          franqueador: false,
          representante: false,
          franqueado: false,
          unidade: true
        }
        break;

      default:
        break;
    }

    setState(prevState => ({...prevState,
      funcionario,
      contrato
    }))
  }

  const handleClickShowPassword = () => {
    setState(prevState => ({...prevState,
      showPassword: !state.showPassword
    }))
  }

  const handleClickShowPasswordRepetir = () => {
    setState(prevState => ({...prevState,
      showPasswordRepetir: !state.showPasswordRepetir
    }))
  }

  const addNovaContaBancaria = () => {
    const funcionario = { ...state.funcionario }
    const contas = funcionario.contas

    const index = contas.length + 1

    contas.push({
      agencia: "",
      numero: "",
      gerente: "",
      chave_pix: "",
      banco_id: "",
      banco: {
        banco_nome: "",
        banco_numero: "",
        banco_padraocosif: ""
      },
      index
    })

    funcionario.contas = contas
    setState(prevState => ({...prevState, funcionario }))
  }

  const updateFieldContaBancaria = (event, index) => {
    const funcionario = { ...state.funcionario }
    funcionario.contas[index - 1][event.target.name] = event.target.value
    setState(prevState => ({...prevState, funcionario }))
  }

  const updateFieldBanco = (event, value, index) => {
    if (!value) return false
    const funcionario = { ...state.funcionario }
    funcionario.contas[index - 1].banco = value
    funcionario.contas[index - 1].banco_id = value.banco_id

    setState(prevState => ({...prevState, funcionario }))
  }

  const removerConta = (index) => {
    const funcionario = { ...state.funcionario }
    const contas = funcionario.contas
    const aux = contas

    aux.splice(index - 1, 1)

    funcionario.contas = aux
    setState(prevState => ({...prevState, funcionario }))
  }

  const removeEnderecoFuncionario = (index) => {
    const funcionario = { ...state.funcionario };
    const enderecos = [...funcionario.enderecos];
    
    if (enderecos.length === 0) {
      return false; 
    }

    enderecos.splice(index - 1, 1);
    enderecos.forEach((endereco, i) => endereco.index = i + 1);

    funcionario.enderecos = enderecos;

    setState(prevState => ({...prevState,
      funcionario
    }))
  }

  const removeContatoFuncionario = (index) => {
    const funcionario = { ...state.funcionario };
    const contatos = [...funcionario.contatos];
    
    if (contatos.length === 0) {
      return null; // ou retornar algo apropriado para indicar um erro
    }

    contatos.splice(index - 1, 1);
    contatos.forEach((endereco, i) => endereco.index = i + 1);

    funcionario.contatos = contatos;

    setState(prevState => ({...prevState,
      funcionario
    }))
  }

  const removeEmailFuncionario = (index) => {
    const funcionario = { ...state.funcionario };
    const emails = [...funcionario.emails];
    
    if (emails.length === 0) {
      return false;
    }

    emails.splice(index - 1, 1);
    emails.forEach((endereco, i) => endereco.index = i + 1);

    funcionario.emails = emails;
    setState(prevState => ({...prevState,
      funcionario
    }))
  }

  const salvar = async () => {
    const { funcionario } = state
    
    let dados = {}

    let emails = []
    let telefones = []
    let enderecos = []

    funcionario.emails.forEach(email => {
      if (email.id) {
        emails.push({
          id: email.id,
          email: email.email,
          principal: email.principal
        })
      } else {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      }
    })

    funcionario.contatos.forEach(telefone => {
      if (telefone.id) {
        telefones.push({
          id: telefone.id,
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      } else {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      }
    })

    funcionario.enderecos.forEach(endereco => {
      if (endereco.id) {
        enderecos.push({
          id: endereco.id,
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: endereco.complemento === "" ? "N/A" : endereco.complemento,
          principal: endereco.principal,
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          tipo: endereco.tipoEndereco
        })
      } else {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: endereco.complemento === "" ? "N/A" : endereco.complemento,
          principal: endereco.principal,
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          tipo: endereco.tipoEndereco
        })
      }
    })

    if (funcionario.usuario.email_login !== "" || funcionario.usuario.perfil_id !== "" || funcionario.usuario.senha_usuario !== "" || funcionario.usuario.repetirSenha !== "") {
      if (funcionario.usuario.email_login === "" || funcionario.usuario.perfil_id === "") {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios'
          }
        }))
        
        setState(prevState => ({...prevState, loading: false }))
        return
      }

      if (funcionario.usuario.senha_usuario !== "" && funcionario.usuario.repetirSenha === "") {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios'
          }
        }))
        setState(prevState => ({...prevState, loading: false }))
        return
      }

      if (funcionario.usuario.senha_usuario !== funcionario.usuario.repetirSenha) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Senhas não Conferem! Por favor verificar as senhas.'
          }
        }))
        setState(prevState => ({...prevState, loading: false }))
        return
      }

      if (!validacaoEmail(funcionario.usuario.email_login)) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Email Invalido!'
          }
        }))
        setState(prevState => ({...prevState, loading: false }))
        return
      }

      dados = {
        ativo: true,
        id: funcionario.id,
        nome: funcionario.pessoaFisica.nome,
        cpf: funcionario.pessoaFisica.cpf,
        rg: funcionario.pessoaFisica.rg,
        rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
        rg_uf: funcionario.pessoaFisica.rg_uf,
        rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
        datanascimento: funcionario.pessoaFisica.dataNascimento,
        enderecos,
        emails,
        telefones,
        unidadetrabalho_id: funcionario.unidadetrabalho_id,
        funcao_id: funcionario.contrato.idFuncao,
        data_admissao: funcionario.contrato.dataInicial,
        datainicio: funcionario.contrato.dataInicial,
        observacao: funcionario.contrato.observacao,
        usuario: {
          login: funcionario.usuario.email_login,
          senha: funcionario.usuario.senha_usuario,
          perfil_id: funcionario.usuario.perfil_id,
          id: funcionario.usuario.id
        }
      }

      if (dados.usuario.senha === '') {
        delete dados.usuario.senha
      }

      if (dados.usuario.id === "" || !dados.usuario.id) {
        delete dados.usuario.id
      }
      
    } else {
      dados = {
        ativo: true,
        id: funcionario.id,
        nome: funcionario.pessoaFisica.nome,
        cpf: funcionario.pessoaFisica.cpf,
        rg: funcionario.pessoaFisica.rg,
        rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
        rg_uf: funcionario.pessoaFisica.rg_uf,
        rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
        datanascimento: funcionario.pessoaFisica.dataNascimento,
        enderecos,
        emails,
        telefones,
        unidadetrabalho_id: funcionario.unidadetrabalho_id,
        funcao_id: funcionario.contrato.idFuncao,
        data_admissao: funcionario.contrato.dataInicial,
        datainicio: funcionario.contrato.dataInicial,
        observacao: funcionario.contrato.observacao
      }
    }

    if (dados.rg_orgao_emissor === "" || !dados.rg_orgao_emissor) {
      delete dados.rg_orgao_emissor
    }

    if (dados.rg_uf === "" || !dados.rg_uf) {
      delete dados.rg_uf
    }

    if (dados.rg_data_emissao === "" || !dados.rg_data_emissao) {
      delete dados.rg_data_emissao
    }

    if(dados.id === ''){
      delete dados.id
    }

    if(dados.observacao === '' || !dados.observacao){
      delete dados.observacao
    }

    const contas = funcionario.contas
    let contasAux = []

    contas.forEach(conta => {
      if (conta.agencia !== "" && conta.numero !== "" && conta.banco_id !== "") {

        const aux = {
          id: conta.id ? conta.id : "",
          numero: conta.numero,
          gerente: conta.gerente,
          agencia: conta.agencia,
          chave_pix: conta.chave_pix,
          banco_id: conta.banco_id
        }

        if (!conta.id) {
          delete aux.id
        }

        if (conta.gerente === "") {
          delete aux.gerente
        }

        if (conta.chave_pix === "") {
          delete aux.chave_pix
        }

        contasAux.push(aux)
      }
    });

    if (contasAux.length !== 0) {
      dados.contas = contasAux
    }

    if(dados.observacao === ''){
      delete dados.observacao
    }

    setState(prevState => ({...prevState, 
      loading: true,
      mensagemLoading: 'Cadastrando/Alterando Colaborador'
    }))

    try {
      if (funcionario.id !== "") {
        await axios.put(`${window._env_.REACT_APP_API_URL}/funcionarios/${funcionario.id}`, dados, getToken())
      } else {
        await axios.post(`${window._env_.REACT_APP_API_URL}/funcionarios`, dados, getToken())
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))
      
      backPage()
      return
    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    } 
    
  }

  const updateFieldSearchFunc = (event) => {
    const {searchResp} = state

    searchResp[event.target.name] = event.target.value

    setState(prevState => ({...prevState,searchResp}))
  }

  const buscarFunc = async () => {
    const { searchResp } = state

    const buscaPor = 'cpf'
    const search = searchResp.cpf

    if (!cpf.isValid(cpf.format(searchResp.cpf))) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor corrija o campo CPF'
        }
      }))
      return
    }

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/search/${search}`, { buscaPor, busca: 'funcionario' }, getToken())
      
      let funcionario = null
      if(data){
        funcionario = {
          id: "",
          dataAdmissao: "",
          dataDemissao: "",
          unidadetrabalho_id: "",
          pessoa_id: data.id,
          tipoPessoa: "FISICA",
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: data.nome,
            cpf: cpf.format(data.cpf),
            rg: data.rg ? data.rg : '',
            rg_orgao_emissor: data.rg_orgao_emissor ? data.rg_orgao_emissor : '',
            rg_uf: data.rg_uf ? data.rg_uf : '',
            rg_data_emissao: data.rg_data_emissao ? data.rg_data_emissao : '',
            dataNascimento: data.datanascimento
          },
          enderecos: data.pessoa.enderecos.map((end, key) => {
            return {
              id: end.id,
              cep_id: end.cep_id,
              complemento: end.complemento,
              rua: end.cep.logradouro,
              numero: end.numero,
              bairro: end.cep.bairro.nome,
              cidade: end.cep.bairro.cidade.nome,
              estado: end.cep.bairro.cidade.uf.descricao,
              cep: end.cep.cep,
              principal: end.principal,
              tipoEndereco: end.tipo,
              index: key + 1
            }
          }),
          emails: data.pessoa.emails.map((ema, key) => {
            return {
              id: ema.id,
              email: ema.email,
              principal: ema.principal,
              index: key + 1
            }
          }),
          contatos:data.pessoa.telefones.map((tel, key) => {
            return {
              id: tel.id,
              numero: tel.numero,
              tipoContato: tel.tipo,
              principal: tel.principal,
              index: key + 1
            }
          }),
          usuario: {
            email_login: "",
            senha_usuario: "",
            repetirSenha: "",
            perfil_id: ""
          },
          contas: [{
            agencia: "",
            numero: "",
            gerente: "",
            chave_pix: "",
            banco_id: "",
            index: 1,
            banco: {
              banco_id: "",
              banco_nome: "",
              banco_numero: "",
              banco_padraocosif: ""
            }
          }],
          ativo: "",
        }
      }else{
        funcionario = {
          id: "",
          pessoa_id: "",
          dataAdmissao: "",
          dataDemissao: "",
          unidadetrabalho_id: "",
          tipoPessoa: "FISICA",
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: "",
            cpf: cpf.format(searchResp.cpf),
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: ""
          },
          enderecos: [],
          emails: [],
          contatos: [],
          usuario: {
            email_login: "",
            senha_usuario: "",
            repetirSenha: "",
            perfil_id: ""
          },
          contas: [{
            agencia: "",
            numero: "",
            gerente: "",
            chave_pix: "",
            banco_id: "",
            index: 1,
            banco: {
              banco_id: "",
              banco_nome: "",
              banco_numero: "",
              banco_padraocosif: ""
            }
          }],
          ativo: "",
        }
      }
      setState(prevState => ({...prevState, funcionario, enableBuscaFunc: false }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    } 
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const informarEndereco = () => {
    const { funcionario } = state;
    let principal = !funcionario.enderecos.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarEndereco: true,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }))
  }

  const editarEndereco = (row) => {
    let {funcionario} = state

    setState(prevState => ({...prevState,
      endereco: {...funcionario.enderecos[row.index - 1]},
      informarEndereco: true
    }))
  }

  const informarContato = () =>{
    const { funcionario } = state;
    let principal = !funcionario.contatos.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarContato: true,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal,
        index: ""
      },
    }))
  }

  const editarContato = (row) =>{
    let {funcionario} = state

    setState(prevState => ({...prevState,
      contato: {...funcionario.contatos[row.index - 1]},
      informarContato: true
    }))
  }

  const editarEmail = (row) => {
    let {funcionario} = state

    setState(prevState => ({...prevState,
      email: {...funcionario.emails[row.index - 1]},
      informarEmail: true
    }))
  }

  const informarEmail = () => {
    const { funcionario } = state;
    let principal = !funcionario.emails.some(param => param.principal);

    setState(prevState => ({...prevState,
      informarEmail: true,
      email: {
        email: "",
        principal,
        index: ""
      },
    }))
  }

  const validarDadosPessoa = async () =>{
    const {funcionario, dadosComplementaresPF} = state

    if (
      funcionario.pessoaFisica.nome === ""
    ) {
      return {
        valido: false,
        mensagem: 'Por favor informar o nome do funcionario.'
      }
    }

    if (
      funcionario.pessoaFisica.dataNascimento === ""
    ) {
      return {
        valido: false,
        mensagem: 'Por favor informar a data de nascimento do funcionario.'
      }
    }

    if (
      funcionario.pessoaFisica.cpf === ""
    ) {
      return {
        valido: false,
        mensagem: 'Por favor informar o CPF do funcionario.'
      }
    }

    if(funcionario.pessoaFisica.cpf !== ''){
      let cpf_cnpj = funcionario.pessoaFisica.cpf

      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {
        if(cpf_cnpj !== '000.000.000-00'){
          return {
            valido: false,
            mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
          }
        }
      }
    }

    setState(prevState => ({...prevState, funcionario }))

    // for (const item of dadosComplementaresPF.list) {
    //   if(item.obrigatorio){
    //     if(dadosComplementaresPF[item.column_name] === '' || !dadosComplementaresPF[item.column_name]){
    //       return {
    //         valido: false,
    //         mensagem: `Por favor informar o(a) ${item.column_comment}`
    //       }
    //     }
    //   }
    // }

    if(funcionario.enderecos.length > 0){
      let valido = true
      funcionario.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })
  
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
  
      if(funcionario.enderecos.length === 0 && funcionario.id){
        try {
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/verificafuncionario/${funcionario.pessoa_id}`, getToken())
          
          if(data){
            return {
              valido: false,
              mensagem: `Para esse funcionario foram gerados contratos com emissão de nfse, por favor informar o endereço do responsável.`
            }
          }
  
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }        
      }
    }
    
    if(funcionario.contatos.length > 0){
      funcionario.contatos.forEach(contato => {
        if (contato.numero === "") {
          return {
            valido: false,
            mensagem: `Por favor informar o contato do funcionario.`
          }
        }
      })
    }

    if(funcionario.emails.length > 0){
      let valido = true
      funcionario.emails.forEach(email => {
        if (email.email === "") valido = false
      })
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    setState(prevState => ({...prevState,
      funcionario
    }))

    return {
      valido: true,
      mensagem: ''
    }
  }

  const informarContrato = async () => {
    const {funcionario} = state

    let validacao = await validarDadosPessoa()

    if(!validacao.valido){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: validacao.mensagem
        }
      }))
      return
    }

    funcionario.dataAdmissao = moment().format('YYYY-MM-DD')
    funcionario.contrato.dataInicial = moment().format('YYYY-MM-DD')

    setState(prevState => ({...prevState,
      funcionario,
      enableDadosPessoa: false,
      enableDadosContrato: true
    }))
  }

  const voltarDadosPessoais = () => {
    setState(prevState => ({...prevState,
      enableDadosPessoa: true,
      enableDadosContrato: false
    }))
  }

  const addNovoEndereco = () => {
    const { endereco, funcionario } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        endereco.cep !== "" &&
        endereco.rua !== "" &&
        endereco.bairro !== "" &&
        endereco.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }))
      return;
    }
  
    // Crie uma cópia dos endereços do funcionario
    const novosEnderecos = [...funcionario.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (endereco.index === "") {
      endereco.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...endereco });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[endereco.index - 1] = { ...endereco };
    }
  
    // Atualize os endereços do funcionario
    funcionario.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({...prevState,
      funcionario,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEndereco: false,
      alerta: { open: false } // Feche o alerta após adicionar o novo endereço
    }))
  }

  const updateFieldContatoPrincipal = () => {
    let { contato, funcionario } = state
    const indexAux = funcionario.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contato.index)) {
      contato.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui contato principal'
        }
      }))
    }else{
      contato.principal = !contato.principal
    }

    setState(prevState => ({...prevState, contato }))
  }

  const updateFieldEmailPrincipal = () => {
    let { email, funcionario } = state
    const indexAux = funcionario.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== email.index)) {
      email.principal = false

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui endereço principal'
        }
      }))
    }else{
      email.principal = !email.principal
    }

    setState(prevState => ({...prevState, email }))
  }

  const updateFieldUsuario = async (event) => {
    const {funcionario} =state

    funcionario.usuario[event.target.name] = event.target.value

    if (event.target.name === 'senha_usuario') {
      const resp = await verificaForcaDaSenha(event.target.value)
      setState(prevState => ({...prevState,
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      }))
    }

    setState(prevState => ({...prevState, funcionario }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/funcionario/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        searchResp:{
          cpf: ''
        }
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'funcionario')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (funcionarioId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      setState(prevState => ({...prevState,
        contrato: {
          paraquem: "",
          selects_unidadetrabalho: {
            franqueador: true,
            franqueado: false,
            unidade: false
          }
        },
        funcionario: {
          id: "",
          dataAdmissao: "",
          dataDemissao: "",
          unidadetrabalho_id: "",
          tipoPessoa: "FISICA",
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: "",
            cpf: "",
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: ""
          },
          enderecos: [],
          emails: [],
          contatos: [],
          usuario: {
            email_login: "",
            senha_usuario: "",
            repetirSenha: "",
            perfil_id: ""
          },
          contas: [{
            agencia: "",
            numero: "",
            gerente: "",
            chave_pix: "",
            banco_id: "",
            index: 1,
            banco: {
              banco_id: "",
              banco_nome: "",
              banco_numero: "",
              banco_padraocosif: ""
            }
          }],
          ativo: "",
        },
      }))

      let funcionario = null

      if (funcionarioId) {
        try {
          setState(prevState => ({...prevState, enableUpdate: true }))
          const { data: respFuncionario } = await axios.get(`${window._env_.REACT_APP_API_URL}/funcionarios/${funcionarioId}`, getToken())

          const auxEnderecos = respFuncionario.fisica.pessoa.enderecos
          const auxContatos = respFuncionario.fisica.pessoa.telefones
          const auxEmails = respFuncionario.fisica.pessoa.emails
          const auxContas = respFuncionario.fisica.pessoa.contas

          let enderecos = []
          let contatos = []
          let emails = []
          let contas = []

          for (let i = 0; i < auxEnderecos.length; i++) {
            enderecos.push({
              id: auxEnderecos[i].id,
              cep_id: auxEnderecos[i].cep_id,
              complemento: auxEnderecos[i].complemento,
              rua: auxEnderecos[i].logradouro,
              numero: auxEnderecos[i].numero,
              bairro: auxEnderecos[i].bairro,
              cidade: auxEnderecos[i].cidade,
              estado: auxEnderecos[i].estado,
              cep: formatarCEP(auxEnderecos[i].num_cep),
              principal: auxEnderecos[i].principal,
              tipoEndereco: auxEnderecos[i].tipo,
              index: i + 1
            })
          }

          for (let i = 0; i < auxContatos.length; i++) {
            contatos.push({
              id: auxContatos[i].id,
              numero: formatarTelefone(auxContatos[i].numero),
              tipoContato: auxContatos[i].tipo,
              principal: auxContatos[i].principal,
              index: i + 1
            })
          }

          for (let i = 0; i < auxEmails.length; i++) {
            emails.push({
              id: auxEmails[i].id,
              email: auxEmails[i].email,
              principal: auxEmails[i].principal,
              index: i + 1
            })
          }

          for (let i = 0; i < auxContas.length; i++) {
            contas.push({
              id: auxContas[i].id,
              agencia: auxContas[i].agencia,
              numero: auxContas[i].numero,
              gerente: auxContas[i].gerente ? auxContas[i].gerente : "",
              chave_pix: auxContas[i].chave_pix ? auxContas[i].chave_pix : "",
              banco_id: auxContas[i].banco_id,
              index: i + 1,
              banco: {
                banco_id: auxContas[i].banco.banco_id,
                banco_nome: auxContas[i].banco.banco_nome,
                banco_numero: auxContas[i].banco.banco_numero,
                banco_padraocosif: auxContas[i].banco.banco_padraocosif
              }
            })
          }

          if (respFuncionario.contrato.unidadetrabalho.tipo === 'MANTENEDOR') {
            setState(prevState => ({...prevState,
              contrato: {
                paraquem: respFuncionario.contrato.unidadetrabalho.tipo,
                selects_unidadetrabalho: {
                  franqueador: true,
                  representante: false,
                  franqueado: false,
                  unidade: false
                }
              }
            }))
          } else if (respFuncionario.contrato.unidadetrabalho.tipo === 'REPRESENTANTE') {
            setState(prevState => ({...prevState,
              contrato: {
                paraquem: respFuncionario.contrato.unidadetrabalho.tipo,
                selects_unidadetrabalho: {
                  franqueador: false,
                  representante: true,
                  franqueado: false,
                  unidade: false
                }
              }
            }))
          }else if (respFuncionario.contrato.unidadetrabalho.tipo === 'GRUPOECONOMICO') {
            setState(prevState => ({...prevState,
              contrato: {
                paraquem: respFuncionario.contrato.unidadetrabalho.tipo,
                selects_unidadetrabalho: {
                  franqueador: false,
                  representante: false,
                  franqueado: true,
                  unidade: false
                }
              }
            }))
          } else if (respFuncionario.contrato.unidadetrabalho.tipo === 'UNIDADE') {
            setState(prevState => ({...prevState,
              contrato: {
                paraquem: respFuncionario.contrato.unidadetrabalho.tipo,
                selects_unidadetrabalho: {
                  franqueador: false,
                  representante: false,
                  franqueado: false,
                  unidade: true
                }
              }
            }))
          }

          funcionario = {
            id: respFuncionario.id,
            pessoa_fisica_id: respFuncionario.fisica.id,
            dataAdmissao: moment(respFuncionario.data_admissao).format('YYYY-MM-DD'),
            dataDemissao: respFuncionario.data_demissao ? moment(respFuncionario.data_demissao).format('YYYY-MM-DD') : "",
            unidadetrabalho_id: respFuncionario.contrato.unidadetrabalho.id,
            tipoPessoa: "FISICA",
            contrato: {
              id: respFuncionario.contrato.id,
              departamento: respFuncionario.contrato.funcao.cargo.departamento.descricao,
              idDepartamento: respFuncionario.contrato.funcao.cargo.departamento_id,
              cargo: respFuncionario.contrato.funcao.cargo.descricao,
              idCargo: respFuncionario.contrato.funcao.cargo.id,
              funcao: respFuncionario.contrato.funcao.descricao,
              idFuncao: respFuncionario.contrato.funcao.id,
              dataInicial: moment(respFuncionario.contrato.datainicio).format('YYYY-MM-DD'),
              dataFinal: moment(respFuncionario.contrato.datafinal).format('YYYY-MM-DD'),
              observacao: respFuncionario.contrato.observacao
            },
            pessoaFisica: {
              nome: respFuncionario.fisica.nome,
              cpf: cpf.format(respFuncionario.fisica.cpf),
              rg: respFuncionario.fisica.rg,
              rg_orgao_emissor: respFuncionario.fisica.rg_orgao_emissor,
              rg_uf: respFuncionario.fisica.rg_uf,
              rg_data_emissao: respFuncionario.fisica.rg_data_emissao ? moment(respFuncionario.fisica.rg_data_emissao).format('YYYY-MM-DD') : "",
              dataNascimento: moment(respFuncionario.fisica.datanascimento).format('YYYY-MM-DD')
            },
            enderecos,
            emails,
            contatos,
            ativo: respFuncionario.status,
            contas: contas.length > 0 ? contas : initialState.funcionario.contas,
            usuario: {
              email_login: respFuncionario.fisica.pessoa.usuario ? respFuncionario.fisica.pessoa.usuario.login : "",
              senha_usuario: "",
              repetirSenha: "",
              perfil_id: respFuncionario.fisica.pessoa.usuario && respFuncionario.fisica.pessoa.usuario.perfil ? respFuncionario.fisica.pessoa.usuario.perfil.id : "",
              id: respFuncionario.fisica.pessoa.usuario ? respFuncionario.fisica.pessoa.usuario.id : ""
            },
          }

          setState(prevState => ({...prevState,
            funcionario,
            perfil: {
              id: respFuncionario.fisica.pessoa.usuario && respFuncionario.fisica.pessoa.usuario.perfil ? respFuncionario.fisica.pessoa.usuario.perfil.id : '',
              descricao: respFuncionario.fisica.pessoa.usuario && respFuncionario.fisica.pessoa.usuario.perfil ? respFuncionario.fisica.pessoa.usuario.perfil.descricao : ''
            },
            enableBuscaFunc: false
          }))

        } catch (error) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
          return false
        }

        try {

          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/listByUnidade/${funcionario.unidadetrabalho_id}`, {}, getToken())

          setState(prevState => ({...prevState,
            departamentos: {
              list: data.filter(param => param.ativo === true)
            }
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }

      } else {
        updateParaquem({
          target: { value: null }
        })
      }

      try {
        const { data: bancos } = await axios.get(`${window._env_.REACT_APP_API_URL}/bancos`, getToken())

        setState(prevState => ({...prevState,
          bancos: {
            list: bancos
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      if(dados.unidadesnegocio.length === 1){
        await consultaDepartamentos(dados.unidadesnegocio)
      }

      setState(prevState => ({...prevState,
        loading: false
      }))

    };
    
    fetchData();
  }, []);

  const { funcionario, enableUpdate, enableBuscaFunc, conta } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Funcionários</h1>
                </Grid>
              </Grid>

              {state.enableDadosPessoa &&
                <React.Fragment>
                  {state.enableBuscaFunc &&
                    <React.Fragment>
                        <Grid container direction="row" className="mg_top_20">
                          <Grid item md={12}>
                            <p>Por favor informar o CPF do colaborador</p>
                          </Grid>
                        </Grid>
                      <Grid container direction="row" spacing={1}>
                        <Grid item md={3} xs={12} sm={6}>
                          <TextField className="input" label="CPF" variant="outlined" size="small" name="cpf" value={state.searchResp.cpf} onChange={(e) => updateFieldSearchFunc(e)} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item md={1} xs={12} sm={3}>
                          <Button fullWidth onClick={() => buscarFunc()} color="primary" variant="contained" size="small">Buscar</Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
              }

              {(state.enableDadosPessoa && !state.enableBuscaFunc) &&
                <FormPessoa
                  pessoa={funcionario}
                  informarEndereco={state.informarEndereco}
                  endereco={state.endereco}
                  informarContato={state.informarContato}
                  contato={state.contato}
                  informarEmail={state.informarEmail}
                  email={state.email}
                  updateFieldPessoaFisica={(e) => updateFieldPessoaFisica(e)}
                  validarCpfCnpj={(e) => validarCpfCnpj(e)}
                  dadosComplementaresPF={state.dadosComplementaresPF}
                  metodos_divugacoes={state.metodos_divugacoes.list}
                  informarNovoEndereco={() => informarEndereco()}
                  pegaEndereco={e => pegaEndereco(e)}
                  updateFieldEndereco={e => updateFieldEndereco(e)} 
                  updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipal(e)} 
                  addNovoEndereco={(e) => addNovoEndereco(e)}
                  cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                  editarEndereco={(e) => editarEndereco(e)}
                  removeEndereco={(e) => removeEnderecoFuncionario(e)}
                  informarNovoContato={() => informarContato()}
                  updateFieldContato={e => updateFieldContato(e)} 
                  updateFieldContatoPrincipal={e => updateFieldContatoPrincipal(e)} 
                  addNovoContato={() => addNovoContato()}
                  editarContato={(e) => editarContato(e)}
                  removeContato={(e) => removeContatoFuncionario(e)}
                  informarNovoEmail={() => informarEmail()}
                  updateFieldEmail={e => updateFieldEmail(e)} 
                  updateFieldEmailPrincipal={e => updateFieldEmailPrincipal(e)}
                  addNovoEmail={() => addNovoEmail()}
                  editarEmail={(e) => editarEmail(e)}
                  removeEmail={(index) => removeEmailFuncionario(index)}
                  acoesTabelaEndereco={state.acoesTabelaEndereco}
                  cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                  cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
                />
              }

              {state.enableDadosPessoa &&
                <React.Fragment>
                  <br />
                  <hr />

                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    {enableBuscaFunc &&
                      <Grid item md={2} xs={12} sm={3}>
                        <Link to={'/funcionario'}>
                          <Button color='warning' fullWidth size='small' variant="contained" startIcon={<ArrowBackIcon />}>
                            Voltar Listagem
                          </Button>
                        </Link>
                      </Grid>
                    }
                    {!enableBuscaFunc &&
                      <Grid item md={2} xs={12} sm={3}>
                        <Link to={'/funcionario'}>
                          <Button color='warning' fullWidth size='small' variant="contained" startIcon={<ArrowBackIcon />}>
                            Voltar
                          </Button>
                        </Link>
                      </Grid>
                    }
                    
                    <Grid item md={8} xs={false} sm={6}></Grid>
                    {!enableBuscaFunc &&
                      <Grid item md={2} xs={12} sm={3}>
                        <Button color='success' fullWidth onClick={() => informarContrato()} variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Informar Contrato</Button>
                      </Grid>
                    }
                  </Grid>
                </React.Fragment>
              }


              {state.enableDadosContrato &&
                <div>
                  <div className="form">
                    <FormQuemTrabalha
                      selects_unidadetrabalho={state.contrato.selects_unidadetrabalho}
                      updateParaquem={e => updateParaquem(e)}
                      updateContrato={e => updateContrato(e)}
                      dados={state.contrato}
                      unidadetrabalho_id={funcionario.unidadetrabalho_id}
                      enableUpdate={enableUpdate} />
                  </div>
                  <div className="formFranqueado">
                    <Grid container direction="row">
                      <Grid item md={12}>
                        <h4 className="titulo">Dados do Contrato</h4>
                      </Grid>
                    </Grid>
                    <FormContratoFuncionario
                      enableUpdate={enableUpdate}
                      departamentos={state.departamentos.list}
                      cargos={state.cargosFiltrado.list}
                      funcaos={state.funcaosFiltrado.list}
                      updateField={e => updateFieldContrato(e)}
                      dados={funcionario.contrato} />
                    <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                      <Grid item md={6}></Grid>
                      <Grid item md={3}>
                        <Button fullWidth onClick={() => voltarDadosPessoais()} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                      </Grid>
                      <Grid item md={3}>
                        <Button fullWidth onClick={() => proximo("enableDadosContrato", "enableDadosContaBancaria")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              }

              {state.enableDadosContaBancaria &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h4 className="titulo">Dados das Contas Bancarias</h4>
                    </Grid>
                  </Grid>
                  {funcionario.contas.map(conta => {
                    return (
                      <div key={conta.index} className="cardEndereco">
                        <FormContaBancaria
                          updateField={e => updateFieldContaBancaria(e, conta.index)}
                          updateFieldBanco={(event, value, index) => updateFieldBanco(event, value, conta.index)}
                          dados={conta}
                          banco={conta.banco}
                          bancos={state.bancos.list}
                          removerConta={(index) => removerConta(index)} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => addNovaContaBancaria()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Nova Conta </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => voltar("enableDadosContaBancaria", "enableDadosContrato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <React.Fragment>
                        <Button fullWidth onClick={() => proximo("enableDadosContaBancaria", "enableDadosUsuario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                      </React.Fragment>
                    </Grid>
                  </Grid>
                </div>
              }

              {state.enableDadosUsuario &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h4 className="titulo">Dados do Usuário</h4>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_20">
                    <Grid item md={4} xs={12} sm={6}>
                      <Autocomplete
                        onChange={(event, value) => updateFieldPerfil(event, value)}
                        freeSolo
                        options={state.perfis.list}
                        value={state.perfil}
                        getOptionLabel={option => option.descricao}
                        renderInput={(params) => (
                          <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={state.perfil.descricao} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <FormUsuario
                    updateField={e => updateFieldUsuario(e)}
                    dados={funcionario.usuario}
                    handleClickShowPassword={e => handleClickShowPassword(e)}
                    handleClickShowPasswordRepetir={e => handleClickShowPasswordRepetir(e)}
                    showPassword={state.showPassword}
                    showPasswordRepetir={state.showPasswordRepetir}
                    nivelSenha={state.nivelSenha}
                    corSenha={state.corSenha}
                  />
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={6}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => voltar("enableDadosUsuario", "enableDadosContaBancaria")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
              <br />
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Funcionario"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroFuncionario