import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '80%',
  marginLeft: '10%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));


export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={11} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Selecionar Logo</h5>
            </Grid>
            {props.loading && 
              <Grid item md={1} xs={2} sm={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            {props.imagens.map(img => {
              return (
                <Grid item md={2} xs={12} sm={3} 
                  className={props.configuracao.midia_logo_id === img.midia_id ? 'logo_selecionado' : 'logo_nao_selecionada'}
                  onClick={e => props.selecionarLogo(img)}
                  style={{textAlign: 'right', maxHeight: '220px', overflow: 'auto'}}
                >
                  <img src={`${window._env_.REACT_APP_API_URL}/static/${img.midia.key}`} alt="Imagem" />
                  <span style={{fontSize:'10px'}}>{img.midia.dimensoes} </span>
                </Grid>
              )
            })}
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={9} xs={12} sm={8}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.handleClose()}>Voltar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
