import React, { useState, useEffect } from 'react';
import './Unidade.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Loading from './../../components/templates/Loading'
import TabViews from './TabViews'
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  unidade: {},
  locaisEstoques: [],
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  mensagemLoading: 'Carregando Dados da Unidade...',
  unin_tipo: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoes_migracao: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  }
}

const View = () => {
  const [state, setState] = useState({ ...initialState });
  const { unidadeId, tab } = useParams();
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/unidade");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'unidade')[0]

      setState(prevState => ({
        ...prevState,
        permissoes
      }));

      if (!permissoes.visualizar) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }));

        backPage()
      }

      let permissoes_migracao = perfil.permissoes.filter(param => param.tela.modulo.slug === 'migracao-dados')[0]

      if(permissoes_migracao){
        setState(prevState => ({
          ...prevState,
          permissoes_migracao
        }));
      }

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

      setState(prevState => ({
        ...prevState,
        unin_tipo
      }));

      try {

        const { data: unidade } = await axios.get(`${window._env_.REACT_APP_API_URL}/unidades/${unidadeId}`, getToken())

        setState(prevState => ({
          ...prevState,
          unidade: unidade,
          loading: false
        }));

      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }));
        backPage()
      }
    };

    fetchData();

    
  }, []);

  const { unidade, locaisEstoques } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main >
            <div>
              <TabViews 
                unidade={unidade} 
                locaisEstoques={locaisEstoques} 
                unin_tipo={state.unin_tipo}
                navigate={navigate}
                params={unidadeId}
                tab={tab}
                permissoes_migracao={state.permissoes_migracao}
              />
            </div>
          </Main>
          <Nav />
          <Alert 
            open={state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
  
}

export default View
