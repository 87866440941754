import React, { useState, useEffect } from 'react';
import './PlanoContas.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormPlanoContas from '../../components/forms/FormPlanoContas'
import SaveIcon from '@mui/icons-material/Save'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  planoContas: {
    descricao: "",
    codigo: "",
    nivel_superior_id: "",
    unidade_id: "",
    ativo: true,
    totalizador: false,
    tipo: '',
    nivel: 1,
    sequencia: 1,
    permite_centro_custo: false,
    uso_centro_custo: false,
    centro_custos: []
  },
  planosContas: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  update: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidade: {
    id: "",
    descricao: "",
    numero: ""
  },
  unidades:{
    list: []
  },
  departamentos: {
    list: []
  },
  centros_custo: {
    list: []
  },
  centro_custo:{
    id: '',
    departamento_id: '',
    departamento: '',
    centrocusto_id: '',
    centrocusto: '',
    valor_percentual: 0,
    ativo: true,
    index: ''
  },
  updateCentro: false,
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const CadastroPlanoContas = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { planoContasId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = async (event) => {
    const planoContas = { ...state.planoContas }
    const planosContas = state.planosContas

    if (event.target.name === 'nivel_superior_id') {
      const plano = planosContas.list.filter(param => param.id === parseInt(event.target.value))
      const planoInferior = planosContas.list.filter(param => param.nivel_superior_id === parseInt(event.target.value))

      const codigo = planoInferior.length + 1

      if (codigo < 10) {
        planoContas.codigo = `${plano[0].codigo}.0${codigo}`
      } else {
        planoContas.codigo = `${plano[0].codigo}.${codigo}`
      }

      planoContas.nivel = plano[0].nivel + 1
      planoContas.sequencia = codigo

      planoContas.nivel_superior_id = parseInt(event.target.value)

      setState(prevState => ({...prevState, planoContas }))
    } else {
      planoContas[event.target.name] = event.target.value
      setState(prevState => ({...prevState, planoContas }))
    }


    if(event.target.name === 'unidade_id'){
      await consultarPlanos(parseInt(event.target.value))
    }
  }

  const consultarPlanos = async (unidade_id) =>{
    try {
    
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data: planosContas } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/list`, dados,getToken())

      const { planoContas } = state
      const planos = planosContas.filter(param => param.nivel_superior_id === null)

      const codigo = planos.length + 1

      if (codigo < 10) {
        planoContas.codigo = `0${codigo}`
      } else {
        planoContas.codigo = `${codigo}`
      }

      planoContas.sequencia = codigo

      setState(prevState => ({...prevState,
        planoContas,
        planosContas: {
          list: planosContas
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldAtivo = () => {
    const planoContas = { ...state.planoContas }
    planoContas.ativo = !planoContas.ativo
    setState(prevState => ({...prevState, planoContas }))
  }

  const updateFieldTotalizador = () => {
    const planoContas = { ...state.planoContas }
    planoContas.totalizador = !planoContas.totalizador
    setState(prevState => ({...prevState, planoContas }))
  }

  const updateFieldUnidade = (event, value) => {
    if (!value) return false
    const planoContas = { ...state.planoContas }
    planoContas.unidade_id = value.id

    setState(prevState => ({...prevState,
      unidade: {
        id: value.id,
        descricao: value.descricao,
        numero: value.numero
      },
      planoContas
    }))
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      navigate("/plano_contas");
    }, timeout)
  }

  const validateForm = () => {
    const { planoContas } = state

    if (!planoContas.descricao) return false
    if (!planoContas.codigo) return false
    if (!planoContas.unidade_id) return false
    if (!planoContas.tipo) return false

    return true
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldCentroCusto = (event) =>{
    const {centro_custo, departamentos, centros_custo} = state
    centro_custo[event.target.name] = event.target.value

    if(event.target.name === 'departamento_id'){
      
      const departamento = departamentos.list.filter(param => param.id === parseInt(event.target.value))[0]
      setState(prevState => ({...prevState,
        centros_custo: {
          list: departamento.centro_custos
        }
      }))

      centro_custo.departamento = departamento.descricao
    }

    if(event.target.name === 'centrocusto_id'){
      const centro = centros_custo.list.filter(param => param.id === parseInt(event.target.value))[0]
  
      centro_custo.centrocusto = centro.descricao
    }
    
    setState(prevState => ({...prevState,centro_custo}))
  }

  const validateCentroCusto = () => {
    const centro_custo = state.centro_custo

    if (!centro_custo.centrocusto_id) return false
    if (centro_custo.valor_percentual === '' || centro_custo.valor_percentual <= 0) return false

    return true
  }

  const addCentroCusto = () => {
    if (!validateCentroCusto()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    const planoContas = state.planoContas
    const centro_custo = state.centro_custo

    if(centro_custo.valor_percentual > 100){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "O valor de rateio não pode ser superior a 100%."
        }
      }))
      return
    }

    const itens = planoContas.centro_custos

    if (centro_custo.index !== '') {
      const index = itens.findIndex(param => param.index === centro_custo.index)
      
      let total = somarValores(planoContas.centro_custos.filter(param => param.id !== centro_custo.id && param.ativo).map(centro => { return parseFloat(centro.valor_percentual)}))

      total += parseInt(centro_custo.valor_percentual)

      if(total > 100){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor total do rateio não pode ser superior a 100%.'
          }
        }))
        return
      }

      itens[index] = {
        id: centro_custo.id,
        departamento_id: centro_custo.departamento_id,
        departamento:centro_custo.departamento,
        centrocusto_id: centro_custo.centrocusto_id,
        centrocusto: centro_custo.centrocusto,
        valor_percentual: centro_custo.valor_percentual,
        ativo: centro_custo.ativo,
        index: centro_custo.index
      }
    } else {
      const index = itens.length - 1

      let total = somarValores(planoContas.centro_custos.filter(param => param.ativo).map(centro => { return parseInt(centro.valor_percentual)}))

      total += parseInt(centro_custo.valor_percentual)

      if(total > 100){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor total do rateio não pode ser superior a 100%.'
          }
        }))
        return
      }

      itens.push({
        departamento_id: centro_custo.departamento_id,
        departamento:centro_custo.departamento,
        centrocusto_id: centro_custo.centrocusto_id,
        centrocusto: centro_custo.centrocusto,
        valor_percentual: centro_custo.valor_percentual,
        ativo: centro_custo.ativo,
        index
      })
    }

    planoContas.centro_custos = itens

    setState(prevState => ({...prevState,
      planoContas,
      centro_custo:{
        id: '',
        departamento_id: '',
        departamento: '',
        centrocusto_id: '',
        centrocusto: '',
        valor_percentual: 0,
        ativo: true,
        index: ''
      },
      updateCentro: false
    }))
  }

  const updateFieldCentroAtivo = () => {
    let {centro_custo} = state
    centro_custo.ativo = !centro_custo.ativo
    setState(prevState => ({...prevState, centro_custo}))
  }

  const editarItem = (index) => {
    const aux = state.planoContas.centro_custos.filter(param => param.index === index)

    const departamento = state.departamentos.list.filter(param => param.id === parseInt(aux[0].departamento_id))[0]

    setState(prevState => ({...prevState,
      updateCentro: true,
      centro_custo: aux[0],
      centros_custo: {
        list: departamento.centro_custos
      }
    }))
  }

  const removerItem = (index) => {
    const planoContas = state.planoContas

    let itens = planoContas.centro_custos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id: itens[i].id,
        departamento_id: itens[i].departamento_id,
        departamento:itens[i].departamento,
        centrocusto_id: itens[i].centrocusto_id,
        centrocusto: itens[i].centrocusto,
        valor_percentual: itens[i].valor_percentual,
        ativo: itens[i].ativo,
        index: i
      })
    }

    itens = aux

    planoContas.centro_custos = itens

    setState(prevState => ({...prevState, planoContas }))
  }

  const updateFieldPermCentroCusto = () => {
    const planoContas = { ...state.planoContas }
    planoContas.permite_centro_custo = !planoContas.permite_centro_custo
    setState(prevState => ({...prevState, planoContas }))
  }

  const updateFieldUsaCentroCusto = () => {
    const planoContas = { ...state.planoContas }
    planoContas.uso_centro_custo = !planoContas.uso_centro_custo
    setState(prevState => ({...prevState, planoContas }))
  }

  const salvar = async () => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    const {planoContas} = state
    
    if(!planoContas.totalizador && planoContas.centro_custos.length > 0){
      const total = somarValores(planoContas.centro_custos.filter(param => param.ativo).map(centro => { return parseFloat(centro.valor_percentual)}))
      if(total !== 100){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'O valor total do rateio precisa ser igual a 100%.'
          }
        }))
        return
      }
    }

    try {
      setState(prevState => ({...prevState,
        loading: true,
        mensagemLoading: 'Cadastrando/Alterando Plano de Contas'
      }))


      const method = planoContasId ? 'put' : 'post'
      const url = planoContasId ? `/planoContas/${planoContasId}` : `/planoContas`

      if (planoContas.nivel_superior_id === "") {
        delete planoContas.nivel_superior_id
      }

      if (planoContas.centro_custos.length === 0) {
        delete planoContas.centro_custos
      }

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, planoContas, getToken())

      setState(prevState => ({...prevState,
        planoContas: initialState.planoContas
      }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/planoContas/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        planoContas: {
          descricao: "",
          codigo: "",
          nivel_superior_id: "",
          unidade_id: "",
          ativo: true,
          totalizador: false,
          tipo: '',
          nivel: 1,
          sequencia: 1,
          permite_centro_custo: false,
          uso_centro_custo: false,
          centro_custos: []
        },
        centro_custo:{
          id: '',
          departamento_id: '',
          departamento: '',
          centrocusto_id: '',
          centrocusto: '',
          valor_percentual: 0,
          ativo: true,
          index: ''
        },
        update: false
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'plano-contas')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (planoContasId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        let unidadesAux = unidades.filter(param => param.status === true && param.tipo === 'UNIDADE')

        const pessoa = JSON.parse(localStorage.getItem('pessoa'))
        const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

        if(tipo === 'MANTENEDOR'){
          unidadesAux.push({
            id: 1,
            descricao: "Click",
            numero: 0
          })
        }

        setState(prevState => ({...prevState,
          unidades: {
            list: unidadesAux.sort((a, b) => (a.numero > b.numero) ? 1 : ((b.numero > a.numero) ? -1 : 0))
          }
        }))

        if(unidades.length === 1){
          const {planoContas} = state
          planoContas.unidade_id = unidades[0].id
          await consultarPlanos(parseInt(unidades[0].id))
          setState(prevState => ({...prevState,planoContas}))
        }

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, dados, getToken())

        const departamentos = []
        
        data.forEach(departamento => {
          if(departamento.unidade){
            departamentos.push({
              id: departamento.id,
              descricao: departamento.descricao,
              unidade: departamento.unidade.descricao ? departamento.unidade.descricao : (departamento.unidade.pessoa.tipo === 'FISICA' ? departamento.unidade.pessoa.fisica.nome : departamento.unidade.pessoa.juridica.razaosocial),
              centro_custos: departamento.centro_custos,
              ativo: departamento.ativo
            })
          }
        })

        setState(prevState => ({...prevState,
          departamentos: {
            list: departamentos
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }


      if (planoContasId) {
        try {
          const { data: planoContas } = await axios.get(`${window._env_.REACT_APP_API_URL}/planoContas/${planoContasId}`, getToken())

          setState(prevState => ({...prevState,
            planoContas: {
              descricao: planoContas.descricao,
              codigo: planoContas.codigo,
              nivel_superior_id: planoContas.nivel_superior_id ? planoContas.nivel_superior_id : "",
              unidade_id: planoContas.unidade_id,
              ativo: planoContas.ativo,
              totalizador: planoContas.totalizador,
              tipo: planoContas.tipo,
              nivel: planoContas.nivel,
              sequencia: planoContas.sequencia,
              permite_centro_custo: planoContas.permite_centro_custo,
              uso_centro_custo: planoContas.uso_centro_custo,
              centro_custos: planoContas.centro_custos.map((centro, key) => {
                return {
                  id: centro.id,
                  departamento_id: centro.centro_custo.departamento_id,
                  departamento: centro.centro_custo.departamento.descricao,
                  centrocusto_id: centro.centro_custo.id,
                  centrocusto: centro.centro_custo.descricao,
                  valor_percentual: centro.valor_percentual,
                  ativo: centro.ativo,
                  index: key
                }
              })
            },
            unidade:{
              id: planoContas.unidade_cadastro.id,
              descricao: planoContas.unidade_cadastro.descricao ? planoContas.unidade_cadastro.descricao : 'Matrix',
              numero: planoContas.unidade_cadastro.numero ? planoContas.unidade_cadastro.numero : 0
            },
            update: true
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
          return false
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { planoContas } = state
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Plano de Contas</h1>
                </Grid>
              </Grid>
              <FormPlanoContas
                unidades={state.unidades.list}
                unidade={state.unidade}
                updateField={e => updateField(e)}
                updateFieldAtivo={e => updateFieldAtivo(e)}
                updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
                updateFieldTotalizador={e => updateFieldTotalizador(e)}
                dados={planoContas}
                planos={state.planosContas.list}
                update={state.update}
                departamentos={state.departamentos.list}
                centro_custo={state.centro_custo}
                updateFieldCentroCusto={e => updateFieldCentroCusto(e)}
                centros_custo={state.centros_custo.list}
                addCentroCusto={e => addCentroCusto()}
                updateFieldCentroAtivo={e => updateFieldCentroAtivo(e)}
                editarItem={e => editarItem(e)}
                removerItem={e => removerItem(e)}
                updateFieldPermCentroCusto={e => updateFieldPermCentroCusto()}
                updateFieldUsaCentroCusto={e => updateFieldUsaCentroCusto()}
                updateCentro={state.updateCentro}
              />

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
            <br />
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Plano de Contas"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroPlanoContas