import React, { Component } from 'react'
import './Estoque.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';

const initialState = {
  estoqueProduto: {

  },
  estoqueProdutos: {
    list: []
  },
  loading: false,
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Produto' },
    { id: 'unidadeMedida', numeric: false, disablePadding: false, label: 'Uni. de Medida' },
    { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'localEstoque', numeric: false, disablePadding: false, label: 'Local Estoque' },
    { id: 'estoqueMinimo', numeric: true, disablePadding: false, label: 'Estoq. Mínimo' },
    { id: 'estoqueMaximo', numeric: true, disablePadding: false, label: 'Estoq. Máximo' },
    { id: 'estoqueAtual', numeric: true, disablePadding: false, label: 'Estoq. Atual' }
  ],
  acoesTabela: ['update', 'view']
}

export default class Estoque extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/estoqueProduto`, this.getToken())

      let estoqueProdutos = []

      data.forEach(estoqueProduto => {
        estoqueProdutos.push({
          _id: estoqueProduto.estoqprod_id,
          descricao: estoqueProduto.produto.prod_descricao,
          unidadeMedida: estoqueProduto.produto.prod_unidademedida,
          estoqueMaximo: estoqueProduto.estoqprod_estoquemaximo,
          estoqueMinimo: estoqueProduto.estoqprod_estoqueminimo,
          estoqueAtual: estoqueProduto.estoqprod_estoqueatual,
          unidade: `${estoqueProduto.localestoque.unidade.unin_numero} - ${estoqueProduto.localestoque.unidade.unin_descricao}`,
          localEstoque: estoqueProduto.localestoque.localestoq_descricao,
        })
      })
      this.setState({
        estoqueProdutos: {
          list: estoqueProdutos
        },
        loading: false
      })
    } catch ({ response }) {
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      return
    }
  }

  render() {
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Estoque Produtos</h1>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Link to="/estoque/cadastro">
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Estoque-Produto
								    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
                className="mg_top_30"
              >
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabela} rows={this.state.estoqueProdutos.list} acoes={this.state.acoesTabela} urlUpdate="/estoque/cadastro/" />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav history={this.props.history}/>
      </div>
    )
  }
}