import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import moment from 'moment';
import real from './../../services/real'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  let {movimentacao_extrato, selecionar} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <StyledPaper theme={theme}>
                <Grid container direction="row" spacing={1} className="borderBottom">
                  <Grid item md={12} xs={12} sm={12}>
                    <h5 className="tituloConfirmacao">Lançar Movimentação</h5>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h7 className="titulo">Movimentação Selecionada:</h7>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="borderBottom mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <p style={{margin: 0}}>Data: {moment(movimentacao_extrato.data).format('DD/MM/YYYY')}</p>
                  </Grid>
                  <Grid item md={12} xs={12} sm={12}>
                    <p style={{margin: 0}}>Valor: R$ {real(movimentacao_extrato.valor)}</p>
                  </Grid>
                  <Grid item md={12} xs={12} sm={12}>
                    <p style={{margin: 0}}>Descrição: {movimentacao_extrato.descricao}</p>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <p style={{margin: 0}}>Por favor selecionar a forma que deseja lançar essa movimentação:</p>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={6} xs={12} sm={6}>
                    <Button fullWidth size='small' onClick={e => selecionar('CONTASPR')} variant="contained" color="primary">
                      Contas a Pagar/Receber
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12} sm={6}>
                    <Button fullWidth size='small' onClick={e => selecionar('MOVIMENTACAO')} variant="contained" color="secondary">
                      Movimentação Financeira
                    </Button>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    </div >
  );
}
