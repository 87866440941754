import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';
import moment from 'moment'
import Alert from './../../components/templates/Alert'
import Autocomplete from '@mui/material/Autocomplete';
import real from './../../services/real'
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unidade_id: ""
  },
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm: {
        visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  planosConta: {
    list: []
  },
  ModalRelatorio: false,
  cabecalhoTabelaQtdAulas: [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
    { id: 'qtd_alunos2', numeric: false, disablePadding: false, label: 'Qtd. Alunos' }
  ],
  acoesTabelaQtdAulas: [],
  linhaSelecionada: '',
  itensFechadoDre: [],
  contasSelecionadas: [],
  contaFinanceiras: {
    list: []
  },
  fluxo_caixa_simples: {
    list: []
  },
  saldo_anterior: 0,
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default class Financeiro extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/relatorios/FluxoCaixa.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'fluxo-caixa-resumo')

    this.setState({
      relatorios_options: {
        list: permissoes.map(value => {
          return {
            descricao: value.tela.descricao,
            slug: value.tela.slug,
            perm: {
              visualizar: value.visualizar
            }
          }
        })
      }
    })


    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
            let descricao = ''
            if (unidade.tipo === 'MANTENEDOR') {
              descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            }if (unidade.tipo === 'REPRESENTANTE') {
              descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            } else if (unidade.tipo === 'GRUPOECONOMICO') {
              descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            } else if (unidade.tipo === 'UNIDADE') {
              descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
            }

            return {
              id: unidade.id,
              descricao,
              tipo: unidade.tipo
            }
          })
        }
      })

      if(unidades.length === 1){
        const {filtro} = this.state
        filtro.unidade_id = unidades[0].id

        await this.consultarContas(unidades[0].id)

        this.setState({filtro})
      }
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })

      this.backPage()
    }

  }

  async consultarContas(unidade_id){
    try {
      const dados = {
        filtro: {
          tipo_conta: "",
          descricao: "",
          ativo: "Sim"
        },
        unidadesnegocio: [unidade_id]
      }
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceiras = data.filter(param => param.ativo === true).sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(param => {
        return {
          _id: param.id,
          descricao: param.descricao,
          url_banco: param.logo
        }
      })

      this.setState({
        contaFinanceiras: {
          list: contaFinanceiras
        },
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro
    
    let name = event.target.name
    let value = event.target.value

    if(name === 'unidade_id'){
      await this.consultarContas(value)
    }

    filtro[name] = value

    this.setState({ filtro })
  }

  async filtrar() {
    const {filtro, contasSelecionadas} = this.state

    this.setState({
      loading: true
    })

    if (filtro.data_inicial === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial'
        }
      })
      this.setState({
        loading: false
      })
      return
    }

    if (filtro.data_final === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar a data final'
        }
      })
      this.setState({
        loading: false
      })
      return

    }

    if(contasSelecionadas.length === 0){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar ao menos uma conta'
        }
      })
      this.setState({
        loading: false
      })
      return
    }
    
    try {
     
      filtro.relatorio = 'fluxo-caixa-resumo'
      filtro.conta_fin_ids = contasSelecionadas.map(conta => {return conta._id})

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())     
            
      this.setState({
        fluxo_caixa_simples: {
          list: data.list
        },
        saldo_anterior: data.saldo_anterior
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      console.log(error)
    }


  }

  async imprimir() {
    const { filtro, fluxo_caixa_simples } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      if (filtro.relatorio !== 'contratos-alunos') {
        filtro.list = fluxo_caixa_simples.list
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, 'Fluxo-de-Caixa.pdf')
      
    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateFieldConta(event, value){
    this.setState({
      contasSelecionadas: value
    })
  }

  openRow(key){

    const {linhaSelecionada} = this.state

    this.setState({
      linhaSelecionada: linhaSelecionada === key  ? '' : key
    })
  }

  render() {
    const { filtro, contaFinanceiras, fluxo_caixa_simples, saldo_anterior, contasSelecionadas, 
      loadingDownload,  unidades, linhaSelecionada } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          <Grid container spacing={2} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Fluxo de Caixa</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={2} direction="row" className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_id"
                value={filtro.unidade_id}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Unidade</option>
                {unidades.list.map(unidade => {
                  return (
                    <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12} sm={6} id='form-contas'>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={contaFinanceiras.list.filter(conta => {
                  if (contasSelecionadas.findIndex(i => i._id === conta._id) === -1){
                    return conta;
                  }else{
                    return false
                  }
                })}
                size="small"
                value={contasSelecionadas}
                onChange={(event, value) => this.updateFieldConta(event, value)}
                getOptionLabel={(option) => option.descricao}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Contas" InputLabelProps={{ shrink: true }}/>
                )}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type='date' label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()}>
                Filtrar
              </Button>
            </Grid>
            {fluxo_caixa_simples.list.length > 0 &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          <hr className="mg_top_10"/>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {fluxo_caixa_simples.list.length > 0 && 
                <React.Fragment>
                  <Grid container spacing={2} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Saldo Inicial: <span style={{color: saldo_anterior < 0 ? '#FF0000' :  (saldo_anterior === 0 ? '#000' :'#006400')}}>R$ {real(saldo_anterior)}</span></h4>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="row">
                    <Grid item md={12} xs={12} sm={12} id='fluxo_caixa'>
                      <table className="lista sempadding borderTable">
                        <thead>
                          <tr className="titulos acompanha">
                            <th style={{width:'10%', height: '23px'}}></th>
                            <th style={{width:'18%'}}>Data</th>
                            <th style={{width:'18%'}}>Pagar</th>
                            <th style={{width:'18%'}}>Receber</th>
                            <th style={{width:'18%'}}>Saldo do Dia</th>
                            <th style={{width:'18%'}}>Saldo</th>
                          </tr>
                        </thead>
                        <tbody className='rolagemTabela'>
                            {fluxo_caixa_simples.list.map((fluxo, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <tr style={{cursor: 'pointer'}} onClick={e => this.openRow(key)}>
                                    <td style={{width:'10%'}}>
                                      {linhaSelecionada === key &&
                                        <IconButton color="primary" aria-label="view">
                                          <IndeterminateCheckBoxIcon />
                                        </IconButton>
                                      }
                                      {linhaSelecionada !== key &&
                                        <IconButton color="primary" aria-label="view">
                                          <AddBoxIcon />
                                        </IconButton>
                                      }
                                    </td>
                                    <td style={{marginTop: '5px', width:'18%'}}>{moment(fluxo.data).format('DD/MM/YYYY')}</td>
                                    <td style={{marginTop: '5px', width:'18%', color: fluxo.pagar < 0 ? '#FF0000' :  (fluxo.pagar === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.pagar)}</td>
                                    <td style={{marginTop: '5px', width:'18%', color: fluxo.receber < 0 ? '#FF0000' :  (fluxo.receber === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.receber)}</td>
                                    <td style={{marginTop: '5px', width:'18%', color: fluxo.saldo_dia < 0 ? '#FF0000' :  (fluxo.saldo_dia === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.saldo_dia)}</td>
                                    <td style={{marginTop: '5px', width:'18%', color: fluxo.saldo < 0 ? '#FF0000' :  (fluxo.saldo === 0 ? '#000' :'#006400')}}>R$ {real(fluxo.saldo)}</td>
                                  </tr>
                                  <tr>
                                    <td colSpan="6" style={{overflow: 'hidden', display: linhaSelecionada === key ? '': 'none', height: linhaSelecionada === key ? (fluxo.contas.length === 0 ? '35px' :'200px') : '20px', width:'100%'}} id={key}>
                                      {fluxo.contas.length === 0 &&
                                        <p style={{marginTop: linhaSelecionada === key ? '5px' : '30px', marginLeft: '2%', width: '96%'}}  >Nenhum registro para essa data!</p>
                                      }

                                      {fluxo.contas.length > 0 &&
                                        <table style={{marginTop: linhaSelecionada === key ? '5px' : '30px', marginLeft: '2%', width: '96%'}} className="lista sempadding borderTable">
                                          <thead>
                                            <tr className="titulos acompanha">
                                              <th style={{width:'10%'}}>Tipo</th>
                                              <th style={{width:'30%'}}>Cliente/Fornecedor</th>
                                              <th style={{width:'10%'}}>Tipo de Data</th>
                                              <th style={{width:'10%'}}>Status</th>
                                              <th style={{width:'10%'}}>Form. Pagto.</th>
                                              <th style={{width:'10%'}}>Valor</th>
                                              <th style={{width:'10%'}}>Multa/Juros</th>
                                              <th style={{width:'10%'}}>Total</th>
                                            </tr>
                                          </thead>
                                          <tbody className='rolagemTabelaParcelas'>
                                            {fluxo.contas.map((conta, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td style={{width:'10%'}}>{conta.tipo === 'DEPOSITO' ? 'Receita' : (conta.tipo === 'RECEITA' ? 'Receita'  : 'Despesa') }</td>
                                                  <td style={{width:'30%'}}>{conta.pessoa_id ? (conta.tipo_pessoa === 'FISICA' ? conta.nome : conta.razaosocial) : ''}</td>
                                                  <td style={{width:'10%'}}>{conta.tipo_data}</td>
                                                  <td style={{width:'10%'}}>{conta.status}</td>
                                                  <td style={{width:'10%'}}>{conta.forma_pagto || ''}</td>
                                                  <td style={{width:'10%', color: conta.valor < 0 ? '#FF0000' :  (conta.valor === 0 ? '#000' :'#006400')}}>R$ {real(conta.valorparcela || conta.valor)}</td>
                                                  <td style={{width:'10%', color: conta.multajuros < 0 ? '#FF0000' :  (conta.multajuros === 0 ? '#000' :'#006400')}}>R$ {real(conta.multajuros)}</td>
                                                  <td style={{width:'10%', color: conta.valor + conta.multajuros < 0 ? '#FF0000' :  (conta.valor + conta.multajuros === 0 ? '#000' :'#006400')}}>R$ {real((conta.valorparcela || conta.valor) + conta.multajuros)}</td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      }
                                      
                                      
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )
                            })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="row" className="mg_top_10">
                    <Grid item md={4} xs={12} sm={4} style={{textAlign: 'right'}}>
                      <p>Total de Saídas: R$ {real(somarValores(fluxo_caixa_simples.list.map(value => {return value.pagar})))}</p>
                    </Grid>
                    <Grid item md={4} xs={12} sm={4} style={{textAlign: 'right'}}>
                      <p>Total de Entradas: R$ {real(somarValores(fluxo_caixa_simples.list.map(value => {return value.receber})))}</p>
                    </Grid>
                    <Grid item md={4} xs={12} sm={4} style={{textAlign: 'right'}}>
                      <p>Saldo Final: R$ {real(fluxo_caixa_simples.list[fluxo_caixa_simples.list.length - 1].saldo)}</p>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </React.Fragment>
          }
        </Main>
        <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Fluxo de Caixa"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}