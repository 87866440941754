import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  marginTop: 100,
  width: '30%', 
  marginLeft: '35%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} data-cy="modal-esqueceu-senha">
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Esqueceu sua Senha</h5>
            </Grid>
          </Grid>
          {props.dados.usuario === '' &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12} sm={12}>
                  <TextField data-cy="email-esqueceu-senha" fullWidth className="input" label="Informe o seu e-mail" variant="outlined" name="email" value={props.dados.email} onChange={(e) => props.updateField(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={6} xs={6} sm={6}>
                  <Button data-cy="voltar-esqueceu-senha" fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.travaBotao}>Voltar</Button>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <Button data-cy="enviar-esqueceu-senha" fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.travaBotao}>Enviar</Button>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          {(props.dados.usuario !== '' && !props.dados.codigo_valido) &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <p>Olá {props.dados.usuario}</p>
                </Grid>
              </Grid>              

              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  {props.dados.meio_envio === "whats" &&
                    <p>Enviamos um código para o WhatsApp do número: {props.dados.aonde_foi_enviado}</p>
                  }
                  {props.dados.meio_envio === "email" &&
                    <p>Enviamos um código para o E-mail: {props.dados.aonde_foi_enviado}</p>
                  }
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1}>
                <Grid item md={3} xs={3} sm={3}></Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <TextField data-cy="codigo-esqueceu-senha" fullWidth className="input" label="Informe o código" variant="outlined" name="codigo" value={props.dados.codigo} onChange={(e) => props.updateField(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={3} sm={3}></Grid>
              </Grid>

              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={6} xs={6} sm={6}>
                  <Button data-cy="botao-voltar-codigo" fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmarCodigo(false)} disabled={props.travaBotao}>Voltar</Button>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <Button data-cy="botao-enviar-codigo" fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmarCodigo(true)} disabled={props.travaBotao}>Enviar</Button>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          {props.dados.codigo_valido &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <p>{props.dados.usuario} por favor informar a sua nova senha.</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Nova Senha</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={props.showPassword ? 'text' : 'password'}
                      value={props.dados.senha}
                      onChange={(e) => props.updateField(e)}
                      className="input"
                      name="senha"
                      data-cy="input-nova-senha"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => props.handleClickShowPassword(e)}
                            edge="end"
                          >
                            {props.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </Grid>
              </Grid>     
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirmar Senha</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={props.showPasswordConfirmar ? 'text' : 'password'}
                      value={props.dados.confirma_senha}
                      onChange={(e) => props.updateField(e)}
                      className="input"
                      name="confirma_senha"
                      data-cy="input-confirma-senha"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => props.handleClickShowPasswordConfirmar(e)}
                            edge="end"
                          >
                            {props.showPasswordConfirmar ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <span data-cy="span-nivel-senha">Nivel Senha: <b style={{ color: props.corSenha }}>{props.nivelSenha}</b> </span>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={6} xs={6} sm={6}>
                  <Button data-cy="botao-voltar-trocar-senha" fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.trocarSenha(false)} disabled={props.travaBotao}>Voltar</Button>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <Button data-cy="botao-trocar-senha" fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.trocarSenha(true)} disabled={props.travaBotao}>Enviar</Button>
                </Grid>
              </Grid>
            </React.Fragment>
          }
        </StyledPaper>
      </Modal>
    </div>
  );
}
