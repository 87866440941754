import React, { useState, useEffect } from 'react';
import './FechamentoCaixa.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, TextField } from '@mui/material'
import Loading from './../../components/templates/Loading'
import real from '../../services/real'
import moment from 'moment'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  caixa: {},
  caixas: {
    list: []
  },
  caixasFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'numero', numeric: false, disablePadding: true, label: 'Nº Caixa' },
    { id: 'operador', numeric: false, disablePadding: true, label: 'Operador' },
    { id: 'dataabertura', numeric: false, disablePadding: true, label: 'Data de abertura' },
    { id: 'saldo', numeric: false, disablePadding: true, label: 'Saldo atual' },
    { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
  ],
  acoesTabela: ['view'],
  loading: true,
  mensagemLoading: 'Carregando Caixas...',
  filtro: {
    numero: "",
    operador: "",
    status: ""
  },
  unin_tipo: 'UNIDADE',
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

const FechamentoCaixa = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateFiltro = (event) => {
    setState(prevState => ({...prevState, term: event.target.value }))
    const caixas = state.caixas.list
    const filtro = state.filtro

    const list = caixas.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      caixasFiltrado: {
        list
      },
      filtro
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/Listagem.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })
  
      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo : 'UNIDADE'
  
      setState(prevState => ({...prevState,
        unin_tipo
      }))
  
      try {
  
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }
  
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/caixa/aguardando-fechamento`, dados, getToken())
  
        const list = data.map(caixa => {
          return {
            _id: caixa.id,
            numero: caixa.numero,
            operador: caixa.operador,
            dataabertura: moment(caixa.dataabertura).format('DD/MM/YYYY HH:mm'),
            saldo: `R$ ${real(caixa.saldo_atual)}`,
            situacao: caixa.status
          }
        })
  
        setState(prevState => ({...prevState,
          caixas: { list },
          caixasFiltrado: { list }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
  
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { filtro, caixasFiltrado } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12} xs={12}>
                  <h1 className="titulo">Caixas Aguardando Fechamento</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Caixa" variant="outlined" size="small" name="numero" value={filtro.numero} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Operador" variant="outlined" size="small" name="operador" value={filtro.operador} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Situação" variant="outlined" size="small" name="status" value={filtro.status} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table
                    urlView="/caixa_fechamento/view/"
                    headCell={state.cabecalhoTabela}
                    rows={caixasFiltrado.list}
                    acoes={state.acoesTabela}
                    ordem='desc'
                  />
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav />
        </div>
      }
      <Loading
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Caixas Aguardando Fechamento"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default FechamentoCaixa
