import React from 'react'
import real from './../../../services/real'
import { Grid } from '@mui/material'
import Table from './../../../components/Table'
import moment from 'moment';

let cabecalhoTabela = [
  { id: 'voucher', numeric: false, disablePadding: false, label: 'Voucher' },
  { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
  { id: 'habilita', numeric: false, disablePadding: false, label: 'Habilita' },
  { id: 'data_compra', numeric: false, disablePadding: false, label: 'Data da Compra' },
  { id: 'credito', numeric: true, disablePadding: false, label: 'Credito' },
  { id: 'saldo_voucher', numeric: true, disablePadding: false, label: 'Saldo' }
]

let cabecalhoTabelaAgenda = [
  { id: 'os', numeric: false, disablePadding: false, label: 'Os' },
  { id: 'status_agenda', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'servico', numeric: false, disablePadding: false, label: 'Serviço' },
  { id: 'atendente', numeric: false, disablePadding: false, label: 'Fisio.' },
  { id: 'sala', numeric: true, disablePadding: false, label: 'Sala' },
  { id: 'valor_servico', numeric: true, disablePadding: false, label: 'Valor Serviço'},
  { id: 'data', numeric: true, disablePadding: false, label: 'Data' }
]

let cabecalhoPagamento = [
  { id: 'forma', numeric: false, disablePadding: false, label: 'Forma de Pagamento' },
  { id: 'valor_pagamento', numeric: false, disablePadding: false, label: 'Valor' }
]

// let coresStatus = [
//   {
//     status: 'AGENDADO',
//     cor_fundo: '#ffff00',
//     cor_fonte: '#000' 
//   },
//   {
//     status: 'CONFIRMADO',
//     cor_fundo: '#00a933',
//     cor_fonte: '#000' 
//   },
//   {
//     status: 'EM ATENDIMENTO',
//     cor_fundo: '#2a6099',
//     cor_fonte: '#FFF' 
//   },
//   {
//     status: 'ATRASADO',
//     cor_fundo: '#ff4000',
//     cor_fonte: '#FFF' 
//   },
//   {
//     status: 'FINALIZADO',
//     cor_fundo: '#FFF',
//     cor_fonte: '#000' 
//   },
//   {
//     status: 'FALTOU',
//     cor_fundo: '#800000',
//     cor_fonte: '#FFF' 
//   }
// ]

const VendaVouchers = ({vouchers, agendas, pagamentos_voucher, pagamentos_agendas, viewAgenda}) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12}>
        <h4 className='titulo'>Vouchers: </h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12}>
        <Table
          headCell={cabecalhoTabela}
          rows={vouchers.list.map(value => {
            return {
              _id: value.id,
              voucher: value.padrao.nome,
              numero: value.numero,
              habilita: value.padrao.habilita,
              data_compra: moment(value.data_compra).format('DD/MM/YYYY'),
              credito: real(value.valor_credito),
              saldo_voucher: real(value.saldo)
            }
          })}
          acoes={['viewVoucherRel']}
          urlView={'/venda_voucher/view/'}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12}>
        <h5 className='titulo'>Pagamentos dos Vouchers: </h5>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={6} xs={6}>
        <Table
          headCell={cabecalhoPagamento}
          rows={pagamentos_voucher.list.map(value => {
            return {
              _id: value.id,
              forma: value.forma,
              valor_pagamento: 'R$ ' + real(value.valor_pagamento)
            }
          })}
          qtdLinhas={pagamentos_voucher.list.length}
          desabilitaRodape={true}
          acoes={[]}
        />
      </Grid>
    </Grid>
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12}>
        <h4 className='titulo'>Agendamentos: </h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12}>
        <Table
          urlView={''}
          headCell={cabecalhoTabelaAgenda}
          rows={agendas.list.map(value => {
            return {
              _id: value.id,
              os: value.item_pedido.pedido_servico.numero,
              status_agenda: value.situacao,
              servico: value.item_pedido.produto.descricao,
              atendente: value.funcionario ? (value.funcionario.fisica.nome + ' ' + (value.funcionario.fisica.sobrenome ? value.funcionario.fisica.sobrenome : '')): '',
              sala: value.ambiente.nome,
              data: moment(value.data_hora_inicio).format('DD/MM/YYYY hh:mm'),
              data_hora_inicio: value.data_hora_inicio,
              valor_servico: 'R$ ' + real(value.item_pedido.valor_servico),
              tela: 'AGENDA',
              utilizado: value.situacao !== 'DESMARCADO' ? true : false
            }
          })}
          acoes={['viewAgenda']}
          viewAgenda={e => viewAgenda(e)}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12}>
        <h5 className='titulo'>Pagamentos das Agendas: </h5>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={6} xs={6}>
        <Table
          headCell={cabecalhoPagamento}
          rows={pagamentos_agendas.list.map(value => {
            return {
              _id: value.id,
              forma: value.forma,
              valor_pagamento: 'R$ ' + real(value.valor_pagamento)
            }
          })}
          qtdLinhas={pagamentos_agendas.list.length}
          desabilitaRodape={true}
          acoes={[]}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default VendaVouchers
