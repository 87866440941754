import React, { Component } from 'react'
import './Funcionario.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Alert from './../../components/templates/Alert'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import { styled } from '@mui/system';
import Color from 'color';
import ModalAjuda from './../../components/modals/Ajuda'

const ColorDarkOrangeButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: Color('#FF8C00').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#FF8C00',
  },
}));

const initialState = {
  funcionario: {

  },
  funcionarios: {
    list: []
  },
  funcionariosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'funcao', numeric: false, disablePadding: false, label: 'Função' },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Trabalha para' },
    { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: [],
  filtro: {
    ativo: 'SIM',
    nome: "",
    unidade_descricao: "",
    funcao_descricao: ""
  },
  loading: true,
  mensagemLoading: 'Carregando Colaboradores...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

export default class Funcionario extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    this.setState({
      filtro: {
        ativo: 'SIM',
        nome: "",
        unidade_descricao: "",
        funcao_descricao: ""
      }
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'funcionario')[0]

    let acoesTabela = []

    if (permissoes.visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    let filtro = this.state.filtro

    let aux = {
      ativo: filtro.ativo === 'SIM' ? true : false,
      nome: filtro.nome,
      unidade_descricao:filtro.unidade_descricao,
      funcao_descricao: filtro.funcao_descricao
    }

    aux.unidadesnegocio = dados.unidadesnegocio

    try {
      const { data: funcionarios } = await axios.post(`${window._env_.REACT_APP_API_URL}/funcionarios/list/filtro`, aux, this.getToken())

      this.setState({
        funcionarios: {
          list: funcionarios
        },
        funcionariosFiltrado: {
          list: funcionarios
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })
  }

  async filtrar() {

    this.setState({
      loading: true
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    let filtro = this.state.filtro

    let aux = {
      ativo: filtro.ativo === 'SIM' ? true : false,
      nome: filtro.nome,
      unidade_descricao:filtro.unidade_descricao,
      funcao_descricao: filtro.funcao_descricao
    }

    aux.unidadesnegocio = dados.unidadesnegocio

    try {
      const { data: funcionarios } = await axios.post(`${window._env_.REACT_APP_API_URL}/funcionarios/list/filtro`, aux, this.getToken())

      this.setState({
        funcionarios: {
          list: funcionarios
        },
        funcionariosFiltrado: {
          list: funcionarios
        },
        loading: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  render() {
    const { filtro, permissoes } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="borderBottom"
                >
                  <Grid item md={9} xs={12} sm={6}>
                    <h1 className="titulo">Colaboradores</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={3} xs={12} sm={6}>
                      <Link to="/funcionario/cadastro">
                        <Button size="small" fullWidth variant="contained" color="primary">
                          Cadastrar Colaborador
                        </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Nome" variant="outlined" size="small" name="nome" value={filtro.nome} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Função" variant="outlined" size="small" name="descricao" value={filtro.funcao_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Unidade" variant="outlined" size="small" name="descricao" value={filtro.unidade_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Ativo"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="ativo"
                      value={filtro.ativo}
                      onChangeCapture={(e) => this.updateFiltro(e)}
                    >
                      <option key="Sim" value='SIM'>
                        Sim
                      </option>
                      <option key="Não" value='NAO'>
                        Não
                      </option>
                    </TextField>
                  </Grid>
                  <Grid item md={1} xs={12} sm={6}>
                    <Button color='warning' className='btn_search_func' fullWidth variant="contained" endIcon={<SearchIcon />} onClick={e => this.filtrar(e)}></Button>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table urlUpdate="/funcionario/cadastro/" urlView="/funcionario/view/" headCell={this.state.cabecalhoTabela} rows={this.state.funcionariosFiltrado.list} acoes={this.state.acoesTabela} />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />

        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Colaboradores"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}