import React, { useState, useEffect } from 'react';
import './Pesquisa.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormPesquisa from '../../components/forms/FormPesquisa'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import ModalEnviarPesquisa from './../../components/modals/EnviarPesquisa'
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  pesquisa: {
    id: "",
    titulo: "",
    mensagem: "",
    hora_inicio: "",
    hora_fim: "",
    data_inicio: "",
    data_fim: "",
    ativo: true,
    opcoes: []
  },
  opcao: {
    id: '',
    titulo: '',
    descricao: '',
    index: ''
  },
  loadingSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading:true,
  update: false,
  unidades: {
    list: []
  },
  tipo: 'UNIDADE',
  unidadesSelecionadas: [],
  openModalSelecionarLogo: false,
  openModalEnviarPesquisa: false,
  dadosEnvio: {
    canal: '',
  },
  flagsSelecionadas: [],
  editorState: EditorState.createEmpty(),
  filtro: {
    descricao: ''
  },
  variaveisFiltrada:{
    list: []
  },
  templates: {
    list: []
  },
  openModalAjuda: false,
  markdown: ''
}

const CadastroNotificacao = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { pesquisaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 2000) => {
    setTimeout(() => {
      navigate("/pesquisa");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldUnidade = (event, value) => {
    setState(prevState => ({...prevState,
      unidadesSelecionadas: value
    }))
  }

  const validaForm = () => {
    const {pesquisa} = state

    if(pesquisa.titulo === '') return false
    if(pesquisa.mensagem === '') return false
    if(pesquisa.opcoes.length === 0) return false

    return true
  }

  const salvar = async () => {
    const {pesquisa} = state
    
    if(!validaForm()){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        },
        loadingSalvar: false
      }))
      return
    }

    if(pesquisa.data_inicio){
      setState(prevState => ({...prevState,
        openModalEnviarPesquisa: true
      }))
    }else{
      await continuarSalvar()
    }
  }

  const confirmarEnvioPesquisa = async (value) => {
    if(value){
      let {dadosEnvio, flagsSelecionadas} = state

      if(dadosEnvio.canal === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o canal de comunicação.'
          }
        }))
        return 
      }

      if(flagsSelecionadas.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor selecionar pelo menos uma flag'
          }
        }))

        return 
      }

      await continuarSalvar()
    }else{
      handleCloseModalEnviarPesquisa()
    }
  }

  const continuarSalvar = async () => {
    const {pesquisa, unidadesSelecionadas, dadosEnvio, flagsSelecionadas} = state

    if(unidadesSelecionadas.length > 0){
      pesquisa.unidades = unidadesSelecionadas.map(unidade => { return {unidade_id: unidade.id}})
    }

    try {

      if(dadosEnvio.canal !== ''){
        pesquisa.dadosEnvio = {
          canal: dadosEnvio.canal,
          flags: flagsSelecionadas
        }
      }

      if(pesquisa.hora_inicio === '') {
        delete pesquisa.hora_inicio
      }

      if(pesquisa.hora_fim === '') {
        delete pesquisa.hora_fim
      }

      if(pesquisa.data_inicio === '') {
        delete pesquisa.data_inicio
      }

      if(pesquisa.data_fim === '') {
        delete pesquisa.data_fim
      }

      if(pesquisa.id !== ''){
        for (let i = 0; i < pesquisa.opcoes.length; i++) {
          if(pesquisa.opcoes[i].id === ''){
            delete pesquisa.opcoes[i].id
          }
        }

        await axios.put(`${window._env_.REACT_APP_API_URL}/pesquisa/${pesquisa.id}`, pesquisa, getToken())
      }else{
        delete pesquisa.id

        for (let i = 0; i < pesquisa.opcoes.length; i++) {
          delete pesquisa.opcoes[i].id
        }

        await axios.post(`${window._env_.REACT_APP_API_URL}/pesquisa`, pesquisa, getToken())
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      }))

      backPage()
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
  }

  const updateFieldAtivo = () => {
    const {pesquisa} = state
    pesquisa.ativo = !pesquisa.ativo
    setState(prevState => ({...prevState,pesquisa}))
  }

  const updateField = (event) => {
    const {pesquisa} = state

    let name = event.target.name
    let value = event.target.value

    pesquisa[name] = value

    setState(prevState => ({...prevState,pesquisa}))
  }

  const updateFieldOpcao = (event) => {
    const {opcao} = state

    let name = event.target.name
    let value = event.target.value

    opcao[name] = value

    setState(prevState => ({...prevState,opcao}))
  }

  const addOpcao = () => {
    let {opcao, pesquisa} = state

    if(opcao.index === ''){
      pesquisa.opcoes.push({
        id: opcao.id,
        titulo: opcao.titulo,
        descricao: opcao.descricao,
        index: pesquisa.opcoes.length
      })
    }else{
      pesquisa.opcoes[opcao.index] = opcao
    }

    setState(prevState => ({...prevState,
      pesquisa,
      opcao: {
        id: '',
        titulo: '',
        descricao: '',
        index: ''
      },
      update: false
    }))
  }

  const editarOpcao = (index) => {
    let {opcao, pesquisa} = state

    opcao = {
      ...pesquisa.opcoes[index]
    }

    setState(prevState => ({...prevState,
      opcao,
      update: true
    }))
  }

  const removerOpcao = (index) => {
    const {pesquisa} = state

    let itens = pesquisa.opcoes

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id:itens[i].id,
        titulo: itens[i].titulo,
        descricao: itens[i].descricao,
        index: i
      })
    }

    itens = aux

    pesquisa.opcoes = itens

    setState(prevState => ({...prevState, pesquisa }))
  }

  const handleCloseModalEnviarPesquisa = () => {
    setState(prevState => ({...prevState,
      openModalEnviarPesquisa: false
    }))
  }

  const updateFieldEnviaPesquisa = (event) => {
    let {dadosEnvio} = state

    dadosEnvio[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      dadosEnvio
    }))
  }

  const updateFieldFlags = (event, value) => {
    setState(prevState => ({...prevState,
      flagsSelecionadas: value
    }))
  }

  const onEditorStateChange = (editorState) => {
    const {pesquisa } = state

    pesquisa.mensagem = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    setState(prevState => ({...prevState,editorState, pesquisa}))
  } 

  const selecionarVar = (variavel) => {
    let pesquisa = { ...state.pesquisa }

    pesquisa.mensagem = pesquisa.mensagem.replace('{%}', `%%${variavel.variavel}`)

    const contentBlock = htmlToDraft(pesquisa.mensagem);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setState(prevState => ({...prevState, pesquisa, editorState }))
  }

  const updateFiltro = (event) => {
    let {filtro} = state
    filtro.descricao = event.target.value
    setState(prevState => ({...prevState,filtro}))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/pesquisa/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        pesquisa: {
          id: "",
          titulo: "",
          mensagem: "",
          hora_inicio: "",
          hora_fim: "",
          data_inicio: "",
          data_fim: "",
          ativo: true,
          opcoes: []
        },
        unidadesSelecionadas: []
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'pesquisa')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (pesquisaId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }  

      try {
        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, {}, getToken())
        const { data: templates } = await axios.get(`${window._env_.REACT_APP_API_URL}/templatesvariaveis`, getToken())

        setState(prevState => ({...prevState,
          templates:{
            list: templates
          },
          variaveisFiltrada:{
            list: templates.filter(param => param.tipo === 'PESQUISA')[0].variaveis
          },
          unidades:{
            list: unidades.map(unidade => {
              return {
                id: unidade.id,
                numero: unidade.numero,
                descricao: unidade.descricao,
                midias: unidade.pessoa.midias_pessoa
              }
            })
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (pesquisaId) {
        try {
          const { data: pesquisa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pesquisa/${pesquisaId}`, getToken())

          if(pesquisa.envio){
            setState(prevState => ({...prevState,
              dadosEnvio: {
                canal: pesquisa.envio.meio_envio,
              },
              flagsSelecionadas: pesquisa.envio.flags.split(",")
            }))
          }

          const contentBlock = htmlToDraft(pesquisa.mensagem);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);

          setState(prevState => ({...prevState,
            pesquisa:{
              id: pesquisa.id,
              titulo: pesquisa.titulo,
              mensagem: pesquisa.mensagem,
              ativo: pesquisa.ativo,
              hora_inicio: pesquisa.hora_inicio,
              hora_fim: pesquisa.hora_fim,
              data_inicio: pesquisa.data_inicio,
              data_fim: pesquisa.data_fim,
              opcoes: pesquisa.opcoes.map((opcao, key) => {
                return {
                  id: opcao.id,
                  titulo: opcao.titulo,
                  descricao: opcao.descricao,
                  index: key
                } 
              })
            },
            unidadesSelecionadas:pesquisa.unidades.map(value => {
              return {
                id: value.unidade_id,
                descricao: value.unidade.descricao,
                numero: value.unidade.numero
              }
            }),
            editorState
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { pesquisa, unidades, unidadesSelecionadas, opcao, update, editorState, variaveisFiltrada, filtro } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Tela...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom" >
              <Grid item md={11}>
                <h1 className="titulo">Cadastro de Pesquisa</h1>
              </Grid>
              <Grid item md={1}>
                {state.loadingSalvar &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>
            <FormPesquisa 
              dados={pesquisa}
              updateField={e => updateField(e)}
              updateFieldAtivo={e => updateFieldAtivo()}
              unidades={unidades.list}
              unidadesSelecionadas={unidadesSelecionadas}
              updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
              opcao={opcao}
              addOpcao={(e) => addOpcao(e)}
              update={update}
              updateFieldOpcao={(e) => updateFieldOpcao(e)}
              editarOpcao={(e) => editarOpcao(e)}
              removerOpcao={(e) => removerOpcao(e)}
              editorState={editorState}
              onEditorStateChange={e => onEditorStateChange(e)}
              variaveisFiltrada={variaveisFiltrada.list}
              selecionarVar={e => selecionarVar(e)}
              filtro={filtro}
              updateFiltro={(e) => updateFiltro(e)}
            />
            <br />
            <hr />
            <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
              <Grid item md={10} xs={12} sm={6}></Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button 
                  fullWidth 
                  color="primary" 
                  variant="contained"  
                  size="small" 
                  startIcon={<SaveIcon />} 
                  onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
        <ModalEnviarPesquisa 
          open={state.openModalEnviarPesquisa}
          handleClose={e => handleCloseModalEnviarPesquisa()}
          dados={state.dadosEnvio} 
          disabledButtons={state.disabledButtons}
          updateField={(e) => updateFieldEnviaPesquisa(e)}
          confirmar={(e) => confirmarEnvioPesquisa(e)}
          flagsSelecionadas={state.flagsSelecionadas}
          updateFieldFlags={(event, value) => updateFieldFlags(event, value)}
        />
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message}
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Pesquisas"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default CadastroNotificacao