import React from 'react'
import { Grid, TextField, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

import { styled } from '@mui/system';
import Color from 'color';

const ColorDarkOrangeButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: Color('#FF8C00').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#FF8C00',
  },
}));

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const FormCentroCustoProduto = ({ dados, centro_custo, updateFieldCentroCusto, centro_lucro, centros_custo, removerItemCentroCusto, centros_lucro,
  addCentroCusto, removerItemCentroLucro, updateCentro, updateFieldCentro, addCentroLucro, updateField, planoContas, updateFieldCentroLucro }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12}>
        <h4 className="titulo">Rateio do Centro de Lucro (Receitas):</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Plano de Contas*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="planocontas_receita_id"
          size="small"
          value={dados.planocontas_receita_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.visibilidade === '' ? true: false}
        >
          <option value="">Selecionar Plano de Contas</option>
          {planoContas.filter(param => param.tipo_saida_entrada === 'RECEITA').map(plano => {
            return (
              <option value={plano.id} disabled={plano.totalizador} className={plano.totalizador ? 'agrupador' : ''}  >{plano.codigo} - {plano.descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Centro de Lucro*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="centrocusto_id"
          value={centro_lucro.centrocusto_id}
          onChangeCapture={(e) => updateFieldCentroLucro(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={centro_lucro.centrocusto_id !== '' ? centro_lucro.centrocusto_id : ''}> {centro_lucro.centrocusto_id !== '' ? centro_lucro.centrocusto : 'Selecionar Centro de Custo'} </option>
          {centros_lucro.filter(param => {
            const index = dados.centro_lucros.findIndex(value => parseInt(value.centrocusto_id) === parseInt(param.id))
            if(index === -1) return true
            return false
          }).sort((a, b) => (a.centro_custo.descricao > b.centro_custo.descricao) ? 1 : ((b.centro_custo.descricao > a.centro_custo.descricao) ? -1 : 0)).map(centro => {
            return (
              <option value={centro.id}> {centro.centro_custo.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <ColorDarkOrangeButton fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addCentroLucro(e)}>{updateCentro ? 'Alterar' : 'Adicionar'}</ColorDarkOrangeButton>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={6} xs={12} sm={12}>
        <div className='scroll'>
          <table className="lista sempadding borderTable">
            <thead>
              <tr className="titulos acompanha">
                <th>Centro de Lucro</th>
                <th>Porc. Rateio*</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {dados.centro_lucros.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(centro => {
                return (
                  <tr key={centro.index}>
                    <td>{centro.centrocusto}</td>
                    <td>
                      <TextField 
                      type='number' 
                      className="inputValor" 
                      variant="outlined" 
                      size="small" 
                      name="valor_percentual" 
                      value={centro.valor_percentual} 
                      InputLabelProps={{ shrink: true }} 
                      onChange={(e) => updateFieldCentro(e, centro.index)} 
                      // onBlur={(e) => ajustaValorCentro(e, centro.index)}
                      InputProps={{ inputProps: { max: 100 } }}
                      disabled={false}
                    />
                    </td>
                    <td>
                      <Tooltip title='Remover' aria-label="add">
                        <IconButton onClick={e => removerItemCentroLucro(centro.index)} color="primary" aria-label="remove" disabled={false}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td>Restante</td>
                <td>
                  <TextField 
                  type='number' 
                  className="inputValor" 
                  variant="outlined" 
                  size="small" 
                  name="valor_percentual" 
                  value={(100 - somarValores(dados.centro_lucros.map(centro => { return parseFloat(centro.valor_percentual)}))).toFixed(2)} 
                  InputLabelProps={{ shrink: true }} 
                  disabled
                />
                </td>
                <td>
                  {somarValores(dados.centro_lucros.map(centro => { return parseFloat(centro.valor_percentual)})) !== 100 &&
                    <Tooltip title='Rateio inferior/superior a 100%' aria-label="add">
                      <IconButton color="error" aria-label="remove">
                        <WarningIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
    </Grid>
    <hr className="mg_top_10"/>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12}>
        <h4 className="titulo">Rateio do Centro de Custo (Despesas):</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Plano de Contas*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="planocontas_id"
          size="small"
          value={dados.planocontas_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.visibilidade === '' ? true: false}
        >
          <option value="">Selecionar Plano de Contas</option>
          {planoContas.filter(param => param.tipo_saida_entrada === 'DESPESA').map(plano => {
            return (
              <option value={plano.id} disabled={plano.totalizador} className={plano.totalizador ? 'agrupador' : ''}  >{plano.codigo} - {plano.descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Centro de Custo*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="centrocusto_id"
          value={centro_custo.centrocusto_id}
          onChangeCapture={(e) => updateFieldCentroCusto(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={centro_custo.centrocusto_id !== '' ? centro_custo.centrocusto_id : ''}> {centro_custo.centrocusto_id !== '' ? centro_custo.centrocusto : 'Selecionar Centro de Custo'} </option>
          {centros_custo.filter(param => {
            const index = dados.centro_custos.findIndex(value => parseInt(value.centrocusto_id) === parseInt(param.id))
            if(index === -1) return true
            return false
          }).sort((a, b) => (a.centro_custo.descricao > b.centro_custo.descricao) ? 1 : ((b.centro_custo.descricao > a.centro_custo.descricao) ? -1 : 0)).map(centro => {
            return (
              <option value={centro.id}> {centro.centro_custo.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <ColorDarkOrangeButton fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addCentroCusto(e)}>{updateCentro ? 'Alterar' : 'Adicionar'}</ColorDarkOrangeButton>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={6} xs={12} sm={12}>
        <div className='scroll'>
          <table className="lista sempadding borderTable">
            <thead>
              <tr className="titulos acompanha">
                <th>Centro de Custo</th>
                <th>Porc. Rateio*</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {dados.centro_custos.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(centro => {
                return (
                  <tr key={centro.index}>
                    <td>{centro.centrocusto}</td>
                    <td>
                      <TextField 
                      type='number' 
                      className="inputValor" 
                      variant="outlined" 
                      size="small" 
                      name="valor_percentual" 
                      value={centro.valor_percentual} 
                      InputLabelProps={{ shrink: true }} 
                      onChange={(e) => updateFieldCentro(e, centro.index)} 
                      // onBlur={(e) => ajustaValorCentro(e, centro.index)}
                      InputProps={{ inputProps: { max: 100 } }}
                      disabled={false}
                    />
                    </td>
                    <td>
                      <Tooltip title='Remover' aria-label="add">
                        <IconButton onClick={e => removerItemCentroCusto(centro.index)} color="primary" aria-label="remove" disabled={false}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td>Restante</td>
                <td>
                  <TextField 
                  type='number' 
                  className="inputValor" 
                  variant="outlined" 
                  size="small" 
                  name="valor_percentual" 
                  value={(100 - somarValores(dados.centro_custos.map(centro => { return parseFloat(centro.valor_percentual)}))).toFixed(2)} 
                  InputLabelProps={{ shrink: true }} 
                  disabled
                />
                </td>
                <td>
                  {somarValores(dados.centro_custos.map(centro => { return parseFloat(centro.valor_percentual)})) !== 100 &&
                    <Tooltip title='Rateio inferior/superior a 100%' aria-label="add">
                      <IconButton color="error" aria-label="remove">
                        <WarningIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
    </Grid>
  </React.Fragment >
)

export default FormCentroCustoProduto