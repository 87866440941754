import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button,TextField} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import real from './../../services/real'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Cancelamento de Venda</h5>
            </Grid>
          </Grid>
          {props.dados.valor > 0 && 
            <React.Fragment>
              <br />
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <p>Existe lançamento financeiro já conciliados nessa venda, por favor informar como será feita a devolução do valor</p>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={3} xs={12} sm={3}>
                  <Autocomplete
                    onChange={(event, value) => props.updateFieldItem(event, value)}
                    freeSolo
                    options={props.itens_receita_despesa}
                    value={props.itemSelecionado}
                    getOptionLabel={option => `${option.descricao}`}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Item Receita/Despesa*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${props.itemSelecionado.descricao}`} />
                    )}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="Valor*" 
                    variant="outlined" 
                    name="valor" 
                    size="small"
                    value={real(props.dados.valor)} 
                    InputLabelProps={{ shrink: true }}
                    disabled={true} />
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="Data do Pagamento*" 
                    variant="outlined" 
                    name="data_vencimento" 
                    size="small"
                    type='date'
                    value={props.dados.data_vencimento} 
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => props.updateField(e)} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Forma de Pagamento*" 
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="formapagto_id"
                    value={props.dados.formapagto_id}
                    onChange={(e) => props.updateField(e)} 
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    {props.formasPagamento.map(forma => {
                      return (
                        <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                <TextField
                    id="standard-select-currency"
                    select
                    label="Conta Financeira*" 
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="contfin_id"
                    value={props.dados.contfin_id}
                    onChange={(e) => props.updateField(e)} 
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    {props.contasFinanceira.filter(param => param.tipo_conta === props.dados.habilita || (param.tipo_conta === "CAIXATURNO" && props.logado === 'caixa') ).map(conta => {
                      return (
                        <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <hr className="mg_top_10"/>
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                className="input"
                label="Motivo*"
                variant="outlined"
                size="small"
                multiline
                rows={3}
                name="motivoCancelar"
                value={props.motivoCancelar}
                onChange={(e) => props.updateFieldMotivo(e)}
                InputLabelProps={{ shrink: true }}
                placeholder="!!!OBRIGATORIO!!!" />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <span>Deseja continuar com o cancelamento dessa venda? </span>
            </Grid>
          </Grid>
          <hr />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={12} sm={4}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button 
                fullWidth 
                color="primary" 
                variant="contained" 
                className="btn_salvar" 
                size="small" 
                startIcon={<CheckIcon />}  
                onClick={e => props.confirmar(true)} 
                disabled={props.motivoCancelar === ''}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
