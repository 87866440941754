import React, { useState, useEffect } from 'react';
import './Caixa.scss'
import './../../main/ultil.scss'
import Alert from './../../components/templates/Alert'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import axios from 'axios'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, Button, TextField } from '@mui/material'
import Loading from './../../components/templates/Loading'
import real from '../../services/real'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router-dom';
import CurrencyInput from './../../components/mask/CurrencyInput'

const initialState = {
  dadosAprovacao: {
    valor_fundo_troco: '',
    abertura_aprovado: false
  },
  solicitacao: {},
  contaCofre: {},
  loading: true,
  mensagemLoading: 'Carregando Solicitação...',
  modalErro: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  }
}

const SolicitacaoAberturaCaixa = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { solicitacaoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    const self = this

    setTimeout(() => {
      navigate("/home");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const aprovar = async (value) => {
    try {

      const { dadosAprovacao } = state

      dadosAprovacao.abertura_aprovado = value
      dadosAprovacao.valor_fundo_troco = parseFloat(dadosAprovacao.valor_fundo_troco.replace("R$ ", "").replaceAll(".", "").replace(",", "."))

      await axios.post(`${window._env_.REACT_APP_API_URL}/caixa/aprovar-solicitacao-abertura/${solicitacaoId}`, dadosAprovacao, getToken())

      if (value) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'Success',
            message: 'Caixa Liberado para Uso.'
          }
        }))
      } else {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'Success',
            message: 'Abertura de caixa reprovado.'
          }
        }))
      }

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateField = (event) => {
    const { dadosAprovacao } = state

    dadosAprovacao[event.target.name] = event.target.value

    setState(prevState => ({...prevState, dadosAprovacao }))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/solicitacao-abertura/${solicitacaoId}`, getToken())
  
        setState(prevState => ({...prevState,
          solicitacao: data.solicitacao,
          contaCofre: data.contaCofre,
          dadosAprovacao: {
            valor_fundo_troco: real(data.solicitacao.valor_fundo_troco),
            abertura_aprovado: false
          }
        }))
  
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
  
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { solicitacao, contaCofre, dadosAprovacao } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Solicitação Abertura de Caixa</h1>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={5} xs={12} sm={4}>
                  <TextField className="input" label="Usuário:" variant="outlined" size="small" value={solicitacao.usuario_solicitacao.pessoa.fisica.nome} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Data/Hora Solicitação:" variant="outlined" size="small" value={moment(solicitacao.criado_em).format('DD/MM/YYYY HH:mm')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Saldo Atual Cofre:" variant="outlined" size="small" value={real(contaCofre.saldo_atual)} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Fundo de Troco:*" variant="outlined" size="small" name='valor_fundo_troco' value={dadosAprovacao.valor_fundo_troco} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} onChange={(e) => updateField(e)} />
                </Grid>

              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={8} xs={false} sm={4}></Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    className="btn_salvar"
                    startIcon={<CheckIcon />}
                    onClick={e => aprovar(true)}
                    disabled={dadosAprovacao.valor_fundo_troco === ''}>
                    Aprovar
                    </Button>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    className="btn_salvar"
                    startIcon={<CloseIcon />}
                    onClick={e => aprovar(false)}>
                    Reprovar
                    </Button>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default SolicitacaoAberturaCaixa