import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
        <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Dados do Envio</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={4}>
              <TextField
                id="standard-select-currency"
                select
                label="Canal de Comunicação*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="canal"
                value={props.dados.canal}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true}} 
              >
                <option value=''>Selecionar Canal</option>
                <option value='WHATSAPP'> WhatsApp </option>
              </TextField>
            </Grid>
            <Grid item md={6} xs={12} sm={4}>
              <Autocomplete
                multiple
                limitTags={3}
                id="multiple-limit-tags"
                options={['FUNCIONARIO', 'CLIENTE', 'FORNECEDOR', 'RESPONSÁVEL', 'REVENDEDOR']}
                size="small"
                value={props.flagsSelecionadas}
                onChange={(event, value) => props.updateFieldFlags(event, value)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Flags*" InputLabelProps={{ shrink: true}} />
                )}
              />
            </Grid>
          </Grid>
          <br />
          <hr />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.disabledButtons}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.disabledButtons}>Continuar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div> 
  );
}
