import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Cliente.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button, TextField } from '@mui/material'
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import decrypt from './../../services/decrypt'
import ModalAssinarContrato from './../../components/modals/AssinarContrato'
import { saveAs } from 'file-saver';
import Tooltip from '@mui/material/Tooltip';
import ModalAjuda from './../../components/modals/Ajuda'
import HelpIcon from '@mui/icons-material/Help';
import getToken from './../../services/getToken'

const initialState = {
  loading: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  localizacao: '',
  contrato: {
    id: '',
    codigo: '',
    estado_contrato: 'AGUARDANDO_ASSINATURA',
    resp_fin: null,
    contrato_html: '',
    codigo_assinatura: '',
    cliente: {
      pessoa: {
        tipo: '',
        fisica: {
          nome: ''
        },
        juridica: {
          nomefantasia: ''
        }
      }
    },
    documento: {
      contaluno_criado_em: ''
    }
  },
  openModalAssinarContrato: false,
  codigo: '',
  travaBotao: false,
  openModalAjuda: false,
  markdown: ''
}

let sigCanvas = {}

const AssinarContrato = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { contrato_id } = useParams();

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const openModalAssinarContrato = () => {
    setState(prevState => ({...prevState,
      openModalAssinarContrato: true
    }))
  }

  const updateField = (event) => {
    setState(prevState => ({...prevState,
      codigo: event.target.value
    }))
  }

  const assinarContrato = async (value, sigCanvas) => {
    if(value){
      setState(prevState => ({...prevState,travaBotao: true}))

      try {
        const {codigo, contrato, localizacao} = state
        
        
        let base64Assinatura = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        
        if(codigo === '') {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o código'
            },
            travaBotao: false
          }))
          return
        }

        if(sigCanvas.current.isEmpty()) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor escrever a sua assinatura'
            },
            travaBotao: false
          }))
          return
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            autoHideDuration: 10000,
            severity: 'info',
            message: 'Assinando Contrato...'
          }
        }))
  
        const res = await axios.get("https://api.ipify.org/?format=json");

        let dados = {
          contrato: contrato_id,
          codigo,
          base64Assinatura,
          localizacao: localizacao,
          ip: res.data.ip
        }

        const respImprimir = await axios.post(`${window._env_.REACT_APP_API_URL}/contrato/assinarContrato`, dados, getToken())

        const dadosImprimir = { path: respImprimir.data }
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Contrato-Assinado.pdf`); 

        contrato.estado_contrato = 'ATIVO'
  
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'success',
            message: 'Contrato Assinado com sucesso'
          },
          codigo: '',
          loadingSalvar: false,
          openModalAssinarContrato: false,
          contrato,
          travaBotao: false
        }))

        return

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          travaBotao: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }
    }else{
      handleCloseModalAssinarContrato()
    }
    
  }

  const baixarContrato = async () => {
    try {

      let {contrato, codigo} = state

      setState(prevState => ({...prevState,
        travaBotao: true
      }))

      if(codigo === '') {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o código.'
          },
          travaBotao: false
        }))
        return
      }

      if(codigo !== decrypt(contrato.codigo_assinatura)){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Código Invalido.'
          },
          travaBotao: false
        }))
        return
      }

      setState(prevState => ({...prevState,
        loadingImprimir: true,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Carregando Contrato'
        }
      }))

      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/contrato/viewContrato/${contrato.id}`, getToken())
      
      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingImprimir: false,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

      saveAs(pdfBlob, `Contrato_${contrato.id}.pdf`)

    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseModalAssinarContrato = () => {
    setState((prevState) => ({
      ...prevState,
      openModalAssinarContrato: false
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/cliente/AssinarContrato.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
      
            setState(prevState => ({...prevState,
              localizacao: `${latitude}, ${longitude}`
            }))
          },
          (error) => {
            setState(prevState => ({...prevState,
              localizacao: 'Localização não compartilhada pelo usuário'
            }))
          }
        );
      }else {
        setState(prevState => ({...prevState,
          localizacao: 'Localização não compartilhada pelo usuário'
        }))
      }

      try {
        console.log(this)
        
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/contratoAssinatura/${contrato_id}`, getToken())

        let resp = JSON.parse(decrypt(data))

        let resp_fin = null
        
        if(resp.resp_fin.length > 0){
          resp_fin = resp.resp_fin[0]
        }

        setState(prevState => ({...prevState,
          contrato: {
            id: resp.id,
            codigo: resp.codigo,
            estado_contrato: resp.statusAtual.estado_contrato,
            documento: resp.documento,
            cliente: resp.cliente,
            contrato_html: resp.contrato_html.replaceAll('%%ASSINATURA_UNIDADE_E_CLIENTE','').replaceAll('%%ASSINATURA_UNIDADE_UNICA', '').replaceAll('%%ASSINATURA_CLIENTE_UNICA', ''),
            codigo_assinatura: resp.codigo_assinatura,
            resp_fin
          }
        }))
        
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          loading: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }
    };
    
    fetchData();
  }, []);

  const { contrato, codigo } = state

  return (
    <div className="container-boletos" id="app">
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Contas...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <React.Fragment>
          {contrato.estado_contrato === "AGUARDANDO_ASSINATURA" &&
            <React.Fragment>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                  <p>Olá {contrato.resp_fin ? (contrato.resp_fin.pessoa.tipo === 'FISICA' ? contrato.resp_fin.pessoa.fisica.nome : contrato.resp_fin.pessoa.juridica.nomefantasia) : (contrato.cliente.pessoa.tipo === 'FISICA' ? contrato.cliente.pessoa.fisica.nome : contrato.cliente.pessoa.juridica.nomefantasia)}, segue a baixo {contrato.resp_fin ? ` o contrato do ${(contrato.cliente.pessoa.tipo === 'FISICA' ? contrato.cliente.pessoa.fisica.nome : contrato.cliente.pessoa.juridica.nomefantasia)}` : ` o seu contrato`} Cod.: {contrato.codigo}</p>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={4} xs={12} sm={4}></Grid>
                <Grid item md={4} xs={12} sm={4} >
                  <Button fullWidth variant="contained" color="primary" onClick={() => openModalAssinarContrato()}>
                    Assinar Contrato
                  </Button>
                </Grid>
                <Grid item md={4} xs={12} sm={4}></Grid>
              </Grid>
            </React.Fragment>
          }
          {contrato.estado_contrato === "ATIVO" &&
            <React.Fragment>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                  <p>Olá {contrato.resp_fin ? contrato.resp_fin.pesf_nome : (contrato.cliente.pessoa.tipo === 'FISICA' ? contrato.cliente.pessoa.fisica.nome : contrato.cliente.pessoa.juridica.nomefantasia)}, o contrato de Cod.: {contrato.id}, já foi assinado.</p>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={4} xs={12} sm={4} ></Grid>
                <Grid item md={2} xs={12} sm={4} >
                  <TextField
                    className="input" 
                    label="Código Recebido*" 
                    variant="outlined" 
                    size="small" 
                    name="codigo"
                    value={codigo} 
                    onChange={(e) => updateField(e)} 
                    InputLabelProps={{ shrink: true }} 
                    inputProps={{ maxLength: 6 }}
                  />   
                </Grid>
                <Grid item md={2} xs={12} sm={4} >
                  <Button fullWidth variant="contained" color="secondary" onClick={() => baixarContrato()}>
                    Baixar Contrato
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <Grid container spacing={2} direction="row" className="mg_top_20">
            <Grid item md={4} xs={12}></Grid>
            <Grid item md={4} xs={12}>
              <img className='logo_mais_financeiro_login' src="https://sistema.maisfinanceiro.com.br/images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
            </Grid>
          </Grid>
          <Tooltip title='Ajuda' aria-label="add" arrow>
            <HelpIcon style={{bottom: 20}} className='botao_ajuda' onClick={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}/>
          </Tooltip>
          <span className='clicksistemas'>Desenvolvido por Click Sistemas LTDA 2021 ® </span>
        </React.Fragment>
      }

      <ModalAssinarContrato
        open={state.openModalAssinarContrato}
        handleClose={e => handleCloseModalAssinarContrato()} 
        contrato={contrato}
        codigo={codigo}
        updateField={(e) => updateField(e)}
        confirmar={(e, sigCanvas) => assinarContrato(e, sigCanvas)}
        // limpar={(e) => limpar(e)}
        baixarContrato={() => baixarContrato()}
        sigCanvas={sigCanvas}
        travaBotao={state.travaBotao}
      />

      <Alert
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()}
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />

      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Minhas Contas"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false }))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default AssinarContrato