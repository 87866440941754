import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, IconButton, Button } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import PanToolIcon from '@mui/icons-material/PanTool';
import Color from 'color';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const ColorGreenButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  backgroundColor: Color('#008000').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#008000',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  let { clientes, cliente, pessoa} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Importar Dados:</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <Autocomplete
                onChange={(event, value) => props.updateFieldCliente(event, value)}
                freeSolo
                options={clientes.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)}
                value={cliente}
                id='cliente'
                getOptionLabel={option => `${option.nome}`}
                renderInput={(params) => (
                  <TextField 
                    InputProps={{ ...params.InputProps, type: 'search' }} 
                    {...params} 
                    size="small" 
                    label="Selecionar Pessoa*" 
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }} 
                    value={`${cliente.nome}`} 
                    onChange={(e) => props.updateFiltroPessoa(e)}
                  />
                )}
              />
            </Grid>
            <Grid item md={1} xs={12} sm={2}>
              <Tooltip title='Buscar Cliente' aria-label="add">
                <IconButton color="primary" aria-label="remove" style={{marginTop: '-5px', marginLeft: '0px'}} onClick={e => props.buscarPessoa()}>
                  <PanToolIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {cliente.pessoa_id !== '' &&
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <span>Todos os dados de <b>{cliente.nome}</b> serão importados para <b>{pessoa.tipoPessoa === 'FISICA' ? (pessoa.pessoaFisica.nome + `${pessoa.pessoaFisica.sobrenome ? ` ${pessoa.pessoaFisica.sobrenome}` : ''}`) : pessoa.pessoaJuridica.nomeFantasia}</b>. *(agendamentos, vouchers, contratos, financeiro e dependentes) </span>
              </Grid>
              <Grid item md={12} xs={12} sm={12}>
                <span style={{fontSize: 18}}><b>Deseja continuar?</b></span>
              </Grid>
            </Grid>
          }

          <hr className="mg_top_10"/>

          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon/>} onClick={e => props.confirmar(false, false)}>Voltar</Button>
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon/>} onClick={e => props.confirmar(true, false)}>Confirmar</Button>
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <ColorGreenButton fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon/>} onClick={e => props.confirmar(true, true)}>Confirmar e Remover Pessoa</ColorGreenButton>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
