import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
	marginTop: 100,
	width: '30%', 
	marginLeft: '35%',

	// Media Queries para diferentes tamanhos de tela
	[theme.breakpoints.only('xs')]: {
		width: '90%', // para telas pequenas 
		marginLeft: '5%',
	},

	[theme.breakpoints.only('md')]: {
		width: '50%', // para telas pequenas 
		marginLeft: '25%',
	}
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  const { dados, open, travarCampos} = props

  return (
    <div>
      <Modal
        open={open}
				onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <StyledPaper theme={theme}>
						<Grid container direction="row" spacing={1} className="borderBottom">
							<Grid item md={12} xs={12} sm={12}>
								<h5 className="tituloConfirmacao">Baixar Parcela</h5>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} className='mg_top_20'>
							<Grid item md={12} xs={12} sm={12}>
								<TextField
									id="standard-select-currency"
									select
									variant="outlined"
									className="input"
									fullWidth
									label="Conta*"
									size="small"
									SelectProps={{
										native: true,
									}}
									name="contfin_id"
									value={dados.contfin_id}
									onChangeCapture={(e) => props.updateField(e)}
									InputLabelProps={{ shrink: true }}
									disabled={travarCampos}
								>
									<option value="">--Escolha--</option>
									{props.contasFinanceira.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(conta => {
										return (
											<option key={conta.id} value={conta.id}>{conta.descricao}</option>
										)
									})}
								</TextField>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} className='mg_top_20'>
							<Grid item md={12} xs={12} sm={12}>
								<TextField
									id="standard-select-currency"
									select
									variant="outlined"
									className="input"
									fullWidth
									label="Forma de Pagamento*"
									size="small"
									SelectProps={{
										native: true,
									}}
									name="formapagto_id"
									value={dados.formapagto_id}
									onChangeCapture={(e) => props.updateField(e)}
									InputLabelProps={{ shrink: true }}
									disabled={dados.contfin_id === '' ? true : false}
								>
									<option value={dados.formapagto_id === '' ? '' : dados.formapagto_id}>{dados.formapagto_id === '' ? '--Escolha--' : dados.descricao}</option>
									{props.formasPagamento.map(forma => {
										if (forma.id !== dados.formapagto_id) {
											if (dados.tipo_conta === 'BANCO') {
												if (forma.slug !== 'dinheiro') {
													return (
														<option key={forma.id} value={forma.id}>{forma.descricao}</option>
													)
												}
											} else if (dados.tipo_conta === 'COFRE') {
												if (forma.slug === 'dinheiro') {
													return (
														<option key={forma.id} value={forma.id}>{forma.descricao}</option>
													)
												}
											} else {
												return (
													<option key={forma.id} value={forma.id}>{forma.descricao}</option>
												)
											}
										}
										return false
									})}
								</TextField>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} className='mg_top_20'>
							<Grid item md={12} xs={12} sm={12}>
								<TextField 
									type='date'
									className="input" 
									label="Data do Pagamento" 
									variant="outlined" 
									name="data_pagamento" 
									size="small"
									value={dados.data_pagamento} 
									onChange={(e) => props.updateField(e)} 
									InputLabelProps={{ shrink: true }}
									disabled={travarCampos}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} className='mg_top_10'>
							<Grid item md={6} xs={6} sm={6}>
								<Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.disabledButtonsSalvar}>Confirmar</Button>
							</Grid>
							<Grid item md={6} xs={6} sm={6}>
								<Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.disabledButtonsSalvar}>Cancelar</Button>
							</Grid>
						</Grid>
					</StyledPaper>
        </React.Fragment>
      </Modal>
    </div >
  );
}
