const run = () => {
  const USER_TOKEN = localStorage.getItem('token')

  const config = {
    headers: {
      'Authorization': 'Bearer ' + USER_TOKEN,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }

  return config
}

export default run