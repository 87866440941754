import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'

import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import Autocomplete from '@mui/material/Autocomplete';

const FormTabelaPreco = ({ dados, updateField, produto, produtos, updateFieldProduto, addItem,
  updateFieldProdutoAtivo, updateFieldProdutoPreco, update, unidades, updateFieldUnidade,
  unidadesSelecionadas, tipo, updateFieldPadrao, inputRef, grupoProdutos, subGrupoProdutos, produto_selecionado }) => (
    
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      {tipo !== 'UNIDADE' &&
        <Grid item md={3} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Unidade"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="unidade_id"
            value={dados.unidade_id}
            onChangeCapture={(e) => updateField(e)}
            InputLabelProps={{ shrink: true }}
            disabled={dados.itens.length > 0}
          >
            <option value=""> Selecionar Unidade</option>
            {unidades.map(unidade => {
              return (
                <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
              )
            })}
          </TextField>
        </Grid>
      }
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Descrição *" variant="outlined" size="small" name="descricao" value={dados.descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={dados.unidade_id === ''}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" type="date" label="Data de Inicio *" variant="outlined" size="small" name="data_inicio" value={dados.data_inicio} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={dados.unidade_id === ''}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" type="date" label="Data Final *" variant="outlined" size="small" name="data_fim" value={dados.data_fim} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={dados.unidade_id === ''}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Padrão?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Padrão"
                color="primary"
                checked={dados.padrao}
              />
            }
            size="small"
            label={dados.padrao ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldPadrao(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} style={{marginTop: -20}}>
      <h4 className="titulo">Selecionar Produto:</h4>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          className="input" 
          id="standard-select-currency"
          select
          variant="outlined"
          fullWidth
          size="small"
          label="Grupo Produto/Servico" 
          SelectProps={{
            native: true,
          }}
          name="grupo_id"
          value={produto.grupo_id}
          onChangeCapture={(e) => updateFieldProdutoPreco(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.unidade_id === '' || update}
        >
          <option value="">Selecionar Grupo</option>
          {grupoProdutos.map((grupo, key) => {
            return (
              <option key={key} value={grupo.id}>{grupo.descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input" 
          fullWidth
          size="small"
          label="Grupo Produto/Servico" 
          SelectProps={{
            native: true,
          }}
          name="sub_grupo_id"
          value={produto.sub_grupo_id}
          onChangeCapture={(e) => updateFieldProdutoPreco(e)}
          InputLabelProps={{ shrink: true }}
          disabled={produto.grupo_id === '' || update}
        >
          <option value="">Selecionar Grupo</option>
          {subGrupoProdutos.filter(param => param.idgrupoproduto === parseInt(produto.grupo_id)).map((subGrupo, key) => {
            return (
              <option key={key} value={subGrupo.id}>{subGrupo.descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={12}>
        <Autocomplete
          onChange={(event, value) => updateFieldProduto(event, value)}
          freeSolo
          options={produtos.filter(param => {
            let index = dados.itens.findIndex(value => value.produto_id === param.id)

            if(index !== -1){
              return false
            }

            return true
          }).sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0)}
          value={produto_selecionado}
          getOptionLabel={option => `${option.codigo ? `${option.codigo} - ` : ''}${option.descricao}`}
          disabled={produto.sub_grupo_id === '' || update}
          renderInput={(params) => (
            <TextField 
              InputProps={{ ...params.InputProps, type: 'search' }} 
              {...params} 
              size="small" 
              label="Produto/Serviço*" 
              margin="normal" 
              variant="outlined" 
              InputLabelProps={{ shrink: true }} 
              value={`${produto_selecionado.codigo ? `${produto_selecionado.codigo} - ` : ''}${produto_selecionado.descricao}`}
              disabled={produto.sub_grupo_id === '' || update}
            />
          )}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Preço Mínimo *" size="small" variant="outlined" name="valor_minimo" value={produto.valor_minimo} onChange={(e) => updateFieldProdutoPreco(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput, inputRef }} disabled={dados.unidade_id === ''}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Preço Máximo *" size="small" variant="outlined" name="valor_maximo" value={produto.valor_maximo} onChange={(e) => updateFieldProdutoPreco(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput, inputRef }} disabled={dados.unidade_id === ''}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" style={{marginLeft: 20}}> 
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={produto.ativo}
              />
            }
            size="small"
            label={produto.ativo ? 'Ativo' : 'Inativo'}
            name="ativo"
            onChange={(e) => updateFieldProdutoAtivo(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={6} xs={false} sm={6}></Grid>
      <Grid item md={2} xs={12} sm={6}>
        <Button fullWidth color="warning" variant="contained" className="btn_salvar" size="small" startIcon={update ? <CreateIcon /> : <AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <hr />
  </React.Fragment>
)

export default FormTabelaPreco