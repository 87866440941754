import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import Alert from './../../../components/templates/Alert'
import {filesize} from "filesize";
import UploadClientes from './../../../components/modals/UploadClientes'
import { saveAs } from 'file-saver';
import moment from 'moment';

const initialState = {
  unidade: {},
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openUploadCliente: false,
  uploadedFiles: [],
  unidades: {
    list: []
  },
  uploadClientes:{
    unidade_id: '',
    data_ini_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    data_fim_vig: '',
    informar_contrato: ''
  },
  loadingSalvar: false,
  html_resposta: '',
  qtd_linhas_planilha: 0,
  qtd_linhas_inseridas: 0
}

export default class Unidade extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
   
    this.setState({
      loading: false
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async openUploadCliente(){
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.filter(param => param.tipo === 'UNIDADE').sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
            let descricao = ''
            descricao = `${unidade.numero} - ${unidade.descricao}`
          
            return {
              id: unidade.id,
              descricao,
              tipo: unidade.tipo
            }

          })
        },
        openUploadCliente: true
      })

      if(unidades.length === 1){
        const {uploadClientes} = this.state
        uploadClientes.unidade_id = unidades[0].id
        this.setState({uploadClientes})
      }
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
    
  }

  handleCloseUploadClientes(){
    this.setState({
      openUploadCliente: false
    })
  }

  async downloadPlanilha(){
    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/downloadPlanilha`, {}, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      saveAs(new Blob([resp.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), 'Clientes.xlsx');

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  handleUploadPlanilha = files => {
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    this.setState({
      uploadedFiles
    })
  }

  async enviarPlanilha(){
    try {
      let { uploadClientes, uploadedFiles } = this.state

      let data = new FormData();

      if(uploadClientes.unidade_id === ''){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a unidade.'
          }
        })
      }

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Subindo Planilha...'
        },
        loadingSalvar: true
      })

      data.append("file", uploadedFiles[0].file, uploadedFiles[0].name);
      data.append("unidade_id", uploadClientes.unidade_id);
      data.append("data_inicio_contrato", uploadClientes.data_ini_vig);
      data.append("data_final_contrato", uploadClientes.data_fim_vig);
      data.append("tempo_contrato", uploadClientes.tempo_contrato);
      data.append("unidade_tempo", uploadClientes.unidade_tempo);
      data.append("informar_contrato", uploadClientes.informar_contrato);
      
      const USER_TOKEN = localStorage.getItem('token')

      const config = {
        headers: {
          'Authorization': 'Bearer ' + USER_TOKEN,
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }

      let {data: resp} = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/uploadPlanilha`, data, config)

      const dadosImprimir = {
        path: resp.pdf
      }

      const {data: resp_pdf} = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
      const pdfBlob = new Blob([resp_pdf], { type: 'application/pdf' });

      saveAs(pdfBlob, `Migracao.pdf`)

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loadingSalvar: false,
        html_resposta: resp.resposta,
        qtd_linhas_planilha: resp.qtd_linhas_planilha,
        qtd_linhas_inseridas: resp.qtd_linhas_inseridas
      })

      this.handleCloseUploadClientes()

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      })
    }
  }

  updateFieldUpload(event){
    let {uploadClientes} = this.state

    let name = event.target.name
    let value = event.target.value

    uploadClientes[name] = value
    
    if((name === 'data_ini_vig' || name === 'unidade_tempo' || name === 'tempo_contrato') && uploadClientes.data_ini_vig !== '' && uploadClientes.unidade_tempo !== '' && uploadClientes.tempo_contrato !== ''){
      uploadClientes.data_fim_vig = moment(uploadClientes.data_ini_vig).add(uploadClientes.tempo_contrato, this.verificaUnidadeTempo(uploadClientes.unidade_tempo)).subtract(1,'day').format('YYYY-MM-DD')   
    }

    this.setState({uploadClientes})
  }

  verificaUnidadeTempo(value){
    if(value === 'DIA'){
      return 'day'
    }else if (value === 'MÊS'){
      return 'month'
    }else if (value === 'ANO'){
      return 'year'
    }
  }

  updateFieldInformarContrato(){
    let {uploadClientes} = this.state

    uploadClientes.informar_contrato = !uploadClientes.informar_contrato

    this.setState({
      uploadClientes
    })
  }

  render() {
    const { uploadedFiles, html_resposta, qtd_linhas_planilha, qtd_linhas_inseridas } = this.state
    
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Midias...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
              <Grid item md={6} xs={12} sm={12}>
                <h1 className="titulo">Migrações: </h1>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              <Grid item md={2} xs={12} sm={6}>
                <Button onClick={() => this.openUploadCliente()} size="small" className="btnCadastrar" variant="contained" color="primary">
                  Upload Clientes
                </Button>
              </Grid>
            </Grid>
            {html_resposta !== '' &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4>Resultado da Migração:</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <p>Quantidade de Linhas na Planilha: {qtd_linhas_planilha}</p>
                    <p>Quantidade de Linhas Inseridas: {qtd_linhas_inseridas}</p>
                    <p>Quantidade de Linhas não Inseridas: {qtd_linhas_planilha - qtd_linhas_inseridas}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4>Pendências:</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={1} xs={false} sm={1}></Grid>
                  <Grid item md={10} xs={12} sm={10}>
                    <div style={{border: '1px solid #000', padding: '20px'}} dangerouslySetInnerHTML={{__html: html_resposta}} />  
                  </Grid>
                  <Grid item md={1} xs={false} sm={1}></Grid>
                </Grid>
              </React.Fragment>
            }
          </div>
        }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <UploadClientes 
          open={this.state.openUploadCliente}
          handleClose={e => this.handleCloseUploadClientes()}
          downloadPlanilha={() => this.downloadPlanilha()}
          handleUpload={(e) => this.handleUploadPlanilha(e)}
          uploadedFiles={uploadedFiles}
          unidades={this.state.unidades.list}
          uploadClientes={this.state.uploadClientes}
          confirmar={() => this.enviarPlanilha()}
          updateField={(e) => this.updateFieldUpload(e)}
          loadingSalvar={this.state.loadingSalvar}
          updateFieldInformarContrato={() => this.updateFieldInformarContrato()}
        />
      </React.Fragment>
    )
  }
}