import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'

const FormContaFinanceira = ({ dados, updateField, unidades, tipo, updateFieldAtivo, update }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      {/* <Grid item md={1} xs={12} sm={2}>
        <TextField className="input" label="Ordem" variant="outlined" size="small" name="ordem" value={dados.ordem} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid> */}
      <Grid item md={4} xs={12} sm={5}>
        <TextField className="input" label="Descrição*" variant="outlined" size="small" name="descricao" value={dados.descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      {tipo !== 'UNIDADE' &&
        <Grid item md={4} xs={12} sm={5}>
          <TextField
            id="standard-select-currency"
            select
            label="Unidade"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="unidade_id"
            value={dados.unidade_id}
            onChangeCapture={(e) => updateField(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value=""> Selecionar Unidade</option>
            {unidades.map((unidade, key) => {
              return (
                <option key={key} value={unidade.unidade_id}>{unidade.tipo} - {unidade.descricao}</option>
              )
            })}
          </TextField>
        </Grid>
      }
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Saldo Incial*" variant="outlined" size="small" name="saldo_inicial" value={dados.saldo_inicial} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={update} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Saldo Atual" variant="outlined" size="small" name="saldo_atual" value={dados.saldo_atual} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled />
      </Grid> 
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" type='date' label="Data do Saldo Inicial" variant="outlined" size="small" name="data_abertura" value={dados.data_abertura} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={update}/>
      </Grid> 
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Limite de Crédito" variant="outlined" size="small" name="limite" value={dados.limite} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
      </Grid> 
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo Conta*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{native: true,}}
          size="small"
          name="tipo_conta"
          value={dados.tipo_conta}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.pes_id === ''  ? true : false}
        >
          <option value=''> Selecionar Tipo da Conta </option>
          <option value='COFRE'> COFRE </option>
          <option value='BANCO'> BANCO </option>
          <option value='CAIXATURNO'> CAIXA TURNO </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Conta Cofre Unidade?*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{ native: true}}
          size="small"
          name="cofre"
          value={dados.cofre}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value='Sim'> Sim </option>
          <option value='Não'> Não </option>
        </TextField>
      </Grid>
      {/* <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Conciliavel*"
          variant="outlined"
          className="input"
          size="small"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="conciliavel"
          value={dados.conciliavel}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=''> Selecionar </option>
          <option value={true}> Sim </option>
          <option value={false}> Não </option>
        </TextField>
      </Grid> */}
      <Grid item md={2} xs={12} sm={4}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.ativo}
            />
          }
          label="Ativo"
          name="ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
       
    </Grid>
  </React.Fragment>
)

export default FormContaFinanceira