import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import real from './../../services/real'

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
	marginTop: 50,
	width: '30%', 
	marginLeft: '35%',

	// Media Queries para diferentes tamanhos de tela
	[theme.breakpoints.only('xs')]: {
		width: '90%', // para telas pequenas 
		marginLeft: '5%',
	},

	[theme.breakpoints.only('md')]: {
		width: '50%', // para telas pequenas 
		marginLeft: '25%',
	}
}));

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default function SimpleModal(props) {
  const theme = useTheme();
  const { dados, open, travarCampos} = props

  return (
    <div>
      <Modal
        open={open}
				onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <StyledPaper theme={theme}>
						<Grid container direction="row" spacing={1} className="borderBottom">
							<Grid item md={12} xs={12} sm={12}>
								<h5 className="tituloConfirmacao">Antecipar Parcelas</h5>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} style={{marginTop: 5}}>
              <Grid item md={12} xs={12} sm={12}>
                <span>Valores Abertos: R$ {real(somarValores(props.contas.map(value => {return value.valor_total})))}</span>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
              <Grid item md={12} xs={12} sm={12}>
                <Button size='small' fullWidth variant="contained" color="success" onClick={() => props.quitarParcelas()}>
                  Quitar Todas
                </Button>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
              <Grid item md={12} xs={12} sm={12}>
                <Button size='small' fullWidth variant="contained" color="primary" onClick={() => props.openSelecionarParcelas()}>
                  Selecionar Parcelas
                </Button>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
              <Grid item md={12} xs={12} sm={12}>
                <Button size='small' fullWidth variant="contained" color="secondary" onClick={props.handleClose}>
                  Voltar
                </Button>
              </Grid>
            </Grid>
					</StyledPaper>
        </React.Fragment>
      </Modal>
    </div >
  );
}
