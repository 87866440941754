import React, { Component } from 'react'
import './Franqueado.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, TextField } from '@mui/material'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  franqueado: {

  },
  franqueados: {
    list: []
  },
  franqueadosFiltrado: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Grupos...',
  cabecalho: [
    { id: 'franqueado', numeric: false, disablePadding: true, label: 'Grupo', edit: true, link: "/franqueado/update/", tooltip: "Editar Franqueado" },
    { id: 'responsavel', numeric: false, disablePadding: false, label: 'Responsavel', edit: true, link: "/franqueado/updateResponsavel/", tooltip: "Editar Responsavel" },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade', edit: false, link: "" },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo Pessoa', edit: false, link: "" },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo', edit: false, link: "" }
  ],
  acoesTabela: ['view'],
  filtro: {
    responsavel: "",
    franqueado: "",
    cidade: "",
    tipo: '',
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  tipo_unidade: 'UNIDADE',
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Franqueados extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    this.setState({
      permissoes: perfil.permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    let acoesTabela = ['view']

    if(tipo === 'MANTENEDOR'){
      let index = acoesTabela.findIndex(param => param === 'exibeMenu')
      if(index === -1){
        acoesTabela.push('exibeMenu')
      }
    }

    this.setState({
      tipo_unidade: tipo,
      acoesTabela
    })

    try {

      const resp = await axios.get(`${window._env_.REACT_APP_API_URL}/franqueados`, this.getToken())

      const franqueados = []

      resp.data.forEach(franqueado => {
        const enderecoPrincipal = franqueado.pessoa.enderecos.filter(u => u.principal)

        if (franqueado.pessoa.tipo === "FISICA") {
          franqueados.push({
            _id: franqueado.id,
            franqueado: franqueado.pessoa.fisica.nome,
            responsavel: franqueado.responsavel ? franqueado.responsavel.nome : '',
            cidade: enderecoPrincipal[0] ? `${enderecoPrincipal[0].cidade} - ${enderecoPrincipal[0].estado}` : '',
            tipo: franqueado.pessoa.tipo,
            exibe_menu: franqueado.exibe_menu,
            ativo: franqueado.status
          })
        } else {
          franqueados.push({
            _id: franqueado.id,
            franqueado: franqueado.pessoa.juridica.nomefantasia,
            responsavel: franqueado.responsavel ? franqueado.responsavel.nome : '',
            cidade: enderecoPrincipal[0] ? `${enderecoPrincipal[0].cidade} - ${enderecoPrincipal[0].estado}` : '',
            tipo: franqueado.pessoa.tipo,
            exibe_menu: franqueado.exibe_menu,
            ativo: franqueado.status
          })
        }
      });

      this.setState({
        franqueados: {
          list: franqueados,
        },
        franqueadosFiltrado: {
          list: franqueados
        },
        loading: false
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const franqueados = this.state.franqueados.list
    const filtro = this.state.filtro

    const list = franqueados.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      franqueadosFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async selecionarExibeMenu(row){

    try {
      let {franqueadosFiltrado} = this.state

      const unidades_acesso = JSON.parse(localStorage.getItem('unidades_acesso'))
      
      let representantes = unidades_acesso.franqueador.representantes

      let index_rep = representantes.findIndex(param => {
        let index_fraq = param.franquias.findIndex(param => param.id === row._id)

        if(index_fraq !== -1) return true

        return false
      })

      let index = franqueadosFiltrado.list.findIndex(param => param._id === row._id)

      if(index_rep !== -1){
        let index_franquia = representantes[index_rep].franquias.findIndex(param => param.id === row._id)
        representantes[index_rep].franquias[index_franquia].exibe_menu = !franqueadosFiltrado.list[index].exibe_menu
      }
      
      franqueadosFiltrado.list[index].exibe_menu = !franqueadosFiltrado.list[index].exibe_menu

      this.setState({
        franqueadosFiltrado
      })

      await axios.put(`${window._env_.REACT_APP_API_URL}/franqueados/updateExibeMenu/${row._id}`, {}, this.getToken())

      unidades_acesso.franqueador.representantes = representantes

      localStorage.setItem('unidades_acesso', JSON.stringify(unidades_acesso))

      // window.location.reload()

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  render() {
    const { filtro, franqueadosFiltrado } = this.state

    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid container spacing={1} direction="row" className="borderBottom">
                  <Grid item md={12}>
                    <h1 className="titulo">Grupos Econômicos</h1>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Grupo" variant="outlined" size="small" name="franqueado" value={filtro.franqueado} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Responsavel" variant="outlined" size="small" name="responsavel" value={filtro.responsavel} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Cidade" variant="outlined" size="small" name="cidade" value={filtro.cidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Pessoa"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipo"
                      value={filtro.tipo}
                      onChangeCapture={(e) => this.updateFiltro(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">Pesquisar</option>
                      <option value="FISICA">
                        Pessoa Física
                        </option>
                      <option value="JURIDICA">
                        Pessoa Júridica
                        </option>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table 
                      urlView="/franqueado/view/" 
                      headCell={this.state.cabecalho} 
                      rows={franqueadosFiltrado.list} 
                      acoes={this.state.acoesTabela} 
                      selecionarExibeMenu={(e) => this.selecionarExibeMenu(e)}
                    />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Grupos Econômicos"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}