import React, { useState, useEffect } from 'react';
import './ParceirosBancarios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormParceirosBancarios from '../../components/forms/FormParceirosBancarios'
import FormServicosParceiro from '../../components/forms/FormServicosParceiro'
import FormBancosIsentos from './../../components/forms/FormBancosIsentos'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import moment from 'moment'
import real from './../../services/real'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  parceiro_bancario: {
    id: "",
    descricao: "",
    parceiro_id: "",
    pessoa_id: "",
    ativo: true,
    parceiro:{
      pessoa_id: '',
      nome: '',
      parceiro_id: ''
    },
    servicos: [],
    bancos_isentos: []
  },
  servico: {
    _id: '',
    valor: '',
    data_inicio: '',
    data_final: '',
    servico_bancario_id: '',
    cobrar_isentos: 'Sim',
    index: ''
  },
  servicos:{
    list: []
  },
  loadingSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  pessoas: {
    list: []
  },
  alteraServico: false,
  tab: 0,
  bancos: {
    list: []
  },
  banco: {
    id: "",
    banco_id: "",
    nome: "",
    numero: "",
    padraocosif: "",
    logo: "",
    index: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CadastroParceiroBancario = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { parceiroBancarioId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = (event) => {
    const parceiro_bancario = { ...state.parceiro_bancario }
    parceiro_bancario[event.target.name] = event.target.value

    setState(prevState => ({...prevState, parceiro_bancario }))
  }

  const updateFieldAtivo = () => {
    const parceiro_bancario = { ...state.parceiro_bancario }
    parceiro_bancario.ativo = !parceiro_bancario.ativo
    setState(prevState => ({...prevState, parceiro_bancario }))
  }

  const updateFieldServico = (event) => {
    const servico = { ...state.servico }
    servico[event.target.name] = event.target.value

    setState(prevState => ({...prevState, servico }))
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/parceiros_bancario");
    }, timeout)
  }

  const updateFieldParceiro = (event) => {
    const parceiro_bancario = { ...state.parceiro_bancario }
    parceiro_bancario.parceiro[event.target.name] = event.target.value

    if(event.target.name === 'pessoa_id'){
      const pessoaSelecionada = state.pessoas.list.filter(param => param.pessoa_id === parseInt(event.target.value))[0]
    
      parceiro_bancario.parceiro.nome = pessoaSelecionada.nome
    }

    setState(prevState => ({...prevState,parceiro_bancario}))
  }

  const validateFormServico = () => {
    const { servico } = state

    if (!servico.servico_bancario_id) return false
    if (!servico.valor) return false
    if (!servico.data_inicio) return false

    return true
  }

  const addServico = () => {
    if (!validateFormServico()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios do serviço (*).'
        }
      }))
      return
    }

    const {parceiro_bancario, servico, servicos} = state

    const itens = parceiro_bancario.servicos

    const index = itens.findIndex(param => {
      if(param.servico_bancario_id === servico.servico_bancario_id && servico.index !== param.index){
      if(moment(servico.data_inicio).isAfter(param.data_inicio) && moment(servico.data_inicio).isBefore(param.data_final)){
        return true
      }else if (moment(servico.data_final).isAfter(param.data_inicio) && moment(servico.data_final).isBefore(param.data_final)){
        return true
      }else if (moment(servico.data_inicio).isBefore(param.data_inicio) && moment(servico.data_final).isAfter(param.data_final)){
        return true
      }else{
        return false
      }
      }else{
        return false
      }

    })

    if(index !== -1){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Esse registro sobrepõe a data de outro serviço!'
        }
      }))
      return
    }

    if(servico.index === ''){
      itens.push({
        ...servico,
        servico_descricao: servicos.list.filter(param => param._id === parseInt(servico.servico_bancario_id))[0].descricao,
        index: itens.length
      })
    }else{
      itens[servico.index] = {
        ...servico,
        servico_descricao: servicos.list.filter(param => param._id === parseInt(servico.servico_bancario_id))[0].descricao,
        index: servico.index
      }
    }

    parceiro_bancario.servicos = itens

    setState(prevState => ({...prevState,
      parceiro_bancario,
      servico: {
        _id: '',
        valor: '',
        data_inicio: '',
        data_final: '',
        servico_bancario_id: '',
        cobrar_isentos: 'Sim',
        index: ''
      },
      alteraServico: false
    }))

  }

  const editarItemServico = (index) => {
    const {parceiro_bancario} = state

    setState(prevState => ({...prevState,
      servico: parceiro_bancario.servicos[index],
      alteraServico: true
    }))
  }
  
  const removerItemServico = (index) => {
    const parceiro_bancario = state.parceiro_bancario

    let itens = parceiro_bancario.servicos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        _id: itens[i]._id,
        valor: itens[i].valor,
        data_inicio: itens[i].data_inicio,
        data_final: itens[i].data_final,
        servico_bancario_id: itens[i].servico_bancario_id,
        servico_descricao: itens[i].servico_descricao,
        index: i
      })
    }

    itens = aux

    parceiro_bancario.servicos = itens

    setState(prevState => ({...prevState, parceiro_bancario }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState,
      tab: newValue
    }))
  };

  const updateFieldBanco = (event, value) => {
    if (!value) return false
    
    setState(prevState => ({...prevState,
      banco: {
        id: "",
        banco_id: value.id,
        nome: value.nome,
        numero: value.numero,
        padraocosif: value.padraocosif,
        logo: value.logo,
        index: ''
      }
    }))
  }

  const addBanco = () => {
    const {banco, parceiro_bancario} = state

    const itens = parceiro_bancario.bancos_isentos

    itens.push({
      ...banco,
      index: itens.length
    })

    parceiro_bancario.bancos_isentos = itens

    setState(prevState => ({...prevState,
      parceiro_bancario,
      banco: {
        id: "",
        banco_id: "",
        nome: "",
        numero: "",
        padraocosif: "",
        logo: "",
        index: ""
      }
    }))
  }

  const removerItemBanco = (index) => {
    const parceiro_bancario = state.parceiro_bancario

    let itens = parceiro_bancario.bancos_isentos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id: itens[i].id,
        banco_id: itens[i].banco_id,
        nome: itens[i].nome,
        numero: itens[i].numero,
        padraocosif: itens[i].padraocosif,
        ativo: itens[i].ativo,
        logo: itens[i].logo,
        index: i
      })
    }

    itens = aux

    parceiro_bancario.bancos_isentos = itens

    setState(prevState => ({...prevState, parceiro_bancario }))
  }

  const updateFieldAtivoBanco = () => {
    const banco = { ...state.banco }
    banco.ativo = !banco.ativo
    setState(prevState => ({...prevState, banco }))
  }

  const validateForm = () => {
    const { parceiro_bancario } = state

    if (!parceiro_bancario.descricao) return false
    if (!parceiro_bancario.parceiro.pessoa_id) return false

    return true
  }

  const salvar = async () => {

    setState(prevState => ({...prevState, loadingSalvar: true }))

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        },
        loadingSalvar: false
      }))
      return
    }

    const parceiro_bancario = state.parceiro_bancario

    const dados = {
      descricao: parceiro_bancario.descricao,
      ativo: parceiro_bancario.ativo,
      parceiro_id: parceiro_bancario.parceiro_id,
      pessoa_id: parseInt(parceiro_bancario.parceiro.pessoa_id),
      servicos: parceiro_bancario.servicos.map(servico => {
        return {
          id: servico._id,
          valor: parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")),
          data_inicio: servico.data_inicio,
          data_final: servico.data_final,
          servico_bancario_id: servico.servico_bancario_id,
          cobrar_isentos: servico.cobrar_isentos === 'Sim' ? true : false
        }
      }),
      bancos_isentos: parceiro_bancario.bancos_isentos.map(banco => {
        return {
          id: banco.id,
          banco_id: banco.banco_id
        }
      })
    }
    
    if(dados.servicos.length > 0){
      for (let i = 0; i < dados.servicos.length; i++) {
        if(dados.servicos[i].id === '' || !dados.servicos[i].id){
          delete dados.servicos[i].id
        }
        
        if(dados.servicos[i].data_final === '' || !dados.servicos[i].data_final){
          delete dados.servicos[i].data_final
        }
      }
    }else{
      delete dados.servicos
    }

    if(dados.bancos_isentos.length === 0){
      delete dados.bancos_isentos
    }else{
      for (let i = 0; i < dados.bancos_isentos.length; i++) {
        if(dados.bancos_isentos[i].id === '' || !dados.bancos_isentos[i].id){
          delete dados.bancos_isentos[i].id
        }
        
      }
    }

    try {

      const method = parceiro_bancario.id !== "" ? 'put' : 'post'
      const url = parceiro_bancario.id !== "" ? `/parceiroBancario/${parceiro_bancario.id}` : `/parceiroBancario`

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/parceirosBancarios/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'servicos-bancario')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

      setState(prevState => ({...prevState, tipo }))

      if (parceiroBancarioId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      try {
        const dados = {
          unidadesnegocio: [1]
        }

        let filtro = {
          nome: "",
          cpf: "",
          cnpj: "",
          flag: "FORNECEDOR",
          razaosocial: "",
          ativo: 'Sim',
          filtrar_por: "Nome - nome",
          value: ''
        }

        filtro.unidadesnegocio = dados.unidadesnegocio

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/list`, filtro, getToken())

        const pessoas = []

        data.filter(param => param.ativo).forEach(pessoa => {

          pessoas.push({
            pessoa_id: pessoa.id,
            nome: pessoa.tipo === 'FISICA' ? pessoa.fisica.nome : pessoa.juridica.razaosocial
          })
        })

        setState(prevState => ({...prevState,
          pessoas: {
            list: pessoas
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          loadingPessoas: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      try {
        
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/servicosBancarios`, getToken())

        let servicos = data.map(servico => {
          return {
            _id: servico.id,
            descricao: servico.descricao,
            ativo: servico.ativo
          }
        })
        
        setState(prevState => ({...prevState,
          servicos: {
            list: servicos
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      try {
        const { data: bancos } = await axios.get(`${window._env_.REACT_APP_API_URL}/bancos`, getToken())

        setState(prevState => ({...prevState,
          bancos: {
            list: bancos
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (parceiroBancarioId) {
        try {

          const { data: parceiro_bancario } = await axios.get(`${window._env_.REACT_APP_API_URL}/parceiroBancario/${parceiroBancarioId}`, getToken())

          setState(prevState => ({...prevState,
            parceiro_bancario: {
              id: parceiro_bancario.id,
              descricao: parceiro_bancario.descricao,
              parceiro_id: parceiro_bancario.parceiro_id,
              pessoa_id: parceiro_bancario.pessoa_id,
              ativo: parceiro_bancario.ativo,
              parceiro:{
                pessoa_id: parceiro_bancario.pessoa_id,
                nome: parceiro_bancario.pessoa.tipo === 'FISICA' ? parceiro_bancario.pessoa.fisica.nome : parceiro_bancario.pessoa.juridica.razaosocial,
              },
              servicos: parceiro_bancario.servicos_parceiro.map((servico, key) => {
                return {
                  _id: servico.id,
                  valor: real(servico.valor),
                  data_inicio: servico.data_inicio,
                  data_final: servico.data_final,
                  servico_bancario_id: servico.servico_bancario_id,
                  servico_descricao: servico.servico_bancario.descricao,
                  cobrar_isentos: servico.cobrar_isentos ? 'Sim' : 'Não',
                  index: key
                }
              }),
              bancos_isentos: parceiro_bancario.bancos_isentos.map((item, key) => {
                return {
                  id: item.id,
                  banco_id: item.banco.id,
                  nome: item.banco.nome,
                  numero: item.banco.numero,
                  padraocosif: item.banco.padraocosif,
                  logo: item.banco.logo,
                  index: key
                }
              })
            },
            loading: false
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
        }
      }
    };
    
    fetchData();
  }, []);

  const { parceiro_bancario, tab } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        <Grid
          container
          spacing={1}
          direction="row"
          className="borderBottom"
        >
          <Grid item md={11}>
            <h1 className="titulo">Cadastro de Parceiros Bancarios</h1>
          </Grid>
          <Grid item md={1}>
            {state.loadingSalvar &&
              <div>
                <CircularProgress />
              </div>
            }
          </Grid>
        </Grid>
        <AppBar position="static" className="mg_top_20">
          <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Dados do Parceiro" {...a11yProps(0)} />
            <Tab label="Serviços do Parceiro" {...a11yProps(1)} />
            <Tab label="Bancos Isentos" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={tab} index={0}>
          <FormParceirosBancarios 
            updateField={e => updateField(e)} 
            dados={parceiro_bancario} 
            updateFieldAtivo={e => updateFieldAtivo()}
            fornecedores={state.pessoas.list}
            updateFieldParceiro={e => updateFieldParceiro(e)}
          />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <FormServicosParceiro 
            dados={parceiro_bancario} 
            servicos={state.servicos.list}
            servico={state.servico}
            updateFieldServico={e => updateFieldServico(e)}
            addServico={e => addServico(e)}
            editarItemServico={e => editarItemServico(e)}
            alteraServico={state.alteraServico}
            removerItemServico={e => removerItemServico(e)}
          />
        </TabPanel>

        <TabPanel value={tab} index={2}>
          <FormBancosIsentos
            dados={parceiro_bancario} 
            bancos={state.bancos.list}
            banco={state.banco}
            updateFieldBanco={(e, value) => updateFieldBanco(e, value)}
            addBanco={e => addBanco(e)}
            removerItemBanco={e => removerItemBanco(e)}
            updateFieldAtivoBanco={e => updateFieldAtivoBanco(e)}
          />
        </TabPanel>

        <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
          <Grid item md={10} xs={12} sm={6}></Grid>
          <Grid item md={2} xs={12} sm={6}>
            <Button 
              fullWidth 
              color="primary" 
              variant="contained"  
              size="small" 
              startIcon={<SaveIcon />} 
              onClick={e => salvar(e)}>Salvar</Button>
          </Grid>
        </Grid>
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message}
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Parceiros Bancarios"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default CadastroParceiroBancario