import React, { Component } from 'react'
import './Pesquisa.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  pesquisas: {
    list: []
  },
  pesquisasFiltrado: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'titulo', numeric: false, disablePadding: true, label: 'Titulo Pesquisa' },
    { id: 'mensagem', numeric: false, disablePadding: true, label: 'Mensagem' },
    { id: 'enviado', numeric: false, disablePadding: true, label: 'Enviado' },
    { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  filtro: {
    descricao: "",
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

export default class WhatsApp extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'pesquisa')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('updatePesquisa')
    }

    acoesTabela.push('viewResultado')

    this.setState({
      acoesTabela,
      permissoes
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data: pesquisas } = await axios.post(`${window._env_.REACT_APP_API_URL}/pesquisa/list`, dados, this.getToken())

      this.setState({
        pesquisas: {
          list: pesquisas.map((value, key) => {
            return {
              _id: value.id,
              titulo: value.titulo,
              mensagem: value.mensagem,
              enviado: value.enviado,
              ativo: value.ativo,
              index: key
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { permissoes, pesquisas } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Números...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12} sm={6}>
                  <h1 className="titulo">Pesquisas</h1>
                </Grid>
                {permissoes.inserir &&
                  <Grid item md={3} xs={12} sm={6}>
                    <Link to="/pesquisa/cadastro">
                      <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Pesquisa
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate="/pesquisa/cadastro/" 
                    urlView="/pesquisa/view/" 
                    headCell={this.state.cabecalhoTabela} 
                    rows={pesquisas.list} 
                    acoes={this.state.acoesTabela} 
                  />
                </Grid>
              </Grid>
            </div>
          }

        </Main>
         <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Pesquisas"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}