import React from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

const TelefoneInput = ({ value, onChange, ...otherProps }) => {
  return (
    <InputMask
      mask={'+99 (99) 99999-9999'}
      maskChar={''}
      value={value}
      onChange={onChange}
      alwaysShowMask={false}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          {...otherProps}
          variant="outlined"
          fullWidth
          className="input"
          size='small'
          InputLabelProps={{ shrink: true }}
        />
      )}
    </InputMask>
  );
};

export default TelefoneInput;
