import React, { Component } from 'react'
import './PlanoContas.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import Loading from './../../components/templates/Loading'
import Alert from './../../components/templates/Alert'
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { saveAs } from 'file-saver'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  planoConta: {

  },
  planoContas: {
    list: []
  },
  planoContasFiltrado: {
    list: []
  },
  filtro: {
    codigo: "",
    descricao: ""
  },
  cabecalhoTabela: [
    { id: 'codigo', numeric: false, disablePadding: true, label: 'Codigo' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'unidade_cadastro', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  loading: true,
  mensagemLoading: 'Carregando Plano de Contas...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  planosSegundoNivel: {
    list: []
  },
  planosTerceiroNivel: {
    list: []
  },
  planosQuartoNivel: {
    list: []
  },
  planosQuintoNivel: {
    list: []
  },
  primeiroNivelSelecionado: false,
  segundoNivelSelecionado: false,
  terceiroNivelSelecionado: false,
  quartoNivelSelecionado: false,
  centros_custo: {
    list: []
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class PlanoContas extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/planoContas/PlanoContas.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'plano-contas')[0]

    let acoesTabela = []

    if (permissoes.visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/listGrupo`, dados,this.getToken())

      const planoContas = data.map((param, key) => {
        return {
          ...param,
          plano_selecionado: false,
          index: key
        }
      })

      this.setState({
        planoContas: {
          list: planoContas
        },
        planoContasFiltrado: {
          list: planoContas
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const planoContas = this.state.planoContas.list
    const filtro = this.state.filtro

    const list = planoContas.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      planoContasFiltrado: {
        list
      },
      filtro
    })

  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  selecionarPrimeiroNivel(plano){
    this.setState({
      segundoNivelSelecionado: false,
      terceiroNivelSelecionado: false,
      quartoNivelSelecionado: false,
      planosSegundoNivel: {
        list: []
      },
      planosTerceiroNivel: {
        list: []
      },
      planosQuartoNivel: {
        list: []
      },
      planosQuintoNivel: {
        list: []
      },
    })

    const planos = this.state.planoContasFiltrado.list

    for (let i = 0; i < planos.length; i++) {
      if(planos[i].index !== plano.index){
        planos[i].plano_selecionado = false
      }
    }

    let index = planos.findIndex(param => param.index === plano.index)

    planos[index].plano_selecionado = !planos[index].plano_selecionado
    
    this.setState({
      primeiroNivelSelecionado: planos[index].plano_selecionado
    })

    if(planos[index].plano_selecionado){
      this.setState({
        planosSegundoNivel: {
          list: plano.niveis_inferior.sort((a, b) => (a.sequencia > b.sequencia) ? 1 : ((b.sequencia > a.sequencia) ? -1 : 0)).map((value, key) => {
            return {
              ...value,
              index: key,
              plano_selecionado: false
            }
          })
        },
        planoContasFiltrado: {
          list: planos
        },
        centros_custo: {
          list: planos[plano.index].centro_custos.map(value => {
            return {
              valor_percentual: value.valor_percentual,
              centro_custo:{
                desc: value.centro_custo.descricao
              }
            }
          })
        }
      })
    }
  }

  selecionarSegundoNivel(plano){
    const planos = this.state.planosSegundoNivel.list

    this.setState({
      terceiroNivelSelecionado: false,
      quartoNivelSelecionado: false,
      planosTerceiroNivel: {
        list: []
      },
      planosQuartoNivel: {
        list: []
      },
      planosQuintoNivel: {
        list: []
      },
    })

    for (let i = 0; i < planos.length; i++) {
      if(planos[i].index !== plano.index){
        planos[i].plano_selecionado = false
      }
    }

    let index = planos.findIndex(param => param.index === plano.index)

    planos[index].plano_selecionado = !planos[index].plano_selecionado

    this.setState({
      segundoNivelSelecionado: planos[index].plano_selecionado
    })

    if(planos[index].plano_selecionado){
      this.setState({
        planosTerceiroNivel: {
          list: plano.niveis_inferior.sort((a, b) => (a.sequencia > b.sequencia) ? 1 : ((b.sequencia > a.sequencia) ? -1 : 0)).map((value, key) => {
            return {
              ...value,
              index: key,
              plano_selecionado: false
            }
          })
        },
        planosSegundoNivel: {
          list: planos
        },
        centros_custo: {
          list: planos[plano.index].centro_custos.map(value => {
            return {
              valor_percentual: value.valor_percentual,
              centro_custo:{
                desc: value.centro_custo.descricao
              }
            }
          })
        }
      })
    }
  }

  selecionarTerceiroNivel(plano){
    const planos = this.state.planosTerceiroNivel.list

    this.setState({
      quartoNivelSelecionado: false,
      planosQuartoNivel: {
        list: []
      },
      planosQuintoNivel: {
        list: []
      },
    })

    for (let i = 0; i < planos.length; i++) {
      if(planos[i].index !== plano.index){
        planos[i].plano_selecionado = false
      }
    }

    let index = planos.findIndex(param => param.index === plano.index)

    planos[index].plano_selecionado = !planos[index].plano_selecionado

    this.setState({
      terceiroNivelSelecionado: planos[index].plano_selecionado
    })

    if(planos[index].plano_selecionado){
      this.setState({
        planosQuartoNivel: {
          list: plano.niveis_inferior.sort((a, b) => (a.sequencia > b.sequencia) ? 1 : ((b.sequencia > a.sequencia) ? -1 : 0)).map((value, key) => {
            return {
              ...value,
              index: key,
              plano_selecionado: false
            }
          })
        },
        planosTerceiroNivel: {
          list: planos
        },
        centros_custo: {
          list: planos[plano.index].centro_custos
        }
      })
    }
  }

  // selecionarQuartoNivel(plano){
  //   const planos = this.state.planosQuartoNivel.list

  //   this.setState({
  //     planosQuintoNivel: {
  //       list: []
  //     },
  //   })

  //   for (let i = 0; i < planos.length; i++) {
  //     if(planos[i].index !== plano.index){
  //       planos[i].plano_selecionado = false
  //     }
  //   }

  //   planos[plano.index].plano_selecionado = !planos[plano.index].plano_selecionado

  //   this.setState({
  //     quartoNivelSelecionado: planos[plano.index].plano_selecionado
  //   })

  //   if(planos[plano.index].plano_selecionado){
  //     this.setState({
  //       planosQuintoNivel: {
  //         list: plano.niveis_inferior.sort((a, b) => (a.sequencia > b.sequencia) ? 1 : ((b.sequencia > a.sequencia) ? -1 : 0)).map((value, key) => {
  //           return {
  //             ...value,
  //             index: key,
  //             plano_selecionado: false
  //           }
  //         })
  //       },
  //       planosQuartoNivel: {
  //         list: planos
  //       }
  //     })
  //   }
  // }

  async imprimir(){
    let {planoContasFiltrado} = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      let filtro = {
        relatorio: 'plano-contas',
        unidadesnegocio: dados.unidadesnegocio,
        list: planoContasFiltrado.list
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, 'Planos-Contas.pdf')
      
    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  render() {
    const { permissoes, planoContasFiltrado, planosSegundoNivel, planosTerceiroNivel, centros_custo } = this.state

    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="borderBottom"
                >
                  <Grid item md={9} xs={12}>
                    <h1 className="titulo">Planos de Contas</h1>
                  </Grid>
                  {/* <Grid item md={2} xs={12}>
                    <Button className="btnCadastrar" variant="contained" color="secondary" onClick={() => this.imprimir()}>
                      Imprimir
                    </Button>
                  </Grid> */}
                  {permissoes.inserir &&
                    <Grid item md={3} xs={12}>
                      <Link to="/plano_contas/cadastro">
                        <Button className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar Plano de Contas
                        </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                    <Grid container direction="row" className='titulo'>
                      <Grid item md={8} xs={8} sm={6}>
                        <h6>Conta</h6>
                      </Grid>
                      {this.state.primeiroNivelSelecionado &&
                        <React.Fragment>
                          {!permissoes.alterar &&
                            <Grid item md={2} xs={2} sm={3}></Grid>
                          }
                          <Grid item md={2} xs={2} sm={3}>
                            <Link to={`/plano_contas/view/${planoContasFiltrado.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                              <IconButton color="primary" aria-label="view">
                                <VisibilityIcon />
                              </IconButton>
                            </Link>
                          </Grid>
                          {permissoes.alterar && 
                            <Grid item md={2} xs={2} sm={3}>
                              <Link to={`/plano_contas/cadastro/${planoContasFiltrado.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                                <IconButton color="error" aria-label="view">
                                  <CreateIcon />
                                </IconButton>
                              </Link>
                            </Grid>
                          }
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid container direction="row" className='conteudo'>
                      <Grid item md={12} xs={12} sm={12}>
                        {planoContasFiltrado.list.length === 0 &&
                          <Grid container direction="row" className='sem_plano'>
                            <Grid item md={8} xs={12} sm={12}>
                              <span>Sem Plano.</span>
                            </Grid>
                          </Grid>
                        }
                        {planoContasFiltrado.list.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(plano => {
                          return (
                            <Grid container direction="row" className={`linha_plano ${plano.plano_selecionado ? 'active' : ''}`} onClick={e => this.selecionarPrimeiroNivel(plano)}>
                              <Grid item md={2} xs={2} sm={2} className='plano_codigo'>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.codigo}</span>
                              </Grid>
                              <Grid item md={8} xs={8} sm={12} className='plano_descricao'>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.descricao}</span>
                              </Grid>
                              <Grid item md={2} xs={2} sm={12} className='plano_niveis_inferior'>
                                <div>
                                  <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.niveis_inferior.length}</span>
                                </div>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                    <Grid container direction="row" className='titulo'>
                      <Grid item md={8} xs={8} sm={6}>
                        <h6>Categoria</h6>
                      </Grid>
                      {this.state.segundoNivelSelecionado &&
                        <React.Fragment>
                          {!permissoes.alterar &&
                            <Grid item md={2} xs={2} sm={3}></Grid>
                          }
                          <Grid item md={2} xs={2} sm={3}>
                            <Link to={`/plano_contas/view/${planosSegundoNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                              <IconButton color="primary" aria-label="view">
                                <VisibilityIcon />
                              </IconButton>
                            </Link>
                          </Grid>
                          {permissoes.alterar &&
                            <Grid item md={2} xs={2} sm={3}>
                              <Link to={`/plano_contas/cadastro/${planosSegundoNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                                <IconButton color="error" aria-label="view">
                                  <CreateIcon />
                                </IconButton>
                              </Link>
                            </Grid>
                          }
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid container direction="row" className='conteudo'>
                      <Grid item md={12} xs={12} sm={12}>
                        {planosSegundoNivel.list.length === 0 &&
                          <Grid container direction="row" className='sem_plano'>
                            {this.state.primeiroNivelSelecionado &&
                              <Grid item md={12} xs={12} sm={12}>
                                <span>Sem Categoria.</span>
                              </Grid>
                            }
                          </Grid>
                        }
                        {planosSegundoNivel.list.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(plano => {
                          return (
                            <Grid container direction="row" className={`linha_plano ${plano.plano_selecionado ? 'active' : ''}`} onClick={e => this.selecionarSegundoNivel(plano)}>
                              <Grid item md={2} xs={3} sm={12} className='plano_codigo'>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.codigo}</span>
                              </Grid>
                              <Grid item md={8} xs={7} sm={12} className='plano_descricao'>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.descricao}</span>
                              </Grid>
                              <Grid item md={2} xs={2} sm={12} className='plano_niveis_inferior'>
                                <div>
                                  <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.niveis_inferior.length}</span>
                                </div>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                    <Grid container direction="row" className='titulo'>
                      <Grid item md={8} xs={8} sm={6}>
                        <h6>Sub Categoria</h6>
                      </Grid>
                      {this.state.terceiroNivelSelecionado &&
                        <React.Fragment>
                          {!permissoes.alterar &&
                            <Grid item md={2} xs={2} sm={3}></Grid>
                          }
                          <Grid item md={2} xs={2} sm={3}>
                            <Link to={`/plano_contas/view/${planosTerceiroNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                              <IconButton color="primary" aria-label="view">
                                <VisibilityIcon />
                              </IconButton>
                            </Link>
                          </Grid>
                          {permissoes.alterar &&
                            <Grid item md={2} xs={2} sm={3}>
                              <Link to={`/plano_contas/cadastro/${planosTerceiroNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                                <IconButton color="error" aria-label="view">
                                  <CreateIcon />
                                </IconButton>
                              </Link>
                            </Grid>
                          }
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid container direction="row" className='conteudo'>
                      <Grid item md={12} xs={12} sm={12}>
                        {planosTerceiroNivel.list.length === 0 &&
                          <Grid container direction="row" className='sem_plano'>
                            {this.state.segundoNivelSelecionado && 
                              <Grid item md={8} xs={12} sm={12}>
                                <span>Sem Sub Categoria.</span>
                              </Grid>
                            }
                          </Grid>
                        }
                        {planosTerceiroNivel.list.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(plano => {
                          return (
                            <Grid container direction="row" className={`linha_plano ${plano.plano_selecionado ? 'active' : ''}`} onClick={e => this.selecionarTerceiroNivel(plano)}>
                              <Grid item md={2} xs={3} sm={12} className='plano_codigo'>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.codigo}</span>
                              </Grid>
                              <Grid item md={8} xs={7} sm={12} className='plano_descricao'>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.descricao}</span>
                              </Grid>
                              <Grid item md={2} xs={2} sm={12} className='plano_niveis_inferior'>
                                <div>
                                  <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.centro_custos.length}</span>
                                </div>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                    <Grid container direction="row" className='titulo'>
                      <Grid item md={12} xs={12} sm={12}>
                        <h6>Centro de Custo</h6>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className='conteudo'>
                      <Grid item md={12} xs={12} sm={12}>
                        {centros_custo.list.length === 0 &&
                          <Grid container direction="row" className='sem_plano'>
                            {this.state.primeiroNivelSelecionado && 
                              <Grid item md={8} xs={12} sm={12}>
                                <span>Sem Centro de Custo.</span>
                              </Grid>
                            }
                          </Grid>
                        }
                        {centros_custo.list.map(centro => {
                          return (
                            <Grid container direction="row" className={`linha_plano`}>
                              <Grid item md={10} xs={9} sm={12} className='plano_descricao'>
                                <span style={{marginLeft: '10px'}}>{centro.centro_custo.desc}</span>
                              </Grid>
                              <Grid item md={2} xs={3} sm={12} className='plano_codigo'>
                                <span>{centro.valor_percentual} %</span>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Plano de Contas"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}