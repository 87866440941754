import axios from 'axios'

const USER_TOKEN = localStorage.getItem('token')

const api = axios.create({
  headers: {
    'Authorization': 'Bearer ' + USER_TOKEN,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})

export default api;
