export default {
  pessoa: {
    _id: "",
    flags: [],
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    pessoaJuridica: {
      id: '',
      razaoSocial:'',
      nomeFantasia:'',
      cnpj:'',
      inscricaoEstadual:'',
      dataFundacao: ''
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      data_ini_vig: "",
      contm_data_fim_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: "",
      resp_fin: []
    },
    unidade:[]
  },
  responsavel: {
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    }]
  },
  cursos: {
    list: []
  },
  curso: {
    curso_id: "",
    curso_nome: "",
  },
  unidade: {
    unin_id: "",
    unin_descricao: "",
  },
  unidades: {
    list: []
  },
  financeiros: {
    list: []
  },
  filtro: {
    matricula: ""
  },
  loading: true,
  mensagemLoading: 'Carregando Cliente/Fornecedor...',
  transferencia: {
    tra_contrato_id: '',
    tra_unin_destino_id: '',
    formapagto_id: '',
    contm_data_vct_matricula: '',
    contm_dia_vct_mensalidade: null,
    tra_observacao: ''
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  modalTransferenciaOpen: false,
  modalTransferencia: {
    mensagem: '',
  },
  formasPagamento: {
    list: []
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesTransferencia: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesContrato: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  disabledButtonsTransferencia: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  responsaveis: [],
  dadosComplementaresPF: []
}