import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button, TextField } from '@mui/material'
import Table from '../../components/Table'
import real from './../../services/real'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'produto', numeric: false, disablePadding: false, label: 'Produto' },
  { id: 'numero', numeric: false, disablePadding: false, label: 'Núm. Voucher' },
  { id: 'comprador', numeric: false, disablePadding: false, label: 'Comprador' },
  { id: 'valor_credito_form', numeric: true, disablePadding: false, label: 'Valor do Produto' }
]

const acoesTabela = ['selecionarVoucherProduto']

export default function SimpleModal(props) {
  let { produtos_voucher, voucherSelecionado, dados } = props

  const theme = useTheme();
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Selecionar o Produtos do Voucher para Utilizar</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20" id="view">
            <Grid item md={6} xs={false} sm={3}></Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Valor Total" 
                variant="outlined" 
                size="small" 
                name="totalConta" 
                value={dados.totalConta}
                InputLabelProps={{ shrink: true }} 
                disabled={true}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Valor Total Vouchers"   
                variant="outlined" 
                size="small" 
                name="totalVouchers" 
                value={dados.totalVouchers} 
                InputLabelProps={{ shrink: true }} 
                disabled={true}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Diferença"   
                variant="outlined" 
                size="small" 
                name="diferenca" 
                value={dados.diferenca}
                InputLabelProps={{ shrink: true }} 
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={cabecalhoTabela} 
                rows={produtos_voucher.filter(param => !param.utilizado).map(value => {
                  return {
                    id: value.id, 
                    produto: value.produto.descricao,
                    numero: voucherSelecionado.numero || '',
                    comprador: voucherSelecionado.comprador,
                    valor_credito_form: 'R$ ' + real(value.valor),
                    valor: value.valor
                  }
                })} 
                acoes={acoesTabela} 
                vouchersProdutosSelecionados={props.vouchersProdutosSelecionados}
                selecionarVoucherProduto={e => props.selecionarVoucherProduto(e)}
                diferencaVoucher={parseFloat(props.dados.diferenca.replaceAll(".", "").replace(",", "."))}
                totalVouchers={parseFloat(props.dados.totalVouchers.replaceAll(".", "").replace(",", "."))}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={9} xs={false}></Grid>
            <Grid item md={3} xs={12}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.voltar()}>Continuar</Button>
            </Grid>
          </Grid>
       </StyledPaper>
      </Modal>
    </div>
  );
}
