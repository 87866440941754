import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import UploadImage from './../../services/uploads/UploadImage'
import FileListImage from '../../services/uploads/FileListImage';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <UploadImage onUpload={props.handleUploadImage} />
          <FileListImage files={props.uploadedFiles} onDelete={props.handleDeleteImage} />
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.continuar()}>Continuar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
