import React, { useState, useEffect } from 'react';
import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import './Unidade.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, TextField, Button } from '@mui/material'
import FormPessoa from '../../components/forms/FormPessoa'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios'
import moment from 'moment';
import { format } from 'cnpj';
import { useParams, useNavigate } from 'react-router-dom';
import formatarCEP from '../../services/formatarCEP'
import validacaoEmail from '../../services/validacaoEmail'
import formatarTelefone from '../../services/formatarTelefone'
import getToken from '../../services/getToken'

const cpf = require('node-cpf-cnpj');
const cnpj = require('node-cpf-cnpj')

const initialState = {
  unidade: {
    id: "",
    descricao: "",
    ativo: "",
    tipoPessoa: "FISICA",
    unidade_id: "",
    unidadePrincipal: true,
    importarDadosFuncionario: true,
    class_tipo: "",
    pessoaFisica: {
      id: "",
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    pessoaJuridica: {
      id: "",
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: ""
    },
    enderecos: [],
    emails: [],
    contatos: []
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  disableEnderecoFuncionario: true,
  enableDadosPessoaFuncionario: true,
  enableDadosEnderecoFuncionario: false,
  enableDadosEmailFuncionario: false,
  enableDadosContatoFuncionario: false,
  enableDadosFuncionarioFuncionario: false,
  enableDadosContratoFuncionario: false,
  enableDadosUsuarioFuncionario: false,
  loading: true,
  mensagemLoading: 'Carregando Dados da Unidade...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  informarEndereco: false,
  informarContato: false,
  informarEmail: false,
  endereco: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contato: {
    numero: "",
    tipoContato: "COMERCIAL",
    principal: true,
    index: ""
  },
  email: {
    email: "",
    principal: true,
    index: ""
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes: {
    list: []
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: ['update', 'remover'],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
}

const UpdateUnidade = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { unidadeId } = useParams();

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/unidade");      
    }, timeout)
  }

  const handleCloseAlerta = () =>{
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateField = (event) => {

    if (event.target.name === "tipoPessoa") {
      if (event.target.value === "FISICA") {
        setState(prevState => ({
          ...prevState,
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false
        }))
      } else {
        setState(prevState => ({
          ...prevState,
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true
        }))
      }
    }

    const {unidade} = state
    unidade[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      unidade
    }))
  }

  const updateFieldPessoaFisica = (event) => {
    const unidade = { ...state.unidade }
    unidade.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const updateFieldPessoaJuridica = (event) => {
    const unidade = { ...state.unidade }
    unidade.pessoaJuridica[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const validarCpfCnpj = async (event) => {
    let cpf_cnpj = event.target.value
    const unidade = state.unidade

    if (unidade.tipoPessoa === "FISICA") {
      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {

        unidade.pessoaFisica.cpf = ""

        setState(prevState => ({
          ...prevState,
          unidade,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CPF Invalido! Por favor corrija o campo CPF'
          }
        }));
      } else {
        unidade.pessoaFisica.cpf = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          unidade
        }));
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        unidade.pessoaJuridica.cnpj = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          unidade
        }));

      } else {
        unidade.pessoaJuridica.cnpj = ""
        setState(prevState => ({
          ...prevState,
          unidade,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CNPJ Invalido! Por favor corrija o campo CNPJ'
          }
        }));
      }
    }
  }

  const informarEndereco = () => {
    const { unidade } = state;
    let principal = !unidade.enderecos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEndereco: true,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }));
  }

  const pegaEndereco = async (event, index) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { endereco } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: endereco.numero,
        complemento: endereco.complemento,
        cep: formatarCEP(endereco.cep),
        principal: endereco.principal,
        tipoEndereco: endereco.tipoEndereco,
        index: endereco.index
      };
  
      setState(prevState => ({
        ...prevState,
        endereco: novoEndereco
      }));
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: endereco.complemento,
          rua: resp.logradouro,
          numero: endereco.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };
  
        setState(prevState => ({
          ...prevState,
          endereco: enderecoEncontrado,
          disableEndereco: true,
          modalErro: false
        }));
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: endereco.numero,
          cep: "",
          complemento: endereco.complemento,
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };

        setState(prevState => ({
          ...prevState,
          endereco: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }));
      }
    }
  }

  const updateFieldEndereco = (event, index) => {
    let { endereco } = state
    endereco[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const updateFieldEnderecoPrincipal = (event, index) => {
    let { endereco, unidade } = state
    const indexAux = unidade.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== endereco.index)) {
      endereco.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui endereço principal'
        }
      }));
    }else{
      endereco.principal = !endereco.principal
    }

    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const addNovoEndereco = () => {
    const { endereco, unidade } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        endereco.cep !== "" &&
        endereco.rua !== "" &&
        endereco.bairro !== "" &&
        endereco.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }));
      return;
    }
  
    // Crie uma cópia dos endereços da unidade
    const novosEnderecos = [...unidade.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (endereco.index === "") {
      endereco.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...endereco });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[endereco.index - 1] = { ...endereco };
    }
  
    // Atualize os endereços da unidade
    unidade.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({
      ...prevState,
      unidade,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEndereco: false,
      alerta: { open: false } // Feche o alerta após adicionar o novo endereço
    }));
  }

  const editarEndereco = (row) => {
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      endereco: {...unidade.enderecos[row.index - 1]},
      informarEndereco: true
    }));
  }

  const removeEnderecounidade = (index) => {
    const unidade = { ...state.unidade }
    const enderecos = unidade.enderecos
    let aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.enderecos = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const informarContato = () => {
    const { unidade } = state;
    let principal = !unidade.contatos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarContato: true,
      contato: {
        numero: "",
        tipoContato: "COMERCIAL",
        principal,
        index: ""
      },
    }));
  }

  const updateFieldContato = (event, index) => {
    let { contato } = state
    contato[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const updateFieldContatoPrincipal = (event, index) => {
    let { contato, unidade } = state
    const indexAux = unidade.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contato.index)) {
      contato.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui contato principal'
        }
      }));
    }else{
      contato.principal = !contato.principal
    }

    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const addNovoContato = () => {
    let {contato, unidade} = state

    let valido = true

    if (contato.numero === "") valido = false
    if (contato.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }));
      return
    }

    const contatos = unidade.contatos

    if(contato.index === ""){
      contato.index = contatos.length + 1

      contatos.push({
        ...contato
      })
    }else{
      contatos[contato.index - 1] = {
        ...contato
      }
    }

    unidade.contatos = contatos

    setState(prevState => ({
      ...prevState,
      unidade,
      contato: {
        numero: "",
        tipoContato: "COMERCIAL",
        principal: false,
        index: ""
      },
      informarContato: false
    }));
  }

  const editarContato = (row) =>{
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      contato: {...unidade.contatos[row.index - 1]},
      informarContato: true
    }));
  }

  const removeContatoUnidade = (index) => {
    const unidade = { ...state.unidade }
    const contatos = unidade.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.contatos = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }))
  }

  const informarEmail = () => {
    const { unidade } = state;
    let principal = !unidade.emails.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEmail: true,
      email: {
        email: "",
        principal,
        index: ""
      },
    }))
  }

  const updateFieldEmail = (event, index) => {
    let { email } = state
    email[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      email
    }));
  }

  const updateFieldEmailPrincipal = (event, index) => {
    let { email, unidade } = state
    const indexAux = unidade.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== email.index)) {
      email.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui email principal'
        }
      }))
    }else{
      email.principal = !email.principal
    }

    setState(prevState => ({
      ...prevState,
      email
    }))
  }

  const addNovoEmail = () => {
    let {email, unidade} = state

    let valido = true

    if (email.email === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(email.email)) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do unidade é inválido!'
        }
      }))
      return
    }

    const emails = unidade.emails

    if(email.index === ""){
      email.index = emails.length + 1

      emails.push({
        ...email
      })
    }else{
      emails[email.index - 1] = {
        ...email
      }
    }

    unidade.emails = emails

    setState(prevState => ({
      ...prevState,
      unidade,
      email: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmail: false
    }))
  }

  const editarEmail = (row) => {
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      email: {...unidade.emails[row.index - 1]},
      informarEmail: true
    }))
  }

  const removeEmailUnidade = (index) => {
    const unidade = { ...state.unidade }
    const emails = unidade.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.emails = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }))
  }

  const validarDadosPessoa = async() =>{
    const {unidade} = state
    
    if (unidade.tipoPessoa === "FISICA") {
      if (
        unidade.pessoaFisica.nome === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o nome do(a) representante.'
        }
      }

      if (
        unidade.pessoaFisica.dataNascimento === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar a data de nascimento do(a) representante.'
        }
      }

      if (
        unidade.pessoaFisica.cpf === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o CPF do(a) representante.'
        }
      }

      let cpf_cnpj = unidade.pessoaFisica.cpf

      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {
        if(cpf_cnpj !== '000.000.000-00'){
          return {
            valido: false,
            mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
          }
        }
      }

      setState(prevState => ({
        ...prevState,
        unidade
      }))
    }else{
      if (unidade.pessoaJuridica.razaoSocial === "") {
        return {
          valido: false,
          mensagem: 'Por favor informar a razão social do representante.'
        }
      }

      if (unidade.pessoaJuridica.nomeFantasia === "") {
        return {
          valido: false,
          mensagem: `Por favor informar o nome fantasia do representante.`
        }
      }
    }

    if(unidade.enderecos.length > 0){
      let valido = true
      unidade.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })
  
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }
    
    if(unidade.contatos.length > 0){
      unidade.contatos.forEach(contato => {
        if (contato.numero === "") {
          return {
            valido: false,
            mensagem: `Por favor informar o contato da unidade.`
          }
        }
      })
    }

    if(unidade.emails.length > 0){
      let valido = true
      unidade.emails.forEach(email => {
        if (email.email === "") valido = false
      })
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    setState(prevState => ({
      ...prevState,
      unidade
    }))

    return {
      valido: true,
      mensagem: ''
    }
  }

  const salvar = async () => {
    let validacao = await validarDadosPessoa()

    if(!validacao.valido){
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: validacao.mensagem
        }
      }))
      return
    }

    const { unidade } = state
    
    setState(prevState => ({
      ...prevState,
      loading: true,
      mensagemLoading: 'Alterando Unidade...'
    }))

    let fisica = {}
    let juridica = {}

    if (unidade.tipoPessoa === "FISICA") {
      fisica = {
        pessoa_id: unidade.pessoa_id,
        id: unidade.pessoaFisica.id,
        nome: unidade.pessoaFisica.nome,
        cpf: cpf.strip(unidade.pessoaFisica.cpf),
        rg: unidade.pessoaFisica.rg,
        rg_orgao_emissor: unidade.pessoaFisica.rg_orgao_emissor,
        rg_uf: unidade.pessoaFisica.rg_uf,
        rg_data_emissao: unidade.pessoaFisica.rg_data_emissao,
        datanascimento: unidade.pessoaFisica.dataNascimento,
        class_tipo: unidade.class_tipo,
      }
    } else {
      juridica = {
        pessoa_id: unidade.pessoa_id,
        id: unidade.pessoaJuridica.id,
        razaosocial: unidade.pessoaJuridica.razaoSocial,
        nomefantasia: unidade.pessoaJuridica.nomeFantasia,
        inscricaoestadual: unidade.pessoaJuridica.inscricaoEstadual,
        datafundacao: unidade.pessoaJuridica.dataFundacao,
        cnpj: cnpj.strip(unidade.pessoaJuridica.cnpj),
        class_tipo: unidade.class_tipo,
      }
    }

    let emails = []
    let telefones = []
    let enderecos = []

    unidade.emails.forEach(email => {
      if (email.id) {
        emails.push({
          id: email.id,
          email: email.email,
          principal: email.principal
        })
      } else {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      }
    })

    unidade.contatos.forEach(telefone => {
      if (telefone.id) {
        telefones.push({
          id: telefone.id,
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      } else {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      }
    })

    unidade.enderecos.forEach(endereco => {
      if (endereco.id) {
        enderecos.push({
          id: endereco.id,
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      } else {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      }
    })

    const dados = {
      unidade_id: unidade.unidade_id,
      importar_planos: false,
      unidade: {
        uni_id: unidade.id,
        descricao: unidade.descricao,
        pessoa_id: unidade.pessoa_id,
        tipo: unidade.tipoPessoa,
        juridica,
        fisica,
        emails,
        telefones,
        enderecos
      }
    }

    try {

      await axios.put(`${window._env_.REACT_APP_API_URL}/unidades/${unidade.id}`, dados, getToken())

      setState(prevState => ({
        ...prevState,
        unidade: {
          id: "",
          descricao: "",
          ativo: true,
          tipoPessoa: "FISICA",
          unidade_id: "",
          unidadePrincipal: true,
          importarDadosFuncionario: true,
          pessoaFisica: {
            nome: "",
            cpf: "",
            rg: "",
            dataNascimento: ""
          },
          pessoaJuridica: {
            razaoSocial: "",
            nomeFantasia: "",
            cnpj: "",
            inscricaoEstadual: "",
            dataFundacao: ""
          },
          enderecos: [],
          emails: [],
          contatos: []
        },
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'unidade')[0]

      setState(prevState => ({
        ...prevState,
        permissoes
      }))

      if (!permissoes.alterar) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }))

        backPage()
      }

      try {
        const { data: unidade } = await axios.get(`${window._env_.REACT_APP_API_URL}/unidades/${unidadeId}`, getToken())

        let fisica = {}
        let juridica = {}

        if (unidade.pessoa.tipo === "FISICA") {
          fisica = {
            id: unidade.pessoa.fisica.id,
            nome: unidade.pessoa.fisica.nome,
            cpf: cpf.format(unidade.pessoa.fisica.cpf),
            rg: unidade.pessoa.fisica.rg,
            rg_orgao_emissor: unidade.pessoa.fisica.rg_orgao_emissor,
            rg_uf: unidade.pessoa.fisica.rg_uf,
            rg_data_emissao: moment(unidade.pessoa.fisica.rg_data_emissao).format('YYYY-MM-DD'),
            dataNascimento: moment(unidade.pessoa.fisica.datanascimento).format('YYYY-MM-DD')
          }

          setState(prevState => ({
            ...prevState,
            enableDadosPessoaFisica: true,
            enableDadosPessoaJuridica: false,
          }))
        } else {
          juridica = {
            id: unidade.pessoa.juridica.id,
            razaoSocial: unidade.pessoa.juridica.razaosocial,
            nomeFantasia: unidade.pessoa.juridica.nomefantasia,
            cnpj: format(unidade.pessoa.juridica.cnpj),
            inscricaoEstadual: unidade.pessoa.juridica.inscricaoestadual,
            dataFundacao:unidade.pessoa.juridica.datafundacao ? moment(unidade.pessoa.juridica.datafundacao).format('YYYY-MM-DD') : ''
          }

          setState(prevState => ({
            ...prevState,
            enableDadosPessoaFisica: false,
            enableDadosPessoaJuridica: true,
          }))
        }

        let enderecos = []
        let contatos = []
        let emails = []

        for (let i = 0; i < unidade.pessoa.enderecos.length; i++) {
          enderecos.push({
            id: unidade.pessoa.enderecos[i].id,
            cep_id: unidade.pessoa.enderecos[i].cep_id,
            rua: unidade.pessoa.enderecos[i].logradouro,
            numero: unidade.pessoa.enderecos[i].numero,
            complemento: unidade.pessoa.enderecos[i].complemento,
            bairro: unidade.pessoa.enderecos[i].bairro,
            cidade: unidade.pessoa.enderecos[i].cidade,
            estado: unidade.pessoa.enderecos[i].estado,
            cep: formatarCEP(unidade.pessoa.enderecos[i].num_cep),
            principal: unidade.pessoa.enderecos[i].principal,
            tipoEndereco: unidade.pessoa.enderecos[i].tipo,
            index: i + 1
          })
        }

        for (let i = 0; i < unidade.pessoa.telefones.length; i++) {
          contatos.push({
            id: unidade.pessoa.telefones[i].id,
            numero: formatarTelefone(unidade.pessoa.telefones[i].numero),
            tipoContato: unidade.pessoa.telefones[i].tipo,
            principal: unidade.pessoa.telefones[i].principal,
            index: i + 1
          })
        }

        for (let i = 0; i < unidade.pessoa.emails.length; i++) {
          emails.push({
            id: unidade.pessoa.emails[i].id,
            email: unidade.pessoa.emails[i].email,
            principal: unidade.pessoa.emails[i].principal,
            index: i + 1
          })
        }

        const unidadeAux = {
          id: unidade.id,
          descricao: unidade.descricao,
          ativo: unidade.status,
          tipoPessoa: unidade.pessoa.tipo,
          unidade_id: unidade.superior_id,
          pessoa_id: unidade.pessoa_id,
          pessoaFisica: fisica,
          pessoaJuridica: juridica,
          enderecos,
          emails,
          contatos
        }

        setState(prevState => ({
          ...prevState,
          unidade: unidadeAux,
          loading: false,
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }
    };
    
    fetchData();
  }, []);

  const { unidade } = state

  return (
    <React.Fragment>
      {!state.loading && (
        <div className="app-menu-closed" id="app">
          <Main>
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={12} xs={12} sm={12}>
                <h1 className="titulo">Atualizar Unidade</h1>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  className="input"
                  label="Descrição Unidade"
                  variant="outlined"
                  size="small"
                  name="descricao"
                  value={unidade.descricao}
                  onChange={(e) => updateField(e)}
                />
              </Grid>
            </Grid>
            <FormPessoa
              pessoa={unidade}
              informarEndereco={state.informarEndereco}
              endereco={state.endereco}
              informarContato={state.informarContato}
              contato={state.contato}
              informarEmail={state.informarEmail}
              email={state.email}
              updateFieldPessoaFisica={(e) => updateFieldPessoaFisica(e)}
              updateFieldPessoaJuridica={(e) => updateFieldPessoaJuridica(e)}
              validarCpfCnpj={(e) => validarCpfCnpj(e)}
              dadosComplementaresPF={state.dadosComplementaresPF}
              metodos_divugacoes={state.metodos_divugacoes.list}
              informarNovoEndereco={() => informarEndereco()}
              pegaEndereco={(e) => pegaEndereco(e)}
              updateFieldEndereco={(e) => updateFieldEndereco(e)}
              updateFieldEnderecoPrincipal={(e) =>
                updateFieldEnderecoPrincipal(e)
              }
              addNovoEndereco={(e) => addNovoEndereco(e)}
              cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
              editarEndereco={(e) => editarEndereco(e)}
              removeEndereco={(e) => removeEnderecounidade(e)}
              informarNovoContato={() => informarContato()}
              updateFieldContato={(e) => updateFieldContato(e)}
              updateFieldContatoPrincipal={(e) =>
                updateFieldContatoPrincipal(e)
              }
              addNovoContato={() => addNovoContato()}
              editarContato={(e) => editarContato(e)}
              removeContato={(e) => removeContatoUnidade(e)}
              informarNovoEmail={() => informarEmail()}
              updateFieldEmail={(e) => updateFieldEmail(e)}
              updateFieldEmailPrincipal={(e) => updateFieldEmailPrincipal(e)}
              addNovoEmail={() => addNovoEmail()}
              editarEmail={(e) => editarEmail(e)}
              removeEmail={(e) => removeEmailUnidade(e)}
              acoesTabelaEndereco={state.acoesTabelaEndereco}
              cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
              cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
            />
            <hr className="mg_top_10" />
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              spacing={1}
              className="mg_top_10"
            >
              <Grid item md={10} xs={1} sm={9}></Grid>
              <Grid item md={2} xs={6} sm={3}>
                <Button
                  disabled={state.loading}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<SaveIcon />}
                  onClick={(e) => salvar(e)}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Main>
          <Nav />
          <Alert
            open={state.alerta.open}
            handleClose={(e) => handleCloseAlerta()}
            severity={state.alerta.severity}
            message={state.alerta.message}
          />
        </div>
      )}
      <Loading open={state.loading} message={state.mensagemLoading} />
    </React.Fragment>
  );
}

export default UpdateUnidade
