import React, { Component } from 'react'
import './Produto.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import Loading from './../../components/templates/Loading'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  produtos: {
    list: []
  },
  produtosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    // { id: 'url', numeric: false, disablePadding: true, label: 'Imagem' },
    { id: 'codigo', numeric: false, disablePadding: true, label: 'Código' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    // { id: 'unidadeMedida', numeric: false, disablePadding: false, label: 'Unidade de Medida' },
    { id: 'subGrupoProduto', numeric: false, disablePadding: false, label: 'Sub Grupo Produto' },
    { id: 'grupoProduto', numeric: false, disablePadding: false, label: 'Grupo Produto' },
    { id: 'visibilidade', numeric: false, disablePadding: false, label: 'Visibilidade' },
    // { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'destaque', numeric: false, disablePadding: false, label: 'Destaque' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    descricao: "",
    subGrupoProduto: "",
    grupoProduto: "",
    visibilidade: ""
  },
  loading: true,
  mensagemLoading: 'Carregando Produtos...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Produto extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'produto')[0]

    let acoesTabela = []

    if (permissoes.visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    this.setState({
      unin_tipo
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, this.getToken())

      const produtos = []

      resp.data.forEach(produto => {
        produtos.push({
          _id: produto.id,
          url: produto.midia ? produto.midia.key : '',
          descricao: produto.descricao,
          codigo: produto.codigo,
          unidadeMedida: produto.unidademedida,
          subGrupoProduto: produto.subgrupoproduto.descricao,
          grupoProduto: produto.subgrupoproduto.grupoproduto.descricao,
          visibilidade: produto.visibilidade,
          destaque: produto.destaque,
          unidade: produto.unidade.descricao ? `${produto.unidade.numero} - ${produto.unidade.descricao}` : (produto.unidade.pessoa.tipo === 'FISICA' ? produto.unidade.pessoa.fisica.nome : produto.unidade.pessoa.juridica.razaosocial),
          ativo: produto.ativo
        })
      })

      this.setState({
        produtos: {
          list: produtos
        },
        produtosFiltrado: {
          list: produtos
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const produtos = this.state.produtos.list
    const filtro = this.state.filtro

    const list = produtos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      produtosFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, produtosFiltrado, permissoes } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid container spacing={1} direction="row" className="borderBottom">
                  <Grid item md={9} xs={12}>
                    <h1 className="titulo">Produtos e Serviços</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={3} xs={12}>
                      <Link to="/produtos/cadastro">
                        <Button size='small' className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar Produto/Serviço
                      </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Produto" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Sub Grupo" variant="outlined" size="small" name="subGrupoProduto" value={filtro.subGrupoProduto} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Grupo" variant="outlined" size="small" name="grupoProduto" value={filtro.grupoProduto} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Visibilidade"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="visibilidade"
                      value={filtro.visibilidade}
                      onChangeCapture={(e) => this.updateFiltro(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">Pesquisar</option>
                      <option value="FRANQUEADOR">
                        Franqueador
                      </option>
                      <option value="UNIDADE">
                        Unidade
                      </option>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table headCell={this.state.cabecalhoTabela} rows={produtosFiltrado.list} acoes={this.state.acoesTabela} urlUpdate="/produtos/cadastro/" urlView="/produtos/view/" />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} 
            />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Produtos e Serviços"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}