import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { Grid, TextField, Button } from '@mui/material'
import CurrencyInput from './../mask/CurrencyInput'
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import Color from 'color';
import Table from './../Table'
import moment from 'moment';
import real from './../../services/real'

const cabecalhoTabelaServico = [
  { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
  { id: 'nome', numeric: false, disablePadding: true, label: 'Serviço' },
  { id: 'quantidade', numeric: true, disablePadding: true, label: 'Quantidade' },
  { id: 'valor_unit', numeric: true, disablePadding: true, label: 'Valor Unitário' },
  { id: 'desconto', numeric: true, disablePadding: true, label: 'Desconto/Acréscimo Unit.' },
  { id: 'valor_total_pedido', numeric: true, disablePadding: true, label: 'Valor Total' },
  { id: 'data_execucao_form', numeric: true, disablePadding: true, label: 'Data da Execução' }
]

const acoesTabela = ['editar', 'remove']

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const FormCargo = ({ dados, updateField, pessoas, cliente, updateFieldPessoaFiltro, updateFiltroPessoa, servico, 
  updateFieldServico, updateServico, addServico, produtos, servico_selecionado, updateFieldServicoAutoComplete,
  editarItemServico, removerItemServico, conta, updateFieldConta, mudarParcelas, filtro_produtos, updateFiltroProdutos,
  recalcularParcela, updateFieldParcela, formasPagamento, contasFinanceira, verificaDataParcela, openVouchers, 
  marcaParcelasContaFin, logado, unidades, travarUnidade, grupoProdutos, subGrupoProdutos}) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="unidade_id"
          select
          label="Unidade*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={travarUnidade}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      {dados.unidade_id !== '' &&
        <React.Fragment>
          <Grid item md={4} xs={12} sm={6}>
            <Autocomplete
              onChange={(event, value) => updateFieldPessoaFiltro(event, value)}
              freeSolo
              options={pessoas}
              value={cliente}
              size="small"
              getOptionLabel={option => option.nome}
              renderInput={(params) => (
                <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Cliente/Fornecedor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={cliente.nome} onChange={(e) => updateFiltroPessoa(e)}/>
              )}
            />
          </Grid>
          <Grid item md={2} xs={12} sm={3}>
            <TextField 
              className="input" 
              type="date" 
              label="Data Venda*" 
              variant="outlined" 
              size="small" 
              name="data_pedido" 
              value={dados.data_pedido} 
              onChange={(e) => updateField(e)} 
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
        </React.Fragment>
      }
    </Grid>
    <hr className="mg_top_10"/>
    {(dados.unidade_id !== '' && cliente.pessoa_id !== '') &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={12} sm={3}>
            <h5 className='titulo'>Produtos/Serviços: </h5>
          </Grid>
          {/* <Grid item md={2} xs={12} sm={3}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="inputFormPagamento"
              fullWidth
              size="small"
              label="Tipo Produto" 
              SelectProps={{
                native: true,
              }}
              name="formapagto_id"
              value={filtro_produtos}
              onChangeCapture={(e) => updateFiltroProdutos(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="PRODUTO">Produto</option>
              <option value="SERVICO">Serviço</option>
            </TextField>
          </Grid> */}
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              className="input" 
              id="standard-select-currency"
              select
              variant="outlined"
              fullWidth
              size="small"
              label="Grupo Produto/Servico" 
              SelectProps={{
                native: true,
              }}
              name="grupo_id"
              value={servico.grupo_id}
              onChangeCapture={(e) => updateFieldServico(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Selecionar Grupo</option>
              {grupoProdutos.map((grupo, key) => {
                return (
                  <option key={key} value={grupo.id}>{grupo.descricao}</option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input" 
              fullWidth
              size="small"
              label="Grupo Produto/Servico" 
              SelectProps={{
                native: true,
              }}
              name="sub_grupo_id"
              value={servico.sub_grupo_id}
              onChangeCapture={(e) => updateFieldServico(e)}
              InputLabelProps={{ shrink: true }}
              disabled={servico.grupo_id === ''}
            >
              <option value="">Selecionar Grupo</option>
              {subGrupoProdutos.filter(param => param.idgrupoproduto === parseInt(servico.grupo_id)).map((subGrupo, key) => {
                return (
                  <option key={key} value={subGrupo.id}>{subGrupo.descricao}</option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <Autocomplete
              onChange={(event, value) => updateFieldServicoAutoComplete(event, value)}
              freeSolo
              options={produtos.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))}
              value={servico_selecionado}
              getOptionLabel={option => `${option.codigo ? `${option.codigo} - ` : ''}${option.nome}`}
              disabled={servico.sub_grupo_id === ''}
              renderInput={(params) => (
                <TextField 
                  InputProps={{ ...params.InputProps, type: 'search' }} 
                  {...params} 
                  size="small" 
                  label="Item*" 
                  margin="normal" 
                  variant="outlined" 
                  InputLabelProps={{ shrink: true }} 
                  value={`${servico_selecionado.codigo ? `${servico_selecionado.codigo} - ` : ''}${servico_selecionado.nome}`}
                  disabled={servico.sub_grupo_id === ''}
                />
              )}
            />
          </Grid>
          <Grid item md={1} xs={12} sm={6}>
            <TextField 
              className="input" 
              type="number" 
              label="Quantidade*" 
              variant="outlined" 
              size="small" 
              name="quantidade" 
              value={servico.quantidade} 
              onChange={(e) => updateFieldServico(e)} 
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              className="input" 
              label="Valor Unitário*" 
              variant="outlined" 
              size="small" 
              name="valor_unit" 
              value={servico.valor_unit} 
              onChange={(e) => updateFieldServico(e)} 
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: CurrencyInput }}
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              className="input" 
              label="Desc./Acréscimo Unit." 
              variant="outlined" 
              size="small" 
              name="desconto" 
              value={servico.desconto} 
              onChange={(e) => updateFieldServico(e)} 
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: CurrencyInput }}
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              className="input" 
              label="Valor Total*" 
              variant="outlined" 
              size="small" 
              name="valor_total_pedido" 
              value={servico.valor_total_pedido} 
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          {filtro_produtos === 'SERVICO' &&
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                type="date" 
                label="Data Execução" 
                variant="outlined" 
                size="small" 
                name="data_execucao" 
                value={servico.data_execucao} 
                onChange={(e) => updateFieldServico(e)} 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
          }
          <Grid item md={2} xs={12} sm={4}>
            <Button
              color='warning'
              fullWidth
              variant="contained" 
              className="btn_salvar" 
              size="small" 
              startIcon={updateServico ? <AddIcon /> : <SaveIcon />} 
              onClick={e => addServico(e)}>{updateServico ? 'Alterar' : 'Adicionar'}
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <Table
              editar={e => editarItemServico(e)}
              remove={e => removerItemServico(e)}
              headCell={cabecalhoTabelaServico}
              rows={dados.servicos.map(value => {
                return {
                  ...value,
                  data_execucao_form:value.data_execucao ? moment(value.data_execucao).format('DD/MM/YYYY') : ''
                }
              })}
              acoes={acoesTabela} 
              qtdLinhas={5}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Observações"
              className="input"
              multiline
              rows={3}
              size="small"
              variant="outlined"
              name="observacoes"
              value={dados.observacoes}
              onChange={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
        </Grid>
        <hr className="mg_top_10"/>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h5 className='titulo'>Lançamento Financeiro - <span style={{color: '#063e11'}}><b> Valor Total: R$ {real(somarValores(dados.servicos.map(value => { return parseFloat(value.valor_total_pedido.replaceAll(".", "").replace(",", "."))})))}</b></span></h5>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <div className='scroll'>
              <table className="lista sempadding borderTable">
                <thead>
                  <tr className="titulos acompanha">
                    <th>Formas de Pagto.</th>
                    <th>Valor*</th>
                    <th>Desconto</th>
                    <th>Data Venc.*</th>
                    <th>Forma de Pagamento*</th>
                    <th>Contas Bancárias / Nº Vouchers</th>
                  </tr>
                </thead>
                <tbody>
                  {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                    return (
                      <tr key={parcela.index}>
                        <td>
                          <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.num} InputLabelProps={{ shrink: true }} disabled />
                          <span className="separacaoParcelas">/</span>
                          <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) =>mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                        </td>
                        <td>
                          <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                        </td>
                        <td>
                          <TextField className="inputValor" variant="outlined" size="small" name="multajuros" value={parcela.multajuros} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                        </td>
                        <td>
                          <TextField 
                            type="date" 
                            className="inputVencimento" 
                            variant="outlined" 
                            size="small" 
                            name="datavencimento" 
                            value={parcela.datavencimento}  
                            onChange={(e) => updateFieldParcela(e, parcela.index)} 
                            onBlur={(e) => verificaDataParcela(e, parcela.index)}
                            InputLabelProps={{ shrink: true }} 
                            // InputProps={{ inputProps: { max: dataHojeFormatado } }}
                          />
                        </td>
                        <td>
                          <TextField
                            id="standard-select-currency"
                            select
                            variant="outlined"
                            className="inputFormPagamento"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="formapagto_id"
                            value={parcela.formapagto_id}
                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                            // onBlur={(e) => marcaParcelas(e, parcela.index)}
                            InputLabelProps={{ shrink: true }}
                            
                          >
                            <option value="">--Escolha--</option>
                            {formasPagamento.map(forma => {
                              return (
                                <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                              )
                            })}
                          </TextField>
                        </td>
                        <td>
                          {parseInt(parcela.formapagto_id) === 9 &&
                            <div 
                              style={{
                                cursor: 'pointer',
                                width: '300px',
                                border: '1px solid',
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                height: '38px',
                                borderRadius: '5px',
                                padding: '10px 10px',
                                marginLeft: '37px'
                              }} 
                              onClick={() => openVouchers(parcela.index)}
                            >
                              <span>{parcela.vouchersCreditoSelecionadosString}</span>
                            </div>
                          }
                          {parseInt(parcela.formapagto_id) !== 9 &&
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="inputFormPagamento"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name="contfin_id"
                              value={parcela.contfin_id}
                              onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                              onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                              InputLabelProps={{ shrink: true }}
                            >
                              <option value="">--Escolha--</option>
                              {contasFinanceira.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                                return (
                                  <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                )
                              })}
                            </TextField>
                            // <Select
                            // fullWidth
                            // className={`${parcela.contfin_id !== '' ? 'selecionado' : 'nao_selecionado'} inputConta`}
                            // variant="outlined"
                            // name="contfin_id"
                            // value={parcela.contfin_id}
                            // onChange={(e) => updateFieldParcela(e, parcela.index)}
                            // >
                            //   <MenuItem value=''>
                            //     <ListItemIcon>
                            //     </ListItemIcon>
                            //     <ListItemText primary={'Selecionar Conta'} />
                            //   </MenuItem>
                            //   {contasFinanceira.filter(param => (logado.caixa && param.tipo_conta === "CAIXATURNO") || (!logado.caixa && param.tipo_conta === parcela.habilita)).map(conta => {
                            //     return (
                            //       <MenuItem key={conta.id} value={conta.id}>
                            //         <ListItemIcon>
                            //           <img style={{width: '30px', height: '30px', marginRight: '5px'}} src={conta.conta_corrente ? conta.conta_corrente.conta.banco.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt='logo'/>
                            //         </ListItemIcon>
                            //         <ListItemText primary={conta.descricao} />
                            //       </MenuItem>
                            //     )
                            //   })}
                            // </Select>
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
        <hr className="mg_top_10"/>
        <br />
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormCargo