import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'

import './App.scss'
import Routes from './Routes'
import React from 'react'

const app = props =>
  <Routes />

export default app