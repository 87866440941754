import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

const CPForCNPJInput = ({ value, onChange, ...otherProps }) => {
  const [mask, setMask] = useState('999.999.999-99');

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    
    const cleanedValue = value.replace(/\D/g, '');
        
    if(cleanedValue.length === 11 && userInput){
      if(newState.value === oldState.value){
        value += userInput
        selection.start += 2
        selection.end += 2
        setMask('99.999.999/9999-99');
      }
    }else if(cleanedValue.length <= 11){
      setMask('999.999.999-99');
    }

    return {
      value,
      selection
    };
  }

  return (
    <InputMask
      mask={mask}
      maskChar={''}
      value={value}
      onChange={onChange}
      beforeMaskedValueChange={beforeMaskedValueChange}
      alwaysShowMask={false}
    >
      {(inputProps) => <TextField 
        {...inputProps}
        {...otherProps}
        variant="outlined"
        fullWidth
        className="input"
        InputLabelProps={{ shrink: true }}
      />}
    </InputMask>
  );
};

export default CPForCNPJInput;
