import React, { useState, useEffect } from 'react';
import './ContaFinanceira.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import FormDadosBancario from '../../components/forms/FormDadosBancario'
import FormContaFinanceira from '../../components/forms/FormContaFinanceira'
import FormDadosParceiro from '../../components/forms/FormDadosParceiro'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import moment from 'moment'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ModalAjuda from '../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import real from './../../services/real'

const initialState = {
  contaFinanceira: {
    descricao: "",
    ordem: "",
    conciliavel: true,
    saldo_inicial: "",
    saldo_atual: "",
    tipo_conta: "",
    pessoa_id: "",
    ativo: true,
    cofre: "Não",
    unidade_id: '',
    saldo_nao_conciliado: 0,
    limite: real(0),
    data_abertura: '',
    conta_corrente: {
      id: "",
      cb_id: ""
    },
    informar_parceiro: false,
    parceiro:{
      id: '',
      api_key: '',
      token: '',
      pessoa_id: '',
      nome: '',
      parceiro_id: '',
      parceiro_descricao: '',
      bol_multa: '0.0%',
      bol_juros: false,
      bol_texto: "",
      bol_qtd_dias_pag: ""
    }
  },
  contaBancaria: {
    agencia: "",
    numero: "",
    gerente: "",
    chave_pix: "",
    banco_id: "",
    index: 1,
    banco: {
      id: "",
      nome: "",
      numero: "",
      padraocosif: ""
    }
  },
  contasFinanceira: {
    list: []
  },
  contasBancarias: {
    list: []
  },
  contasBancariasFiltrada: {
    list: []
  },
  unidades: {
    list: []
  },
  listUnidades: {
    list: []
  },
  unidade: {
    descricao: "",
    id: "",
    tipo: "",
    pessoa_id: ""
  },
  bancos: {
    list: []
  },
  criarConta: true,
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  update: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  pessoas: {
    list: []
  },
  tab: 0,
  parceirosBancarios: {
    list:[]
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao:{
    message: ''
  },
  contasPH: {
    list: []
  },
  contas_relacao: {
    list: []
  },
  openModalAjuda: false,
  markdown: ''
}

function converteFloatMoeda(valor) {
  var inteiro = null, decimal = null, c = null, j = null;
  var aux = [];
  valor = "" + valor;
  c = valor.indexOf(".", 0);
  //encontrou o ponto na string
  if (c > 0) {
    //separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
  } else {
    inteiro = valor;
  }

  //pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  //percorre a string acrescentando os pontos
  inteiro = "";
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += aux[c] + '.';
  }
  //retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = "00";
  } else {
    decimal = "" + decimal;
    if (decimal.length === 1) {
      decimal = decimal + "0";
    }
  }

  valor = inteiro + "," + decimal;

  return valor;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CadastroContaFinanceira = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { contaFinanceiraId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const buscaContasPH = async (parceiro_unidade_id) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/boleto/listContasPH/${parceiro_unidade_id}`, getToken())
    
      if(data.http_code === 201){
        setState(prevState => ({...prevState,
          contasPH:{
            list: data.bank_account_list
          }
        }))
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: data.response_message
          }
        }))
      }
    
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultaContasBancarias = async (unidade_id) => {
    try {

      const {contas_relacao} = state
    
      const dados = {
        filtro:{
          tipo_conta: "",
          descricao: "",
          ativo: "Sim"
        },
        unidadesnegocio: [unidade_id]
      }
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())

      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: data.filter(param => param.tipo_conta === 'BANCO' && !param.parceiro_id).map((conta, key) => {
            let relacao = contas_relacao.list.filter(param => param.contfin_id === conta.id)[0]

            return {
              id: relacao ? relacao.id : '',
              contfin_id: conta.id,
              logo: conta.logo,
              descricao: conta.descricao,
              bank_account_id: relacao ? relacao.bank_account_id : '',
              index: key
            }
          })
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateField = (event) => {
    const contaFinanceira = { ...state.contaFinanceira }

    if (event.target.name === 'saldo_inicial') {
      if (!state.update) {
        contaFinanceira.saldo_atual = event.target.value
      }
    }

    if(event.target.name === 'unidade_id'){
      const unidades = state.unidades.list
      const contasBancarias = state.contasBancarias.list

      const unidade = unidades.filter(param => param.unidade_id === parseInt(event.target.value))[0]
      
      contaFinanceira.pessoa_id = unidade.pessoa_id

      const contasBancariasFiltrada = contasBancarias.filter(param => param.pessoa.pessoa_id === unidade.pessoa_id)

      setState(prevState => ({...prevState,
        contasBancariasFiltrada: {
          list: contasBancariasFiltrada
        }
      }))
    }

    contaFinanceira[event.target.name] = event.target.value
    setState(prevState => ({...prevState, contaFinanceira }))
  }

  const updateFieldInformarParceiro = () => {
    const contaFinanceira = { ...state.contaFinanceira }
    contaFinanceira.informar_parceiro = !contaFinanceira.informar_parceiro
    setState(prevState => ({...prevState,contaFinanceira}))
  }

  const updateFieldParceiro = (event) => {
    const contaFinanceira = { ...state.contaFinanceira }
    contaFinanceira.parceiro[event.target.name] = event.target.value

    if(event.target.name === 'parceiro_id'){
      const parceiro_selecionado = state.parceirosBancarios.list.filter(param => param._id === parseInt(event.target.value))[0]
    
      contaFinanceira.parceiro.parceiro_descricao = parceiro_selecionado.descricao
    }

    setState(prevState => ({...prevState,contaFinanceira}))
  }

  const updateFieldCobrarJuros = () => {
    const contaFinanceira = { ...state.contaFinanceira }
    contaFinanceira.parceiro.bol_juros = !contaFinanceira.parceiro.bol_juros
    setState(prevState => ({...prevState,contaFinanceira}))
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      navigate("/conta_financeira");
    }, timeout)
  }

  const updateFieldUnidade = (event, value) => {
    const contasBancarias = state.contasBancarias.list

    const contaFinanceira = { ...state.contaFinanceira }
    contaFinanceira.pessoa_id = value.pessoa_id
    contaFinanceira.unidade_id = value.unidade_id

    const contasBancariasFiltrada = contasBancarias.filter(param => param.pessoa.pessoa_id === value.pessoa_id)

    setState(prevState => ({...prevState,
      contaFinanceira,
      contasBancariasFiltrada: {
        list: contasBancariasFiltrada
      },
      unidade: value
    }))
  }

  const updateFieldBanco = (event, value) => {
    if (!value) return false
    const contaBancaria = { ...state.contaBancaria }
    contaBancaria.banco = value
    contaBancaria.banco_id = value.id

    setState(prevState => ({...prevState, contaBancaria }))
  }

  const addConta = () => {
    setState(prevState => ({...prevState,
      criarConta: true
    }))
  }

  const updateFieldContaBancaria = (event, index) => {
    const contaBancaria = { ...state.contaBancaria }
    contaBancaria[event.target.name] = event.target.value
    setState(prevState => ({...prevState, contaBancaria }))
  }

  const updateFieldContaCorrente = (event) => {
    const contaFinanceira = { ...state.contaFinanceira }
    contaFinanceira.conta_corrente[event.target.name] = event.target.value
    setState(prevState => ({...prevState, contaFinanceira }))
  }

  const updateFieldAtivo = () => {
    const contaFinanceira = { ...state.contaFinanceira }
    contaFinanceira.ativo = !contaFinanceira.ativo
    setState(prevState => ({...prevState, contaFinanceira }))
  }

  const updateFieldSelectConta = (value) => {
    const contaFinanceira = { ...state.contaFinanceira }
    contaFinanceira.conta_corrente.cb_id = value.cb_id
    setState(prevState => ({...prevState, contaFinanceira }))
  }

  const validateForm = () => {
    const { contaFinanceira, contaBancaria } = state

    if (!contaFinanceira.descricao) return false
    if (!contaFinanceira.saldo_inicial) return false
    if (!contaFinanceira.tipo_conta) return false
    if (!contaFinanceira.data_abertura) return false

    if (contaFinanceira.tipo_conta === 'BANCO') {
    
      if (!contaBancaria.agencia) return false
      if (!contaBancaria.numero) return false
      if (!contaBancaria.banco_id) return false
    }

    if(contaFinanceira.informar_parceiro){
      if (!contaFinanceira.parceiro.api_key) return false
      if (!contaFinanceira.parceiro.parceiro_id) return false
      if (!contaFinanceira.parceiro.token) return false

      if (contaFinanceira.parceiro.bol_multa === '') return false
      if (contaFinanceira.parceiro.bol_qtd_dias_pag === '') return false
    }

    return true
  }

  const handleChange = (event, newValue) => {
    localStorage.setItem('tab_cad_conta_fin', newValue);

    setState(prevState => ({...prevState,
      tab: newValue
    }))
  };

  const relacionarContas = () => {
    if (contaFinanceiraId) {

    }else{

      if (!validateForm()) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Preencha os campos obrigatorios (*).'
          }
        }))
        return
      }

      setState(prevState => ({...prevState,
        modalConfirmacao: {
          mensagem: 'Para relacionar as contas é necessário salvar os dados do parceiro, deseja salvar?',
        },
        modalConfirmacaoOpen: true,
      }))
    }
  }

  const updateFieldContaPH = (event, index) => {
    const {contasFinanceira} = state

    contasFinanceira.list[index].bank_account_id = event.target.value

    setState(prevState => ({...prevState,
      contasFinanceira
    }))
  }

  const salvar = async (voltar) => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    try {

      let { contaFinanceira, contaBancaria } = state

      if(contaFinanceira.tipo_conta === 'BANCO'){
        if(contaFinanceira.informar_parceiro){
          if((contaFinanceira.parceiro.bol_qtd_dias_pag >= 1 && contaFinanceira.parceiro.bol_qtd_dias_pag < 5) || contaFinanceira.parceiro.bol_qtd_dias_pag > 30 || contaFinanceira.parceiro.bol_qtd_dias_pag < 0 ){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'A Qtd. de dias para pagamento apos o vencimento precisa ser igual a 0 ou entre 5 e 30 dias!'
              }
            }))
            return
          }
    
          if(parseFloat(contaFinanceira.parceiro.bol_multa.replace(" %", "").replaceAll(".", "").replace(",", ".")) !== 2 && parseFloat(contaFinanceira.parceiro.bol_multa.replace(" %", "").replaceAll(".", "").replace(",", ".")) !== 1){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'O valor da multa do boleto precisa ser igual a 1% ou 2%!'
              }
            }))
            return
          }

        }

      }

      setState(prevState => ({...prevState,
        loading: true,
        mensagemLoading: 'Cadastrando/Alterando Conta Bancária'
      }))

      let dadosContaFinanceira = []

      if (contaFinanceira.tipo_conta === 'COFRE' || contaFinanceira.tipo_conta === 'CAIXATURNO') {
        dadosContaFinanceira = {
          descricao: contaFinanceira.descricao,
          conciliavel: contaFinanceira.tipo_conta === 'BANCO' ? true : false,
          saldo_inicial: parseFloat(contaFinanceira.saldo_inicial.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          saldo_atual: parseFloat(contaFinanceira.saldo_atual.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          tipo_conta: contaFinanceira.tipo_conta,
          pessoa_id: contaFinanceira.pessoa_id,
          ativo: contaFinanceira.ativo,
          cofre: contaFinanceira.cofre === 'Não' ? false : true,
          saldo_nao_conciliado: contaFinanceira.saldo_nao_conciliado,
          limite: parseFloat(contaFinanceira.limite.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          data_abertura: contaFinanceira.data_abertura,
          unidade_id: contaFinanceira.unidade_id
        }
      } else {
        const dadosContaBancaria = {
          id: contaBancaria.id,
          numero: contaBancaria.numero,
          gerente: contaBancaria.gerente,
          agencia: contaBancaria.agencia,
          banco_id: contaBancaria.banco_id,
          chave_pix: contaBancaria.chave_pix,
          pessoa_id: contaFinanceira.pessoa_id,
        }

        let contas_assossiadas = []

        if(dadosContaBancaria.id === ''){
          delete dadosContaBancaria.id
        }

        if(dadosContaBancaria.gerente === '' || !dadosContaBancaria.gerente){
          delete dadosContaBancaria.gerente
        }

        if(dadosContaBancaria.chave_pix === '' || !dadosContaBancaria.chave_pix){
          delete dadosContaBancaria.chave_pix
        }

        dadosContaFinanceira = {
          descricao: contaFinanceira.descricao,
          conciliavel: contaFinanceira.tipo_conta === 'BANCO' ? true : false,
          saldo_inicial: parseFloat(contaFinanceira.saldo_inicial.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          saldo_atual: parseFloat(contaFinanceira.saldo_atual.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          tipo_conta: contaFinanceira.tipo_conta,
          pessoa_id: contaFinanceira.pessoa_id,
          ativo: contaFinanceira.ativo,
          cofre: contaFinanceira.cofre === 'Não' ? false : true,
          saldo_nao_conciliado: contaFinanceira.saldo_nao_conciliado,
          limite: parseFloat(contaFinanceira.limite.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          unidade_id: contaFinanceira.unidade_id,
          data_abertura: contaFinanceira.data_abertura,
          conta_corrente: {
            id: contaFinanceira.conta_corrente.id,
            conta_bancaria: dadosContaBancaria,
          },
          parceiro: {
            id: contaFinanceira.parceiro.id,
            api_key: contaFinanceira.parceiro.api_key,
            parceiro_id: contaFinanceira.parceiro.parceiro_id,
            token: contaFinanceira.parceiro.token,
            bol_multa: parseFloat(contaFinanceira.parceiro.bol_multa.replace(" %", "").replaceAll(".", "").replace(",", ".")) / 100,
            bol_juros: contaFinanceira.parceiro.bol_juros,
            bol_texto: contaFinanceira.parceiro.bol_texto,
            bol_qtd_dias_pag: contaFinanceira.parceiro.bol_qtd_dias_pag
          },
          contas_assossiadas
        }

        if(dadosContaFinanceira.contas_assossiadas.length === 0){
          delete dadosContaFinanceira.contas_assossiadas
        }

      }

      if(dadosContaFinanceira.conta_corrente){
        if (dadosContaFinanceira.conta_corrente.id === '') {
          delete dadosContaFinanceira.conta_corrente.id
        }else{
          dadosContaFinanceira.conta_corrente.id = parseInt(dadosContaFinanceira.conta_corrente.id)
        }  

        if(dadosContaFinanceira.parceiro.bol_texto === '' || !dadosContaFinanceira.parceiro.bol_texto){
          delete dadosContaFinanceira.parceiro.bol_texto
        }
      }

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

      if(tipo === 'UNIDADE'){
        dadosContaFinanceira.unidade_id = pessoa.fisica.funcionario.contrato.unidadetrabalho.id
      }

      if(dadosContaFinanceira.parceiro){
        if(dadosContaFinanceira.parceiro.id === '' || !dadosContaFinanceira.parceiro.id){
          delete dadosContaFinanceira.parceiro.id
        }
      }

      if(!contaFinanceira.informar_parceiro){
        delete dadosContaFinanceira.parceiro
      }

      const method = contaFinanceiraId ? 'put' : 'post'
      const url = contaFinanceiraId ? `/contaFinanceira/${contaFinanceiraId}` : `/contaFinanceira`

      const {data : respConta} = await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dadosContaFinanceira, getToken())

      if(method === 'post'){

        const movimentacao = {
          data_hora_conta:moment(contaFinanceira.data_abertura).format('YYYY-MM-DD') + ' 00:01',
          descricao: 'Saldo Inicial',
          tipo:'DEPOSITO',
          conciliado: true,
          valor: parseFloat(contaFinanceira.saldo_inicial.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          contfin_id: respConta.id,
          data_conciliacao: contaFinanceira.data_abertura,
        }

        await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira`, movimentacao, getToken())
      }

      setState(prevState => ({...prevState,
        contaFinanceira: initialState.contaFinanceira
      }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      if(voltar){
        localStorage.removeItem('tab_cad_conta_fin');
        backPage()
      }else{
        // navigate(`/conta_financeira/cadastro/${respConta.id}`);
        handleCloseConfirmacao()
        window.location.reload()

        return;
      }

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }

  }

  const continuarSalvamento = async (value) => {
    if(value){
      await salvar(false)
    }else{
      handleCloseConfirmacao()
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleCloseConfirmacao = () => {
    setState(prevState => ({...prevState,
      modalConfirmacao: {
        mensagem: '',
      },
      modalConfirmacaoOpen: false,
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/contaFinanceira/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        contaFinanceira: {
          descricao: "",
          ordem: "",
          conciliavel: "",
          saldo_inicial: "",
          saldo_atual: "",
          tipo_conta: "",
          pessoa_id: "",
          ativo: true,
          cofre: "Não",
          unidade_id: '',
          limite: real(0),
          saldo_nao_conciliado: 0,
          data_abertura: '',
          conta_corrente: {
            id: "",
            cb_id: "",
          },
          informar_parceiro: false,
          parceiro:{
            id: '',
            api_key: '',
            token: '',
            pessoa_id: '',
            nome: '',
            parceiro_id: '',
            parceiro_descricao: '',
            bol_multa: '0.0%',
            bol_juros: false,
            bol_texto: "",
            bol_qtd_dias_pag: ""
          }
        },
        update: false
      }))

      let listaUnidades = []
      
      try {

        const pessoa = JSON.parse(localStorage.getItem('pessoa'))
        const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo
        
        const unidades_acesso = JSON.parse(localStorage.getItem('unidades_acesso'))
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          filtro: {
            tipo_conta: "",
            descricao: "",
            ativo: "Sim"
          },
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: contasBancarias } = await axios.get(`${window._env_.REACT_APP_API_URL}/contasBancaria`, getToken())
        const { data: contasFinanceira } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())

        const contaFinanceira = { ...state.contaFinanceira }

        setState(prevState => ({...prevState,
          tipo
        }))
    
        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        listaUnidades = unidades

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                unidade_id: unidade.id,
                descricao,
                tipo: unidade.tipo,
                pessoa_id: unidade.pessoa_id
              }
            })
          }
        }))

        if(unidades.length === 1){
          contaFinanceira.unidade_id = unidades[0].id
          contaFinanceira.pessoa_id = unidades[0].pessoa_id
        }
        
        if (tipo === 'UNIDADE') {

          const contasBancariasFiltrada = contasBancarias.filter(param => param.pessoa.pessoa_id === unidades_acesso.unidade.pessoa_id)

          contaFinanceira.pessoa_id = unidades_acesso.unidade.pessoa_id
          contaFinanceira.unidade_id = unidades_acesso.unidade.unidade_id

          setState(prevState => ({...prevState,
            contasBancariasFiltrada: {
              list: contasBancariasFiltrada
            }
          }))
        }

        setState(prevState => ({...prevState,
          contaFinanceira,
          contasBancarias: {
            list: contasBancarias,
          },
          contasFinanceira: {
            list: contasFinanceira,
          }
        }))

        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/parceiroBancario`, getToken())

        let parceirosBancarios = data.map(parceiro => {
          return {
            _id: parceiro.id,
            descricao: parceiro.descricao,
            ativo: parceiro.ativo
          }
        })
        
        setState(prevState => ({...prevState,
          parceirosBancarios: {
            list: parceirosBancarios
          }
        }))

        const { data: bancos } = await axios.get(`${window._env_.REACT_APP_API_URL}/bancos`, getToken())

        setState(prevState => ({...prevState,
          bancos: {
            list: bancos
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (contaFinanceiraId) {
        try {

          const tab = localStorage.getItem('tab_cad_conta_fin')

          if(tab){
            setState(prevState => ({...prevState,
              tab: parseInt(tab)
            }))
          }else{
            setState(prevState => ({...prevState,
              tab: 0
            }))
          }

          const { data: contaFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/${contaFinanceiraId}`, getToken())

          let parceiro = null
          let informar_parceiro = null

          if(contaFinanceira.conta_corrente){
            if(contaFinanceira.conta_corrente.parceiro){
              parceiro = {
                id: contaFinanceira.conta_corrente.parceiro.id,
                api_key: contaFinanceira.conta_corrente.parceiro.api_key,
                token: contaFinanceira.conta_corrente.parceiro.token,
                parceiro_id: contaFinanceira.conta_corrente.parceiro.parceiro_bancario_id,
                parceiro_descricao: contaFinanceira.conta_corrente.parceiro.parceiro_bancario.descricao,
                bol_multa: contaFinanceira.conta_corrente.parceiro.bol_multa * 100 + ' %',
                bol_juros: contaFinanceira.conta_corrente.parceiro.bol_juros,
                bol_texto: contaFinanceira.conta_corrente.parceiro.bol_texto,
                bol_qtd_dias_pag: contaFinanceira.conta_corrente.parceiro.bol_qtd_dias_pag
              }
    
              setState(prevState => ({...prevState,
                contas_relacao:{
                  list: contaFinanceira.conta_corrente.parceiro.contas_relacao
                }
              }))
    
              await buscaContasPH(contaFinanceira.conta_corrente.parceiro.id)
    
              informar_parceiro = true

            }else{
              parceiro = {
                id: '',
                api_key: '',
                token: '',
                parceiro_id: '',
                parceiro_descricao: '',
                bol_multa: '0.0%',
                bol_juros: false,
                bol_texto: "",
                bol_qtd_dias_pag: ""
              }
    
              informar_parceiro = false
            }
          }else{
            parceiro = {
              id: '',
              api_key: '',
              token: '',
              parceiro_id: '',
              parceiro_descricao: '',
              bol_multa: '0.0%',
              bol_juros: false,
              bol_texto: "",
              bol_qtd_dias_pag: ""
            }

            informar_parceiro = false
          }

          let contaFinanceiraAux = {
            descricao: contaFinanceira.descricao,
            ordem: contaFinanceira.ordem,
            conciliavel: contaFinanceira.conciliavel,
            saldo_inicial: converteFloatMoeda(contaFinanceira.saldo_inicial),
            saldo_atual: converteFloatMoeda(contaFinanceira.saldo_atual),
            tipo_conta: contaFinanceira.tipo_conta,
            pessoa_id: contaFinanceira.pessoa_id,
            ativo: contaFinanceira.ativo,
            cofre: contaFinanceira.cofre ? 'Sim' : 'Não',
            saldo_nao_conciliado: contaFinanceira.saldo_nao_conciliado,
            limite: converteFloatMoeda(contaFinanceira.limite),
            data_abertura: contaFinanceira.data_abertura ? moment(contaFinanceira.data_abertura).format('YYYY-MM-DD') : '',
            unidade_id: contaFinanceira.unidade_id,
            informar_parceiro,
            parceiro
          }

          if (contaFinanceira.conta_corrente) {
            const conta_corrente = {
              id: contaFinanceira.conta_corrente.id,
              cb_id: contaFinanceira.conta_corrente.cb_id,
            }

            const contaBancaria = {
              id: contaFinanceira.conta_corrente.id,
              agencia: contaFinanceira.conta_corrente.conta.agencia,
              numero: contaFinanceira.conta_corrente.conta.numero,
              gerente: contaFinanceira.conta_corrente.conta.gerente,
              chave_pix: contaFinanceira.conta_corrente.conta.chave_pix,
              banco_id: contaFinanceira.conta_corrente.conta.banco_id,
              banco: {
                id: contaFinanceira.conta_corrente.conta.banco.id,
                nome: contaFinanceira.conta_corrente.conta.banco.nome,
                numero: contaFinanceira.conta_corrente.conta.banco.numero,
                padraocosif: contaFinanceira.conta_corrente.conta.banco.padraocosif
              }
            }

            contaFinanceiraAux.conta_corrente = conta_corrente

            setState(prevState => ({...prevState,
              contaBancaria
            }))

          }else{
            contaFinanceiraAux.conta_corrente = {
              id: "",
              cb_id: "",
            }
          }

          let descricao = ''

          if(state.tipo === 'UNIDADE'){
            descricao = contaFinanceira.pessoa.unidade.descricao
          }else{
            descricao = listaUnidades.filter(param => param.id === contaFinanceira.unidade_id)[0].descricao
          }

          let criarConta = false
          if(contaFinanceira.tipo_conta !== 'BANCO'){
            criarConta = true
          }

          await consultaContasBancarias(contaFinanceira.unidade_id)

          setState(prevState => ({...prevState,
            contaFinanceira: contaFinanceiraAux,
            unidade: {
              descricao,
              unidade_id: contaFinanceira.pessoa.unidade.id,
              tipo: contaFinanceira.pessoa.unidade.tipo,
              pessoa_id: contaFinanceira.pessoa.unidade.pessoa_id
            },
            update: true,
            criarConta
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
          return false
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { contaFinanceira, tab} = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Contas Bancárias</h1>
                </Grid>
              </Grid>
              <AppBar position="static" className="mg_top_10">
                <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Dados da Conta" {...a11yProps(0)} />
                  <Tab label="Dados Bancário" {...a11yProps(1)} />
                  <Tab label="Emissão de Boleto" {...a11yProps(2)} />
                </Tabs>
              </AppBar>

              <TabPanel value={tab} index={0}>
                <FormContaFinanceira
                  dados={contaFinanceira}
                  updateField={e => updateField(e)}
                  updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
                  update={state.update}
                  unidades={state.unidades.list}
                  unidade={state.unidade}
                  contasBancarias={state.contasBancariasFiltrada.list}
                  addConta={e => addConta()}
                  criarConta={state.criarConta}
                  updateFieldAtivo={e => updateFieldAtivo(e)}
                  updateFieldSelectConta={e => updateFieldSelectConta(e)}
                  tipo={state.tipo}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <FormDadosBancario
                  dados={contaFinanceira}
                  updateFieldBanco={(event, value) => updateFieldBanco(event, value)}
                  updateFieldContaBancaria={e => updateFieldContaBancaria(e)}
                  banco={state.contaBancaria.banco}
                  bancos={state.bancos.list}
                  contaBancaria={state.contaBancaria}
                />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <FormDadosParceiro
                  dados={contaFinanceira}
                  parceirosBancarios={state.parceirosBancarios.list}
                  updateFieldContaCorrente={e => updateFieldContaCorrente(e)}
                  updateFieldInformarParceiro={e => updateFieldInformarParceiro(e)}
                  updateFieldParceiro={e => updateFieldParceiro(e)}
                  contasPH={state.contasPH.list}
                  contasFinanceira={state.contasFinanceira.list}
                  updateFieldContaPH={(e, index) => updateFieldContaPH(e, index)}
                  updateFieldCobrarJuros={e => updateFieldCobrarJuros()}
                />
              </TabPanel>
              <Grid container spacing={1} direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={8} xs={12} sm={8}></Grid>
                <Grid item md={2} xs={12} sm={4}>
                  {tab === 2 && 
                    <React.Fragment>
                      {contaFinanceira.informar_parceiro &&
                        <React.Fragment>
                            {!contaFinanceira.parceiro.id && 
                              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => relacionarContas(e)}>Relacionar Contas</Button>
                            }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => salvar(true)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
            <br />
          </Main>
          <Nav/>
          <ModalConfirmacao
            open={state.modalConfirmacaoOpen} 
            dados={state.modalConfirmacao} 
            handleClose={e => handleCloseConfirmacao(e)}  
            confirmar={e => continuarSalvamento(e)} />
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Contas Bancários"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroContaFinanceira