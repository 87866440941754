import React from 'react';
import Modal from '@mui/material/Modal';
import { Grid} from '@mui/material'
import MarkdownView from 'react-showdown';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const {} = props
  const theme = useTheme();
  
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={10} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Ajuda - {props.tela}</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} style={{marginTop: 20}}>
            <Grid item md={12} xs={12} sm={12}>
              {/* <Markdown options={{ forceBlock: true }}>{props.markdown}</Markdown> */}
              <MarkdownView
                markdown={props.markdown}
                options={{ tables: true, emoji: true }}
              />
            </Grid>
          </Grid>

        </StyledPaper>
      </Modal>
    </div>
  );
}
