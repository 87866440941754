import React from 'react'
import real from './../../../services/real'
import { Grid } from '@mui/material'

const Dre = ({ dados, planosConta, minimizarMaximizarRows, itensFechadoDre }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12} id='dre'>
        <table className="mg_top_20 lista sempadding borderTable">
          <thead>
            <tr className="titulos acompanha">
              {dados[0].map((value, i) => {
                return (
                  <th style={{width: i === 0 ? '20%' : `${80 / (dados[0].length - 1)}%`}}>{value}</th>
                )
              })}
            </tr>
          </thead>
          <tbody className='rolagemTabela'>
              {dados.map((value, key) => {
                if(key !== 0){
                  let planoLinha = null
                  let indexFechado = -1
                  let indexFechadoItem = -1
                  return (
                    <tr key={key} style={{backgroundColor: '#fff', borderBottom: '1px solid #A9A9A9'}}>
                      {value.map((linha, index) => {
                        if(index === 0 && linha !== 'RESULTADO'){
                          planoLinha = planosConta.filter(param => param.codigo === linha.split(' - ')[0])[0]

                          indexFechado = itensFechadoDre.findIndex(param => {
                            let codigo = linha.split(' - ')[0]

                            let codigo_div = codigo.split('.')

                            if(codigo_div.length > 1){
                              if(codigo === param){
                                return false
                              }else if(codigo_div[0] === param){
                                return true
                              }else if (`${codigo_div[0]}.${codigo_div[1]}` === param){
                                return true
                              }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}` === param){
                                return true
                              }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}.${codigo_div[3]}` === param){
                                return true
                              }else{
                                return false
                              }

                            }else{
                              return false
                            }
                          })
                          indexFechadoItem = itensFechadoDre.findIndex(param => {
                            let codigo = linha.split(' - ')[0]

                            let codigo_div = codigo.split('.')

                            if(codigo === param){
                              if(codigo_div[0] === param){
                                return true
                              }else if (`${codigo_div[0]}.${codigo_div[1]}` === param){
                                return true
                              }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}` === param){
                                return true
                              }else if (`${codigo_div[0]}.${codigo_div[1]}.${codigo_div[2]}.${codigo_div[3]}` === param){
                                return true
                              }else{
                                return false
                              }
                            }else{
                              return false
                            }
                          })
                        }

                        console.log(linha)
                        
                        return (
                          <td style={{
                            display: indexFechado !== -1 ? 'none': '',
                            width: index === 0 ? '20%' : `${80 / (value.length -1)}%`,
                            textAlign: index === 0 ? 'left' : 'center',
                            paddingTop: 5,
                            paddingLeft: index === 0 ? ( linha === 'RESULTADO' ? 10 :(planoLinha ? `${planoLinha.nivel * 10}px` : '0px')) : ( linha === 'RESULTADO' ? '10px' : '0px'),
                          }}>
                            <p style={{
                              fontWeight: planoLinha ? (planoLinha.totalizador ? 'bold' : 'none') : (linha === 'RESULTADO' ? 'bold' : 'none' ),
                              margin: '0px'
                            }}>
                              {index === 0 &&
                                <React.Fragment>
                                  {planoLinha &&
                                    <React.Fragment>
                                      {planoLinha.totalizador &&
                                        <i 
                                          className={indexFechadoItem !== -1 ? "fa fa-plus" : "fa fa-minus"}
                                          aria-hidden="true" 
                                          style={{marginRight: '5px', cursor: 'pointer'}}
                                          onClick={e => minimizarMaximizarRows(linha)}
                                        ></i>
                                      }
                                    </React.Fragment>
                                  }
                                </React.Fragment>
                              }

                              <b>
                                {index === 0 &&
                                  <span>{linha}</span>
                                }  
                                {index !== 0 &&
                                  <React.Fragment>
                                    <span style={{color: index !== 0 ? (parseFloat(linha.split('|')[0]) > 0 ? 'green' : (parseFloat(linha.split('|')[0]) < 0 ? 'red' : '#000')) : '#000'}}>R$ {real(linha.split('|')[0])}</span>
                                    {parseFloat(linha.split('|')[1]) !== 0 &&
                                      <span style={{fontSize: 12}}> ({linha.split('|')[1]}%)</span>
                                    }
                                  </React.Fragment>
                                }
                              </b>
                            </p>
                          </td>
                        )
                      })}
                    </tr>
                  )
                }

                return false
              })}
          </tbody>
        </table>
      </Grid>
    </Grid>

  </React.Fragment>
)

export default Dre
