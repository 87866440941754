import React from 'react'
import { Grid, TextField, FormControlLabel, FormLabel, FormControl, Switch, Button } from '@mui/material'
import FileList from './../uploadMidia/FileList'
import Upload from './../uploadMidia/Upload'
import AddIcon from '@mui/icons-material/Add';
import Table from './../Table'
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';

import { styled } from '@mui/system';
import Color from 'color';

const cabecalhoTabela = [
  { id: 'produtoItem', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'quantidade', numeric: false, disablePadding: true, label: 'Quantidade' },
  { id: 'duracao', numeric: false, disablePadding: true, label: 'Duração' },
  { id: 'ambientes', numeric: false, disablePadding: true, label: 'Ambientes/Equipamentos' },
  { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const cabecalhoTabelaComServico = [
  { id: 'produtoItem', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'quantidade', numeric: false, disablePadding: true, label: 'Quantidade' },
  { id: 'duracao', numeric: false, disablePadding: true, label: 'Duração' },
  { id: 'ambientes', numeric: false, disablePadding: true, label: 'Ambientes/Equipamentos' },
  { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const acoesTabela = ['editar', 'remove']

const FormProduto = ({ grupoProdutos, subGrupoProdutos, dados, updateField, updateFieldAmbiente,
  updateFieldAtivo, uploadedFiles, handleUpload, handleDelete, listaProdutos, ambientesSelecionado,
  addItemCombo, updateFieldCombo, updateFieldItemAtivo,itemCombo, editarItem, removerItem, update, 
  unidades, tipo, planoContas, ambientes, updateFieldDestaque, updateFieldItemPrincipal}) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Grupo Produtos*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idGrupoProduto"
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}}
          disabled={dados.unidade_id === ''}
        >
          <option value={dados.grupoProduto === "" ? "" : dados.idSubGrupoProduto}> {dados.grupoProduto === "" ? "Selecionar Grupo Produto" : dados.grupoProduto} </option>
          {grupoProdutos.map(grupoProduto => {
            return (
              <option key={grupoProduto.id} value={grupoProduto.id}> {grupoProduto.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Sub Grupo Produtos*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idSubGrupoProduto"
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.idGrupoProduto === ''}
        >
          <option value={dados.subGrupoProduto === "" ? "Selecionar Sub Grupo Produto" : dados.idsubGrupoProduto}> {dados.subGrupoProduto === "" ? "Selecionar Sub Grupo Produto" : dados.subGrupoProduto} </option>
          {subGrupoProdutos.map(subGrupoProduto => {
            return (
              <option key={subGrupoProduto._id} value={subGrupoProduto._id}> {subGrupoProduto.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Descrição*" variant="outlined" size="small" name="descricao" value={dados.descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={dados.idGrupoProduto === ''} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade de Medida*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidadeMedida"
          value={dados.unidadeMedida}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.idSubGrupoProduto === ''}
        >
          {dados.unidadeMedida === "" &&
            <option value="">
              Selecionar Unidade Medida
					  </option>
          }
          <option value="UNI"> Unidade </option>
          <option value="CAIXA"> Caixa</option>
          <option value="KG"> KG</option>
          <option value="GRAMAS"> Gramas</option>
          <option value="LITROS"> Litros</option>
          <option value="METROS"> Metros</option>
          <option value="CENTIMETROS"> Centimetro</option>
          <option value="COMBO"> Combo</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Visibilidade*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{ native: true }}
          name="visibilidade"
          value={dados.visibilidade}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.idSubGrupoProduto === ''}
        >
          {dados.visibilidade === "" &&
            <option value=""> Selecionar Visibilidade </option>
          }
          {tipo === 'MANTENEDOR' &&
            <React.Fragment>
              <option value="MANTENEDOR"> Mantenedor</option>
              <option value="REPRESENTANTE"> Representante</option>
              <option value="GRUPOECONOMICO"> Grupo Econômico</option>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
          {tipo === 'REPRESENTANTE' &&
            <React.Fragment>
              <option value="REPRESENTANTE"> Representante</option>
              <option value="GRUPOECONOMICO"> Grupo Econômico</option>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
          {tipo === 'GRUPOECONOMICO' &&
            <React.Fragment>
              <option value="GRUPOECONOMICO"> Grupo Econômico</option>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
          {tipo === 'UNIDADE' &&
            <React.Fragment>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
        </TextField>
      </Grid>
      {/* <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Plano de Contas"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="planocontas_id"
          size="small"
          value={dados.planocontas_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.visibilidade === '' ? true: false}
        >
          <option value="">Selecionar Plano de Contas</option>
          {planoContas.map(plano => {
            return (
              <option value={plano.id} disabled={plano.totalizador} className={plano.totalizador ? 'agrupador' : ''}  >{plano.codigo} - {plano.descricao}</option>
            )
          })}
        </TextField>
      </Grid> */}
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Embalagem*" variant="outlined" size="small" name="embalagem" value={dados.embalagem} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={dados.idSubGrupoProduto === ''}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{ native: true }}
          name="tipo"
          value={dados.tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.idSubGrupoProduto === ''}
        >
          {dados.tipo === "" &&
            <option value=""> Selecionar Tipo </option>
          }
          <option value="SERVICO"> Serviço </option>
          <option value="PRODUTO"> Produto </option>
        </TextField>
      </Grid>
      {dados.tipo === 'SERVICO' &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='number' 
              className="input" 
              label="Duração Serviço (Min)*" 
              variant="outlined" 
              size="small" 
              name="duracao" 
              value={dados.duracao} 
              onChange={(e) => updateField(e)} 
              InputLabelProps={{ shrink: true }}
              disabled={(dados.tipo === '' || dados.unidadeMedida === 'COMBO')}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Serviço Preparo"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="preparo"
              value={dados.preparo}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Não utiliza preparo</option>
              {listaProdutos.map(produto => {
                return (
                  <option key={produto._id} value={produto._id}>
                    {produto.descricao}
                  </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='number' 
              className="input" 
              label="Duração Preparo (Min)" 
              variant="outlined" 
              size="small" 
              name="duracaoPreparo" 
              value={dados.duracaoPreparo} 
              onChange={(e) => updateField(e)} 
              InputLabelProps={{ shrink: true }}
              disabled={dados.tipo === ''}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Serviço Limpeza"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="limpeza"
              value={dados.limpeza}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Não utiliza limpeza</option>
              {listaProdutos.map(produto => {
                return (
                  <option key={produto._id} value={produto._id}>
                    {produto.descricao}
                  </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='number' 
              className="input" 
              label="Duração Limpeza (Min)" 
              variant="outlined" 
              size="small" 
              name="duracaoLimpeza" 
              value={dados.duracaoLimpeza} 
              onChange={(e) => updateField(e)} 
              InputLabelProps={{ shrink: true }}
              disabled={dados.tipo === ''}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={6} id='form-contas'>
            <Autocomplete
              multiple
              limitTags={6}
              id="multiple-limit-tags"
              options={ambientes.filter(ambiente => {
                if (ambientesSelecionado.findIndex(i => i.id === ambiente.id) === -1){
                  return ambiente;
                }else{
                  return false
                }
              })}
              size="small"
              value={ambientesSelecionado}
              onChange={(event, value) => updateFieldAmbiente(event, value)}
              getOptionLabel={(option) => option.nome}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Ambientes" InputLabelProps={{ shrink: true }}/>
              )}
            />
          </Grid>
        </React.Fragment>
      }
      {dados.tipo === 'PRODUTO' && 
        <Grid item md={2} xs={12} sm={6}>
          <TextField 
          type='number' 
          className="input" 
          label="Cod. Barras" 
          variant="outlined" 
          size="small" 
          name="codigo" 
          value={dados.codigo} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }}
          disabled={(dados.tipo === '' || dados.unidadeMedida === 'COMBO')}/>
        </Grid>
      }
      <Grid item md={1} xs={6} sm={6} className="mg_left_10">
        <FormControl component="fieldset">
          <FormLabel component="legend">Destaque ?</FormLabel>
          <FormControlLabel 
            control={
              <Switch
                name="destaque"
                color="primary"
                checked={dados.destaque}
              />
            }
            size="small"
            label={dados.destaque ? 'Sim' : 'Não'}
            name="destaque"
            onChange={(e) => updateFieldDestaque(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={1} xs={6} sm={6} className="mg_left_10">
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo ?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <TextField
          id="outlined-multiline-static"
          label="Observação"
          className="input"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          name="observacao"
          value={dados.observacao}
          onChange={(e) => updateField(e)}
          disabled={dados.idSubGrupoProduto === ''}
        />
      </Grid>
      {/* <Grid item md={2} xs={12} sm={9}>
        <Upload onUpload={handleUpload} />
      </Grid>
      {!!uploadedFiles.length && (
        <Grid item md={2} xs={12} sm={3}>
          <FileList files={uploadedFiles} onDelete={handleDelete} />
        </Grid>
      )} */}
    </Grid>    
    {(dados.unidadeMedida === 'COMBO' && dados.tipo !== '') &&
      <div>
        <hr />
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={6}>
            <h4 className="titulo">Produtos do Combo:</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={4} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Produto"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="produtoItem"
              value={itemCombo.produtoId}
              onChangeCapture={(e) => updateFieldCombo(e)}
              InputLabelProps={{ shrink: true }}
            >
              {itemCombo.produtoId === "" &&
                <option value="">
                  Selecionar Produto do Combo
                        </option>
              }
              {listaProdutos.map(produto => {
                return (
                  <option key={produto._id} value={produto._id}>
                    {produto.descricao}
                  </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" type="number" label="Quantidade" variant="outlined" size="small" name="quantidade" value={itemCombo.quantidade} onChange={(e) => updateFieldCombo(e)} />
          </Grid>
          <Grid item md={2} xs={12} sm={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend"> Item Principal?</FormLabel>
              <FormControlLabel className="mg_left_10"
                control={
                  <Switch
                    name="Ativo"
                    color="primary"
                    checked={itemCombo.principal}
                  />
                }
                size="small"
                label={itemCombo.principal ? 'Sim' : 'Não'}
                name="principal"
                onChange={(e) => updateFieldItemPrincipal(itemCombo.index)}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12} sm={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend"> Ativo?</FormLabel>
              <FormControlLabel className="mg_left_10"
                control={
                  <Switch
                    name="Ativo"
                    color="primary"
                    checked={itemCombo.ativo}
                  />
                }
                size="small"
                label={itemCombo.ativo ? 'Sim' : 'Não'}
                name="ativo"
                onChange={(e) => updateFieldItemAtivo(itemCombo.index)}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12} sm={6} className="alignCenter">
            <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={update ? <AddIcon /> : <SaveIcon />} onClick={e => addItemCombo(e)}>{update ? 'Alterar' : 'Adicionar'}</Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <Table
              editar={e => editarItem(e)}
              remove={e => removerItem(e)}
              headCell={dados.tipo === 'SERVICO' ? cabecalhoTabelaComServico : cabecalhoTabela}
              rows={dados.itensCombo.map(item => {
                return {
                  _id: item._id,
                  produtoItem: item.produtoItem,
                  quantidade: item.quantidade,
                  duracao: (item.duracao * item.quantidade) + ' Minutos',
                  ambientes: item.ambientes,
                  ativo: item.ativo,
                  principal: item.principal,
                  index: item.index
                }
              })}
              acoes={acoesTabela} />
          </Grid>
        </Grid>
      </div>
    }
  </React.Fragment >
)

export default FormProduto