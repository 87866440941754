import React, { Component } from 'react'
import './ContaFinanceira.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import real from '../../services/real'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  contaFinanceira: {

  },
  contaFinanceiras: {
    list: []
  },
  contaFinanceirasFiltrado: {
    list: []
  },
  filtro: {
    tipo_conta: "",
    descricao: "",
    ativo: "Sim"
  },
  cabecalhoTabela: [
    { id: 'url_banco', numeric: false, disablePadding: true, label: 'Logo' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'saldo_atual', numeric: false, disablePadding: false, label: 'Saldo Atual' },
    { id: 'saldo_nao_conciliado', numeric: false, disablePadding: false, label: 'Saldo Não Conciliado' },
    { id: 'limite', numeric: false, disablePadding: false, label: 'Limite de Crédito' },
    { id: 'tipo_conta', numeric: false, disablePadding: false, label: 'Tipo Conta' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCorrigeSaldo: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  loading: true,
  mensagemLoading: 'Carregando Contas Bancárias...',
  openModalAjuda: false,
  markdown: ''
}

export default class contaFinanceiras extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    try {

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'conta-financeira')[0]
      let permissoesCorrigeSaldo = perfil.permissoes.filter(param => param.tela.modulo.slug === 'ajuste_saldo_conta')[0]

      if (!permissoes.visualizar) {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: "Você não tem permissão para acessa essa tela!"
          }
        })

        this.backPage()
      }

      const acoesTabela = []

      if (permissoes.alterar) {
        acoesTabela.push('update')
      }

      if(permissoesCorrigeSaldo){
        if (permissoesCorrigeSaldo.inserir || permissoesCorrigeSaldo.alterar) {
          acoesTabela.push('corrigeSaldo')
        }
      }

      this.setState({ acoesTabela, permissoes })

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
      const filtro = this.state.filtro

      const dados = {
        filtro,
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
        return {
          _id: param.id,
          descricao: param.descricao,
          ordem: param.ordem,
          saldo_atual: 'R$ ' + real(param.saldo_atual),
          saldo_nao_conciliado: 'R$ ' + real(param.saldo_nao_conciliado),
          tipo_conta: param.tipo_conta,
          ativo: param.ativo ? 'Sim' : 'Não',
          unin_descricao: param.num_unidade + ' - ' + param.unidade,
          limite: 'R$ ' + real(param.limite),
          url_banco: param.logo
        }
      })

      this.setState({
        contaFinanceiras: {
          list: contaFinanceiras
        },
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })

  }

  async filtrarContas(){
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    const filtro = this.state.filtro

    const dados = {
      filtro,
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
        return {
          _id: param.id,
          descricao: param.descricao,
          ordem: param.ordem,
          saldo_atual: 'R$ ' + real(param.saldo_atual),
          saldo_nao_conciliado: 'R$ ' + real(param.saldo_nao_conciliado),
          tipo_conta: param.tipo_conta,
          ativo: param.ativo ? 'Sim' : 'Não',
          unin_descricao: param.num_unidade + ' - ' + param.unidade,
          limite: 'R$ ' + real(param.limite)
        }
      })
  
      this.setState({
        contaFinanceiras: {
          list: contaFinanceiras
        }
      })
    }  catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  render() {
    const { filtro, contaFinanceiras, permissoes } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={10} xs={12}>
                  <h1 className="titulo">Contas Bancárias</h1>
                </Grid>
                {permissoes.inserir &&
                  <Grid item md={2} xs={12}>
                    <Link to="/conta_financeira/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Conta
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Tipo Conta" variant="outlined" size="small" name="tipo_conta" value={filtro.tipo_conta} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Contas Ativas*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="ativo"
                    value={filtro.ativo}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value='Sim'> Sim </option>
                    <option value='Não'> Não </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={false} sm={false}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button color='warning' fullWidth variant="contained" onClick={() => this.filtrarContas()}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table
                    urlUpdate="/conta_financeira/cadastro/"
                    urlView="/conta_financeira/view/"
                    urlCorrigeSaldo="/conta_financeira/corrige_saldo/"
                    headCell={this.state.cabecalhoTabela}
                    rows={contaFinanceiras.list}
                    acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Contas Bancárias"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}