import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './../Cliente.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import Alert from './../../../components/templates/Alert'
import moment from 'moment'
import ModalMigrarDados from './../../../components/modals/MigrarDados'
import ModalVisualizarDocumento from './../../../components/modals/VisualizarDocumento'
import axios from 'axios'
import { format } from 'cnpj'
import CreateIcon from '@mui/icons-material/Create';
import initialState from './../initialState/clienteView';
import getToken from './../../../services/getToken'
import VisibilityIcon from '@mui/icons-material/Visibility';
import UploadImage from './../../../services/uploads/UploadImage'
import {
  ImagesProvider,
  URLEditor,
  ThumbnailOptionsProvider,
  Thumbnails
} from "react-thumbnails";
import {filesize} from "filesize";
import Tabela from './../../../components/Tabela'
import formatarTelefone from './../../../services/formatarTelefone'
import VideoThumbnail from 'react-video-thumbnail'; 

const cpf = require('node-cpf-cnpj');

const Cliente = (props) => {
  const [state, setState] = useState({ ...initialState });
  const [thumbnail, setThumbnail] = useState(null);

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
  
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'success',
        message: 'Link copiado'
      }
    }))
    return
    
  }

  const handleCloseAlerta = () =>{
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const migrarDados = () =>{
    setState(prevState => ({...prevState,
      openModalMigrarDados: true
    }))
  }

  const handleModalMigrarDados = () =>{
    setState(prevState => ({...prevState,
      openModalMigrarDados: false,
      clienteSelecionado: {
        nome: '',
        cpf_cnpj: '',
        pessoa_id: ''
      }
    }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }else if(event.target.value.length < 3){
      setState(prevState => ({...prevState,
        clientesFiltrado: {
          list: []
        }
      }))
    }
  }

  const consultarPessoa = async (nome) => {
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listSearch`, filtroPessoa, getToken())

      const pessoas = data.map(pessoa => {
        return {
          pessoa_id: pessoa.id,
          nome: pessoa.nome + (pessoa.sobrenome ? ` ${pessoa.sobrenome}` : ''),
        }
      })

      setState(prevState => ({...prevState,
        clientesFiltrado: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldCliente = async (event, value) => {

    if(!value){
      setState(prevState => ({...prevState,
        clienteSelecionado: {
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        }
      }))

      return false
    }

    setState(prevState => ({...prevState,
      clienteSelecionado: value
    }))
  }

  const confirmarMigracao = async (value, excluir) => {
    if(value){
      try {
        let dados = {
          de: state.clienteSelecionado.pessoa_id,
          para: state.cliente._id,
          excluir
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Migrando dados...'
          }
        }))

        await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/migracao`,dados, getToken())
        
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Migração Realizada com Sucesso.'
          }
        }))

        handleModalMigrarDados()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      handleModalMigrarDados()
    }
  }

  const openDocumento = (link, documento, formato) => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: true,
      documento: {
        nome: documento,
        url: link,
        formato
      }
    }))
  }

  const handleModalVisualizarDocumento = () => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: false,
      documento: {
        nome: '',
        url: ''
      }
    }))
  }

  const handleUploadImage = (files) => {   
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      index: state.uploadedFiles.length + key,
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      caminho_uploads: '',
      formato: file.type,
      url: null,
    }))

    concatUploadedFiles(uploadedFiles)
  }

  const concatUploadedFiles = async (uploadedFiles) => {
    await processUpload(uploadedFiles)
  }

  const processUpload = async (uploadedFile) => {
    const data = new FormData();
    const USER_TOKEN = localStorage.getItem('token')
    let {uploadedFiles, cliente} = state
    
    data.append("file", uploadedFile[0].file, uploadedFile[0].name);
    
    await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/imagem/${cliente._id}/${cliente.unidade_id}`, data, {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      onUploadProgress: e => {
        const progress = parseInt(Math.round((e.loaded * 100) / e.total));

        // let newUploadedFiles = {
        //   ...uploadedFile[0],
        //   progress
        // }
  
        // uploadedFiles = uploadedFiles.concat(newUploadedFiles)
  
        // setState((prevState) => ({
        //   ...prevState,
        //   uploadedFiles
        // }))
      }
    }).then(response => {

      let newUploadedFiles = {
        ...uploadedFile[0],
        uploaded: true,
        id: response.data.id,
        key: response.data.key,
        dimensoes: response.data.dimensoes
      }

      uploadedFiles = uploadedFiles.concat(newUploadedFiles)

      setState((prevState) => ({
        ...prevState,
        uploadedFiles,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Imagem Salva!'
        }
      }))


    }).catch((error) => {
      console.log(error)

      // let newUploadedFiles = {
      //   ...uploadedFile[0],
      //   error: true
      // }

      // uploadedFiles = uploadedFiles.concat(newUploadedFiles)

      setState(prevState => ({...prevState,
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: 'Erro Interno'
        }
      }))
    });
  }

  const removerImagem = async (img) => {
    try {
      await axios.delete(`${window._env_.REACT_APP_API_URL}/pessoa/imagem/${img.id}`, getToken())
    
      const {uploadedFiles} = state

      let index = uploadedFiles.findIndex(param => param.id === img.id)

      uploadedFiles.splice(index, 1)

      setState(prevState => ({...prevState,
        uploadedFiles
      }))

      window.location.reload()
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'cliente')[0]
    
      if (permissoes) {
        let acoesTabela = []
  
        if (permissoes.visualizar) {
          acoesTabela.push('viewcliente')
        }
  
        if (permissoes.alterar) {
          acoesTabela.push('update')
        }
        setState(prevState => ({...prevState,
          permissoes,
          acoesTabela
        }))
      }
  
      const cliente = props.cliente
  
      setState(prevState => ({...prevState,
        cliente: cliente,
        dadosComplementaresPF: props.dadosComplementaresPF,
        uploadedFiles: props.uploadedFiles
      }))
    };
    
    fetchData();
  }, []);

  const { cliente, permissoes, cabecalhoTabelaEndereco, acoesTabelaEndereco, cabecalhoTabelaTelefone, 
    cabecalhoTabelaEmail, uploadedFiles } = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Dados do cliente...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          {cliente.tipoPessoa === 'FISICA' &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={cliente.pessoaFisica.nome} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Sobrenome" value={cliente.pessoaFisica.sobrenome} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cliente.pessoaFisica.cpf} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={cliente.pessoaFisica.dataNascimento} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
              </Grid>
            </React.Fragment>
          }
          {cliente.tipoPessoa === 'JURIDICA' &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={cliente.pessoaJuridica.razaoSocial} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantansia" value={cliente.pessoaJuridica.nomeFantasia} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={cliente.pessoaJuridica.cnpj || ''} disabled InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={cliente.pessoaJuridica.inscricaoEstadual || ''} disabled InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data Fundação" value={cliente.pessoaJuridica.dataFundacao || ''} disabled InputLabelProps={{ shrink: true }}/>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <hr style={{marginTop: 10}}/>
          {cliente.dadosComplementaresPF &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Dados Complementares: </h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                {state.dadosComplementaresPF.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                  if(value.column_name === 'como_conheceu_id'){
                    return (
                      <Grid key={value.dtd_identifier} item md={3} sm={6} xs={12}>
                        <TextField 
                          fullWidth 
                          size="small" 
                          variant="outlined" 
                          className="input" 
                          label={value.column_comment} 
                          value={
                            cliente.dadosComplementaresPF.como_conheceu ? cliente.dadosComplementaresPF.como_conheceu.descricao : 'Não Informado'
                          } 
                          disabled 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )
                  }else if(value.column_name === 'receber_novidades'){
                    return (
                      <Grid key={value.dtd_identifier} item md={3} sm={6} xs={12}>
                        <TextField 
                          fullWidth 
                          size="small" 
                          variant="outlined" 
                          className="input" 
                          label={value.column_comment} 
                          value={cliente.dadosComplementaresPF.receber_novidades ? 'Sim' : 'Não'} 
                          disabled 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )
                  }else if(value.column_name !== 'doc_rg_frente' && value.column_name !== 'doc_rg_verso' && value.column_name !== 'doc_cnh_frente' &&
                    value.column_name !== 'doc_cnh_verso' && value.column_name !== 'doc_comprovante_renda' && value.column_name !== 'doc_comprovante_endereco'){
                    return (
                      <Grid key={value.dtd_identifier} item md={3} sm={6} xs={12}>
                        <TextField 
                          fullWidth 
                          size="small" 
                          variant="outlined" 
                          className="input" 
                          label={value.column_comment} 
                          value={
                            cliente.dadosComplementaresPF[value.column_name] 
                            ? (value.data_type === 'date' ? moment(cliente.dadosComplementaresPF[value.column_name]).format('DD/MM/YYYY') : cliente.dadosComplementaresPF[value.column_name]) 
                            : 'Não informado'
                          } 
                          disabled 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )  
                  }
                })}
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                {state.dadosComplementaresPF.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                  if(value.column_name === 'doc_rg_frente' || value.column_name === 'doc_rg_verso' || value.column_name === 'doc_cnh_frente' ||
                    value.column_name === 'doc_cnh_verso' || value.column_name === 'doc_comprovante_renda' || value.column_name === 'doc_comprovante_endereco'){
                      return (
                        <Grid key={value.dtd_identifier} item md={2} sm={6} xs={12} style={{position: 'relative'}}>
                          <span>{value.column_comment}</span>
                          {cliente.dadosComplementaresPF[`${value.column_name}_obj`] &&
                            <React.Fragment>
                              <Tooltip title='Visualizar' aria-label="add">
                                <VisibilityIcon color="warning" style={{position: 'absolute', top: 30, right: 10, cursor: 'pointer', fontSize: 32, zIndex: 999}} onClick={() => openDocumento(cliente.dadosComplementaresPF[`${value.column_name}_obj`].caminho, value.column_comment, cliente.dadosComplementaresPF[`${value.column_name}_obj`].formato)}/>
                              </Tooltip>
                              <ImagesProvider
                                options={{ maxImagesCount: 1 }}
                                urls={[cliente.dadosComplementaresPF[`${value.column_name}_obj`].caminho]}
                              >
                                <ThumbnailOptionsProvider
                                  defaults={{
                                    size: "small"
                                  }}
                                >
                                  <Thumbnails />
                                </ThumbnailOptionsProvider>
                              </ImagesProvider>
                            </React.Fragment>
                          }
                        </Grid>
                      )
                  }
                })}
              </Grid>
            </React.Fragment>
          }
          <hr style={{marginTop: 10}}/>
          {cliente.enderecos.length > 0 &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Endereços do cliente: </h4>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12}>
                  <Tabela 
                    headCell={cabecalhoTabelaEndereco} 
                    rows={cliente.enderecos.map(endereco => {
                      return {
                        cep: endereco.cep,
                        endereco: `${endereco.rua}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                        cidade: endereco.cidade,
                        estado: endereco.estado,
                        principal: endereco.principal,
                        tipoEndereco: endereco.tipoEndereco,
                        index: endereco.index
                      }
                    })} 
                    acoes={acoesTabelaEndereco}
                  />
                </Grid>
              </Grid>
              <hr style={{marginTop: 10}}/>
            </React.Fragment>
          }
          {cliente.contatos.length > 0 &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Telefones do cliente: </h4>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12}>
                  <Tabela 
                    headCell={cabecalhoTabelaTelefone} 
                    rows={cliente.contatos.map(contato => {
                      return {
                        numero: formatarTelefone(contato.numero),
                        tipoContato: contato.tipoContato,
                        principal: contato.principal,
                        index: contato.index
                      }
                    })} 
                    acoes={acoesTabelaEndereco}
                  />
                </Grid>
              </Grid>
            <hr style={{marginTop: 10}}/>
            </React.Fragment>
          }
          {cliente.emails.length > 0 &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">E-mails do cliente: </h4>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12}>
                  <Tabela 
                    headCell={cabecalhoTabelaEmail} 
                    rows={cliente.emails.map(email => {
                      return {
                        email: email.email,
                        principal: email.principal,
                        index: email.index
                      }
                    })} 
                    acoes={acoesTabelaEndereco}
                  />
                </Grid>
              </Grid>
              <hr style={{marginTop: 10}}/>
            </React.Fragment>
          }
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className="titulo">Acesso do cliente: </h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={4} xs={12} sm={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Link" value={`${window._env_.REACT_APP_URL}/boletos/cliente/${cliente.contrato ? (cliente.contrato.resp_fin.length > 0 ? cliente.contrato.resp_fin[0].pessoa_id : cliente._id) : cliente._id}`} InputLabelProps={{ shrink: true }} disabled />
            </Grid>
            <Grid item md={2} xs={12} sm={12}>
              <Tooltip title='Copiar Link' aria-label="add">
                <IconButton style={{marginTop: '-3px', marginLeft: '-10px'}} color="primary" aria-label="view" onClick={e => copyLink(`${window._env_.REACT_APP_URL}/boletos/cliente/${cliente.contrato ? (cliente.contrato.resp_fin.length > 0 ? cliente.contrato.resp_fin[0].pessoa_id : cliente._id) : cliente._id}`)}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <hr style={{marginTop: 10}}/>
          <Grid container direction="row" spacing={1} style={{marginTop: 10}}>
            <Grid item md={3} xs={12} sm={3}>
              <h5 className='titulo'>Imagens (Limite 2MB):</h5>
            </Grid>
            <Grid item md={9} xs={12} sm={9}>
              <UploadImage onUpload={e => handleUploadImage(e)} />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            {uploadedFiles.map((img, key) => {
              if(img.formato === "video/mp4" || img.formato === "video/webm" || img.formato === "video/ogg"){
                return (
                  <Grid key={key} item md={2} xs={12} sm={4} style={{textAlign: 'right', marginTop: 10}}>
                    <div style={{position: 'relative'}}>
                      <Tooltip title='Visualizar' aria-label="add">
                        <VisibilityIcon color="warning" style={{position: 'absolute', top: 0, right: 10, cursor: 'pointer', fontSize: 32, zIndex: 999}} onClick={() => openDocumento(img.preview, '', img.formato)}/>
                      </Tooltip>
                      <div style={{ display: 'none' }}>
                        <VideoThumbnail
                          videoUrl={img.preview}
                          thumbnailHandler={setThumbnail}
                          snapshotAtTime={2}
                          renderThumbnail={false}
                        />
                      </div>

                      {thumbnail ? (
                        <img src={thumbnail} alt="Thumbnail do Vídeo" style={{ width: '300px' }} />
                      ) : (
                        <p style={{textAlign: 'left'}}>Gerando thumbnail...</p>
                      )}
                      <span style={{textAlign: 'left', float: 'left', textTransform: 'uppercase', fontSize: 14, marginTop: 3}}>{img.formato}</span> 
                      <Tooltip title='Excluir Midia' aria-label="add">
                        <span className='excluir_img' onClick={e => removerImagem(img)}>EXCLUIR</span> 
                      </Tooltip>
                    </div>
                  </Grid>
                )
              }else{
                return (
                  <Grid key={key} item md={2} xs={12} sm={4} style={{textAlign: 'right', marginTop: 10}}>
                    <div style={{position: 'relative'}}>
                      <Tooltip title='Visualizar' aria-label="add">
                        <VisibilityIcon color="warning" style={{position: 'absolute', top: 0, right: 10, cursor: 'pointer', fontSize: 32, zIndex: 999}} onClick={() => openDocumento(img.preview, '', img.formato)}/>
                      </Tooltip>
                      <ImagesProvider
                        options={{ maxImagesCount: 1 }}
                        urls={[img.preview]}
                      >
                        <ThumbnailOptionsProvider
                          defaults={{
                            size: "medium",
                            shadow: false, 
                            border: false
                          }}
                        >
                          <Thumbnails />
                        </ThumbnailOptionsProvider>
                      </ImagesProvider>
                      <span style={{textAlign: 'left', float: 'left', textTransform: 'uppercase', fontSize: 14, marginTop: 3}}>{img.formato}</span> 
                      <Tooltip title='Excluir Midia' aria-label="add">
                        <span className='excluir_img' onClick={e => removerImagem(img)}>EXCLUIR</span> 
                      </Tooltip>
                    </div>
                  </Grid>
                )
              }
            })}
          </Grid>

          <hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            {permissoes.alterar &&
              <Grid item md={2} sm={12} xs={3}>
                <Link to={`/cliente/cadastro/${cliente._id}`}>
                  <Button fullWidth size='small' variant="contained" color="primary" startIcon={<CreateIcon />}>
                    Editar cliente
                  </Button>
                </Link>
              </Grid>
            }
            {permissoes.inserir &&
              <Grid item md={3} xs={6} sm={6}>
                <Button size='small' fullWidth variant="contained" color="secondary" onClick={() => migrarDados()}>
                  Importar Dados
                </Button>
              </Grid>
            }
          </Grid>
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalMigrarDados 
        open={state.openModalMigrarDados}
        handleClose={e => handleModalMigrarDados()}
        clientes={state.clientesFiltrado.list}
        cliente={state.clienteSelecionado}
        updateFiltroPessoa={e => updateFiltroPessoa(e)}
        updateFieldCliente={(e, value) => updateFieldCliente(e, value)}
        pessoa={state.cliente}
        confirmar={(e, excluir) => confirmarMigracao(e, excluir)}
      />

      <ModalVisualizarDocumento 
        open={state.openModalVisualizarDocumento}
        handleClose={e => handleModalVisualizarDocumento()}
        documento={state.documento}
      />
    </React.Fragment>
  )
}

export default Cliente
