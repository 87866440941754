import React, { useState, useEffect } from 'react';
import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import './Representante.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import Loading from './../../components/templates/Loading'
import Alert from './../../components/templates/Alert'
import moment from 'moment';
import { format } from 'cnpj'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';
import formatarTelefone from './../../services/formatarTelefone'
import formatarCEP from './../../services/formatarCEP'
import getToken from './../../services/getToken'

const cpf = require('node-cpf-cnpj')

const initialState = {
  responsavel: {
    id: "",
    pessoa_id: "",
    descricao: "",
    tipoPessoa: "FISICA",
    unin_pessoa_id: "",
    pessoaFisica: {
      id: "",
      pessoa_id: "",
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    enderecos: [{
      id: "",
      cep_id: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: "",
      tipoEndereco: "RESIDENCIAL",
      index: "1"
    }],
    emails: [{
      id: "",
      email: "",
      principal: "",
      index: "1"
    }],
    contatos: [{
      id: "",
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: "",
      index: "1"
    }]
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes: {
    list: []
  }
}

const CadastroResp = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { representanteId, franqueadoId } = useParams();

  const updateFieldPessoaFisica = (event) => {
    const responsavel = { ...state.responsavel }
    responsavel.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({...prevState,  responsavel }))
  }

  const updateFieldEndereco = (event, index) => {
    const responsavel = { ...state.responsavel }
    responsavel.enderecos[index - 1][event.target.name] = event.target.value
    setState(prevState => ({...prevState,  responsavel }))
  }

  const updateFieldEmail = (event, index) => {
    const responsavel = { ...state.responsavel }
    responsavel.emails[index - 1][event.target.name] = event.target.value
    setState(prevState => ({...prevState,  responsavel }))
  }

  const updateFieldContato = (event, index) => {
    const responsavel = { ...state.responsavel }
    responsavel.contatos[index - 1][event.target.name] = event.target.value
    setState(prevState => ({...prevState,  responsavel }))
  }

  const formataTelefone = (event, index) => {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const responsavel = { ...state.responsavel }
    responsavel.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    setState(prevState => ({...prevState,  responsavel }))
  }

  const updateField = (event) => {
    const responsavel = { ...state.responsavel }
    responsavel[event.target.name] = event.target.value
    setState(prevState => ({...prevState,  responsavel }))
  }

  const updateFieldEnderecoPrincipal = (event, index) => {
    const responsavel = { ...state.responsavel }
    const indexAux = responsavel.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.enderecos[indexAux].principal = false
    }

    responsavel.enderecos[index - 1].principal = !responsavel.enderecos[index - 1].principal
    setState(prevState => ({...prevState,  responsavel }))
  }

  const updateFieldContatoPrincipal = (event, index) => {
    const responsavel = { ...state.responsavel }
    const indexAux = responsavel.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.contatos[indexAux].principal = false
    }

    responsavel.contatos[index - 1].principal = !responsavel.contatos[index - 1].principal
    setState(prevState => ({...prevState,  responsavel }))
  }

  const updateFieldEmailPrincipal = (event, index) => {
    const responsavel = { ...state.responsavel }
    const indexAux = responsavel.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.emails[indexAux].principal = false
    }

    responsavel.emails[index - 1].principal = !responsavel.emails[index - 1].principal

    setState(prevState => ({...prevState,  responsavel }))
  }

  const validarCpfCnpj = async (event) => {

    let cpf_cnpj = event.target.value
    const responsavel = state.responsavel

    if (cpf_cnpj.length <= 11) {
      cpf_cnpj = cpf.format(cpf_cnpj)
      if (!cpf.isValid(cpf_cnpj)) {

        responsavel.pessoaFisica.cpf = ""

        setState(prevState => ({...prevState,
          responsavel,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CPF Invalido! Por favor corrija o campo CPF'
          }
        }))

      } else {
        responsavel.pessoaFisica.cpf = cpf_cnpj
        setState(prevState => ({...prevState,  responsavel }))
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        responsavel.pessoaJuridica.cnpj = cpf_cnpj
        setState(prevState => ({...prevState,  responsavel }))

      } else {
        responsavel.pessoaJuridica.cnpj = ""
        setState(prevState => ({...prevState, 
          responsavel,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CNPJ Invalido! Por favor corrija o campo CNPJ'
          }
        }))
      }
    }
  }

  const pegaEndereco = async (event, index) => {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const responsavel = { ...state.responsavel }
      const enderecos = responsavel.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      responsavel.enderecos[index - 1] = endereco
      setState(prevState => ({...prevState,  responsavel }))

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        responsavel.enderecos[index - 1] = endereco

        setState(prevState => ({...prevState,
          responsavel,
          disableEndereco: true
        }))
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        responsavel.enderecos[index - 1] = endereco

        setState(prevState => ({...prevState, 
          responsavel,
          alerta: {
            open: true,
            severity: 'warning',
            message:'CEP Não encontrado!'
          }
        }))
      }
    }
  }

  const addNovoEndereco = () => {
    const responsavel = { ...state.responsavel }
    const enderecos = responsavel.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    responsavel.enderecos = enderecos
    setState(prevState => ({...prevState,  responsavel }))
  }

  const addNovoContato = () => {
    const responsavel = { ...state.responsavel }
    const contatos = responsavel.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: false,
      index
    })

    responsavel.contatos = contatos
    setState(prevState => ({...prevState,  responsavel }))
  }

  const addNovoEmail = () => {
    const responsavel = { ...state.responsavel }
    const emails = responsavel.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    responsavel.emails = emails
    setState(prevState => ({...prevState,  responsavel }))
  }

  const proximo = (atual, prox) => {
    const { responsavel } = state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      let valido = true

      if (responsavel.pessoaFisica.nome === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.cpf === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.dataNascimento === "") {
        valido = false
      }

      if (valido) {
        setState(prevState => ({...prevState, 
          enableDadosPessoa: false,
          enableDadosEndereco: true
        }))
      } else {
       setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios.'
          }
        }))
      }
    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      responsavel.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          valido = false
        }
      });
      if (valido) {
        setState(prevState => ({...prevState, 
          enableDadosEndereco: false,
          enableDadosContato: true
        }))
      } else {
       setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios.'
          }
        }))
      }
    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      responsavel.contatos.forEach(contato => {
        if (contato.numero === "") {
          valido = false
        }
      });
      if (valido) {
        setState(prevState => ({...prevState, 
          enableDadosContato: false,
          enableDadosEmail: true
        }))
      } else {
       setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios.'
          }
        }))
      }
    }
  }

  const voltar = (atual, anterior) => {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      setState(prevState => ({...prevState, 
        enableDadosPessoa: true,
        enableDadosEndereco: false
      }))
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      setState(prevState => ({...prevState, 
        enableDadosEndereco: true,
        enableDadosContato: false
      }))
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      setState(prevState => ({...prevState, 
        enableDadosContato: true,
        enableDadosEmail: false
      }))
    }
  }

  const validateForm = () => {
    const { responsavel } = state

    responsavel.emails.forEach(email => {
      if (email.email === "") {
        return false
      }
    })

    return true
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/representante");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState, 
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const salvar = async () => {
    const { responsavel } = state

    if (!validateForm()) {
     setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    setState(prevState => ({...prevState,  
      loading: true,
      mensagemLoading: 'Atualizando Cadastro do Representante'
    }))

    let emails = []
    let telefones = []
    let enderecos = []

    responsavel.emails.forEach(email => {
      if (email.id) {
        emails.push({
          id: email.id,
          email: email.email,
          principal: email.principal
        })
      } else {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      }
    })

    responsavel.contatos.forEach(telefone => {
      if (telefone.id) {
        telefones.push({
          id: telefone.id,
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      } else {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      }
    })

    responsavel.enderecos.forEach(endereco => {
      if (endereco.id) {
        enderecos.push({
          id: endereco.id,
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      } else {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      }
    })

    const dados = {
      responsavel: {
        unin_id: franqueadoId,
        pessoa_id: responsavel.pessoa_id,
        id: responsavel.pessoaFisica.id,
        nome: responsavel.pessoaFisica.nome,
        cpf: cpf.strip(responsavel.pessoaFisica.cpf),
        rg: responsavel.pessoaFisica.rg,
        rg_orgao_emissor: responsavel.pessoaFisica.rg_orgao_emissor,
        rg_uf: responsavel.pessoaFisica.rg_uf,
        rg_data_emissao: responsavel.pessoaFisica.rg_data_emissao,
        datanascimento: responsavel.pessoaFisica.dataNascimento,
        class_tipo: "",
        tipo: responsavel.tipoPessoa,
        emails,
        telefones,
        enderecos,
      }
    }

    try {

      await axios.put(`${window._env_.REACT_APP_API_URL}/franqueados/responsavel/${representanteId}`, dados, getToken())

      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const removeEnderecoResponsavel = (index) => {
    const responsavel = { ...state.responsavel }
    const enderecos = responsavel.enderecos
    const aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    responsavel.enderecos = aux
    setState(prevState => ({...prevState,  responsavel }))
  }

  const removeContatoResponsavel = (index) => {
    const responsavel = { ...state.responsavel }
    const contatos = responsavel.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    responsavel.contatos = aux
    setState(prevState => ({...prevState,  responsavel }))
  }

  const removeEmailResponsavel = (index) => {
    const responsavel = { ...state.responsavel }
    const emails = responsavel.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    responsavel.emails = aux
    setState(prevState => ({...prevState,  responsavel }))
  }

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({...prevState, 
        loading: true
      }))

      try {
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/franqueados/${representanteId}`, getToken())

        let enderecos = []
        let emails = []
        let contatos = []

        for (let i = 0; i < data.responsavel.pessoa.enderecos.length; i++) {
          enderecos.push({
            id: data.responsavel.pessoa.enderecos[i].id,
            cep_id: data.responsavel.pessoa.enderecos[i].cep_id,
            rua: data.responsavel.pessoa.enderecos[i].logradouro,
            numero: data.responsavel.pessoa.enderecos[i].numero,
            bairro: data.responsavel.pessoa.enderecos[i].bairro,
            cidade: data.responsavel.pessoa.enderecos[i].cidade,
            estado: data.responsavel.pessoa.enderecos[i].estado,
            cep: formatarCEP(data.responsavel.pessoa.enderecos[i].num_cep),
            principal: data.responsavel.pessoa.enderecos[i].principal,
            tipoEndereco: data.responsavel.pessoa.enderecos[i].tipo,
            index: i + 1
          })
        }

        for (let i = 0; i < data.responsavel.pessoa.telefones.length; i++) {
          contatos.push({
            id: data.responsavel.pessoa.telefones[i].id,
            numero: data.responsavel.pessoa.telefones[i].numero,
            tipoContato: data.responsavel.pessoa.telefones[i].tipo,
            principal: data.responsavel.pessoa.telefones[i].principal,
            index: i + 1
          })
        }

        for (let i = 0; i < data.responsavel.pessoa.emails.length; i++) {
          emails.push({
            id: data.responsavel.pessoa.emails[i].id,
            email: data.responsavel.pessoa.emails[i].email,
            principal: data.responsavel.pessoa.emails[i].principal,
            index: i + 1
          })
        }

        setState(prevState => ({...prevState, 
          responsavel: {
            id: data.frao_id,
            pessoa_id: data.frao_responsavel_id,
            tipoPessoa: data.responsavel.pessoa.tipo,
            unin_pessoa_id: data.unin_id,
            pessoaFisica: {
              id: data.responsavel.id,
              pessoa_id: data.responsavel.pessoa.id,
              nome: data.responsavel.nome,
              cpf: cpf.format(data.responsavel.cpf),
              rg: data.responsavel.rg,
              rg_orgao_emissor: data.responsavel.rg_orgao_emissor,
              rg_uf: data.responsavel.rg_uf,
              rg_data_emissao:data.responsavel.rg_data_emissao ? moment(data.responsavel.rg_data_emissao).format('YYYY-MM-DD') : '',
              dataNascimento: data.responsavel.datanascimento ? moment(data.responsavel.datanascimento).format('YYYY-MM-DD') : ''
            },
            enderecos,
            emails,
            contatos
          },
          loading: false
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      setState(prevState => ({...prevState, 
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { responsavel } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11} xs={12} sm={10}>
                  <h1 className="titulo">Atualização de Responsavel Franqueado</h1>
                </Grid>
                <Grid item md={1} xs={12} sm={2}>
                  {state.loading &&
                    <CircularProgress />
                  }
                </Grid>
              </Grid>
              {state.enableDadosPessoa &&
                <div className="formFranqueado">
                  <FormPessoaFisica validarCpfCnpj={e => validarCpfCnpj(e)} updateField={e => updateFieldPessoaFisica(e)} dados={responsavel.pessoaFisica} dadosComplementaresPF={state.dadosComplementaresPF} metodos_divugacoes={state.metodos_divugacoes.list}/>
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={9}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {state.enableDadosEndereco &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3 className="titulo">Dados de Endereço</h3>
                    </Grid>
                  </Grid>
                  {responsavel.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco removerItem={(index) => removeEnderecoResponsavel(index)} disableEndereco={state.disableEndereco} pegaEndereco={e => pegaEndereco(e, endereco.index)} updateFieldPrincipal={e => updateFieldEnderecoPrincipal(e, endereco.index)} updateField={e => updateFieldEndereco(e, endereco.index)} dados={endereco} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => addNovoEndereco()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {state.enableDadosContato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3 className="titulo">Dados de Contato</h3>
                    </Grid>
                  </Grid>
                  {responsavel.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato formataTelefone={e => formataTelefone(e, contato.index)} removerItem={(index) => removeContatoResponsavel(index)} updateField={e => updateFieldContato(e, contato.index)} dados={contato} updateFieldPrincipal={e => updateFieldContatoPrincipal(e, contato.index)} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => addNovoContato()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {state.enableDadosEmail &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3 className="titulo">Dados de E-mail</h3>
                    </Grid>
                  </Grid>
                  {responsavel.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail removerItem={(index) => removeEmailResponsavel(index)} updateField={e => updateFieldEmail(e, email.index)} dados={email} updateFieldPrincipal={e => updateFieldEmailPrincipal(e, email.index)} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => addNovoEmail()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button disabled={state.loading} fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
              <br />
            </div>
            </Main>
            <Nav/>
            <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default CadastroResp