import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material'
import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import real from './../../services/real'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',
  marginTop: '30px',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação</h5>
            </Grid>
          </Grid>
          {props.tela === 'estorno' &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12} sm={12}>
                  <p style={{marginBottom: '0px'}}>Essa parcela possui as seguintes contas associadas:</p>
                </Grid>
              </Grid>
              {props.parcelas_filhos.map((parcela, key) => {
                return (
                  <Grid key={key} container direction="row" spacing={1} className="mg_top_20">
                    <Grid item md={12} xs={12} sm={12}>
                      <p style={{marginBottom: '0px'}}>{parcela.conta.descricao} - Valor: R$ {real(parcela.valorparcela)}</p>
                    </Grid>
                  </Grid>
                )
              })}
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12} sm={12}>
                  <p style={{marginBottom: '0px'}}>Ao estornar essa conta essas parcelas também serão estornadas, deseja continuar?</p>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          {props.tela === 'movimentacao' && 
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12} sm={12}>
                  <p style={{marginBottom: '0px'}}>Essa movimentação possui as seguintes movimentações associadas:</p>
                </Grid>
              </Grid>
              {props.parcelas_filhos.map((parcela, key) => {
                return (
                  <React.Fragment key={key}>
                    {parcela.valores_pago.map((forma_pgto, index) => {
                      return (
                        <Grid container direction="row" spacing={1} className="mg_top_20">
                          <Grid item md={12} xs={12} sm={12}>
                            <p style={{marginBottom: '0px'}}>
                              {forma_pgto.mov.descricao} - Valor: R$ {real(forma_pgto.mov.valor)}
                            </p>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </React.Fragment>
                )
              })}
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12} sm={12}>
                  <p style={{marginBottom: '0px'}}>Deseja desconciliar todas as movimentações associadas?</p>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.disabledButtons}>Sim</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.disabledButtons}>Não</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
