import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CurrencyInput from './../mask/CurrencyInput'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  const {conta, formasPagamento, contasFinanceira} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Novo Lançamento</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <div className='scroll'>
                <table className="lista sempadding borderTable">
                  <thead>
                    <tr className="titulos acompanha">
                      <th>Parcelas</th>
                      <th>Valor*</th>
                      <th>Desconto</th>
                      <th>Vencimento*</th>
                      <th>Forma de Pagamento*</th>
                      <th>Contas Bancárias / Nº Vouchers</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                      return (
                        <tr key={parcela.index}>
                          <td>
                            <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.num} InputLabelProps={{ shrink: true }} disabled />
                            <span className="separacaoParcelas">/</span>
                            <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) =>props.mudarParcelas(e)} InputLabelProps={{ shrink: true }}/>
                          </td>
                          <td>
                            <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
                          </td>
                          <td>
                            <TextField className="inputValor" variant="outlined" size="small" name="multajuros" value={parcela.multajuros} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} onChange={(e) => props.updateFieldParcela(e, parcela.index)}/>
                          </td>
                          <td>
                            <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="datavencimento" value={parcela.datavencimento} onBlur={(e) => props.verificaDataParcela(e, parcela.index)} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }}/>
                          </td>
                          <td>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="inputFormPagamento"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name="formapagto_id"
                              value={parcela.formapagto_id}
                              onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                              // onBlur={(e) => props.marcaParcelas(e, parcela.index)}
                              InputLabelProps={{ shrink: true }}
                            >
                              <option value="">--Escolha--</option>
                              {formasPagamento.filter(param => {
                                if(parcela.num !== 1){
                                  if(param.id !== 9) return true
                                }else{
                                  return true
                                }
                                return false
                              }).map(forma => {
                                return (
                                  <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                )
                              })}
                            </TextField>
                          </td>
                          <td>
                            {parseInt(parcela.formapagto_id) === 9 &&
                              <div 
                                style={{
                                  cursor: 'pointer',
                                  width: '300px',
                                  border: '1px solid',
                                  borderColor: 'rgba(0, 0, 0, 0.23)',
                                  height: '38px',
                                  borderRadius: '5px',
                                  padding: '10px 10px',
                                  marginLeft: '37px'
                                }} 
                                onClick={() => props.openVouchers()}
                              >
                                <span>{props.vouchersCreditoSelecionadosString}</span>
                              </div>
                            }
                            {parseInt(parcela.formapagto_id) !== 9 &&
                              <TextField
                                id="standard-select-currency"
                                select
                                variant="outlined"
                                className="inputFormPagamento"
                                fullWidth
                                size="small"
                                SelectProps={{
                                  native: true,
                                }}
                                name="contfin_id"
                                value={parcela.contfin_id}
                                onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                // onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                InputLabelProps={{ shrink: true }}
                              >
                                <option value="">--Escolha--</option>
                                {contasFinanceira.filter(param =>  (props.logado.caixa && parseInt(parcela.formapagto_id) === 1 && param.tipo_conta === "CAIXATURNO") || (parseInt(parcela.formapagto_id) !== 1 && param.tipo_conta === parcela.habilita)).map(conta => {
                                  return (
                                    <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                  )
                                })}
                              </TextField>
                            }
                          </td>
                          <td>
                            <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={12} sm={4}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon/>} onClick={e => props.confirmar(false)}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon/>} onClick={e => props.confirmar(true)}>Salvar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
