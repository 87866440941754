import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',
  marginTop: '30px',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  const {dados, disabledButton} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação</h5>
            </Grid>
          </Grid>
          {props.movConciliada && 
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12} sm={12}>
                  <p style={{ marginBottom: 0, marginTop: 0, fontSize: '16px' }}>Movimentações já conciliadas que serão estornadas:</p>
                </Grid>
              </Grid>
              {props.dados.formas_pgto.map(param => {
                if(param.movcontfin_conciliado){
                  return (
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <p style={{ marginBottom: 0, fontSize: '16px' }}> - Forma Pagto.: {param.formapagto_descricao} no valor de {param.movcontfin_valor} Reais</p>
                        <hr/>
                      </Grid>
                    </Grid>
                  )
                }
                return false
              })}
              <br />
            </React.Fragment>
          }
          {!props.movConciliada &&
            <br/>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                className="input"
                label="Motivo do Estorno da Parcela*"
                variant="outlined"
                size="small"
                name="motivo_cancelamento"
                value={dados.motivo_cancelamento}
                onChange={(e) => props.updateData(e)}
                InputLabelProps={{ shrink: true }}
                placeholder="!!!OBRIGATORIO!!!" />
            </Grid>
          </Grid>
          {(props.parcela.conta.nfse_id && props.parcela.conta.nfse.status === 'APROVADO' && props.parcela.conta.numparcela === 1) &&
            <React.Fragment>
              {!props.travarCancelamentoNFSE &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <p>Esta conta possui uma Nota Fiscal de Servico (NFSE) ao cancelar essa conta a NFSE tambem será cancelada, por favor informar o motivo:</p>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} xs={12} sm={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Motivo Cancelamento NFSE"
                        variant="outlined"
                        className="input"
                        fullWidth
                        SelectProps={{
                          native: true,
                        }}
                        name="motivo"
                        value={props.dadosCancelar.motivo}
                        onChangeCapture={(e) => props.updateFieldCancelamentoNFSE(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value="">Selecionar Motivo do Cancelamento</option>
                        {props.motivosCancelamento.map(value => {
                          return (
                            <option value={value.numero}>{value.descricao}</option>
                          )
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {props.travarCancelamentoNFSE &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <p className='avisoConciliacao'><b>Esta conta possui uma Nota Fiscal de Serviço (NFSE) emitida a mais de 24hrs, não será possível cancelar a nota, ao continuar a nota continuará aberta.</b></p>
                    <p className='avisoConciliacao'><b>Para o cancelamento da nota acesse o portal da sua prefeitura.</b></p>
                  </Grid>
                </Grid>
              }
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='avisoConciliacao'><b>Esta operação não poderá ser desfeita. Deseja continuar?</b></p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button 
                fullWidth 
                color="primary" 
                variant="contained" 
                className="btn_salvar" 
                size="small" 
                startIcon={<CheckIcon />} 
                onClick={e => props.confirmar(true)} 
                disabled={disabledButton ? true : (dados.motivo_cancelamento === "" ? true : ((props.parcela.conta.nfse_id && props.parcela.conta.nfse.status === 'APROVADO' && props.parcela.conta.numparcela === 1 && props.dadosCancelar.motivo === '') ? (props.travarCancelamentoNFSE ? false : true) : false))}
              >
                Continuar
              </Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Voltar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
