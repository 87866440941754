import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ClienteExterno.scss'
import { Grid, Button, TextField } from '@mui/material'
import Alert from './../../components/templates/Alert'
import initialState from './initialState/clienteExterno';
import CircularProgress from '@mui/material/CircularProgress';
import CPFInput from './../../components/mask/CPFInput'
import CEPInput from './../../components/mask/CEPInput'
import CNPJInput from './../../components/mask/CNPJInput'
import TelefoneInput from './../../components/mask/TelefoneInput'
import getEndereco from './../../services/api/getEndereco'
import axios from 'axios'
import decrypt from '../../services/decrypt'
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Upload from './../../components/uploadMidia/Upload'
import {filesize} from "filesize";
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const cpf = require('node-cpf-cnpj');

let formatos = [
  {
    type: "text",
    data_type: "character varying"
  },
  {
    type: "date",
    data_type: "date"
  },
  {
    type: "number",
    data_type: "integer"
  },
]

const ClienteExterno = () => {
  const [state, setState] = useState({ ...initialState });
  const { unidadeId } = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleCloseAlerta = () =>{
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldTipo = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))

  }

  const updateFieldFisica = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.fisica[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))

  }

  const updateFieldJuridica = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.juridica[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))

  }

  const updateFieldEndereco = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.endereco[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))
  }

  const buscaEndereco = async(event) => {
    let {cliente} = state

    if(event.target.value !== ''){
      try {
        cliente.endereco = {
          cep_id: '',
          cep: '',
          logradouro: 'Buscando Endereço...',
          complemento: '',
          numero: '',
          bairro: 'Buscando Endereço...',
          cidade: 'Buscando Endereço...',
          estado: 'Buscando Endereço...',
        }
  
        setState((prevState) => ({
          ...prevState,
          cliente
        }))
  
        const resp = await getEndereco(event.target.value)
  
        cliente.endereco = {
          cep_id: resp.id,
          cep: resp.cep,
          logradouro: resp.logradouro,
          complemento: '',
          numero: '',
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao
        }
  
        setState((prevState) => ({
          ...prevState,
          cliente
        }))
        
      } catch (error) {
        cliente.endereco = {
          cep_id: '',
          cep: '',
          logradouro: '',
          complemento: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: '',
        }
  
        setState((prevState) => ({
          ...prevState,
          cliente,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }
  }

  const updateFieldDadosComplementar = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.fisica.dadosComplementar[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))
  }

  const getDadoComplementarUnidade = async (unidade_id) =>{
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPFExterno/list/${unidade_id}`)

      let dadosComplementaresPF = JSON.parse(decrypt(data))

      setState(prevState => ({...prevState,
        dadosComplementaresPF: {
          list: dadosComplementaresPF
        }
      }))
    } catch (error) {
      console.log(error)
      // setState(prevState => ({...prevState,
      //   alerta: {
      //     open: true,
      //     severity: 'error',
      //     message: error.response ? error.response.data.message : 'Erro Interno'
      //   }
      // }))
      return false
    }
  }

  const getConfiguracaoUnidade = async (unidade_id) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidadeExterno/${unidade_id}`)
      
      if(data){
        setState((prevState) => ({
          ...prevState,
          configuracao_unidade: JSON.parse(decrypt(data))
        }))
      }

     
    } catch ({response}) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: response.data.message
        },
      }));
      return
    }
  }

  const handleUpload = (files, name) => {
    let {cliente} = state  

    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    cliente.files[name] = uploadedFiles

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const validarCpfCnpjConjugue = async (event) => {

    let cpf_cnpj = event.target.value
    const cliente = state.cliente

    cpf_cnpj = cpf.format(cpf_cnpj)
  
    if (!cpf.isValid(cpf_cnpj)) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF Invalido! Por favor corrija o campo CPF do Cônjugue'
        }
      }))
      cliente.fisica.dadosComplementar.cpf_conjugue = ''
    }else{
      cliente.fisica.dadosComplementar.cpf_conjugue = cpf.format(cpf_cnpj)
    }

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const validarCpfCnpj = async (event) => {

    let cpf_cnpj = event.target.value
    const cliente = state.cliente

    cpf_cnpj = cpf.format(cpf_cnpj)
  
    if (!cpf.isValid(cpf_cnpj)) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF Invalido! Por favor corrija o campo CPF'
        }
      }))
      cliente.fisica.cpf = ''
    }else{
      cliente.fisica.cpf = cpf.format(cpf_cnpj)
    }

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const consultaMetodosDivugacao = async (unidade_id) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/metodoDivugacaoExterno/${unidade_id}`)
      
      setState(prevState => ({...prevState,
        metodos_divugacoes:{
          list: JSON.parse(decrypt(data))
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const uploadDoc = async (pessoa_id, doc) => {
    try {
      let {cliente} = state

      let files = cliente.files

      let file = files[doc]
      
      if(file.length > 0){
        const data = new FormData();
        data.append("file", file[0].file, file[0].name);

        await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/uploadDocumento/${pessoa_id}/${doc}/${unidadeId}`, data )
        .catch((error) => {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            },
            loadingSalvar: false
          }))
        });
      }
      
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
  }

  const salvar = async () => {
    let {cliente, dadosComplementaresPF} = state
  
    if(cliente.tipoPessoa === "FISICA"){
      if(cliente.fisica.cpf === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o seu CPF!'
          }
        }))
        return false
      }
      if(cliente.fisica.nome === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o seu nome!'
          }
        }))
        return false
      }
      if(cliente.fisica.data_nascimento === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a sua data de nascimento!'
          }
        }))
        return false
      }
      if(cliente.fisica.telefone === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o seu telefone!'
          }
        }))
        return false
      }

      for (const item of dadosComplementaresPF.list) {
        if(item.obrigatorio){
          if(item.column_name === 'doc_rg_frente'){
            if(cliente.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_rg_verso'){
            if(cliente.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_cnh_frente'){
            if(cliente.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_cnh_verso'){
            if(cliente.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_comprovante_renda'){
            if(cliente.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(item.column_name === 'doc_comprovante_endereco'){
            if(cliente.files[item.column_name].length === 0){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }else if(cliente.fisica.dadosComplementar[item.column_name] === '' || !cliente.fisica.dadosComplementar[item.column_name]){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar o(a) ${item.column_comment}`
              }
            }))
            return
          }
        }
      }
    }else{
      if(cliente.juridica.cnpj === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o CNPJ da empresa!'
          }
        }))
        return false
      }
      if(cliente.juridica.razao_social === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a razão social da empresa!'
          }
        }))
        return false
      }
      if(cliente.juridica.nome_fantasia === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o nome fantasia da empresa!'
          }
        }))
        return false
      }
      if(cliente.juridica.data_fundacao === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data de fundação da empresa!'
          }
        }))
        return false
      }
      if(cliente.juridica.telefone === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o telefone da empresa!'
          }
        }))
        return false
      }
    }

    if(cliente.endereco.cep === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o CEP do seu endereço!'
        }
      }))
      return false
    }
    if(cliente.endereco.logradouro === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o seu endereço!'
        }
      }))
      return false
    }
    if(cliente.endereco.numero === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o número do seu endereço!'
        }
      }))
      return false
    }

    try {
      setState(prevState => ({...prevState,
        loadingSalvar: true,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Realizando Cadastro...'
        }
      }))

      let dados = {
        tipoPessoa: cliente.tipoPessoa,
        fisica: cliente.fisica,
        juridica: cliente.juridica,
        endereco: cliente.endereco
      }

      if(cliente.tipoPessoa === "FISICA"){
        delete dados.juridica

        if(dados.fisica.dadosComplementar.rg_data_emissao === '') delete  dados.fisica.dadosComplementar.rg_data_emissao
        if(dados.fisica.dadosComplementar.data_casamento === '') delete  dados.fisica.dadosComplementar.data_casamento
        if(dados.fisica.dadosComplementar.rg_data_emissao_conjugue === '') delete  dados.fisica.dadosComplementar.rg_data_emissao_conjugue
        if(dados.fisica.dadosComplementar.data_nascimento_conjugue === '') delete  dados.fisica.dadosComplementar.data_nascimento_conjugue
        if(dados.fisica.dadosComplementar.sexo === '') delete  dados.fisica.dadosComplementar.sexo
        if(dados.fisica.dadosComplementar.como_conheceu_id === '') delete  dados.fisica.dadosComplementar.como_conheceu_id
        if(dados.fisica.dadosComplementar.quantidade_filhos === '') delete  dados.fisica.dadosComplementar.quantidade_filhos
      }else{
        delete dados.fisica
      }

      const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/cliente/cadastroExterno/${unidadeId}`, dados)

      for (const key in cliente.files) {
        await uploadDoc(data.id, key)
      }
      
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        cadastrado: true,
        loadingSalvar: false
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
  }

  const voltar = () => {
    setState(prevState => ({...prevState,
      cadastrado: false,
      cliente: {
        tipoPessoa: 'FISICA',
        fisica: {
          cpf: '',
          nome: '',
          sobrenome: '',
          telefone: '',
          data_nascimento: '',
          email: '',
          dadosComplementar: {
            sexo: '',
            num_calcado: '',
            receber_novidades: false,
            quantidade_filhos: '',
            profissao: '',
            data_casamento: '',
            rg: '',
            rg_orgao_emissor: '',
            rg_uf: '',
            rg_data_emissao: '',
            como_conheceu_id: '',
            estado_civil: '',
            colegio: '',
            nome_pai: '',
            nome_mae: '',
            cnh_cliente: '',
            nome_conjugue: '',
            cpf_conjugue: '',
            rg_conjugue: '',
            rg_orgao_emissor_conjugue: '',
            rg_data_emissao_conjugue: '',
            data_nascimento_conjugue: '',
            email_conjugue: '',
            telefone_conjugue: '',
          }
        },
        juridica: {
          cnpj: '',
          razao_social: '',
          nome_fantasia: '',
          data_fundacao: '',
          telefone: '',
          email: '',
        },
        endereco:{
          cep_id: '',
          cep: '',
          logradouro: '',
          numero: '',
          complemento: '',
          bairro: '',
          cidade: '',
          estado: ''
        },
        files: {
          doc_rg_frente: [],
          doc_rg_verso: [],
          doc_cnh_frente: [],
          doc_cnh_verso: [],
          doc_comprovante_renda: [],
          doc_comprovante_endereco: [],
        }
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDadoComplementarUnidade(unidadeId)
        await getConfiguracaoUnidade(unidadeId)
        await consultaMetodosDivugacao(unidadeId)

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState((prevState) => ({
        ...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  let {cliente, configuracao_unidade, dadosComplementaresPF, metodos_divugacoes} = state

  console.log(configuracao_unidade.midia)

  return (
    <div className="container-boletos" id="app" style={{minHeight: '100%'}}>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p style={{fontSize: 20}}>CARREGANDO DADOS...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <React.Fragment>
          {!state.cadastrado &&
            <React.Fragment>
              <Grid container direction="row" spacing={1}>
                <Grid item md={2} xs={false} sm={2}>
                  {isDesktop &&
                    <React.Fragment>
                      {configuracao_unidade.midia &&
                        <div style={{position: 'fixed', top: 10, left: 40}}>
                          <img style={{width: 180}} src={configuracao_unidade.midia.caminho} alt="Logo Unidade" />
                        </div>
                      }
                      <div style={{position: 'fixed', bottom: 10, left: 40}}>
                        <img style={{width: 180}} src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                      </div>
                    </React.Fragment>
                  }
                  {isMobile &&
                    <React.Fragment>
                      {configuracao_unidade.midia &&
                        <React.Fragment>
                          {configuracao_unidade.midia.caminho !== '' &&
                            <div>
                              <img style={{width: '40%', marginLeft: '30%'}} src={configuracao_unidade.midia.caminho} alt="Logo Unidade" />
                              <br />
                            </div>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </Grid>
                <Grid item md={10} xs={12} sm={10}>
                  <Grid container direction="row" spacing={1}>
                    <Grid style={{backgroundColor: '#063e11', color: '#FFF', borderRadius: 10}} item md={12} xs={12} sm={12}>
                      <h3 style={{marginTop: -3}} className='titulo'>Pré Cadastro de Clientes:</h3>
                    </Grid>
                  </Grid>
                  <div className='card_dados mg_top_20'>
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <h5>Dados Básicos:</h5>
                      </Grid>
                    </Grid>
                    {cliente.tipoPessoa === 'FISICA' &&
                      <React.Fragment>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          <Grid item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Tipo Pessoa*"
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name="tipoPessoa"
                              value={cliente.tipoPessoa}
                              onChangeCapture={(e) => updateFieldTipo(e)}
                            >
                              <option value="FISICA">
                                Pessoa Física
                              </option>
                              <option value="JURIDICA">
                                Pessoa Júridica
                              </option>
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12} sm={6}>
                            <CPFInput
                              label="CPF*"
                              className="inputCpfCnpj"
                              name="cpf"
                              value={cliente.fisica.cpf}
                              onChange={(e) => updateFieldFisica(e)}
                              onBlur={e => validarCpfCnpj(e)}
                            />
                          </Grid>
                          <Grid item md={3} xs={12} sm={8}>
                            <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={cliente.fisica.nome} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                          <Grid item md={3} xs={12} sm={8}>
                            <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={cliente.fisica.sobrenome} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          <Grid item md={3} xs={12} sm={8}>
                            <TextField type='date' className="input" label="Data de Nascimento*" variant="outlined" size="small" name="data_nascimento" value={cliente.fisica.data_nascimento} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                          <Grid item md={3} xs={12} sm={8}>
                            <TelefoneInput
                              label="Celular*"
                              className="inputCpfCnpj"
                              name="telefone"
                              value={cliente.fisica.telefone}
                              onChange={(e) => updateFieldFisica(e)}
                            />
                          </Grid>
                          <Grid item md={6} xs={12} sm={8}>
                            <TextField className="input" label="E-mail" variant="outlined" size="small" name="email" value={cliente.fisica.email} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                    {cliente.tipoPessoa === 'JURIDICA' &&
                      <React.Fragment>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          <Grid item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Tipo Pessoa*"
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name="tipoPessoa"
                              value={cliente.tipoPessoa}
                              onChangeCapture={(e) => updateFieldTipo(e)}
                            >
                              <option value="FISICA">
                                Pessoa Física
                              </option>
                              <option value="JURIDICA">
                                Pessoa Júridica
                              </option>
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12} sm={6}>
                            <CNPJInput
                              label="CNPJ*"
                              className="inputCpfCnpj"
                              name="cnpj"
                              value={cliente.juridica.cnpj}
                              onChange={(e) => updateFieldJuridica(e)}
                            />
                          </Grid>
                          <Grid item md={3} xs={12} sm={8}>
                            <TextField className="input" label="Razão Social*" variant="outlined" size="small" name="razao_social" value={cliente.juridica.razao_social} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                          <Grid item md={3} xs={12} sm={8}>
                            <TextField className="input" label="Nome Fantasia*" variant="outlined" size="small" name="nome_fantasia" value={cliente.juridica.nome_fantasia} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          <Grid item md={3} xs={12} sm={8}>
                            <TextField type='date' className="input" label="Data de Fundação*" variant="outlined" size="small" name="data_fundacao" value={cliente.juridica.data_fundacao} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                          <Grid item md={3} xs={12} sm={8}>
                            <TelefoneInput
                              label="Celular*"
                              className="inputCpfCnpj"
                              name="telefone"
                              value={cliente.juridica.telefone}
                              onChange={(e) => updateFieldJuridica(e)}
                            />
                          </Grid>
                          <Grid item md={6} xs={12} sm={8}>
                            <TextField className="input" label="E-mail" variant="outlined" size="small" name="email" value={cliente.juridica.email} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  </div>
                  {(cliente.tipoPessoa === 'FISICA' && dadosComplementaresPF.list.length > 0) &&
                    <div className='card_dados mg_top_20'>
                      <Grid container direction="row" spacing={1}>
                        <Grid item md={12} xs={12} sm={12}>
                          <h5>Dados Complementares:</h5>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        {dadosComplementaresPF.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                          console.log(value)
                          if(value.column_name === 'estado_civil'){
                            return (
                              <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                >
                                  <option value="">Selecionar Estado Civil</option>
                                  <option value="CASADO">Casado(a)</option>
                                  <option value="SOLTEIRO">Solteiro(a)</option>
                                  <option value="VIUVO">Viuvo(a)</option>
                                  <option value="SEPARADO">Separado(a)</option>
                                </TextField>
                              </Grid>
                            )
                          }else if(value.column_name === 'sexo'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                >
                                  <option value="">Selecionar Sexo</option>
                                  <option value="MASCULINO">Masculino</option>
                                  <option value="FEMININO">Feminino</option>
                                </TextField>
                              </Grid>
                            )
                          }else if(value.column_name === 'rg_uf'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                >
                                  <option value="">Selecionar Estado</option>
                                  <option value="AC">Acre</option>
                                  <option value="AL">Alagoas</option>
                                  <option value="AP">Amapá</option>
                                  <option value="AM">Amazonas</option>
                                  <option value="BA">Bahia</option>
                                  <option value="CE">Ceará</option>
                                  <option value="DF">Distrito Federal</option>
                                  <option value="ES">Espírito Santo</option>
                                  <option value="GO">Goiás</option>
                                  <option value="MA">Maranhão</option>
                                  <option value="MT">Mato Grosso</option>
                                  <option value="MS">Mato Grosso do Sul</option>
                                  <option value="MG">Minas Gerais</option>
                                  <option value="PA">Pará</option>
                                  <option value="PB">Paraíba</option>
                                  <option value="PR">Paraná</option>
                                  <option value="PE">Pernambuco</option>
                                  <option value="PI">Piauí</option>
                                  <option value="RJ">Rio de Janeiro</option>
                                  <option value="RN">Rio Grande do Norte</option>
                                  <option value="RS">Rio Grande do Sul</option>
                                  <option value="RO">Rondônia</option>
                                  <option value="RR">Roraima</option>
                                  <option value="SC">Santa Catarina</option>
                                  <option value="SP">São Paulo</option>
                                  <option value="SE">Sergipe</option>
                                  <option value="TO">Tocantins</option>
                                </TextField>
                              </Grid>
                            )
                          }else if(value.column_name === 'rg_orgao_emissor'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                >
                                  <option value="">Selecionar Orgão Emissor</option>
                                  <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                                  <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                                  <option value="PC - Policia Civil">PC - Policia Civil</option>
                                  <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                                  <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                                  <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                                  <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                                  <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                                  <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                                  <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                                  <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                                  <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                                  <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                                  <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                                  <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                                  <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                                  <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                                  <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                                  <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                                  <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                                  <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                                  <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                                  <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                                </TextField>
                              </Grid>
                            )
                          }else if(value.column_name === 'rg_orgao_emissor_conjugue'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                >
                                  <option value="">Selecionar Orgão Emissor</option>
                                  <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                                  <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                                  <option value="PC - Policia Civil">PC - Policia Civil</option>
                                  <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                                  <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                                  <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                                  <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                                  <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                                  <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                                  <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                                  <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                                  <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                                  <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                                  <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                                  <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                                  <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                                  <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                                  <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                                  <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                                  <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                                  <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                                  <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                                  <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                                </TextField>
                              </Grid>
                            )
                          }else if(value.column_name === 'receber_novidades'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                >
                                  <option value="SIM">Sim</option>
                                  <option value="NAO">Não</option>
                                </TextField>
                              </Grid>
                            )
                          }else if(value.column_name === 'como_conheceu_id'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                >
                                  <option value={value.id}>Selecionar Como Conheceu?</option>
                                {state.metodos_divugacoes.list.map(value => {
                                  return (
                                    <option key={value.id} value={value.id}>{value.descricao}</option>
                                  )
                                })}
                                </TextField>
                              </Grid>
                            )
                          }else if(value.column_name === 'telefone_conjugue'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <TelefoneInput
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  className="inputCpfCnpj"
                                  name={value.column_name} 
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChange={(e) => updateFieldDadosComplementar(e)}
                                />
                              </Grid>
                            )
                          }else if(value.column_name === 'cpf_conjugue'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <CPFInput
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  className="inputCpfCnpj"
                                  name={value.column_name}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  onChange={(e) => updateFieldDadosComplementar(e)}
                                  onBlur={e => validarCpfCnpjConjugue(e)}
                                />
                              </Grid>
                            )
                          }else if(value.column_name === 'doc_rg_frente'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <span style={{color: "#000", fontWeight: 400}}>RG Frente: (Limite 2MB)</span>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_rg_frente')} />
                                <span>{cliente.files['doc_rg_frente'][0] ? cliente.files['doc_rg_frente'][0].name : ''}</span>
                              </Grid>
                            )
                          }else if(value.column_name !== 'doc_rg_frente' &&
                            value.column_name !== 'doc_rg_verso' &&
                            value.column_name !== 'doc_cnh_frente' &&
                            value.column_name !== 'doc_cnh_verso' &&
                            value.column_name !== 'doc_comprovante_renda' &&
                            value.column_name !== 'doc_comprovante_endereco'){
                            return (
                              <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  className="input"
                                  type={formatos.filter(param => param.data_type === value.data_type)[0] ? formatos.filter(param => param.data_type === value.data_type)[0].type : 'text'}
                                  name={value.column_name}
                                  label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                                  value={cliente.fisica.dadosComplementar[value.column_name]}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => updateFieldDadosComplementar(e)}
                                  InputLabelProps={{shrink: true}} 
                                />
                              </Grid>
                            )
                          }
                        })}
                      </Grid>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        {dadosComplementaresPF.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                          if(value.column_name === 'doc_rg_frente'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <span style={{color: "#000", fontWeight: 400}}>RG Frente: (Limite 2MB)</span>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_rg_frente')} />
                                <span>{cliente.files['doc_rg_frente'][0] ? cliente.files['doc_rg_frente'][0].name : ''}</span>
                              </Grid>
                            )
                          }else if(value.column_name === 'doc_rg_verso'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <span style={{color: "#000", fontWeight: 400}}>RG Verso: (Limite 2MB)</span>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_rg_verso')} />
                                <span>{cliente.files['doc_rg_verso'][0] ? cliente.files['doc_rg_verso'][0].name : ''}</span>
                              </Grid>
                            )
                          }else if(value.column_name === 'doc_cnh_frente'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <span style={{color: "#000", fontWeight: 400}}>CNH Frente: (Limite 2MB)</span>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_frente')} />
                                <span>{cliente.files['doc_cnh_frente'][0] ? cliente.files['doc_cnh_frente'][0].name : ''}</span>
                              </Grid>
                            )
                          }else if(value.column_name === 'doc_cnh_verso'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <span style={{color: "#000", fontWeight: 400}}>CNH Verso: (Limite 2MB)</span>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_verso')} />
                                <span>{cliente.files['doc_cnh_verso'][0] ? cliente.files['doc_cnh_verso'][0].name : ''}</span>
                              </Grid>
                            )
                          }else if(value.column_name === 'doc_comprovante_renda'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <span style={{color: "#000", fontWeight: 400}}>Comp. de Renda: (Limite 2MB)</span>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_renda')} />
                                <span>{cliente.files['doc_comprovante_renda'][0] ? cliente.files['doc_comprovante_renda'][0].name : ''}</span>
                              </Grid>
                            )
                          }else if(value.column_name === 'doc_comprovante_endereco'){
                            return (
                              <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                                <span style={{color: "#000", fontWeight: 400}}>Comp. de Endereço: (Limite 2MB)</span>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_endereco')} />
                                <span>{cliente.files['doc_comprovante_endereco'][0] ? cliente.files['doc_comprovante_endereco'][0].name : ''}</span>
                              </Grid>
                            )
                          }
                        })}
                      </Grid>
                    </div>
                  }
                  <div className='card_dados mg_top_20'>
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <h5>Endereço:</h5>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={2} xs={12} sm={8}>
                        <CEPInput
                          label="CEP*"
                          className="inputCpfCnpj"
                          name="cep"
                          value={cliente.endereco.cep}
                          onChange={(e) => updateFieldEndereco(e)}
                          onBlur={(e) => buscaEndereco(e)}
                        />
                      </Grid>
                      <Grid item md={6} xs={12} sm={8}>
                        <TextField className="input" label="Endereço*" variant="outlined" size="small" name="logradouro" value={cliente.endereco.logradouro} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={2} xs={12} sm={8}>
                        <TextField className="input" label="Número*" variant="outlined" size="small" name="numero" value={cliente.endereco.numero} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={2} xs={12} sm={8}>
                        <TextField className="input" label="Complemento" variant="outlined" size="small" name="complemento" value={cliente.endereco.complemento} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={4} xs={12} sm={8}>
                        <TextField className="input" label="Bairro*" variant="outlined" size="small" name="bairro" value={cliente.endereco.bairro} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={4} xs={12} sm={8}>
                        <TextField className="input" label="Cidade*" variant="outlined" size="small" name="cidade" value={cliente.endereco.cidade} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={4} xs={12} sm={8}>
                        <TextField className="input" label="Estado*" variant="outlined" size="small" name="estado" value={cliente.endereco.estado} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              {isMobile &&
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12} className='mg_top_20'>
                    <div>
                      <img style={{width: '50%', marginLeft: '25%'}} src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro"/>
                    </div>
                  </Grid>
                </Grid>
              }
              <br />
              <br />
              <div style={{position: 'fixed', width: 160, right: 10, bottom: 10}}>
                <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => salvar()} disabled={state.loadingSalvar}>Salvar</Button>
              </div>
            </React.Fragment>
          }
          {state.cadastrado &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} style={{height: '100vh'}}>
                {isDesktop &&
                  <React.Fragment>
                    <Grid item md={2} xs={12} sm={2}>
                      <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon/>} onClick={e => voltar()} disabled={state.loadingSalvar}>Voltar</Button>
                    </Grid>
                    <Grid item md={1} xs={12} sm={2}></Grid>
                  </React.Fragment>
                }
                <Grid item md={6} xs={12} sm={8}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} xs={12} sm={12}>
                      <p style={{fontSize: 20}}>Seu cadastro foi realizado com sucesso! Agora, ele passará por uma análise para aprovação. A empresa entrará em contato em breve para finalizar o processo e fornecer as próximas orientações. Agradecemos por escolher nossos serviços!</p>
                    </Grid>
                    {isDesktop &&
                      <React.Fragment>
                        <Grid item md={3} xs={12} sm={6}>
                          {configuracao_unidade.midia &&
                            <img style={{width: '100%'}} src={`${window._env_.REACT_APP_API_URL}/static/${configuracao_unidade.midia.key}`} alt="Logo Unidade" />
                          }
                        </Grid>
                        <Grid item md={6} xs={12} sm={6}></Grid>
                        <Grid item md={3} xs={12} sm={6}>
                          <img style={{width: '100%'}} src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                        </Grid>
                      </React.Fragment>
                    }
                    {isMobile &&
                      <React.Fragment>
                        <Grid item xs={6} style={{marginTop: '30px'}}>
                          {configuracao_unidade.midia &&
                            <img style={{width: '80%', marginLeft: '10%'}} src={`${window._env_.REACT_APP_API_URL}/static/${configuracao_unidade.midia.key}`} alt="Logo Unidade" />
                          }
                        </Grid>
                        <Grid item xs={6} style={{marginTop: '30px'}}>
                          <img style={{width: '80%', marginLeft: '10%'}} src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8} style={{marginTop: '30px'}}>
                          <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon/>} onClick={e => voltar()} disabled={state.loadingSalvar}>Voltar</Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </React.Fragment>
                    }
                  </Grid>
                </Grid>
                {isDesktop &&
                  <Grid item md={3} xs={12} sm={2}></Grid>
                }
              </Grid>
            </React.Fragment>
          }
        </React.Fragment>
      }

      <Alert
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()}
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
    </div>
  )
}

export default ClienteExterno