import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import real from '../../services/real';
import CurrencyInput from './../mask/CurrencyInput'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '88%',
  marginLeft: '6%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const {mov_selecionadas_extrato, mov_selecionadas_sistema} = props

  let valor_extrato = somarValores(mov_selecionadas_extrato.map(value => parseFloat(value.valor.replaceAll(".", "").replace(",", "."))))
  let valor_sistema = somarValores(mov_selecionadas_sistema.map(value => value.tipo === 'DEBIT' ? parseFloat(value.valor.replaceAll(".", "").replace(",", ".")) * -1 : parseFloat(value.valor.replaceAll(".", "").replace(",", "."))))

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Ajustar Valores Conciliação</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <span>Os valores selecionados não confere! Por favor ajustar os valores dos lançamentos.</span>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={4}>
              <h6 className='titulo'>Movimentações Selecionadas:</h6>
            </Grid>
            <Grid item md={4} xs={12} sm={4} style={{textAlign: 'right'}}>
              <h6 className='titulo'>Total Extrato: R$ {real(valor_extrato)}</h6>
            </Grid>
            <Grid item md={4} xs={12} sm={4} style={{textAlign: 'right'}}>
              <h6 className='titulo'>Total Sistema: R$ {real(valor_sistema)}</h6>
            </Grid>
          </Grid>
          {mov_selecionadas_sistema.map((value, key) => {
            return (
              <React.Fragment key={key}>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={6} xs={12} sm={4}>
                    <TextField
                      className="input" 
                      label="Descrição" 
                      variant="outlined" 
                      size="small" 
                      name="descricao"
                      value={value.descricao} 
                      disabled
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <TextField
                      className="input" 
                      label="Data Sistema" 
                      variant="outlined" 
                      size="small" 
                      name="data"
                      value={moment(value.data).format('DD/MM/YYYY')} 
                      disabled
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <TextField
                      className="input" 
                      label="Tipo" 
                      variant="outlined" 
                      size="small" 
                      name="tipo"
                      value={value.tipo === 'DEBIT' ? 'Despesa': 'Receita'} 
                      disabled
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <TextField
                      className="input" 
                      label="Valor" 
                      variant="outlined" 
                      size="small" 
                      name="valor"
                      value={value.valor}
                      InputProps={{ inputComponent: CurrencyInput }}
                      onChange={(e) => props.updateField(e, value.key)}
                    />
                  </Grid>
                </Grid>
                <hr className="mg_top_10"/>
              </React.Fragment>
            )
          })}
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.disabledButtons}>Voltar</Button>
            </Grid>
            <Grid item md={6} xs={false} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button 
                fullWidth 
                color="primary" 
                variant="contained" 
                className="btn_salvar" 
                size="small" 
                startIcon={<CheckIcon />} 
                onClick={e => props.confirmar(true)} 
                disabled={props.disabledButtons ? true : (valor_extrato !== valor_sistema ? true: false)}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
