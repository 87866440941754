import React from 'react'
import MinimizeIcon from '@mui/icons-material/Minimize';
import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';

const checkPermissao = (perfil, tela, propriedade) => {
  for (const permissao of perfil.permissoes) {
    if (permissao.tela_id === tela.id) return permissao[propriedade]
  }

  return false
}

const listaVisibilidade = () => {
  const pessoa = JSON.parse(localStorage.getItem('pessoa'))
  const tipoUnidade = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

  if (tipoUnidade === "MANTENEDOR") {
    return (
      <React.Fragment>
        <option value="UNIDADE"> Unidade </option>
        <option value="GRUPOECONOMICO"> Grupo Econômico </option>
        <option value="REPRESENTANTE"> Representante </option>
        <option value="MANTENEDOR"> Mantenedor </option>
      </React.Fragment>
    )
  }if (tipoUnidade === "REPRESENTANTE") {
    return (
      <React.Fragment>
        <option value="UNIDADE"> Unidade </option>
        <option value="GRUPOECONOMICO"> Grupo Econômico </option>
        <option value="REPRESENTANTE"> Representante </option>
      </React.Fragment>
    )
  } else if (tipoUnidade === "GRUPOECONOMICO") {
    return (
      <React.Fragment>
        <option value="UNIDADE"> Unidade </option>
        <option value="GRUPOECONOMICO"> Grupo Econômico </option>
      </React.Fragment>
    )
  } else if (tipoUnidade === "UNIDADE") {
    return (
      <React.Fragment>
        <option value="UNIDADE"> Unidade </option>
      </React.Fragment>
    )
  }
}

const FormPerfil = ({ dados, telas, updateField, updateFieldAtivo, updateFieldSwitch, update, tipoUnidade, 
  permissoesAtual, grupos, minimizar, maximizar }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField
          className="input"
          label="Descrição *"
          variant="outlined"
          size="small"
          name="descricao"
          value={dados.descricao} onChange={(e) => updateField(e)}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Para qual usuario? *"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="visibilidade"
          value={dados.visibilidade}
          onChangeCapture={(e) => updateField(e)}
          disabled={update}
        >
          {listaVisibilidade()}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={<Switch
            name="Ativo"
            color="primary"
            checked={dados.status}
          />}
          label="Ativo"
          name="status"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12}>
        <h3 className="titulo">Permissões</h3>
      </Grid>
    </Grid>

    <Grid container direction="row">
      <Grid item md={12} className="permissoes">
        <div className="mg_top_20 linhas">
          <Grid container item md={12}>
            <Grid item md={6} xs={6} sm={6}><h5 className="titulo">Modulo</h5></Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Grid container item md={12}>
                <Grid item md={3} xs={3} sm={3}><h5 className="titulo">Visualizar</h5></Grid>
                <Grid item md={3} xs={3} sm={3}><h5 className="titulo">Inserir</h5></Grid>
                <Grid item md={3} xs={3} sm={3}><h5 className="titulo">Alterar</h5></Grid>
                <Grid item md={3} xs={3} sm={3}><h5 className="titulo">Deletar</h5></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className='lista_permissoes'>
            {grupos.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map((grupo, key) => {
              return (
                <Grid container direction="row">
                  <Grid container item md={12}>
                    <div key={key} className={!grupo.min ? 'card_grupo open' : 'card_grupo closeCard'}>
                      <Grid container direction="row">
                        <Grid container item md={12}>
                          <h6 className='titulo'>{grupo.descricao}</h6>
                            {grupo.min && 
                              <Tooltip title='Maximizar' aria-label="add">
                                <IconButton style={{top: '-5px'}} className='button' color="primary" aria-label="view" onClick={() => maximizar(grupo)}>
                                  <CropLandscapeIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {!grupo.min && 
                              <Tooltip title='Minimizar' aria-label="add">
                                <IconButton style={{top: '-15px'}} className='button' color="primary" aria-label="view" onClick={() => minimizar(grupo)}>
                                  <MinimizeIcon />
                                </IconButton>
                              </Tooltip>
                            }
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid container item md={12}>
                          {tipoUnidade === "MANTENEDOR" &&
                            <React.Fragment>
                              {
                                telas.filter(param => param.modulo.grmod_id === grupo.id).map((tela, key) => {
                                  return <Grid container item md={12} key={key} className={key % 2 === 0 ? 'row-grey linhas' : 'linhas'}>
                                    <Grid item md={6} xs={6} sm={6} className="descricao">
                                      <p>{tela.modulo.descricao} - {tela.descricao}</p>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                      <Grid container item md={12}>
                                        <Grid item md={3} xs={3} sm={3}>
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="visualizar"
                                              color="primary"
                                              checked={checkPermissao(dados, tela, 'visualizar')}
                                            />}
                                            name="visualizar"
                                            onChange={(e) => updateFieldSwitch(tela, e)}
                                          />
                                        </Grid>
                                        <Grid item md={3} xs={3} sm={3}>
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="inserir"
                                              color="primary"
                                              checked={checkPermissao(dados, tela, 'inserir')}
                                            />}
                                            name="inserir"
                                            onChange={(e) => updateFieldSwitch(tela, e)}
                                          />
                                        </Grid>
                                        <Grid item md={3} xs={3} sm={3}>
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="alterar"
                                              color="primary"
                                              checked={checkPermissao(dados, tela, 'alterar')}
                                            />}
                                            name="alterar"
                                            onChange={(e) => updateFieldSwitch(tela, e)}
                                          />
                                        </Grid>
                                        <Grid item md={3} xs={3} sm={3}>
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="deletar"
                                              color="primary"
                                              checked={checkPermissao(dados, tela, 'deletar')}
                                            />}
                                            name="deletar"
                                            onChange={(e) => updateFieldSwitch(tela, e)}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                })
                              }
                            </React.Fragment>
                          }
                          {tipoUnidade !== "MANTENEDOR" &&
                            <React.Fragment>
                              {permissoesAtual.filter(param => param.tela.modulo.grmod_id === grupo.id).map((permi, key) => {
                                return <Grid container item md={12} key={permi.id} className={key % 2 === 0 ? 'row-grey linhas' : 'linhas'}>
                                  <Grid item md={6} xs={6} sm={6} className="descricao"><p>{permi.tela.modulo.descricao} - {permi.tela.descricao}</p></Grid>
                                  <Grid item md={6} xs={6} sm={6}>
                                    <Grid container item md={12}>
                                      <Grid item md={3} xs={3} sm={3}>
                                        {permi.visualizar &&
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="visualizar"
                                              color="primary"
                                              checked={checkPermissao(dados, permi.tela, 'visualizar')}
                                            />}
                                            name="visualizar"
                                            onChange={(e) => updateFieldSwitch(permi.tela, e)}
                                          />
                                        }
                                      </Grid>
                                      <Grid item md={3} xs={3} sm={3}>
                                        {permi.inserir &&
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="inserir"
                                              color="primary"
                                              checked={checkPermissao(dados, permi.tela, 'inserir')}
                                            />}
                                            name="inserir"
                                            onChange={(e) => updateFieldSwitch(permi.tela, e)}
                                          />
                                        }
                                      </Grid>
                                      <Grid item md={3} xs={3} sm={3}>
                                        {permi.alterar &&
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="alterar"
                                              color="primary"
                                              checked={checkPermissao(dados, permi.tela, 'alterar')}
                                            />}
                                            name="alterar"
                                            onChange={(e) => updateFieldSwitch(permi.tela, e)}
                                          />
                                        }
                                      </Grid>
                                      <Grid item md={3} xs={3} sm={3}>
                                        {permi.deletar &&
                                          <FormControlLabel
                                            control={<Switch
                                              className="switch"
                                              name="deletar"
                                              color="primary"
                                              checked={checkPermissao(dados, permi.tela, 'deletar')}
                                            />}
                                            name="deletar"
                                            onChange={(e) => updateFieldSwitch(permi.tela, e)}
                                          />
                                        }
              
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              })}
                            </React.Fragment>
                          }
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              )
            })}
          </div>          
        </div>
      </Grid>

    </Grid>

  </React.Fragment>
)

export default FormPerfil
