import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './../Cliente.scss'
import './../../../main/ultil.scss'
import { Grid,  Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import Table from '../../../components/Table'
import Alert from './../../../components/templates/Alert'
import real from './../../../services/real'
import moeda from './../../../services/moeda'
import ModalContrato from './../../../components/modals/ContratoUnidade'
import ModalCancelarContratoUnidade from './../../../components/modals/CancelarContratoUnidade'
import ModalNovoContrato from './../../../components/modals/NovoContratoUnidade'
import initialState from './../initialState/contratoUnidade';
import getToken from './../../../services/getToken'

const cpf = require('node-cpf-cnpj');

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const Contrato = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/cliente");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleCloseModalNovoContrato = () => {
    setState(prevState => ({...prevState,
      openModalNovoContrato: false,
      disabledButton: false
    }))
  }

  const updateFieldMinServico = () => {
    const servico = { ...state.servico }
    servico.habilita_valor_min = !servico.habilita_valor_min
    setState(prevState => ({...prevState, servico }))
  }

  const updateFieldMaxServico = () => {
    const servico = { ...state.servico }
    servico.habilita_valor_max = !servico.habilita_valor_max
    setState(prevState => ({...prevState, servico }))
  }

  const verificaUnidadeTempo = (value) => {
    if(value === 'DIA'){
      return 'day'
    }else if (value === 'MÊS'){
      return 'month'
    }else if (value === 'ANO'){
      return 'year'
    }
  }

  const updateFieldContrato = (event) => {
    const { dadosContrato } = state

    const name = event.target.name
    const value = event.target.value

    dadosContrato[name] = value

    if((name === 'data_ini_vig' || name === 'unidade_tempo' || name === 'tempo_contrato') && dadosContrato.data_ini_vig !== '' && dadosContrato.unidade_tempo !== '' && dadosContrato.tempo_contrato !== ''){
      dadosContrato.data_fim_vig = moment(dadosContrato.data_ini_vig).add(dadosContrato.tempo_contrato, verificaUnidadeTempo(dadosContrato.unidade_tempo)).subtract(1, 'day').format('YYYY-MM-DD')   
    }

    setState(prevState => ({...prevState, dadosContrato }))
  }

  const updateFieldServico = (event) => {
    const {servico, produtos} = state

    let value = event.target.value
    let name = event.target.name

    if(name === "prod_id"){
      servico.prod_descricao = produtos.list.filter(param => param._id === parseInt(value))[0].descricao
    }

    servico[name] = value
    setState(prevState => ({...prevState, servico }))
  }

  const updateFieldAtivoServico = () => {
    const servico = { ...state.servico }
    servico.ativo = !servico.ativo
    setState(prevState => ({...prevState, servico }))
  }

  const updateFieldInativaUnidadeServico = () => {
    const servico = { ...state.servico }
    servico.inativa_unidade = !servico.inativa_unidade
    setState(prevState => ({...prevState, servico }))
  }

  const handleCloseModalContrato = () => {
    setState(prevState => ({...prevState,
      openModalContrato: false
    }))
  }

  const updateFieldGerarNfse = () => {
    let servico = state.servico

    servico.gerar_nfse = !servico.gerar_nfse
    
    setState(prevState => ({...prevState,
      servico
    }))
  }

  const updateFieldAssinar = () => {
    const { dadosContrato } = state
    dadosContrato.assinar_contrato = !dadosContrato.assinar_contrato
    setState(prevState => ({...prevState, dadosContrato }))
  }
  
  const validateServico = () => {
    const {servico} = state

    if(servico.prod_id === '') return false
    if(servico.dia_vencimento === '') return false
    if(servico.parametro === '') return false
    if(servico.valor === '') return false

    if(servico.habilita_valor_min){
      if(servico.valor_min === '') return false
    }

    if(servico.habilita_valor_max){
      if(servico.valor_max === '') return false
    }

    return true
  }

  const addItem = () => {
    const {servico, dadosContrato} = state

    if (!validateServico()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    if(parseFloat(servico.valor_min.replaceAll(".", "").replace(",", ".")) > parseFloat(servico.valor_max.replaceAll(".", "").replace(",", "."))){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:"O valor mínimo não pode ser superior ao valor máximo!"
        }
      }))
      return
    }

    if(servico.index === ""){
      dadosContrato.produtos.push({
        ...servico,
        index: dadosContrato.produtos.length
      })
    }else{
      dadosContrato.produtos[servico.index] = servico
    }

    setState(prevState => ({...prevState,
      dadosContrato,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        dia_vencimento: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        valor_min: real(0),
        valor_max: real(0),
        inativa_unidade: true,
        gerar_nfse: false,
        index: ""
      },
      update: false
    }))
  }

  const removerItem = (index) => {
    const {dadosContrato} = state
    
    let itens = dadosContrato.produtos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {

      aux.push({
        prod_id: itens[i].prod_id,
        prod_descricao: itens[i].prod_descricao,
        id: itens[i].id,
        dia_vencimento: itens[i].dia_vencimento,
        parametro: itens[i].parametro,
        valor: itens[i].valor,
        ativo: itens[i].ativo,
        habilita_valor_min: itens[i].habilita_valor_min,
        habilita_valor_max: itens[i].habilita_valor_max,
        inativa_unidade: itens[i].inativa_unidade,
        valor_min: itens[i].valor_min,
        valor_max: itens[i].valor_max,
        index: i
      })
    }

    itens = aux

    dadosContrato.produtos = itens

    setState(prevState => ({...prevState,
      dadosContrato
    }))
  }

  const validateForm = () => {
    let {dadosContrato} = state

    if(dadosContrato.contfin_id === "") return 
    if(dadosContrato.data_fim_vig === "") return false
    if(dadosContrato.data_ini_vig === "") return false
    if(dadosContrato.formpag_id === "") return false
    if(dadosContrato.tempo_contrato === "") return false
    if(dadosContrato.unidade_tempo === "") return false
    if(dadosContrato.unidade_id === "") return false
    if(dadosContrato.dias_pagamento === "") return false
    if(dadosContrato.juros === "") return false
    
    return true
  }

  const confirmarNovoContrato = async (value) => {
    if(value){
      let {dadosContrato} = state
  
      if (!validateForm()) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:"Preencha os campos obrigatorios (*)."
          }
        }))
        return
      }
  
      if(dadosContrato.produtos.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:"Por favor informar ao menos um serviço."
          }
        }))
        return
      }
  
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Cadastrando Contrato...'
        }
      }))

      try {
        let dados = {
          codigo: dadosContrato.codigo,
          contfin_id: parseInt(dadosContrato.contfin_id),
          data_fim_vig: dadosContrato.data_fim_vig,
          data_ini_vig: dadosContrato.data_ini_vig,
          formpag_id: parseInt(dadosContrato.formpag_id),
          tempo_contrato: parseInt(dadosContrato.tempo_contrato),
          unidade_id:  parseInt(dadosContrato.unidade_id),
          unidade_tempo: dadosContrato.unidade_tempo,
          dias_pagamento: parseInt(dadosContrato.dias_pagamento),
          juros: parseInt(dadosContrato.juros),
          observacao: dadosContrato.observacao,
          servicos: dadosContrato.produtos.map(servico => {
            return {
              id: servico.id,
              prod_id: parseInt(servico.prod_id),
              dia_vencimento: parseInt(servico.dia_vencimento),
              parametro: servico.parametro,
              ativo: servico.ativo,
              valor: parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")),
              habilita_valor_min: servico.habilita_valor_min,
              habilita_valor_max: servico.habilita_valor_max,
              inativa_unidade: servico.inativa_unidade,
              valor_min: parseFloat(servico.valor_min.replaceAll(".", "").replace(",", ".")),
              valor_max: parseFloat(servico.valor_max.replaceAll(".", "").replace(",", ".")),
              gerar_nfse: servico.gerar_nfse
            }
          })
        }
  
        setState(prevState => ({...prevState,
          disabledButton: true,
        }))
  
        await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/novoContrato`, dados, getToken())
        
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Contrato cadastrado com sucesso'
          },
          loading:true
        }))
  
        // UNSAFE_componentWillMount()
        handleCloseModalNovoContrato()
        handleCloseModalContrato()
        window.location.reload()

        return 
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabledButton: false
        }))
      }
    }else{
      handleCloseModalNovoContrato()
    }
  }

  const viewContrato = async (value) => {
    setState(prevState => ({...prevState,
      dadosContratoView: {
        id: value.id,
        codigo: value.codigo,
        data_ini_vig_unform: value.data_ini_vig,
        data_fim_vig_unform: value.data_fim_vig,
        data_ini_vig: moment(value.data_ini_vig).format('DD/MM/YYYY'),
        data_fim_vig: moment(value.data_fim_vig).format('DD/MM/YYYY'),
        unidade_tempo: value.unidade_tempo,
        tempo_contrato: value.tempo_contrato,
        unidade_id: value.unidade_id,
        dias_pagamento: value.dias_pagamento,
        juros: value.juros,
        observacao: '',
        situacao: value.situacao_atual,
        forma_pagto: value.forma_pagamento.descricao,
        conta_fin: value.conta_financeira.descricao,
        formpag_id: value.forma_pagamento.id,
        contfin_id: value.conta_financeira.id,
        servicos: value.servicos,
        produtos: value.servicos.map(servico => {
          return {
            prod_descricao: servico.produto.descricao,
            id: servico.id,
            dia_vencimento: servico.dia_vencimento,
            parametro: servico.parametro,
            valor: real(servico.valor),
            ativo: servico.ativo,
            habilita_valor_min: servico.habilita_valor_min,
            habilita_valor_max: servico.habilita_valor_max,
            inativa_unidade: servico.inativa_unidade,
            valor_min: real(servico.valor_min),
            valor_max: real(servico.valor_max),
            pendencias: servico.pendencias
          }
        }),
      },
      openModalContrato: true
    }))
  }

  const consultaProdutos = async () => {
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, getToken())

      const produtos = []

      resp.data.forEach(produto => {
        if(produto.ativo && produto.tipo === 'SERVICO'){
          produtos.push({
            _id: produto.id,
            descricao: produto.descricao,
            ativo: produto.ativo,
            index: ""
          })
        }
      })

      setState(prevState => ({...prevState,
        produtos: {
          list: produtos
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarFormaPagamento = async () => {
    try {
      const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())
  
        setState(prevState => ({...prevState,
          formasPagamento: {
            list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
          }
        }))
  
        const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/1`, getToken())
        
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira
          }
        }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const novoContrato = async () => {
    
    let unidades = props.cliente.unidades.filter(param => param.tipo === 'UNIDADE')
    
    await consultarFormaPagamento()

    let dadosContrato ={
      codigo: '',
      data_ini_vig: '',
      data_fim_vig: '',
      unidade_tempo: '',
      tempo_contrato: '',
      unidade_id: '',
      produtos: [],
      formpag_id: '',
      contfin_id: '',
      dias_pagamento: '',
      juros: '',
      observacao: ''
    }

    if(unidades.length === 1){
      dadosContrato.unidade_id = unidades[0].id
    }

    await consultaProdutos()

    setState(prevState => ({...prevState,
      dadosContrato,
      openModalNovoContrato: true,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        dia_vencimento: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        inativa_unidade: true,
        valor_min: real(0),
        valor_max: real(0),
        gerar_nfse: false,
        index: ""
      },
      unidades:{
        list: unidades.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(unidade => {
          return {
            id: unidade.id,
            descricao: unidade.descricao.toUpperCase(),
            status: unidade.status
          }
        })
      }
    }))
  }
  
  const consultaPlanoContas = async () => {
    try {
  
      const dados = {
        unidadesnegocio: [1],
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados,getToken())
             
      setState(prevState => ({...prevState,
        itens_receita_despesa: {
          list: data.filter(param => param.ativo && param.plano_contas.tipo === 'RECEITA').map(item => {
            return {
              id: item.id,
              descricao: item.descricao,
              planocontas_id: item.planocontas_id,
              plano_contas: item.plano_contas
            }
          })
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const filtrarContratos = async () => {
    setState(prevState => ({...prevState,
      loading:true
    }))

    let filtro = state.filtro

    console.log(props.cliente.unidades)

    filtro.unidadesnegocio = props.cliente.unidades.map(value => {return value.id})

    const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/listContratos`, filtro, getToken())

    setState(prevState => ({...prevState,
      contratos: {
        list: data.sort((a, b) => (a.situacao_atual.situacao > b.situacao_atual.situacao) ? 1 : ((b.situacao_atual.situacao > a.situacao_atual.situacao) ? -1 : 0))
        .map(contrato => {
          let produtos = ''

          for (let i = 0; i < contrato.servicos.length; i++) {
            if((i + 1) === contrato.servicos.length){
              produtos += `${contrato.servicos[i].produto.descricao}.`
            }else{
              produtos += `${contrato.servicos[i].produto.descricao}, `
            }
            
          }

          return {
            ...contrato,
            unidade: contrato.unidade.descricao,
            data_ini_vig_form: moment(contrato.data_ini_vig).format('DD/MM/YYYY'),
            data_fim_vig_form: moment(contrato.data_fim_vig).format('DD/MM/YYYY'),
            situacao_atual: contrato.situacao_atual.situacao,
            produtos
          }
        })
      },
      loading:false
    }))
  }

  const cancelarContrato = async () => {
    let {dadosContratoView} = state

    let financeiros_pendentes = []

    for (const produto of dadosContratoView.produtos) {
      for (const pendencia of produto.pendencias) {
        if(pendencia.parcela_receita.situacao === "A VENCER"){
          financeiros_pendentes.push({
            ...pendencia.parcela_receita
          })
        }
      }
    }

    await consultaPlanoContas()

    setState(prevState => ({...prevState,
      financeiros_pendentes: {
        list: financeiros_pendentes
      },
      conta: {
        descricao: "Cancelamento de Contrato",
        tipo: "",
        valortotal: "",
        numparcela: 1,
        item_id: "",
        qtd_parcelas: 1,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            datavencimento_orig: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            parcela_editada: false,
            contfin_id: '',
            index: 1
          }
        ]
      },
      openModalCancelarContratoUnidade: true
    }))
  }

  const addServico = async () => {
    await consultaProdutos()

    setState(prevState => ({...prevState,
      openModalAdicionarServContratoUnidade: true
    }))
  }

  const renovarContrato = async () => {
    let {dadosContratoView} = state

    await consultaProdutos()

    setState(prevState => ({...prevState,
      dadosContrato: {
        codigo: "",
        data_ini_vig: "",
        data_fim_vig: "",
        unidade_tempo: dadosContratoView.unidade_tempo,
        tempo_contrato: dadosContratoView.tempo_contrato,
        unidade_id: dadosContratoView.unidade_id,
        formpag_id: dadosContratoView.formpag_id,
        contfin_id: dadosContratoView.contfin_id,
        dias_pagamento: dadosContratoView.dias_pagamento,
        juros: dadosContratoView.juros,
        observacao: dadosContratoView.observacao,
        produtos: dadosContratoView.servicos.map((servico, key) => {
          return {
            prod_id: servico.produto.id,
            prod_descricao: servico.produto.descricao,
            dia_vencimento: servico.dia_vencimento,
            parametro: servico.parametro,
            valor: real(servico.valor),
            ativo: true,
            habilita_valor_min: servico.habilita_valor_min,
            habilita_valor_max: servico.habilita_valor_max,
            inativa_unidade: servico.inativa_unidade,
            valor_min: real(servico.valor_min),
            valor_max: real(servico.valor_max),
            index: key
          }
        }),
      },
      openModalNovoContrato: true,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        dia_vencimento: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        inativa_unidade: true,
        valor_min: real(0),
        valor_max: real(0),
        gerar_nfse: false,
        index: ""
      }
    }))
  }

  const handleCloseModalCancelarContratoUnidade = () => {
    setState(prevState => ({...prevState,
      openModalCancelarContratoUnidade: false
    }))
  }

  const updateFieldLancFinanceiroCancelamento = () => {
    let {dadosCancelamento} = state
    dadosCancelamento.lanc_fin = !dadosCancelamento.lanc_fin
    setState(prevState => ({...prevState,dadosCancelamento}))
  }

  const updateFieldConta = (event) => {
    const conta = { ...state.conta }

    if (event.target.name === 'valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value

    setState(prevState => ({...prevState, conta }))
  }

  const updateFieldParcela = (event, index) => {
    const conta = { ...state.conta }

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      conta.parcelas[index - 1][name] = value
    }else{
      conta.parcelas[index - 1][name] = value
    }
    
    setState(prevState => ({...prevState, conta }))
  }

  const mudarParcelas = (event) => {
    const conta = { ...state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date();
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i));

      array.push({
        baixado: "",
        datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        valorparcela: real(valorDividido),
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array
    setState(prevState => ({...prevState, conta }))
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)


      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const updateFieldCancelamento = (event) => {
    let {dadosCancelamento} = state
    dadosCancelamento[event.target.name] = event.target.value
    setState(prevState => ({...prevState,dadosCancelamento}))
  }

  const confirmarCancelamento = async (value) => {
    if(value){
      let {dadosCancelamento, conta, dadosContratoView, financeiros_pendentes} = state

      if(dadosCancelamento.lanc_fin){
        if(conta.planocontas_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o Plano de Contas.'
            }
          }))
          return
        }
  
        if(conta.valortotal === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o Valor Total.'
            }
          }))
          return
        }
  
        if(conta.descricao === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a descrição da conta.'
            }
          }))
          return
        }
      }

      if(dadosCancelamento.motivoCancelamento === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o motivo do cancelamento.'
          }
        }))
        return
      }

      try {
        const dados = {
          motivoCancelamento: dadosCancelamento.motivoCancelamento,
          contrato_id: dadosContratoView.id,
          conta,
          financeiros_pendentes: financeiros_pendentes.list.map(value => {
            return {
              id: value.id
            }
          })
        }

        if(dados.financeiros_pendentes.length === 0){
          delete dados.financeiros_pendentes
        }

        if(!dadosCancelamento.lanc_fin){
          delete dados.conta
        }else{
          dados.conta.tipo = 'RECEBER'
          dados.conta.unin_id = 1
          dados.conta.valortotal = parseFloat(dados.conta.valortotal.replaceAll(".", "").replace(",", "."))
    
          for (let i = 0; i < dados.conta.parcelas.length; i++) {
            dados.conta.parcelas[i].valorparcela = parseFloat(dados.conta.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
            dados.conta.parcelas[i].baixado = false
            dados.conta.parcelas[i].num = dados.conta.parcelas[i].index
            dados.conta.parcelas[i].formas_pgto = [
              {
                formapagto_id: parseInt(dados.conta.parcelas[i].formapagto_id)
              }
            ]
      
            if(
              moment(dados.conta.parcelas[i].datavencimento).isBefore(new Date()) 
              && (dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 || dados.conta.parcelas[i].formas_pgto.formapagto_id === 5)){
               
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `A data do ${dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 ? 'Pix' : 'Boleto'} não pode ser inferior ao dia de amanhã`
                  }
                }))
                return
            }
    
            delete dados.conta.parcelas[i].formapagto_id
          }
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: `Cancelando Contrato...`
          }
        }))
          
        await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/cancelarContrato/${dadosContratoView.id}`, dados, getToken())   
  
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: `Contrato Cancelado.`
          }
        }))

        // UNSAFE_componentWillMount()
        handleCloseModalCancelarContratoUnidade()
        handleCloseModalContrato()

        window.location.reload()

        return
        
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

    }else{
      handleCloseModalCancelarContratoUnidade()
    }
  }

  const updateFieldItem = (e, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        }
      }))

      return false
    } 

    const {conta} = state

    conta.item_id = value.id
      
    setState(prevState => ({...prevState,
      itemSelecionado: value,
      conta
    }))
  }

  const inicia_tela = async () => {
    const perfil = JSON.parse(localStorage.getItem('perfil'))
    
    let permissoesContrato = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contrato-unidade')[0]

    if(permissoesContrato){

      if(permissoesContrato.inserir || permissoesContrato.alterar){
        setState(prevState => ({...prevState,
          acoesTabelaServico: ['removeServico', 'updateServico']
        }))
      }

      setState(prevState => ({...prevState,
        permissoesContrato
      }))
    }

    await filtrarContratos()

    setState(prevState => ({...prevState,
      loading: false
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      await inicia_tela()
    };
    
    fetchData();
  }, []);

  const {permissoesContrato, filtro, contratos, permissoes, dadosContratoView, financeiros_pendentes, conta, contasFinanceira, 
    formasPagamento, dadosCancelamento, itens_receita_despesa, servico, update, produtos, unidades, itemSelecionado} = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>{state.msgLoading}</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} sm={12} xs={12}>
              <h4 className='titulo'>Contratos Unidade:</h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={state.cabecalhoTabela} 
                rows={contratos.list} 
                acoes={state.acoesTabela} 
                viewContrato={e => viewContrato(e)} 
                qtdLinhas={contratos.list.length}
                desabilitaRodape={true}
              />
            </Grid>
          </Grid>
          {permissoesContrato.inserir &&
            <Grid container spacing={1} direction="row">
              <Grid item md={9} sm={12} xs={9}></Grid>
              <Grid item md={3} sm={12} xs={3}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => novoContrato()}
                >
                  Adicionar Contrato
                </Button>
              </Grid>
            </Grid>
          }
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalNovoContrato 
        open={state.openModalNovoContrato}
        handleClose={e => handleCloseModalNovoContrato()}
        dados={state.dadosContrato}
        updateField={e => updateFieldContrato(e)}
        servico={servico}
        update={update}
        produtos={produtos.list}
        updateFieldServico={(e) => updateFieldServico(e)}
        updateFieldAtivoServico={() => updateFieldAtivoServico()}
        updateFieldMinServico={() => updateFieldMinServico()}
        updateFieldMaxServico={() => updateFieldMaxServico()}
        updateFieldInativaUnidadeServico={() => updateFieldInativaUnidadeServico()}
        contasFinanceira={contasFinanceira.list}
        formasPagamento={formasPagamento.list}
        addItem={() => addItem()}
        removerItem={(e) => removerItem(e)}
        disabledButton={state.disabledButton}
        confirmar={e => confirmarNovoContrato(e)}
        unidades={unidades.list}
        disabledUnidade={false}
        possui_emissao_nfse={true}
        updateFieldGerarNfse={() => updateFieldGerarNfse()}
        template_documentos={state.template_documentos.list}
        updateFieldAssinar={() => updateFieldAssinar()}
      />

      <ModalContrato 
        open={state.openModalContrato}
        handleClose={e => handleCloseModalContrato()}
        dados={dadosContratoView}
        cancelarContrato={() => cancelarContrato()}
        addServico={() => addServico()}
        renovarContrato={() => renovarContrato()}
        permissoes={permissoes}
      />

      <ModalCancelarContratoUnidade 
        open={state.openModalCancelarContratoUnidade}
        handleClose={e => handleCloseModalCancelarContratoUnidade()}
        financeiros_pendentes={financeiros_pendentes.list}
        dados={dadosCancelamento}
        updateFieldLancFinanceiroCancelamento={() => updateFieldLancFinanceiroCancelamento()}
        conta={conta}
        contasFinanceira={contasFinanceira.list}
        formasPagamento={formasPagamento.list}
        updateFieldConta={e => updateFieldConta(e)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        mudarParcelas={e => mudarParcelas(e)}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        itens_receita_despesa={itens_receita_despesa.list}
        updateField={(e) => updateFieldCancelamento(e)}
        confirmar={(e) => confirmarCancelamento(e)}
        updateFieldItem={(e, value) => updateFieldItem(e, value)}
        itemSelecionado={itemSelecionado}
      />
    </React.Fragment>
  )
}

export default Contrato