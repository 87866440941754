import React, { useState, useEffect } from 'react';
import './CentralNotificacao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormNotificacao from '../../components/forms/FormNotificacao'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import ModalSelecionarLogo from './../../components/modals/SelecionarLogo'
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  notificacao: {
    id: "",
    nome_alerta: "",
    titulo_alerta: "",
    modulo: "",
    canal: "",
    qtd_dias: "",
    html: "",
    contato_resposta: "",
    configuracao_id: "",
    midia_logo_id: "",
    hora_envio: "",
    enviar_todos_dias: false,
    ativo: true
  },
  notificacaos: {
    list: []
  },
  loadingSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading:true,
  update: false,
  unidades: {
    list: []
  },
  tipo: 'UNIDADE',
  filtro: {
    descricao: ''
  },
  variaveisFiltrada:{
    list: []
  },
  templates: {
    list: []
  },
  editorState: EditorState.createEmpty(),
  unidadesSelecionadas: [],
  logo_unidade: {
    id: '',
    key: ''
  },
  midias_pessoa: {
    list: []
  },
  openModalSelecionarLogo: false,
  dias_selecionado: [],
  dias_semana: [
    {
      dia: 'Segunda',
      value: 'segunda-feira'
    },
    {
      dia: 'Terça',
      value: 'terça-feira'
    },
    {
      dia: 'Quarta',
      value: 'quarta-feira'
    },
    {
      dia: 'Quinta',
      value: 'quinta-feira'
    },
    {
      dia: 'Sexta',
      value: 'sexta-feira'
    },
    {
      dia: 'Sábado',
      value: 'sábado'
    },
    {
      dia: 'Domingo',
      value: 'domingo'
    }
  ],
  openModalAjuda: false,
  markdown: ''
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

const CadastroNotificacao = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { centralAlertaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/central_notificacao");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateField = (event) => {
    const {notificacao, templates} = state

    let name = event.target.name
    let value = event.target.value

    notificacao[name] = value

    if(name === 'modulo'){
      if(templates.list.filter(param => param.tipo === value)[0]){
        setState(prevState => ({...prevState,
          variaveisFiltrada:{
            list: templates.list.filter(param => param.tipo === value)[0].variaveis
          }
        }))
      }
    }

    setState(prevState => ({...prevState,notificacao}))
  }

  const onEditorStateChange = (editorState) => {
    const {notificacao } = state

    notificacao.html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    setState(prevState => ({...prevState,editorState, notificacao}))
  } 

  const selecionarVar = (variavel) => {
    let notificacao = { ...state.notificacao }

    notificacao.html = notificacao.html.replace('{%}', `%%${variavel.variavel}`)

    const contentBlock = htmlToDraft(notificacao.html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setState(prevState => ({...prevState, notificacao, editorState }))
  }

  const updateFieldUnidade = (event, value) => {
    let midias_pessoa = []

    for (const unidade of value) {
      midias_pessoa = midias_pessoa.concat(unidade.midias)
    }

    setState(prevState => ({...prevState,
      unidadesSelecionadas: value,
      midias_pessoa: {
        list: midias_pessoa
      }
    }))
  }

  const validaForm = () => {
    const {notificacao, unidadesSelecionadas} = state

    if(notificacao.nome_alerta === '') return false
    if(notificacao.alerta_alerta === '') return false
    if(notificacao.hora_envio === '') return false
    if(notificacao.modulo === '') return false
    if(notificacao.canal === '') return false
    if(notificacao.html === '') return false
    if(notificacao.qtd_dias === '') return false
    if(notificacao.canal === 'EMAIL'){
      if(notificacao.contato_resposta === '') return false
    }
    if(unidadesSelecionadas.length === 0) return false

    return true
  }

  const salvar = async () => {
    const {notificacao, unidadesSelecionadas, dias_selecionado} = state
    setState(prevState => ({...prevState, loadingSalvar: true }))

    if(!validaForm()){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        },
        loadingSalvar: false
      }))
      return
    }

    if(dias_selecionado.length === 0 && notificacao.qtd_dias < 0 && notificacao.enviar_todos_dias){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Selecionar ao menos um dia da semana!'
        },
        loadingSalvar: false
      }))
      return
    }

    if(notificacao.canal === 'EMAIL'){
      if (!validacaoEmail(notificacao.contato_resposta)) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Email Invalido!'
          },
          loadingSalvar: false
        }))
        return
      }
    }

    notificacao.unidades = unidadesSelecionadas.map(unidade => { return {unidade_id: unidade.id}})
    if(notificacao.qtd_dias < 0 && notificacao.enviar_todos_dias){
      notificacao.dias_selecionado = dias_selecionado.map(dia => { return dia.value})
    }

    try {
      if(notificacao.id !== ''){
        await axios.put(`${window._env_.REACT_APP_API_URL}/centralAlerta/${notificacao.id}`, notificacao, getToken())
      }else{
        await axios.post(`${window._env_.REACT_APP_API_URL}/centralAlerta`, notificacao, getToken())
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      }))

      backPage()
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
    
  }

  const openModalSelecionarLogo = () => {
    setState(prevState => ({...prevState,
      openModalSelecionarLogo: true
    }))
  }

  const handleCloseModalSelecionarLogo = () => {
    setState(prevState => ({...prevState,
      openModalSelecionarLogo: false
    }))
  }

  const selecionarLogo = (img) => {
    const {notificacao} = state

    const img_aux = new Image();

    img_aux.onload = () => {
      if(img_aux.width !== img_aux.height){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor selecionar uma imagem com a mesma largura e altura Ex.: 512x512'
          }
        }))
        return false
      }else{
        notificacao.midia_logo_id = img.midia_id

        setState(prevState => ({...prevState,
          notificacao,
          logo_unidade:{
            id: img.midia_id,
            key: img.midia.key
          }
        }))
      }

    }

    img_aux.src = `${window._env_.REACT_APP_API_URL}/static/${img.midia.key}`

  }

  const updateFieldAtivo = () => {
    const {notificacao} = state
    notificacao.ativo = !notificacao.ativo
    setState(prevState => ({...prevState,notificacao}))
  }

  const updateFieldEnviarTodosDias = () => {
    const {notificacao} = state
    notificacao.enviar_todos_dias = !notificacao.enviar_todos_dias
    setState(prevState => ({...prevState,notificacao}))
  }

  const updateFiltro = (event) => {
    let {filtro} = state
    filtro.descricao = event.target.value
    setState(prevState => ({...prevState,filtro}))
  }

  const updateFieldDias = (event, value) => {
    setState(prevState => ({...prevState,
      dias_selecionado: value
    }))
  }

  const enviarNotificacao = async () => {
    const {notificacao} = state

    try {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Enviando Notificações'
        }
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/centralAlerta/enviarNotificao/${notificacao.id}`, {}, getToken())
      
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Notificações Enviada'
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState, 
        loadingSalvar: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response.data.error
        }
       }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/centralNotificacao/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        notificacao: {
          id: "",
          nome_alerta: "",
          titulo_alerta: "",
          modulo: "",
          canal: "",
          qtd_dias: "",
          html: "",
          contato_resposta: "",
          configuracao_id: "",
          midia_logo_id: "",
          hora_envio: "",
          enviar_todos_dias: false,
          ativo: true
        },
        unidadesSelecionadas: [],
        dias_selecionado: []
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'central-alerta')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (centralAlertaId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }  

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: templates } = await axios.get(`${window._env_.REACT_APP_API_URL}/templatesvariaveis`, getToken())
        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, dados, getToken())

        setState(prevState => ({...prevState,
          templates:{
            list: templates
          },
          unidades:{
            list: unidades.map(unidade => {
              return {
                id: unidade.id,
                numero: unidade.numero,
                descricao: unidade.descricao,
                midias: unidade.pessoa.midias_pessoa
              }
            })
          }
        }))

        let central_copiar = localStorage.getItem('central_copiar');

        if (centralAlertaId || central_copiar) {
          const { data: central } = await axios.get(`${window._env_.REACT_APP_API_URL}/centralAlerta/${centralAlertaId || central_copiar}`, getToken())

          const contentBlock = htmlToDraft(central.template.html);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          
          let midias_pessoa = []

          for (const unidade of central.unidades) {
            midias_pessoa = midias_pessoa.concat(unidade.unidade.pessoa.midias_pessoa)
          }

          if(central.logo){
            setState(prevState => ({...prevState,
              logo_unidade: {
                id: central.logo.id,
                key: central.logo.key
              }
            }))
          }

          let dias_selecionado = []

          for (const dia_semana of central.dias_semana) {
            dias_selecionado.push({
              dia: state.dias_semana.filter(param => param.value === dia_semana.dia)[0].dia,
              value: dia_semana.dia
            })
          }

          setState(prevState => ({...prevState,
            notificacao: {
              id: central_copiar ? '' : central.id,
              nome_alerta: central.nome_alerta,
              titulo_alerta: central.titulo_alerta,
              modulo: central.modulo,
              canal: central.canal,
              hora_envio: central.hora_envio || '',
              qtd_dias: central.dias_antes_evento,
              html: central.template.html,
              contato_resposta: central.contato_resposta,
              configuracao_id: central.configuracao_id,
              midia_logo_id: central.midia_logo_id,
              enviar_todos_dias: central.enviar_todos_dias,
              ativo: central.ativo
            },
            variaveisFiltrada:{
              list: templates.filter(param => param.tipo === central.modulo)[0].variaveis
            },
            unidadesSelecionadas:central.unidades.map(value => {
              return {
                id: value.unidade_id,
                descricao: value.unidade.descricao,
                numero: value.unidade.numero
              }
            }),
            dias_selecionado,
            editorState,
            midias_pessoa: {
              list: midias_pessoa
            },
          }))

          if(central_copiar){
            localStorage.removeItem('central_copiar')
          }
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { notificacao, filtro, variaveisFiltrada, editorState, unidades, unidadesSelecionadas, logo_unidade, midias_pessoa,
    dias_selecionado, dias_semana } = state
  
  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Tela...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom" >
              <Grid item md={11}>
                <h1 className="titulo">Cadastro de Notificações</h1>
              </Grid>
              <Grid item md={1}>
                {state.loadingSalvar &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>
            <FormNotificacao 
              dados={notificacao}
              updateField={e => updateField(e)}
              filtro={filtro}
              editorState={editorState}
              onEditorStateChange={e => onEditorStateChange(e)}
              variaveisFiltrada={variaveisFiltrada.list}
              selecionarVar={e => selecionarVar(e)}
              unidades={unidades.list}
              unidadesSelecionadas={unidadesSelecionadas}
              updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
              openModalSelecionarLogo={e => openModalSelecionarLogo()}
              updateFieldAtivo={e => updateFieldAtivo()}
              logo_unidade={logo_unidade}
              updateFiltro={(e) => updateFiltro(e)}
              updateFieldEnviarTodosDias={() => updateFieldEnviarTodosDias()}
              dias_selecionado={dias_selecionado}
              updateFieldDias ={(event, value) => updateFieldDias(event, value)}
              dias_semana={dias_semana}
            />
            <Grid spacing={1} container direction="row" alignItems="flex-end" className="mg_top_10">
              <Grid item md={8} xs={12} sm={6}></Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button 
                  fullWidth 
                  color="secondary" 
                  variant="contained"  
                  size="small"
                  disabled={!notificacao.ativo}
                  onClick={e => enviarNotificacao(e)}>Enviar Notificação</Button>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button 
                  fullWidth 
                  color="primary" 
                  variant="contained"  
                  size="small" 
                  startIcon={<SaveIcon />} 
                  onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalSelecionarLogo 
        open={state.openModalSelecionarLogo}
        handleClose={e => handleCloseModalSelecionarLogo()} 
        imagens={midias_pessoa.list}
        configuracao={notificacao}
        selecionarLogo={e => selecionarLogo(e)}
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Notificações"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default CadastroNotificacao