import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '60%',
  marginLeft: '20%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  const {relatorios, relatorios_usuario} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} data-cy="modal-trocar-relatorio">
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Selecionar Relatório</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className='mg_top_20'>
            <Grid item md={5} xs={12} sm={5}>
              <div className="cardSelectAulas">
                <h4>Não Selecionados</h4>
                <Grid container direction="row" spacing={2} className='fundo'>
                  <Grid item md={12} xs={12} sm={12} style={{paddingTop: 8}}>
                    <h5>Relatórios</h5>
                  </Grid>
                </Grid>
                <div data-cy="card-nao-selecionados" className="cardSelectAulasOverFlow" style={{marginTop: 8}}>
                  {relatorios.sort((a, b) => (a.titulo > b.titulo) ? 1 : ((b.titulo > a.titulo) ? -1 : 0)).map((relatorio, key) => {
                    return (
                      <div key={key} data-cy="item-nao-selecionado" className='item' onClick={() => props.selecionarRelatorio(relatorio)}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item md={12} xs={12} sm={12}>
                            <p>{relatorio.titulo}</p>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Grid>
            <Grid item md={2} xs={12} sm={2} className="aling-center">
              <SyncAltIcon className="iconeTroca" />
            </Grid>
            <Grid item md={5} xs={12} sm={5}>
              <div className="cardSelectAulas">
                <h4>Selecionados</h4>
                <Grid container direction="row" spacing={2} className='fundo'>
                  <Grid item md={12} xs={12} sm={12} style={{paddingTop: 8}}>
                    <h5>Relatórios</h5>
                  </Grid>
                </Grid>
                <div data-cy="card-selecionados" className="cardSelectAulasOverFlow" style={{marginTop: 8}}>
                  {relatorios_usuario.sort((a, b) => (a.titulo > b.titulo) ? 1 : ((b.titulo > a.titulo) ? -1 : 0)).map((relatorio, key) => {
                    return (
                      <div key={key} data-cy="item-selecionado" className='item' onClick={() => props.removerRelatorio(relatorio)}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item md={12} xs={12} sm={12}>
                            <p>{relatorio.titulo}</p>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={3} xs={12}>
              <Button data-cy="botao-voltar" fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(false)}>Voltar</Button>
            </Grid>
            <Grid item md={6} xs={false}></Grid>
            <Grid item md={3} xs={12}>
              <Button data-cy="botao-confirmar" fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true)}>Confirmar</Button>
            </Grid>
          </Grid>
         </StyledPaper>
      </Modal>
    </div>
  );
}
