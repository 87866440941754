import React, { useState, useEffect } from 'react';
import './Funcionario.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormNovoContrato from '../../components/forms/FormNovoContrato'
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment';
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');

const initialState = {
  contrato: {
    paraquem: "FRANQUEADOR",
    selects_unidadetrabalho: {
      franqueador: true,
      franqueado: false,
      unidade: false
    }
  },
  franqueados: [],
  funcionario: {
    _id: "",
    dataAdmissao: "",
    dataDemissao: "",
    unidadetrabalho_id: "",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    ativo: "",
  },
  novoContrato: {
    unidadetrabalho_id: "",
    mudar_unidade: false,
    departamento: "",
    idDepartamento: "",
    cargo: "",
    idCargo: "",
    funcao: "",
    idFuncao: "",
    dataInicial: "",
    dataFinal: "",
    observacao: "",
    paraOnde: ""
  },
  funcionarios: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  unidades: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  loadingSalvar: false,
  status: '',
  tipo: 'UNIDADE',
  edit: false
}

const ContratoFuncionario = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { funcionarioId, status: paramsStatus } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/funcionario");
    }, timeout)
  }

  const updateField = async (event) => {
    const { status, funcionario, novoContrato } = state

    if (status === 'demitir') {
      funcionario.contrato[event.target.name] = event.target.value
      setState(prevState => ({...prevState, funcionario }))
    }
    if (status === 'promover') {
      if (event.target && event.target.name === "idDepartamento") {
        if (novoContrato.departamento === parseInt(event.target.value)) return false

        try {
          novoContrato.idDepartamento = parseInt(event.target.value)
          novoContrato.departamento = state.departamentos.list.filter(u => u.dep_id === parseInt(event.target.value))[0].dep_descricao

          let {data: cargos} = await axios.get(`${window._env_.REACT_APP_API_URL}/cargos/departamento/${parseInt(event.target.value)}`, getToken())

          setState(prevState => ({...prevState,
            novoContrato,
            cargosFiltrado: {
              list: cargos
            }
          }))
        } catch (error) {
          console.log(error)
        }
      }

      if (event.target && event.target.name === "idCargo") {
        if (novoContrato.cargo === parseInt(event.target.value)) return false

        try {
          novoContrato.idCargo = parseInt(event.target.value)
          novoContrato.cargo = state.cargosFiltrado.list.filter(u => u.car_id === parseInt(event.target.value))[0].car_descricao

          let {data: funcoes} = await axios.get(`${window._env_.REACT_APP_API_URL}/funcoes/cargo/${parseInt(event.target.value)}`, getToken())

          setState(prevState => ({...prevState,
            novoContrato,
            funcaosFiltrado: {
              list: funcoes
            }
          }))
        } catch (error) {
          console.log(error)
        }
      }

      if (event.target && event.target.name === "idFuncao") {
        if (novoContrato.funcao === parseInt(event.target.value)) return false

        novoContrato.idFuncao = parseInt(event.target.value)
        novoContrato.funcao = state.funcaosFiltrado.list.filter(u => u.func_id === parseInt(event.target.value))[0].func_descricao
        setState(prevState => ({...prevState, novoContrato }))
      }

      if (event.target && event.target.name === 'dataFinal') {
        funcionario.contrato.dataFinal = event.target.value
        novoContrato.dataInicial = event.target.value
        setState(prevState => ({...prevState, funcionario, novoContrato }))
      } else if (event.target && event.target.name === 'unidadetrabalho_id') {
        novoContrato.unidadetrabalho_id = event.target.value
        setState(prevState => ({...prevState, novoContrato }))
      }

    }
    if (status === 'transferir') {
      if (event.target && event.target.name === 'dataFinal') {
        funcionario.contrato.dataFinal = event.target.value
        novoContrato.dataInicial = event.target.value
        setState(prevState => ({...prevState, funcionario, novoContrato }))
      }
    }
  }

  const updateFieldNovoContrato = async (event) => {
    const { novoContrato, unidades } = state

    if (event.target.name === 'unidadetrabalho_id') {
      const unidade = unidades.list.filter(param => param.id === parseInt(event.target.value))

      if (unidade[0].tipo === 'UNIDADE') {
        novoContrato.unidade = `${unidade[0].numero} - ${unidade[0].descricao}`
      } else {
        novoContrato.unidade = unidade[0].descricao
      }
    }

    novoContrato[event.target.name] = event.target.value
    setState(prevState => ({...prevState, novoContrato }))
  }

  const updateFieldMudarUnidade = () => {
    const novoContrato = state.novoContrato
    novoContrato.mudar_unidade = !novoContrato.mudar_unidade
    setState(prevState => ({...prevState, novoContrato }))
  }

  const validateForm = () => {
    const { novoContrato } = state

    if (novoContrato.mudar_unidade) {
      if (novoContrato.unidadetrabalho_id === "") return false
    }

    if (novoContrato.idFuncao === "") return false

    return true
  }

  const salvar = async () => {
    const { status, funcionario, novoContrato } = state

    let dados = {}

    if (status === 'demitir') {
      
      const contratoAntigo = {
        id: funcionario.contrato.id,
        unidadetrabalho_id: funcionario.unidadetrabalho_id,
        funcao_id: funcionario.contrato.idFuncao,
        funcionario_id: funcionario._id,
        observacao: funcionario.contrato.observacao,
        ativo: false,
        datainicio: funcionario.contrato.dataInicial,
        datafinal: funcionario.contrato.dataFinal
      }

      dados = {
        status_mudanca: status,
        contratoAntigo
      }
    }

    if (status === 'promover') {

      if (!validateForm()) {
        setState(prevState => ({...prevState, loadingSalvar: false }))
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:'Preencha os campos obrigatorios (*).'
          }
        }))
        return
      }

      const contratoAntigo = {
        contt_id: funcionario.contrato.contt_id,
        contt_unidadetrabalho_id: funcionario.unidadetrabalho_id,
        contt_funcao_id: funcionario.contrato.idFuncao,
        contt_funcionario_id: funcionario._id,
        contt_observacao: funcionario.contrato.observacao,
        contt_ativo: false,
        contt_datainicio: funcionario.contrato.dataInicial,
        contt_datafinal: funcionario.contrato.dataFinal
      }

      let contratoNovo = {}

      if (novoContrato.mudar_unidade) {
        contratoNovo = {
          contt_unidadetrabalho_id: novoContrato.unidadetrabalho_id,
          contt_funcao_id: novoContrato.idFuncao,
          contt_funcionario_id: funcionario._id,
          contt_observacao: novoContrato.observacao,
          contt_ativo: true,
          contt_datainicio: novoContrato.dataInicial
        }
      } else {
        if (funcionario.contrato.idFuncao === novoContrato.idFuncao) {
          setState(prevState => ({...prevState, loadingSalvar: false }))
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message:'Você não pode promover o funcionário para a mesma função.'
            }
          }))
          return
        }

        contratoNovo = {
          contt_unidadetrabalho_id: funcionario.unidadetrabalho_id,
          contt_funcao_id: novoContrato.idFuncao,
          contt_funcionario_id: funcionario._id,
          contt_observacao: novoContrato.observacao,
          contt_ativo: true,
          contt_datainicio: novoContrato.dataInicial
        }
      }

      dados = {
        status_mudanca: status,
        contratoAntigo,
        contratoNovo
      }
    }

    try {

      await axios.put(`${window._env_.REACT_APP_API_URL}/funcionarios/contrato/${funcionario._id}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message:'Cadastro realizado com sucesso.'
        }
      }))
      backPage()

    } catch ({ response }) {
      setState(prevState => ({...prevState, loadingSalvar: false }))
      if (response.status === 400 || response.status === 500) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message:response.data.message
          }
        }))
        return
      }
    }
  }

  const handleCloseAlerta = () => {
    setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo
      const novoContrato = state.novoContrato

      if (tipo === 'UNIDADE') {
        novoContrato.paraOnde = 'UNIDADE'
        setState(prevState => ({...prevState,
          novoContrato
        }))
      }

      setState(prevState => ({...prevState,
        tipo
      }))

      setState(prevState => ({...prevState,
        contrato: {
          paraquem: "FRANQUEADOR",
          selects_unidadetrabalho: {
            franqueador: true,
            franqueado: false,
            unidade: false
          }
        },
        funcionario: {
          id: "",
          dataAdmissao: "",
          dataDemissao: "",
          unidadetrabalho_id: "",
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: "",
            cpf: "",
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: ""
          },
          enderecos: [{
            rua: "",
            complemento: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            cep: "",
            principal: true,
            tipoEndereco: "RESIDENCIAL",
            index: 1
          }],
          emails: [{
            email: "",
            principal: true,
            index: 1
          }],
          contatos: [{
            numero: "",
            tipoContato: "RESIDENCIAL",
            principal: true,
            index: 1
          }],
          usuario: {
            login: "",
            senha: "",
            repetirSenha: "",
            usu_perfil_id: ""
          },
          contas: [{
            cb_agencia: "",
            cb_numero: "",
            cb_gerente: "",
            cb_chave_pix: "",
            cb_banco_id: "",
            index: 1,
            banco: {
              banco_id: "",
              banco_nome: "",
              banco_numero: "",
              banco_padraocosif: ""
            }
          }],
          ativo: "",
        },
      }))

      const dataHoje = new Date()
      const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

      if (funcionarioId) {
        try {
          setState(prevState => ({...prevState, enableUpdate: true }))
          const { data: funcionario } = await axios.get(`${window._env_.REACT_APP_API_URL}/funcionarios/${funcionarioId}`, getToken())
    
          setState(prevState => ({...prevState,
            funcionario: {
              _id: funcionario.id,
              dataAdmissao: moment(funcionario.data_admissao).format('YYYY-MM-DD'),
              dataDemissao: moment(funcionario.data_demissao).format('YYYY-MM-DD'),
              unidadetrabalho_id: funcionario.contrato.unidadetrabalho.id,
              matricula: funcionario.matricula,
              contrato: {
                id: funcionario.contrato.id,
                departamento: funcionario.contrato.funcao.cargo.departamento.descricao,
                idDepartamento: funcionario.contrato.funcao.cargo.id,
                cargo: funcionario.contrato.funcao.cargo.descricao,
                idCargo: funcionario.contrato.funcao.cargo.id,
                funcao: funcionario.contrato.funcao.descricao,
                idFuncao: funcionario.contrato.funcao.id,
                dataInicial: moment(funcionario.contrato.datainicio).format('YYYY-MM-DD'),
                dataFinal: dataHojeFormatado,
                observacao: funcionario.contrato.observacao,
                unidadetrabalho: funcionario.contrato.unidadetrabalho,
                ativo: funcionario.contrato.ativo
              },
              pessoaFisica: {
                nome: funcionario.fisica.nome,
                cpf: cpf.format(funcionario.fisica.cpf),
                rg: funcionario.fisica.rg,
                rg_orgao_emissor: funcionario.fisica.rg_orgao_emissor,
                rg_uf: funcionario.fisica.rg_uf,
                rg_data_emissao: moment(funcionario.fisica.rg_data_emissao).format('YYYY-MM-DD'),
                dataNascimento: moment(funcionario.fisica.datanascimento).format('YYYY-MM-DD')
              },
              ativo: funcionario.fun_status
            },
            novoContrato: {
              departamento: '',
              idDepartamento: '',
              cargo: '',
              idCargo: '',
              funcao: '',
              idFuncao: '',
              dataInicial: dataHojeFormatado,
              dataFinal: '',
              observacao: '',
              unidadetrabalho: '',
              unidadetrabalho_id: '',
              ativo: true,
              paraOnde: novoContrato.paraOnde
            },
            loading: false,
            status: paramsStatus
          }))
    
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message:"Não foi possível buscar o funcionario, tente mais tarde"
            }
          }))
    
          backPage()
        }
      }

      try {
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, {}, getToken())

        setState(prevState => ({...prevState,
          departamentos: {
            list: data
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: "Não foi possível buscar os Departamentos, tente mais tarde"
          }
        }))

        backPage()
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'FRANQUEADOR') {
                descricao = `${unidade.pessoa.pes_tipo === "JURIDICA" ? unidade.pessoa.juridica.pesj_razaosocial : unidade.pessoa.fisica.pesf_nome}`
              } else if (unidade.tipo === 'FRANQUEADO') {
                descricao = `${unidade.pessoa.pes_tipo === "JURIDICA" ? unidade.pessoa.juridica.pesj_razaosocial : unidade.pessoa.fisica.pesf_nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

      } catch ({ response }) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: response.data.message
          }
        }))
        return
      }
    };
    
    fetchData();
  }, []);

  const { funcionario, status, novoContrato, tipo } = state

  return (
    <div className="app-menu-closed" id="app">

      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <div>
            <CircularProgress />
          </div>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={11}>
                {status === 'demitir' &&
                  <h1 className="titulo">Demissão de Funcionário</h1>
                }
                {status === 'promover' &&
                  <h1 className="titulo">Promoção de Funcionário</h1>
                }
                {status === 'transferir' &&
                  <h1 className="titulo">Transferência de Funcionário</h1>
                }
              </Grid>
              <Grid item md={1} xs={2} sm={2}>
                {state.loadingSalvar &&
                  <CircularProgress />
                }
              </Grid>
            </Grid>
            <FormNovoContrato
              funcionario={funcionario}
              status={status}
              departamentos={state.departamentos.list}
              cargos={state.cargosFiltrado.list}
              funcaos={state.funcaosFiltrado.list}
              novoContrato={novoContrato}
              unidades={state.unidades.list}
              tipo={tipo}
              updateFieldMudarUnidade={e => updateFieldMudarUnidade(e)}
              updateField={e => updateField(e)}
              updateFieldNovoContrato={e => updateFieldNovoContrato(e)}
            />
            <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
              <Grid item md={9}></Grid>
              <Grid item md={3}>
                <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
            <br />
            <Alert 
              open={state.alerta.open}
              handleClose={e => handleCloseAlerta()} 
              severity={state.alerta.severity}
              message={state.alerta.message} 
            />
          </div>
        }
      </Main>
        <Nav/>
    </div>
  )
}

export default ContratoFuncionario