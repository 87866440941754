import React, { useState, useEffect } from 'react';
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from './../../../components/templates/Alert'
import UploadImage from './../../../services/uploads/UploadImage'
import {filesize} from "filesize";
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  unidade: {},
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loadingSalvar: false,
  itens: {
    list: []
  },
  openModalSelecionarLogo: false,
  logo_unidade:{
    id: '',
    key: ''
  },
  uploadedFiles: [],
  deleteImagem: false
}

const Midias = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }


  const voltar = () => {
    navigate("/unidade");
    return;
  }


  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({
        ...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { } = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Contratos...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
            <Grid item md={6} xs={12} sm={12}>
              <h1 className="titulo">Contratos: </h1>
            </Grid>
          </Grid>
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
    </React.Fragment>
  )

}

export default Midias