import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'

const FormCargo = ({ dados, updateField, updateFieldAtivo, unidades }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          id='ambiente'
          className="input" 
          label="Nome" 
          variant="outlined" 
          size="small" 
          name="nome" 
          value={dados.nome} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={1} xs={12} sm={6}>
        <TextField 
          id='ambiente'
          type='number'
          className="input" 
          label="Ordem" 
          variant="outlined" 
          size="small" 
          name="ordem"
          value={dados.ordem}
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={5}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.ativo}
            />
          }
          label="Ativo"
          name="ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <TextField
          id="descrição"
          label="Descrição"
          className="input"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          name="descricao"
          value={dados.descricao}
          onChange={(e) => updateField(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormCargo