import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material'
import Table from './../Table'
import real from './../../services/real'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">{props.tituloRelCarteira}</h5>
            </Grid>
          </Grid>
          <br />
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
							<Table 
                headCell={props.cabecalhoTabela} 
                rows={props.list} 
                acoes={props.acoesTabela} 
                urlView={props.urlView}
              />
						</Grid>
					</Grid>
          <Grid container spacing={1} direction="row">
            <Grid style={{textAlign: 'right'}} item md={12} xs={12}>
              <h4 className='titulo'>Valor Total: R$ {real(props.valorTotalRelCarteira)}</h4>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
