export default {
  cliente: {

  },
  pessoas: {
    list: []
  },
  pessoasFiltrado: {
    list: []
  },
  filtro: {
    nome: "",
    cpf_cnpj: "",
    flag: "",
    ativo: 'Sim',
    filtrar_por: "Nome - nome",
    value: '',
    telefone: '',
    limit: 10,
    offset: 0,
    representante: ''
  },
  cabecalhoTabela: [
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome/Nome Fantasia' },
    { id: 'cpf_cnpj', numeric: false, disablePadding: false, label: 'CPF/CNPJ' },
    { id: 'numero', numeric: false, disablePadding: false, label: 'Telefone' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'flags', numeric: false, disablePadding: false, label: 'Flags' }
  ],
  cabecalhoTabelaMobile: [
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome/Nome Fantasia' },
    { id: 'cpf_cnpj', numeric: false, disablePadding: false, label: 'CPF/CNPJ' }
  ],
  acoesTabela: [],
  loading: true,
  mensagemLoading: 'Carregando Clientes/Fornecedores...',
  msgTrava: '',
  exibir_ids_pessoas: false,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  filtrocliente: {
    label: 'Nome',
    name:'nome'
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  rows: 0,
  openModalAjuda: false,
  markdown: '',
  representantes: {
    list: []
  }
}