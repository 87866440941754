import React from "react";
import ReactDOM from "react-dom/client";
import App from './main/App';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

// ReactDOM.render(<App />, document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
