import React, { useState, useEffect } from 'react';
import './CentralNotificacao.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import moment from 'moment';
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  notificacoes: {
    list: []
  },
  notificacoesFiltrado: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'nome_alerta', numeric: false, disablePadding: true, label: 'Nome do Alerta' },
    { id: 'titulo_alerta', numeric: false, disablePadding: true, label: 'Titulo do Alerta' },
    { id: 'modulo', numeric: false, disablePadding: true, label: 'Modulo' },
    { id: 'canal', numeric: false, disablePadding: true, label: 'Canal' },
    { id: 'dias_envia', numeric: false, disablePadding: true, label: 'Dias para Envio' },
    { id: 'hora_envio', numeric: false, disablePadding: true, label: 'Horario do Envio' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  filtro: {
    descricao: "",
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

const CentralNotificacao = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const  handleCloseAlerta = () => {
    setState(prevState => ({...prevState, 
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const copiar = (row) => {
    localStorage.setItem('central_copiar', row._id)
    navigate("/central_notificacao/cadastro")
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/Listagem.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState, 
            markdown: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'central-alerta')[0]

      let acoesTabela = []

      if (permissoes.alterar) {
        acoesTabela.push('update')
      }

      if (permissoes.inserir) {
        acoesTabela.push('copy')
      }

      setState(prevState => ({...prevState, 
        acoesTabela,
        permissoes
      }))

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: alertas } = await axios.post(`${window._env_.REACT_APP_API_URL}/centralAlerta/list`, dados, getToken())

        setState(prevState => ({...prevState, 
          notificacoes:{
            list: alertas.map(alerta => {
              return{
                _id: alerta.id,
                nome_alerta: alerta.nome_alerta,
                titulo_alerta: alerta.titulo_alerta,
                modulo: alerta.modulo,
                canal: alerta.canal,
                dias_envia: alerta.dias_antes_evento,
                hora_envio: moment(`${moment(new Date()).format('YYYY-MM-DD')} ${alerta.hora_envio}`).format('HH:mm'),
                ativo: alerta.ativo
              }
            })
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState(prevState => ({...prevState, 
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { permissoes, notificacoes } = state
  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Notificações...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={9} xs={12} sm={6}>
                <h1 className="titulo">Central de Notificações</h1>
              </Grid>
              {permissoes.inserir &&
                <Grid item md={3} xs={12} sm={6}>
                  <Link to="/central_notificacao/cadastro">
                    <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Notificação
                    </Button>
                  </Link>
                </Grid>
              }
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              <Grid item md={12} xs={12}>
                <Table 
                  urlUpdate="/central_notificacao/cadastro/" 
                  headCell={state.cabecalhoTabela} 
                  rows={notificacoes.list} 
                  acoes={state.acoesTabela}
                  copiarVersao={(e) => copiar(e)}
                />
              </Grid>
            </Grid>
          </div>
        }

      </Main>
       <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message}
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Notificações"}
        handleClose={() => {setState(prevState => ({...prevState, openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default CentralNotificacao