import axios from 'axios'
import getToken from './../../../services/getToken'

const run = async () => {
  try {
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, dados, getToken())

    const departamentos = []

    data.forEach(departamento => {
      if(departamento.unidade){
        let centro_custos = ''
  
        if(departamento.centro_custos.length > 0){
          for (let i = 0; i < departamento.centro_custos.length; i++) {
            if((i + 1) === departamento.centro_custos.length){
              centro_custos += `${departamento.centro_custos[i].descricao}.`
            }else{
              centro_custos += `${departamento.centro_custos[i].descricao}, `
            }
          }
        }
        
        departamentos.push({
          _id: departamento.id,
          descricao: departamento.descricao,
          unidade: departamento.unidade ? (departamento.unidade.descricao ? departamento.unidade.descricao : (departamento.unidade.pessoa.tipo === 'FISICA' ? departamento.unidade.pessoa.fisica.nome : departamento.unidade.pessoa.juridica.razaosocial)) : 'SISTEMA',
          centro_custos,
          ativo: departamento.ativo
        })
      }
    })

    return departamentos

  } catch (error) {
    console.log(error)
    throw error
  }
}

export default run