import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import Table from '../../../components/Table'
import axios from 'axios'
import ModalErro from './../../../components/modals/Erro'
import ModalCancelamentoAfastamento from './../../../components/modals/ConfirmacaoCancelamentoAfastamento'
import ModalAulasPeriodo from './../../../components/modals/AulasPeriodoAusencia'

const moment = require('moment')

const initialState = {
  ausencia: {
    afafun_fun_id: '',
    afafun_data_inicial: '',
    afafun_hora_inicial: '',
    afafun_data_final: '',
    afafun_hora_final: '',
    afafun_motivo: ''
  },
  ausencias: {
    list: []
  },
  ausenciaRemove: {
    afafun_fun_id: '',
    afafun_data_inicial: '',
    afafun_data_final: '',
    afafun_hora_inicial: '',
    afafun_hora_final: '',
    afafun_motivo: '',
    remarcar: false
  },
  agendas: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'afafun_criado_em', numeric: false, disablePadding: true, label: 'Inserido em', orderBy: 'criado_em_order' },
    { id: 'afafun_data_inicial', numeric: false, disablePadding: true, label: 'Data Inicial', orderBy: 'data_inicial_order' },
    { id: 'afafun_data_final', numeric: false, disablePadding: false, label: 'Data Final', orderBy: 'data_final_order' },
    { id: 'afafun_motivo', numeric: false, disablePadding: false, label: 'Motivo' },
    { id: 'criado_por', numeric: false, disablePadding: false, label: 'Cadastrado por	' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: ['desativar'],
  modalErro: false,
  modalCancelamento: false,
  modalAulasPeriodo: false,
  modalAulasPeriodoCancelamento: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  disabledSalvar: false,
  modalTrocaProfessoresAulas: false,
  aulasAgendadas:{
    list: []
  },
  rowProfessores: [],
  professoresUnidade:{
    list: []
  },
  professorSelecionado:{
    prof_id: ''
  },
  verificaAulas: true,
  afastamentos: {
    list: []
  },
  agendasUni:{
    list: []
  },
  loadingSalvar: false
}

export default class Agenda extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    this.setState({
      ausencia: {
        afafun_fun_id: '',
        afafun_data_inicial: '',
        afafun_data_final: '',
        afafun_hora_inicial: '',
        afafun_hora_final: '',
        afafun_motivo: ''
      }
    })

    const funcionario = this.props.funcionario
    const { ausencia } = this.state

    ausencia.afafun_fun_id = funcionario.fun_id

    this.setState({
      ausencia
    })

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/afastamentoFuncionario/${funcionario.fun_id}`, this.getToken())
      
      this.setState({
        ausencias: {
          list: data
        }
      })

    } catch (error) {
      console.log(error)
    }

  }

  updateAusencia(event) {
    const { ausencia } = this.state
    ausencia[event.target.name] = event.target.value
    this.setState({ ausencia })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleCloseCancelamento() {
    this.setState({
      modalCancelamento: !this.state.modalCancelamento
    })
  }

  handleCloseModalAulasPeriodo() {
    this.setState({
      modalAulasPeriodo: !this.state.modalAulasPeriodo,
      modalAulasPeriodoCancelamento: !this.state.modalAulasPeriodoCancelamento,
    })
  }

  backPage(timeout = 1000) {
    setTimeout(() => {
      window.location.reload()
    }, timeout)
    this.setState({
      ausencia: initialState.ausencia
    })
  }

  validateForm() {
    const { ausencia } = this.state

    if (!ausencia.afafun_data_inicial) return false
    if (!ausencia.afafun_data_final) return false
    if (!ausencia.afafun_motivo) return false

    return true
  }

  calcDias() {
    const { ausencia } = this.state

    if (ausencia.afafun_data_inicial !== '' && ausencia.afafun_data_final !== '') {

      const inicio = moment(ausencia.afafun_data_inicial);
      const fim = moment(ausencia.afafun_data_final);
      const duration = moment.duration(fim.diff(inicio));

      // Mostra a diferença em dias
      ausencia.qtd_dias = duration.asDays() + 1;

      this.setState({
        ausencia
      })
    }
  }

  async verificaAulas() {
    this.setState({
      verificaAulas: true,
      loadingSalvar: true
    })

    const funcionario = this.props.funcionario

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    if(funcionario.professor){
      try {
        const { ausencia } = this.state
  
        const dados = {
          data_inicial: ausencia.afafun_data_inicial + ' ' + ausencia.afafun_hora_inicial,
          data_final: ausencia.afafun_data_final + ' ' + ausencia.afafun_hora_final
        }

        const periodo = {
          data_inicial: moment(ausencia.afafun_data_inicial).format('YYYY-MM-DD'),
          data_final: moment(ausencia.afafun_data_final).format('YYYY-MM-DD'),
        }
  
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAula/byProfessor/periodo/${funcionario.professor.prof_id}`, dados, this.getToken())
  
        if (data.length > 0) {

          const { data: professores } = await axios.get(`${window._env_.REACT_APP_API_URL}/professores/byUnidade/${funcionario.fun_unin_id}`, this.getToken())
          const { data: afastamentos } = await axios.post(`${window._env_.REACT_APP_API_URL}/afastamentoFuncionario/periodo/${funcionario.fun_unin_id}`, dados, this.getToken())
          const { data: agendas } = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAula/byUnidade/periodo/${funcionario.fun_unin_id}`, periodo, this.getToken())

          this.setState({
            aulasAgendadas:{
              list: data
            },
            professoresUnidade:{
              list: professores
            },
            afastamentos: {
              list: afastamentos
            },
            agendasUni:{
              list: agendas
            },
            modalTrocaProfessoresAulas: true,
            professorSelecionado: funcionario.professor
          })

          return

        } else {
          this.salvarAusencia()
        }
  
  
      } catch ({ response }) {
        this.setState({
          loadingSalvar: false
        })
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }
  
        this.handleOpenDialog({})
      }
    }else{
      this.salvarAusencia()
    }
  }

  async verificaAulasCancelamento() {
    this.setState({
      verificaAulas: false,
      loadingSalvar: true
    })

    const funcionario = this.props.funcionario

    if(funcionario.professor){
      try {
        const { ausenciaRemove } = this.state

        if(ausenciaRemove.remarcar){
          const dados = {
            data_inicial: ausenciaRemove.afafun_data_inicial + ' ' + ausenciaRemove.afafun_hora_inicial,
            data_final: ausenciaRemove.afafun_data_final_nova + ' ' + ausenciaRemove.afafun_hora_final_nova
          }
  
          const periodo = {
            data_inicial: moment(ausenciaRemove.afafun_data_inicial).format('YYYY-MM-DD'),
            data_final: moment(ausenciaRemove.afafun_data_final_nova).format('YYYY-MM-DD'),
          }
    
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAula/byProfessor/periodo/${funcionario.professor.prof_id}`, dados, this.getToken())
  
          if (data.length > 0) {
            const { data: professores } = await axios.get(`${window._env_.REACT_APP_API_URL}/professores/byUnidade/${funcionario.fun_unin_id}`, this.getToken())
            const { data: afastamentos } = await axios.post(`${window._env_.REACT_APP_API_URL}/afastamentoFuncionario/periodo/${funcionario.fun_unin_id}`, dados, this.getToken())
            const { data: agendas } = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAula/byUnidade/periodo/${funcionario.fun_unin_id}`, periodo, this.getToken())
  
            this.setState({
              aulasAgendadas:{
                list: data
              },
              professoresUnidade:{
                list: professores
              },
              afastamentos: {
                list: afastamentos
              },
              agendasUni:{
                list: agendas
              },
              modalTrocaProfessoresAulas: true,
              professorSelecionado: funcionario.professor
            })
  
            return
          } else {
            this.confirmarCancelamento()
          }
        }else{
          this.confirmarCancelamento()
        }
  
      } catch ({ response }) {
        this.setState({
          loadingSalvar: false
        })
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }
  
        this.handleOpenDialog({})
      }
    }else{
      this.confirmarCancelamento()
    }

  }

  async confirmarCancelamento() {
    let { ausenciaRemove } = this.state

    try {

      let date1 = new Date(ausenciaRemove.afafun_data_inicial); 
      let date2 = new Date(ausenciaRemove.afafun_data_final); 

      if (date1.valueOf() > date2.valueOf()) {
        this.setState({
          loadingSalvar: false
        })

        this.handleOpenDialog({
          titulo: 'Erro Cadastro',
          descricao: 'A data final não pode ser menor que a data inicial!'
        })
        return
      }

      ausenciaRemove.afafun_data_final_nova = ausenciaRemove.afafun_data_final_nova + ' ' + ausenciaRemove.afafun_hora_final_nova

      await axios.put(`${window._env_.REACT_APP_API_URL}/afastamentoFuncionario/remarcar/${ausenciaRemove.afafun_id}`, ausenciaRemove, this.getToken())

      this.backPage()

    } catch ({ response }) {
      this.setState({
        loadingSalvar: false
      })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  async salvarAusencia() {
    try {
      let { ausencia } = this.state

      ausencia.afafun_data_inicial = ausencia.afafun_data_inicial + ' ' + ausencia.afafun_hora_inicial
      ausencia.afafun_data_final = ausencia.afafun_data_final + ' ' + ausencia.afafun_hora_final

      let date1 = new Date(ausencia.afafun_data_inicial); 
      let date2 = new Date(ausencia.afafun_data_final); 

      if (date1.valueOf() > date2.valueOf()) {
        this.setState({
          loadingSalvar: false
        })
        this.handleOpenDialog({
          titulo: 'Erro Cadastro',
          descricao: 'A data final não pode ser menor que a data inicial!'
        })
        return
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/afastamentoFuncionario`, ausencia, this.getToken())

      this.setState({
        loadingSalvar: false
      })

      this.backPage()

    } catch ({ response }) {
      this.setState({
        loadingSalvar: false
      })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  updateFieldRemarcar() {
    const { ausenciaRemove } = this.state
    ausenciaRemove.remarcar = !ausenciaRemove.remarcar
    this.setState({ ausenciaRemove })
  }

  async desativar(value) {
    const { ausencias } = this.state

    const ausencia = ausencias.list.filter(param => param.afafun_id === value._id)[0]

    const ausenciaRemove = {
      afafun_id: ausencia.afafun_id,
      afafun_fun_id: ausencia.afafun_fun_id,
      afafun_data_inicial_form: moment(ausencia.afafun_data_inicial).format('DD/MM/YYYY HH:mm'),
      afafun_data_final_form: moment(ausencia.afafun_data_final).format('DD/MM/YYYY HH:mm'),
      afafun_data_inicial: moment(ausencia.afafun_data_inicial).format('YYYY-MM-DD'),
      afafun_hora_inicial: moment(ausencia.afafun_data_inicial).format('HH:mm'),
      afafun_data_final: moment(ausencia.afafun_data_final).format('YYYY-MM-DD'),
      afafun_data_final_nova: moment(ausencia.afafun_data_final).format('YYYY-MM-DD'),
      afafun_hora_final: moment(ausencia.afafun_data_final).format('HH:mm'),
      afafun_hora_final_nova: moment(ausencia.afafun_data_final).format('HH:mm'),
      afafun_motivo: ausencia.afafun_motivo,
      remarcar: false
    }

    this.setState({
      ausenciaRemove,
      modalCancelamento: true
    })
  }

  updateData(event) {
    const { ausenciaRemove } = this.state
    ausenciaRemove[event.target.name] = event.target.value
    this.setState({ ausenciaRemove })
  }

  calcDifDias(data_inicial, data_final) {
    const inicio = moment(data_inicial);
    const fim = moment(data_final);
    const duration = moment.duration(fim.diff(inicio));

    // Mostra a diferença em dias
    return duration.asDays();
  }

  handleCloseModalTrocaProfessoresAulas(){
    this.setState({
      modalTrocaProfessoresAulas: false,
      loadingSalvar: false
    })
  }

  async getProfDisp(aulaSelc, dataAula, unidade, agenda){
    const {afastamentos, agendasUni} = this.state

    try {
      const { data: professores } = await axios.get(`${window._env_.REACT_APP_API_URL}/professores/byUnidadeByAula/${unidade}/${aulaSelc}`, this.getToken())

      let index = null
      const rows = []
      const diaSemana = moment(dataAula).format("dddd").replace("-feira", "").toUpperCase()
      const agendasUnidade = agendasUni.list
      const aula = agenda

      for (let i = 0; i < professores.length; i++) {
        const hora = moment(dataAula).format('HH:mm')
        let horaForm = hora.replace(":", "")
        horaForm = parseInt(horaForm)
        let horaMais = hora.split(":")
        horaMais = parseInt(horaMais[0]) + 1
        horaMais = horaMais < 10 ? `0${horaMais}` : horaMais
        horaMais = horaMais + ':00'
        let horaMenos = hora.split(":")
        horaMenos = parseInt(horaMenos[0]) - 1
        horaMenos = horaMenos < 10 ? `0${horaMenos}` : horaMenos
        horaMenos = horaMenos + ':00'

        let indexAgenda = agendasUnidade.findIndex(agendaUni => {
          const dataHoje = new Date()
          if (dataHoje.getTimezoneOffset() === 240) {
            let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
            dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
            if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
              return true
            } else {
              return false
            }
          } else {
            if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
              return true
            } else {
              return false
            }
          }
        })

        if (aula.aula_duracao === 2) {
          if (indexAgenda === -1) {
            const indexAgendaAnterior = agendasUnidade.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)
  
            if (indexAgendaAnterior !== -1) {
              if (agendasUnidade[indexAgendaAnterior].ageaula_duracao_aula === 2) {
                indexAgenda = agendasUnidade.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)
              }
            }
          }
        }

       

        if (indexAgenda === -1) {
          if (aula.aula_duracao === 2) {
            indexAgenda = agendasUnidade.findIndex(agendaUni => {
              const dataHoje = new Date()
              if (dataHoje.getTimezoneOffset() === 240) {
                let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
                dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
                if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD HH:mm")} ${horaMais}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                  return true
                } else {
                  return false
                }
              } else {
                if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD HH:mm")} ${horaMais}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                  return true
                } else {
                  return false
                }
              }
            })

            if (indexAgenda === -1) {
              indexAgenda = agendasUnidade.findIndex(agendaUni => {
                const dataHoje = new Date()
                if (dataHoje.getTimezoneOffset() === 240) {
                  let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
                  dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
                  if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                    return true
                  } else {
                    return false
                  }
                }
              })
            }
          }
        }

        if (indexAgenda === -1) {
          index = professores[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === horaForm)
  
          if (aula.aula_duracao === 2) {
            index = professores[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === (horaForm + 100))
          }
  
          if (index !== -1) {

            const indexAusencia = afastamentos.list.findIndex(param => {
              if (new Date(`${moment(dataAula).format("YYYY-MM-DD")} ${hora}`).valueOf() <= new Date(param.afafun_data_final).valueOf() && new Date(`${moment(dataAula).format("YYYY-MM-DD")} ${hora}`).valueOf() >= new Date(param.afafun_data_inicial).valueOf() && param.afafun_fun_id === professores[i].funcionario.fun_id) {
                return true
              }else{
                return false
              }
            })

            if (indexAusencia === -1) {
              if (agenda.ageaula_professor_id !== professores[i].prof_id) {
                if (!professores[i].disponibilidades[index].disp_data_vig_fim && !professores[i].disponibilidades[index].disp_data_vig_inicio) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (Number(new Date(professores[i].disponibilidades[index].disp_data_vig_fim)) > Number(new Date(dataAula)) && Number(new Date(professores[i].disponibilidades[index].disp_data_vig_inicio)) < Number(new Date(dataAula))) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (Number(new Date(professores[i].disponibilidades[index].disp_data_vig_fim)) > Number(new Date(dataAula)) && !professores[i].disponibilidades[index].disp_data_vig_inicio) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (!professores[i].disponibilidades[index].disp_data_vig_fim && Number(new Date(professores[i].disponibilidades[index].disp_data_vig_inicio)) < Number(new Date(dataAula))) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                }
              }
            }
  
          }
        }
      }

      this.setState({
        rowProfessores: rows
      })

    } catch (error) {
      console.log(error)
    }
  }

  limpaProfessor() {
    this.setState({
      rowProfessores: []
    })
  }

  updateFieldProfessor(event, row){
    const {aulasAgendadas, professoresUnidade} = this.state

    const index = aulasAgendadas.list.findIndex(param => param.ageaula_id === row.ageaula_id)
    const indexProf = professoresUnidade.list.findIndex(param => param.prof_id === parseInt(event.target.value))

    aulasAgendadas.list[index].ageaula_professor_id = parseInt(event.target.value)
    aulasAgendadas.list[index].professor.prof_id = parseInt(event.target.value)
    aulasAgendadas.list[index].professor.funcionario.fisica.pesf_nome = professoresUnidade.list[indexProf].pesf_nome

    this.setState({
      aulasAgendadas
    })

  }

  render() {
    const { ausencia, ausencias } = this.state
    const funcionario = this.props.funcionario
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Afastamentos...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            {funcionario.fun_status && 
              <div className='cardEndereco'>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12}>
                    <h5 className="titulo">Novo Afastamento</h5>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField InputProps={{ inputProps: { max: moment(ausencia.afafun_data_final).format('YYYY-MM-DD') } }} className="input" label="Data Inicial" type='date' variant="outlined" size="small" name="afafun_data_inicial" value={ausencia.afafun_data_inicial} onChange={(e) => this.updateAusencia(e)} onBlur={(e) => this.calcDias(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={1} xs={12} sm={6}>
                    <TextField className="input" label="Hora" type='time' variant="outlined" size="small" name="afafun_hora_inicial" value={ausencia.afafun_hora_inicial} onChange={(e) => this.updateAusencia(e)} onBlur={(e) => this.calcDias(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField InputProps={{ inputProps: { min: moment(ausencia.afafun_data_inicial).format('YYYY-MM-DD') } }} className="input" label="Data Final" type='date' variant="outlined" size="small" name="afafun_data_final" value={ausencia.afafun_data_final} onChange={(e) => this.updateAusencia(e)} onBlur={(e) => this.calcDias(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={1} xs={12} sm={6}>
                    <TextField className="input" label="Hora" type='time' variant="outlined" size="small" name="afafun_hora_final" value={ausencia.afafun_hora_final} onChange={(e) => this.updateAusencia(e)} onBlur={(e) => this.calcDias(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField className="input" label="Motivo" variant="outlined" size="small" name="afafun_motivo" value={ausencia.afafun_motivo} onChange={(e) => this.updateAusencia(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.verificaAulas(e)} disabled={this.state.loadingSalvar}>Salvar</Button>
                  </Grid>
                </Grid>
              </div>
            }
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <Table
                  headCell={this.state.cabecalhoTabela}
                  rows={ausencias.list.map(ausencia => {
                    return {
                      _id: ausencia.afafun_id,
                      afafun_data_inicial: moment(ausencia.afafun_data_inicial).format('DD/MM/YYYY HH:mm'),
                      afafun_data_final: moment(ausencia.afafun_data_final).format('DD/MM/YYYY HH:mm'),
                      afafun_motivo: ausencia.afafun_motivo,
                      ativo: ausencia.afafun_ativo,
                      qtd_dias: this.calcDifDias(ausencia.afafun_data_inicial, ausencia.afafun_data_final) + 1,
                      afafun_criado_em: moment(ausencia.afafun_criado_em).format('DD/MM/YYYY HH:mm'),
                      criado_por: ausencia.usuario.pessoa.fisica ? ausencia.usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração',
                      criado_em_order: moment(ausencia.afafun_criado_em).format('YYYYMMDDHH:mm'),
                      data_inicial_order: moment(ausencia.afafun_data_inicial).format('YYYYMMDDHH:mm'),
                      data_final_order: moment(ausencia.afafun_data_final).format('YYYYMMDDHH:mm'),
                    }
                  })}
                  acoes={this.state.acoesTabela}
                  imprimir={e => this.imprimir(e)}
                  desativar={e => this.desativar(e)} />
              </Grid>
            </Grid>
          </div>
        }

        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        <ModalCancelamentoAfastamento
          open={this.state.modalCancelamento}
          handleClose={e => this.handleCloseCancelamento(e)}
          dados={this.state.ausenciaRemove}
          updateFieldRemarcar={e => this.updateFieldRemarcar(e)}
          confirmar={e => this.verificaAulasCancelamento(e)}
          voltar={e => this.handleCloseCancelamento(e)}
          updateData={e => this.updateData(e)} />

        <ModalAulasPeriodo
          open={this.state.modalAulasPeriodo}
          handleClose={e => this.handleCloseModalAulasPeriodo(e)}
          confirmar={e => this.salvarAusencia(e)}
          voltar={e => this.handleCloseModalAulasPeriodo(e)}
          dados={this.state.agendas.list}
        />

      </React.Fragment>
    )
  }
}