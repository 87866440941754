module.exports = (arr) => {
  // Objeto para armazenar as contagens de cada valor
  const contagem = {};

  // Itera pelo array e conta as ocorrências de cada valor
  arr.forEach(valor => {
      if (contagem[valor]) {
          contagem[valor]++;
      } else {
          contagem[valor] = 1;
      }
  });

  // Transforma o objeto de contagem em um array de objetos
  const resultado = Object.keys(contagem).map(chave => {
      return { valor: chave, quantidade: contagem[chave] };
  });

  return resultado;
}