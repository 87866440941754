import React, { useState, useEffect } from 'react';

function Countdown({ targetDate }) {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval} style={{fontSize: 18}}>
        {(interval === 'minutes' || interval === 'seconds') ? (timeLeft[interval] < 10 ? '0'+timeLeft[interval] : timeLeft[interval]) :timeLeft[interval] }{interval === 'minutes' ? ':' :" "}
      </span>
    );

    console.log(timerComponents)
  });

  return (
    <b>{timerComponents.length ? timerComponents : <span>Pix Expirado!</span>}</b>
  );
}

export default Countdown;
