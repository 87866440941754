import React from 'react'
import { Grid, TextField, FormControlLabel, Switch } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '../../components/Table'

const cabecalhoTabela = [
  { id: 'versao', numeric: false, disablePadding: true, label: 'Versão' },
  { id: 'publicado', numeric: false, disablePadding: true, label: 'Publicado' },
  { id: 'datainicio', numeric: false, disablePadding: true, label: 'Data de Inicio' },
  { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' },
  { id: 'congelado', numeric: false, disablePadding: true, label: 'Congelado' }
]

const acoesTabela = [ 'imprimirDocVers', 'updateVersaoDoc', 'copy']

const FormDocumento = ({ dados, updateField, updateFieldAssinarDocumento, cursos, updateFieldCurso, templates,
  updateFieldPublicado, update, editarVersao, copiarVersao, updateVersao, congelado, unidades, unidadesSelecionadas,
  cursosSelecionadas,imprimirDoc, updateFieldUnidade }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={4}>
        <TextField 
          className="input"
          label="Titulo do Documento*"
          variant="outlined"
          name="titulo"
          value={dados.titulo}
          size="small"
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={congelado}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={3}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="tipo"
          size="small"
          value={dados.tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={congelado}
        >
          <option value=""> Selecionar Tipo </option>
          <option value="EMBRANCO"> Em Branco </option>
          <option value="PREENCHIDO"> Preenchido </option>
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <Autocomplete
          multiple
          limitTags={3}
          id="multiple-limit-tags"
          options={unidades.filter(unidade => {
            if (unidadesSelecionadas.findIndex(i => i.id === unidade.id) === -1)
              return unidade;
            return false
          })}
          size="small"
          value={unidadesSelecionadas}
          onChange={(event, value) => updateFieldUnidade(event, value)}
          getOptionLabel={(option) => option.descricao}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Unidades*" InputLabelProps={{ shrink: true}} />
          )}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="assinar"
              color="primary"
              checked={dados.assinar}
            />
          }
          size="small"
          label='Assinar Documento?'
          name="assinar"
          onChange={(e) => updateFieldAssinarDocumento(e)}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} style={{marginTop: '-20px'}}>
      <Grid item md={6} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Template*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          size="small"
          name="tempvar_id"
          value={dados.tempvar_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={congelado}
        >
          <option value=""> Selecionar Template </option>
          {templates.map(template => {
            return (
              <option key={template.id} value={template.id}> {template.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
    </Grid>

    <hr className="mg_top_10"/>

    {update &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h4>Versões:</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <Table 
              copiarVersao={e => copiarVersao(e)} 
              editarVersao={e => editarVersao(e)}
              headCell={cabecalhoTabela} 
              rows={dados.versoes} 
              acoes={acoesTabela} 
              imprimir={e => imprimirDoc(e)}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    }

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={3}>
        <TextField 
          className="input" 
          label="Versão*" 
          variant="outlined"
          name="versao" 
          size="small"
          value={dados.versao} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
          disabled={updateVersao} 
        />
      </Grid>
      {dados.publicado &&
        <Grid item md={3} xs={12} sm={3}>
          <TextField type="date" className="input" label="Data de Início*" variant="outlined" size="small" name="datainicio" value={dados.datainicio} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={true} />
        </Grid>
      }
      <Grid item md={2} xs={12} sm={3}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="publicado"
              color="primary"
              checked={dados.publicado}
            />
          }
          size="small"
          label={dados.publicado ? 'Publicado' : 'Publicado'}
          name="publicado"
          onChange={(e) => updateFieldPublicado(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormDocumento