import React, { Component } from 'react'
import './Ambiente.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Alert from './../../components/templates/Alert'
import { Grid, Button } from '@mui/material'
import Table from '../../components/Table'
import ModalCadastro from './../../components/modals/Cadastro/Ambiente'
import axios from 'axios'
import ModalAjuda from './../../components/modals/Ajuda'
import ModalAjudaCadastro from './../../components/modals/Ajuda'

const initialState = {
  loading: true,
  mensagemLoading: 'Carregando Ambientes...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  ambientes:{
    list: []
  },
  cabecalhoTabela: [
    { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
    // { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'ordem', numeric: false, disablePadding: true, label: 'Ordem' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  openModalCadastro: false,
  ambiente:{
    id: '',
    nome: '',
    descricao: '',
    unidade_id: '',
    ordem: 0,
    ativo: true
  },
  loadingSalvar:false,
  alterar: false,
  unidades: {
    list: []
  },
  openModalAjuda: false,
  markdown: '',
  openModalAjudaCadastro: false,
  markdownCadastro: ''
}

export default class AgendaAmbiente extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const helpPathCadastro = require("./../../help/ambiente/Cadastro.md")

    fetch(helpPathCadastro)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdownCadastro: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'ambientes')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/ambiente/list`, dados, this.getToken())

      const ambientes = []

      data.sort((a, b) => (a.ordem > b.ordem) ? 1 : (b.ordem > a.ordem) ? -1 : 0).forEach(ambiente => {
        ambientes.push({
          id: ambiente.id,
          nome: ambiente.nome,
          descricao: ambiente.descricao,
          unidade: ambiente.unidade.numero + ' - ' + ambiente.unidade.descricao,
          unidade_id: ambiente.unidade_id,
          ordem: ambiente.ordem,
          ativo: ambiente.ativo
        })
      })

      this.setState({
        ambientes: {
          list: ambientes
        }
      })

      const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.filter(param => param.tipo === 'UNIDADE').sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
            let descricao = ''
            descricao = `${unidade.numero} - ${unidade.descricao}`
          
            return {
              id: unidade.id,
              descricao,
              tipo: unidade.tipo
            }

          })
        }
      })

      if(unidades.length === 1){
        const {ambiente} = this.state
        ambiente.unidade_id = unidades[0].id
        this.setState({ambiente})
      }
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({loading: false})
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  openCadastro(){
    this.setState({
      openModalCadastro: true,
      ambiente:{
        id: '',
        nome: '',
        descricao: '',
        unidade_id: this.state.ambiente.unidade_id,
        ordem: 0,
        ativo: true
      }
    })
  }

  updateFieldAmbiente(event){
    const {ambiente} = this.state
    ambiente[event.target.name] = event.target.value
    this.setState({ambiente})
  }

  updateFieldAtivo(){
    const {ambiente} = this.state
    ambiente.ativo = !ambiente.ativo
    this.setState({ambiente})
  }

  handleCloseModalCadastro(){
    this.setState({
      openModalCadastro: false,
      ambiente:{
        id: '',
        nome: '',
        descricao: '',
        unidade_id: this.state.ambiente.unidade_id,
        ordem: 0,
        ativo: true
      }
    })
  }

  async salvarAmbiente(value){

    if(value){
      const {ambiente, ambientes, unidades} = this.state

      this.setState({loadingSalvar: true})
  
      if(ambiente.unidade_id === ""){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a unidade do ambiente!'
          },
          loadingSalva: false
        })
  
        // document.getElementById('ambiente').focus();
        return
      }
  
      if(ambiente.nome === ""){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o nome do ambiente!'
          },
          loadingSalva: false
        })
  
        document.getElementById('ambiente').focus();
        return
      }
  
      try {
        
        if(ambiente.id !== ''){
          delete ambiente.unidade
  
          await axios.put(`${window._env_.REACT_APP_API_URL}/ambiente/${ambiente.id}`, ambiente, this.getToken())
        
          ambientes.list[ambientes.list.findIndex(param => param.id === ambiente.id)] = {
            id: ambiente.id,
            nome: ambiente.nome,
            descricao: ambiente.descricao,
            unidade: unidades.list.filter(param => param.id === ambiente.unidade_id)[0].descricao,
            unidade_id: ambiente.unidade_id,
            ordem: ambiente.ordem,
            ativo: ambiente.ativo
          }
        }else{
          delete ambiente.id
  
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/ambiente`, ambiente, this.getToken())
          
          // ambiente.id = ambientes.list.length + 1
          ambientes.list.push({
            id: data.id,
            nome: data.nome,
            descricao: data.descricao,
            unidade: unidades.list.filter(param => param.id === data.unidade_id)[0].descricao,
            unidade_id: data.unidade_id,
            ordem: data.ordem,
            ativo: data.ativo
          })
        }
      
        this.setState({
          ambientes: {
            list: ambientes.list.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0),
          },
          alerta: {
            open: true,
            severity: 'success',
            message: ambiente.id !== '' ? 'Cadastro atualizado com sucesso' : 'Cadastro realizado com sucesso.'
          },
          loadingSalvar: false
        })
  
        this.handleCloseModalCadastro()
  
      } catch (error) {
        console.log(error)
        this.setState({
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        })
      }
    }else{
      this.handleCloseModalCadastro()
    }
  }

  editarAmbiente(row){
    this.setState({
      ambiente: {...row},
      openModalCadastro: true,
      alterar: true
    })
  }

  render() {
    const { permissoes, ambientes } = this.state

    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid container spacing={1} direction="row" className="borderBottom">
                  <Grid item md={10} xs={12} sm={8}>
                    <h1 className="titulo">Ambientes/Salas</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={2} xs={12} sm={4}>
                      <Button onClick={() => this.openCadastro()} size="small" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Ambientes
                      </Button>
                    </Grid>
                  }
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_20">
                  <Grid item md={12} xs={12}>
                    <Table 
                      urlUpdate={(e) => this.editarAmbiente(e)}
                      headCell={this.state.cabecalhoTabela} 
                      rows={ambientes.list} 
                      acoes={this.state.acoesTabela} 
                    />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} 
            />

            <ModalCadastro
              open={this.state.openModalCadastro}
              handleClose={e => this.handleCloseModalCadastro()} 
              ambiente={this.state.ambiente}
              updateField={(e) => this.updateFieldAmbiente(e)}
              updateFieldAtivo={(e) => this.updateFieldAtivo(e)}
              confirmar={(e) => this.salvarAmbiente(e)}
              unidades={this.state.unidades.list}
              alterar={this.state.alterar}
              openModalAjudaCadastro={() => {this.setState({openModalAjudaCadastro: true})}}
            />

          </div>
        }
        <Loading
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Ambientes/Salas"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
        <ModalAjudaCadastro 
          open={this.state.openModalAjudaCadastro}
          tela={"Cadastro de Ambientes/Salas"}
          handleClose={() => {this.setState({openModalAjudaCadastro: false})}}
          markdown={this.state.markdownCadastro}
        />
      </React.Fragment>
    )
  }
}