import React, { Component } from 'react'
import './Estoque.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'


import FormEstoqueProduto from '../../components/forms/FormEstoqueProduto'

import { Grid, Button, TextField } from '@mui/material'

import SaveIcon from '@mui/icons-material/Save';

import ModalErro from './../../components/modals/Erro'

import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'

const initialState = {
  estoque: {
    _id: "",
    idProduto: "",
    produto: "",
    idLocalEstoque: "",
    localEstoque: "",
    idUnidade: "",
    unidade: "",
    estoqueMinimo: "",
    estoqueMaximo: "",
    estoqueAtual: "",
    precoEstoqueAtual: "",
    grupoProduto: "",
    idGrupoProduto: "",
    subGrupoProduto: "",
    idSubGrupoProduto: ""
  },
  produto: {
    _id: "",
    descricao: "",
    ativo: true,
    grupoProduto: "",
    idGrupoProduto: "",
    subGrupoProduto: "",
    idSubGrupoProduto: "",
    unidadeMedida: "",
    visibilidade: "",
  },
  estoques: {
    list: []
  },
  grupoProdutos: {
    list: []
  },
  subGrupoProdutos: {
    list: []
  },
  subGrupoProdutosFiltrado: {
    list: []
  },
  produtosFiltrado: {
    list: []
  },
  localEstoques: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  unidades: {
    list: []
  },
  localEstoquesFiltrado: {
    list: []
  },
  loading: true,
  loadingSalvar: false
}

export default class CadastroEstoque extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const { match: { params } } = this.props;
    if (params.estoqueProdutoId) {
      try {
        const { data: estoqueProduto } = await axios.get(`${window._env_.REACT_APP_API_URL}/estoqueProduto/${params.estoqueProdutoId}`, this.getToken())

        var estoqueProdutoAux = {
          _id: estoqueProduto.id,
          idProduto: estoqueProduto.produtoid,
          produto: estoqueProduto.produto.descricao,
          idLocalEstoque: estoqueProduto.localestoqid,
          localEstoque: estoqueProduto.localestoque.descricao,
          idUnidade: estoqueProduto.localestoque.unidade.id,
          unidade: estoqueProduto.localestoque.unidade.descricao,
          estoqueMinimo: estoqueProduto.estoqueminimo,
          estoqueMaximo: estoqueProduto.estoquemaximo,
          estoqueAtual: estoqueProduto.estoqueatual,
          precoEstoqueAtual: estoqueProduto.custoestoqueatual,
          grupoProduto: estoqueProduto.produto.subgrupoproduto.grupoproduto.descricao,
          idGrupoProduto: estoqueProduto.produto.subgrupoproduto.grupoproduto.id,
          subGrupoProduto: estoqueProduto.produto.subgrupoproduto.descricao,
          idSubGrupoProduto: estoqueProduto.produto.subgrupoproduto.id
        }

        this.setState({
          estoque: estoqueProdutoAux
        })
      } catch (error) {
        console.log(error);
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o produto no estoque, tente mais tarde"
        })

        this.backPage()
      }
    }

    if (params.produtoId) {
      try {
        const { data: produto } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/${params.produtoId}`, this.getToken())

        this.setState({
          produto: {
            _id: produto.id,
            descricao: produto.descricao,
            ativo: produto.ativo,
            grupoProduto: produto.subgrupoproduto.grupoproduto.descricao,
            idGrupoProduto: produto.subgrupoproduto.grupoproduto.id,
            subGrupoProduto: produto.subgrupoproduto.descricao,
            idSubGrupoProduto: produto.subgrupoproduto.id,
            unidadeMedida: produto.unidademedida,
            visibilidade: produto.visibilidade,
            idMidia: produto.idmidia
          }
        })
      } catch (error) {
        console.log(error)
        this.setState({
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        })
      }
    }

    // const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    // const dados = {
    //   unidadesnegocio: acesso_atual.map(acesso => {
    //     return acesso.id
    //   })
    // }

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/localEstoque/list`, {}, this.getToken())

      let localEstoques = []

      data.forEach(localEstoque => {
        localEstoques.push({
          _id: localEstoque.id,
          descricao: localEstoque.descricao,
          unidade: localEstoque.unidade.descricao,
          idUnidade: localEstoque.unidade.id,
          franqueado: localEstoque.unidade.superior.pessoa.tipo === 'FISICA' ? localEstoque.unidade.superior.pessoa.fisica.nome : localEstoque.unidade.superior.pessoa.juridica.razaosocial,
        })
      })

      this.setState({
        localEstoques: {
          list: localEstoques
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())

      let unidades = []

      data.forEach(unidade => {
        const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.principal)

        unidades.push({
          _id: unidade.id,
          numero: unidade.numero,
          descricao: unidade.descricao,
          franqueado: unidade.superior.pessoa.tipo === "FISICA" ? unidade.superior.pessoa.fisica.nome : unidade.superior.pessoa.juridica.razaosocial,
          cidade: `${enderecoPrincipal[0].cidade} - ${enderecoPrincipal[0].estado}`,
          tipo: unidade.pessoa.tipo,
          ativo: unidade.status
        })
      });

      this.setState({
        unidades: {
          list: unidades
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  updateField(event) {

    const estoque = { ...this.state.estoque }

    if (event.target.name === "idUnidade") {
      const localEstoques = this.state.localEstoques.list.filter(u => u.idUnidade === parseInt(event.target.value))

      if (localEstoques.length > 0) {
        this.setState({
          localEstoquesFiltrado: {
            list: localEstoques
          }
        })

        estoque.unidade = localEstoques[0].unidade
      } else {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: "Essa Unidade ainda não possui Local de Estoque Cadastrado"
        })
        return
      }

    }

    if (event.target.name === "idLocalEstoque") {
      const localEstoque = this.state.localEstoquesFiltrado.list.filter(u => u._id === parseInt(event.target.value))

      estoque.localEstoque = localEstoque[0].descricao
    }

    estoque[event.target.name] = event.target.value
    this.setState({ estoque })
  }

  validateForm() {
    const { estoque, produto } = this.state

    if (!produto._id) return false
    if (!estoque.idLocalEstoque) return false
    if (!estoque.estoqueMinimo) return false
    if (!estoque.estoqueMaximo) return false

    return true
  }

  async salvar() {
    const { estoque, produto } = this.state

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const data = {
      produtoid: produto._id,
      localestoqid: estoque.idLocalEstoque,
      estoqueminimo: estoque.estoqueMinimo,
      estoquemaximo: estoque.estoqueMaximo
    }

    try {
      if (estoque._id !== "") {
        await axios.put(`${window._env_.REACT_APP_API_URL}/estoqueProduto/${estoque._id}`, data, this.getToken())
      } else {
        await axios.post(`${window._env_.REACT_APP_API_URL}/estoqueProduto`, data, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()
    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      // navigate("/produtos");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { estoque, produto } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }{!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11}>
                  <h1 className="titulo">Cadastro de Estoque</h1>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Produto" value={produto.descricao} disabled />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Sub Grupo Produto" value={produto.subGrupoProduto} disabled />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Grupo Produto" value={produto.grupoProduto} disabled />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={6} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="idUnidade"
                    value={estoque.idUnidade}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <option value={estoque.idUnidade === "" ? "" : estoque.idUnidade}> {estoque.idUnidade === "" ? "Selecionar Unidade" : estoque.unidade} </option>
                    {this.state.unidades.list.map(unidade => {
                      return (
                        <option key={unidade._id} value={unidade._id}> {unidade.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Local de Estoque"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="idLocalEstoque"
                    value={estoque.idLocalEstoque}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <option value={estoque.idLocalEstoque === "" ? "" : estoque.idLocalEstoque}> {estoque.idLocalEstoque === "" ? "Selecionar Local de Estoque" : estoque.localEstoque} </option>
                    {this.state.localEstoquesFiltrado.list.map(localEstoque => {
                      return (
                        <option key={localEstoque._id} value={localEstoque._id}> {localEstoque.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <FormEstoqueProduto updateField={e => this.updateField(e)} dados={estoque} />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
         <Nav history={this.props.history}/>
      </div>
    )
  }
}