import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, IconButton, Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '../../components/TableV2'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'flags', numeric: false, disablePadding: false, label: 'Flags' },
  { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo Pessoa' },
  { id: 'cpf_cnpj', numeric: false, disablePadding: false, label: 'CPF' },
  { id: 'numero', numeric: false, disablePadding: false, label: 'Telefone' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' },
]

const acoesTabela = ['pegarPessoa']

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
        <Grid container direction="row" spacing={1} className="borderBottom">
          <Grid item md={11} xs={10} sm={10}>
            <h5 className="tituloConfirmacao">Selecionar Pessoa</h5>
          </Grid>
          {props.loadingPessoas && 
            <Grid item md={1} xs={2} sm={2}>
              <CircularProgress />
            </Grid>
          }
        </Grid>
        <br />
        <Grid container direction="row" spacing={1}>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Filtrar por"
              variant="outlined"
              // className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="filtrar_por"
              value={props.filtro.filtrar_por}
              onChangeCapture={(e) => props.updateFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="Nome - nome"> Nome / Nome Fantasia </option>
              <option value="CPF - cpf_cnpj"> CPF / CNPJ </option>
              <option value="Telefone - telefone"> Telefone </option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField className="input" label={props.filtrocliente.label} variant="outlined" size="small" name='value' value={props.filtro.value} onChange={(e) => props.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
          </Grid>
          {!props.desabilitaFlag &&
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Flag"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="flag"
                value={props.filtro.flag}
                onChangeCapture={(e) => props.updateFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> </option>
                <option value="CLIENTE"> Cliente</option>
                <option value="FORNECEDOR"> Fornecedor(a)</option>
                <option value="REVENDEDOR"> Revendedor(a)</option>
                <option value="FUNCIONARIO"> Funcionario</option>
              </TextField>
            </Grid>
          }
          <Grid item md={2} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Ativo"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="ativo"
              value={props.filtro.ativo}
              onChangeCapture={(e) => props.updateFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=""> </option>
              <option value='Sim'> Ativo</option>
              <option value='Não'> Inativo</option>
            </TextField>
          </Grid>
          <Grid item md={1} xs={2} sm={1}>
            <Tooltip title='Filtrar' aria-label="add">
              <IconButton size="small" color="primary" onClick={() => props.filtrarCliente()}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" className="mg_top_10">
          <Grid item md={12} xs={12}>
            <Table 
              headCell={cabecalhoTabela} 
              rows={props.pessoasFiltrado} 
              acoes={acoesTabela} 
              selecionarPessoa={ e => props.selecionarPessoa(e)}
              filtro={props.filtro}
              count={props.rows}
              handleChangePage={(event, newPage) => props.handleChangePage(event, newPage)}
              handleChangeRowsPerPage={e => props.handleChangeRowsPerPage(e)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" className="mg_top_10">
          <Grid item md={9} xs={false}></Grid>
          <Grid item md={3} xs={12}>
            <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.handleClose()}>Fechar</Button>
          </Grid>
        </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
