import React from 'react'

import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl } from '@mui/material'

const FormGrupoProdutos = ({ dados, updateField, updateFieldAtivo, unidades, tipo_unidade }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={dados.descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }}/>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Visibilidade*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="visibilidade"
          value={dados.visibilidade}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.unidade_id === ''}
        >
          {dados.visibilidade === "" &&
            <option value=""> Selecionar Visibilidade</option>
          }
          {tipo_unidade === 'MANTENEDOR' &&
            <React.Fragment>
              <option value="MANTENEDOR"> Mantenedor</option>
              <option value="REPRESENTANTE"> Representante</option>
              <option value="GRUPOECONOMICO"> Grupo Econômico</option>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
          {tipo_unidade === 'REPRESENTANTE' &&
            <React.Fragment>
              <option value="REPRESENTANTE"> Representante</option>
              <option value="GRUPOECONOMICO"> Grupo Econômico</option>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
          {tipo_unidade === 'GRUPOECONOMICO' &&
            <React.Fragment>
              <option value="GRUPOECONOMICO"> Grupo Econômico</option>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
          {tipo_unidade === 'UNIDADE' &&
            <React.Fragment>
              <option value="UNIDADE">Unidade</option>
            </React.Fragment>
          }
        </TextField>
      </Grid>
      <Grid item md={1} xs={false} sm={false}></Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Ativo' : 'Inativo'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <hr className="mag-top-10"/>
  </React.Fragment>
)

export default FormGrupoProdutos