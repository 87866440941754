import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import Table from './../Table'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),

  width: '80%', 
  marginLeft: '10%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));


export default function SimpleModal(props) {
  const theme = useTheme();
  
  const dados = props.dados
  const cabecalhoTabela = [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' }
  ]
  const acoesTabela = []

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">CANCELAMENTO DE AULAS</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p>Esse aluno possui essas aulas para esse intervalo de ausência, deseja desmarca-las?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={dados.map(agenda => {
                  return {
                    _id: agenda.ageaula_id,
                    ageaula_data_hora: moment(agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm'),
                    aula_nome: agenda.aula.aula_nome,
                    professor: agenda.professor.funcionario.fisica.pesf_nome
                  }
                })}
                acoes={acoesTabela}
                imprimir={e => this.imprimir(e)}
                desativar={e => this.desativar(e)} />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='avisoConciliacao'><i>Esta operação não poderá ser desfeita.</i></p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.voltar()}>Voltar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar()}>{dados.remarcar ? 'Remarcar' : 'Excluir'}</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
