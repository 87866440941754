import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormAmbiente from './../../forms/FormAmbiente'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  const {ambiente, alterar, unidades} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={10} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Cadastrar Ambiente</h5>
            </Grid>
          </Grid>  
          <FormAmbiente 
            dados={ambiente}
            updateField={(e) => props.updateField(e)}
            updateFieldAtivo={(e) => props.updateFieldAtivo(e)}
            unidades={unidades}
          /> 
          <Grid container direction="row" spacing={1}>
            <Grid item md={8} xs={12} sm={4}></Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="secondary" 
                startIcon={<ArrowBackIcon />}
                onClick={e => props.confirmar(false)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="primary" 
                startIcon={<SaveIcon />}
                onClick={e => props.confirmar(true)}
              >
                {alterar ? 'Alterar' : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        <Tooltip title='Ajuda' aria-label="add" arrow>
          <HelpIcon className='botao_ajuda' onClick={() => props.openModalAjudaCadastro()}/>
        </Tooltip>
        </StyledPaper>
      </Modal>
    </div>
  );
}
