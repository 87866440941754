import React, { useState, useEffect } from 'react';
import './ServicosBancarios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormServicosBancarios from '../../components/forms/FormServicosBancarios'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  servico_bancario: {
    id: "",
    descricao: "",
    ativo: true
  },
  loadingSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

const CadastroServicoBancario = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { servicoBancarioId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = (event) => {
    const servico_bancario = { ...state.servico_bancario }
    servico_bancario[event.target.name] = event.target.value

    setState(prevState => ({...prevState,  servico_bancario }))
  }

  const updateFieldAtivo = () => {
    const servico_bancario = { ...state.servico_bancario }
    servico_bancario.ativo = !servico_bancario.ativo
    setState(prevState => ({...prevState,  servico_bancario }))
  }

  const validateForm = () => {
    const { servico_bancario } = state

    if (!servico_bancario.descricao) return false

    return true
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/servicos_bancario");
    }, timeout)
  }

  const salvar = async () => {

    setState(prevState => ({...prevState,  loadingSalvar: true }))

    if (!validateForm()) {
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    const servico_bancario = state.servico_bancario

    const dados = {
      descricao: servico_bancario.descricao,
      ativo: servico_bancario.ativo
    }

    try {

      const method = servico_bancario.id !== "" ? 'put' : 'post'
      const url = servico_bancario.id !== "" ? `/servicosBancarios/${servico_bancario.id}` : `/servicosBancarios`

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState, 
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/servicosBancarios/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState, 
            markdown: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'servicos-bancario')[0]

      setState(prevState => ({...prevState, 
        permissoes
      }))

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

      setState(prevState => ({...prevState,  tipo }))

      if (servicoBancarioId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }


      if (servicoBancarioId) {
        try {

          const { data: servico_bancario } = await axios.get(`${window._env_.REACT_APP_API_URL}/servicosBancarios/${servicoBancarioId}`, getToken())

          setState(prevState => ({...prevState, 
            servico_bancario: {
              id: servico_bancario.id,
              descricao: servico_bancario.descricao,
              ativo: servico_bancario.ativo
            },
            loading: false
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
        }
      }
    };
    
    fetchData();
  }, []);

  const { servico_bancario } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
        <Grid
          container
          spacing={1}
          direction="row"
          className="borderBottom"
        >
          <Grid item md={11}>
            <h1 className="titulo">Cadastro de Serviços Bancarios</h1>
          </Grid>
          <Grid item md={1}>
            {state.loadingSalvar &&
              <div>
                <CircularProgress />
              </div>
            }
          </Grid>
        </Grid>
        <FormServicosBancarios 
          updateField={e => updateField(e)} 
          dados={servico_bancario} 
          updateFieldAtivo={e => updateFieldAtivo()}
          />
        <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
          <Grid item md={10} xs={12} sm={6}></Grid>
          <Grid item md={2} xs={12} sm={6}>
            <Button 
              fullWidth 
              color="primary" 
              variant="contained"  
              size="small" 
              startIcon={<SaveIcon />} 
              onClick={e => salvar(e)}>Salvar</Button>
          </Grid>
        </Grid>
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Serviços Bancarios"}
        handleClose={() => {setState(prevState => ({...prevState, openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default CadastroServicoBancario