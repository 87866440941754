import React from 'react';
import Modal from '@mui/material/Modal';
import { Grid, Button, TextField, FormControl, FormLabel, FormControlLabel, Switch } from '@mui/material'
import UploadPlanilha from './../../services/uploads/UploadPlanilha'

export default function SimpleModal(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={'paperUploadPlanilha'}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Upload de Clientes</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_id"
                value={props.uploadClientes.unidade_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Unidade</option>
                {props.unidades.map((unidade, key) => {
                  return (
                    <option key={key} value={unidade.id}> {unidade.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={3} xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Informar o Contrato?</FormLabel>
                <FormControlLabel className="mg_left_10"
                  control={
                    <Switch
                      name="informar_contrato"
                      color="primary"
                      checked={props.uploadClientes.informar_contrato}
                    />
                  }
                  size="small"
                  label={props.uploadClientes.informar_contrato ? 'Sim' : 'Não'}
                  name="informar_contrato"
                  onChange={(e) => props.updateFieldInformarContrato(e)}
                />
              </FormControl>
            </Grid> 
          </Grid>
          {props.uploadClientes.informar_contrato &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={6} xs={12} sm={6}>
                  <TextField type="date"
                    className="input"
                    label="Data Inicio Vigência*"
                    variant="outlined"
                    name="data_ini_vig"
                    value={props.uploadClientes.data_ini_vig}
                    onChange={(e) => props.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade Tempo Duração"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="unidade_tempo"
                    value={props.uploadClientes.unidade_tempo}
                    disabled={props.uploadClientes.data_ini_vig === ''}
                    onChangeCapture={(e) => props.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=''> Selecionar Unidade Tempo </option>
                    <option value='DIA'> Dia </option>
                    <option value='MÊS'> Mês </option>
                    {/* <option value='SEMESTRE'> Semestre </option> */}
                    <option value='ANO'> Ano </option>
                    
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={6} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    label="Tempo Contrato" 
                    type="number"
                    variant="outlined" 
                    size="small"
                    name="tempo_contrato" 
                    value={props.uploadClientes.tempo_contrato} 
                    disabled={props.uploadClientes.unidade_tempo === ''}
                    onChange={(e) => props.updateField(e)} 
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <TextField type="date"
                    className="input"
                    label="Data Fim Vigência"
                    variant="outlined"
                    name="data_fim_vig"
                    value={props.uploadClientes.data_fim_vig}
                    disabled
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <UploadPlanilha onUpload={e => props.handleUpload(e)} />
              <span>{props.uploadedFiles[0] ? props.uploadedFiles[0].name : ''}</span>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <span className='alerta'>Por favor não alterar o formato da planilha!</span>
            </Grid>
          </Grid>
          <hr className="mg_top_10" />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={12} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.downloadPlanilha()} disabled={props.loadingSalvar}>Download Planilha</Button>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar()} disabled={props.loadingSalvar}>Salvar</Button>
            </Grid>
          </Grid>
       </div>
      </Modal>
    </div>
  );
}
