import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@mui/material'
import MaskedInput from 'react-text-mask';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'
import moment from 'moment';
import ModalErro from './../../../components/modals/Erro'
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import Tabela from './../../../components/Tabela'
import formatarTelefone from './../../../services/formatarTelefone'
import axios from 'axios'

const cpf = require('node-cpf-cnpj')
const cnpj = require('node-cpf-cnpj')

const initialState = {
  unidade: {
    pessoa:{
      tipo: 'FISICA'
    }
  },
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: [],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
}

export default class Unidade extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    const unidade = this.props.unidade

    this.setState({
      tipo,
      unidade: unidade,
      loading: false
    })
  }

  copyLink(link){
    navigator.clipboard.writeText(link);
  
    this.setState({
      alerta: {
        open: true,
        severity: 'success',
        message: 'Link copiado'
      }
    })

    return
    
  }

  async ativarIntativarUnidade(){

    try {
      const { unidade } = this.state

      await axios.put(`${window._env_.REACT_APP_API_URL}/unidades/ativarInativar/${unidade.id}`,{}, this.getToken())

      window.location.reload()

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

  }

  render() {
    const { unidade, tipo, cabecalhoTabelaEndereco, acoesTabelaEndereco, cabecalhoTabelaTelefone, cabecalhoTabelaEmail } = this.state

    console.log(unidade)

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Unidade...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&

          <div id='view'>
            <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
              <Grid item md={6} xs={12} sm={12}>
                <h1 className="titulo">Dados Unidade: </h1>
              </Grid>
              {tipo !== 'MANTENEDOR' &&
                <Grid item md={2} xs={12} sm={6}></Grid>
              }
              <Grid item md={2} xs={12} sm={6}>
                <Link to={`/unidade`}>
                  <Button size='small' fullWidth variant="contained" color="secondary">
                    Voltar
                </Button>
                </Link>
              </Grid>
              {tipo === 'MANTENEDOR' &&
                <Grid item md={2} xs={12} sm={6}>
                  <Button size='small' fullWidth variant="contained" color="warning" onClick={(e) => this.ativarIntativarUnidade(e)}>
                    {unidade.status ? 'Inativar Unidade' : 'Ativar Unidade'}
                  </Button>
                </Grid>
              }
              {tipo === 'MANTENEDOR' &&
                <Grid item md={2} xs={12} sm={6}>
                  <Link to={`/unidade/update/${unidade.id}`}>
                    <Button size='small' fullWidth variant="contained" color="primary">
                      Editar Unidade
                  </Button>
                  </Link>
                </Grid>
              }
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={2} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade" value={unidade.numero} disabled />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Descrição" value={unidade.descricao} disabled />
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Status" value={unidade.status ? 'ATIVO' : 'INATIVO'} disabled />
              </Grid>
            </Grid>
            {unidade.pessoa.tipo === "FISICA" &&
              <div>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Dados Pessoa Física: </h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={unidade.pessoa.fisica.nome} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(unidade.pessoa.fisica.cpf)} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={unidade.pessoa.fisica.rg} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(unidade.pessoa.fisica.datanascimento).format('YYYY-MM-DD')} disabled InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </div>
            }
            {unidade.pessoa.tipo === "JURIDICA" &&
              <div>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Dados Pessoa Jurídica: </h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={unidade.pessoa.juridica.razaosocial} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantasia" value={unidade.pessoa.juridica.nomefantasia} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={cnpj.format(unidade.pessoa.juridica.cnpj)} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={unidade.pessoa.juridica.inscricaoestadual} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Fundação" value={moment(unidade.pessoa.juridica.datafundacao).format('YYYY-MM-DD')} disabled InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </div>
            }
            {unidade.pessoa.enderecos.length > 0 &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Endereços: </h4>
                  </Grid>
                </Grid>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <Tabela 
                      headCell={cabecalhoTabelaEndereco} 
                      rows={unidade.pessoa.enderecos.map((endereco, index) => {
                        return {
                          cep: endereco.num_cep,
                          endereco: `${endereco.logradouro}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                          cidade: endereco.cidade,
                          estado: endereco.estado,
                          principal: endereco.principal,
                          tipoEndereco: endereco.tipo,
                          index
                        }
                      })} 
                      acoes={acoesTabelaEndereco}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {unidade.pessoa.telefones.length > 0 &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Telefones: </h4>
                  </Grid>
                </Grid>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <Tabela 
                      headCell={cabecalhoTabelaTelefone} 
                      rows={unidade.pessoa.telefones.map(contato => {
                        return {
                          numero: formatarTelefone(contato.numero),
                          tipoContato: contato.tipo,
                          principal: contato.principal,
                          index: contato.index
                        }
                      })} 
                      acoes={acoesTabelaEndereco}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {unidade.pessoa.emails.length > 0 &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">E-mails: </h4>
                  </Grid>
                </Grid>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <Tabela 
                      headCell={cabecalhoTabelaEmail} 
                      rows={unidade.pessoa.emails.map(email => {
                        return {
                          email: email.email,
                          principal: email.principal,
                          index: email.index
                        }
                      })} 
                      acoes={acoesTabelaEndereco}
                    />
                  </Grid>
                </Grid>  
              </React.Fragment>
            }
            <hr />

            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">Acessos: </h4>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={3} xs={12} sm={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Pré Cadastro de Contas" value={`${window._env_.REACT_APP_URL}/preContasPR/${unidade.id}`} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
              <Grid item md={1} xs={12} sm={12}>
                <Tooltip title='Copiar Link' aria-label="add">
                  <IconButton style={{marginTop: '-3px', marginLeft: '-10px'}} color="primary" aria-label="view" onClick={e => this.copyLink(`${window._env_.REACT_APP_URL}/preContasPR/${unidade.id}`)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item md={3} xs={12} sm={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Cadastro Externo de Cliente" value={`${window._env_.REACT_APP_URL}/cadastro_externo/${unidade.id}`} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
              <Grid item md={1} xs={12} sm={12}>
                <Tooltip title='Copiar Link' aria-label="add">
                  <IconButton style={{marginTop: '-3px', marginLeft: '-10px'}} color="primary" aria-label="view" onClick={e => this.copyLink(`${window._env_.REACT_APP_URL}/cadastro_externo/${unidade.id}`)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        }
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
      </React.Fragment>
    )
  }
}