import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl, 
  Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Table from './../Table'
import CurrencyInput from './../mask/CurrencyInput'
import real from '../../services/real';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabelaFinanceiro = [
  { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
  { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma Pgto' },
  { id: 'situacao', numeric: false, disablePadding: false, label: 'Situação' },
  { id: 'valorparcela', numeric: false, disablePadding: false, label: 'Valor' },
  { id: 'multajuros', numeric: false, disablePadding: false, label: 'Juros/Multas' },
]

const acoesTabelaFinanceiro = []

export default function SimpleModal(props) {
  const theme = useTheme();
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h6 className="tituloConfirmacao">Confirmação de Cancelamento</h6>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className='titulo'>Financeiro Pendente:</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={cabecalhoTabelaFinanceiro} 
                rows={props.financeiros_pendentes.map(parcela => {
                  return {
                    datavencimento: moment(parcela.datavencimento).format('DD/MM/YYYY'),
                    formapagto_descricao: parcela.valores_pago[0].forma_pagto.descricao,
                    situacao: parcela.situacao,
                    valorparcela: real(parcela.valorparcela),
                    multajuros: parcela.multajuros ? real(parcela.multajuros) : real(0)
                  }
                })} 
                acoes={acoesTabelaFinanceiro} />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={1}>
						<Grid item md={4} xs={12} sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Lançar Financeiro de Conciliação</FormLabel>
								<FormControlLabel className="mg_left_10"
									control={
										<Switch
											name="Ativo"
											color="primary"
											checked={dados.lanc_fin}
										/>
									}
									size="small"
									label={dados.lanc_fin ? 'Sim' : 'Não'}
									name="lanc_fin"
									onChange={(e) => props.updateFieldLancFinanceiroCancelamento(e)}
								/>
							</FormControl>
						</Grid>
					</Grid>

          {dados.lanc_fin && 
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" size="small" label="Tipo da Conta" variant="outlined" value={props.conta.tipo} InputLabelProps={{ shrink: true }} disabled/>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Item de Receita"
                    variant="outlined"
                    className="input"
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                    name="item_id"
                    size="small"
                    value={props.conta.item_id}
                    onChangeCapture={(e) => props.updateFieldConta(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">Selecionar Item de Receita</option>
                    {props.itens.map(plano => {
                      return (
                        <option value={plano.id}>{plano.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" size="small" label="Descrição*" variant="outlined" name="descricao" value={props.conta.descricao} InputLabelProps={{ shrink: true }} onChange={(e) => props.updateFieldConta(e)}/>
                </Grid>
                <Grid item md={1} xs={12} sm={6}>
                  <TextField className="input" size="small" label="Valor*" variant="outlined" name="valortotal" value={props.conta.valortotal} onChange={(e) => props.updateFieldConta(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
                <Grid item md={1} xs={12} sm={6}>
                  {/* {props.possui_emissao_nfse &&
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Gerar NFSE?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="Ativo"
                            color="primary"
                            checked={props.conta.gerar_nfse}
                          />
                        }
                        size="small"
                        label={props.conta.gerar_nfse ? 'Sim' : 'Não'}
                        name="gerar_nfse"
                        onChange={(e) => props.updateFieldGerarNfseConta(e)}
                      />
                    </FormControl>
                  } */}
                </Grid> 
              </Grid>
              {props.conta.valortotal !== '' &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <div className='scroll'>
                      <table className="lista sempadding borderTable">
                        <thead>
                          <tr className="titulos acompanha">
                            <th>Parcelas</th>
                            <th>Valor*</th>
                            <th>Vencimento*</th>
                            <th>Forma de Pagamento*</th>
                            <th>Contas Bancárias*</th>
                            <th>Documento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                            return (
                              <tr key={parcela.index}>
                                <td>
                                  <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                                  <span className="separacaoParcelas">/</span>
                                  <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={props.conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) => props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                                </td>
                                <td>
                                  <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                                </td>
                                <td>
                                  <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="datavencimento" value={parcela.datavencimento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                </td>
                                <td>
                                  <TextField
                                    id="standard-select-currency"
                                    select
                                    variant="outlined"
                                    className="inputFormPagamento"
                                    fullWidth
                                    size="small"
                                    SelectProps={{
                                      native: true,
                                    }}
                                    name="formapagto_id"
                                    value={parcela.formapagto_id}
                                    onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                    InputLabelProps={{ shrink: true }}
                                  >
                                    <option value="">--Escolha--</option>
                                    {props.formasPagamento.map(forma => {
                                      return (
                                        <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                      )
                                    })}
                                  </TextField>
                                </td>
                                <td>
                                  <Select
                                    fullWidth
                                    className={`${parcela.contfin_id !== '' ? 'selecionado' : 'nao_selecionado'} inputConta`}
                                    variant="outlined"
                                    name="contfin_id"
                                    value={parcela.contfin_id}
                                    onChange={(e) => props.updateFieldParcela(e, parcela.index)}
                                  >
                                    <MenuItem value=''>
                                      <ListItemIcon>
                                      </ListItemIcon>
                                      <ListItemText primary={'Selecionar Conta'} />
                                    </MenuItem>
                                    {props.contasFinanceira.filter(param => param.tipo_conta === parcela.habilita).map(conta => {
                                      return (
                                        <MenuItem key={conta.id} value={conta.id}>
                                          <ListItemIcon>
                                            <img style={{width: '30px', height: '30px', marginRight: '5px'}} src={conta.conta_corrente ? conta.conta_corrente.conta.banco.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt='logo'/>
                                          </ListItemIcon>
                                          <ListItemText primary={conta.descricao} />
                                        </MenuItem>
                                      )
                                    })}
                                  </Select>
                                </td>
                                <td>
                                  <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              }
              <br />
              <hr />
              <br />
           </React.Fragment>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente realizar o cancelamento?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
            <TextField
                id="outlined-multiline-static"
                label="Motivo"
                className="input"
                multiline
                rows={5}
                size="small"
                variant="outlined"
                name="motivoCancelamento"
                value={dados.motivoCancelamento}
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={dados.motivoInativar === "" ? true : false}>Sim</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div> 
  );
}
