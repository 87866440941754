import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import Alert from './../../../components/templates/Alert'
import UploadCertificado from './../../../services/uploads/UploadCertificado'
import {filesize} from "filesize";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const initialState = {
  unidade: {
    id: ''
  },
  certificado: {
    certificado_informado: false,
    senha_certificado: ''
  },
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  uploadedFiles: [],
  showPassword: false,
  loadingSalvar: false,
}

export default class Certificado extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const unidade = this.props.unidade

    try {

      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade.id}`, this.getToken())

      if (configuracao) {
        this.setState({
          certificado: {
            certificado_informado: configuracao.certificado_id ? true : false,
            senha_certificado: ''
          }
        })
      }else{
        this.setState({
          certificado: {
            certificado_informado: false,
            senha_certificado: ''
          }
        })
      }

     

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
        
    this.setState({
      loading:false,
      unidade
    })
    
  }

  handleCloseAlerta() {
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  handleUploadCertificado = files => {
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    this.setState({
      uploadedFiles
    })
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
 
  updateFieldSenha(event){
    const {certificado} = this.state

    let name = event.target.name
    let value = event.target.value

    certificado[name] = value

    this.setState({certificado})
  }

  async confirmarEmissao(){
    try {
      this.setState({
        loadingSalvar: true
      })

      let {unidade, certificado, uploadedFiles} = this.state

      if(certificado.senha_certificado === ''){
        this.setState({
          alerta: {
            open: true,
            severity: 'error',
            message: 'Por favor informar a senha do certificado'
          },
          loadingSalvar: false
        })
        return
      }

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Subindo Certificado'
        }
      })

      let data = new FormData();

      data.append("file", uploadedFiles[0].file, uploadedFiles[0].name)
      data.append("senha_certificado", certificado.senha_certificado);

      const USER_TOKEN = localStorage.getItem('token')

      const config = {
        headers: {
          'Authorization': 'Bearer ' + USER_TOKEN,
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/uploadCertificado/${unidade.id}`, data, config)
    
      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Upload do Certificado com Sucesso.'
        },
        loadingSalvar: false
      })
    
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      })
    }
  }

  render() {
    const { uploadedFiles, certificado, showPassword, loadingSalvar } = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText"> 
              <p>Carregando Dados do Certificado...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
              <Grid item md={11} xs={12} sm={11}>
                <h1 className="titulo">Certificado Digital </h1>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              <Grid item md={3} xs={12} sm={4}>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12}>
                    <UploadCertificado onUpload={e => this.handleUploadCertificado(e)} />
                  </Grid>
                </Grid>
                {uploadedFiles[0] &&
                  <Grid container spacing={1} direction="row">
                    <Grid item md={12} xs={12} sm={12}>
                      <p>{uploadedFiles[0] ? uploadedFiles[0].name : ''}</p>
                    </Grid>
                  </Grid>
                }
                {certificado.certificado_informado &&
                  <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12}>
                    <p style={{margin:0}}>Certificado e senha já informado!</p>
                  </Grid>
                </Grid>
                }
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={3} xs={12} sm={4}>
                <TextField 
                  className="input" 
                  label="Senha Certificado"
                  type={showPassword ? "text" : "password"}
                  variant="outlined" 
                  size="small" 
                  name="senha_certificado" 
                  value={certificado.senha_certificado} 
                  onChange={(e) => this.updateFieldSenha(e)} 
                  InputLabelProps={{ shrink: true }}
                />
                {!showPassword &&
                  <VisibilityIcon className='visibilityPasswordCertificado' onClick={e => this.handleClickShowPassword()}/>
                }
                {showPassword &&
                  <VisibilityOffIcon className='visibilityPasswordCertificado' onClick={e => this.handleClickShowPassword()}/>
                }
              </Grid>
            </Grid>
            <br />
            <hr />
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={10} xs={false} sm={4}></Grid>
              <Grid item md={2} xs={false} sm={4}>
                <Button fullWidth onClick={() => this.confirmarEmissao()} color="primary" variant="contained" size="small" disabled={loadingSalvar}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}