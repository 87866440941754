import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, Button, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../../components/templates/Alert'
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const initialState = {
  servicos: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  servicosSelecionados: [],
  servicosSelecionadosFiltradas: [],
  loading:true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  desabilitarCampos: false
}


export default class Agenda extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'funcionario')[0]

    if (permissoes) {
      this.setState({
        permissoes
      })

      if(!permissoes.alterar && !permissoes.inserir){
        this.setState({
          desabilitarCampos: true
        })
      }
    }

    const {funcionario} = this.props

    try {
      const dados = {
        unidadesnegocio: [funcionario.unidade_id]
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, this.getToken())

      const produtos = []

      resp.data.filter(param => param.servico && param.destaque).forEach(produto => {
        produtos.push({
          nome: produto.descricao + ' - ' + produto.servico.duracao + ' Min.',
          id: produto.servico.id,
          comissao: 0
        })
      })

      this.setState({
        servicos: {
          list: produtos
        },
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      servicosSelecionados: funcionario.servico_funcionario.map(value => {
        return {
          id: value.servico.id,
          nome: value.servico.produto.descricao + ' - ' + value.servico.duracao + ' Min.', 
          comissao: value.comissao
        }
      })
    })

    this.setState({
      loading: false
    })
  
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  addServico(servico) {
    const { servicosSelecionados, desabilitarCampos } = this.state
    if(!desabilitarCampos){
      servicosSelecionados.push(servico)
  
      this.setState({
        servicosSelecionados,
      })
    }else{
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Você não possui permissão para essa ação!'
        }
      })
    }

  }

  removerServico(servico) {
    const { servicosSelecionados, desabilitarCampos } = this.state
    if(!desabilitarCampos){
      const index = servicosSelecionados.findIndex(param => param.id === servico.id)

      servicosSelecionados.splice(index, 1)

      this.setState({
        servicosSelecionados,
      })
    }else{
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Você não possui permissão para essa ação!'
        }
      })
    }
   
  }

  async salvar(){
    const {servicosSelecionados} = this.state
    const {funcionario} = this.props

    try {
      const dados = {
        funcionario_id: funcionario.id,
        servicos: servicosSelecionados.map(servico => {
          return {
            id: servico.id,
            comissao: parseFloat(servico.comissao)
          }
        })
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/funcionario/servicos`, dados, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      })

      // window.location.reload()
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  updateFieldComissao(value, id){
    const {servicosSelecionados} = this.state

    const index = servicosSelecionados.findIndex(param => param.id === id)

    servicosSelecionados[index].comissao = value.target.value
    this.setState({
      servicosSelecionados
    })
  }

  render() {
    const {servicos, servicosSelecionados, desabilitarCampos } = this.state
    const {funcionario} = this.props

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Serviços...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <React.Fragment>
            {!funcionario.status && 
              <h4>Este colaborador não se encontra mais ativo!</h4>
            }
            {funcionario.status && 
              <React.Fragment>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <h3 className='titulo'>Serviços Habilitados:</h3>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={2} className="mg_top_10">
                  <Grid item md={4} xs={12} sm={5}>
                    <div className="cardSelectAulas">
                      <h4>Não Selecionados</h4>
                      <Grid container direction="row" spacing={2} className='fundo'>
                        <Grid item md={12} xs={12} sm={12}>
                          <h5>Serviços</h5>
                        </Grid>
                      </Grid>
                      <div className="cardSelectAulasOverFlow">
                        {servicos.list.filter(param => {
                          if (servicosSelecionados.findIndex(i => i.id === param.id) === -1) {
                            return true
                          } else {
                            return false
                          }
                        }).sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)).map(servico => {
                          return (
                            <div className='item' onClick={() => this.addServico(servico)}>
                              <Grid container direction="row" spacing={1}>
                                <Grid item md={12} xs={12} sm={12}>
                                  <p key={servico.id}>{servico.nome}</p>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </div>
                      {/* <h4 className='cursorPointer' onClick={e => addTodasAulas()}>Selecionar Todos</h4> */}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={12} sm={2} className="aling-center">
                    <SyncAltIcon className="iconeTroca" />
                  </Grid>
                  <Grid item md={4} xs={12} sm={5}>
                    <div className="cardSelectAulas">
                      <h4>Selecionados</h4>
                      <Grid container direction="row" spacing={2} className='fundo'>
                        <Grid item md={8} xs={6} sm={6}>
                          <h5>Serviços</h5>
                        </Grid>
                        <Grid item md={4} xs={6} sm={6}>
                          <h5>Comissao (%)</h5>
                        </Grid>
                      </Grid>
                      <div className="cardSelectAulasOverFlow">
                        {servicosSelecionados.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)).map(servico => {
                          return (
                            <div className='item'>
                              <Grid container direction="row" spacing={1}>
                                <Grid item md={9} xs={6} sm={6}>
                                  <p key={servico.id} onClick={() => this.removerServico(servico)}>{servico.nome}</p>
                                </Grid>
                                <Grid item md={2} xs={6} sm={6}>
                                  <TextField 
                                    type="number" 
                                    className="input" 
                                    variant="outlined" 
                                    size="small" 
                                    name="comissao" 
                                    value={servico.comissao} 
                                    onChange={(e) => this.updateFieldComissao(e, servico.id)}
                                    disabled={desabilitarCampos}
                                  />
                                </Grid>
                                <Grid item md={1} xs={6} sm={6}>
                                  <p className='porcentagem'>%</p>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </div>
                      {/* <h4 className='cursorPointer' onClick={e => removerTodasAulas()}>Remover Todos</h4> */}
                    </div>
                  </Grid>
                </Grid>
                <br />
                <hr />
                <Grid container direction="row" spacing={1}>
                  <Grid item md={9} xs={false} sm={8}></Grid>
                  {!desabilitarCampos &&
                    <Grid item md={3} xs={12} sm={4}>
                      <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                    </Grid>
                  }
                </Grid>
              </React.Fragment>
            }
          </React.Fragment>
        }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} />
      </React.Fragment>
    )
  }
}