import React, { Component } from 'react'
import './Cargo.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import Alert from './../../components/templates/Alert'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  cargo: {

  },
  cargos: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Cargo' },
    { id: 'departamento', numeric: false, disablePadding: true, label: 'Departamento' },
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'comissionado', numeric: false, disablePadding: false, label: 'Comissionado' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    departamento: "",
    descricao: ""
  },
  loading: true,
  mensagemLoading: 'Carregando Cargos...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Cargo extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'cargo')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/cargos/list`, dados, this.getToken())

      const cargos = []

      data.forEach(cargo => {
        if(cargo.unidade){
          cargos.push({
            _id: cargo.id,
            descricao: cargo.descricao,
            departamento: cargo.departamento.descricao,
            comissionado: cargo.comissionado,
            unidade: cargo.unidade ? (cargo.unidade.descricao ? cargo.unidade.descricao : (cargo.unidade.pessoa.tipo === 'FISICA' ? cargo.unidade.pessoa.fisica.nome : cargo.unidade.pessoa.juridica.razaosocial)) : 'SISTEMA',
            ativo: cargo.ativo
          })
        }
      })

      this.setState({
        cargos: {
          list: cargos
        },
        cargosFiltrado: {
          list: cargos
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const cargos = this.state.cargos.list
    const filtro = this.state.filtro

    const list = cargos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      cargosFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, cargosFiltrado, permissoes } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="borderBottom"
                >
                  <Grid item md={10} xs={12} sm={6}>
                    <h1 className="titulo">Cargos</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={2} xs={12} sm={6}>
                      <Link to="/cargo/cadastro">
                        <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar Cargo
                        </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Cargo" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Departamento" variant="outlined" size="small" name="departamento" value={filtro.departamento} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table urlUpdate="/cargo/cadastro/" headCell={this.state.cabecalhoTabela} rows={cargosFiltrado.list} acoes={this.state.acoesTabela} />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />

        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Cargos"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}