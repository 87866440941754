import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './Unidade.scss'
import Unidade from './View/Unidade'
import Configuracao from './View/Configuracao'
import DadosComplementares from './View/DadosComplementares'
import EmissaoNFSE from './View/EmissaoNFSE'
import Certificado from './View/Certificado'
import Contratos from './View/Contratos'
// import LocalEstoque from './View/LocalEstoque'
import Midias from './View/Midias'
import Migracao from './View/Migracao'
import { useTheme } from '@mui/material/styles';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#FFF',
}));

export default function SimpleTabs(props) {
  const [value, setValue] = React.useState(parseInt(props.tab));
  const unidade = props.unidade
  const permissoes_migracao = props.permissoes_migracao
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
   
    props.navigate(`/unidade/view/${newValue}/${unidade.id}`);
    return;
  };

  const theme = useTheme();

  return (
    <Root theme={theme}>
      <AppBar position="static">
        <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Dados da Unidade" {...a11yProps(0)} />
          <Tab label="Configuração da Unidade" {...a11yProps(1)} />
          <Tab label="Contratos" {...a11yProps(2)} />
          <Tab label="Dados Complementares" {...a11yProps(3)} />
          <Tab label="Emissão NFSE" {...a11yProps(4)} />
          <Tab label="Certificado Digital" {...a11yProps(5)} />
          <Tab label="Midias" {...a11yProps(6)} />
          {permissoes_migracao.inserir &&
            <Tab label="Migrações" {...a11yProps(7)} />
          }
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Unidade unidade={unidade} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Configuracao unidade={unidade} history={props.history} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Contratos unidade={unidade} history={props.history}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DadosComplementares unidade={unidade} history={props.history} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <EmissaoNFSE unidade={unidade} history={props.history} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Certificado unidade={unidade} history={props.history} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Midias unidade={unidade} history={props.history} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Migracao unidade={unidade} history={props.history} />
      </TabPanel>
    </Root>
  );
}
