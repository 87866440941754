import React, { useState, useEffect } from 'react';
// import './../Produto.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import FormGrupoProdutos from '../../components/forms/FormGrupoProdutos'
import { Grid, Button } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import Loading from './../../components/templates/Loading'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
  grupoProduto: {
    id: "",
    descricao: "",
    visibilidade: "",
    unidade_id: "",
    ativo: true
  },
  grupoProdutos: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Grupo de Produtos...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  tipo_unidade: 'UNIDADE',
  openModalAjuda: false,
  markdown: ''
}

const CadastroGrupo = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { grupoProdutoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateField = (event) => {
    const {grupoProduto, unidades} = state

    const name = event.target.name
    const value = event.target.value

    grupoProduto[name] = value

    if(name === 'unidade_id'){
      const unidadeSelecionada = unidades.list.filter(param => param.id === parseInt(value))[0]
    
      setState(prevState => ({...prevState,
        tipo_unidade: unidadeSelecionada.tipo
      }))
    }

    setState(prevState => ({...prevState, grupoProduto }))
  }

  const updateFieldAtivo = (event) => {
    const grupoProduto = { ...state.grupoProduto }
    grupoProduto.ativo = !grupoProduto.ativo
    setState(prevState => ({...prevState, grupoProduto }))
  }

  const validateForm = () => {
    const { grupoProduto } = state

    if (!grupoProduto.descricao) return false
    if (!grupoProduto.unidade_id) return false
    if (!grupoProduto.visibilidade) return false

    return true
  }

  const salvar = async () => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }


    setState(prevState => ({...prevState, 
      loading: true,
      mensagemLoading: 'Cadastrando/Alterando Grupo de Produto'
    }))

    const { grupoProduto } = state

    const dados = {
      descricao: grupoProduto.descricao,
      unidade_id: grupoProduto.unidade_id,
      visibilidade: grupoProduto.visibilidade,
      ativo: grupoProduto.ativo
    }

    try {

      const method = grupoProduto.id !== "" ? 'put' : 'post'
      const url = grupoProduto.id !== "" ? `/grupoProdutos/${grupoProduto.id}` : `/grupoProdutos`

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))
      
      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }

  }

  const backPage = (timeout = 3000) => {
    const self = this
    setTimeout(() => {
      navigate("/grupo_produtos");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/grupoProduto/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'grupo-produtos')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (grupoProdutoId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message:'Você não tem permissão para acessa essa tela!'
            }
          }))
          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message:'Você não tem permissão para acessa essa tela!'
            }
          }))
          backPage()
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          const {grupoProduto} = state
          grupoProduto.unidade_id = unidades[0].id
        
          setState(prevState => ({...prevState,
            tipo_unidade: unidades[0].tipo
          }))

          setState(prevState => ({...prevState,grupoProduto}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (grupoProdutoId) {
        try {

          const { data: grupoProduto } = await axios.get(`${window._env_.REACT_APP_API_URL}/grupoProdutos/${grupoProdutoId}`, getToken())

          setState(prevState => ({...prevState,
            grupoProduto: {
              id: grupoProduto.id,
              descricao: grupoProduto.descricao,
              visibilidade: grupoProduto.visibilidade,
              unidade_id: grupoProduto.unidade_id,
              ativo: grupoProduto.ativo
            }
          }))
        }catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
          return false
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { grupoProduto, unidades } = state

  console.log(unidades)

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Grupo de Produtos</h1>
                </Grid>
              </Grid>
              <FormGrupoProdutos 
                updateField={e => updateField(e)} 
                updateFieldAtivo={e => updateFieldAtivo(e)} 
                dados={grupoProduto}
                unidades={unidades.list}
                tipo_unidade={state.tipo_unidade}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={e => backPage(0)}>Voltar</Button>
                </Grid>
                <Grid item md={8} xs={false} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button disabled={state.loading} fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Grupo de Produtos/Serviços"}
        handleClose={() => {setState(prevState => ({...prevState, openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroGrupo
