import React, { Component } from 'react'
import './Documentos.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import Alert from './../../components/templates/Alert'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import moment from 'moment';
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  documentos: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'titulo', numeric: false, disablePadding: true, label: 'Titulo Documento' },
    { id: 'versao', numeric: false, disablePadding: true, label: 'Versão' },
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'publicado', numeric: false, disablePadding: true, label: 'Publicado' },
    { id: 'datainicio', numeric: false, disablePadding: true, label: 'Data de Inicio', orderBy: 'datainicio_order' },
    { id: 'assinar', numeric: false, disablePadding: true, label: 'Assinar?' },
    { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    departamento: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

export default class Documentos extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'template-documentos')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }
  

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/list`, dados, this.getToken())
  
      const documentos = data.sort((a, b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0)).map(value => {
        return {
          _id: value.id,
          titulo: value.titulo,
          tipo: value.tipo,
          assinar: value.assinar,
          ativo: value.versoes[0].ativo,
          publicado: value.versoes[0].publicado,
          versao: value.versoes[0].versao,
          html: value.versoes[0].html,
          datainicio: moment(value.versoes[0].datainicio).format('DD/MM/YYYY'),
          datainicio_order: moment(value.versoes[0].datainicio).format('YYYYMMDD')
        }
      })

      this.setState({
        documentos: {
          list: documentos
        }
      })
      
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }



    this.setState({
      loading: false
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { permissoes, documentos } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Documentos...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={10} xs={12} sm={6}>
                  <h1 className="titulo">Documentos</h1>
                </Grid>
                {permissoes.inserir &&
                  <Grid item md={2} xs={12} sm={6}>
                    <Link to="/documentos/cadastro">
                      <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Documento
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate="/documentos/cadastro/" 
                    headCell={this.state.cabecalhoTabela} 
                    rows={documentos.list} 
                    acoes={this.state.acoesTabela}/>
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Templates de Documentos"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}