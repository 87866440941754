import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button,TextField} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import real from './../../services/real'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from './../Table'
import moment from 'moment';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
  { id: 'datavencimento', numeric: true, disablePadding: true, label: 'Data de Vencimento' },
  { id: 'valor_parcela', numeric: false, disablePadding: true, label: 'Valor da Parcela' },
  { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação'}
]

const acoesTabela = ['viewFinanceiroPendente']

export default function SimpleModal(props) {
  const theme = useTheme();

  let {financeiro_pendente} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Financeiro Pendente</h5>
            </Grid>
          </Grid>
          <br />
					<Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <Table
              headCell={cabecalhoTabela}
              rows={financeiro_pendente.map(value => {
                return {
                  id: value.parcela_id,
                  descricao: value.descricao,
                  datavencimento: moment(value.datavencimento).format('DD/MM/YYYY'),
                  valor_parcela: 'R$ ' + real(value.valor_parcela),
                  situacao: value.situacao
                }
              })}
              acoes={acoesTabela} />
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
