import React, { useState, useEffect } from 'react';
import './../Relatorios.scss'
import './Impressao.scss'
import './../../../main/ultil.scss'
import { Grid } from '@mui/material'
import Chart from "react-google-charts";
import moment from 'moment'
import Table from './../../../components/Table'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  porCargo: [],
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
		func_id: "",
		fun_id: ""
  },
  cabecalhoTabelaType: [],
  relatoriosType: [],
  acoesTabelaType: [],
  dataChart: []
}

const options = {
  is3D: true,
};

const Relatorio_Sintetico = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { funcaoId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const dados = JSON.parse(localStorage.getItem('grafico_venda_vouchers_periodo'))
      const pessoa = JSON.parse(localStorage.getItem('pessoa'))

      setState(prevState => ({...prevState,
        ...dados,
        pessoa
      }))
      
      setTimeout(() => {
        window.print()
        navigate("/rel_vendas");
        setState({ loading: false })
        return;
      }, 500);
    };
    
    fetchData();
  }, []);

  const { filtro, pessoa, cabecalhoTabelaType, relatoriosType, acoesTabelaType, dataChart } = state

  return (
    <div id="app" style={{backgroundColor: '#FFF'}}>
      <hr style={{width: "100%"}}/>
      <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
        <Grid item md={12} xs={12} sm={12}>
          <p style={{fontSize: '42px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'center'}}><b>Vendas de Vouchers Período</b></p>
        </Grid>
      </Grid>
      <hr style={{width: "100%"}}/>
      <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
        <Grid item md={6} xs={6} sm={6}>
          <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Relatório: {moment(new Date()).format('DD/MM/YYYY HH:mm')}</b></p>
        </Grid>
        <Grid item md={6} xs={6} sm={6}>
          <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Gerado por: {pessoa.fisica.nome.split(' ')[0]}</b></p>
        </Grid>
      </Grid>
      <hr style={{width: "100%"}}/>
      <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
        <Grid item md={6} xs={6} sm={6}>
          <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Inicial: {moment(filtro.data_inicial).format('DD/MM/YYYY')}</b></p>
        </Grid>
        <Grid item md={6} xs={6} sm={6}>
          <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Data Final: {moment(filtro.data_final).format('DD/MM/YYYY')}</b></p>
        </Grid>
      </Grid>
      <hr style={{width: "100%"}}/>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={6} xs={6}>
          <Table
            headCell={cabecalhoTabelaType}
            rows={relatoriosType}
            acoes={acoesTabelaType}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <Chart
            chartType="PieChart"
            data={dataChart}
            options={options}
            width={"100%"}
            height={"400px"}
            formatters={[
              {
                type: 'NumberFormat',
                column: 1,
                options: {
                  groupingSymbol: '.',
                  decimalSymbol: ','
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Relatorio_Sintetico