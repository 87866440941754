import React from 'react'
import { Grid } from '@mui/material'
import Table from './../../../components/Table'
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Chart } from "react-google-charts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const options = {
  is3D: true,
};

const VendaVouchers = ({urlView, cabecalhoTabela, relatorios, acoesTabela, viewRow, tab, handleChange, relatoriosType,
  cabecalhoTabelaType, acoesTabelaType, dataChart}) => (
  <React.Fragment>
    <AppBar position="static" className="mg_top_20">
      <Tabs id="viewCliente" value={tab} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Análitico" {...a11yProps(0)} />
        <Tab label="Sintético" {...a11yProps(1)} />
      </Tabs>
    </AppBar>
    <TabPanel value={tab} index={0}>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12}>
          <Table
            urlView={urlView}
            headCell={cabecalhoTabela}
            rows={relatorios}
            acoes={acoesTabela}
            viewRow={(e, acao, status) => viewRow(e, acao, status)} 
          />
        </Grid>
      </Grid>
    </TabPanel>
    <TabPanel value={tab} index={1}>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={6} xs={12}>
          <Table
            headCell={cabecalhoTabelaType}
            rows={relatoriosType}
            acoes={acoesTabelaType}
            viewRow={(e, acao, status) => viewRow(e, acao, status)} 
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Chart
            chartType="PieChart"
            data={dataChart}
            options={options}
            width={"100%"}
            height={"400px"}
            formatters={[
              {
                type: 'NumberFormat',
                column: 1,
                options: {
                  groupingSymbol: '.',
                  decimalSymbol: ','
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </TabPanel>
  </React.Fragment>
)

export default VendaVouchers
