import React, { useRef } from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button, TextField } from '@mui/material'
import SignaturePad from "react-signature-canvas";
import Color from 'color';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const ColorYellowButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: Color('#FFFF00').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#FFFF00',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const sigCanvas = useRef({})

  const theme = useTheme();

  const clear = () => sigCanvas.current.clear();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Assinar Contrato - {props.contrato.codigo}</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
              <p>Olá {props.contrato.resp_fin ? props.contrato.resp_fin.pesf_nome : (props.contrato.cliente.pessoa.tipo === 'FISICA' ? props.contrato.cliente.pessoa.fisica.nome : props.contrato.cliente.pessoa.juridica.nomefantasia)}, segue a baixo {props.contrato.resp_fin ? ` o contrato do ${(props.contrato.cliente.pessoa.tipo === 'FISICA' ? props.contrato.cliente.pessoa.fisica.nome : props.contrato.cliente.pessoa.juridica.nomefantasia)}` : ` o seu contrato`} Cod.: {props.contrato.codigo}, ao final do contrato realizar a sua assinatura.</p>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={1} xs={false} sm={1}></Grid>
            <Grid item md={10} xs={12} sm={10}>
              <div style={{border: '1px solid #000', padding: '20px'}} dangerouslySetInnerHTML={{__html: props.contrato.contrato_html}} />  
            </Grid>
            <Grid item md={1} xs={false} sm={1}></Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
            <Grid item md={2} xs={12} sm={1}></Grid>
            <Grid item md={8} xs={12} sm={10} style={{textAlign: 'center'}}>
              {!props.contrato.resp_fin && 
                <p>Eu {(props.contrato.cliente.pessoa.tipo === 'FISICA' ? props.contrato.cliente.pessoa.fisica.nome : props.contrato.cliente.pessoa.juridica.nomefantasia)}, portador do CPF/CNPJ {(props.contrato.cliente.pessoa.tipo === 'FISICA' ? props.contrato.cliente.pessoa.fisica.cpf : props.contrato.cliente.pessoa.juridica.cnpj)}, li e concordo com os termos do contrato de num. {props.contrato.codigo}</p>
              }

              {props.contrato.resp_fin && 
                <p>Eu {props.contrato.resp_fin.pessoa.tipo === 'FISICA' ? props.contrato.resp_fin.pessoa.fisica.nome : props.contrato.resp_fin.pessoa.juridica.nomefantasia}, portador do CPF/CNPJ {props.contrato.resp_fin.pessoa.tipo === 'FISICA' ? props.contrato.resp_fin.pessoa.fisica.cpf : props.contrato.resp_fin.pessoa.juridica.cnpj}, responsável pelo aluno {(props.contrato.cliente.pessoa.tipo === 'FISICA' ? props.contrato.cliente.pessoa.fisica.nome : props.contrato.cliente.pessoa.juridica.nomefantasia)}, li e concordo com os termos do contrato de num. {props.contrato.codigo}</p>
            }
              <p>Sua Assinatura:</p>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row">
            <Grid item md={2} xs={12} sm={1}></Grid>
            <Grid item md={8} xs={12} sm={10}>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  className: "signatureCanvas"
                }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={1}></Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={5} xs={12} sm={4}></Grid>
            <Grid item md={2} xs={12} sm={4} >
              <TextField
                className="input" 
                label="Código Recebido*" 
                variant="outlined"
                name="codigo" 
                value={props.codigo} 
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }} 
                inputProps={{ maxLength: 6 }}
              />   
            </Grid>
            <Grid item md={5} xs={12} sm={4}></Grid>
            {/* <Grid item md={4} xs={12} sm={4}>
              <ColorVioletButton style={{marginTop: 7}} fullWidth variant="contained" className="btn_salvar" size="small" onClick={e => props.baixarContrato()}>Baixar Contrato</ColorVioletButton>
            </Grid> */}
          </Grid>
          <br />
          <hr />
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={4} xs={12} sm={4}>
              <ColorYellowButton fullWidth variant="contained" className="btn_salvar" size="small" onClick={clear} disabled={props.travaBotao}>Limpar</ColorYellowButton>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(false, sigCanvas)} disabled={props.travaBotao}>Voltar</Button>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true, sigCanvas)} disabled={props.travaBotao}>Assinar Contrato</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
