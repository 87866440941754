import React, { useState, useEffect, useCallback } from 'react';
import './Documentos.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import FormDocumento from '../../components/forms/FormDocumento'
import moment from 'moment'
import Table from '../../components/Table'
import { saveAs } from 'file-saver';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import ModalSelecionaPessoa from './../../components/modals/SelecionaPessoa'
import ModalSelecionarContrato from './../../components/modals/SelecionarContrato'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
  documento: {
    id: "",
    titulo: "",
    tipo: "",
    tempvar_id: "",
    assinar: false,
    tempver_id: "",
    versao: "",
    html: "",
    publicado: false,
    datainicio: "",
    vinculos: [],
    versoes: []
  },
  loading: true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  templates: {
    list: []
  },
  update: false,
  updateVersao: false,
  term: '',
  congelado: false,
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'variavel', numeric: false, disablePadding: true, label: 'Variavel' }
  ],
  acoesTabela: ['selectVar'],
  variaveisFiltrada: [],
  editorState: EditorState.createEmpty(),
  unidadesSelecionadas: [],
  openModalAjuda: false,
  markdown: '',
  modalSelecionaPessoa: false,
  clientes:{
    list:[]
  },
  clientesFiltrado: {
    list: []
  },
  filtro: {
    descricao: "",
    nome: "",
    cpf_cnpj: "",
    flag: "CLIENTE",
    ativo: 'Sim',
    filtrar_por: "Nome - nome",
    value: '',
    limit: 10,
    offset: 0
  },
  loadingPessoas: false,
  filtrocliente: {
    label: 'Nome',
    name:'nome'
  },
  rows: 0,
  versaoSelecionada: null,
  openModalSelecionarContrato: false,
  contratos: []
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

function formataTelefone(numero) {

  if(numero){
    let texto = numero.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

const CadastroCargo = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { documentoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateField = (event) => {
    let { documento, templateSelecionado, templates } = state

    if (event.target.name === 'tempvar_id') {
      let variaveisFiltrada = []

      if (event.target.value !== '') {
        templateSelecionado = templates.list.filter(param => param.id === parseInt(event.target.value))[0]

        variaveisFiltrada = templateSelecionado.variaveis

        variaveisFiltrada.push({
          descricao: "Assinatura da Unidade e do Aluno/Responsável",
          id: -1,
          mapa: "assinatura",
          model: "Aluno",
          variavel: "ASSINATURA_UNIDADE_E_CLIENTE",
        })

        variaveisFiltrada.push({
          descricao: "Assinatura da Unidade",
          id: -2,
          mapa: "assinatura",
          model: "Aluno",
          variavel: "ASSINATURA_UNIDADE_UNICA",
        })

        variaveisFiltrada.push({
          descricao: "Assinatura do Aluno/Responsável",
          id: -3,
          mapa: "assinatura",
          model: "Aluno",
          variavel: "ASSINATURA_CLIENTE_UNICA",
        })

      } else {
        templateSelecionado = {
          descricao: '',
          id: '',
          tipo: '',
          variaveis: []
        }
      }

      setState(prevState => ({...prevState,
        variaveisFiltrada
      }))
    }

    documento[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      documento,
      templateSelecionado
    }))
  }

  const updateFieldAssinarDocumento = () => {
    let {documento} = state
    documento.assinar = !documento.assinar
    setState(prevState => ({...prevState,documento}))
  }

  const updateFieldPublicado = () => {
    const documento = { ...state.documento }
    documento.publicado = !documento.publicado

    if(documento.publicado){
      documento.datainicio = moment(new Date()).format('YYYY-MM-DD')
    }else{
      documento.datainicio = ''
    }

    setState(prevState => ({...prevState, documento }))
  }

  const updateFiltro = (event) => {
    setState(prevState => ({...prevState, term: event.target.value }))
    const variaveis = state.templateSelecionado.variaveis
    const filtro = state.filtro

    const list = variaveis.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      variaveisFiltrada: list,
      filtro
    }))
  }

  const onEditorStateChange = (editorState) => {
    const {documento } = state

    documento.html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    setState(prevState => ({...prevState,editorState, documento}))
  }

  const selecionarVar = (variavel) => {
    let documento = { ...state.documento }
    documento.html = documento.html.replace('{%}', `%%${variavel.variavel}`)

    const contentBlock = htmlToDraft(documento.html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setState(prevState => ({...prevState, documento, editorState }))
  }

  const validateForm = () => {
    const { documento, unidadesSelecionadas } = state

    if (!documento.titulo) return false
    if (!documento.tipo) return false
    if (!documento.tempvar_id) return false

    if(!state.update){
      if (!documento.versao) return false
      // if (!documento.tempver_html) return false
    }

    if (unidadesSelecionadas.length === 0) return false

    // if (documento.vinculos.length === 0) return false

    return true
  }

  const salvar = async () => {
    const { editorState, unidadesSelecionadas} = state

    setState(prevState => ({...prevState, loadingSalvar: true }))

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: 'Preencha os campos obrigatorios (*).'
        },
        loadingSalvar: false
      }))
      return
    }

    const documento = state.documento

    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    html = html.replaceAll(`<p style="text-align:start;"></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:left;"></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:justify;"></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:justify;">&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:start;">&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:left;">&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p>&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:left;"><br></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:justify;"><br></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:start;"><br></p>`, "<br/>")

    const dadosSalvar = {
      titulo: documento.titulo,
      tipo: documento.tipo,
      tempvar_id: documento.tempvar_id,
      assinar: documento.assinar,
      versao: documento.versao,
      html: html,
      publicado: documento.publicado,
      datainicio: documento.datainicio,
      unidades: unidadesSelecionadas
    }

    if(dadosSalvar.publicado === '' || !dadosSalvar.publicado){
      delete dadosSalvar.datainicio
    }
    
    const dadosUpdate = {
      titulo: documento.titulo,
      tempvar_id: documento.tempvar_id,
      tipo: documento.tipo,
      assinar: documento.assinar,
      unidades: unidadesSelecionadas,
      versao: {
        id: documento.tempver_id,
        versao: documento.versao,
        html: html,
        nova_versao: !state.updateVersao,
        publicado: documento.publicado,
        datainicio: documento.datainicio
      }
    }

    if(dadosUpdate.versao.publicado === '' || !dadosUpdate.versao.publicado){
      delete dadosUpdate.versao.datainicio
    }

    if (!state.updateVersao) {
      delete dadosUpdate.versao.id
    }

    try {
      const method = documento.id !== "" ? 'put' : 'post'
      const url = documento.id !== "" ? `/templatesdocumentos/${documento.id}` : `/templatesdocumentos`
      const dados = documento.id !== "" ? dadosUpdate : dadosSalvar

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Documento Cadastrado.'
        },
        loadingSalvar: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const backPage = useCallback((timeout = 3000) => {
    setTimeout(() => {
      navigate("/documentos");
    }, timeout)
  }, [])

  const editarVersao = (value) => {
    if (value.congelado || !value.ativo) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Só é possível editar versões ativas e não congeladas'
        }
      }))
      return false
    }

    const contentBlock = htmlToDraft(value.html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    let documento = {
      id: state.documento.id,
      titulo: state.documento.titulo,
      tipo: state.documento.tipo,
      tempvar_id: state.documento.tempvar_id,
      assinar: state.documento.assinar,
      versao: value.versao,
      html: value.html,
      publicado: value.publicado,
      datainicio: moment(value.datainicio_sem_form).format('YYYY-MM-DD'),
      tempver_id: value.id,
      vinculos: state.documento.vinculos,
      versoes: state.documento.versoes,
    } 

    setState(prevState => ({...prevState,
      documento,
      update: true,
      updateVersao: true,
      editorState,
    }))
  }

  const copiarVersao = (value) => {

    const contentBlock = htmlToDraft(value.html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setState(prevState => ({...prevState,
      documento: {
        id: state.documento.id,
        titulo: state.documento.titulo,
        tipo: state.documento.tipo,
        tempvar_id: state.documento.tempvar_id,
        assinar: state.documento.assinar,
        versao: "",
        html: value.html,
        publicado: false,
        datainicio: "",
        tempver_id: "",
        versoes: state.documento.versoes,
      },
      update: true,
      updateVersao: false,
      editorState
    }))
  }

  const imprimirDoc = async (value) => {
    await filtrarCliente()

    setState((prevState) => ({
      ...prevState,
      modalSelecionaPessoa: true,
      versaoSelecionada: value
    }))

   

    // try {
    //   const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/imprimirView`, value, getToken())

    //   const dadosImprimir = {
    //     path: data
    //   }
      
    //   const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

    //   const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

    //   setState(prevState => ({...prevState,
    //     loadingDownload: false
    //   }))

    //   saveAs(pdfBlob, `${documento.titulo.replaceAll(" ", "_")}_${value.versao}.pdf`);

    // } catch (error) {
    //   console.log(error)
    //   setState(prevState => ({...prevState,
    //     alerta: {
    //       open: true,
    //       severity: 'error',
    //       message: error.response ? error.response.data.message : 'Erro Interno'
    //     }
    //   }))
    // }
  }

  const consultarTemplates = async () => {
    try {
      const { data: templates } = await axios.get(`${window._env_.REACT_APP_API_URL}/templatesvariaveis`, getToken())
        
      setState(prevState => ({...prevState,
        templates:{
          list: templates.filter(param => param.documento)
        }
      }))

      return templates.filter(param => param.documento)
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))

      backPage()
    }
  }

  const handleCloseModalPessoa = () => {
    setState((prevState) => ({
      ...prevState,
      modalSelecionaPessoa: false,
      clientes:{
        list:[]
      },
      clientesFiltrado: {
        list: []
      },
    }))
  }

  const handleChangePage = async (event, newPage) =>{
    const {filtro} = state
    filtro.offset = newPage
    setState((prevState) => ({
      ...prevState,
      filtro
    }))

    await filtrarCliente()
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState((prevState) => ({
      ...prevState,
      filtro
    }))

    await filtrarCliente()
  }

  const filtrarCliente = async () =>{
    setState((prevState) => ({
      ...prevState,
      loadingPessoas: true,
    }))

    try {
     
      let {filtro, filtrocliente} = state

      filtro = {
        flag: 'CLIENTE',
        ativo: filtro.ativo,
        filtrar_por: filtro.filtrar_por,
        value: filtro.value,
        nome: "",
        cpf_cnpj: "",
        limit: filtro.limit,
        tipo: "FISICA",
        offset: filtro.offset * filtro.limit
      }
      
      filtro[filtrocliente.name] = filtro.value ? filtro.value : ''
      
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      filtro.unidadesnegocio = [acesso_atual[0].id]

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtro, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          tipo: pessoa.tipo,
          nome: pessoa.nome,
          sobrenome: pessoa.sobrenome,
          numero: formataTelefone(pessoa.numero),
          cpf_cnpj: pessoa.cpf_cnpj,
          flags: pessoa.flags.map(flag => {return flag.flag}),
          ativo: pessoa.ativo,
          cliente_id: pessoa.cliente_id
        })
      })

      setState((prevState) => ({
        ...prevState,
        clientesFiltrado: {
          list: pessoas
        },
        loadingPessoas: false,
        rows: data.rows,
      }))

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        loadingPessoas: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const selecionarPessoa = async (row) => {
    try {
      console.log(row)

      const { data : contratos } = await axios.get(`${window._env_.REACT_APP_API_URL}/cliente/contratos/${row.cliente_id}`, getToken())

      if(contratos.length === 0){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Esse cliente não possui contratos.'
          }
        }))
        return
      }

      if(contratos.length === 1){
        await imprimirContrato(contratos[0].id)
      }

      if(contratos.length > 1){
        setState((prevState) => ({
          ...prevState,
          contratos,
          openModalSelecionarContrato: true
        }))
      }

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const imprimirContrato = async (contrato_id) => {
    try {
      let {versaoSelecionada} = state

      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Gerando PDF...'
        }
      }))

       const documento = state.documento

      versaoSelecionada.titulo = documento.titulo
      versaoSelecionada.contrato_id = contrato_id

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/imprimirView`, versaoSelecionada, getToken())

      const dadosImprimir = {
        path: data
      }
      
      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

      saveAs(pdfBlob, `${documento.titulo.replaceAll(" ", "_")}_${versaoSelecionada.versao}.pdf`);        
    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseModalSelecionarContrato = () => {
    setState((prevState) => ({
      ...prevState,
      openModalSelecionarContrato: false,
      contratos: []
    }))
  }

  const selecionarContrato = async (row) => {
    await imprimirContrato(row._id)
  }

  const updateFieldUnidade = (event, value) => {
    setState(prevState => ({...prevState,
      unidadesSelecionadas: value
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/documentos/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'template-documentos')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (documentoId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      let templates = []

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, dados, getToken())
        
        if(unidades.length === 1){
          setState(prevState => ({...prevState,
            unidadesSelecionadas: [unidades[0]]
          }))
        }

        setState(prevState => ({...prevState,
          unidades:{
            list: unidades.map(unidade => {
              return {
                id: unidade.id,
                numero: unidade.numero,
                descricao: unidade.descricao,
                midias: unidade.pessoa.midias_pessoa
              }
            })
          }
        }))

        templates = await consultarTemplates()
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (documentoId) {
        let { templateSelecionado } = state
        try {
          const { data: documento } = await axios.get(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/${documentoId}`, getToken())
          
          templateSelecionado = templates.filter(param => param.id === parseInt(documento.tempvar_id))[0]

          let variaveisFiltrada = templateSelecionado.variaveis

          variaveisFiltrada.push({
            descricao: "Assinatura da Unidade e do Aluno/Responsável",
            id: -1,
            mapa: "assinatura",
            model: "Aluno",
            variavel: "ASSINATURA_UNIDADE_E_CLIENTE",
          })

          variaveisFiltrada.push({
            descricao: "Assinatura da Unidade",
            id: -2,
            mapa: "assinatura",
            model: "Aluno",
            variavel: "ASSINATURA_UNIDADE_UNICA",
          })

          variaveisFiltrada.push({
            descricao: "Assinatura do Aluno/Responsável",
            id: -3,
            mapa: "assinatura",
            model: "Aluno",
            variavel: "ASSINATURA_CLIENTE_UNICA",
          })

          console.log(documento)

          setState(prevState => ({...prevState,
            documento: {
              id: documento.id,
              titulo: documento.titulo,
              tipo: documento.tipo,
              tempvar_id: documento.tempvar_id,
              assinar: documento.assinar,
              versao: "",
              html: "",
              publicado: false,
              datainicio: "",
              tempver_id: "",
              versoes: documento.versoes.map(versao => {
                return {
                  id: versao.id,
                  versao: versao.versao,
                  html: versao.html,
                  publicado: versao.publicado,
                  ativo: versao.ativo,
                  congelado: versao.congelado,
                  datainicio: moment(versao.datainicio).format('DD/MM/YYYY'),
                  datainicio_sem_form: versao.datainicio,
                }
              })
            },
            update: true,
            templateSelecionado,
            variaveisFiltrada,
            unidadesSelecionadas: documento.temp_unidades.map(value => {
              return {
                ...value.unidade
              }
            })
          }))

          for (const versao of documento.versoes) {
            if (versao.congelado) {
              setState(prevState => ({...prevState, congelado: true }))
            }
          }

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { documento, filtro, editorState, unidades, unidadesSelecionadas } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <div>
            <CircularProgress />
          </div>
        }
        {!state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={12}>
                <h1 className="titulo">Cadastro de Documentos</h1>
              </Grid>
            </Grid>
            <FormDocumento
              updateField={e => updateField(e)}
              dados={documento}
              updateFieldAssinarDocumento={() => updateFieldAssinarDocumento()}
              templates={state.templates.list}
              updateVersao={state.updateVersao}
              update={state.update}
              updateFieldPublicado={e => updateFieldPublicado(e)}
              unidades={unidades.list}
              unidadesSelecionadas={unidadesSelecionadas}
              editarVersao={e => editarVersao(e)}
              copiarVersao={e => copiarVersao(e)}
              imprimirDoc={e => imprimirDoc(e)}
              updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
            />

            <hr className="mg_top_10"/>

            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={3} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Descrição" 
                  size="small"
                  variant="outlined" 
                  name="descricao" 
                  value={filtro.descricao} 
                  onChange={(e) => updateFiltro(e)} 
                  InputLabelProps={{ shrink: true }} 
                  placeholder='Pesquisar' 
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table
                  headCell={state.cabecalhoTabela}
                  rows={state.variaveisFiltrada}
                  acoes={state.acoesTabela}
                  selecionarVar={(e) => selecionarVar(e)} />
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <div className="cardEditor">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={e => onEditorStateChange(e)}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
              <Grid item md={2} xs={12} sm={4}>
                <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => backPage(0)}>Voltar</Button>
              </Grid>
              <Grid item md={8} xs={12} sm={8}></Grid>
              <Grid item md={2} xs={12} sm={4}>
                <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Template de Documentos"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
      <ModalSelecionaPessoa 
        open={state.modalSelecionaPessoa} 
        handleClose={e => handleCloseModalPessoa()} 
        updateFiltro={e => updateFiltro(e)}
        filtro={state.filtro}
        filtrocliente={state.filtrocliente}
        filtrarCliente={e => filtrarCliente()}
        loadingPessoas={state.loadingPessoas}
        pessoasFiltrado={state.clientesFiltrado.list}
        selecionarPessoa={e => selecionarPessoa(e)}
        desabilitaFlag={true}
        rows={state.rows}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
      />

      <ModalSelecionarContrato 
        open={state.openModalSelecionarContrato} 
        handleClose={e => handleCloseModalSelecionarContrato()}
        contratos={state.contratos}
        selecionarContrato={(e) => selecionarContrato(e)}
      />
    </div>
  )
}

export default CadastroCargo