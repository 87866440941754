import React from 'react'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Table from './../Table'
import { styled } from '@mui/system';
import Color from 'color';

const ColorDarkOrangeButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: Color('#FF8C00').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#FF8C00',
  },
}));

const cabecalhoTabela = [
  { id: 'departamento', numeric: false, disablePadding: true, label: 'Departamento' },
  { id: 'centrocusto', numeric: false, disablePadding: true, label: 'Centro de Custo' },
  { id: 'valor_percentual', numeric: false, disablePadding: true, label: 'Rateio %' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const acoesTabela = ['editar', 'remove']

const FormPlanoContas = ({ dados, updateField, updateFieldAtivo, planos, 
  updateFieldCentroCusto, unidades, centros_custo, updateFieldTotalizador, centro_custo, 
  departamentos, update, addCentroCusto, editarItem, removerItem, updateFieldCentroAtivo, 
  updateFieldPermCentroCusto, updateFieldUsaCentroCusto, updateCentro }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.numero + ' - ' + unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Nivel do Plano*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="nivel_superior_id"
          value={dados.nivel_superior_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.unidade_id === ''}
        >
          <option value=''> Primeiro Nivel </option>
          {planos.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(plano => {
            return (
              <option value={plano.id}> {plano.codigo} - {plano.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Codigo*" variant="outlined" size="small" name="codigo" value={dados.codigo} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Nome*" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={dados.descricao} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
          disabled={dados.unidade_id === ''}
        />
      </Grid>
    </Grid>
    
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="tipo"
          value={dados.tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.unidade_id === ''}
        >
          <option value=''> Selecionar Tipo </option>
          <option value='RECEITA'> Receita </option>
          <option value='DESPESA'> Despesa </option>
        </TextField>
      </Grid>
      <Grid item md={1} xs={false} sm={false}> </Grid>
      <Grid item md={1} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Totalizador?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ativo"
                color="primary"
                checked={dados.totalizador}
              />
            }
            size="small"
            label={dados.totalizador ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldTotalizador(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={1} xs={false} sm={false}> </Grid>
      <Grid item md={1} xs={11} sm={5}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Ativo' : 'Inativo'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={1} xs={false} sm={false}> </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Permite Centro de Custo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ativo"
                color="primary"
                checked={dados.permite_centro_custo}
              />
            }
            size="small"
            label={dados.permite_centro_custo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldPermCentroCusto(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Usar Centro de Custo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ativo"
                color="primary"
                checked={dados.uso_centro_custo}
              />
            }
            size="small"
            label={dados.uso_centro_custo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldUsaCentroCusto(e)}
          />
        </FormControl>
      </Grid>
    </Grid>

    {!dados.totalizador &&
      <React.Fragment>
        <br />
        <hr />
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h5 className="titulo">Informar Centro de Custos:</h5>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Departamento*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="departamento_id"
              value={centro_custo.departamento_id}
              onChangeCapture={(e) => updateFieldCentroCusto(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=''> </option>
              {departamentos.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(departamento => {
                return (
                  <option value={departamento.id}> {departamento.descricao} </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Centro de Custo*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="centrocusto_id"
              value={centro_custo.centrocusto_id}
              onChangeCapture={(e) => updateFieldCentroCusto(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=''> </option>
              {centros_custo.filter(param => {
                if(update) return true
                if(dados.centro_custos.findIndex(value => parseInt(value.centrocusto_id) === parseInt(param.id)) === -1) return true
                return false
              }).sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(centro => {
                return (
                  <option value={centro.id}> {centro.descricao} </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField type='number' className="input" label="Rateio %*" variant="outlined" size="small" name="valor_percentual" value={centro_custo.valor_percentual} onChange={(e) => updateFieldCentroCusto(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <FormControlLabel className="mg_left_10"
              control={
                <Switch
                  name="ativo"
                  color="primary"
                  checked={centro_custo.ativo}
                />
              }
              label={centro_custo.ativo ? "Ativo" : "Inativo"}
              name="ativo"
              onChange={(e) => updateFieldCentroAtivo(centro_custo.index)}
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6} className="alignCenter">
            <ColorDarkOrangeButton fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addCentroCusto(e)}>{updateCentro ? 'Alterar' : 'Adicionar'}</ColorDarkOrangeButton>
          </Grid>
        </Grid>
        <br />
        <hr />
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <Table
              editar={e => editarItem(e)}
              remove={e => removerItem(e)}
              headCell={cabecalhoTabela}
              rows={dados.centro_custos.map(item => {
                return {
                  _id: item.id,
                  departamento: item.departamento,
                  centrocusto: item.centrocusto,
                  valor_percentual: item.valor_percentual,
                  ativo: item.ativo,
                  index: item.index
                }
              })}
              acoes={acoesTabela} />
          </Grid>
        </Grid>
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormPlanoContas