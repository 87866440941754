import React from 'react'
import { Grid, TextField, FormControlLabel, Switch, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';

const FormPessoaJuridica = ({ dados, updateField, updateFieldPrincipal, addNovoEmail }) => (
  <React.Fragment>
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={7} xs={12} sm={6}>
        <TextField fullWidth className="input" label="E-mail" variant="outlined" size="small" name="email" value={dados.email} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="principal"
              color="primary"
              checked={dados.principal}
            />
          }
          label="Principal"
          name="principal"
          onChange={(e) => updateFieldPrincipal(e)}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <Button fullWidth onClick={() => addNovoEmail()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>{dados.index === '' ? 'Adicionar Email' : 'Alterar Email'} </Button>
      </Grid>
    </Grid>
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <WarningIcon style={{color: 'red'}}/><span style={{marginLeft: 5,fontSize: 14}} className='error'>Esse email ainda não foi {dados.index === '' ? 'adicionado' : 'alterado'}, para confirmar clique em "{dados.index === '' ? 'ADICIONAR EMAIL' : 'ALTERAR EMAIL'}"</span>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormPessoaJuridica