import React, { useState, useEffect } from 'react';
import './Departamento.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import FormDepartamento from '../../components/forms/FormDepartamento'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
  departamento: {
    id: "",
    descricao: "",
    unidade_id: '',
    ativo: true,
    centro_custos: []
  },
  centro_custo:{
    descricao: '',
    ativo: true,
    index: ''
  },
  departamentos: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Dados do Departamento...',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  update: false,
  unidades: {
    list: []
  },
  tipo: 'UNIDADE',
  openModalAjuda: false,
  markdown: ''
}

const CadastroDepartamento = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { departamentoId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    const self = this
    setTimeout(() => {
      navigate("/departamentos");
    }, timeout)
  }

  const updateField = (event) => {
    const departamento = { ...state.departamento }
    departamento[event.target.name] = event.target.value

    setState(prevState => ({...prevState, departamento }))
  }

  const updateFieldAtivo = () => {
    const departamento = { ...state.departamento }
    departamento.ativo = !departamento.ativo
    setState(prevState => ({...prevState, departamento }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldCentro = (event) =>{
    const {centro_custo} = state
    centro_custo[event.target.name] = event.target.value
    setState(prevState => ({...prevState,
      centro_custo
    }))
  }

  const updateFieldCentroAtivo = () => {
    const {centro_custo} = state
    centro_custo.ativo = !centro_custo.ativo
    setState(prevState => ({...prevState,centro_custo}))
  }

  const validateCentroCusto = () => {
    const centro_custo = state.centro_custo

    if (!centro_custo.descricao) return false

    return true
  }

  const addCentroCusto = () => {
    if (!validateCentroCusto()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          autoHideDuration: 5000,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }
    const departamento = state.departamento
    const centro_custo = state.centro_custo

    const itens = departamento.centro_custos

    if (centro_custo.index !== '') {
      const index = itens.findIndex(param => param.index === centro_custo.index)

      itens[index] = {
        id: centro_custo.id,
        descricao: centro_custo.descricao,
        ativo: centro_custo.ativo,
        index: centro_custo.index
      }
    } else {
      const index = itens.length + 1

      itens.push({
        descricao: centro_custo.descricao,
        ativo: centro_custo.ativo,
        index
      })
    }

    departamento.centro_custos = itens

    setState(prevState => ({...prevState,
      departamento,
      centro_custo:{
        descricao: '',
        ativo: true,
        index: ''
      },
      update: false
    }))
  }

  const editarItem = (index) => {
    const aux = state.departamento.centro_custos.filter(param => param.index === index)

    setState(prevState => ({...prevState,
      update: true,
      centro_custo: aux[0]
    }))
  }

  const removerItem = (index) => {
    const departamento = state.departamento

    let itens = departamento.centro_custos

    itens.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id:itens[i].id,
        descricao: itens[i].descricao,
        ativo: itens[i].ativo,
        index: i + 1
      })
    }

    itens = aux

    departamento.centro_custos = itens

    setState(prevState => ({...prevState, departamento }))
  }

  const validateForm = () => {
    const { departamento } = state

    if (!departamento.descricao) return false
    if (!departamento.unidade_id) return false

    return true
  }

  const salvar = async () => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    setState(prevState => ({...prevState, 
      loading: true,
      mensagemLoading: 'Cadastrando/Alterando Departamento...'
    }))

    const departamento = state.departamento

    const dados = {
      descricao: departamento.descricao,
      unidade_id: departamento.unidade_id,
      centro_custos: departamento.centro_custos,
      ativo: departamento.ativo
    }

    try {

      const method = departamento.id !== "" ? 'put' : 'post'
      const url = departamento.id !== "" ? `/departamentos/${departamento.id}` : `/departamentos`

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loading: false
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }

  }

  useEffect(() => {
    const fetchData = async () => {
     
      const helpPath = require("./../../help/departamento/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        departamento: {
          id: "",
          descricao: "",
          unidade_id: '',
          ativo: true,
          centro_custos: []
        },
        centro_custo:{
          descricao: '',
          ativo: true,
          index: ''
        },
        departamentos: {
          list: []
        }
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'departamento')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

      setState(prevState => ({...prevState, tipo }))

      if (departamentoId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warnig',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          const {departamento} = state
          departamento.unidade_id = unidades[0].id
          setState(prevState => ({...prevState,departamento}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      if (departamentoId) {
        try {

          const { data: departamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/departamentos/${departamentoId}`, getToken())

          setState(prevState => ({...prevState,
            departamento: {
              id: departamento.id,
              descricao: departamento.descricao,
              unidade_id: departamento.unidade_id,
              centro_custos: departamento.centro_custos.map((centro, key) => {
                return {
                  id: centro.id,
                  descricao: centro.descricao,
                  ativo: centro.ativo,
                  index: key
                }
              }),
              ativo: departamento.ativo
            },
            loading: false
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
        }
      }

      setState(prevState => ({...prevState,
        loading: false
      }))

    };
    
    fetchData();
  }, []);


  const { departamento } = state
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={12}>
                <h1 className="titulo">Cadastro de Departamentos</h1>
              </Grid>
            </Grid>
            <FormDepartamento 
              updateField={e => updateField(e)} 
              updateFieldAtivo={e => updateFieldAtivo(e)} 
              dados={departamento} 
              centro_custo={state.centro_custo}
              updateFieldCentro={e => updateFieldCentro(e)}
              updateFieldCentroAtivo={e => updateFieldCentroAtivo(e)}
              addCentroCusto={e => addCentroCusto(e)}
              update={state.update} 
              editarItem={e => editarItem(e)}
              removerItem={e => removerItem(e)}
              unidades={state.unidades.list}
            />
            <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
              <Grid item md={2} xs={12} sm={6}>
                <Button fullWidth color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />} onClick={e => backPage(0)}>Voltar</Button>
              </Grid>
              <Grid item md={8} xs={false} sm={6}></Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Departamento"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default CadastroDepartamento