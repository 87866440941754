import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  const {unidades, dados, tabelaPrecos, loadingSalvar, itens, updateFieldAgruparCredito} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={10} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Configuração de Voucher</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={3} xs={12} sm={4}>
              <TextField
                id="unidade_id"
                select
                label="Unidade*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_id"
                value={dados.unidade_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Unidade</option>
                {unidades.map(unidade => {
                  return (
                    <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12} sm={4}>
              <TextField 
                id='configuracao'
                className="input" 
                label="Nome*" 
                variant="outlined" 
                size="small" 
                name="nome" 
                value={dados.nome} 
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <TextField
                id="habilita"
                select
                label="Tipo*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="habilita"
                value={dados.habilita}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Tipo</option>
                <option value="PRODUTO">Produto</option>
                <option value="CREDITO">Crédito</option>
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={5}>
              <FormControlLabel className="mg_left_10"
                control={
                  <Switch
                    name="Ativo"
                    color="primary"
                    checked={dados.ativo}
                  />
                }
                label="Ativo"
                name="ativo"
                onChange={(e) => props.updateFieldAtivo(e)}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={4}>
              <TextField
                id="tab_prom_id"
                select
                label="Validade por*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="validade_por"
                value={dados.validade_por}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar </option>
                <option value="DATA_FIXA"> Data Fixa</option>
                <option value="QTD_DIAS"> Quantidade de Dias</option>
              </TextField>
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <TextField
                id="tab_prom_id"
                select
                label="Tabela Promocional*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="tab_prom_id"
                value={dados.tab_prom_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
                disabled={dados.validade_por === ''}
              >
                <option value=""> Selecionar Tabela</option>
                {tabelaPrecos.map(tabela => {
                  return (
                    <option key={tabela.id} value={tabela.id}> {tabela.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            {dados.validade_por === 'QTD_DIAS' &&
              <Grid item md={2} xs={12} sm={4}>
                <TextField 
                  id='dias_val_tab_prom'
                  type='number'
                  className="input" 
                  label="Val. Tab. Prom. (Dias)*" 
                  variant="outlined" 
                  size="small" 
                  name="dias_val_tab_prom" 
                  value={dados.dias_val_tab_prom} 
                  onChange={(e) => props.updateField(e)} 
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            }
            {dados.validade_por === 'DATA_FIXA' &&
              <Grid item md={2} xs={12} sm={4}>
                <TextField 
                  id='data_val_tab_prom'
                  type='date'
                  className="input" 
                  label="Val. Tab. Prom. (Data)*" 
                  variant="outlined" 
                  size="small" 
                  name="data_val_tab_prom" 
                  value={dados.data_val_tab_prom} 
                  onChange={(e) => props.updateField(e)} 
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            }
            <Grid item md={3} xs={12} sm={4}>
              <TextField
                id="tab_normal_id"
                select
                label="Tabela Normal*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="tab_normal_id"
                value={dados.tab_normal_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
                disabled={dados.validade_por === ''}
              >
                <option value=""> Selecionar Tabela</option>
                {tabelaPrecos.map(tabela => {
                  return (
                    <option key={tabela.id} value={tabela.id}> {tabela.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            {dados.validade_por === 'QTD_DIAS' &&
              <Grid item md={2} xs={12} sm={4}>
                <TextField 
                  id='dias_val_tab_normal'
                  type='number'
                  className="input" 
                  label="Val. Tab. Normal (Dias)*" 
                  variant="outlined" 
                  size="small" 
                  name="dias_val_tab_normal" 
                  value={dados.dias_val_tab_normal} 
                  onChange={(e) => props.updateField(e)} 
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            }
            {dados.validade_por === 'DATA_FIXA' &&
              <Grid item md={2} xs={12} sm={4}>
                <TextField 
                  id='data_val_tab_normal'
                  type='date'
                  className="input" 
                  label="Val. Tab. Normal (Data)*" 
                  variant="outlined" 
                  size="small" 
                  name="data_val_tab_normal" 
                  value={dados.data_val_tab_normal} 
                  onChange={(e) => props.updateField(e)} 
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            }
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={4}>
              <TextField
                id="item_rd_id"
                select
                label="Item de Receita*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="item_rd_id"
                value={dados.item_rd_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Item</option>
                {itens.map(item => {
                  return (
                    <option key={item.id} value={item.id}> {item.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            {dados.habilita === 'CREDITO' &&
              <Grid item md={2} xs={12} sm={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Agrupar Crédito?</FormLabel>
                  <FormControlLabel className="mg_left_10"
                    control={
                      <Switch
                        name="Ativo"
                        color="primary"
                        checked={dados.agrupar_credito}
                      />
                    }
                    size="small"
                    label={dados.agrupar_credito ? 'Sim' : 'Não'}
                    name="agrupar_credito"
                    onChange={(e) => updateFieldAgruparCredito(e)}
                  />
                </FormControl>
              </Grid>
            }
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="descrição"
                label="Descrição"
                className="input"
                multiline
                rows={3}
                size="small"
                variant="outlined"
                name="descricao"
                value={dados.descricao}
                onChange={(e) => props.updateField(e)}
              />
            </Grid>
          </Grid>             
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="secondary" 
                startIcon={<ArrowBackIcon />}
                onClick={e => props.confirmar(false)}
                disabled={loadingSalvar}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item md={8} xs={12} sm={4}></Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="primary" 
                startIcon={<AddIcon />}
                onClick={e => props.confirmar(true)}
                disabled={loadingSalvar}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
          <Tooltip title='Ajuda' aria-label="add" arrow>
            <HelpIcon className='botao_ajuda' onClick={() => props.openModalAjudaCadastro()}/>
          </Tooltip>
        </StyledPaper>
      </Modal>
    </div>
  );
}
