import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import Table from './../Table'
import SaveIcon from '@mui/icons-material/Save';
import { Grid, TextField, FormControlLabel, Switch, Button } from '@mui/material'

import { styled } from '@mui/system';
import Color from 'color';

const ColorDarkOrangeButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: Color('#FF8C00').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#FF8C00',
  },
}));

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Centro de Custo' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const acoesTabela = ['editar', 'removeCentroCusto']

const FormDepartamento = ({editarItem, removerItem, addCentroCusto, update, unidades, updateFieldCentro,updateFieldCentroAtivo, centro_custo, dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Descrição" 
          variant="outlined" 
          size="small" 
          name="descricao" 
          value={dados.descricao} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_id"
          value={dados.unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Unidade</option>
          {unidades.map(unidade => {
            return (
              <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.ativo}
            />
          }
          label="Ativo"
          name="ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={6}>
        <h4 className="titulo">Centros de Custo:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10"> 
      <Grid item md={6} xs={12} sm={6}>
        <TextField 
        className="input" 
        label="Descricao" 
        variant="outlined"
        size="small" 
        name="descricao" 
        value={centro_custo.descricao} 
        onChange={(e) => updateFieldCentro(e)}
        InputLabelProps={{ shrink: true}} />
      </Grid>
      <Grid item md={1} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="ativo"
              color="primary"
              checked={centro_custo.ativo}
            />
          }
          label={centro_custo.ativo ? "Ativo" : "Inativo"}
          name="ativo"
          onChange={(e) => updateFieldCentroAtivo(centro_custo.index)}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6} className="alignCenter">
        <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={update ? <AddIcon /> : <SaveIcon />} onClick={e => addCentroCusto(e)}>{update ? 'Alterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <Table
          editar={e => editarItem(e)}
          remove={e => removerItem(e)}
          headCell={cabecalhoTabela}
          rows={dados.centro_custos.map(item => {
            return {
              _id: item.id,
              descricao: item.descricao,
              ativo: item.ativo,
              index: item.index
            }
          })}
          acoes={acoesTabela} />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormDepartamento