import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, IconButton, Button} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import PanToolIcon from '@mui/icons-material/PanTool';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '88%',
  marginLeft: '6%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  
  let {voucher, cliente, clientes, buscarPessoa, updateFiltroPessoa, updateFieldCliente} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Alterar Comprador Voucher</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" style={{marginTop: 10}}>
            <Grid item md={3} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Comprador Atual" 
                variant="outlined" 
                size="small" 
                name="cliente" 
                value={voucher.responsavel.pessoa.tipo === 'FISICA' ? 
                  (voucher.responsavel.pessoa.fisica.nome + (voucher.responsavel.pessoa.fisica.sobrenome ? ` ${voucher.responsavel.pessoa.fisica.sobrenome}` : '')) : 
                  voucher.responsavel.pessoa.juridica.nomefantasia}
                InputLabelProps={{ shrink: true }} 
                disabled={true}/>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <Autocomplete
                onChange={(event, value) => updateFieldCliente(event, value)}
                freeSolo
                options={clientes}
                value={cliente}
                id='cliente'
                getOptionLabel={option => `${option.nome}`}
                renderInput={(params) => (
                  <TextField 
                    InputProps={{ ...params.InputProps, type: 'search' }} 
                    {...params} 
                    size="small" 
                    label="Novo Comprador*" 
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }} 
                    value={`${cliente.nome}`}
                    onChange={(e) => updateFiltroPessoa(e)}
                  />
                )}
              />
            </Grid>
            <Grid item md={1} xs={12} sm={2}>
              <Tooltip title='Buscar Comprador' aria-label="add">
                <IconButton color="primary" aria-label="remove" style={{marginTop: '-5px', marginLeft: '0px'}} onClick={e => buscarPessoa()}>
                  <PanToolIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={cliente.pessoa_id === ''}>Confirmar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
