import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, Checkbox, FormControlLabel } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p className='desc-ausencia'>Ausência:</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={12} sm={12}>
              <p className='desc-ausencia'>Data Inicial: {dados.afafun_data_inicial_form}</p>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <p className='desc-ausencia'>Data Final: {dados.afafun_data_final_form}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <p className='desc-ausencia'>Motivo: {dados.afafun_motivo}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja remarcar essa Ausência Temporária?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              {dados.remarcar &&
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" checked={true} />}
                  onClick={e => props.updateFieldRemarcar(e)}
                  label="Remarcar Afastamento"
                  labelPlacement="end"
                />
              }
              {!dados.remarcar &&
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" checked={false} />}
                  onClick={e => props.updateFieldRemarcar(e)}
                  label="Remarcar Afastamento"
                  labelPlacement="end"
                />
              }
            </Grid>
          </Grid>
          {dados.remarcar &&
            <Grid container direction="row" spacing={1}>
              <Grid item md={4} xs={12} sm={12}>
                <TextField
                  className="input"
                  label="Data Inicio*"
                  variant="outlined"
                  size="small"
                  type='date'
                  name="afafun_data_inicial"
                  value={dados.afafun_data_inicial}
                  onChange={(e) => props.updateData(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled />
              </Grid>
              <Grid item md={2} xs={12} sm={12}>
                <TextField
                  className="input"
                  label="Hora*"
                  variant="outlined"
                  size="small"
                  type='time'
                  name="afafun_hora_inicial"
                  value={dados.afafun_hora_inicial}
                  onChange={(e) => props.updateData(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled />
              </Grid>
              <Grid item md={4} xs={12} sm={12}>
                <TextField
                  className="input"
                  label="Nova Data Final*"
                  variant="outlined"
                  size="small"
                  type='date'
                  name="afafun_data_final_nova"
                  InputProps={{ inputProps: { min: moment(dados.afafun_data_inicial).format('YYYY-MM-DD') } }}
                  value={dados.afafun_data_final_nova}
                  onChange={(e) => props.updateData(e)}
                  InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={12}>
                <TextField
                  className="input"
                  label="Hora*"
                  variant="outlined"
                  size="small"
                  type='time'
                  name="afafun_hora_final_nova"
                  value={dados.afafun_hora_final_nova}
                  onChange={(e) => props.updateData(e)}
                  InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='avisoConciliacao'><i>Esta operação não poderá ser desfeita.</i></p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.voltar()}>Voltar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar()}>{dados.remarcar ? 'Remarcar' : 'Excluir'}</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
