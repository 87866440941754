import React, { Component } from 'react'
import './ParceirosBancarios.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import Alert from './../../components/templates/Alert'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  parceirosBancarios: {
    list: []
  },
  parceirosBancariosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    
  },
  loading: true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

export default class Funcao extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })
    
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'parceiros-bancario')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/parceiroBancario`, this.getToken())

      let parceirosBancarios = data.map(parceiro => {
        return {
          _id: parceiro.id,
          descricao: parceiro.descricao,
          ativo: parceiro.ativo
        }
      })
      
      this.setState({
        parceirosBancarios: {
          list: parceirosBancarios
        },
        parceirosBancariosFiltrado: {
          list: parceirosBancarios
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { parceirosBancariosFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Parceiros...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={10} xs={12} sm={6}>
                  <h1 className="titulo">Parceiros Bancarios</h1>
                </Grid>
                {permissoes.inserir &&
                  <Grid item md={2} xs={12} sm={6}>
                    <Link to="/parceiros_bancario/cadastro">
                      <Button size="small"fullWidth variant="contained" color="primary">
                        Cadastrar Parceiro
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/parceiros_bancario/cadastro/" headCell={this.state.cabecalhoTabela} rows={parceirosBancariosFiltrado.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
         <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message}
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Parceiros Bancarios"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}