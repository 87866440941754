export default {
  contratos: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade'},
    { id: 'data_ini_vig_form', numeric: false, disablePadding: false, label: 'Data Inicio'},
    { id: 'data_fim_vig_form', numeric: false, disablePadding: false, label: 'Data Fim' },
    { id: 'situacao_atual', numeric: false, disablePadding: false, label: 'Situação' }
  ],
  acoesTabela: ['viewContrato'],
  unin_tipo: "",
  filtro: {
    situacao: "",
    unidade_id: ""
  },
  loading: true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  openModalContrato: false,
  dadosContrato: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    produtos: [],
    formpag_id: '',
    contfin_id: '',
    dias_pagamento: '',
    juros: '',
    observacao: '',
    template_versao_id: "",
    assinar_contrato: false,
  },
  servico: {
    prod_id: "",
    prod_descricao: "",
    id: "",
    dia_vencimento: "",
    parametro: "",
    valor: "",
    ativo: true,
    habilita_valor_min: false,
    habilita_valor_max: false,
    inativa_unidade: true,
    valor_min: '0',
    valor_max: '0',
    index: "",
    gerar_nfse: false
  },
  update: false,
  dadosContratoView: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    produtos: [],
    formpag_id: '',
    contfin_id: '',
    dias_pagamento: '',
    juros: '',
    observacao: ''
  },
  financeiros_pendentes: {
    list: []
  },
  openModalCancelarContratoUnidade: false,
  dadosCancelamento: {
    lanc_fin: false,
    motivoCancelamento: ''
  },
  conta: {
    descricao: "",
    tipo: "",
    valortotal: "",
    numparcela: 1,
    item_id: "",
    qtd_parcelas: 1,
    parcelas: [
      {
        baixado: "",
        datavencimento: "",
        datavencimento_orig: "",
        valorparcela: "",
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        contfin_id: '',
        index: 1
      }
    ]
  },
  produtos: {
    list: []
  },
  itens_receita_despesa: {
    list: []
  },
  openModalAdicionarServContratoUnidade: false,
  contasFinanceira:{
    list: []
  },
  formasPagamento:{
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades: {
    list: []
  },
  itemSelecionado:{
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  },
  possui_emissao_nfse: false,
  template_documentos: {
    list: []
  },
  permissoesContrato: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
}