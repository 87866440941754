import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',
  marginTop: 100,

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <StyledPaper theme={theme}>
                <Grid container direction="row" spacing={1} className="borderBottom">
                  <Grid item md={12} xs={12} sm={12}>
                    <h5 className="tituloConfirmacao">Confirmar Baixa</h5>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className='mg_top_10'>
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField type="date" className="input" label="Data Pagamento*" variant="outlined" size="small" name="datapagamento" value={props.baixaConta.datapagamento} onChange={(event) => props.updateFieldBaixaConta(event)} InputLabelProps={{ shrink: true }}/>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className='mg_top_10'>
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      label="Contas Bancárias*"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="contfin_id"
                      value={props.baixaConta.contfin_id}
                      onChangeCapture={(e) => props.updateFieldBaixaConta(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">--Escolha--</option>
                      {props.contas_financeiras.map(forma => {
                        return (
                          <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className='mg_top_10'>
                  <Grid item md={12} xs={12} sm={12}>
                    <p> Deseja confirmar a baixa das parcelas selecionadas?</p>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} >
                  <Grid item md={6} xs={6} sm={6}>
                    <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.baixaConta.contfin_id === '' ? true : props.loadingBaixaConta}>Confirmar</Button>
                  </Grid>
                  <Grid item md={6} xs={6} sm={6}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.loadingBaixaConta}>Cancelar</Button>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    </div >
  );
}
