import React, { Component } from 'react'
// import './../Produto.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import Loading from './../../components/templates/Loading'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  grupoProduto: {

  },
  subGrupoProdutos: {
    list: []
  },
  subGrupoProdutosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'grupoProduto', numeric: false, disablePadding: true, label: 'Grupo Produto' },
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    descricao: "",
    grupoProduto: ""
  },
  loading: true,
  mensagemLoading: 'Carregando Sub Grupos de Produtos...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Cargo extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'sub-grupo-produtos')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    this.setState({
      unin_tipo
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/subGrupoProdutos/list`, dados, this.getToken())

      const subGrupoProdutos = []

      resp.data.forEach(subGrupoProduto => {
        subGrupoProdutos.push({
          _id: subGrupoProduto.id,
          grupoProduto: subGrupoProduto.grupoproduto.descricao,
          descricao: subGrupoProduto.descricao,
          unidade: subGrupoProduto.grupoproduto.unidade.descricao ? `${subGrupoProduto.grupoproduto.unidade.numero} - ${subGrupoProduto.grupoproduto.unidade.descricao}` : (subGrupoProduto.grupoproduto.unidade.pessoa.tipo === 'FISICA' ? subGrupoProduto.grupoproduto.unidade.pessoa.fisica.nome : subGrupoProduto.grupoproduto.unidade.pessoa.juridica.razaosocial),
          ativo: subGrupoProduto.ativo
        })
      })

      this.setState({
        subGrupoProdutos: {
          list: subGrupoProdutos
        },
        subGrupoProdutosFiltrado: {
          list: subGrupoProdutos
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const subGrupoProdutos = this.state.subGrupoProdutos.list
    const filtro = this.state.filtro

    const list = subGrupoProdutos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      subGrupoProdutosFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, subGrupoProdutosFiltrado, permissoes } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="borderBottom"
                >
                  <Grid item md={8} xs={12} sm={6}>
                    <h1 className="titulo">Sub-Grupo de Produtos</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={4} xs={12} sm={6}>
                      <Link to="/sub_grupo_produtos/cadastro">
                        <Button size='small' className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar Sub-Grupo de Produtos
                        </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Sub Grupo" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Grupo" variant="outlined" size="small" name="grupoProduto" value={filtro.grupoProduto} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10" >
                  <Grid item md={12} xs={12}>
                    <Table headCell={this.state.cabecalhoTabela} rows={subGrupoProdutosFiltrado.list} acoes={this.state.acoesTabela} urlUpdate="/sub_grupo_produtos/cadastro/" />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Sub-Grupos de Produtos"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}