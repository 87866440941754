import React from 'react'
import { Grid, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';

const FormContaFinanceira = ({ dados, updateFieldBanco, updateFieldContaBancaria, banco, bancos, contaBancaria }) => (
  <React.Fragment>
    {dados.tipo_conta === 'BANCO' &&
      <React.Fragment> 
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={6} xs={12} sm={6}>
            <Autocomplete
              onChange={(event, value) => updateFieldBanco(event, value)}
              freeSolo
              options={bancos}
              value={banco}
              getOptionLabel={option => `${option.numero} - ${option.nome}`}
              renderInput={(params) => (
                <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Banco*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${banco.numero} - ${banco.nome}`} />
              )}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField size="small" className="input" label="Núm. Agência*" variant="outlined" name="agencia" value={contaBancaria.agencia} onChange={(e) => updateFieldContaBancaria(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField size="small" className="input" label="Núm. Conta*" variant="outlined" name="numero" value={contaBancaria.numero} onChange={(e) => updateFieldContaBancaria(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={6} xs={12} sm={6}>
            <TextField size="small" className="input" label="Gerente" variant="outlined" name="gerente" value={contaBancaria.gerente} onChange={(e) => updateFieldContaBancaria(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField size="small" className="input" label="Chave Pix" variant="outlined" name="chave_pix" value={contaBancaria.chave_pix} onChange={(e) => updateFieldContaBancaria(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
        </Grid> 
      </React.Fragment>
    }

    {dados.tipo_conta !== 'BANCO' &&
      <React.Fragment> 
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h5>Apenas para contas do tipo BANCO</h5>
          </Grid>
        </Grid>
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormContaFinanceira