import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, Button, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../../components/templates/Alert'
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import moment from 'moment'
import Table from './../../../components/Table'

const initialState = {
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  horarios: [],
  horario: {
    id: '',
    dia_semana: '',
    hora_inicio: '',
    hora_fim: '',
    ordem: 0,
    index: ''
  },
  loading:true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  desabilitarCampos: false,
  cabecalhoTabela: [
    { id: 'dia_semana', numeric: false, disablePadding: true, label: 'Dia da Semana' },
    { id: 'hora_inicio', numeric: false, disablePadding: true, label: 'Hora Inicio' },
    { id: 'hora_fim', numeric: false, disablePadding: false, label: 'Hora Fim' }
  ],
  acoesTabela:[],
}

const formataDiaSemana = (value) => {
  if(value === "segunda-feira") return 'Segunda-Feira'
  if(value === "terça-feira") return 'Terça-Feira'
  if(value === "quarta-feira") return 'Quarta-Feira'
  if(value === "quinta-feira") return 'Quinta-Feira'
  if(value === "sexta-feira") return 'Sexta-Feira'
  if(value === "sábado") return 'Sábado'
  if(value === "domingo") return 'Domingo'
}

export default class Agenda extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'horario-trabalho-funcionario')[0]

    if (permissoes) {
      if(permissoes.inserir){
        this.setState({
          acoesTabela: ['editar', 'removeTabela']
        })
      }

      this.setState({
        permissoes
      })
    }

    const {funcionario} = this.props


    this.setState({
      horarios: funcionario.horarios_trabalho.map((value, key) => {
        let ordem = 0

        if(value.dia_semana === "segunda-feira") ordem = 1
        if(value.dia_semana === "terça-feira") ordem = 2
        if(value.dia_semana === "quarta-feira") ordem = 3
        if(value.dia_semana === "quinta-feira") ordem = 4
        if(value.dia_semana === "sexta-feira") ordem = 5
        if(value.dia_semana === "sábado") ordem = 6
        if(value.dia_semana === "domingo") ordem = 7

        return {
          id: value.id,
          dia_semana: value.dia_semana,
          hora_inicio: value.hora_inicio,
          hora_fim: value.hora_fim,
          ordem,
          index: key
        }
      }),
      loading: false
    })
  
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateField(event){
    let {horario} = this.state

    horario[event.target.name] = event.target.value

    this.setState({
      horario
    })
  }

  adicionarHorario(){
    let {horario, horarios} = this.state

    if(horario.dia_semana === ''){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o dia da semana!'
        }
      })
      return
    }

    if(horario.hora_inicio === ''){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o horário de inicio!'
        }
      })
      return
    }

    if(horario.hora_fim === ''){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o horário de fim!'
        }
      })
      return
    }

    if(horario.dia_semana === "segunda-feira") horario.ordem = 1
    if(horario.dia_semana === "terça-feira") horario.ordem = 2
    if(horario.dia_semana === "quarta-feira") horario.ordem = 3
    if(horario.dia_semana === "quinta-feira") horario.ordem = 4
    if(horario.dia_semana === "sexta-feira") horario.ordem = 5
    if(horario.dia_semana === "sábado") horario.ordem = 6
    if(horario.dia_semana === "domingo") horario.ordem = 7

    if(horario.index === ''){
      horario.index = horarios.length
      horarios.push({
        ...horario
      })
    }else{
      horarios[horario.index] = {
        ...horario
      }
    }

    this.setState({
      horarios,
      horario: {
        id: '',
        dia_semana: '',
        hora_inicio: '',
        hora_fim: '',
        ordem: 0,
        index: ''
      }
    })
  }

  editarHorario(index){
    let {horario, horarios} = this.state

    horario = {...horarios[index]}

    this.setState({
      horario
    })
  }

  removerHorario(index){
    let {horarios} = this.state

    horarios.splice(index, 1)

    for (let i = 0; i < horarios.length; i++) {
      horarios[i].index = i      
    }

    this.setState({
      horarios
    })
  }

  async salvar(){
    const {horarios} = this.state
    const {funcionario} = this.props

    try {

      const dados = {
        funcionario_id: funcionario.id,
        horarios: horarios.map(value => {
          return {
            id: value.id,
            dia_semana: value.dia_semana,
            hora_inicio: value.hora_inicio,
            hora_fim: value.hora_fim
          }
        })
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/funcionarios/horarioFuncionario`, dados, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      })

      // window.location.reload()
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  render() {
    const {horarios, permissoes, horario } = this.state
    const {funcionario} = this.props

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Horários...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <React.Fragment>
            {!funcionario.status && 
              <h4>Este colaborador não se encontra mais ativo!</h4>
            }
            {funcionario.status && 
              <React.Fragment>
                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={12}>
                    <h3 className='titulo'>Horários de Trabalho:</h3>
                  </Grid>
                </Grid>
                {permissoes.inserir &&
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={3}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Dia da Semana"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="dia_semana"
                        value={horario.dia_semana}
                        onChangeCapture={(e) => this.updateField(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Selecionar Dia da Semana</option>                      
                        <option value="segunda-feira">Segunda-Feira</option>                      
                        <option value="terça-feira">Terça-Feira</option>                      
                        <option value="quarta-feira">Quarta-Feira</option>                      
                        <option value="quinta-feira">Quinta-Feira</option>                      
                        <option value="sexta-feira">Sexta-Feira</option>                      
                        <option value="sábado">Sábado</option>                      
                        <option value="domingo">Domingo</option>                      
                      </TextField>
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField 
                        id='ambiente'
                        type='time'
                        className="input" 
                        label="Horário de Inicio" 
                        variant="outlined" 
                        size="small" 
                        name="hora_inicio"
                        value={horario.hora_inicio}
                        onChange={(e) => this.updateField(e)} 
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField 
                        id='ambiente'
                        type='time'
                        className="input" 
                        label="Horário Final" 
                        variant="outlined" 
                        size="small" 
                        name="hora_fim"
                        value={horario.hora_fim}
                        onChange={(e) => this.updateField(e)} 
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <Button fullWidth color="warning" variant="contained" size="small" onClick={e => this.adicionarHorario(e)}>Adicionar</Button>    
                    </Grid>         
                  </Grid>
                }
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={6} xs={12} sm={12}>
                    <Table
                      headCell={this.state.cabecalhoTabela}
                      rows={horarios.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                        return {
                          dia_semana: formataDiaSemana(value.dia_semana),
                          hora_inicio: value.hora_inicio,
                          hora_fim: value.hora_fim,
                          index: value.index
                        }
                      })}
                      acoes={this.state.acoesTabela}
                      editar={(e) => this.editarHorario(e)}
                      remove={(e) => this.removerHorario(e)}
                    />
                  </Grid>
                </Grid>
                <hr className="mg_top_10"/>
                {permissoes.inserir &&
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={10} xs={12} sm={9}></Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>    
                    </Grid>  
                  </Grid>
                }
              </React.Fragment>
            }
          </React.Fragment>
        }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} />
      </React.Fragment>
    )
  }
}