import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from '../pages/login/Login'
import Home from '../pages/home/Home'
import Admin from '../pages/admin/Admin'

import Usuario from '../pages/usuario/Usuario'

import Representante from '../pages/representante/Representante'
import RepresentanteCadastro from '../pages/representante/Cadastro'
import RepresentanteUpdate from '../pages/representante/Update'
import RepresentanteUpdateResponsavel from '../pages/representante/CadastroResp'
import RepresentanteView from '../pages/representante/View'

import Franqueado from '../pages/franqueado/Franqueado'
import FranqueadoCadastro from '../pages/franqueado/Cadastro'
import FranqueadoUpdateResponsavel from '../pages/franqueado/CadastroResp'
import FranqueadoView from '../pages/franqueado/View'

import Unidade from '../pages/unidade/Unidade'
import UnidadeCadastro from '../pages/unidade/Cadastro'
import UnidadeView from '../pages/unidade/View'
import UnidadeUpdate from '../pages/unidade/Update'

import Cargo from '../pages/cargo/Cargo'
import CargoCadastro from '../pages/cargo/Cadastro'

import Funcionario from '../pages/funcionario/Funcionario'
import FuncionarioCadastro from '../pages/funcionario/Cadastro'
import FuncionarioView from '../pages/funcionario/View'
import FuncionarioContrato from '../pages/funcionario/Contrato'
import FuncionarioUpdateUsuario from '../pages/funcionario/UpdateUsuario'

import GrupoProdutos from '../pages/grupoProduto/GrupoProduto'
import GrupoProdutosCadastro from '../pages/grupoProduto/Cadastro'

import SubGrupoProdutos from '../pages/subGrupoProduto/SubGrupoProduto'
import SubGrupoProdutosCadastro from '../pages/subGrupoProduto/Cadastro'

import Produtos from '../pages/produto/Produto'
import ProdutosCadastro from '../pages/produto/Cadastro'
import ProdutosView from '../pages/produto/View'

import Departamentos from '../pages/departamento/Departamento'
import DepartamentosCadastro from '../pages/departamento/Cadastro'

import Funcao from '../pages/funcao/Funcao'
import FuncaoCadastro from '../pages/funcao/Cadastro'

import Perfil from '../pages/perfil/Perfil'
import PerfilCadastro from '../pages/perfil/Cadastro'

import Estoque from './../pages/estoque/Estoque'
import EstoqueCadastro from './../pages/estoque/Cadastro'

import TabelaPreco from './../pages/tabelaPrecos/TabelaPreco'
import TabelaPrecoCadastro from './../pages/tabelaPrecos/Cadastro'

import Cliente from './../pages/cliente/Cliente'
import ClienteCadastro from './../pages/cliente/Cadastro'
import ClienteView from './../pages/cliente/View'

import PlanoContas from './../pages/planoContas/PlanoContas'
import PlanoContasCadastro from './../pages/planoContas/Cadastro'
import PlanoContasView from './../pages/planoContas/View'

import ContaFinanceira from './../pages/contaFinanceira/ContaFinanceira'
import ContaFinanceiraCadastro from './../pages/contaFinanceira/Cadastro'
import ContaFinanceiraCorrigirSaldo from './../pages/contaFinanceira/CorrigirSaldo'

import Movimentacao from './../pages/movimentacao/Movimentacao'
import MovimentacaoCadastro from './../pages/movimentacao/Cadastro'
import ImportarExtrato from './../pages/movimentacao/ImportarExtrato'

import ContaPagarReceber from './../pages/contaPagarReceber/ContaPagarReceber'
import ContaPagarReceberCadastro from './../pages/contaPagarReceber/Cadastro'
import ContaPagarReceberUpdate from './../pages/contaPagarReceber/Update'

import RelatorioFinanceiro from './../pages/relatorios/Financeiro'
import RelatorioFluxoCaixa from './../pages/relatorios/FluxoCaixa'
import RelatorioComissoes from './../pages/relatorios/Comissoes'
import RelatorioVendas from './../pages/relatorios/Vendas'
import NFSE from './../pages/relatorios/NFSE'
import RelatorioAdministrativos from './../pages/relatorios/Administrativos'

import GraficoVendaVoucher from './../pages/relatorios/impressao/sintetico_voucher'

import Caixa from '../pages/caixa/Caixa'
import CaixaCadastro from '../pages/caixa/Cadastro'
import CaixaView from '../pages/caixa/View'
import SolicitacaoAberturaCaixa from '../pages/caixa/SolicitacaoAberturaCaixa'

import FechamentoCaixa from '../pages/fechamentoCaixa/FechamentoCaixa'
import FechamentoCaixaView from '../pages/fechamentoCaixa/View'

import ServicosBancarios from './../pages/servicosBancarios/ServicosBancarios'
import ServicosBancariosCadastro from './../pages/servicosBancarios/Cadastro'

import ParceirosBancarios from './../pages/parceirosBancarios/ParceirosBancarios'
import ParceirosBancariosCadastro from './../pages/parceirosBancarios/Cadastro'

import BoletosCliente from '../pages/boletosCliente/BoletosCliente'

import AgendaAmbiente from '../pages/agendaAmbiente/AgendaAmbiente'

import Ambiente from '../pages/ambiente/Ambiente'

import ItemReceitaDespesa from '../pages/itemReceitaDespesa/ItemReceitaDespesa'

import VoucherPadrao from '../pages/voucherPadrao/VoucherPadrao'
import Voucher from '../pages/voucher/Voucher'
import VoucherView from '../pages/voucher/View'

import CentralNotificacao from './../pages/centralNotificacao/CentralNotificacao'
import CentralNotificacaoCadastro from './../pages/centralNotificacao/Cadastro'

import WhatsApp from './../pages/whatsApp/WhatsApp'
import WhatsAppCadastro from './../pages/whatsApp/Cadastro'

import Pesquisa from './../pages/pesquisa/Pesquisa'
import PesquisaCadastro from './../pages/pesquisa/Cadastro'
import PesquisaView from './../pages/pesquisa/View'

import Vendas from './../pages/vendas/Vendas'
import VendasCadastro from './../pages/vendas/Cadastro'
import VendasView from './../pages/vendas/View'

import Compras from './../pages/compras/Compras'
import ComprasCadastro from './../pages/compras/Cadastro'
import ComprasView from './../pages/compras/View'

import ContratosUnidades from './../pages/contratosUnidades/ContratosUnidades'

import Documentos from './../pages/documentos/Documentos'
import DocumentosCadastro from './../pages/documentos/Cadastro'

import AssinarContrato from './../pages/cliente/AssinarContrato'

import PreContasPR from './../pages/preContasPR/PreContasPR'
import Listagem from './../pages/preContasPR/Listagem'

import ClienteExterno from './../pages/clienteExterno/ClienteExterno'
import ClienteExternoListagem from './../pages/clienteExterno/Listagem'
import ClienteExternoView from './../pages/clienteExterno/View'

// import PrivateRoute from './auth';
import PrivateRoute from './PrivateRoutes';
const Rotas = () => (
  <Router>
    <Routes>
      <Route exact path={`/`} element={<Login />} />
      <Route exact path={`/login`} element={<Login />} />
      <Route exact path={`/admin`} element={<Admin />} />
      <Route exact path={`/boletos/cliente/:pessoaId`} element={<BoletosCliente />} />
      <Route exact path={`/assinatura_contrato/:contrato_id`} element={<AssinarContrato />} />
      <Route exact path={`/cadastro_externo/:unidadeId`} element={<ClienteExterno />} />
      
      <Route exact path='/preContasPR/:unidadeId' element={<PrivateRoute titulo='Cadastro Pré Contas'/>}>
        <Route exact path={`/preContasPR/:unidadeId`} element={<PreContasPR />} />
      </Route>
      
      <Route exact path='/preContasPR' element={<PrivateRoute titulo='Listagem de Pré Contas a Pagar e Receber'/>}>
        <Route titulo='Listagem de Pré Contas a Pagar e Receber' exact path={`/preContasPR`} element={<Listagem />} />
      </Route>
      
      <Route exact path='/home' element={<PrivateRoute titulo='Home'/>}>
        <Route titulo='Home' exact path={`/home`} element={<Home />} />
      </Route>
      
      <Route exact path='/usuario' element={<PrivateRoute titulo='Usuário'/>}>
        <Route titulo='Usuário' exact path={`/usuario`} element={<Usuario />} />
      </Route>
      
      <Route exact path='/representante' element={<PrivateRoute titulo='Representante'/>}>
        <Route titulo='Representante' exact path={`/representante`} element={<Representante />}/>
      </Route>

      <Route exact path='/representante/cadastro' element={<PrivateRoute titulo='Cadastro Representante'/>}>
        <Route titulo='Cadastro Representante' exact path={`/representante/cadastro`} element={<RepresentanteCadastro />} />
      </Route>

      <Route exact path='/representante/update/:representanteId' element={<PrivateRoute titulo='Alterar Representante'/>}>
        <Route titulo='Alterar Representante' exact path={`/representante/update/:representanteId`} element={<RepresentanteUpdate />} />
      </Route>

      <Route exact path='/representante/view/:representanteId' element={<PrivateRoute titulo='Visualizar Representante'/>}>
        <Route titulo='Visualizar Representante' exact path={`/representante/view/:representanteId`} element={<RepresentanteView />} />
      </Route>

      <Route exact path='/representante/updateResponsavel/:representanteId' element={<PrivateRoute titulo='Alterar Responsável Representante'/>}>
        <Route titulo='Alterar Responsável Representante' exact path={`/representante/updateResponsavel/:representanteId`} element={<RepresentanteUpdateResponsavel />} />
      </Route>

      <Route exact path='/franqueado' element={<PrivateRoute titulo='Franqueado'/>}>
        <Route titulo='Franqueado' exact path={`/franqueado`} element={<Franqueado />} />
      </Route>

      <Route exact path='/franqueado/view/:franqueadoId' element={<PrivateRoute titulo='Visualizar Franqueado'/>}>
        <Route titulo='Visualizar Franqueado' exact path={`/franqueado/view/:franqueadoId`} element={<FranqueadoView />} />
      </Route>

      <Route exact path='/franqueado/update/:franqueadoId' element={<PrivateRoute titulo='Alterar Franqueado'/>}>
        <Route titulo='Alterar Franqueado' exact path={`/franqueado/update/:franqueadoId`} element={<FranqueadoCadastro />} />
      </Route>

      <Route exact path='/franqueado/updateResponsavel/:franqueadoId' element={<PrivateRoute titulo='Alterar Responsável Franqueado'/>}>
        <Route titulo='Alterar Responsável Franqueado' exact path={`/franqueado/updateResponsavel/:franqueadoId`} element={<FranqueadoUpdateResponsavel />} />
      </Route>

      <Route exact path='/unidade' element={<PrivateRoute titulo='Unidade'/>}>
        <Route titulo='Unidade' exact path={`/unidade`} element={<Unidade />} />
      </Route>

      <Route exact path='/unidade/cadastro' element={<PrivateRoute titulo='Cadastro Unidade'/>}>
        <Route titulo='Cadastro Unidade' exact path={`/unidade/cadastro`} element={<UnidadeCadastro />} />
      </Route>

      <Route exact path='/unidade/view/:tab/:unidadeId/' element={<PrivateRoute titulo='Visualizar Unidade'/>}>
        <Route titulo='Visualizar Unidade' exact path={`/unidade/view/:tab/:unidadeId/`} element={<UnidadeView />} />
      </Route>

      <Route exact path='/unidade/update/:unidadeId' element={<PrivateRoute titulo='Alterar Unidade'/>}>
        <Route titulo='Alterar Unidade' exact path={`/unidade/update/:unidadeId`} element={<UnidadeUpdate />} />
      </Route>

      <Route exact path='/cargo' element={<PrivateRoute titulo='Cargo'/>}>
        <Route titulo='Cargo' exact path={`/cargo`} element={<Cargo />} />
      </Route>

      <Route exact path='/cargo/cadastro' element={<PrivateRoute titulo='Cadastro Cargo'/>}>
        <Route titulo='Cadastro Cargo' exact path={`/cargo/cadastro`} element={<CargoCadastro />} />
      </Route>

      <Route exact path='/cargo/cadastro/:cargoId' element={<PrivateRoute titulo='Alterar Cargo'/>}>
        <Route titulo='Alterar Cargo' exact path={`/cargo/cadastro/:cargoId`} element={<CargoCadastro />} />
      </Route>

      <Route exact path='/funcionario' element={<PrivateRoute titulo='Colaboradores'/>}>
        <Route titulo='Colaboradores' exact path={`/funcionario`} element={<Funcionario />} />
      </Route>

      <Route exact path='/funcionario/cadastro' element={<PrivateRoute titulo='Cadastro de Colaboradores'/>}>
        <Route titulo='Cadastro de Colaboradores' exact path={`/funcionario/cadastro`} element={<FuncionarioCadastro />} />
      </Route>

      <Route exact path='/funcionario/cadastro/:funcionarioId' element={<PrivateRoute titulo='Alterar Colaborador'/>}>
        <Route titulo='Alterar Colaborador' exact path={`/funcionario/cadastro/:funcionarioId`} element={<FuncionarioCadastro />} />
      </Route>

      <Route exact path='/funcionario/contrato/:status/:funcionarioId' element={<PrivateRoute titulo='Contrato Colaboradores'/>}>
        <Route titulo='Contrato Colaboradores' exact path={`/funcionario/contrato/:status/:funcionarioId`} element={<FuncionarioContrato />} />
      </Route>

      <Route exact path='/funcionario/view/:funcionarioId' element={<PrivateRoute titulo='Visualizar Colaborador' />}>
        <Route titulo='Visualizar Colaborador' exact path={`/funcionario/view/:funcionarioId`} element={<FuncionarioView />} />
      </Route>

      <Route exact path='/funcionario/view/:funcionarioId/:tab' element={<PrivateRoute titulo='Visualizar Colaborador' />}>
        <Route titulo='Visualizar Colaborador' exact path={`/funcionario/view/:funcionarioId/:tab`} element={<FuncionarioView />} />
      </Route>

      <Route exact path='/funcionario/update/usuario/:funcionarioId' element={<PrivateRoute titulo='Alterar Colaborador' />}>
        <Route titulo='Alterar Colaborador' exact path={`/funcionario/update/usuario/:funcionarioId`} element={<FuncionarioUpdateUsuario />} />
      </Route>

      <Route exact path='/grupo_produtos' element={<PrivateRoute titulo='Grupo de Produtos' />}>
        <Route titulo='Grupo de Produtos' exact path={`/grupo_produtos`} element={<GrupoProdutos />} />
      </Route>

      <Route exact path='/grupo_produtos/cadastro' element={<PrivateRoute titulo='Cadastro Grupo de Produtos' />}>
        <Route titulo='Cadastro Grupo de Produtos' exact path={`/grupo_produtos/cadastro`} element={<GrupoProdutosCadastro />} />
      </Route>

      <Route exact path='/grupo_produtos/cadastro/:grupoProdutoId' element={<PrivateRoute titulo='Alterar Grupo de Produtos' />}>
        <Route titulo='Alterar Grupo de Produtos' exact path={`/grupo_produtos/cadastro/:grupoProdutoId`} element={<GrupoProdutosCadastro />} />
      </Route>

      <Route exact path='/sub_grupo_produtos' element={<PrivateRoute titulo='Sub Grupo de Produtos' />}>
        <Route titulo='Sub Grupo de Produtos' exact path={`/sub_grupo_produtos`} element={<SubGrupoProdutos />} />
      </Route>

      <Route exact path='/sub_grupo_produtos/cadastro' element={<PrivateRoute titulo='Cadastro Sub Grupo de Produtos' />}>
        <Route titulo='Cadastro Sub Grupo de Produtos' exact path={`/sub_grupo_produtos/cadastro`} element={<SubGrupoProdutosCadastro />} />
      </Route>

      <Route exact path='/sub_grupo_produtos/cadastro/:subGrupoProdutoId' element={<PrivateRoute titulo='Alterar Sub Grupo de Produtos' />}>
        <Route titulo='Alterar Sub Grupo de Produtos' exact path={`/sub_grupo_produtos/cadastro/:subGrupoProdutoId`} element={<SubGrupoProdutosCadastro />} />
      </Route>

      <Route exact path='/produtos' element={<PrivateRoute titulo='Produtos' />}>
        <Route titulo='Produtos' exact path={`/produtos`} element={<Produtos />} />
      </Route>

      <Route exact path='/produtos/cadastro' element={<PrivateRoute titulo='Cadastro de Produto' />}>
        <Route titulo='Cadastro de Produto' exact path={`/produtos/cadastro`} element={<ProdutosCadastro />} />
      </Route>

      <Route exact path='/produtos/cadastro/:produtoId' element={<PrivateRoute titulo='Alterar Produto' />}>
        <Route titulo='Alterar Produto' exact path={`/produtos/cadastro/:produtoId`} element={<ProdutosCadastro />} />
      </Route>

      <Route exact path='/produtos/view/:produtoId' element={<PrivateRoute titulo='Visualizar Produto' />}>
        <Route titulo='Visualizar Produto' exact path={`/produtos/view/:produtoId`} element={<ProdutosView />} />
      </Route>

      <Route exact path='/departamentos' element={<PrivateRoute titulo='Departamentos' />}>
        <Route titulo='Departamentos' exact path={`/departamentos`} element={<Departamentos />} />
      </Route>

      <Route exact path='/departamentos/cadastro' element={<PrivateRoute titulo='Cadastro Departamento' />}>
        <Route titulo='Cadastro Departamento' exact path={`/departamentos/cadastro`} element={<DepartamentosCadastro />} />
      </Route>

      <Route exact path='/departamentos/cadastro/:departamentoId' element={<PrivateRoute titulo='Alterar Departamento' />}>
        <Route titulo='Alterar Departamento' exact path={`/departamentos/cadastro/:departamentoId`} element={<DepartamentosCadastro />} />
      </Route>

      <Route exact path='/funcao' element={<PrivateRoute titulo='Função' />}>
        <Route titulo='Função' exact path={`/funcao`} element={<Funcao />} />
      </Route>

      <Route exact path='/funcao/cadastro' element={<PrivateRoute titulo='Cadastro de Função' />}>
        <Route titulo='Cadastro de Função' exact path={`/funcao/cadastro`} element={<FuncaoCadastro />} />
      </Route>

      <Route exact path='/funcao/cadastro/:funcaoId' element={<PrivateRoute titulo='Alterar Função' />}>
        <Route titulo='Alterar Função' exact path={`/funcao/cadastro/:funcaoId`} element={<FuncaoCadastro />} />
      </Route>

      <Route exact path='/perfil' element={<PrivateRoute titulo='Perfil de Usuário' />}>
        <Route titulo='Perfil de Usuário' exact path={`/perfil`} element={<Perfil />} />
      </Route>

      <Route exact path='/perfil/cadastro' element={<PrivateRoute titulo='Cadastro de Perfil de Usuário' />}>
        <Route titulo='Cadastro de Perfil de Usuário' exact path={`/perfil/cadastro`} element={<PerfilCadastro />} />
      </Route>

      <Route exact path='/perfil/cadastro/:perfilId' element={<PrivateRoute titulo='Alterar Perfil de Usuário' />}>
        <Route titulo='Alterar Perfil de Usuário' exact path={`/perfil/cadastro/:perfilId`} element={<PerfilCadastro />} />
      </Route>

      <Route exact path='/estoque' element={<PrivateRoute titulo='Estoque de Produtos' />}>
        <Route titulo='Estoque de Produtos' exact path={`/estoque`} element={<Estoque />} />
      </Route>

      <Route exact path='/estoque/cadastro' element={<PrivateRoute titulo='Cadastro de Estoque de Produtos' />}>
        <Route titulo='Cadastro de Estoque de Produtos' exact path={`/estoque/cadastro`} element={<EstoqueCadastro />} />
      </Route>

      <Route exact path='/estoque/update/:estoqueProdutoId' element={<PrivateRoute titulo='Alterar Estoque de Produtos' />}>
        <Route titulo='Alterar Estoque de Produtos' exact path={`/estoque/update/:estoqueProdutoId`} element={<EstoqueCadastro />} />
      </Route>

      <Route exact path='/estoque/cadastro/:produtoId' element={<PrivateRoute titulo='Alterar Estoque de Produtos' />}>
        <Route titulo='Alterar Estoque de Produtos' exact path={`/estoque/cadastro/:produtoId`} element={<EstoqueCadastro />} />
      </Route>

      <Route exact path='/tabela_preco' element={<PrivateRoute titulo='Tabela de Preço' />}>
        <Route titulo='Tabela de Preço' exact path={`/tabela_preco`} element={<TabelaPreco />} />
      </Route>

      <Route exact path='/tabela_preco/cadastro' element={<PrivateRoute titulo='Cadastro de Tabela de Preço' />}>
        <Route titulo='Cadastro de Tabela de Preço' exact path={`/tabela_preco/cadastro`} element={<TabelaPrecoCadastro />} />
      </Route>

      <Route exact path='/tabela_preco/cadastro/:tabelaPrecoProdutoId' element={<PrivateRoute titulo='Alterar Tabela de Preço' />}>
        <Route titulo='Alterar Tabela de Preço' exact path={`/tabela_preco/cadastro/:tabelaPrecoProdutoId`} element={<TabelaPrecoCadastro />} />
      </Route>

      <Route exact path='/cliente' element={<PrivateRoute titulo='Clientes/Fornecedores' />}>
        <Route titulo='Clientes/Fornecedores' exact path={`/cliente`} element={<Cliente />} />
      </Route>

      <Route exact path='/cliente/cadastro' element={<PrivateRoute titulo='Cadastro de Clientes/Fornecedores' />}>
        <Route titulo='Cadastro de Clientes/Fornecedores' exact path={`/cliente/cadastro`} element={<ClienteCadastro />} />
      </Route>

      <Route exact path='/cliente/cadastro/:clienteId' element={<PrivateRoute titulo='Alterar Clientes/Fornecedores' />}>
        <Route titulo='Alterar Clientes/Fornecedores' exact path={`/cliente/cadastro/:clienteId`} element={<ClienteCadastro />} />
      </Route>

      <Route exact path='/cliente/view/:clienteId' element={<PrivateRoute titulo='Visualizar Clientes/Fornecedores' />}>
        <Route titulo='Visualizar Clientes/Fornecedores' exact path={`/cliente/view/:clienteId`} element={<ClienteView />} />
      </Route>

      <Route exact path='/cliente/view/:clienteId/:tab' element={<PrivateRoute titulo='Visualizar Clientes/Fornecedores' />}>
        <Route titulo='Visualizar Clientes/Fornecedores' exact path={`/cliente/view/:clienteId/:tab`} element={<ClienteView />} />
      </Route>

      <Route exact path='/plano_contas' element={<PrivateRoute titulo='Plano de Contas' />}>
        <Route titulo='Plano de Contas' exact path={`/plano_contas`} element={<PlanoContas />} />
      </Route>

      <Route exact path='/plano_contas/cadastro' element={<PrivateRoute titulo='Cadastro de Plano de Contas' />}>
        <Route titulo='Cadastro de Plano de Contas' exact path={`/plano_contas/cadastro`} element={<PlanoContasCadastro />} />
      </Route>

      <Route exact path='/plano_contas/cadastro/:planoContasId' element={<PrivateRoute titulo='Alterar Plano de Contas' />}>
        <Route titulo='Alterar Plano de Contas' exact path={`/plano_contas/cadastro/:planoContasId`} element={<PlanoContasCadastro />} />
      </Route>

      <Route exact path='/plano_contas/view/:planoContasId' element={<PrivateRoute titulo='Visualizar Plano de Contas' />}>
        <Route titulo='Visualizar Plano de Contas' exact path={`/plano_contas/view/:planoContasId`} element={<PlanoContasView />} />
      </Route>

      <Route exact path='/conta_financeira' element={<PrivateRoute titulo='Conta Financeira' />}>
        <Route titulo='Conta Financeira' exact path={`/conta_financeira`} element={<ContaFinanceira />} />
      </Route>

      <Route exact path='/conta_financeira/cadastro' element={<PrivateRoute titulo='Cadastro de Conta Financeira' />}>
        <Route titulo='Cadastro de Conta Financeira' exact path={`/conta_financeira/cadastro`} element={<ContaFinanceiraCadastro />} />
      </Route>

      <Route exact path='/conta_financeira/cadastro/:contaFinanceiraId' element={<PrivateRoute titulo='Alterar Conta Financeira' />}>
        <Route titulo='Alterar Conta Financeira' exact path={`/conta_financeira/cadastro/:contaFinanceiraId`} element={<ContaFinanceiraCadastro />} />
      </Route>

      <Route exact path='/conta_financeira/corrige_saldo/:contaFinanceiraId' element={<PrivateRoute titulo='Corrigir Saldo da Conta Financeira' />}>
        <Route titulo='Corrigir Saldo da Conta Financeira' exact path={`/conta_financeira/corrige_saldo/:contaFinanceiraId`} element={<ContaFinanceiraCorrigirSaldo />} />
      </Route>

      <Route exact path='/movimentacao_financeira' element={<PrivateRoute titulo='Movimentação Financeira' />}>
        <Route titulo='Movimentação Financeira' exact path={`/movimentacao_financeira`} element={<Movimentacao />} />
      </Route>

      <Route exact path='/movimentacao_financeira/cadastro' element={<PrivateRoute titulo='Cadastro de Movimentação Financeira' />}>
        <Route titulo='Cadastro de Movimentação Financeira' exact path={`/movimentacao_financeira/cadastro`} element={<MovimentacaoCadastro />} />
      </Route>

      <Route exact path='/movimentacao_financeira/importar_extrato' element={<PrivateRoute titulo='Conciliação Bancaria via Extrato' />}>
        <Route titulo='Conciliação Bancaria via Extrato' exact path={`/movimentacao_financeira/importar_extrato`} element={<ImportarExtrato />} />
      </Route>

      <Route exact path='/conta_pagar_receber' element={<PrivateRoute titulo='Contas a Pagar e Receber' />}>
        <Route titulo='Contas a Pagar e Receber' exact path={`/conta_pagar_receber`} element={<ContaPagarReceber />} />
      </Route>

      <Route exact path='/conta_pagar_receber/cadastro' element={<PrivateRoute titulo='Cadastro de Contas a Pagar e Receber' />}>
        <Route titulo='Cadastro de Contas a Pagar e Receber' exact path={`/conta_pagar_receber/cadastro`} element={<ContaPagarReceberCadastro />} />
      </Route>

      <Route exact path='/conta_pagar_receber/cadastro/:parcelaId' element={<PrivateRoute titulo='Alterar Contas a Pagar e Receber' />}>
        <Route titulo='Alterar Contas a Pagar e Receber' exact path={`/conta_pagar_receber/cadastro/:parcelaId`} element={<ContaPagarReceberUpdate />} />
      </Route>

      <Route exact path='/rel_financeiro' element={<PrivateRoute titulo='Rel. Financeiro' />}>
        <Route titulo='Rel. Financeiro' exact path={`/rel_financeiro`} element={<RelatorioFinanceiro />} />
      </Route>

      <Route exact path='/fluxo_caixa' element={<PrivateRoute titulo='Rel. de Fluxo de Caixa' />}>
        <Route titulo='Rel. de Fluxo de Caixa' exact path={`/fluxo_caixa`} element={<RelatorioFluxoCaixa />} />
      </Route>

      <Route exact path='/comissoes' element={<PrivateRoute titulo='Rel. de Comissões' />}>
        <Route titulo='Rel. de Comissões' exact path={`/comissoes`} element={<RelatorioComissoes />} />
      </Route>

      <Route exact path='/rel_vendas' element={<PrivateRoute titulo='Rel. de Vendas' />}>
        <Route titulo='Rel. de Vendas' exact path={`/rel_vendas`} element={<RelatorioVendas />} />
      </Route>

      <Route exact path='/rel_nfse' element={<PrivateRoute titulo='Rel. de NFS-e' />}>
        <Route titulo='Rel. de NFS-e' exact path={`/rel_nfse`} element={<NFSE />} />
      </Route>

      <Route exact path='/rel_adm' element={<PrivateRoute titulo='Rel. Administrativos' />}>
        <Route titulo='Rel. Administrativos' exact path={`/rel_adm`} element={<RelatorioAdministrativos />} />
      </Route>

      <Route exact path='/grafico_venda_vouchers_periodo/impressao' element={<PrivateRoute titulo='Gráfico de Venda de Vouchers' />}>
        <Route titulo='Gráfico de Venda de Vouchers' exact path={`/grafico_venda_vouchers_periodo/impressao`} element={<GraficoVendaVoucher />} />
      </Route>

      <Route exact path='/caixa' element={<PrivateRoute titulo='Caixas' />}>
        <Route titulo='Caixas' exact path={`/caixa`} element={<Caixa />} />
      </Route>

      <Route exact path='/caixa/cadastro/' element={<PrivateRoute titulo='Abertura de Caixa' />}>
        <Route titulo='Abertura de Caixa' exact path={`/caixa/cadastro/`} element={<CaixaCadastro />} />
      </Route>

      <Route exact path='/caixa/cadastro/:redirect' element={<PrivateRoute titulo='Abertura de Caixa' />}>
        <Route titulo='Abertura de Caixa' exact path={`/caixa/cadastro/:redirect`} element={<CaixaCadastro />} />
      </Route>

      <Route exact path='/caixa/view/:caixaId' element={<PrivateRoute titulo='Caixa Aberto' />}>
        <Route titulo='Caixa Aberto' exact path={`/caixa/view/:caixaId`} element={<CaixaView />} />
      </Route>

      <Route exact path='/solicitacao_abertura_caixa/:solicitacaoId' element={<PrivateRoute titulo='Sol. de Abertura Caixa' />}>
        <Route titulo='Sol. de Abertura Caixa' exact path={`/solicitacao_abertura_caixa/:solicitacaoId`} element={<SolicitacaoAberturaCaixa />} />
      </Route>

      <Route exact path='/caixa_fechamento' element={<PrivateRoute titulo='Caixas Fechados' />}>
        <Route titulo='Caixas Fechados' exact path={`/caixa_fechamento`} element={<FechamentoCaixa />} />
      </Route>

      <Route exact path='/caixa_fechamento/view/:caixaId' element={<PrivateRoute titulo='Fechamento de Caixa' />}>
        <Route titulo='Fechamento de Caixa' exact path={`/caixa_fechamento/view/:caixaId`} element={<FechamentoCaixaView />} />
      </Route>

      <Route exact path='/servicos_bancario' element={<PrivateRoute titulo='Serviços Bancários' />}>
        <Route titulo='Serviços Bancários' exact path={`/servicos_bancario`} element={<ServicosBancarios />} />
      </Route>

      <Route exact path='/servicos_bancario/cadastro' element={<PrivateRoute titulo='Cadastro de Serviços Bancários' />}>
        <Route titulo='Cadastro de Serviços Bancários' exact path={`/servicos_bancario/cadastro`} element={<ServicosBancariosCadastro />} />
      </Route>

      <Route exact path='/servicos_bancario/cadastro/:servicoBancarioId' element={<PrivateRoute titulo='Alterar Serviços Bancários' />}>
        <Route titulo='Alterar Serviços Bancários' exact path={`/servicos_bancario/cadastro/:servicoBancarioId`} element={<ServicosBancariosCadastro />} />
      </Route>

      <Route exact path='/parceiros_bancario' element={<PrivateRoute titulo='Parceiros Bancários' />}>
        <Route titulo='Parceiros Bancários' exact path={`/parceiros_bancario`} element={<ParceirosBancarios />} />
      </Route>

      <Route exact path='/parceiros_bancario/cadastro' element={<PrivateRoute titulo='Cadastro de Parceiros Bancários' />}>
        <Route titulo='Cadastro de Parceiros Bancários' exact path={`/parceiros_bancario/cadastro`} element={<ParceirosBancariosCadastro />} />
      </Route>

      <Route exact path='/parceiros_bancario/cadastro/:parceiroBancarioId' element={<PrivateRoute titulo='Alterar Parceiros Bancários' />}>
        <Route titulo='Alterar Parceiros Bancários' exact path={`/parceiros_bancario/cadastro/:parceiroBancarioId`} element={<ParceirosBancariosCadastro />} />
      </Route>

      <Route exact path='/agenda_ambiente' element={<PrivateRoute titulo='Agenda' />}>
        <Route titulo='Agenda' exact path={`/agenda_ambiente`} element={<AgendaAmbiente />} />
      </Route>

      <Route exact path='/ambiente' element={<PrivateRoute titulo='Ambiente' />}>
        <Route titulo='Ambiente' exact path={`/ambiente`} element={<Ambiente />} />
      </Route>

      <Route exact path='/item_receita_despesa' element={<PrivateRoute titulo='Itens de Receita e Despesa' />}>
        <Route titulo='Itens de Receita e Despesa' exact path={`/item_receita_despesa`} element={<ItemReceitaDespesa />} />
      </Route>

      <Route exact path='/config_voucher' element={<PrivateRoute titulo='Config. de Voucher' />}>
        <Route titulo='Config. de Voucher' exact path={`/config_voucher`} element={<VoucherPadrao />} />
      </Route>

      <Route exact path='/venda_voucher' element={<PrivateRoute titulo='Vouchers' />}>
        <Route titulo='Vouchers' exact path={`/venda_voucher`} element={<Voucher />} />
      </Route>

      <Route exact path='/venda_voucher/view/:voucherId' element={<PrivateRoute titulo='Venda de Voucher' />}>
        <Route titulo='Venda de Voucher' exact path={`/venda_voucher/view/:voucherId`} element={<VoucherView />} />
      </Route>

      <Route exact path='/central_notificacao' element={<PrivateRoute titulo='Central de Notificação' />}>
        <Route titulo='Central de Notificação' exact path={`/central_notificacao`} element={<CentralNotificacao />} />
      </Route>

      <Route exact path='/central_notificacao/cadastro' element={<PrivateRoute titulo='Cadastro de Central de Notificação' />}>
        <Route titulo='Cadastro de Central de Notificação' exact path={`/central_notificacao/cadastro`} element={<CentralNotificacaoCadastro />} />
      </Route>

      <Route exact path='/central_notificacao/cadastro/:centralAlertaId' element={<PrivateRoute titulo='Alterar Central de Notificação' />}>
        <Route titulo='Alterar Central de Notificação' exact path={`/central_notificacao/cadastro/:centralAlertaId`} element={<CentralNotificacaoCadastro />} />
      </Route>

      <Route exact path='/api_whatsapp' element={<PrivateRoute titulo='API Whats' />}>
        <Route titulo='API Whats' exact path={`/api_whatsapp`} element={<WhatsApp />} />
      </Route>

      <Route exact path='/api_whatsapp/cadastro' element={<PrivateRoute titulo='Cadastro da API Whats' />}>
        <Route titulo='Cadastro da API Whats' exact path={`/api_whatsapp/cadastro`} element={<WhatsAppCadastro />} />
      </Route>

      <Route exact path='/api_whatsapp/cadastro/:numeroWhatsId' element={<PrivateRoute titulo='Alterar API Whats' />}>
        <Route titulo='Alterar API Whats' exact path={`/api_whatsapp/cadastro/:numeroWhatsId`} element={<WhatsAppCadastro />} />
      </Route>

      <Route exact path='/pesquisa' element={<PrivateRoute titulo='Pesquisa' />}>
        <Route titulo='Pesquisa' exact path={`/pesquisa`} element={<Pesquisa />} />
      </Route>

      <Route exact path='/pesquisa/cadastro' element={<PrivateRoute titulo='Cadastro da Pesquisa' />}>
        <Route titulo='Cadastro da Pesquisa' exact path={`/pesquisa/cadastro`} element={<PesquisaCadastro />} />
      </Route>

      <Route exact path='/pesquisa/cadastro/:pesquisaId' element={<PrivateRoute titulo='Alterar Pesquisa' />}>
        <Route titulo='Alterar Pesquisa' exact path={`/pesquisa/cadastro/:pesquisaId`} element={<PesquisaCadastro />} />
      </Route>

      <Route exact path='/pesquisa/view/:pesquisaId' element={<PrivateRoute titulo='Visualizar Pesquisa' />}>
        <Route titulo='Visualizar Pesquisa' exact path={`/pesquisa/view/:pesquisaId`} element={<PesquisaView />} />
      </Route>

      <Route exact path='/vendas' element={<PrivateRoute titulo='Vendas' />}>
        <Route titulo='Vendas' exact path={`/vendas`} element={<Vendas />} />
      </Route>

      <Route exact path='/vendas/cadastro' element={<PrivateRoute titulo='Cadastro da Venda' />}>
        <Route titulo='Cadastro da Venda' exact path={`/vendas/cadastro`} element={<VendasCadastro />} />
      </Route>

      <Route exact path='/vendas/view/:vendaId' element={<PrivateRoute titulo='Visualizar Venda' />}>
        <Route titulo='Visualizar Venda' exact path={`/vendas/view/:vendaId`} element={<VendasView />} />
      </Route>

      <Route exact path='/vendas/cadastro/:vendaId' element={<PrivateRoute titulo='Alterar Venda' />}>
        <Route titulo='Alterar Venda' exact path={`/vendas/cadastro/:vendaId`} element={<VendasCadastro />} />
      </Route>

      <Route exact path='/compras' element={<PrivateRoute titulo='Compras' />}>
        <Route titulo='Compras' exact path={`/compras`} element={<Compras />} />
      </Route>

      <Route exact path='/compras/cadastro' element={<PrivateRoute titulo='Cadastro da Venda' />}>
        <Route titulo='Cadastro de Compras' exact path={`/compras/cadastro`} element={<ComprasCadastro />} />
      </Route>

      <Route exact path='/compras/view/:compraId' element={<PrivateRoute titulo='Visualizar Venda' />}>
        <Route titulo='Visualizar Compra' exact path={`/compras/view/:compraId`} element={<ComprasView />} />
      </Route>

      <Route exact path='/compras/cadastro/:compraId' element={<PrivateRoute titulo='Alterar Venda' />}>
        <Route titulo='Alterar Venda' exact path={`/compras/cadastro/:compraId`} element={<ComprasCadastro />} />
      </Route>

      <Route exact path='/contratos_unidades' element={<PrivateRoute titulo='Contratos de Unidades' />}>
        <Route titulo='Contratos de Unidades' exact path={`/contratos_unidades`} element={<ContratosUnidades />} />
      </Route>

      <Route exact path='/documentos' element={<PrivateRoute titulo='Documentos' />}>
        <Route titulo='Documentos' exact path={`/documentos`} element={<Documentos />} />
      </Route>

      <Route exact path='/documentos/cadastro' element={<PrivateRoute titulo='Cadastro de Documento' />}>
        <Route titulo='Cadastro de Documento' exact path={`/documentos/cadastro`} element={<DocumentosCadastro />} />
      </Route>

      <Route exact path='/documentos/cadastro/:documentoId' element={<PrivateRoute titulo='Alterar Documento' />}>
        <Route titulo='Alterar Documento' exact path={`/documentos/cadastro/:documentoId`} element={<DocumentosCadastro />} />
      </Route>

      <Route exact path='/pre_cadastro_cliente' element={<PrivateRoute titulo='Listagem Pré Cadastro de Clientes' />}>
        <Route titulo='Listagem Pré Cadastro de Clientes' exact path={`/pre_cadastro_cliente`} element={<ClienteExternoListagem />} />
      </Route>

      <Route exact path='/pre_cadastro_cliente/view/:clienteId' element={<PrivateRoute titulo='Visualizar Pré Cadastro de Clientes' />}>
        <Route titulo='Visualizar Pré Cadastro de Clientes' exact path={`/pre_cadastro_cliente/view/:clienteId`} element={<ClienteExternoView />} />
      </Route>
    </Routes>
  </Router>
);

export default Rotas;