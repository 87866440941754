import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, Select, MenuItem, ListItemIcon, ListItemText, InputLabel, FormControl } from '@mui/material'

const FormMovimentacao = ({ dados, updateFieldSaque, updateFieldDeposito, tipo,
  updateFieldTipo, contasFinanceira, travarCampos, tiposMovimentacao }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      {tipo !== 'TRANSFERENCIA' &&
        <Grid item md={2} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Tipo da Mov."
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="tipo"
            value={tipo}
            onChangeCapture={(e) => updateFieldTipo(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value="">Selecionar Tipo</option>
            {tiposMovimentacao.map(tipoMov => {
              return (
                <option value={tipoMov.value}>{tipoMov.name}</option>
              )
            })}
          </TextField>
        </Grid>
      }
      {tipo === 'SAQUE' &&
        <React.Fragment>
          <Grid item md={3} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink htmlFor="outlined-currency-simple">
                Conta de Saque
              </InputLabel>
              <Select
                fullWidth
                className={`input ${dados[0].contfin_id !== '' ? 'selecionado' : 'nao_selecionado'}`}
                variant="outlined"
                name="contfin_id"
                value={dados[0].contfin_id}
                onChange={(e) => updateFieldSaque(e)}
              >
                <MenuItem value=''>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary={'Selecionar Conta'} />
                </MenuItem>
                {contasFinanceira.map(conta => {
                  return (
                    <MenuItem key={conta.id} value={conta.id}>
                      <ListItemIcon>
                        <img alt='logo' style={{width: '25px', height: '25px', marginRight: '5px', marginTop: '5px'}} src={conta.logo ? conta.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} />
                      </ListItemIcon>
                      <ListItemText primary={conta.descricao} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          {dados[0].contfin_id !== '' &&
            <React.Fragment>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="data_hora_conta" value={dados[0].data_hora_conta} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} disabled={travarCampos}/>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField className="input" label="Descrição*" variant="outlined" size="small" name="descricao" value={dados[0].descricao} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={dados[0].valor} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={travarCampos}/>
              </Grid>
            </React.Fragment>
          }
        </React.Fragment>
      }
      {tipo === 'DEPOSITO' &&
        <React.Fragment>
          <Grid item md={3} xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink htmlFor="outlined-currency-simple">
                Conta de Deposito
              </InputLabel>
              <Select
                fullWidth
                className={`input ${dados[1].contfin_id !== '' ? 'selecionado' : 'nao_selecionado'}`}
                variant="outlined"
                name="contfin_id"
                value={dados[1].contfin_id}
                onChange={(e) => updateFieldDeposito(e)}
              >
                <MenuItem value=''>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary={'Selecionar Conta'} />
                </MenuItem>
                {contasFinanceira.map(conta => {
                  return (
                    <MenuItem key={conta.id} value={conta.id}>
                      <ListItemIcon>
                        <img alt='logo' style={{width: '25px', height: '25px', marginRight: '5px', marginTop: '5px'}} src={conta.logo ? conta.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} />
                      </ListItemIcon>
                      <ListItemText primary={conta.descricao} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          {dados[1].contfin_id !== '' &&
            <React.Fragment>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="data_hora_conta" value={dados[1].data_hora_conta} onChange={(e) => updateFieldDeposito(e)} InputLabelProps={{ shrink: true }} disabled={travarCampos}/>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField className="input" label="Descrição*" variant="outlined" size="small" name="descricao" value={dados[1].descricao} onChange={(e) => updateFieldDeposito(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={1} xs={12} sm={6}>
                <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={dados[1].valor} onChange={(e) => updateFieldDeposito(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={travarCampos}/>
              </Grid>
            </React.Fragment>
          }
        </React.Fragment>
      }
    </Grid>
    {tipo === 'TRANSFERENCIA' &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              label="Tipo da Mov."
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="tipo"
              value={tipo}
              onChangeCapture={(e) => updateFieldTipo(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Selecionar Tipo</option>
              <option value="TRANSFERENCIA">Transferência</option>
              <option value="SAQUE">Saque</option>
              <option value="DEPOSITO">Deposito</option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink htmlFor="outlined-currency-simple">
                Conta de Saque
              </InputLabel>
              <Select
                fullWidth
                className={`input ${dados[0].contfin_id !== '' ? 'selecionado' : 'nao_selecionado'}`}
                variant="outlined"
                name="contfin_id"
                value={dados[0].contfin_id}
                label="Conta Saque"
                InputLabelProps={{ shrink: true }} 
                onChange={(e) => updateFieldSaque(e)}
              >
                <MenuItem value=''>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary={'Selecionar Conta'} />
                </MenuItem>
                {contasFinanceira.filter(param => param.id !== dados[1].contfin_id).map(conta => {
                  return (
                    <MenuItem key={conta.id} value={conta.id}>
                      <ListItemIcon>
                        <img alt='logo' style={{width: '25px', height: '25px', marginRight: '5px', marginTop: '5px'}} src={conta.logo ? conta.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} />
                      </ListItemIcon>
                      <ListItemText primary={conta.descricao} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink htmlFor="outlined-currency-simple">
                Conta de Deposito
              </InputLabel>
              <Select
                fullWidth
                className={`input ${dados[0].contfin_id !== '' ? 'selecionado' : 'nao_selecionado'}`}
                variant="outlined"
                name="contfin_id"
                value={dados[1].contfin_id}
                onChange={(e) => updateFieldDeposito(e)}
              >
                <MenuItem value=''>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary={'Selecionar Conta'} />
                </MenuItem>
                {contasFinanceira.filter(param => param.id !== dados[0].contfin_id).map(conta => {
                  return (
                    <MenuItem key={conta.id} value={conta.id}>
                      <ListItemIcon>
                        <img alt='logo' style={{width: '25px', height: '25px', marginRight: '5px'}} src={conta.logo ? conta.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} />
                      </ListItemIcon>
                      <ListItemText primary={conta.descricao} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="data_hora_conta" value={dados[0].data_hora_conta} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} disabled={travarCampos}/>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={dados[0].valor} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={travarCampos}/>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          {dados[0].contfin_id !== '' &&
            <React.Fragment>
              <Grid item md={6} xs={12} sm={6}>
                <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={dados[0].descricao} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </React.Fragment>
    }
    <br />
    <hr />
  </React.Fragment>
)

export default FormMovimentacao