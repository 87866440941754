import React, { useState } from 'react';
import {useDropzone} from 'react-dropzone';

const Upload = (props) => {
  const [errorMessage, setErrorMessage] = useState('');

  const {
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxSize: 2 * 1024 * 1024, // 2MB
    onDropAccepted: (acceptedFiles) => {
      // Chama a função processarImagem passada via props
      props.onUpload(acceptedFiles);
      setErrorMessage(''); // Limpa a mensagem de erro ao aceitar arquivos
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(err => {
          if (err.code === 'file-too-large') {
            setErrorMessage(`O arquivo ${file.name} é muito grande. O limite é de 2MB.`);
          } else {
            setErrorMessage(`O arquivo ${file.name} foi rejeitado.`);
          }
        });
      });
    }
  });

  return (
    <div {...getRootProps({ className: (isDragReject || errorMessage) ? 'dropzone_error': 'dropzone' })}>
      <input {...getInputProps()} />
      <p>Click aqui ou arraste os arquivos aqui</p>
      <em>(Somente imagens *.jpeg e *.png serão aceitas, com limite de 2MB)</em>
      {isDragReject && (<p className='erro'>Esse arquivo não é suportado!</p>)}
      {errorMessage && (<p className='erro'>{errorMessage}</p>)}
    </div>
  );
}

export default Upload;
