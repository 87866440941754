import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl} from '@mui/material'
import CurrencyInput from './../mask/CurrencyInput'
import real from '../../services/real';
import Color from 'color';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const {servico, produtos, formasPagamento, contasFinanceira, produtoSelecionado, possui_emissao_nfse} = props

  const {updateFieldConta, mudarParcelas, recalcularParcela, updateFieldParcela, travarCampos,
    marcaParcelas, marcaParcelasContaFin, logado, conta, verificaDataParcela
   } = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h6 className="tituloConfirmacao">Adicionar Novo Serviço</h6>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={2} sm={2}>
              <Button
                fullWidth 
                color={servico.parcelado ? 'primary' : 'inherit'} 
                variant="contained" 
                size="small" 
                endIcon={servico.parcelado ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />} 
                onClick={e => props.updateParcelado()}
              >
                Parcelamento
              </Button>
            </Grid>
            <Grid item md={2} xs={2} sm={2}>
              <Button 
                fullWidth 
                color={servico.recorrencia ? 'secondary' : 'inherit'} 
                variant="contained" 
                size="small" 
                endIcon={servico.recorrencia ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
                onClick={e => props.updateRecorrencia()}
              >
                Recorrência
              </Button>
            </Grid>
            <Grid item md={1} xs={1} sm={1}></Grid>
          </Grid>
          {servico.recorrencia && 
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={4} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Serviço"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{ native: true }}
                  name="produto_id"
                  value={servico.produto_id}
                  onChangeCapture={(e) => props.updateServico(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""> Selecionar Produto</option>
                  {produtos.list.map(produto => {
                    return (
                      <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={1} xs={12} sm={6}>
                <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={servico.valor} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  type='number'
                  className="input" 
                  label="Repetir a cada*" 
                  variant="outlined" 
                  name="recorrenciaACada" 
                  size="small"
                  value={servico.recorrenciaACada} 
                  onChange={(e) => props.updateServico(e)} 
                  InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={1} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="tipoRecorrencia"
                  value={servico.tipoRecorrencia}
                  onChangeCapture={(e) => props.updateServico(e)}
                >
                  <option value='d'> Dia </option>
                  <option value='w'> Semana </option>
                  <option value='M'> Mês </option>
                  <option value='y'> Ano </option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  type='number'
                  className="input" 
                  label="Num. de Ocorrências*" 
                  variant="outlined" 
                  name="qtdRecorrencia" 
                  size="small"
                  value={servico.qtdRecorrencia} 
                  onChange={(e) => props.updateServico(e)} 
                  InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" type='date' label="Venc. Prim. Recor.*" variant="outlined" size="small" name="venc_prim_parcela" value={servico.venc_prim_parcela} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{ native: true }}
                  name="formapagto_id"
                  value={servico.formapagto_id}
                  label="Forma de Pgto.*"
                  onChangeCapture={(e) => props.updateServico(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">Selecionar Forma de Pgto.</option>
                  {formasPagamento.list.map(forma => {
                    return (
                      <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="contfin_id"
                  value={servico.contfin_id}
                  label="Contas Bancárias.*"
                  onChangeCapture={(e) => props.updateServico(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  {contasFinanceira.list.map(conta => {
                    return (
                      <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Totalizando*" 
                  variant="outlined" 
                  name="qtdRecorrencia" 
                  size="small"
                  value={servico.valor ? real(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) * servico.qtdRecorrencia) : real(0)} 
                  InputLabelProps={{ shrink: true }}
                  disabled/>
              </Grid>
            </Grid>
          }
          {servico.parcelado &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Serviço"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{ native: true }}
                    name="produto_id"
                    value={servico.produto_id}
                    onChangeCapture={(e) => props.updateServico(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Produto</option>
                    {produtos.list.map(produto => {
                      return (
                        <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    label="Valor Total*" 
                    variant="outlined" 
                    size="small" 
                    name="valor" 
                    value={servico.valor} 
                    onChange={(e) => props.updateServico(e)} 
                    InputLabelProps={{ shrink: true }} 
                    InputProps={{ inputComponent: CurrencyInput }} 
                    disabled={servico.produto_id === ''}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade Tempo Duração"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="intervalo_parcela"
                    value={servico.intervalo_parcela}
                    onChangeCapture={(e) => props.updateServico(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=''> Selecionar Intervalo </option>
                    <option value='DIA'> Dia </option>
                    <option value='MÊS'> Mês </option>
                    <option value='ANO'> Ano </option>
                    
                  </TextField>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <Grid container spacing={1} direction="row" className="mg_top_10">
            {produtoSelecionado.preco &&
              <React.Fragment>
                <Grid item md={3} xs={12} sm={3}>
                  <p style={{marginBottom: '0px'}}>Valores Sugeridos da Tabela: </p>
                </Grid>
                <Grid item md={2} xs={12} sm={2}>
                  <TextField className="input" label="Valor Mínimo*" variant="outlined" size="small" name="valor_minimo" value={` R$ ${real(produtoSelecionado.preco.valor_minimo)}`} InputLabelProps={{ shrink: true }} disabled/>
                </Grid>
                <Grid item md={2} xs={12} sm={2}>
                  <TextField className="input" label="Valor Máximo*" variant="outlined" size="small" name="valor_maximo" value={` R$ ${real(produtoSelecionado.preco.valor_maximo)}`} InputLabelProps={{ shrink: true }} disabled/>
                </Grid>
              </React.Fragment>
            }
            {!produtoSelecionado.preco &&
              <Grid item md={6} xs={12} sm={7}></Grid>
            }
            <Grid item md={4} xs={12} sm={4}></Grid>
            <Grid item md={2} xs={12} sm={1}>
              {possui_emissao_nfse &&
                <React.Fragment>
                  <div style={{float: 'left'}}>
                    <Tooltip
                      title={'Ao marcar essa opção quando o cliente efetuar o pagamento através do Pix ou do Boleto a NFSe será emitida Automáticamente.'}
                    >
                      <InfoIcon className='info'/>
                    </Tooltip>
                  </div>
                  <div style={{float: 'left'}}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Gerar NFSE?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="Ativo"
                            color="primary"
                            checked={servico.gerar_nfse}
                          />
                        }
                        size="small"
                        label={servico.gerar_nfse ? 'Sim' : 'Não'}
                        name="gerar_nfse"
                        onChange={(e) => props.updateFieldGerarNfse(e)}
                      />
                    </FormControl>
                  </div>
                </React.Fragment>
              }
            </Grid>
          </Grid>
          {(servico.valor !== '' && servico.parcelado) && 
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <div className='scroll'>
                  <table className="lista sempadding borderTable">
                    <thead>
                      <tr className="titulos acompanha">
                        <th>Parcelas</th>
                        <th>Valor*</th>
                        <th>Vencimento*</th>
                        <th>Forma de Pagamento*</th>
                        <th>Contas Bancárias</th>
                        <th>Documento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                        return (
                          <tr key={parcela.index}>
                            <td>
                              <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                              <span className="separacaoParcelas">/</span>
                              <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }}/>
                            </td>
                            <td>
                              <TextField 
                                className="inputValor" 
                                variant="outlined" 
                                size="small" 
                                name="valorparcela" 
                                value={parcela.valorparcela} 
                                onBlur={(e) => recalcularParcela(e, parcela.index)} 
                                InputLabelProps={{ shrink: true }} 
                                InputProps={{ inputComponent: CurrencyInput }} 
                                disabled={travarCampos}
                              />
                            </td>
                            <td>
                              <TextField 
                                type="date" 
                                className="inputVencimento" 
                                variant="outlined" 
                                size="small" 
                                name="datavencimento" 
                                value={parcela.datavencimento} 
                                onChange={(e) => updateFieldParcela(e, parcela.index)} 
                                onBlur={(e) => verificaDataParcela(e, parcela.index)} 
                                InputLabelProps={{ shrink: true }}
                                disabled={travarCampos}
                              />
                            </td>
                            <td>
                              <TextField
                                id="standard-select-currency"
                                select
                                variant="outlined"
                                className="inputFormPagamento"
                                fullWidth
                                size="small"
                                SelectProps={{
                                  native: true,
                                }}
                                name="formapagto_id"
                                value={parcela.formapagto_id}
                                onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                onBlur={(e) => marcaParcelas(e, parcela.index)}
                                InputLabelProps={{ shrink: true }}
                              >
                                <option value="">--Escolha--</option>
                                {formasPagamento.list.map(forma => {
                                  return (
                                    <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                  )
                                })}
                              </TextField>
                            </td>
                            <td>
                              <TextField
                                id="standard-select-currency"
                                select
                                variant="outlined"
                                className="inputFormPagamento"
                                fullWidth
                                size="small"
                                SelectProps={{
                                  native: true,
                                }}
                                name="contfin_id"
                                value={parcela.contfin_id}
                                onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                InputLabelProps={{ shrink: true }}
                                disabled={travarCampos}
                              >
                                <option value="">--Escolha--</option>
                                {contasFinanceira.list.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                                  return (
                                    <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                  )
                                })}
                              </TextField>
                            </td>
                            <td>
                              <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          }          
          <hr />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={10} xs={12} sm={9}></Grid>
            <Grid item md={2} xs={12} sm={3}>
              <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => props.addServico(e)}>{'Adicionar'}</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div> 
  );
}
