import React from 'react';

const Loading = ({open, message}) => (
  <React.Fragment>
		{open &&
      <React.Fragment>
        <img className='logo_mais_financeiro_loading' src="https://sistema.maisfinanceiro.com.br/images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
        <div className='carregamento' style={{textAlign: 'center'}}>
          <div className='alinhar'>
            <div className="bar bar1"></div>
            <div className="bar bar2"></div>
            <div className="bar bar3"></div>
          </div>
          <span>{message}</span>
        </div>
      </React.Fragment>
    }
  </React.Fragment>
)

export default Loading
