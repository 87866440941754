import React, { useState, useEffect } from 'react';
import './PlanoContas.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import Table from '../../components/Table'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  planoContas: {
    id: "",
    descricao: "",
    codigo: "",
    nivel_superior_id: "",
    unidade_id: "",
    ativo: true,
  },
  planoContasCad: {
    descricao: "",
    codigo: "",
    nivel_superior_id: "",
    unidade_id: "",
    ativo: true,
    totalizador: false,
    tipo: '',
    nivel: 1,
    sequencia: 1,
    permite_centro_custo: false,
    uso_centro_custo: false
  },
  loading: true,
  mensagemLoading: 'Carregando Plano de Contas...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  cabecalhoTabela: [
    { id: 'codigo', numeric: false, disablePadding: true, label: 'Codigo' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'unidade_cadastro', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
}

const ViewPlanoDeContas = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { planoContasId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultarPlano = async () => {
    try {
      const {planoContasCad} = state

      const { data: planoContas } = await axios.get(`${window._env_.REACT_APP_API_URL}/planoContas/${planoContasId}`, getToken())

      const codigo = planoContas.niveis_inferior.length + 1

      if (codigo < 10) {
        planoContasCad.codigo = `${planoContas.codigo}.0${codigo}`
      } else {
        planoContasCad.codigo = `${planoContas.codigo}.${codigo}`
      }

      planoContasCad.nivel = planoContas.nivel + 1
      planoContasCad.sequencia = codigo

      planoContasCad.nivel_superior_id = planoContas.id
      planoContasCad.tipo = planoContas.tipo
      planoContasCad.unidade_id = planoContas.unidade_id

      setState(prevState => ({...prevState,
        planoContas: {
          id: planoContas.id,
          descricao: planoContas.descricao,
          codigo: planoContas.codigo,
          nivel_superior_id: planoContas.nivel_superior_id ? planoContas.nivel_superior_id : "",
          unidade_id: planoContas.unidade_id,
          unidade_cadastro: planoContas.unidade_cadastro.descricao ? (planoContas.unidade_cadastro.numero + ' - ' + planoContas.unidade_cadastro.descricao) : `(${planoContas.unidade_cadastro.tipo}) - ` + (planoContas.unidade_cadastro.pessoa.tipo === 'FISICA' ? planoContas.unidade_cadastro.pessoa.fisica.nome : planoContas.unidade_cadastro.pessoa.juridica.nomefantasia),
          ativo: planoContas.ativo,
          tipo: planoContas.tipo === 'RECEITA' ? 'Receita' : 'Despesa',
          totalizador: planoContas.totalizador,
          niveis_inferior: planoContas.niveis_inferior.map(param => {
            return {
              _id: param.id,
              codigo: param.codigo,
              descricao: param.descricao,
              unidade_cadastro: param.unidade_cadastro.descricao ? (param.unidade_cadastro.numero + ' - ' + param.unidade_cadastro.descricao) : `(${param.unidade_cadastro.tipo}) - ` + (param.unidade_cadastro.pessoa.tipo === 'FISICA' ? param.unidade_cadastro.pessoa.fisica.nome : param.unidade_cadastro.pessoa.juridica.nomefantasia),
              tipo: param.totalizador ? (param.nivel === 1 ? 'Totalizador' : 'Sub-Totalizador') : (param.tipo === 'RECEITA' ? 'Receita' : 'Despesa'),
              ativo: param.ativo,
            }
          }),
        },
        planoContasCad,
        update: true
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))

      backPage()
      return false
    }
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      navigate("/plano_contas");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateField = (event) => {
    const planoContasCad = { ...state.planoContasCad }
    planoContasCad[event.target.name] = event.target.value
    setState(prevState => ({...prevState, planoContasCad }))
  }

  const updateFieldAtivo = () => {
    const planoContasCad = { ...state.planoContasCad }
    planoContasCad.ativo = !planoContasCad.ativo
    setState(prevState => ({...prevState, planoContasCad }))
  }

  const updateFieldTotalizador = () => {
    const planoContasCad = { ...state.planoContasCad }
    planoContasCad.totalizador = !planoContasCad.totalizador
    setState(prevState => ({...prevState, planoContasCad }))
  }

  const updateFieldPermCentroCusto = () => {
    const planoContasCad = { ...state.planoContasCad }
    planoContasCad.permite_centro_custo = !planoContasCad.permite_centro_custo
    setState(prevState => ({...prevState, planoContasCad }))
  }

  const updateFieldUsaCentroCusto = () => {
    const planoContasCad = { ...state.planoContasCad }
    planoContasCad.uso_centro_custo = !planoContasCad.uso_centro_custo
    setState(prevState => ({...prevState, planoContasCad }))
  }

  const validateForm = () => {
    const { planoContasCad } = state

    if (!planoContasCad.descricao) return false
    if (!planoContasCad.codigo) return false
    if (!planoContasCad.unidade_id) return false
    if (!planoContasCad.tipo) return false

    return true
  }

  const salvar = async () => {
    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    const {planoContasCad} = state

    try {
      setState(prevState => ({...prevState,
        loading: true,
        mensagemLoading: 'Cadastrando/Alterando Plano de Contas'
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas`, planoContasCad, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        planoContasCad: initialState.planoContasCad,
        loading: false
      }))

      await consultarPlano()

      // backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const viewPlanos = async (value) => {
    
    const self = this
    navigate(`/plano_contas/view/${value._id}`)

    // await consultarPlano()
    window.location.reload()
    return
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(planoContas => planoContas.tela.modulo.slug === 'plano-contas')[0]

      setState(prevState => ({...prevState,
        permissoes
      }))

      if (!permissoes.visualizar) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:'Você não tem permissão para acessa essa tela!'
          }
        }))

        backPage()
      }
      
      let acoesTabela = []

      if (permissoes.visualizar) {
        acoesTabela.push('viewPlano')
      }

      // if (permissoes.alterar) {
      //   acoesTabela.push('update')
      // }

      setState(prevState => ({...prevState,
        acoesTabela
      }))

      await consultarPlano()
      
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { planoContas, planoContasCad, permissoes } = state
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom" >
                <Grid item md={8} xs={12} sm={12}>
                  <h1 className="titulo">Plano de Contas</h1>
                </Grid>
                {!permissoes.alterar &&
                  <Grid item md={2} xs={6} sm={6}></Grid>
                }
                <Grid item md={2} xs={6} sm={6}>
                  <Link to={`/plano_contas`}>
                    <Button size="small" className="btnCadastrar" variant="contained" color="secondary">
                      Voltar
                    </Button>
                  </Link>
                </Grid>
                {permissoes.alterar &&
                  <Grid item md={2} xs={6} sm={6}>
                    <Link to={`/plano_contas/cadastro/${planoContas.id}`}>
                      <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                        Editar Plano
                    </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <div id="view">
                <Grid container direction="row" spacing={1} className="mg_top_20">
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" label="Codigo" variant="outlined" size="small" value={planoContas.codigo} disabled />
                  </Grid>
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField className="input" label="Nome" variant="outlined" size="small" value={planoContas.descricao} disabled />
                  </Grid>
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField className="input" label="Unidade" variant="outlined" size="small" value={planoContas.unidade_cadastro} disabled />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" label="Ativo" variant="outlined" size="small" value={planoContas.ativo ? 'Ativo' : 'Inativo'} disabled />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" label="Tipo" variant="outlined" size="small" value={planoContas.tipo} disabled />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" label="Totalizador?" variant="outlined" size="small" value={planoContas.totalizador ? 'Sim' : 'Não'} disabled />
                  </Grid>
                </Grid>
              </div>
              <br />
              <hr />
              <br />
              {(permissoes.alterar || permissoes.inserir) &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={12} xs={12} className='cardEndereco'>
                      <Grid container spacing={1} direction="row">
                        <Grid item md={12} xs={12}>
                          <h5 className="titulo">Cadastrar Novo Nivel:</h5>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={2} xs={12} sm={6}>
                          <TextField className="input" label="Codigo*" variant="outlined" size="small" name="codigo" value={planoContasCad.codigo} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled />
                        </Grid>
                        <Grid item md={3} xs={12} sm={6}>
                          <TextField className="input" label="Nome*" variant="outlined" size="small" name="descricao" value={planoContasCad.descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item md={2} xs={12} sm={6}>
                          <TextField className="input" label="Tipo" variant="outlined" size="small" name="tipo" value={planoContasCad.tipo} InputLabelProps={{ shrink: true }} disabled/>
                        </Grid>
                        <Grid item md={1} xs={false} sm={false}> </Grid>
                        <Grid item md={1} xs={12} sm={6}>
                          <FormControl component="fieldset" className="mag-top--8">
                            <FormLabel component="legend">Totalizador?</FormLabel>
                            <FormControlLabel className="mg_left_10"
                              control={
                                <Switch
                                  name="ativo"
                                  color="primary"
                                  checked={planoContasCad.totalizador}
                                />
                              }
                              size="small"
                              label={planoContasCad.totalizador ? 'Sim' : 'Não'}
                              name="ativo"
                              onChange={(e) => updateFieldTotalizador(e)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={1} xs={false} sm={false}> </Grid>
                        <Grid item md={1} xs={11} sm={5}>
                          <FormControl component="fieldset" className="mag-top--8">
                            <FormLabel component="legend">Ativo?</FormLabel>
                            <FormControlLabel className="mg_left_10"
                              control={
                                <Switch
                                  name="ativo"
                                  color="primary"
                                  checked={planoContasCad.ativo}
                                />
                              }
                              size="small"
                              label={planoContasCad.ativo ? 'Ativo' : 'Inativo'}
                              name="ativo"
                              onChange={(e) => updateFieldAtivo(e)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row">
                        <Grid item md={2} xs={12} sm={4}>
                          <FormControl component="fieldset" className="mag-top--8">
                            <FormLabel component="legend">Permite Centro de Custo?</FormLabel>
                            <FormControlLabel className="mg_left_10"
                              control={
                                <Switch
                                  name="ativo"
                                  color="primary"
                                  checked={planoContasCad.permite_centro_custo}
                                />
                              }
                              size="small"
                              label={planoContasCad.permite_centro_custo ? 'Sim' : 'Não'}
                              name="ativo"
                              onChange={(e) => updateFieldPermCentroCusto(e)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12} sm={4}>
                          <FormControl component="fieldset" className="mag-top--8">
                            <FormLabel component="legend">Usar Centro de Custo?</FormLabel>
                            <FormControlLabel className="mg_left_10"
                              control={
                                <Switch
                                  name="ativo"
                                  color="primary"
                                  checked={planoContasCad.uso_centro_custo}
                                />
                              }
                              size="small"
                              label={planoContasCad.uso_centro_custo ? 'Sim' : 'Não'}
                              name="ativo"
                              onChange={(e) => updateFieldUsaCentroCusto(e)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={6} sm={false}></Grid>
                        <Grid item md={2} xs={6} sm={3}>
                          <Button size="small" className="btnCadastrar" variant="contained" color="primary" onClick={e => salvar()}>
                            Cadastrar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                </React.Fragment>
              }
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <h5 className="titulo">Niveis Inferiores:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    viewPlanos={e => viewPlanos(e)}
                    headCell={state.cabecalhoTabela} 
                    rows={planoContas.niveis_inferior}
                    acoes={state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          </Main >
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default ViewPlanoDeContas