import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import socketClient from './../components/lib/socket'

const PrivateRoute = (props) => {
  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')
  
    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  
    return config
  }

  const registraTela = async () => {
    if(localStorage.getItem('token') !== null) {
      
      if(props){
        if(props.titulo){
          try {
            const res = await axios.get("https://api.ipify.org/?format=json");
    
            let dados = {
              tela: props.titulo,
              ip: res.data.ip
            }
      
            await axios.post(`${window._env_.REACT_APP_API_URL}/acessoTela`, dados, getToken())
            
          } catch (error) {
            console.log(error)
          }
        }
      }  
    }
  }

  const isAuth = (rest) => {
    const expira_sessao = localStorage.getItem('expira_sessao')
  
    if(expira_sessao){
      if(moment(expira_sessao).isBefore(moment())){
        alert('Sessão expirada')
  
        const usuario = JSON.parse(localStorage.getItem('usuario'))
  
        if(usuario){
          socketClient({acao: 'user-logoff', values: usuario})
        }
  
        localStorage.clear();
  
        // localStorage.setItem('ultima_url_login', rest.location.pathname)
        return false;
      }else{
        localStorage.setItem('expira_sessao', moment().add(80, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
      }
    }else{
      localStorage.setItem('expira_sessao', moment().add(80, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
    }
  
    if(localStorage.getItem('token') !== null) {
      
      registraTela()
  
      document.title = props ? (props.titulo || 'Mais Financeiro') : 'Mais Financeiro'
      return true
    }
  
    // localStorage.setItem('ultima_url_login', rest.location.pathname)
  
    localStorage.clear();
    alert('Acesso não autorizado!')
    return false;
  };

  return isAuth() ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute