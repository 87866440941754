import React, { Component } from 'react'
import './Admin.scss'
import './../../main/ultil.scss'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios'
import encrypt from '../../services/encrypt'
import decrypt from '../../services/decrypt'
import Alert from './../../components/templates/Alert'
import { Grid } from '@mui/material'
import moment from 'moment'

const initialState = {
  logado: false,
  loading: true,
  css: '',
  login: {
    email: '',
    senha: ''
  },
  showPassword: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  textoBotao: 'Entrar',
  aplicacoes: [
    {
      id: 1,
      nome: 'API - Mais Financeiro',
      url: 'https://back.maisfinanceiro.com.br',
      online: true,
      pools: 0,
      maxPools: 0
    },
    {
      id: 2,
      nome: 'API - British System',
      url: 'https://britishapi.clicksistemas.app.br',
      online: true,
      pools: 0,
      maxPools: 0
    },
    {
      id: 3,
      nome: 'API - Whats',
      url: 'https://apiwhatsdev.clicksistemas.dev.br',
      online: true,
      pools: 0,
      maxPools: 0
    },
    {
      id: 4,
      nome: 'API - NFS-e',
      url: 'https://apinfseapp.clicksistemas.app.br',
      online: true,
      pools: 0,
      maxPools: 0
    }
  ]
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

export default class AgendaAmbiente extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = decrypt(localStorage.getItem('token-admin'))

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const token_admin = localStorage.getItem('token-admin')

    if(token_admin){
      
    	const expira_sessao = localStorage.getItem('expira_sessao_admin')
      
      if(expira_sessao){
        if(moment(expira_sessao).isAfter(moment())){
          this.setState({
            logado: true
          })
  
          let aplicacoes = this.state.aplicacoes
          
          for (const aplicacao of aplicacoes) {
            let index = aplicacoes.findIndex(param => param.id === aplicacao.id)
            
            try {
              const { data } = await axios.get(`${aplicacao.url}/status`, this.getToken())
             
              aplicacoes[index].online = true
              aplicacoes[index].pools = data.pools
              aplicacoes[index].maxPools = data.maxPools
    
              this.setState({
                aplicacoes
              })
            
            } catch (error) {
              // console.log(error)
              
              aplicacoes[index].online = false
              
              this.setState({aplicacoes})
              
            }
          }

    			localStorage.setItem('expira_sessao_admin', moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'));

        }else{
          alert('Sessão expirada')
          localStorage.removeItem('token-admin')
          localStorage.removeItem('expira_sessao_admin')
          localStorage.removeItem('nome-admin')
          this.setState({
            logado: false
          })
        }

      }else{
        alert('Sessão expirada')
        localStorage.removeItem('token-admin')
        localStorage.removeItem('expira_sessao_admin')
        localStorage.removeItem('nome-admin')
        this.setState({
          logado: false
        })
      }
    }

    this.setState({
      loading: false
    })

    setTimeout(() => {
     this.UNSAFE_componentWillMount()
    },  5000);
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  async login(){
    if (!validacaoEmail(this.state.login.email)) {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Email Invalido!'
      })

      return
    }

    this.setState({
      textoBotao: 'Autenticando',
      css: 'loading'
    })

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/admin/login`, this.state.login)
      
      setTimeout(() => {
        this.setState({
          textoBotao: 'Bem vindo de volta!',
          css: 'ok'
        })

        setTimeout(async () => {
          localStorage.setItem('token-admin', encrypt(data.token))
          localStorage.setItem('nome-admin', data.nome )
          
          let aplicacoes = this.state.aplicacoes
      
          for (const aplicacao of aplicacoes) {
            let index = aplicacoes.findIndex(param => param.id === aplicacao.id)
            
            try {
              const { data } = await axios.get(`${aplicacao.url}/status`, this.getToken())
            
              aplicacoes[index].online = true
              aplicacoes[index].pools = data.pools
              aplicacoes[index].maxPools = data.maxPools

              this.setState({
                aplicacoes
              })
            
            } catch (error) {
              console.log(error)
              
              aplicacoes[index].online = false
              
              this.setState({aplicacoes})
              
            }
          }

          localStorage.setItem('expira_sessao_admin', moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'));

          this.setState({ 
            loading: false,
            logado: true
          })
          return;
        }, 2000);
      }, 2000);
    } catch (error) {
      console.log(error.response.data)
      this.setState({
        textoBotao: 'Entrar',
        css: ''
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })

      this.setState({ loading: false })
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateField(event) {
    const login = { ...this.state.login }
   
    login[event.target.name] = event.target.value
    
    this.setState({ login })
  }

  render() {
    const { logado, login, css, textoBotao, showPassword, aplicacoes } = this.state

    return (
      <React.Fragment>
        <React.Fragment>
            {!logado && 
              <React.Fragment>
                <div className="wrapper-admin">
                  <div className={`login ${css}`}>
                    <p className="title">PAINEL ADMIN</p>
                    {/* <img className='logo_mais_financeiro_login' src="./images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" /> */}
                    
                    <input className='input-admin' type="text" placeholder="Email" autoFocus name="email" value={login.email} onChange={(evt) => this.updateField(evt)}/>
                    <i className="fa fa-user"></i>
                    <input className='input-admin' type={showPassword ? "text" : "password"} placeholder="Senha" name="senha" value={login.senha} onChange={(evt) => this.updateField(evt)}/>
                    <i className="fa fa-key"></i>

                    {!showPassword &&
                      <VisibilityIcon className='visibilityPassword' onClick={e => this.handleClickShowPassword()} style={{display: css === 'loading' ? 'none': 'block', color: "#00008B", top: 145, right: 30}}/>
                    }
                    {showPassword &&
                      <VisibilityOffIcon className='visibilityPassword' onClick={e => this.handleClickShowPassword()} style={{display: css === 'loading' ? 'none': 'block', color: "#00008B", top: 145, right: 30}}/>
                    }

                    {/* <a href="#">Forgot your password?</a> */}
                    <button onClick={() => this.login()} style={{backgroundColor: '#00008B'}}>
                      <i class="spinner"></i>
                      <span class="state">{textoBotao}</span>
                    </button>
                  </div>
                  {/* <span className='esqueceu_senha' onClick={() => this.openEsqueceuSenha()}>Esqueceu sua senha?</span> */}
                  <footer><a target="blank" href="http://clicksistemas.com.br/">Click Sistemas</a></footer>
                </div>
              </React.Fragment>
            }
            {logado &&
              <React.Fragment>
                <div className="container-boletos" id="app">
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className='titulo'>Aplicações:</h4>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="row" className="mg_top_20">
                    {aplicacoes.map(aplicacao => {
                      return (
                        <Grid item md={2} xs={12} sm={3}>
                          <div className='card_aplicacao'>
                            <Grid container spacing={1} direction="row">
                              <Grid item md={12} xs={12} sm={12}>
                                <p style={{textAlign: 'center', marginBottom: 5}}>{aplicacao.nome}</p>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} direction="row">
                              <Grid item md={12} xs={12} sm={12}>
                                {aplicacao.online &&
                                  <div className='online'> <b>Online</b> </div>
                                }
                                {!aplicacao.online &&
                                  <div className='offline'> <b>Offline</b> </div>
                                }
                              </Grid>
                            </Grid>
                            {aplicacao.maxPools > 0 &&
                              <React.Fragment>
                                <Grid container spacing={1} direction="row">
                                  <Grid item md={12} xs={12} sm={12}>
                                    <p>Pools Abertas: <b><span style={{color: aplicacao.pools === aplicacao.maxPools ? 'red' : 'green'}}>{aplicacao.pools}</span></b></p>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1} direction="row">
                                  <Grid item md={12} xs={12} sm={12}>
                                    <p>Max. Pools: <b>{aplicacao.maxPools}</b></p>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            }
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
              </React.Fragment>
            }
        </React.Fragment>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}