import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import real from './../../services/real';

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const FormContaPagarReceber = ({ centro_custos, updateFieldCentro, ajustaValorCentro, centro_custo, 
	updateFieldCentroCusto, departamentos, centro_custos_list, addCentroCusto,removeCentro, conta, disabledCampos }) => (
  <React.Fragment>
		<Grid container direction="row" spacing={1} className="mg_top_10">
			<Grid item md={12} xs={12} sm={12}>
				<h5 className="titulo">Informar Centro de Custos:</h5>
			</Grid>
		</Grid>
		<Grid container direction="row" spacing={1} className="mg_top_10">
			<Grid item md={3} xs={12} sm={6}>
				<TextField
					id="standard-select-currency"
					select
					label="Departamento*"
					variant="outlined"
					className="input"
					fullWidth
					size="small"
					SelectProps={{
						native: true,
					}}
					name="departamento_id"
					value={centro_custo.departamento_id}
					onChangeCapture={(e) => updateFieldCentroCusto(e)}
					InputLabelProps={{ shrink: true }}
					disabled={disabledCampos}
				>
					<option value=''> </option>
					{departamentos.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(departamento => {
						return (
							<option value={departamento.id}> {departamento.descricao} </option>
						)
					})}
				</TextField>
			</Grid>
			<Grid item md={3} xs={12} sm={6}>
				<TextField
					id="standard-select-currency"
					select
					label="Centro de Custo*"
					variant="outlined"
					className="input"
					fullWidth
					size="small"
					SelectProps={{
						native: true,
					}}
					name="centrocusto_id"
					value={centro_custo.centrocusto_id}
					onChangeCapture={(e) => updateFieldCentroCusto(e)}
					InputLabelProps={{ shrink: true }}
					disabled={disabledCampos}
				>
					<option value=''> </option>
					{centro_custos_list.filter(param => {
						if(centro_custos.findIndex(value => parseInt(value.centrocusto_id) === parseInt(param.id)) === -1) return true
						return false
					}).sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(centro => {
						return (
							<option value={centro.id}> {centro.descricao} </option>
						)
					})}
				</TextField>
			</Grid>
			<Grid item md={2} xs={12} sm={6} className="alignCenter">
				<Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addCentroCusto(e)}>Adicionar</Button>
			</Grid>
		</Grid>
		<Grid container direction="row" spacing={1} className="mg_top_10">
			<Grid item md={6} xs={12} sm={12}>
				<div className='scroll'>
					<table className="lista sempadding borderTable">
						<thead>
							<tr className="titulos acompanha">
								<th>Centro de Custo</th>
								<th>Valor Rateio*</th>
								<th>Porc. Rateio*</th>
								<th>Ação</th>
							</tr>
						</thead>
						<tbody>
							{centro_custos.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(centro => {
								return (
									<tr key={centro.index}>
										<td>{centro.centrocusto}</td>
										<td>
											<TextField 
												className="inputValor" 
												variant="outlined"
												size="small" 
												name="valor_rateio" 
												value={centro.valor_rateio} 
												InputLabelProps={{ shrink: true }} 
												InputProps={{ inputComponent: CurrencyInput }} 
												onChange={(e) => updateFieldCentro(e, centro.index)} 
												onBlur={(e) => ajustaValorCentro(e, centro.index)}
												disabled={disabledCampos}
											/>
										</td>
										<td>
											<TextField 
											type='number' 
											className="inputValor" 
											variant="outlined" 
											size="small" 
											name="valor_percentual" 
											value={centro.valor_percentual} 
											InputLabelProps={{ shrink: true }} 
											onChange={(e) => updateFieldCentro(e, centro.index)} 
											onBlur={(e) => ajustaValorCentro(e, centro.index)}
											InputProps={{ inputProps: { max: 100 } }}
											disabled={disabledCampos}
										/>
										</td>
										<td>
											<Tooltip title='Remover' aria-label="add">
												<IconButton onClick={e => removeCentro(centro.index)} color="primary" aria-label="remove" disabled={disabledCampos}>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</td>
									</tr>
								)
							})}
							<tr>
								<td>Restante</td>
								<td>
									<TextField 
										className="inputValor" 
										variant="outlined"
										size="small" 
										name="valor_rateio" 
										value={real(parseFloat(conta.valortotal.replaceAll(".", "").replace(",", ".")) - somarValores(centro_custos.map(centro => { return parseFloat(centro.valor_rateio.replaceAll(".", "").replace(",", "."))})))} 
										InputLabelProps={{ shrink: true }} 
										InputProps={{ inputComponent: CurrencyInput }} 
										disabled
									/>
								</td>
								<td>
									<TextField 
									type='number' 
									className="inputValor" 
									variant="outlined" 
									size="small" 
									name="valor_percentual" 
									value={(100 - somarValores(centro_custos.map(centro => { return parseFloat(centro.valor_percentual)}))).toFixed(2)} 
									InputLabelProps={{ shrink: true }} 
									disabled
								/>
								</td>
								<td>
									{somarValores(centro_custos.map(centro => { return parseFloat(centro.valor_percentual)})) !== 100 &&
										<Tooltip title='Rateio inferior/superior a 100%' aria-label="add">
											<IconButton color="error" aria-label="remove">
												<WarningIcon />
											</IconButton>
										</Tooltip>
									}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Grid>
		</Grid>
  </React.Fragment>
)

export default FormContaPagarReceber