import React, { Component } from 'react'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Cadastrar from './components/Cadastro'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  openModalAjuda: false,
  markdown: ''
}
export default class CadastroPlanoContas extends Component {
  state = { ...initialState }

  async componentWillMount() {
    const helpPath = require("./../../help/contaPagarReceber/Cadastro.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })
    
  }

  render() {
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          <Cadastrar {...this.props} />
        </Main>
        <Nav history={this.props.history}/>
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Cadastro de Contas a Pagar/Receber"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}