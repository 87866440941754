import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './Produto.scss'
import { Link } from 'react-router-dom'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles';

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'quantidade', numeric: false, disablePadding: false, label: 'Quantidade' },
  { id: 'duracao', numeric: false, disablePadding: false, label: 'Duração' },
  { id: 'unidadeMedida', numeric: false, disablePadding: false, label: 'Unidade de Medida' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const acoesTabela = []

const cabecalhoTabelaEstoque = [
  { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade' },
  { id: 'localEstoque', numeric: false, disablePadding: false, label: 'Local Estoque' },
  { id: 'estoqueMinimo', numeric: true, disablePadding: false, label: 'Estoq. Mínimo' },
  { id: 'estoqueMaximo', numeric: true, disablePadding: false, label: 'Estoq. Máximo' },
  { id: 'estoqueAtual', numeric: true, disablePadding: false, label: 'Estoq. Atual' }
]

const acoesTabelaEstoque = []

const cabecalhoTabelaCentro = [
  { id: 'planocontas', numeric: false, disablePadding: true, label: 'Plano de Contas' },
  { id: 'centrocusto', numeric: false, disablePadding: true, label: 'Centro de Custo' },
  { id: 'valor_percentual', numeric: false, disablePadding: true, label: 'Rateio %' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const acoesTabelaCentro = []

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#FFF',
}));

export default function SimpleTabs(props) {
  const [value, setValue] = React.useState(0);
  const {produto, permissoesEstoque, permissoes, estoqueProdutos} = props

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();

  return (
    <Root theme={theme}>
      <AppBar position="static">
        <Tabs id="viewCliente" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Dados do Produto" {...a11yProps(0)} />
          {/* {permissoesEstoque.visualizar &&
            <Tab label="Estoque do Produto" {...a11yProps(1)} />
          } */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} id='view'>
        <Grid container spacing={1} direction="row">
          <Grid item md={2} xs={5} sm={5}>
            <Link to={`/produtos`}>
              <Button size="small" className="btnCadastrar" variant="contained" color="secondary">
                Voltar
              </Button>
            </Link>
          </Grid>
          <Grid item md={8}></Grid>
          {!permissoes.alterar && 
            <Grid item md={2} xs={6} sm={6}></Grid>
          }
          {permissoes.alterar &&
            <Grid item md={2} xs={6} sm={6}>
              <Link to={`/produtos/cadastro/${produto._id}`}>
                <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                  Editar Produto
                </Button>
              </Link>
            </Grid>
          }
        </Grid>
        <Grid container spacing={1} direction="row" className="mg_top_10">
          <Grid item md={2} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Cod. Barras" value={produto.codigo} disabled />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Produto" value={produto.descricao} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Sub Grupo Produto" value={produto.subGrupoProduto} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Grupo Produto" value={produto.grupoProduto} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade Medida" value={produto.unidadeMedida} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Visibilidade" value={produto.visibilidade} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Destaque" value={produto.destaque ? 'Sim' : 'Não'} disabled />
          </Grid>
          {produto.tipo === 'SERVICO' &&
            <Grid item md={3} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Duração" value={produto.duracao + ' Min.'} disabled />
            </Grid>
          }
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Ativo" value={produto.ativo ? 'Ativo' : 'Inativo'} disabled />
          </Grid>
        </Grid>
        {produto.tipo === 'SERVICO' &&
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={6} sm={12} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Ambientes" value={produto.ambientes} disabled />
            </Grid>
          </Grid>
        }
        <hr className="mg_top_10"/>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={6}>
            <h4 className="titulo">Rateio Centro de Lucro:</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <Table
              headCell={cabecalhoTabelaCentro}
              rows={produto.centro_lucros.map(item => {
                return {
                  _id: item.id,
                  centrocusto: item.centrocusto,
                  planocontas: item.planocontas,
                  valor_percentual: item.valor_percentual,
                  ativo: item.ativo,
                  index: item.index
                }
              })}
              desabilitaRodape={true}
              qtdLinhas={produto.centro_lucros.length}
              acoes={acoesTabelaCentro} />
          </Grid>
        </Grid>
        <hr className="mg_top_10"/>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={6}>
            <h4 className="titulo">Rateio Centro de Custo:</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <Table
              headCell={cabecalhoTabelaCentro}
              rows={produto.centro_custos.map(item => {
                return {
                  _id: item.id,
                  centrocusto: item.centrocusto,
                  planocontas: item.planocontas,
                  valor_percentual: item.valor_percentual,
                  ativo: item.ativo,
                  index: item.index
                }
              })}
              desabilitaRodape={true}
              qtdLinhas={produto.centro_custos.length}
              acoes={acoesTabelaCentro} />
          </Grid>
        </Grid>
        {produto.itensCombo.length > 1 &&
          <div className="mg_top_10">
            <h4 className="titulo">Itens do Combo:</h4>
            <Grid container pacing={2} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table
                  headCell={cabecalhoTabela}
                  rows={produto.itensCombo.map(item => {
                    return {
                      _id: item.produtoId,
                      url: item.produtoItem.midia ? item.produtoItem.midia.caminho : '',
                      descricao: item.produtoItem.descricao,
                      unidadeMedida: item.produtoItem.unidademedida,
                      quantidade: item.quantidade,
                      duracao: (item.duracao * item.quantidade) + ' Min.',
                      ativo: item.ativo
                    }
                  })}
                  acoes={acoesTabela}
                />
              </Grid>
            </Grid>
          </div>
        }
      </TabPanel>
      {permissoesEstoque.visualizar &&
        <TabPanel value={value} index={1}>
          <div className="cardEndereco">
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={8} xs={12}>
                <h1 className="titulo">Estoque do Produto</h1>
              </Grid>
              {permissoesEstoque.inserir &&
                <Grid item md={4} xs={12}>
                  <Link to={`/estoque/cadastro/${produto._id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Estoque Produto
                    </Button>
                  </Link>
                </Grid>
              }
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={4} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Produto" value={produto.descricao} disabled />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Sub Grupo Produto" value={produto.subGrupoProduto} disabled />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Grupo Produto" value={produto.grupoProduto} disabled />
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_30">
              <Grid item md={12} xs={12}>
                <Table 
                  headCell={cabecalhoTabelaEstoque}
                  rows={estoqueProdutos}
                  acoes={acoesTabelaEstoque}
                  urlUpdate="/estoque/cadastro/"
                />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      }
    </Root>
  );
}
