import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './Funcionario.scss'
import moment from 'moment';
import Table from '../../components/Table'
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import { Grid, Button, TextField } from '@mui/material'
import Funcionario from './View/Funcionario'
import Afastamento from './View/Afastamento'
import Servico from './View/Servico'
import Horarios from './View/Horarios'

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Unidade' },
  { id: 'datainicio', numeric: false, disablePadding: true, label: 'Data Inicio' },
  { id: 'datafinal', numeric: false, disablePadding: false, label: 'Data Final' },
  { id: 'func_descricao', numeric: false, disablePadding: false, label: 'Função' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' },
]

const acoesTabela = []

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#FFF',
}));

export default function SimpleTabs(props) {
  const { funcionario, pessoa, contratos, permissoes, permissoesPromover } = props

  var tabs = {
    funcionario: 0,
    contrato: 1,
    servico: 2,
    horario: 3,
  }

  const [value, setValue] = React.useState(tabs[props.params.tab] ? tabs[props.params.tab] : 0);
  
  const handleChange = (event, newValue) => {
   
    var tabsUrl = {
      0: 'funcionario',
      1: 'contrato',
      2: 'servico',
      3: 'horario',
    }
    
    setValue(newValue)
    props.navigate(`/funcionario/view/${funcionario.id}/${tabsUrl[newValue]}`);
    return;
  };

  const theme = useTheme();

  return (
    <Root theme={theme}>
      <AppBar position="static">
        <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Dados do Funcionario" {...a11yProps(0)} />
          <Tab label="Dados do Contrato" {...a11yProps(1)} />
          <Tab label="Serviços" {...a11yProps(2)} />
          <Tab label="Horários" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Funcionario funcionario={funcionario} permissoes={permissoes} {...props.props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid style={{marginTop: 5}} container spacing={1} direction="row">
          <Grid item md={12} xs={12} sm={12}>
            <h3 className='titulo'>Contratos:</h3>
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" className="mg_top_10">
          <Grid item md={12} xs={12}>
            <Table headCell={cabecalhoTabela} rows={contratos.sort(function (a, b) {
              return new Date(b.datainicio) - new Date(a.datainicio);
            }).map(contrato => {
              return {
                descricao: contrato.unidadetrabalho.descricao,
                datainicio: moment(contrato.datainicio).format('DD/MM/YYYY'),
                datafinal: contrato.datafinal ? moment(contrato.datafinal).format('DD/MM/YYYY') : 'Em Aberto',
                func_descricao: contrato.funcao.descricao,
                ativo: contrato.ativo,
              }
            })} acoes={acoesTabela} />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12} sm={12}>
            <h4 className="titulo">Contrato Atual: </h4>
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" className="mg_top_10">
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Matricula" value={funcionario.matricula} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Admissão" value={moment(funcionario.data_admissao).format('DD/MM/YYYY')} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Funcão" value={funcionario.contrato.funcao.descricao} disabled />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade de Trabalho" value={funcionario.contrato.unidadetrabalho.descricao ? funcionario.contrato.unidadetrabalho.descricao : funcionario.contrato.unidadetrabalho.pessoa.tipo === 'FISICA' ? funcionario.contrato.unidadetrabalho.pessoa.fisica.nome : funcionario.contrato.unidadetrabalho.pessoa.juridica.razaosocial} disabled />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" className="mg_top_10">
          <Grid item md={12} sm={12} xs={12}>
            <TextField multiline rows={5} fullWidth size="small" variant="outlined" className="input" label="Observação" value={funcionario.contrato.observacao} disabled />
          </Grid>
        </Grid>
        {(funcionario.status && (permissoes.inserir || permissoes.alterar)) && 
          <Grid container spacing={1} direction="row">
            {pessoa.fisica.funcionario.id !== funcionario.id &&
              <Grid item md={4} sm={12} xs={12}>
                <Link to={`/funcionario/contrato/demitir/${funcionario.id}`}>
                  <Button className="btnCadastrar" variant="contained" color="secondary">
                    Demitir Funcionário
                  </Button>
                </Link>
              </Grid>
            }
            {permissoesPromover.perm_inserir &&
              <Grid item md={4} sm={12} xs={12}>
                <Link to={`/funcionario/contrato/promover/${funcionario.id}`}>
                  <Button className="btnCadastrar" variant="contained" color="primary">
                    Promover Funcionário
                </Button>
                </Link>
              </Grid>
            }
          </Grid>
        }
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Servico funcionario={funcionario} {...props.props} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Horarios funcionario={funcionario} {...props.props} />
      </TabPanel>
    </Root>
  );
}
