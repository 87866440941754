import React, { useState, useEffect } from 'react';
import './Representante.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Link } from 'react-router-dom'
import { Grid, TextField, Button } from '@mui/material'
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import { useParams, useNavigate } from 'react-router-dom';
import formatarTelefone from './../../services/formatarTelefone'
import Tabela from './../../components/Tabela'

const cpf = require('node-cpf-cnpj')
const cnpj = require('node-cpf-cnpj')

const initialState = {
  representante: {},
  representantes: {
    list: []
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  mensagemLoading: 'Carregando Dados do Representante...',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: [],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
}

const ViewRepresentante = () => {
  const [state, setState] = useState({ ...initialState });
  const { representanteId } = useParams();
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/representante");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    }));
  }

  useEffect(() => {

    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'representante')[0]

      if(permissoes){
        setState(prevState => ({
          ...prevState,
          permissoes
        }));
      }

      try {
        const { data: representante } = await axios.get(`${window._env_.REACT_APP_API_URL}/representantes/${representanteId}`, getToken())
        
        setState(prevState => ({
          ...prevState,
          representante
        }));
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }));
      }
    };

    fetchData();

    setState(prevState => ({
      ...prevState,
      loading: false
    }));
  }, []);

  const { representante, permissoes, loading, alerta, mensagemLoading, cabecalhoTabelaEndereco, acoesTabelaEndereco, cabecalhoTabelaTelefone, cabecalhoTabelaEmail } = state

  return (
    <React.Fragment>
      {!loading &&
        <div className="app-menu-closed" id="app">
          <Main>
            <div>
              {representante.pessoa &&
                <div id="view" className="cardEndereco">
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={8} xs={12} sm={12}>
                      <h2 className="titulo">Dados Representante: </h2>
                    </Grid>
                    {!permissoes.alterar &&
                      <Grid item md={2} xs={12} sm={6}></Grid>
                    }
                    <Grid item md={2} xs={12} sm={6}>
                      <Link to={`/representante`}>
                        <Button size='small' fullWidth variant="contained" color="secondary">
                          Voltar
                        </Button>
                      </Link>
                    </Grid>
                    {permissoes.alterar &&
                      <Grid item md={2} xs={12} sm={6}>
                        <Link to={`/representante/update/${representante.id}`}>
                          <Button size='small' fullWidth variant="contained" color="primary">
                            Editar Representante
                          </Button>
                        </Link>
                      </Grid>
                    }
                  </Grid>
                  {representante.pessoa.tipo === "FISICA" &&
                    <div>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Dados Pessoa Física: </h4>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={representante.pessoa.fisica.nome} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(representante.pessoa.fisica.cpf)} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={representante.pessoa.fisica.rg} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(representante.pessoa.fisica.datanascimento).format('YYYY-MM-DD')} disabled />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {representante.pessoa.tipo === "JURIDICA" &&
                    <div>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Dados Pessoa Jurídica: </h4>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={representante.pessoa.juridica.razaosocial} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantasia" value={representante.pessoa.juridica.nomefantasia} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={cnpj.format(representante.pessoa.juridica.cnpj)} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={representante.pessoa.juridica.inscricaoestadual} disabled />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Fundação" value={moment(representante.pessoa.juridica.datafundacao).format('YYYY-MM-DD')} disabled />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {representante.pessoa.enderecos.length > 0 &&
                    <React.Fragment>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Endereços: </h4>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={12}>
                          <Tabela 
                            headCell={cabecalhoTabelaEndereco} 
                            rows={representante.pessoa.enderecos.map((endereco, index) => {
                              return {
                                cep: endereco.num_cep,
                                endereco: `${endereco.logradouro}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                                cidade: endereco.cidade,
                                estado: endereco.estado,
                                principal: endereco.principal,
                                tipoEndereco: endereco.tipo,
                                index
                              }
                            })} 
                            acoes={acoesTabelaEndereco}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                  {representante.pessoa.telefones.length > 0 &&
                    <React.Fragment>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Telefones Representante: </h4>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={12}>
                          <Tabela 
                            headCell={cabecalhoTabelaTelefone} 
                            rows={representante.pessoa.telefones.map(contato => {
                              return {
                                numero: formatarTelefone(contato.numero),
                                tipoContato: contato.tipo,
                                principal: contato.principal,
                                index: contato.index
                              }
                            })} 
                            acoes={acoesTabelaEndereco}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                  {representante.pessoa.emails.length > 0 &&
                    <React.Fragment>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">E-mails: </h4>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={12}>
                          <Tabela 
                            headCell={cabecalhoTabelaEmail} 
                            rows={representante.pessoa.emails.map(email => {
                              return {
                                email: email.email,
                                principal: email.principal,
                                index: email.index
                              }
                            })} 
                            acoes={acoesTabelaEndereco}
                          />
                        </Grid>
                      </Grid>  
                    </React.Fragment>
                  }
                </div>
              }
              {representante.responsavel &&
                <div id="view" className="cardEndereco">
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={10} xs={12} sm={12}>
                      <h2 className="titulo">Dados Responsavel: </h2>
                    </Grid>
                    {permissoes.alterar && 
                      <Grid item md={2} xs={6} sm={6}>
                        <Link to={`/representante/updateResponsavel/${representante.id}`}>
                          <Button size='small' fullWidth variant="contained" color="primary">
                            Editar Responsável
                          </Button>
                        </Link>
                      </Grid>
                    }
                  </Grid>
                  <div>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Dados Pessoa Física: </h4>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={representante.responsavel.nome} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(representante.responsavel.cpf)} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={representante.responsavel.rg} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(representante.responsavel.datanascimento).format('YYYY-MM-DD')} disabled />
                      </Grid>
                    </Grid>
                  </div>
                  {representante.responsavel.pessoa.enderecos.length > 0 &&
                    <React.Fragment>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Endereços: </h4>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={12}>
                          <Tabela 
                            headCell={cabecalhoTabelaEndereco} 
                            rows={representante.responsavel.pessoa.enderecos.map((endereco, index) => {
                              return {
                                cep: endereco.num_cep,
                                endereco: `${endereco.logradouro}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                                cidade: endereco.cidade,
                                estado: endereco.estado,
                                principal: endereco.principal,
                                tipoEndereco: endereco.tipo,
                                index
                              }
                            })} 
                            acoes={acoesTabelaEndereco}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                  {representante.responsavel.pessoa.telefones.length > 0 &&
                    <React.Fragment>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Telefones: </h4>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={12}>
                          <Tabela 
                            headCell={cabecalhoTabelaTelefone} 
                            rows={representante.responsavel.pessoa.telefones.map(contato => {
                              return {
                                numero: formatarTelefone(contato.numero),
                                tipoContato: contato.tipo,
                                principal: contato.principal,
                                index: contato.index
                              }
                            })} 
                            acoes={acoesTabelaEndereco}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                  {representante.responsavel.pessoa.emails.length && 
                    <React.Fragment>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">E-mails: </h4>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={12}>
                          <Tabela 
                            headCell={cabecalhoTabelaEmail} 
                            rows={representante.responsavel.pessoa.emails.map(email => {
                              return {
                                email: email.email,
                                principal: email.principal,
                                index: email.index
                              }
                            })} 
                            acoes={acoesTabelaEndereco}
                          />
                        </Grid>
                      </Grid>  
                    </React.Fragment>
                  }
                </div>
              }
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={alerta.severity}
            message={alerta.message} />
        </div >
      }
      <Loading 
        open={loading}
        message={mensagemLoading} 
      />
    </React.Fragment>
  )
  
}

export default ViewRepresentante
